import { handleActions } from 'redux-actions';

import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';
import { BaseAction, BaseState } from 'store/types';
import { AccountUser } from 'store/modules/accounts/types';

// Action Constants
export const [
  FETCH_USER_BY_UUID,
  FETCH_USER_BY_UUID_SUCCESS,
  FETCH_USER_BY_UUID_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_USER_BY_UUID'));

export interface State extends BaseState {
  data: Hash<AccountUser>;
}

export interface ActionMeta {
  uuid: string;
}

export interface ActionPayload extends BaseAction {
  payload: AccountUser;
  meta: ActionMeta;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

// Reducer
export const reducer = handleActions<State, AccountUser, ActionMeta>(
  {
    [FETCH_USER_BY_UUID]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_USER_BY_UUID_SUCCESS]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: true,
      error: action.error,
      data: {
        ...state.data,
        [action.meta.uuid]: action.payload,
      },
    }),
    [FETCH_USER_BY_UUID_FAIL]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error || true,
    }),
  },
  initialState
);

export default reducer;
