import { get } from 'lodash';
import { createSelector } from 'reselect';

import { SeatsSubset } from 'features/companies/redux/seats/reducer';
import { MemberType } from 'features/profile/types';
import { MembersSubset } from 'store/modules/members';
import { UserMembershipsSubset } from 'store/modules/userMemberships/types';
import { AccountUser } from 'store/modules/accounts/types';
import { mapAccountUserToAccountUserPrivateData } from 'store/modules/accounts/mappers';
import {
  getAccountUsersLoaded,
  getAccountUsersLoading,
} from 'store/modules/accounts/usersByAccountSelectors';

import { Seat } from './types';

export type AccountUserWithSeat = AccountUser & { seat: Seat };
export const mapAccountUsersToAccountUsersWithSeats = (
  accountUsers: Array<AccountUser>,
  seats: Array<Seat>
): Array<AccountUserWithSeat> =>
  accountUsers
    .map(accountUser => ({
      ...accountUser,
      seat: seats.find(seat => accountUser.uuid === get(seat, 'user.uuid')),
    }))
    .filter((accountUser): accountUser is AccountUserWithSeat => accountUser.seat !== undefined);

export const overrideSeatUser = (accountUsers: Array<AccountUser>, seat: Seat): Seat => {
  const accountUser = accountUsers.find(accountUser => accountUser.uuid === get(seat, 'user.uuid'));
  if (!accountUser) return seat;

  const memberOverride = mapAccountUserToAccountUserPrivateData(accountUser);
  return {
    ...seat,
    user: {
      ...seat.user,
      ...memberOverride,
    },
  };
};

export const overrideSeatUsers = (
  accountUsers: Array<AccountUser>,
  seats: Array<Seat>
): Array<Seat> => {
  return seats.map(seat => overrideSeatUser(accountUsers, seat));
};

export const getCompanySeats = (
  state: SeatsSubset & UserMembershipsSubset,
  props: Readonly<{ companyUuid: string }>
): Array<Seat> => {
  if (state.seats.byUuid[props.companyUuid] && state.userMemberships) {
    return state.seats.byUuid[props.companyUuid].map(
      (seat): Seat => ({
        ...seat,
        user: {
          ...seat.user,
          // @ts-ignore
          userMemberships: state.userMemberships.byUserUuid[seat.user.uuid],
        },
      })
    );
  }

  return state.seats.byUuid[props.companyUuid] || [];
};

export const getCompanySeatsStatus = (
  state: SeatsSubset,
  props: { companyUuid: string }
): { loading: boolean; loaded: boolean; error: boolean | null | undefined } =>
  state.seats.status.byUuid[props.companyUuid];

export const getCompanySeatsLoading = createSelector(
  [getCompanySeatsStatus],
  (status): boolean => !!status?.loading
);

export const getCompanySeatsLoaded = createSelector(
  [getCompanySeatsStatus],
  (status): boolean => !!status?.loaded
);

export const getAccountUsersOrSeatsLoading = createSelector(
  [getAccountUsersLoading, getCompanySeatsLoading],
  (accountUsersLoading, seatsLoading): boolean => accountUsersLoading || seatsLoading
);

export const getAccountUsersAndSeatsLoaded = createSelector(
  [getAccountUsersLoaded, getCompanySeatsLoaded],
  (accountUsersLoaded, seatsLoaded): boolean => accountUsersLoaded && seatsLoaded
);

const getUserUuid = (_: unknown, props: Readonly<{ member: MemberType }>): string =>
  props?.member?.uuid;

export const getUserSeat = createSelector(
  [getUserUuid, getCompanySeats],
  (userUuid, companySeats: Array<Seat>): Seat | undefined =>
    companySeats.find((seat): boolean => seat.user.uuid === userUuid)
);

export const getUserSeatIsActive = createSelector(
  [getUserSeat],
  (seat): boolean => seat?.status === 'active' && seat?.user.status === 'active'
);

const getMembersLoading = (state: MembersSubset): boolean => state.members.loading;

export const getCompanySeatsOrMembersLoading = createSelector(
  [getCompanySeatsLoading, getMembersLoading],
  (seatsLoading, membersLoading): boolean => seatsLoading || membersLoading
);

export const getUserUuids = (seats: Array<Seat>): Array<string> =>
  seats.map((seat): string => seat.user.uuid);

export const getSelectedSeat = (state: SeatsSubset): Seat | null => state.seats.selected;
export const getSeatFilter = (
  state: SeatsSubset
): { locationUuid?: string | null | undefined; status?: string } => state.seats.filter;
export const getSeatQuery = (state: SeatsSubset): string | null | undefined => state.seats.query;
export const getSeatPage = (state: SeatsSubset): number => state.seats.page;
export const getSeatPerPage = (state: SeatsSubset): number => state.seats.perPage;
export const getSeatTotalPages = (state: SeatsSubset): number => state.seats.totalPages;
