import { createSelector } from 'reselect';
import {
  isEqual,
  addDays,
  parseISO,
  lastDayOfMonth,
  isFirstDayOfMonth,
  isBefore,
  startOfMonth,
  eachMonthOfInterval,
  differenceInCalendarMonths,
  isAfter,
  max as maxDate,
  min as minDate,
} from 'date-fns';

import { GeneralInvalidError } from 'store/errors';
import { PaperworkSubset } from 'features/paperwork/ducks';
import {
  ProductReservationUpdate,
  toDiscountPayload,
  toTermPayload,
  DiscountInput,
  MEMBERSHIP_FEE_TYPE,
  MembershipFee,
  SuggestionVariant,
  ProductReservationAttrsUpdate,
  ProductItem,
  termInputToAttrs,
  CommitmentTermInputAttrs,
  CommitmentTermAttrs,
  PreviousAgreement,
} from 'features/paperwork/contracts-flow/types';
import {
  byDateProp,
  formatToISODate,
  isProrated,
  formatToUSAWithSlashes,
  isBetween,
} from 'features/paperwork/contracts-flow/sections/product/utils';
import {
  getSignedAgreementUrl,
  getSignedAt,
} from 'features/companies/addOnsSection/redux/uploadedAgreementsDuck';
import { getDiscountsAmountErrors } from 'features/paperwork/contracts-flow/sections/product/ducks/validators';
import { getChargePrice } from 'lib/discountModalUtil';
import { DiscountType, PaperworkFlow } from 'features/paperwork/contracts-flow/layouts/types';

import { getServiceCloudNumberValue } from '../../serviceCloudNumber/duck';

import { State as ProductsReservationState } from '.';

export const getProductsReservation = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.items;

export const getProductsReservationById = (state: PaperworkSubset, { productId }) => {
  const productReservation = getProductsReservation(state).find(
    ({ productReservation }) => productId === productReservation.id
  );

  if (!productReservation) {
    throw new GeneralInvalidError('Previous Agreement', 'Missing Product Reservation');
  }

  return productReservation;
};

export const getPreviousReservations = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.previousAgreements;

export const getPreviousAgreementById = (state: PaperworkSubset, { productId }) => {
  const productItem = getProductsReservationById(state, { productId });
  if (productItem.productReservation.uuid) {
    const previousAgreement = getPreviousReservations(state).find(({ id }) => productId === id);

    if (!previousAgreement) {
      throw new Error('Missing previous Agreement');
    }

    return previousAgreement;
  }

  return undefined;
};

export const getPreviousAgreementActiveTerm = (
  state: PaperworkSubset,
  props: { productId: string }
) => {
  const previousAgreement = getPreviousAgreementById(state, props);
  if (previousAgreement) {
    const today = new Date();
    const term = previousAgreement.terms.find(({ endDate }) =>
      isBefore(today, parseISO(endDate.value))
    );
    return term && termInputToAttrs(term);
  }

  return undefined;
};

export const getProductDiscounts = (state: PaperworkSubset, { productId }) =>
  getProductsReservation(state).find(
    ({ productReservation }) => productId === productReservation.id
  )?.discounts || [];

export const getPreviousAgreementDiscounts = (
  state: PaperworkSubset,
  props: { productId: string }
) => {
  const previousAgreement = getPreviousAgreementById(state, props);
  if (previousAgreement) {
    return previousAgreement.discounts;
  }
  return [];
};

export const getPreviousAgreementActiveDiscounts = (
  state: PaperworkSubset,
  props: { productId: string }
) => {
  const activeAgreementDiscounts = getPreviousAgreementDiscounts(state, props);

  if (activeAgreementDiscounts.length) {
    const activeTerm = getPreviousAgreementActiveTerm(state, props);
    if (activeTerm)
      return activeAgreementDiscounts.filter(({ termId }) => termId === activeTerm.id);
    return activeAgreementDiscounts;
  }

  return [];
};

const getTerm = (state: PaperworkSubset, props: { productId: string }) => {
  const terms = getProductsReservationById(state, props).terms;

  return terms.length ? termInputToAttrs(terms[0]) : null;
};

const getOfficeInfoById = (state: PaperworkSubset, props: { productId: string }) =>
  state.paperwork.agreementManagement.products.officeInfo?.[props.productId];

export const getReservationMarketPrice = createSelector(
  [getProductsReservationById, getOfficeInfoById],
  productsReservation => {
    const marketPrice = productsReservation.productReservation.marketPrice;
    const reservationPrice = productsReservation.productReservation.price;

    return getChargePrice(marketPrice, reservationPrice);
  }
);

const getCurrentTerms = (state: PaperworkSubset, props: { productId: string }) => {
  const previousAgreement = getPreviousAgreementById(state, props);
  if (previousAgreement) {
    return previousAgreement.terms.sort(byDateProp('startDate.value')).map(termInputToAttrs);
  }
  return [];
};

export const getRemainingPeriod = createSelector([getTerm], (term: CommitmentTermAttrs) => ({
  startDate: formatToISODate(startOfMonth(new Date())),
  endDate: term?.startDate ? formatToISODate(addDays(parseISO(term?.startDate), -1)) : undefined,
}));

interface Range {
  startDate: string;
  endDate: string;
}

export const subRange = <T extends Range>(
  ranges: Array<T>,
  start?: string,
  end?: string
): Array<T> => {
  let firstFound = false;
  let lastFound = false;
  let currentIndex = 0;

  const sortedRanges = [...ranges].sort(byDateProp('startDate'));
  const firstRange = sortedRanges[0];
  const lastRange = sortedRanges[sortedRanges.length - 1];

  if (!(start || end)) {
    return ranges;
  }

  if (start && end) {
    if (!isBefore(parseISO(start), parseISO(end))) {
      return [];
    }

    if (
      !isBefore(parseISO(firstRange.startDate), parseISO(start)) &&
      !isAfter(parseISO(lastRange.endDate), parseISO(end))
    ) {
      return ranges;
    }
  }

  const startDate = start || firstRange.startDate;
  const endDate = end || lastRange.endDate;
  const inRange: Array<T> = [];

  while ((!firstFound || !lastFound) && currentIndex <= sortedRanges.length - 1) {
    const newRange: NewRange = {};
    const currentRange = sortedRanges[currentIndex];

    if (
      isBetween(startDate, currentRange.startDate, currentRange.endDate) ||
      isBetween(endDate, currentRange.startDate, currentRange.endDate)
    ) {
      if (isBetween(startDate, currentRange.startDate, currentRange.endDate)) {
        newRange.startDate = startDate;
        firstFound = true;
      }

      if (isBetween(endDate, currentRange.startDate, currentRange.endDate)) {
        newRange.endDate = endDate;
        lastFound = true;
      }

      inRange.push({
        ...currentRange,
        ...newRange,
      });
    }
    currentIndex++;
  }

  return inRange;
};

export const getRemainingDiscounts = createSelector(
  [getPreviousAgreementDiscounts, getProductDiscounts],
  (previousDiscounts, current) => {
    return subRange(
      previousDiscounts,
      formatToISODate(startOfMonth(new Date())),
      current[0]?.startDate && formatToISODate(addDays(parseISO(current[0]?.startDate), -1))
    );
  }
);

type FieldValue = number | Date | string | null;

type ComparisonField = {
  key: string;
  equals: (copy: FieldValue, origin: FieldValue) => boolean;
  format?: (value: FieldValue) => FieldValue;
};

const compareDates = (copy: Date, origin: Date) => (!copy && !origin) || copy === origin;

const fieldsToCompare: Array<ComparisonField> = [
  {
    key: 'startDate',
    equals: compareDates,
  },
  {
    key: 'endDate',
    equals: compareDates,
  },
];

export const getLoading = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.actions.loading;

export const getAreProductsLoaded = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.loaded;

export const getManualAgreementUpload = (state: PaperworkSubset) => {
  return {
    url: getSignedAgreementUrl(state),
    signedAt: getSignedAt(state),
  };
};

const toProductreservationUpdates = (
  availableSuggestions,
  promotion,
  promotionTerm,
  checkedOption
) => (
  changes: Array<ProductReservationUpdate>,
  { productReservation, productReservationOrigin, discounts, terms, localeInfo }: ProductItem
) => {
  // in the advent of a new add with a renewal, this will assign a proper identifier to find the appropriate maxDiscount
  const reservationIdentifier =
    productReservation.uuid.length > 0 ? productReservation.uuid : productReservation.id;

  const selectedPromotion =
    promotion ||
    (terms[0]?.length.value &&
      availableSuggestions.suggestions[reservationIdentifier]?.[terms[0].length.value]?.[0]);

  const termsValues = {
    'no-discount': [],
    'commitment-term-discount': terms?.map(toTermPayload),
    promotion: promotionTerm ? [promotionTerm].map(toTermPayload) : [],
  };

  const changed = {
    id: productReservation.id,
    uuid: productReservation.uuid,
    locationUuid: productReservation.locationUuid,
    reservableUuid: productReservation.reservableUuid,
    reservableType: productReservation.reservableType,
    price: productReservation.chargePrice,
    type: productReservation.type,
    selectedPromotionCode: selectedPromotion?.code,
    changes: {
      ...fieldsToCompare.reduce<ProductReservationAttrsUpdate>((acc, { key, equals, format }) => {
        const currentValue = productReservation[key].value;
        if (!equals(currentValue, productReservationOrigin[key].value)) {
          acc = {
            ...acc,
            [key]: format ? format(currentValue) : currentValue,
          };
        }
        return acc;
      }, {}),
    },
    discounts: discounts.map(toDiscountPayload),
    terms: termsValues[checkedOption],
    officeNumber: productReservation.officeNum,
    currency: localeInfo.currencyCode,
    maxDiscount: selectedPromotion?.maxDiscount,
  };

  changes.push(changed);
  return changes;
};

export const getAvailableSuggestions = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.discountSuggestions;

export const getUsingPromotion = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.usingPromotion;

export const getPromotion = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.promotion.selectedPromotion;

export const getCheckedOption = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.discountType;

export const getPromotionAmount = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.promotion.amount;

export const getIsPromotionDiscountPerpetuating = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.promotion.isPromotionDiscountPerpetuating;

export const getIsPromotionNotSelected = (state: PaperworkSubset) =>
  !!state.paperwork.agreementManagement.products.promotion?.term?.length?.value &&
  !state.paperwork.agreementManagement.products.promotion.selectedPromotion;

export const getPromotionTerm = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.promotion.term;

export const getTotalReservationPrice = (state: PaperworkSubset) => {
  return state.paperwork.agreementManagement.products.items.reduce(
    (prevValue, item) =>
      prevValue +
      getChargePrice(item.productReservation.marketPrice, item.productReservation.price),
    0
  );
};

export const getTotalNewDiscountPrice = (state: PaperworkSubset) => {
  return state.paperwork.agreementManagement.products.items.reduce(
    (prevValue, item) =>
      prevValue +
      getChargePrice(item.productReservation.marketPrice, item.productReservation.price),
    0
  );
};

export const getProductsReservationChanges = createSelector(
  [
    getProductsReservation,
    getAvailableSuggestions,
    getPromotion,
    getPromotionTerm,
    getCheckedOption,
  ],
  (productsReservation, availableSuggestions, promotion, promotionTerm, checkedOption) =>
    productsReservation.reduce<Array<ProductReservationUpdate>>(
      toProductreservationUpdates(availableSuggestions, promotion, promotionTerm, checkedOption),
      []
    )
);

export const getAllProductsReservationChanges = (state: PaperworkSubset) => {
  return {
    productsReservationChanges: getProductsReservationChanges(state),
    manualAgreementUpload: getManualAgreementUpload(state),
    serviceCloudNumber: getServiceCloudNumberValue(state),
  };
};

export const haveProductReservationsChanged = (state: PaperworkSubset) =>
  getProductsReservation(state).some(({ productReservation, productReservationOrigin }) =>
    fieldsToCompare.some(
      ({ key, equals }) =>
        !equals(productReservation[key].value, productReservationOrigin[key].value)
    )
  );

const discountChanged = (copy, origin) =>
  Object.keys(copy).some(key => key !== 'errors' && copy[key] !== origin[key]);

export const haveProductDiscountsChanged = (state: PaperworkSubset, props?: { productId }) => {
  let items = getProductsReservation(state);
  if (props?.productId) {
    items = items.filter(({ productReservation: { id } }) => id === props.productId);
  }
  return items.some(
    ({ discounts, discountsOrigin }) =>
      discounts.length !== discountsOrigin.length ||
      discountsOrigin.find(originDiscount => {
        const copyDiscount = discounts.find(discount => discount.id === originDiscount.id);
        return !copyDiscount || discountChanged(copyDiscount, originDiscount);
      })
  );
};

export const hasIncompleteAgreementUpload = state => {
  const hasUrl = !!state.uploadedAgreements.uploadedAgreements?.result?.url;
  const hasSignedAt = !!state.form.UploadAgreement?.values?.signedAt;
  return (hasUrl && !hasSignedAt) || (!hasUrl && hasSignedAt);
};

const termChanged = (copy, origin) =>
  Object.keys(copy).some(key => key !== 'errors' && copy[key] !== origin[key]);

export const haveProductTermsChanged = (state: PaperworkSubset) =>
  getProductsReservation(state).some(
    ({ terms, termsOrigin }) =>
      terms.length !== termsOrigin.length ||
      termsOrigin.find(originTerm => {
        const termCopy = terms.find(term => term.id === originTerm.id);
        return !termCopy || termChanged(termCopy, originTerm);
      })
  );

export const hasZeroLengthTerm = (state: PaperworkSubset) =>
  getProductsReservation(state).some(({ terms }) => terms.find(term => term.length.value === 0));

export const missingServiceCloudNumber = (state: PaperworkSubset) => {
  const urlPresent = getSignedAgreementUrl(state) !== undefined;
  const serviceCloudNumberLength =
    state.paperwork.agreementManagement.serviceCloudNumber.serviceCloudNumber.length;
  return urlPresent && serviceCloudNumberLength < 8;
};

export const outOfPolicyAcknowledged = (state: PaperworkSubset) => {
  const {
    checked,
    rationale,
    approver,
  } = state.paperwork.agreementManagement.products.outOfPolicyAcknowledged;
  return checked || (rationale && approver);
};

export const promotionCodeNotSelected = (state: PaperworkSubset) => {
  const { products } = state.paperwork.agreementManagement;
  return products.usingPromotion && !products.promotion.selectedPromotion;
};

export const discountReasonEntered = (state: PaperworkSubset) => {
  const { products } = state.paperwork.agreementManagement;
  const hasDiscount = products.items.some(({ discounts }) => discounts.length > 0);
  const { discountReason } = products.outOfPolicyAcknowledged;

  return hasDiscount && discountReason && discountReason.length > 0;
};

export const allDiscountsDateValid = (state: PaperworkSubset) =>
  !getProductsReservation(state).some(({ discounts }) =>
    discounts.some(({ errors: { startDate, endDate } }) => startDate || endDate)
  );

export const allDiscountsAmountValid = (state: PaperworkSubset) =>
  !getProductsReservation(state).some(({ discounts }) =>
    discounts.some(({ errors: { amount } }) => amount)
  );

export const allDiscountsAmountInPolicy = (state: PaperworkSubset) =>
  !getProductsReservation(state).some(({ discounts }) =>
    discounts.some(({ errors: { outOfPolicy } }) => outOfPolicy)
  );

export const allDiscountValids = (state: PaperworkSubset) =>
  allDiscountsDateValid(state) && allDiscountsAmountValid(state);

export const allReservationDiscountValids = createSelector(
  [getProductDiscounts],
  discounts =>
    !discounts?.some(({ errors: { startDate, endDate, amount } }) => startDate || endDate || amount)
);

export const allTermValids = (state: PaperworkSubset) =>
  !getProductsReservation(state).some(({ terms }) =>
    terms.some(({ errors: { startDate, endDate, amount } }) => startDate || endDate || amount)
  );

export const suggestionByReservation = (state: PaperworkSubset, reservationUuid) =>
  state.paperwork.discountsApi.result[reservationUuid].promotionSuggestions;

const getProducts = (state: PaperworkSubset) => state.paperwork.agreementManagement.products;

export const hasNoZeroDiscounts = createSelector([getProducts], products => {
  return products.items.some(({ discounts, terms }) => {
    const termDiscounts = discounts.filter(({ termId }) => termId === terms[0].id);
    return termDiscounts.some(({ amount }) => Number(amount) > 0);
  });
});

export const invalidChanges = (state: PaperworkSubset) =>
  (haveProductDiscountsChanged(state) && !allDiscountsDateValid(state)) ||
  !allDiscountsAmountValid(state) ||
  (!allDiscountsAmountInPolicy(state) && !outOfPolicyAcknowledged(state)) ||
  !(haveProductDiscountsChanged(state) || haveProductReservationsChanged(state)) ||
  (haveProductTermsChanged(state) && !allTermValids(state)) ||
  (haveProductTermsChanged(state) && hasZeroLengthTerm(state)) ||
  hasIncompleteAgreementUpload(state) ||
  (hasNoZeroDiscounts(state) && !discountReasonEntered(state)) ||
  promotionCodeNotSelected(state) ||
  missingServiceCloudNumber(state);

export const getSuggestion = (products, termLength, contractUuid) =>
  products.discountSuggestions.suggestions[contractUuid][termLength].find(
    option => option.variant === 'default'
  );

export const getMaxSuggestedDiscount = (products, contractUuid, termLength) => {
  const suggestion = products.discountSuggestions.suggestions[contractUuid]?.[termLength]?.[0];
  const usingPromotion = products.usingPromotion;

  if (termLength && suggestion && !usingPromotion) {
    return Number(suggestion.maxDiscount);
  }
  return 1;
};

export const getRecommendedAmount = (state: PaperworkSubset) => (termLength, contractUuid) =>
  createSelector(
    getProducts,
    products => getSuggestion(products, termLength, contractUuid)?.averageDiscount
  )(state);

const getReservation = (state: PaperworkSubset, props: { productId: string }) => {
  const reservationItem = getProductsReservationById(state, props);
  const marketPrice = getReservationMarketPrice(state, props);

  return {
    moveInDate: reservationItem.productReservation.startDate.value,
    moveOutDate: reservationItem.productReservation.endDate.value,
    prorated: reservationItem.proratedPrice,
    regularPrice: reservationItem.productReservation.chargePrice,
    marketPrice,
  };
};

const getPreviousAgreementOfficeInfo = (state: PaperworkSubset, props) => {
  const previousAgreement = getPreviousAgreementById(state, props);
  if (previousAgreement) {
    return {
      moveInDate: previousAgreement.startDate.value,
      moveOutDate: previousAgreement.endDate.value,
      regularPrice: previousAgreement.price,
    };
  }

  return undefined;
};

const getOfficePrice = createSelector([getReservation], reservation => reservation.regularPrice);

export const getTermEndDate = createSelector([getTerm], term => term?.endDate);

export const getTermLength = createSelector([getTerm], (term: CommitmentTermAttrs) =>
  term?.startDate && term?.endDate
    ? differenceInCalendarMonths(parseISO(term.endDate), parseISO(term.startDate)) + 1
    : 0
);

const generateFeeRow = (startDate, endDate, type, price, termId, discount = 0) => ({
  startDate,
  endDate,
  type,
  price,
  discount,
  netFee: price - discount,
  termId,
});

export const isNextDayTo = (previousEndDate, currentStartDate) =>
  previousEndDate &&
  currentStartDate &&
  isEqual(addDays(parseISO(previousEndDate), 1), parseISO(currentStartDate));

export const getPreviousEndDate = (fees, termStartDate) => {
  let previousEndDate;
  if (fees.length && fees[fees.length - 1].endDate) {
    previousEndDate = fees[fees.length - 1].endDate;
  } else if (termStartDate) {
    previousEndDate = formatToISODate(addDays(parseISO(termStartDate), -1));
  }
  return previousEndDate;
};

export const isLastIdx = (idx: number, arr: Array<any>) => idx === arr.length - 1;

interface NewRange {
  startDate?: string;
  endDate?: string;
}

const getRelevantDiscounts = (
  discounts: Array<DiscountInput>,
  start?: string,
  end?: string
): Array<DiscountInput> => {
  const filteredDiscounts = discounts.filter(({ termId }) => termId).sort(byDateProp('startDate'));

  return subRange(filteredDiscounts, start, end);
};

interface OfficeReservation {
  moveInDate: string;
  moveOutDate: string;
  prorated: number;
  regularPrice: number;
  marketPrice: number;
}

const getBeginingOfCommitment = (period, term) =>
  (period?.startDate &&
    term?.startDate &&
    formatToISODate(maxDate([parseISO(period?.startDate), parseISO(term?.startDate)]))) ||
  term?.startDate;

const getEndOfCommitment = (period, term) =>
  (period?.endDate &&
    term?.endDate &&
    formatToISODate(minDate([parseISO(period?.endDate), parseISO(term?.endDate)]))) ||
  term?.endDate;

export const getFeesFromDiscounts = (
  discounts: Array<DiscountInput>,
  reservation: OfficeReservation,
  term?: CommitmentTermInputAttrs,
  period?: {
    startDate?: string;
    endDate?: string;
  }
): Array<MembershipFee> => {
  const standardFees: Array<MembershipFee> = [];

  if (discounts.length) {
    const beginningOfCommitment = getBeginingOfCommitment(period, term);
    const endOfCommitment = getEndOfCommitment(period, term);

    getRelevantDiscounts(discounts, beginningOfCommitment, endOfCommitment).reduce<
      Array<MembershipFee>
    >((fees, { startDate, endDate, amount, termId }, idx, src) => {
      if (startDate && endDate) {
        const itIsProrated = isProrated(startDate, endDate);
        let reservationPrice;
        if (itIsProrated) {
          reservationPrice = reservation.prorated;
        } else if (termId === 'new_term_id') {
          reservationPrice = reservation.marketPrice;
        } else {
          reservationPrice = reservation.regularPrice;
        }

        const previousEndDate = getPreviousEndDate(fees, beginningOfCommitment);

        if (!itIsProrated && !isNextDayTo(previousEndDate, startDate)) {
          fees.push(
            generateFeeRow(
              formatToISODate(addDays(parseISO(previousEndDate), 1)),
              formatToISODate(addDays(parseISO(startDate), -1)),
              MEMBERSHIP_FEE_TYPE.NO_DISCOUNT,
              reservationPrice,
              termId
            )
          );
        }

        fees.push(
          generateFeeRow(
            startDate,
            itIsProrated ? formatToISODate(lastDayOfMonth(parseISO(endDate))) : endDate,
            itIsProrated ? MEMBERSHIP_FEE_TYPE.PRORATED : MEMBERSHIP_FEE_TYPE.REGULAR,
            reservationPrice,
            termId,
            amount
          )
        );

        if (
          isLastIdx(idx, src) &&
          endOfCommitment &&
          isBefore(parseISO(endDate), parseISO(endOfCommitment))
        ) {
          fees.push(
            generateFeeRow(
              formatToISODate(addDays(parseISO(endDate), 1)),
              formatToISODate(parseISO(endOfCommitment)),
              MEMBERSHIP_FEE_TYPE.NO_DISCOUNT,
              reservationPrice,
              termId
            )
          );
        }
      }

      return fees;
    }, standardFees);

    discounts
      .filter(({ termId }) => !termId)
      .sort(byDateProp('startDate'))
      .forEach(({ startDate, endDate, amount, termId }) => {
        if (startDate && endDate) {
          standardFees.push(
            generateFeeRow(
              startDate,
              endDate,
              MEMBERSHIP_FEE_TYPE.NO_COMMITMENT,
              reservation.regularPrice,
              termId,
              amount
            )
          );
        } else {
          standardFees.push(
            generateFeeRow(
              startDate,
              endDate,
              MEMBERSHIP_FEE_TYPE.NO_COMMITMENT_MONTH_TO_MONTH,
              reservation.regularPrice,
              termId,
              amount
            )
          );
        }
      });
    return standardFees;
  }

  if (
    !isBefore(parseISO(reservation.moveInDate), new Date()) &&
    !isFirstDayOfMonth(parseISO(reservation.moveInDate))
  ) {
    standardFees.push({
      startDate: reservation.moveInDate,
      endDate: formatToISODate(lastDayOfMonth(parseISO(reservation.moveInDate))),
      type: MEMBERSHIP_FEE_TYPE.PRORATED,
      price: reservation.prorated,
      termId: term?.id,
      discount: 0,
      netFee: reservation.prorated,
    });
  }

  return standardFees;
};

const getFeesByTerm = (
  discounts,
  reservation: OfficeReservation,
  terms: Array<CommitmentTermAttrs>,
  period: Range
) => {
  const fees = terms.reduce<Array<MembershipFee>>((feesByTerm, term) => {
    const termDiscounts = discounts.filter(({ termId }) => termId === term.id);

    feesByTerm.push(...getFeesFromDiscounts(termDiscounts, reservation, term, period));

    return feesByTerm;
  }, []);

  return fees;
};

export const getMembershipFees = createSelector(
  [getProductDiscounts, getReservation, getTerm, getIsPromotionDiscountPerpetuating],
  (
    discounts,
    reservation: OfficeReservation,
    term: CommitmentTermAttrs,
    isPromotionDiscountPerpetuating: boolean
  ) => {
    const fees = getFeesFromDiscounts(discounts, reservation, term);
    if (!reservation.moveOutDate) {
      const reservationPrice = term ? reservation.marketPrice : reservation.regularPrice;

      const discount =
        isPromotionDiscountPerpetuating && fees.length > 0 ? fees[fees.length - 1].discount : 0;
      const netFee = reservationPrice - discount;
      fees.push({
        startDate: '',
        endDate: '',
        type: MEMBERSHIP_FEE_TYPE.MONTH_TO_MONTH,
        price: reservationPrice,
        termId: MEMBERSHIP_FEE_TYPE.MONTH_TO_MONTH,
        discount,
        netFee,
      });
    }

    return fees;
  }
);

export const getPreviousAgreementMembershipFees = createSelector(
  [getPreviousAgreementDiscounts, getPreviousAgreementOfficeInfo, getCurrentTerms],
  getFeesByTerm
);

export const getPreviousAgreementRemainingMembershipFees = createSelector(
  [
    getRemainingDiscounts,
    getPreviousAgreementOfficeInfo,
    getPreviousAgreementActiveTerm,
    getRemainingPeriod,
  ],
  getFeesFromDiscounts
);

export const getPreviousAgreementMembershipFeesByTerm = createSelector(
  [
    getPreviousAgreementDiscounts,
    getPreviousAgreementOfficeInfo,
    getCurrentTerms,
    getRemainingPeriod,
  ],
  getFeesByTerm
);

export const hasOutOfPolicyDiscounts = createSelector(
  [getProducts, getUsingPromotion],
  (products, isUsingPromotion) => {
    if (isUsingPromotion) return;
    return products.items.some(({ discounts, terms, productReservation }) => {
      if (!discounts?.length || !terms?.length) {
        return false;
      }
      const termDiscounts = discounts.filter(({ termId }) => termId === terms[0].id);

      const maxDiscount = getMaxSuggestedDiscount(
        products,
        productReservation.uuid || productReservation.id,
        terms[0].length.value
      );

      return getDiscountsAmountErrors(
        termDiscounts,
        maxDiscount * terms[0].length.value * productReservation.chargePrice,
        productReservation.chargePrice
      ).some(({ errors: { outOfPolicy } }) => outOfPolicy);
    });
  }
);

export const getDiscountReasonForOutOfPolicyProductID = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.outOfPolicyAcknowledged.discountReason;

export const getOutOfPolicyAcknowledgment = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.outOfPolicyAcknowledged;

const getAccumulatedDiscount = (acc: number, { startDate, endDate, amount }: DiscountInput) => {
  let optionLength = 1;
  if (startDate && endDate && isBefore(parseISO(startDate), parseISO(endDate))) {
    optionLength = eachMonthOfInterval({
      start: parseISO(startDate),
      end: parseISO(endDate),
    }).length;
  }

  return acc + amount * optionLength;
};

export const getAverageDiscountAmountOnTerm = (
  discounts: Array<DiscountInput>,
  termLength: number
) => {
  if (discounts?.length) {
    const accumulatedDiscountAmount = discounts.reduce<number>(getAccumulatedDiscount, 0);
    const length = termLength || 1;
    return accumulatedDiscountAmount / length;
  }
  return 0;
};

export const getAverageDiscountPercentageOnTerm = (
  discounts: Array<DiscountInput>,
  termLength: number,
  officePrice: number
) => {
  if (discounts?.length) {
    const accumulatedDiscountAmount = discounts.reduce<number>(getAccumulatedDiscount, 0);
    const length = termLength || 1;
    return Math.trunc(((accumulatedDiscountAmount / length) * 100) / officePrice);
  }
  return 0;
};

export const getAverageDiscountPercentage = createSelector(
  [getProductDiscounts, getTermLength, getOfficePrice],
  getAverageDiscountPercentageOnTerm
);

export const getAverageDiscountAmount = createSelector(
  [getProductDiscounts, getTermLength, getOfficePrice],
  getAverageDiscountAmountOnTerm
);

const getContractUuid = (_, { contractUuid }: { contractUuid: string }) => contractUuid;

export const buildDiscountAmountSuggestion = (acc, { averageDiscount, maxDiscount, variant }) => {
  const value = Math.floor(Number(averageDiscount) * 100);
  const suggestion = {
    key: String(value),
    label: '',
    value,
  };
  if (!acc[value]) {
    if (averageDiscount === maxDiscount) {
      suggestion.label = 'max';
      acc[value] = suggestion;
    } else if (variant === SuggestionVariant.DEFAULT) {
      suggestion.label = 'suggested';
      acc[value] = suggestion;
    }
  }
  return acc;
};

export const getMaxDiscountSuggestedByTermLength = (
  products: ProductsReservationState,
  contractUuid: string
): Hash<number> => {
  const suggestions = products.discountSuggestions.suggestions[contractUuid];

  return Object.keys(suggestions).reduce<Hash<number>>((acc, termLength: string) => {
    acc[termLength] = suggestions[termLength][0].maxDiscount * 100;
    return acc;
  }, {});
};

export const getMaxDiscountByTermLength = createSelector(
  [getProducts, getContractUuid],
  getMaxDiscountSuggestedByTermLength
);

export const getError = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.error;

export const suggestionLoadedForTerm = (
  state: PaperworkSubset,
  reservationUuid: string,
  termLength: number
) =>
  state.paperwork.agreementManagement.products.discountSuggestions.suggestions[reservationUuid]?.[
    termLength
  ];

export const getPreviousAgreement = createSelector(
  [getPreviousAgreementById, getPreviousAgreementActiveTerm],
  (agreement: PreviousAgreement, activeTerm: CommitmentTermAttrs) => {
    if (agreement) {
      if (activeTerm) {
        const activeDiscounts = agreement.discounts.filter(
          ({ termId }) => termId === activeTerm.id
        );
        const avgDiscountAmount = getAverageDiscountAmountOnTerm(
          activeDiscounts,
          activeTerm.length
        );

        return {
          start: formatToUSAWithSlashes(parseISO(activeTerm.startDate)),
          end: formatToUSAWithSlashes(parseISO(activeTerm.endDate)),
          price: agreement.price,
          netPrice: agreement.price - avgDiscountAmount,
          avgDiscount: (avgDiscountAmount * 100) / agreement.price,
          localeInfo: agreement.localeInfo,
        };
      }
      return {
        price: agreement.price,
        localeInfo: agreement.localeInfo,
      };
    }
    return null;
  }
);

export const doesNewTermOverlaps = createSelector(
  [getPreviousAgreementById, getTerm],
  (previousAgreement: PreviousAgreement, term: CommitmentTermAttrs) =>
    previousAgreement?.terms.length &&
    term?.startDate &&
    isBefore(
      parseISO(term?.startDate),
      parseISO(previousAgreement.terms[previousAgreement.terms.length - 1].endDate.value)
    )
);

export const getHoursUntilExpiration = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.configurations.hoursUntilExpiration;

export const getAgreementAnalyticsData = (state: PaperworkSubset) => ({
  ...state.paperwork.agreementManagement.products.items.map(
    ({ productReservation, discounts, terms }) => ({
      deskCount:
        state.paperwork.agreementManagement.products.officeInfo?.[productReservation.reservableUuid]
          ?.seatCount,
      type: productReservation.type,
      startDate: productReservation.startDate.value,
      endDate: productReservation.endDate.value,
      reservationUuid: productReservation.uuid,
      price: productReservation.price,
      chargePrice: productReservation.chargePrice,
      discounts,
      terms,
    })
  ),
});

export const getIsSingleAgreement = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.singleAgreement;

export const shouldShowDiscounts = (state: PaperworkSubset, { flow }) =>
  flow === PaperworkFlow.AMENDMENT ||
  state.paperwork.agreementManagement.products.discountType === DiscountType.CT_DISCOUNT;

export const getLocationData = (state: PaperworkSubset) => {
  const { officeInfo } = state.paperwork.agreementManagement.products;

  if (!officeInfo) {
    return;
  }

  const firstOfficeInfoKey = Object.keys(officeInfo)[0];
  const locationData = officeInfo?.[firstOfficeInfoKey].location;

  return {
    uuid: locationData.uuid,
    name: locationData.name,
    city: locationData.city,
  };
};

export const getSelectedOffices = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.items.map(
    ({ productReservation }) => productReservation.reservableUuid
  );

export const getCountry = (state: PaperworkSubset) => {
  const { products } = state.paperwork.agreementManagement;
  const reservableUuid = products.items[0].productReservation.reservableUuid;
  const officeInfo = products.officeInfo;

  if (!officeInfo) {
    return;
  }

  return officeInfo[reservableUuid].location.country;
};

export const getTotalSetupFeeCost = (state: PaperworkSubset) => {
  return state.paperwork.agreementManagement.products.items.reduce<number>(
    (totalSetupFee, { setupFee }) => (setupFee ? setupFee + totalSetupFee : totalSetupFee),
    0
  );
};

export const getIsPromotion = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.usingPromotion;

export const getSelectedApprover = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.outOfPolicyAcknowledged.approver;

export const getAppliedPromoCode = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.outOfPolicyAcknowledged.appliedPromoCode;

export const getSelectedOption = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.products.outOfPolicyAcknowledged.rationale;

export const shouldSendForApproval = (state: PaperworkSubset) =>
  !!state.paperwork.agreementManagement.products.outOfPolicyAcknowledged.approver;
