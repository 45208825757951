import React from 'react';
import cn from 'classnames';

import { Location as LocationType } from 'store/modules/siLocations';

import Section from './components/section';
import styles from './locations.scss';

export const NO_RESULTS_TEXT = 'No results found, try adjusting the query.';

type Props = Readonly<{
  nearbyLocations: Array<LocationType>;
  onClickNearbyLocation: (arg0: LocationType) => void;
  onClickRecentLocation: (arg0: LocationType) => void;
  onClickSearchResult: (arg0: LocationType) => void;
  query: string;
  recentLocations: Array<LocationType>;
  searchResults: Array<LocationType>;
  selectedIdx: number;
  showSearchResults: boolean;
}>;

const Locations = ({
  showSearchResults,
  selectedIdx,
  query,
  searchResults,
  onClickNearbyLocation,
  onClickRecentLocation,
  onClickSearchResult,
  recentLocations,
  nearbyLocations,
}: Props) => (
  <div className={cn(styles.locations, { [styles.searchResults]: showSearchResults })}>
    {showSearchResults ? (
      <Section
        emptyText={NO_RESULTS_TEXT}
        locations={searchResults}
        selectedIdx={selectedIdx}
        onClickItem={onClickSearchResult}
        query={query}
      />
    ) : (
      <>
        {recentLocations.length > 0 && (
          <Section
            headerText="Recent"
            locations={recentLocations}
            selectedIdx={selectedIdx}
            onClickItem={onClickRecentLocation}
            query={query}
          />
        )}

        <Section
          headerText="Nearby"
          locations={nearbyLocations}
          selectedIdx={selectedIdx}
          startPosition={recentLocations.length}
          onClickItem={onClickNearbyLocation}
          query={query}
        />
      </>
    )}
  </div>
);
export default Locations;
