// Constants for workflows
export const JAPAN_PASSPORT_PRODUCT_UUID = 'ccbb3926-43a0-4922-bd54-97abb88981bc';
export const JAPAN_PASSPORT_TOS_JPN =
  'https://wework-legal-agreements.s3.amazonaws.com/JPN%2BPassport%2BTerms%2Band%2BConditions%2BSept%2B2020.pdf';

export const PRIVATE_ACCESS_PRODUCT_UUID = '38a7f5e7-c39b-49e9-8a43-80da3f2838a6';
export const GLOBAL_ACCESS_PRODUCT_UUID = 'ca04dfa5-b8f4-4c80-b36e-f9547a23f654';
export const GLOBAL_ACCESS_FLEX_PRODUCT_UUID = 'b067dd33-83b9-442d-96b5-6290cba60e14';
export const GLOBAL_ACCESS_PAY_PER_USE_PRODUCT_UUID = '26933953-b66b-45c6-9f03-e1ed2646016c';
export const GLOBAL_ACCESS_SAKSWORKS_PRODUCT_UUID = '494cd758-db87-4e0d-b9c3-2adfd9199c7e';
export const GLOBAL_ACCESS_IVANHOE_PRODUCT_UUID = 'e45bd65c-4c73-4a97-9e1e-9fc74d8a643c';
export const GLOBAL_ACCESS_BASIC_PRODUCT_UUID = 'cb51248d-76b2-4b36-8c5e-8be1c68394b1';
export const BUSINESS_ADDRESS_PRODUCT_UUID = '05581cbf-c427-43eb-b226-35de1d04b086';
export const BUSINESS_ADDRESS_RESOURCE_UUID = 'cb00a655-6878-4c53-acf0-02008864ff11';

export enum AddProductOptions {
  ADD_OFFICES = 'addOffices',
  PRIVATE_ACCESS = 'workplaceSubscription',
  GLOBAL_ACCESS = 'globalAccess',
  GLOBAL_ACCESS_FLEX = 'globalAccessFlex',
  GLOBAL_ACCESS_PAY_PER_USE = 'globalAccessPayPerUse',
  GLOBAL_ACCESS_SAKSWORKS = 'globalAccessSaksworks',
  GLOBAL_ACCESS_IVANHOE = 'globalAccessIvanhoe',
  GLOBAL_ACCESS_BASIC = 'globalAccessBasic',
  JAPAN_PASSPORT = 'japanPassport',
  PXWE = 'pxwe',
  SS_BUSINESS_ADDRESS = 'ssBusinessAddress',
}

export const ADD_PRODUCT_FORM_LABELS = {
  [AddProductOptions.ADD_OFFICES]: 'Offices',
  [AddProductOptions.PRIVATE_ACCESS]: 'Workplace Subscription',
  [AddProductOptions.GLOBAL_ACCESS]: 'All Access - Plus',
  [AddProductOptions.GLOBAL_ACCESS_FLEX]: 'All Access - Dynamic',
  [AddProductOptions.GLOBAL_ACCESS_PAY_PER_USE]: 'All Access - Pay Per Use',
  [AddProductOptions.GLOBAL_ACCESS_BASIC]: 'All Access - Basic',
  [AddProductOptions.JAPAN_PASSPORT]: 'Japan Passport',
  [AddProductOptions.GLOBAL_ACCESS_IVANHOE]: 'Ivanhoé Cambridge Membership',
  [AddProductOptions.PXWE]: 'PxWE',
  [AddProductOptions.GLOBAL_ACCESS_SAKSWORKS]: 'SaksWorks Membership',
  [AddProductOptions.SS_BUSINESS_ADDRESS]: 'Business Address',
};
