import { validate as uuidValidate } from 'uuid';

import config from 'config';

import { ErrorName } from './constants';

function messageHandler(status: number, statusText: string, domain: string): string {
  return `${status} (${new URL(domain).origin}) - ${statusText}`;
}

class GeneralInvalidError extends Error {
  name: string;
  componentName: string;

  constructor(componentName: string, message: string, customMessage?: string) {
    super();
    this.name = ErrorName.GeneralInvalidError;
    this.componentName = componentName;
    this.message = customMessage ? `${customMessage} - ${message}` : message;
  }
}

class ApiError extends Error {
  name: string;
  status: number;
  statusText: string;
  response: unknown;
  url: string;

  constructor(
    status: number,
    statusText: string,
    response: unknown,
    message: string,
    url?: string
  ) {
    super();
    this.name = ErrorName.ApiError;
    this.status = status;
    this.statusText = statusText;
    this.response = response;
    this.message = message;
    if (url) this.url = url;
  }
}

class ParseError extends Error {
  name: string;
  status: number;
  statusText: string;
  response: {};

  constructor(status: number, statusText: string, response: {}, message?: string) {
    super();
    this.name = ErrorName.ParseError;
    this.status = status;
    this.statusText = statusText;
    this.response = response;
    this.message = message || this.getRollbarMessage();
  }
  getRollbarMessage = (): string => {
    return 'Error parsing response';
  };
}

class NetworkError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = ErrorName.NetworkError;
  }
  getRollbarMessage = (): string => {
    return 'Network Error during fetch';
  };
}

class FallbackError extends Error {
  name: string;
  status: number;
  url: string;
  urlName: string;
  endPointUrl: string;
  xRequestId?: string | null;

  constructor(status: number, url: string, message?: string, xRequestId?: string) {
    super();
    this.name = ErrorName.FallBackError;
    this.status = status;
    this.url = url;
    this.urlName = this.getUrlName(url);
    this.message = message || this.getRollbarMessage();
    this.endPointUrl = this.getEndPointUrl(url);
    this.xRequestId = xRequestId;
  }
  getRollbarMessage = (): string => {
    return 'Fallback error response';
  };

  getUrlName = (url: string): string => {
    if (url) {
      if (url.includes(config.spaceman.uri)) {
        return 'spaceman';
      } else if (url.includes(config.mena.uri)) {
        return 'mena';
      } else if (url.includes(config.id.uri)) {
        return 'ID';
      } else if (url.includes(config.operatorService.uri)) {
        return 'operator';
      }
    }
    return '';
  };

  getEndPointUrl(url: string) {
    let endPointUrl = '';

    try {
      const urlWithOutParam = new URL(url)?.pathname?.split('?')[0]?.split('/');
      if (urlWithOutParam) {
        urlWithOutParam.forEach(element => {
          endPointUrl += !uuidValidate(element) ? `${element}/` : '{UUID}/';
        });
      }
    } catch (error) {
      endPointUrl = 'undefined';
    }

    return endPointUrl;
  }
}

type ErrorContainer = {
  status: number;
  message: string;
  type: string;
  url: string;
  xRequestId?: string;
};

// Keep original error information.
// See: https://stackoverflow.com/questions/42754270/re-throwing-exception-in-nodejs-and-not-losing-stack-trace
function rebuildStackTrace(innerError: Error, message: string, stack: string = ''): string {
  const messageLines = (message.match(/\n/g) || []).length + 1;

  const previousStack = stack
    .split('\n')
    .slice(0, messageLines + 1)
    .join('\n');

  return `${previousStack}\n${innerError.stack}`;
}

class DispatchError extends Error {
  action: string;
  innerError: Error;

  constructor(msg: string, action: string, innerError: Error) {
    super(msg);
    this.name = ErrorName.DispatchError;
    this.action = action;
    this.innerError = innerError;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DispatchError);
    }

    this.stack = rebuildStackTrace(innerError, this.message, this.stack);
  }

  getRollbarMessage = (): string => {
    return 'Failure while dispatching action';
  };
}

export {
  messageHandler,
  GeneralInvalidError,
  ApiError,
  ParseError,
  NetworkError,
  DispatchError,
  FallbackError,
  ErrorContainer,
};
