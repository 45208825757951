export default {
  muted: '#CCC',
  darkMuted: '#888',
  dark: '#888',
  primary: '#3B82DD',
  primaryMuted: '#93C6E6',
  secondary: '#EAB352',
  tertiary: '#45B29D',
  success: '#45B29D',
  info: '#EAB352',
  danger: '#D4586A',
  dangerMuted: '#FAEEF0',
  darkBlue: '#2B3445',
  darkBlueMuted: '#2F446E',
  aluminum: '#cbd0db',
  leo1: '#ffa535',
  leo2: '#ffb75d',

  carbon: '#2b3445',
  cobalt: '#2f446e',
  lead: '#59698b',
  iron: '#97a1b6',
  tin: '#f5f6f8',
  silver: '#eee',
  platinum: '#fafafa',
  palladium: '#fcfcfc',

  gaia: '#3c49ef',
  terra: '#606aef',
  earth1: '#dfe1fc',
  earth2: '#ecedfe',
  earth3: '#f5f6fe',

  scorpio1: '#ff3d0e',
  scorpio2: '#ffd8cf',

  warning: '#CD1F3C',
  warningHover: '#ffd8cf',

  disabled: '#eee',

  hermes: '#63ccb7',
  mercury: '#7cffe5',

  mars: '#ffd8cf',
  aries: '#ff3d0e',

  inactiveBackground: '#F9F9F9',
  inactiveText: '#c4c4c4',
  inactiveAlert: '#bb2630',

  blue: '#5499C3',
  gray200: '#C2C2C2',
  gray30: '#F4F4F5',
  gray40: '#DBDBDB',
  gray70: '#8e8e8f',

  occupancyMapBlue: '#526384',
};
