import React from 'react';

type Props = Readonly<
  {
    focused: boolean;
  } & React.HTMLProps<HTMLDivElement>
>;

export default class ScrollIntoView extends React.Component<Props> {
  ref: { current: null | HTMLDivElement } = React.createRef();

  componentDidUpdate(prevProps: Props) {
    if (this.props.focused && !prevProps.focused && this.ref.current) {
      this.ref.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }

  render() {
    const { focused, ...props } = this.props;

    return <div {...props} ref={this.ref} />;
  }
}
