import { isUndefined, isNull } from 'lodash';

import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export const seatCountSerializer = (
  inventoryPayloadItem: InventoryPayloadItem
): InventoryAttribute | null | undefined => {
  const seatCount = inventoryPayloadItem.seatCount;
  // const workUnits = inventoryPayloadItem.workUnits;

  if (isUndefined(seatCount) || isNull(seatCount)) {
    return null;
  }

  const attribute = {
    value: '---',
    meta: null,
  };

  if (seatCount === 0) {
    return attribute;
  }

  // if (!isUndefined(workUnits) && !isNull(workUnits) && seatCount > workUnits) {
  //   return {
  //     value: `${workUnits}`,
  //     meta: null,
  //   };
  // }

  attribute.value = `${seatCount}`;
  return attribute;
};

export default seatCountSerializer;
