import defaultAvatar from 'images/defaultAvatar.svg';
import config from 'config';

import { UserInfo } from './index';

const baseAvatarUrl = `${config.mena.uri}/api/avatars`;
const isStorybook = window.env.SNAPSHOT;

export const defaultAvatarUrl = defaultAvatar;
export const createAvatarUrlById = (id: string) => `${baseAvatarUrl}/avatar_by_uuid?uuid=${id}`;
export const createAvatarUrlByEmail = (email: string) =>
  `${baseAvatarUrl}/avatar_by_email?email=${email}`;
export const createAvatarUrl = (userInfo?: UserInfo) => {
  if (!userInfo || isStorybook) {
    return defaultAvatar;
  }
  if (userInfo.id) {
    return createAvatarUrlById(userInfo.id);
  }
  if (userInfo.email) {
    return createAvatarUrlByEmail(userInfo.email);
  }
  return defaultAvatar;
};

export const sizes = Object.freeze({
  FLEXIBLE: 'flexible',
  NANO: 'nano',
  MICRO: 'micro',
  MICRO_TINY: 'microTiny',
  TINY: 'tiny',
  EXTRA_SMALL: 'extraSmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
});
