import { combineReducers } from 'redux';

import accounts, { State as AccountsState } from 'store/modules/accounts/accounts';
import usersByAccount, {
  State as UsersByAccountState,
} from 'store/modules/accounts/usersByAccount';
import usersByUuid, { State as UserByUuidState } from 'store/modules/accounts/usersByUuid';
import roles, { State as RoleByAccountState } from 'store/modules/accounts/rolesByAccount';

export interface State {
  accounts: AccountsState;
  usersByAccount: UsersByAccountState;
  usersByUuid: UserByUuidState;
  roles: RoleByAccountState;
}

export interface AccountsSubset {
  accounts: State;
}

export const reducer = combineReducers<State>({
  accounts,
  usersByAccount,
  usersByUuid,
  roles,
});

export default reducer;
