import React, {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  RefObject,
  useRef,
  useState,
} from 'react';
import { Icon, IconProps, IconSize } from '@wework/ray2';
import cn from 'classnames';

export interface TextFieldProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  customValidationMessage?: string;
  customValidation?: (value: string) => boolean;
  className?: string;
  forwardedRef?: RefObject<HTMLInputElement>;
  fillSpace?: boolean;
  icon?: IconProps;
  resetIcon?: IconProps;
  value?: string;
  onReset?: () => void;
}

export const SearchInput: FC<TextFieldProps> = React.forwardRef(
  (
    {
      required,
      placeholder,
      label,
      customValidationMessage,
      customValidation,
      id,
      disabled,
      className,
      fillSpace,
      icon,
      resetIcon,
      value: externalValue = '',
      onReset,
      ...props
    },
    forwardedRef: RefObject<HTMLInputElement>
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRef = forwardedRef || useRef<HTMLInputElement>(null);

    if (label === undefined || label?.length === 0) {
      // eslint-disable-next-line no-console
      console.warn(
        'Please pass in a label prop to ensure TextField conforms to accessibility standards. See https://www.w3.org/WAI/tutorials/forms/labels/'
      );
    }
    const [focused, setFocused] = useState(false);
    const [touched, setTouched] = useState(false);
    const [valid, setValid] = useState(true);
    const [warningMessage, setWarningMessage] = useState('Input not valid');

    const isRequired = value => {
      if (touched && value.length === 0) {
        setValid(false);
        setWarningMessage('This is a required field');
      }
    };

    const handleOnFocus = event => {
      setFocused(true);
      setTouched(true);
      setValid(true);
      if (props.onFocus) {
        props.onFocus(event);
      }
    };

    const handleOnBlur = event => {
      setFocused(false);
      if (required) {
        isRequired(event.target.value);
      }
      if (props.onBlur) {
        props.onBlur(event);
      }
      if (customValidation && !customValidation(event.target.value)) {
        setValid(false);
        setWarningMessage(customValidationMessage || '');
      }
    };

    const handleOnChange = event => {
      if (props.onChange) {
        props.onChange(event);
      }
    };

    const isSmallIcon = iconObj => {
      return (iconObj && !iconObj?.size) || iconObj?.size === IconSize.SMALL;
    };

    const triggerInputEvent = (element, newValue: string) => {
      const lastValue = element.value;
      element.value = newValue;
      const event = new Event('input', { bubbles: true });
      const tracker = element._valueTracker;

      if (tracker) {
        tracker.setValue(lastValue);
      }

      element.dispatchEvent(event);
    };

    const onResetIconClick = () => {
      triggerInputEvent(inputRef.current, '');
      onReset?.();
    };

    const inputClasses = cn([
      'placeholder-gray-40',
      'text-body',
      'text-2xs',
      valid ? 'text-black' : 'text-red-46',
      valid ? 'border-gray-70' : 'border-red-46',
      !icon && 'p-4',
      isSmallIcon(icon) && 'pl-10',
      icon?.size === IconSize.LARGE && 'pl-12',
      isSmallIcon(resetIcon) && 'pr-10',
      resetIcon?.size === IconSize.LARGE && 'pr-12',
      'border',
      'rounded-sm',
      'focus:outline-none',
      'focus:border-primary',
      'disabled:border-gray-70',
      'disabled:bg-gray-97',
      'disabled:text-gray-40',
      'top-2.5',
      'h-[56px]',
      className,
    ]);

    const labelClasses = cn([
      // eslint-disable-next-line no-nested-ternary
      focused ? 'text-primary' : valid ? 'text-gray-40' : 'text-red-46',
      { 'sr-only': !label },
      'font-mono',
      'absolute',
      '-top-2.5',
      'left-3',
      'disabled:text-gray-40',
      'bg-white',
      'text-body',
      'text-4xs',
      'text-center',
      'whitespace-nowrap',
      'px-1',
    ]);

    const baseIconClasses = 'flex absolute justify-center items-center h-full pl-sm';

    const iconClasses = cn(baseIconClasses, 'pointer-events-none');
    const resetIconClasses = cn(baseIconClasses, 'right-0', 'pr-sm');

    return (
      <div
        className={cn('relative', { 'h-full w-full': fillSpace })}
        data-testid="ray-search-field"
      >
        <div className={cn('inline-flex relative', { 'h-full w-full': fillSpace })}>
          {icon && (
            <div className={iconClasses}>
              <Icon size={icon.size || IconSize.SMALL} {...icon} />
            </div>
          )}

          <input
            ref={inputRef}
            required={required}
            data-testid="ray-search-field-input"
            className={inputClasses}
            placeholder={placeholder}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={externalValue}
            id={id}
            disabled={disabled}
            onChange={handleOnChange}
            {...props}
          />

          {resetIcon && externalValue.length > 0 && (
            <div className={resetIconClasses}>
              <Icon
                size={resetIcon.size || IconSize.SMALL}
                {...resetIcon}
                className={cn(resetIcon?.className, 'cursor-pointer')}
                onClick={onResetIconClick}
              />
            </div>
          )}
        </div>

        <label htmlFor={id} className={labelClasses}>
          {label}
        </label>
        {!valid && <p className="text-body p-1 text-red-46"> {warningMessage}</p>}
      </div>
    );
  }
);
