import { handleActions, Action } from 'redux-actions';
import { keyBy } from 'lodash';

import {
  SpacemanApiResourceCategoriesResponse,
  ResourceCategory,
} from 'features/companies/edit/redux/resourceCategories/types';
import {
  FETCH_RESOURCE_CATEGORIES,
  FETCH_RESOURCE_CATEGORIES_SUCCESS,
  FETCH_RESOURCE_CATEGORIES_FAIL,
} from 'features/companies/edit/redux/resourceCategories/constants';

interface State {
  loaded: boolean;
  bySystemName: Hash<ResourceCategory>;
  loading: boolean;
  error: boolean | null | undefined;
}

export interface ResourceCategoriesSubset {
  resourceCategories: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  bySystemName: {},
  error: null,
};

const keyBySystemName = (data: Array<ResourceCategory>): Hash<ResourceCategory> =>
  keyBy(data, item => item.system_name);

export const reducer = handleActions<State, any>(
  {
    [FETCH_RESOURCE_CATEGORIES]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_RESOURCE_CATEGORIES_SUCCESS]: (
      state: State,
      action: Action<SpacemanApiResourceCategoriesResponse>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      bySystemName: keyBySystemName(action.payload.result),
    }),

    [FETCH_RESOURCE_CATEGORIES_FAIL]: (state: State, action: Action<any>) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
