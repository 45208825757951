import { Action } from 'redux-actions';

import { createRequestReducer } from 'store/util/createRequestReducer';
import { camelCaseJson } from 'lib/util';

import { Suggestions } from './types';
import { requestActionTypes } from './actions';

const handlePayload = (
  response: Action<any>,
  previousResponse: Hash<Suggestions>
): Hash<Suggestions> => {
  const responsePayload: Hash<Suggestions> = camelCaseJson(
    response.payload?.result?.contracts ?? {}
  );

  const itemUuid = Object.keys(responsePayload)[0];

  const previousSuggestions = previousResponse[itemUuid]?.promotionSuggestions ?? [];
  const suggestions = responsePayload[itemUuid]?.promotionSuggestions ?? [];

  return {
    ...previousResponse,
    [itemUuid]: {
      ...responsePayload[itemUuid],
      promotionSuggestions: [...previousSuggestions, ...suggestions],
    },
  };
};

const reducer = createRequestReducer<Hash<Suggestions>, any>(requestActionTypes, {}, handlePayload);

export default reducer;
