import cc from 'store/util/createReduxConstant';

export const TOAST = cc('TOAST');

export enum ToastType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARN = 'warn',
  CLEAR = 'clear',
}
