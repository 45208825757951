import { set, merge } from 'dot-prop-immutable';
import { omit, keyBy, get } from 'lodash';

import { EntityActionMeta, ActionType, EntityAction } from '../types';

const initialState = {};

const dataReducer = (state: {} = initialState, action: EntityAction) => {
  if (!action.meta || !action.meta.success) {
    return state;
  }

  const { entity } = action.meta;

  if (!entity) {
    return state;
  }

  const {
    actionType,
    idAttribute,
  }: {
    actionType: ActionType;
    idAttribute: string;
  } = entity as EntityActionMeta;

  if (actionType === 'destroy') {
    return omit(state, get(entity.params, 'id'));
  }

  const id = get(action.payload, idAttribute);

  if (actionType === 'find' || actionType === 'create') {
    return set(state, id, action.payload);
  }

  if (actionType === 'update') {
    const updateId = id || get(entity.params, 'id');
    return merge(state, updateId, action.payload);
  }

  if (actionType === 'query') {
    return {
      ...state,
      ...keyBy(action.payload, idAttribute),
    };
  }

  return state;
};

export default dataReducer;
