import React from 'react';
import cn from 'classnames';

import styles from './hint.scss';

type Props = Readonly<{
  size?: string;
  style?: Hash<string>;
  children?: React.ReactNode;
}>;

const Hint = ({ children, size, style }: Props) => (
  <div className={cn(styles.base, size && styles[`size-${size}`])} style={style}>
    {children}
  </div>
);

export default Hint;
