import {
  FETCH_INVENTORY_BY_UUIDS_SUCCESS,
  FETCH_INVENTORY_BY_UUIDS_FAIL,
} from 'features/inventorySearch/ducks/inventoryItem';
import { OfficeInfoAttrs } from 'features/paperwork/contracts-flow/types/officeInfo';
import { PPW_ERROR } from 'features/paperwork/contracts-flow/types/errors';

import { State } from '.';

const reducer = {
  [FETCH_INVENTORY_BY_UUIDS_SUCCESS]: (
    state: State,
    action: { payload: { inventory: Array<OfficeInfoAttrs> } }
  ) => ({
    ...state,
    loading: false,
    officeInfo: {
      ...state.officeInfo,
      [action.payload.inventory[0].uuid]: action.payload.inventory[0],
    },
  }),
  [FETCH_INVENTORY_BY_UUIDS_FAIL]: (state: State, action: { payload: { message: string } }) => ({
    ...state,
    loading: false,
    error: {
      message: action?.payload?.message,
      code: PPW_ERROR.INVENTORY,
    },
  }),
};

export default reducer;
