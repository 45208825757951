import React from 'react';
import { compose } from 'redux';
import cn from 'classnames';

import style from './style.scss';

type Props = {
  aliases: Array<string>;
};

interface State {
  showFullList: boolean;
}

class Aliases extends React.Component<Props, State> {
  state = { showFullList: false };

  toggleAliases = (): void => {
    this.setState(prevState => ({ showFullList: !prevState.showFullList }));
  };

  renderTruncatedList = (): Array<React.ReactElement> => {
    const truncatedList = this.props.aliases.slice(1);
    return truncatedList.map((alias, idx) => {
      return (
        <li key={`${alias}-${idx}`}>
          <p className="text-primary text-4xs">{alias}</p>
        </li>
      );
    });
  };

  renderCollapsedList = (aliases: Array<string>): React.ReactElement => {
    return (
      <>
        <ul className="text-primary">
          <li>
            <p className="text-primary text-4xs">{aliases[0]}</p>
          </li>
          {this.state.showFullList && this.renderTruncatedList()}
        </ul>
        <button className="text-primary text-4xs" onClick={this.toggleAliases} type="button">
          {this.state.showFullList ? 'Show Less' : `+ ${aliases.length - 1} more`}
        </button>
      </>
    );
  };

  renderList = (aliases: Array<string>): React.ReactElement => {
    return (
      <ul className="text-primary">
        {aliases.map((alias, idx) => {
          return (
            <li key={`${alias}-${idx}`}>
              <p className="text-primary text-4xs">{alias}</p>
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    return (
      <div className={cn('!items-baseline', style.aliasList)}>
        {this.props.aliases.length > 4
          ? this.renderCollapsedList(this.props.aliases)
          : this.renderList(this.props.aliases)}
      </div>
    );
  }
}
export default compose<React.ComponentType<Props>>()(Aliases);
