import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { pick } from 'lodash';
import { getAppNames, registerApplication, start } from 'single-spa';
import { NotifyProps, Location } from '@wework/ss-microfrontend-types';
import { BehaviorSubject } from 'rxjs';

import config from 'config';
import withToasts from 'components/decorators/withToasts';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { getUserInfoFromAuth } from 'features/auth/selectors';
import { getCurrentLocation } from 'store/selectors';
import { ReduxProps } from 'store/types';
import { pageView } from 'store/modules/page';
import { getExperiments } from 'store/middlewares/experimentsHelper';

import useAppDetails from './useAppDetails';
import { CURRENT_LOCATION_EVENT, ROUTER_TYPE_HASH, TO_DYNAMIC_PATH_REGEX } from './constants';
import dynamicProps, { getLocation, getExperimentGroups } from './dynamicProps';
import { APPS_ROUTES } from './routes';

const { System } = window;

type Params = {
  uuid: string;
};

type OwnProps = {} & NotifyProps;

type Props = Readonly<OwnProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>>;

const propsObservable = new BehaviorSubject({});

const registerApplications = async (props, setAppNames) => {
  const { default: importMap } = await System?.import(config.manifestUrl);
  const appNames =
    importMap?.imports && Object.keys(importMap.imports).filter(name => name in APPS_ROUTES);
  if (!appNames?.length) return;

  appNames.map(name =>
    registerApplication({
      name,
      app: () => System?.import(name),
      activeWhen: APPS_ROUTES[name].map(({ path }) => location => {
        const toDynamicPath = TO_DYNAMIC_PATH_REGEX.exec(path)?.[0] || path;
        return location?.hash?.includes(toDynamicPath);
      }),
      customProps: {
        ...props,
        domElementGetter: () => document.getElementById(name),
        // TODO: remove these once we get MFEs to use observables
        propsObservable,
        getAccessToken,
        getLocation,
        getExperimentGroups,
        router: ROUTER_TYPE_HASH, // let the MFE template know to use a hash router
      },
    })
  );

  setAppNames(getAppNames());
  start();
};

const SingleSpa = ({ pageView, ...props }: Props) => {
  const [appNames, setAppNames] = useState<string[] | null>(null);
  const { app, title } = useAppDetails();
  const location = useLocation();
  const { uuid } = useParams<Params>();

  useEffect(() => {
    propsObservable.next({
      ...props,
      getAccessToken,
      getLocation,
      getExperimentGroups,
    });
  }, [props]);

  useEffect(() => {
    registerApplications(props, setAppNames);
  }, []);

  // TODO: remove this once we confirm no MFEs use this
  useEffect(() => {
    dynamicProps.location = props.currentLocation as Location;
    if (app) dispatchEvent(new CustomEvent(CURRENT_LOCATION_EVENT));
  }, [props.currentLocation]);

  useEffect(() => {
    if (title)
      pageView({
        metadata: {
          params: {
            uuid: uuid || '',
          },
          currentLocation: pick(props.currentLocation || {}, ['uuid', 'name']),
        },
        name: title,
        path: location.pathname,
        title,
      });
  }, [title]);

  if (!appNames?.length) return null;

  return (
    <div>
      {appNames.map((appName, index) => (
        <div id={appName} key={index} />
      ))}
      {title && (
        <Helmet>
          <title>{`${title} | Spacestation`}</title>
        </Helmet>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentLocation: getCurrentLocation(state, {}),
  userInfo: getUserInfoFromAuth(state),
  experiments: getExperiments(state),
});

const mapDispatchToProps = {
  pageView,
};

export default compose(withToasts, connect(mapStateToProps, mapDispatchToProps))(SingleSpa);
