import { Button as DieterButton, ButtonProps } from '@wework/dieter-ui';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { withAnalytics } from 'lib/contextualAnalytics/hoc';
import { ClickActionProp, HoverActionProp } from 'lib/contextualAnalytics/hoc/actions';

type ActionProps = ClickActionProp & HoverActionProp;

const AnalyticableButton = withAnalytics<ButtonProps, ActionProps>({
  actions: ['click', { name: 'hover', trackByDefault: false }],
})(DieterButton);

export const Button = hoistNonReactStatics(AnalyticableButton, DieterButton);

export { ButtonContent, ButtonGroup, ButtonOr } from '@wework/dieter-ui';

export type {
  ButtonProps,
  StrictButtonProps,
  ButtonContentProps,
  StrictButtonContentProps,
  ButtonGroupProps,
  StrictButtonGroupProps,
  ButtonOrProps,
  StrictButtonOrProps,
} from '@wework/dieter-ui';
