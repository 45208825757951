import { handleActions } from 'redux-actions';

import config from 'config';
import { camelCaseJson } from 'lib/util';
import { ToastType } from 'store/modules/toasts/constants';
import { ActionType, Method } from 'store/util/createEntity/types';
import createEntity from 'store/util/createEntity';

import { Announcement, Category, PageState } from './types';

export const PER_PAGE = 10;

export const ANNOUNCEMENTS_KEY = 'past';
export const CATEGORIES_KEY = 'all';

type PastAnnouncementsSuccessAction = {
  type: string;
  meta: {
    response: {
      currentPage: number;
      totalPages: number;
    };
  };
};

const customActions = {
  past: {
    type: 'query' as ActionType,
    endpoint: '/admin/v1/announcements/all.json',
  },

  remove: {
    type: 'destroy' as ActionType,
    method: 'POST' as Method,
    endpoint: params => `/admin/v1/announcements/${params.id}/remove.json`,
    getMetaFromResponse: () => ({
      notification: {
        type: ToastType.SUCCESS,
        message: 'Notification removed!',
      },
    }),
  },
};

// Entities
export const Announcements = createEntity<Announcement, typeof customActions>('announcements', {
  baseUrl: config.announcements.uri,
  endpoint: '/admin/v1/announcements.json',
  getPayloadFromResponse: response => camelCaseJson(response.result),
  getMetaFromResponse: response => ({ response: camelCaseJson(response.meta) }),
  idAttribute: 'id',
  customActions,
});

export const AnnouncementCategories = createEntity<Category>('announcementCategories', {
  baseUrl: config.announcements.uri,
  endpoint: '/admin/v1/categories',
  getPayloadFromResponse: response => camelCaseJson(response.result),
  idAttribute: 'id',
});
// Reducer
export const initialState: PageState = {
  totalPages: 1,
};

const reducer = handleActions<PageState, any, any>(
  {
    [Announcements.constants.past.success]: (
      state: PageState,
      action: PastAnnouncementsSuccessAction
    ): PageState => ({
      ...state,
      totalPages: action.meta?.response?.totalPages,
    }),
  },
  initialState
);

// Action Creators

type fetchAllAnnouncementsOptions = {
  page?: number;
  category?: string;
  query?: string;
};

export const fetchAllAnnouncements = (
  locationUuid: string,
  opts: fetchAllAnnouncementsOptions = { page: 1 }
) =>
  Announcements.past({
    queryParams: {
      location_uuids: [locationUuid],
      page: opts.page || 1,
      per_page: PER_PAGE,
      query: opts.query,
      category_ids: [opts.category],
    },
    key: ANNOUNCEMENTS_KEY,
  });

type Body = {
  timezone: string;
  message?: string;
  all_floors: boolean;
  floor_uuids?: Array<string>;

  [key: string]: any;
};

const formatMessage = (body: Body) =>
  body.message ? { ...body, message: body.message.replace(/\r?\n/g, '\r\n') } : body;

export const removeAnnouncement = (id: string) => Announcements.remove(id);
export const createAnnouncement = (body: Body) =>
  Announcements.create({ body: formatMessage(body), key: ANNOUNCEMENTS_KEY });

export const fetchAnnouncementCategories = () =>
  AnnouncementCategories.query({ key: CATEGORIES_KEY });

export default reducer;
