import { combineReducers } from 'redux';

import listingsByBuilding, {
  State as ListingsByBuildingState,
} from 'features/alchemist/ducks/listingsByBuilding';
import listingProductsByProduct, {
  State as ListingProductsByProductState,
} from 'features/alchemist/ducks/listingProductsByProduct';

export interface ListingsSubset {
  listings: State;
}

export interface State {
  listingsByBuilding: ListingsByBuildingState;
  listingProductsByProduct: ListingProductsByProductState;
}

export const reducer = combineReducers<State>({
  listingsByBuilding,
  listingProductsByProduct,
});

export default reducer;
