import { Action } from 'redux-actions';

import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { createRequestAction } from 'store/util/createRequestAction';
import config from 'config';
import cc from 'store/util/createReduxConstant';

import SerializersMapWithItems from '../config/serializersMapWithItems';
import { getSerializedInventoryItems } from '../serializers/inventorySerializer';
import { InventoryItem, SearchResultPayload } from '../types';

export const FETCH_INVENTORY_BY_UUIDS = cc('FETCH_INVENTORY_BY_UUIDS');
export const FETCH_INVENTORY_BY_UUIDS_SUCCESS = cc('FETCH_INVENTORY_BY_UUIDS_SUCCESS');
export const FETCH_INVENTORY_BY_UUIDS_FAIL = cc('FETCH_INVENTORY_BY_UUIDS_FAIL');

const types: RequestActionTypes = [
  FETCH_INVENTORY_BY_UUIDS,
  FETCH_INVENTORY_BY_UUIDS_SUCCESS,
  FETCH_INVENTORY_BY_UUIDS_FAIL,
];

export const initialFetchResult: Array<InventoryItem> = [];

export const fetchInventoryItems = (uuids: string) => (
  dispatch: Dispatch<BaseAction>
): Promise<Action<SearchResultPayload>> =>
  dispatch(
    createRequestAction({
      endpoint: `${config.inventoryQuery.uri}/v1/inventory/${uuids}`,
      errorMessageFromJSON: (json: Partial<{ message: string }>) => json.message,
      method: 'GET',
      types,
    })
  );

export default createRequestReducer<Array<InventoryItem>, SearchResultPayload>(
  types,
  initialFetchResult,
  (res): Array<InventoryItem> => {
    if (Array.isArray(res.payload.inventory) && res.payload.inventory.length) {
      const { inventory } = res.payload;

      return getSerializedInventoryItems(inventory, SerializersMapWithItems);
    }

    return [];
  }
);
