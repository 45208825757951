import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION,
  FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_SUCCESS,
  FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION'));

export const [
  UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION,
  UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_SUCCESS,
  UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_FAIL,
] = createRequestConstantNames(cc('UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION'));
