import { handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';

import { SHOW_CONFIRM_MODAL, HIDE_CONFIRM_MODAL } from './constants';
import { ConfirmModalState, ShowConfirmModalPayload } from './types';

const initialState: ConfirmModalState = {
  isVisible: false,
  onDismiss: () => {},
  onSubmit: () => {},
  title: '',
  description: '',
  primaryButtonLabel: 'Confirm',
  secondaryButtonLabel: 'Cancel',
  isDanger: false,
};

const reducer = handleActions<ConfirmModalState, ShowConfirmModalPayload, undefined>(
  {
    [SHOW_CONFIRM_MODAL]: (
      state: ConfirmModalState,
      action: ActionWithPayload<ShowConfirmModalPayload>
    ) => ({
      ...state,
      ...action.payload,
      isVisible: true,
    }),
    [HIDE_CONFIRM_MODAL]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
