import {
  RSAA,
  getJSON,
  RSAASuccessType,
  RSAAFailureType,
  RSAARequestType,
  RSAARequestTypeDescriptor,
  RSAASuccessTypeDescriptor,
  RSAAFailureTypeDescriptor,
} from 'redux-api-middleware';

import {
  RSAAType,
  RequestActionTypes,
  Method,
  CRAResponseType,
  RequestActionHeaders,
} from 'store/types';
import { ApiError, messageHandler } from 'store/errors';
import { setHeaders } from 'lib/api/apiRules';

export type Params<Payload> = {
  endpoint: string;
  types: RequestActionTypes;
  isJson?: boolean;
  method?: Method;
  headers?: RequestActionHeaders;
  body?: any;
  token?: string;
  meta?: object;
  payloadFromJSON?: (json: Payload, meta?: object | null | undefined) => any;
  metaFromJSON?: (json: Payload, res: Response) => any;
  failureMetaFromErrorAndJSON?: (error: Error, json: Payload) => any;
  errorMessageFromJSON?: (json: Payload, res: Response) => string | null | undefined;
  isRequestSuccess?: (response: Response) => boolean;
};

const toRequestAction = (
  type: RSAASuccessType | RSAAFailureType | RSAARequestType
): RSAARequestTypeDescriptor | RSAASuccessTypeDescriptor | RSAAFailureTypeDescriptor => {
  if (typeof type === 'string' || typeof type === 'symbol') {
    return { type };
  }
  return type;
};

export const createRequestAction = <Payload = any>({
  endpoint,
  body,
  types,
  token,
  meta = {},
  method = 'GET',
  headers = {},
  isJson = true,
  payloadFromJSON = (json: Payload): any => json,
  metaFromJSON = (_json: Payload, _res: Response): undefined => undefined,
  failureMetaFromErrorAndJSON = (_err: Error, _json: Payload): undefined => undefined,
  errorMessageFromJSON = (_json: Payload): undefined => undefined,
  isRequestSuccess = (response: Response) => response.ok,
}: Params<Payload>): RSAAType<Payload> => {
  const [requestType, successType, failureType] = types.map(toRequestAction);

  const failurePayload = (_action, _state, { res, json }) => {
    const errorMessage = errorMessageFromJSON(json, res);

    return new ApiError(
      res.status,
      res.statusText,
      res,
      errorMessage || messageHandler(res.status, res.statusText, endpoint)
    );
  };
  const updatedHeaders = {
    Accept: 'application/json',
    ...headers,
  };

  if (isJson && !headers['Content-Type']) {
    updatedHeaders['Content-Type'] = 'application/json';
  }

  headers = setHeaders(endpoint, updatedHeaders);

  return {
    [RSAA]: {
      endpoint,
      method,
      body:
        ArrayBuffer.isView(body) || typeof body === 'string' || !isJson
          ? body
          : JSON.stringify(body),
      headers,
      ok: ({ res }: CRAResponseType<Payload>) => isRequestSuccess(res),
      fetch: async (input: RequestInfo, init?: RequestInit): Promise<CRAResponseType<Payload>> => {
        const res = await fetch(input, init);
        try {
          const json = await getJSON(res.clone());
          return { res, json };
        } catch (error) {
          // if the Json is invalid we want the response to reach the failure action
          return {
            res: new Response(JSON.stringify({ error: error.message }), {
              status: 500,
              headers: res.headers,
            }),
            json: null,
          };
        }
      },
      types: [
        {
          ...requestType,
          meta: {
            ...meta,
            ...requestType.meta,
            token: token || null,
          },
        },
        {
          ...successType,
          payload: (action, _state, { json }) => payloadFromJSON(json, action.meta),
          meta: (_action, _state, { res, json }) => ({
            ...meta,
            ...successType.meta,
            ...metaFromJSON(json, res),
          }),
        },
        {
          ...failureType,
          payload: failurePayload,

          /*
           * `craResponse` is optional, if the `res = fetch(...args)` itself failed.
           * `payload` will be overwritten by redux-api-middleware, so we only care to preserve `meta`
           */
          meta: (action, state, craResponse?: CRAResponseType<Payload>): object => {
            if (craResponse != null && craResponse.json != null) {
              const { res, json } = craResponse;
              const error = failurePayload(action, state, { res, json });

              return {
                ...meta,
                ...failureType.meta,
                ...failureMetaFromErrorAndJSON(error, json),
              };
            }
            return { ...meta, ...failureType.meta };
          },
        },
      ],
    },
  };
};

export default createRequestAction;
