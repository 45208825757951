import { handleActions } from 'redux-actions';

import {
  FETCH_NOTICE_PERIOD,
  FETCH_NOTICE_PERIOD_SUCCESS,
  FETCH_NOTICE_PERIOD_FAIL,
  UPDATE_NOTICE_PERIOD,
  UPDATE_NOTICE_PERIOD_SUCCESS,
  UPDATE_NOTICE_PERIOD_FAIL,
} from 'features/companies/edit/redux/noticePeriods/constants';

export interface State {
  submitError: boolean | null | undefined;
  submitting: boolean;
  fetchError: boolean | null | undefined;
  byReservationUuid: {};
  loading: boolean;
}

export interface NoticePeriodsSubset {
  noticePeriods: State;
}

export const initialState: State = {
  loading: false,
  fetchError: null,
  submitError: null,
  submitting: false,
  byReservationUuid: {},
};

export const reducer = handleActions<State, any>(
  {
    [UPDATE_NOTICE_PERIOD]: state => ({
      ...state,
      submitting: true,
      submitError: null,
    }),
    [UPDATE_NOTICE_PERIOD_SUCCESS]: (state, action) => ({
      ...state,
      submitting: false,
      submitError: null,
      byReservationUuid: {
        ...state.byReservationUuid,
        [action.payload.params.id]:
          action.payload.result === null ? 1 : action.payload.result.amount,
      },
    }),
    [UPDATE_NOTICE_PERIOD_FAIL]: (state, action) => ({
      ...state,
      submitting: false,
      submitError: action.error,
    }),

    [FETCH_NOTICE_PERIOD]: state => ({
      ...state,
      loading: true,
      fetchError: null,
    }),
    [FETCH_NOTICE_PERIOD_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      byReservationUuid: {
        ...state.byReservationUuid,
        [action.payload.params.id]:
          action.payload.result === null ? 1 : action.payload.result.amount,
      },
      fetchError: null,
    }),
    [FETCH_NOTICE_PERIOD_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      fetchError: action.error,
    }),
  },
  initialState
);

export default reducer;
