import { createRequestReducer } from 'store/util';
import { InventoryQueryResult } from 'features/inventory/redux/ignoreInventory/types';

import {
  QUERY_IQS_FOR_IGNORED,
  QUERY_IQS_FOR_IGNORED_FAIL,
  QUERY_IQS_FOR_IGNORED_SUCCESS,
} from './constants';

const handlePayload = (response, previousState): InventoryQueryResult => {
  return {
    ignoreInventory: {
      ...(previousState.ignoreInventory ?? {}),
      ...(response.payload.ignoreInventory ?? {}),
    },
  };
};

const reducer = createRequestReducer<InventoryQueryResult>(
  [QUERY_IQS_FOR_IGNORED, QUERY_IQS_FOR_IGNORED_SUCCESS, QUERY_IQS_FOR_IGNORED_FAIL],
  { ignoreInventory: {} },
  handlePayload
);

export default reducer;
