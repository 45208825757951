import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_SUBCATEGORY_REPORTS_SEARCH,
  FETCH_SUBCATEGORY_REPORTS_SEARCH_SUCCESS,
  FETCH_SUBCATEGORY_REPORTS_SEARCH_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_SUBCATEGORY_REPORTS_SEARCH'));

export const [
  FETCH_SUBCATEGORY_REPORTS_PARTITIONS,
  FETCH_SUBCATEGORY_REPORTS_PARTITIONS_SUCCESS,
  FETCH_SUBCATEGORY_REPORTS_PARTITIONS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_SUBCATEGORY_REPORTS_PARTITIONS'));

export const [
  FETCH_SUBCATEGORY_REPORTS_CONTROLLER,
  FETCH_SUBCATEGORY_REPORTS_CONTROLLER_SUCCESS,
  FETCH_SUBCATEGORY_REPORTS_CONTROLLER_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_SUBCATEGORY_REPORTS_CONTROLLER'));

export const [GET_SUBCATEGORY_REPORTS_SELECTED_DATA] = createRequestConstantNames(
  createReduxConstant('GET_DOOR_BUILDING_SWIPE_SELECTED_DATA')
);

export const [GET_SUBCATEGORY_REPORTS_SELECTED_TYPE] = createRequestConstantNames(
  createReduxConstant('GET_SUBCATEGORY_REPORTS_SELECTED_TYPE')
);
