import { handleActions, Reducer } from 'redux-actions';

import { BaseAction, ActionWithPayload } from 'store/types';
import {
  MODIFY_COMMITMENT_TERM,
  MODIFY_COMMITMENT_TERM_SUCCESS,
  MODIFY_COMMITMENT_TERM_FAIL,
  FETCH_COMMITMENT_TERM,
  FETCH_COMMITMENT_TERM_SUCCESS,
  FETCH_COMMITMENT_TERM_FAIL,
} from 'features/companies/edit/redux/commitmentTerms/constants';

export interface State {
  submitting: boolean;
  loading: boolean;
  commitmentTerm: {};
  fetchError: boolean | null | undefined;
  submitError: boolean | null | undefined;
}

export interface CommitmentTermsSubset {
  commitmentTerms: State;
}

export const initialState: State = {
  loading: false,
  fetchError: null,
  submitError: null,
  submitting: false,
  commitmentTerm: {},
};

export const reducer: Reducer<State, any> = handleActions<State, any>(
  {
    [MODIFY_COMMITMENT_TERM]: state => ({
      ...state,
      submitting: true,
      submitError: null,
    }),
    [MODIFY_COMMITMENT_TERM_SUCCESS]: (state: State) => ({
      ...state,
      submitting: false,
      submitError: null,
    }),
    [MODIFY_COMMITMENT_TERM_FAIL]: (state: State, action: BaseAction) => ({
      ...state,
      submitting: false,
      submitError: action.error,
    }),

    [FETCH_COMMITMENT_TERM]: (state: State) => ({
      ...state,
      loading: true,
      fetchError: null,
    }),
    [FETCH_COMMITMENT_TERM_SUCCESS]: (state, action: ActionWithPayload<{ result: {} }>) => ({
      ...state,
      loading: false,
      commitmentTerm: action.payload.result,
      fetchError: null,
    }),
    [FETCH_COMMITMENT_TERM_FAIL]: (state, action: BaseAction) => ({
      ...state,
      loading: false,
      fetchError: action.error,
      commitmentTerm: {},
    }),
  },
  initialState
);

export default reducer;
