import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button as RayButton } from '@wework/ray2';

import { checkInGuest } from 'features/visitors/redux/dispatch';
import { ReduxProps } from 'store/types';
import { GlobalState } from 'store/modules';
import { searchGuestCheckInSuccess } from 'store/modules/search/actions';
import { getCurrentLocation } from 'store/selectors';

import { GuestCellProps } from './types';

const BUTTON_TEXT = 'Check in';

const mapStateToProps = (state: GlobalState) => ({
  currentLocation: getCurrentLocation(state, {}),
});

const mapDispatchToProps = {
  checkInGuest,
  searchGuestCheckInSuccess,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  Pick<GuestCellProps, 'guestVisit'>;

const CheckInButton = ({
  guestVisit,
  checkInGuest,
  searchGuestCheckInSuccess,
  currentLocation,
}: Props) => {
  const { id, visitor } = guestVisit;
  const [isDisabled, setIsDisabled] = useState(false);

  const handleCheckIn = useCallback(async () => {
    setIsDisabled(true);
    checkInGuest(currentLocation!, id, visitor)
      .then(() => searchGuestCheckInSuccess(id, new Date()))
      .catch(() => setIsDisabled(false));
  }, [checkInGuest, currentLocation, id, visitor, searchGuestCheckInSuccess]);

  return (
    <RayButton
      theme="outline"
      disabled={!currentLocation || isDisabled}
      onClick={handleCheckIn}
      size="small"
    >
      {BUTTON_TEXT}
    </RayButton>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckInButton);
