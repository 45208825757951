import { combineReducers } from 'redux';

import { RequestState } from 'store/util/createRequestReducer';

import uploadedAgreements from './uploadedAgreementsDuck';

export interface State {
  uploadedAgreements: RequestState<any>;
}

export interface UploadedAddOnAgreementsSubset {
  uploadedAgreements: State;
}

export default combineReducers({
  uploadedAgreements,
});
