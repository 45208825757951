import React from 'react';
import moment from 'moment-timezone';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Location } from 'history';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';

import PersistentObject from 'lib/persistentObject';
import Trackable, { TrackableType } from 'components/decorators/trackable';
import {
  fetchAppVersionInfo,
  getAppInitTime,
  getCurrentBranch,
  getCurrentVersion,
  getLatestBranch,
  getLatestVersion,
  getVersionUpdateSeverity,
  hasNewVersion,
  hasNewVersionHighSeverity,
  hasNewVersionMediumSeverity,
} from 'store/modules/appVersion';
import { setReloadRequiredMessage } from 'store/modules/app';
import { GlobalState } from 'store/modules';
import { BaseAction, Dispatch, ReduxProps } from 'store/types';

const mapStateToProps = (state: GlobalState) => {
  return {
    appInitTime: getAppInitTime(state),
    versionUpdateSeverity: getVersionUpdateSeverity(state),
    currentBranch: getCurrentBranch(state),
    currentVersion: getCurrentVersion(state),
    latestBranch: getLatestBranch(state),
    latestVersion: getLatestVersion(state),
    hasNewVersion: hasNewVersion(state),
    hasNewVersionMediumSeverity: hasNewVersionMediumSeverity(state),
    hasNewVersionHighSeverity: hasNewVersionHighSeverity(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  fetchAppVersionInfo: () => dispatch(fetchAppVersionInfo()),
  setReloadRequiredMessage: (message: string, callback?: (evt: React.MouseEvent) => void) =>
    dispatch(setReloadRequiredMessage(message, callback)),
});

// Thank you prettier, for folding everything without thought.
type MSP = typeof mapStateToProps;
type MDP = typeof mapDispatchToProps;
type ConnectedProps = ReduxProps<MSP, MDP>;

interface OwnProps {
  fetchUpdateIntervalMs?: number;
  reloadRoutes: Array<{
    exact?: boolean;
    path: string;
  }>;
}

export type Props = Readonly<ConnectedProps & RouteComponentProps & TrackableType & OwnProps>;

// Exported for testing purposes.
export const APP_VERSION_UPDATER_REFRESH = 'ss_app_version_refresh';

const DEFAULT_FETCH_UPDATE_INTERVAL_MS = 15 * 60 * 1000;
const AUTO_REFRESH_PERIOD_HOURS = 24;
const STALE_REFRESH_PERIOD_HOURS = 72;

export const UpdateReason = Object.freeze({
  STALE: 'stale',
  PERIODIC: 'periodic',
  AUTOMATIC: 'automatic',
  PROMPTED: 'prompted',
});

type UpdateReasonValues = typeof UpdateReason[keyof typeof UpdateReason];

const hoursSince = (time: string | Date): number => moment().diff(time, 'h');

type PersistentRefreshMarker = {
  reason: UpdateReasonValues;
  current_branch: string;
  latest_branch: string;
  previous_version: string;
};

// Trying to track a page refresh _before_ the refresh usually results in a missed event, as the browser refreshes
// before the event is actually sent.
// Therefore we need to persistently store some kind of marker for the next page load.
//
// There are two reasons for using session storage here:
// 1. We prefer to treat every tab refresh as a separate event. It could tell us, for example, if
//    different versions were open in different tabs.
// 2. We never want the stored values to remain past the open session, so reopening the browser will not
//    be tracked as a refresh.
const persistentRefreshReason: PersistentObject<PersistentRefreshMarker> = new PersistentObject(
  sessionStorage,
  APP_VERSION_UPDATER_REFRESH
);

export class AppVersionUpdater extends React.Component<Props> {
  static defaultProps = {
    fetchUpdateIntervalMs: DEFAULT_FETCH_UPDATE_INTERVAL_MS,
  };

  fetchUpdateIntervalId: number | null | undefined = null;
  unblock: () => void | null | undefined;

  track = () => {
    const { currentVersion, latestVersion, trackInteraction, versionUpdateSeverity } = this.props;

    trackInteraction('App Update Detected', {
      label: 'sst_version_update_detect',
      event_details: {
        severity: versionUpdateSeverity,
        current_version: currentVersion,
        latest_version: latestVersion,
      },
    });
  };

  trackRefresh() {
    const refreshReason = persistentRefreshReason.get();

    if (refreshReason) {
      const { currentVersion, trackInteraction } = this.props;

      trackInteraction('App Update Refresh', {
        label: 'sst_version_update_refresh',
        event_details: {
          reason: refreshReason.reason,
          previous_version: refreshReason.previous_version,
          current_version: currentVersion,
        },
      });

      persistentRefreshReason.clear();
    }
  }

  handleImmediateRefresh = () => {
    const { appInitTime, currentBranch, currentVersion, hasNewVersion, latestBranch } = this.props;

    // App is considered stale if it wasn't refreshed in 72 hours.
    const isAppStale = hoursSince(appInitTime) >= STALE_REFRESH_PERIOD_HOURS;

    if (isAppStale && hasNewVersion) {
      persistentRefreshReason.set({
        reason: UpdateReason.STALE,
        previous_version: currentVersion,
        current_branch: currentBranch,
        latest_branch: latestBranch,
      });

      window.location.reload();
    }
  };

  setFetchUpdateInterval = () => {
    const { fetchAppVersionInfo, fetchUpdateIntervalMs } = this.props;

    // TODO(grozki): Consider user interaction to only make the calls when necessary.
    this.fetchUpdateIntervalId = window.setInterval(() => {
      // TODO(grozki): Do this in the interval?
      this.handleImmediateRefresh();

      fetchAppVersionInfo();
    }, fetchUpdateIntervalMs);
  };

  handleBlockNavigation = (location: Location) => {
    const {
      appInitTime,
      currentBranch,
      currentVersion,
      hasNewVersionHighSeverity,
      hasNewVersionMediumSeverity,
      history,
      latestBranch,
      reloadRoutes,
    } = this.props;

    // App should also refresh on nav if it's been open for more than 24 hours.
    const needsPeriodicRefresh = hoursSince(appInitTime) >= AUTO_REFRESH_PERIOD_HOURS;

    const shouldReload =
      hasNewVersionHighSeverity || hasNewVersionMediumSeverity || needsPeriodicRefresh;

    if (shouldReload) {
      // Check if this route transition allows refreshes. Only perform this when relevant for performance reasons.
      const reloadAllowedForRoute = reloadRoutes.some(route =>
        matchPath(location.pathname, {
          path: route.path,
          exact: route.exact,
        })
      );

      if (reloadAllowedForRoute) {
        let reason = needsPeriodicRefresh ? UpdateReason.PERIODIC : UpdateReason.AUTOMATIC;

        if (hasNewVersionHighSeverity) {
          reason = UpdateReason.PROMPTED;
        }

        persistentRefreshReason.set({
          reason,
          previous_version: currentVersion,
          current_branch: currentBranch,
          latest_branch: latestBranch,
        });

        // $FlowFixMe TODO (grozki): window.location.assign is the same as setting window.location.href, but can be mocked.
        window.location.assign(history.createHref(location));
        window.location.reload();
      }
    }
  };

  componentDidMount() {
    if (__DEVELOPMENT__) {
      return;
    }

    this.setFetchUpdateInterval();

    this.unblock = this.props.history.block(this.handleBlockNavigation);

    this.trackRefresh();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      currentBranch,
      currentVersion,
      hasNewVersion,
      hasNewVersionHighSeverity,
      latestBranch,
      setReloadRequiredMessage,
    } = this.props;

    if (hasNewVersion && hasNewVersion !== prevProps.hasNewVersion) {
      this.track();
    }

    if (
      hasNewVersionHighSeverity &&
      hasNewVersionHighSeverity !== prevProps.hasNewVersionHighSeverity
    ) {
      setReloadRequiredMessage(
        'A new version of Spacestation has been released. Please reload to update.',
        () =>
          persistentRefreshReason.set({
            reason: UpdateReason.PROMPTED,
            previous_version: currentVersion,
            current_branch: currentBranch,
            latest_branch: latestBranch,
          })
      );
    }
  }

  componentWillUnmount() {
    if (this.fetchUpdateIntervalId) {
      window.clearInterval(this.fetchUpdateIntervalId);
    }

    if (this.unblock) {
      this.unblock();
    }
  }

  render() {
    return null;
  }
}

export default compose<React.ComponentType<OwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  Trackable({
    workflow: 'homepage',
    feature: 'appVersionUpdater',
    featureContext: 'page.home',
  }),
  withRouter
)(AppVersionUpdater);
