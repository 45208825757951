import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Button, ButtonTheme, ButtonSize } from '@wework/ray2';

import { addRecentLocation, locationByIdQuery } from 'store/modules/siLocations';
import { fetchLocation } from 'store/modules/siLocations/api';
import { GeneralInvalidError } from 'store/errors';
import Modal from 'components-ray/modal';
import spacestationFullLogo from 'images/spacestation-full-logo.svg';
import { ReduxProps } from 'store/types';
import { trackAnalyticsFor } from 'lib/analytics/analytics';
import withToasts, { ToastsProps } from 'components/decorators/withToasts';
import { getLocationsNamesByUuid } from 'store/selectors';
import { AnalyticsEventName } from 'lib/analytics/constants';
import { LOCATION_CHANGE_ITEM_TYPES } from 'lib/analytics/types/dynamic/notifications';
import { useGetCurrentWorkflow } from 'lib/analytics/types/useGetCurrentWorkflow';
import {
  getNotificationsDeepLink,
  resetNotificationDeepLinkState,
  setLocationChangeModalClosed,
} from 'features/notifications/ducks/notificationDeepLink';

const mapStateToProps = state => ({
  locationNames: getLocationsNamesByUuid(state, {}),
});

const mapDispatchToProps = {
  addRecentLocation,
  resetNotificationDeepLinkState,
  setLocationChangeModalClosed,
};

export type Props = Readonly<
  RouteComponentProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & ToastsProps
>;

export const LocationChangeModal = ({
  addRecentLocation,
  locationNames,
  notifyError,
  resetNotificationDeepLinkState,
  setLocationChangeModalClosed,
}: Props) => {
  const history = useHistory();
  const workflow = useGetCurrentWorkflow();
  const { actionUrl, isModalOpen, targetLocationUuid = '' } = useSelector(getNotificationsDeepLink);

  const locationName = locationNames?.[targetLocationUuid]?.name ?? 'error fetching name';

  // Clear the search params and dispatch a deep link reset
  const handleGoBack = () => {
    trackAnalyticsFor(AnalyticsEventName.locationchange_modal_clicked, {
      item: LOCATION_CHANGE_ITEM_TYPES.GO_BACK,
      workflow,
    });
    history.replace({ search: '' });
    resetNotificationDeepLinkState();
  };

  const handleProceedToNewLocation = async () => {
    if (!actionUrl || !targetLocationUuid) return;

    try {
      trackAnalyticsFor(AnalyticsEventName.locationchange_modal_clicked, {
        item: LOCATION_CHANGE_ITEM_TYPES.NEW_LOCATION,
        workflow,
      });
      const newLocation = await fetchLocation(locationByIdQuery, { id: targetLocationUuid });
      addRecentLocation(newLocation.data.location);
      history.push(actionUrl);
      setLocationChangeModalClosed();
    } catch (err) {
      notifyError(`Failed change location: ${err.message}`);
      throw new GeneralInvalidError(
        'LocationChangeModal',
        err.message,
        'Failed to update location'
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      className="w-[392px] min-h-[402px] !px-8 !pb-8 !pt-6 flex flex-col items-center"
      closeIcon={false}
      overlayClassName="!z-[1000]"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <img
        alt="spacestation full logo"
        className="center w-[118.05px] h-[105.11px]"
        src={spacestationFullLogo}
      />
      <h3 className="!text-heading !text-black !font-main !font-bold text-sm mt-4 !mb-2">
        Location change
      </h3>
      <p className="!font-main !text-body !text-2xs !text-black text-center mb-10">
        Hey there — you are about to change your location to{' '}
        <span className="!font-bold">{locationName}</span>, just wanted to give you a heads up.
      </p>
      <Button
        className="w-full !text-3xs mb-2 !h-10"
        onClick={handleGoBack}
        role="presentation"
        size={ButtonSize.LARGE}
        theme={ButtonTheme.OUTLINE}
        type="button"
      >
        Go back
      </Button>
      <Button
        className="w-full !text-3xs !h-10"
        onClick={handleProceedToNewLocation}
        role="button"
        size={ButtonSize.LARGE}
        theme={ButtonTheme.FILL}
        type="button"
      >
        Proceed to new location
      </Button>
    </Modal>
  );
};

export default compose<React.ComponentType>(
  withRouter,
  withToasts,
  connect(mapStateToProps, mapDispatchToProps)
)(LocationChangeModal);

export const TestableLocationChangeModal = LocationChangeModal;
