import React from 'react';

import { CompanySearchResultItem } from 'store/modules/search/searchService/types';
import { SearchableEntity } from 'features/search/types';

import { Result } from '../types';
import { createAvatarUrlById } from '../utils';
import MemberNameCell from '../member/cells/name';
import ContactInfoCell from '../common/cells/contactInfo';
import Cell from '../cell';

import CompanyNameCell from './cells/name';
import OfficesCell from './cells/offices';
import { companyActionURL } from './utils';
import { CompanyCellProps } from './types';

const PrimaryMemberNameCell = ({ company: { primary_member } }: CompanyCellProps) =>
  !primary_member?.name ? (
    <Cell inactive>Missing Primary Member</Cell>
  ) : (
    <MemberNameCell member={primary_member} inactive />
  );

const CompanyContactInfoCell = ({ company: { primary_member } }: CompanyCellProps) => (
  <ContactInfoCell email={primary_member?.email} />
);

const createCompanyCells = (company: CompanySearchResultItem) =>
  [
    CompanyNameCell,
    PrimaryMemberNameCell,
    CompanyContactInfoCell,
    OfficesCell,
  ].map((Cell, index) => <Cell company={company} key={index} />);

export const mapCompanyResult = (company: CompanySearchResultItem): Result => {
  const { uuid, name, primary_member } = company;
  const analyticsData = {
    company_uuid: uuid,
    account_uuid: uuid,
    target_entity: name,
    member_uuid: primary_member?.uuid ?? '',
  };
  return {
    uuid,
    avatarUrl: createAvatarUrlById(uuid),
    cells: createCompanyCells(company),
    entityType: SearchableEntity.COMPANIES,
    link: companyActionURL(company),
    analyticsData,
    data: company,
  };
};
