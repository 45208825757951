import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Tag as RayTag } from '@wework/ray2';

import Aliases from 'features/companies/components/aliases/aliases';
import {
  CompanySearchResultItem,
  MemberCompany,
  Status,
} from 'store/modules/search/searchService/types';
import { SearchContext } from 'features/search/context/search';
import Cell from 'features/search/resultSets/cell';
import { INACTIVE_LABEL } from 'features/search/resultSets/constants';

import { companyActionURL } from '../utils';

type Props = {
  company: CompanySearchResultItem | MemberCompany;
  inactive?: boolean;
};

const CompanyAliases = ({
  company,
  query,
}: {
  company: CompanySearchResultItem | MemberCompany;
  query: string;
}) => {
  const filteredAlias = (company.aliases || []).filter(aliasName => {
    return (
      aliasName.toLowerCase().includes(query.trim().toLowerCase()) ||
      aliasName.replace(/\s/g, '').toLowerCase().includes(query.toLowerCase())
    );
  });

  return filteredAlias?.length ? <Aliases aliases={filteredAlias} /> : null;
};

export const CompanyNameLink = ({
  company,
  onClick,
}: {
  company: Pick<CompanySearchResultItem, 'uuid' | 'name'> & {
    status?: CompanySearchResultItem['status'];
    potential?: CompanySearchResultItem['potential'];
  };
  onClick?: (evt: React.MouseEvent) => void;
}) => {
  const isInactive = !company.potential && company.status === Status.INACTIVE;

  const inactiveLabel = <RayTag theme="gray">{INACTIVE_LABEL}</RayTag>;
  return (
    <div className="flex !items-center">
      <Link
        className="text-primary text-3xs truncate mr-[5px] hover:text-primary hover:underline"
        to={!onClick ? companyActionURL(company) : ''}
        onClick={onClick}
      >
        {company.name}
      </Link>
      {isInactive && inactiveLabel}
    </div>
  );
};

const CompanyNameCell = ({ company, inactive }: Props) => {
  const { query } = useContext(SearchContext);
  return (
    <Cell inactive={inactive}>
      <CompanyNameLink company={company} />
      <CompanyAliases company={company} query={query} />
    </Cell>
  );
};

export default compose<React.ComponentType<Props>>()(CompanyNameCell);
