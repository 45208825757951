/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import {
  omit,
  filter,
  reject,
  flatMap,
  isEmpty,
  keyBy,
  every,
  pickBy,
  escapeRegExp,
  includes,
  mapValues,
} from 'lodash';

import {
  getCurrentLocationUuid,
  makeGetSortedCollection,
  getOfficeHolds,
  getOfficeHoldsByLocationUuid,
} from "store/selectors";
import { deskTypes, officeTypes, reservableTypes } from 'lib/occupiableTypes';
import { getMimoFromOccupancy } from 'lib/util';
import { getIgnoredInventory } from "features/inventory/redux/ignoreInventory/selectors";

const getMaxValue = value => (value === null ? Number.MAX_VALUE : value);
const getMinValue = value => (value === null ? 0 : value);

const occupancyTypes = [...deskTypes, ...officeTypes, ...reservableTypes, 'VM'];

const getOccupancies = state => state.occupancies;
const getIsFiltered = state => state.yourInventory.filters.dirty;
const getOccupancyFilters = state => state.yourInventory.filters;
const getContracts = state => state.contracts;
const getSortKey = state => state.yourInventory.sort.key;
const getSortOrder = state => state.yourInventory.sort.order;

export const getOccupanciesForCurrentLocation = createSelector(
  [getCurrentLocationUuid, getOccupancies],
  (locationUuid, occupancies) =>
    occupancies.byLocationUuid[locationUuid]
      ? occupancies.byLocationUuid[locationUuid].filter(({ type }) =>
          includes(occupancyTypes, type)
        )
      : []
);

export const getContractsForCurrentLocation = createSelector(
  [getCurrentLocationUuid, getContracts],
  (locationUuid, contracts) => contracts.byLocationUuid[locationUuid]
);

export const getPendingContractsForCurrentLocation = createSelector(
  [getContractsForCurrentLocation],
  contracts =>
    contracts ? contracts.filter(contract => contract.contract_status !== 'Signed') : []
);

const getPendingContractsByReservableUuid = createSelector(
  [getPendingContractsForCurrentLocation],
  contracts =>
    contracts.reduce((result, contract) => {
      contract.reservables.forEach(reservable => {
        // eslint-disable-next-line no-param-reassign
        result[reservable.reservable_uuid] = contract;
      });
      return result;
    }, {})
);

export const getOccupanciesWithContractsForCurrentLocation = createSelector(
  [getOccupanciesForCurrentLocation, getPendingContractsByReservableUuid],
  (occupancies, contractsByReservableUuid) => {
    if (!occupancies || !contractsByReservableUuid) {
      return [];
    }

    return occupancies.map(occupancy => ({
      ...occupancy,
      contract: contractsByReservableUuid[occupancy.uuid],
    }));
  }
);

export const getReservablesWithPendingContracts = createSelector(
  [getPendingContractsForCurrentLocation],
  contracts =>
    flatMap(contracts, contract => {
      return contract.reservables.map(reservable => ({
        ...reservable,
        contract: omit(contract, 'reservables'),
      }));
    })
);

export const getAddsByReservableUuid = createSelector(
  [getReservablesWithPendingContracts],
  reservables => keyBy(reject(reservables, 'reservation_uuid'), 'reservable_uuid')
);

export const getDropsByReservationUuid = createSelector(
  [getReservablesWithPendingContracts],
  reservables => keyBy(filter(reservables, 'reservation_uuid'), 'reservation_uuid')
);

export const getMoveOutsByAccountUuid = createSelector(
  [getPendingContractsForCurrentLocation],
  contracts => keyBy(filter(contracts, { name: 'Move Out' }), 'account.uuid')
);

export const getOfficeHoldsKeyedByReservableUuid = createSelector(
  [getOfficeHolds],
  officeHolds => {
    if (!isEmpty(officeHolds)) {
      return keyBy(officeHolds, 'reservable_uuid');
    }
    return {};
  }
);

export const getOfficeHoldsKeyedByLocationAndReservableUuid = createSelector(
  [getOfficeHoldsByLocationUuid],
  officeHoldsByLocationUuid =>
    mapValues(officeHoldsByLocationUuid, officeHolds => keyBy(officeHolds, 'reservable_uuid'))
);

// Filter functions
const statusFilters = {
  mimo: occupancy => {
    return getMimoFromOccupancy(occupancy);
  },

  sent: occupancy => {
    return (
      occupancy.contract &&
      occupancy.contract.contract_status === 'Sent' &&
      !occupancy.contract.expired
    );
  },

  voided: occupancy => {
    return (
      occupancy.contract &&
      (occupancy.contract.expired || occupancy.contract.contract_status === 'Voided')
    );
  },

  moRequested: occupancy => {
    return (
      !isEmpty(occupancy.reservations) &&
      !isEmpty(occupancy.reservations[0].modification_requests)
    );
  },

  sellable: occupancy => {
    return occupancy.availability;
  },

  custom: () => {
    return true;
  },
};

const filterFunctions = {
  floors: (occupancy, floors) => {
    if (floors.length === 0) return true;
    return includes(floors, occupancy.floor);
  },

  capacityMinMax: (occupancy, { minValue, maxValue }) => {
    return (
      occupancy.capacity >= getMinValue(minValue) && occupancy.capacity <= getMaxValue(maxValue)
    );
  },

  types: (occupancy, types) => {
    return includes(types, occupancy.type);
  },

  price: (occupancy, { minValue, maxValue }) => {
    return occupancy.price >= getMinValue(minValue) && occupancy.price <= getMaxValue(maxValue);
  },

  availability: (occupancy, availability) => {
    if (availability === 'all') return true;
    if (availability === 'sellable') {
      return occupancy.availability;
    }
    return !occupancy.availability;
  },

  query: (occupancy, query) => {
    const regex = new RegExp(escapeRegExp(query), 'i');

    let companiesString;

    // returns string of all companies and account admins of a occupancy row.
    companiesString = occupancy.reservations
      .map(reservation => {
        return `${reservation.account.name}, ${reservation.account.account_admin.full_name}`;
      })
      .join(', ');

    if (occupancy.contract) {
      companiesString += `, ${occupancy.contract.account.name}, ${occupancy.contract.primary_member.name}`;
    }

    const notesString = occupancy.notes || '';

    // TODO: https://jira.we.co/browse/CMOS-2868
    // Once office holds are in redux, re-enable ability to query on them
    return (
      occupancy.office_num.match(regex) || companiesString.match(regex) || notesString.match(regex)
    );
  },

  // Preset status filters
  status: (occupancy, status) => {
    const statusFilter = statusFilters[status];

    if (statusFilter) {
      return statusFilter(occupancy);
    }
    return true;
  },
};

export const getFilteredOccupanciesWithContracts = createSelector(
  [getOccupanciesWithContractsForCurrentLocation, getOccupancyFilters, getIsFiltered],
  (occupancies, filters, isFiltered) => {
    if (!isFiltered) {
      return occupancies;
    }
    return occupancies.filter(occupancy => {
      return every(pickBy(filters), (value, filterName) => {
        if (value && filterFunctions[filterName]) {
          return filterFunctions[filterName](occupancy, value);
        }
        return true;
      });
    });
  }
);

export const getOccupanciesWithIgnoredProductTypes = createSelector(
    [getFilteredOccupanciesWithContracts, getIgnoredInventory],
    (occupancies, ignoredInventory) =>
        occupancies.filter(occupancy => !(occupancy.uuid in ignoredInventory))
);

export const getSortedOccupancies = makeGetSortedCollection(
  getOccupanciesWithIgnoredProductTypes,
  getSortKey,
  getSortOrder
);
