import normalize from 'json-api-normalizer';
import moment from 'moment-timezone';

import config from 'config';
import { Location } from 'store/modules/locations';
import { formatDateWithTimeZone } from 'features/visitors/util';
import { AuthPrefix, httpFetcher } from 'lib/fetchAPI';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

const DEFAULT_PAGINATE_COUNT = 20;

const getFetcher = (options = {}) =>
  httpFetcher({
    domain: config.rooms.uri,
    authPrefix: AuthPrefix.BEARER,
    getAccessToken,
    ...options,
  });

export async function fetchReservations(
  location: Location,
  date: Date,
  paginated: boolean = false,
  count: number = DEFAULT_PAGINATE_COUNT
) {
  const fetcher = getFetcher({ entry: '' });
  const startMomentBegin = moment(date).startOf('day').format();
  const startMomentEnd = moment(date).endOf('day').format();
  const startDateBegin = formatDateWithTimeZone(new Date(startMomentBegin), location);
  const startDateEnd = formatDateWithTimeZone(new Date(startMomentEnd), location);

  const data = await fetcher(
    'api/v7/reservations',
    {
      'filter[location_uuid]': location.uuid,
      'filter[start_gte]': startDateBegin,
      'filter[start_lte]': startDateEnd,
      'filter[cancelled_at]': null,
      'extra_fields[reservations]': 'first_time_at_location,user_home_location_uuid',
      ...(paginated && count && { 'page[size]': count }),
      ...(!paginated && { 'stats[total]': 'count' }),
      include: 'company,location,reservable,user,booked_by_user,reservation_refunds',
      include_third_party: true,
    },
    true
  );

  return [normalize(data), data?.meta?.stats?.total?.count];
}

// FetchDailyDesks: getting all daily desks associated with the location. Currently, we are assuming there is only 1 daily desk per the location
export async function fetchDailyDesks({
  locationUuid,
  userMembershipUuid,
  targetUserUuid,
  accessibleOnDate,
}) {
  const fetcher = getFetcher();

  const data = await fetcher(
    'api/v7/daily_desks',
    {
      'filter[user_membership_uuid]': userMembershipUuid,
      'filter[location_uuid]': locationUuid,
      'filter[target_user_uuid]': targetUserUuid,
      'filter[accessible_on]': accessibleOnDate,
      'extra_fields[daily_desks]': 'open_time,close_time,credits',
    },
    true
  );

  return data[0];
}

// FetchDailyDeskCapacity: getting the capacity of DailyDesk. DailyDesk or DailyPass will be determine by user_membership_uuid
export function fetchDailyDeskCapacity({
  location,
  dailyDeskUuid,
  date,
  userMembershipUuid,
  targetUserUuid,
}) {
  const fetcher = getFetcher();

  return fetcher(
    `api/v7/daily_desks/${dailyDeskUuid}/seats_available`,
    {
      'filter[user_membership_uuid]': userMembershipUuid,
      'filter[date]': formatDateWithTimeZone(date, location),
      'filter[target_user_uuid]': targetUserUuid,
    },
    true
  );
}

export function bookDailyDesk({
  start,
  finish,
  memberUuid,
  companyUuid,
  reservableType,
  reservableUuid,
}) {
  const fetcher = getFetcher();

  return fetcher(
    `api/v7/reservations`,
    {
      data: {
        type: 'reservations',
        attributes: {
          start,
          finish,
          user_uuid: memberUuid,
          company_uuid: companyUuid,
          reservable_type: reservableType,
          reservable_uuid: reservableUuid,
        },
      },
    },
    true,
    'POST'
  );
}
