import { handleActions } from 'redux-actions';

import {
  UPDATE_CURRENT_PAGE,
  SET_FILTER,
  RESET_FILTER,
  SET_DEFAULT_TYPES,
  SET_SORT,
} from 'features/inventory/redux/yourInventory/constants';

interface State {
  sort: {
    key: string | null;
    order: 'asc' | 'desc' | null;
  };
  filters: {
    dirty: boolean;
    capacityMinMax: {
      minValue: number | null;
      maxValue: number | null;
    };
    types: any[];
    floors: any[];
    price: {
      minValue: number | null;
      maxValue: number | null;
    };
    query: string | null;
    availability: 'all' | 'sellable';
    status: string | null;
  };
  currentPage: number;
  defaultTypes: any[];
}

export interface YourInventorySubset {
  yourInventory: State;
}

export const initialState: State = {
  currentPage: 0,
  defaultTypes: [],
  sort: {
    key: null,
    order: null,
  },
  filters: {
    dirty: false,
    availability: 'all',
    status: null,
    floors: [],
    types: [],
    capacityMinMax: {
      minValue: null,
      maxValue: null,
    },
    price: {
      minValue: null,
      maxValue: null,
    },
    query: null,
  },
};

export const reducer = handleActions<State, any>(
  {
    [UPDATE_CURRENT_PAGE]: (state, action: { payload: number }) => ({
      ...state,
      currentPage: action.payload,
    }),

    [RESET_FILTER]: state => ({
      ...initialState,
      defaultTypes: state.defaultTypes,
      filters: {
        ...initialState.filters,
        types: state.defaultTypes,
      },
    }),

    [SET_FILTER]: (state, action: { payload: {} }) => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
        dirty: true,
      },
      currentPage: 0,
    }),

    [SET_DEFAULT_TYPES]: (state, action: { payload: [] }) => ({
      ...state,
      defaultTypes: action.payload,
      filters: {
        ...state.filters,
        types: action.payload,
      },
    }),

    [SET_SORT]: (state, action: { payload: {} }) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);

export default reducer;
