import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getCompanyFormattedLocations } from 'features/search/resultSets/selectors';
import { CompanySearchResultItem, MemberCompany } from 'store/modules/search/searchService/types';
import Cell from 'features/search/resultSets/cell';
import OfficeNumbersCell from 'features/search/resultSets/common/cells/officeNumbers';
import { getCurrentLocationUuid } from 'store/selectors';
import { GlobalState } from 'store/modules';
import { getSearchFilters } from 'store/modules/search';
import { ReduxProps } from 'store/types';

type OwnProps = {
  company: CompanySearchResultItem | MemberCompany;
};

const companyPropHasLocationUuids = (
  props: OwnProps
): props is {
  company: CompanySearchResultItem;
} => Boolean((props.company as CompanySearchResultItem).location_uuids);

const mapStateToProps = (state: GlobalState, props: OwnProps) => ({
  currentLocationUuid: getCurrentLocationUuid(state, {})!,
  isCurrentLocationActive: getSearchFilters(state).currentLocation,
  locations: companyPropHasLocationUuids(props) ? getCompanyFormattedLocations(state, props) : '',
});

type Props = Readonly<ReduxProps<typeof mapStateToProps> & OwnProps>;

const Locations: React.FC<Pick<Props, 'locations'>> = ({ locations }) =>
  locations ? <p className="text-black line-clamp-2 text-ellipsis text-3xs">{locations}</p> : null;

const LocationOfficeNumbers: React.FC<Pick<Props, 'company' | 'currentLocationUuid'>> = React.memo(
  function LocationOfficeNumbers({ company, currentLocationUuid }) {
    const currentLocationOfficeNumbers =
      company.spaces?.reduce<Array<string>>((officeNumbers, { location_uuid, office_number }) => {
        if (office_number && location_uuid === currentLocationUuid) {
          officeNumbers.push(office_number);
        }
        return officeNumbers;
      }, []) ?? [];

    return <OfficeNumbersCell offices={currentLocationOfficeNumbers} />;
  }
);

const OfficesCell: React.FC<Props> = ({
  company,
  isCurrentLocationActive: showOfficeNumber,
  currentLocationUuid,
  locations,
}) =>
  showOfficeNumber ? (
    <LocationOfficeNumbers company={company} currentLocationUuid={currentLocationUuid} />
  ) : (
    <Cell inactive>
      <Locations locations={locations} />
    </Cell>
  );

export default compose(connect(mapStateToProps))(OfficesCell);
