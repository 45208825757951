import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { GlobalState } from 'store/modules';
import { getCurrentLocationUuid } from 'store/selectors';
import { ReduxProps } from 'store/types';

import { getMemberPrimaryLocation } from '../selectors';

import { MemberCellProps } from './types';

type OwnProps = Pick<MemberCellProps, 'member'>;

const mapStateToProps = (state: GlobalState, props: OwnProps) => ({
  location: getMemberPrimaryLocation(state, props),
  currentLocationUuid: getCurrentLocationUuid(state, {}),
});

type Props = OwnProps & ReduxProps<typeof mapStateToProps, {}>;

const MemberLocation: React.FC<Props> = ({ location, currentLocationUuid }) => {
  const isCurrentLocation = currentLocationUuid === location?.uuid;
  return !isCurrentLocation && location?.name ? (
    <p className="leading-loose text-primary truncate">{location.name}</p>
  ) : null;
};

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps))(MemberLocation);
