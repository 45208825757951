import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Cell from 'features/search/resultSets/cell';
import { CompanyNameLink } from 'features/search/resultSets/company/cells/name';
import MemberNameCell from 'features/search/resultSets/member/cells/name';
import { Status } from 'store/modules/search/searchService/types';
import { fetchMemberByUuid } from 'store/modules/members';
import { ReduxProps } from 'store/types';

import { GuestCellProps } from '../types';

const mapDispatchToProps = {
  fetchMemberByUuid,
};

type Props = GuestCellProps & ReduxProps<null, typeof mapDispatchToProps>;

const HostCell: React.FC<Props> = ({ guestVisit: { host }, fetchMemberByUuid }) => {
  const [company, setCompany] = useState({
    uuid: '',
    name: host?.company.name ?? '',
  });

  const updateCompanyLink = useCallback(async (): Promise<string | null> => {
    if (!company.uuid && host) {
      const member = await fetchMemberByUuid(host.id);
      const companyId = member?.payload?.seats[0]?.company?.uuid ?? null;
      setCompany({
        uuid: companyId ?? '',
        name: host.company.name ?? '',
      });
      return companyId;
    }
    return null;
  }, [host, fetchMemberByUuid, company]);

  const openCompanyLink = useCallback(async () => {
    const companyId = await updateCompanyLink();
    if (companyId) {
      window.location.hash = `/companies/${companyId}`;
    }
  }, [updateCompanyLink]);

  if (!host) {
    return <Cell />;
  }
  const member = {
    uuid: host.id,
    name: host.name,
    email: host.email,
    status: Status.ACTIVE,
  };

  const handleClick = !company.uuid ? openCompanyLink : undefined;
  const handleMouseEnter = !company.uuid ? updateCompanyLink : undefined;

  return (
    <MemberNameCell
      member={member}
      inactive
      secondRowContent={<CompanyNameLink company={company} onClick={handleClick} />}
      onMouseEnter={handleMouseEnter}
    />
  );
};

export default compose(connect(null, mapDispatchToProps))(HostCell);
