import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_PRICE_CHANGES_BY_LOCATION,
  FETCH_PRICE_CHANGES_BY_LOCATION_SUCCESS,
  FETCH_PRICE_CHANGES_BY_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_PRICE_CHANGES_BY_LOCATION'));

export const [
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION,
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION_SUCCESS,
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_ALL_PRICE_CHANGES_BY_LOCATION'));
