import { showModal } from '../ducks/modals';
import { ActionPayload, InventoryAction, ActionConfig } from '../config/types';

import { isAvailableForHolds } from './actionAvailability';
import isInventoryGroup from './isInventoryGroup';
import modalTypes from './modalTypes';

export const addHold: InventoryAction = (payload: ActionPayload | null | undefined) =>
  showModal(modalTypes.officeHoldModal, payload);

export const removeHold: InventoryAction = (payload: ActionPayload | null | undefined) =>
  showModal(modalTypes.removeOfficeHoldModal, payload);

export const addNotes: InventoryAction = (payload: ActionPayload | null | undefined) =>
  showModal(modalTypes.addNoteModal, payload);

export const ActionMap: Hash<ActionConfig> = {
  addHold: { key: 'addHold', text: 'Add Hold', action: addHold, dispatch: true },
  addNotes: { key: 'addNotes', text: 'Add Notes', action: addNotes, dispatch: true },
  editHold: { key: 'editHold', text: 'Edit Hold', action: addHold, dispatch: true },
  editNotes: { key: 'editNotes', text: 'Edit Notes', action: addNotes, dispatch: true },
  removeHold: { key: 'removeHold', text: 'Remove Hold', action: removeHold, dispatch: true },
};

export const getActions = (params: ActionPayload): Array<ActionConfig> => {
  const actions: Array<ActionConfig> = [];

  if (isInventoryGroup(params)) {
    return actions;
  }

  if (isAvailableForHolds(params)) {
    if (params.onHold) {
      actions.push(ActionMap.editHold);
      actions.push(ActionMap.removeHold);
    } else {
      actions.push(ActionMap.addHold);
    }
  }

  if (params.notes) {
    actions.push(ActionMap.editNotes);
  } else {
    actions.push(ActionMap.addNotes);
  }

  return actions;
};
