import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { getCurrentLocationUuid, getLocationsByUuid } from 'store/selectors';
import { Notification } from 'lib/api/notify/types';
import { GlobalState } from 'store/modules';
import { ReduxProps } from 'store/types';

const NOTIFICATION_KINDS = {
  SPACESTATION_TASK_ASSIGNMENT: 'spacestation-task-assignment',
};

const DUE_DATE_FORMAT = 'MMM do';

const ERROR_LOADING_DATE = 'Error Loading Date';
const ERROR_LOADING_LOCATION = 'Error Loading Location';

const mapStateToProps = (state: GlobalState) => ({
  currentLocationUuid: getCurrentLocationUuid(state, {}),
  locationsByUuid: getLocationsByUuid(state),
});

type OwnProps = {
  notification: Notification;
};

type NotificationMapperProps = OwnProps & ReduxProps<typeof mapStateToProps>;

/**
 * Maps the Notification returned from the Notify endpoint to JSX
 * @param {object} notification The Notify notification to map from
 * @returns Notification text as a JSX element
 */
const NotificationMapper = ({
  currentLocationUuid,
  locationsByUuid,
  notification,
}: NotificationMapperProps): JSX.Element => {
  const locationUuid = notification?.meta_data?.websocket?.location_uuid;
  const dueDate = notification?.websocket_params?.due_date;
  let message: JSX.Element;

  switch (notification.kind) {
    case NOTIFICATION_KINDS.SPACESTATION_TASK_ASSIGNMENT:
      message = (
        <>
          A check-in with <strong>{notification?.websocket_params?.company_name}</strong> due on{' '}
          <strong>
            {dueDate ? format(new Date(dueDate), DUE_DATE_FORMAT) : ERROR_LOADING_DATE}
          </strong>{' '}
          has been assigned to you
          {currentLocationUuid !== locationUuid && (
            <>
              {' '}
              at{' '}
              <strong>
                {locationUuid ? locationsByUuid[locationUuid]?.name : ERROR_LOADING_LOCATION}
              </strong>
            </>
          )}
          .
        </>
      );
      break;
    default:
      message = <>{notification.websocket_content}</>;
  }

  return (
    <p
      className="line-clamp-5 leading-6 m-0 text-black text-3xs"
      data-testid="notification-content"
    >
      {message}
    </p>
  );
};

export default connect(mapStateToProps)(NotificationMapper);
