import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_EXPIRED_CONTRACTS,
  FETCH_EXPIRED_CONTRACTS_SUCCESS,
  FETCH_EXPIRED_CONTRACTS_FAIL,
] = createRequestConstantNames(cc('FETCH_EXPIRED_CONTRACTS'));

export const EXPIRED_CONTRACT_WINDOW_DAYS = 14;
