import { ActionMeta, BaseAction, createAction } from 'redux-actions';

import { Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { fetchInventory } from 'store/modules/siInventory/api';
import { Location as SILocation } from 'store/modules/siLocations';

import { SIInventoryAndLocationPreprocess, SIInventoryRenderableResult } from '../types';
import { Filters } from '../config/types';
import applyFilterMappers from '../config/siFilterMap';
import { inventorySearchQuery, SIInventoryResponse } from '../config/siQueries';
import { serializeSearchResultSI } from '../serializers/inventorySerializer';
import SerializersMapWithItems from '../config/serializersMapWithItems';

import { locationsForInventory } from './utils';

// TODO: rename this file (and actions file) to inventoryQueryService once paperwork moves off of
//       existing reducer
// ticket: https://jira.weworkers.io/browse/IMS-2242

const pageSize = 100;

export const CLEAR_INVENTORY_SI = cc('CLEAR_INVENTORY_SI');
export const SEARCH_INVENTORY_SI = cc('SEARCH_INVENTORY_SI');
export const SEARCH_INVENTORY_SI_SUCCESS = cc('SEARCH_INVENTORY_SI_SUCCESS');
export const SEARCH_INVENTORY_SI_FAIL = cc('SEARCH_INVENTORY_SI_FAIL');
export const SEARCH_INVENTORY_SI_RESET = cc('SEARCH_INVENTORY_SI_RESET');

export const fetchAndMergeInventoryAction = createAction(SEARCH_INVENTORY_SI, null, () => ({
  keepCurrentResult: true,
}));
export const fetchAndMergeInventorySuccessAction = createAction(
  SEARCH_INVENTORY_SI_SUCCESS,
  null,
  () => ({ keepCurrentResult: true })
);
export const fetchAndMergeInventoryFailAction = createAction(
  SEARCH_INVENTORY_SI_FAIL,
  null,
  () => ({ keepCurrentResult: true })
);
export const fetchAndMergeInventoryResetAction = createAction(SEARCH_INVENTORY_SI_RESET);

export const initialResultState: SIInventoryRenderableResult = {
  inventory: [],
  inventoryGroups: [],
  metadata: {
    pagination: {
      offset: 0,
      limit: pageSize,
      totalCount: 0,
    },
    location: {
      availableCount: {},
    },
  },
  groupsMetadata: {
    pagination: {
      offset: 0,
      limit: pageSize,
      totalCount: 0,
    },
    location: {
      availableCount: {},
    },
  },
  rawPayload: {
    data: [],
    pageInfo: {
      offset: 0,
      limit: pageSize,
      totalCount: 0,
    },
    meta: {
      availableCount: [],
    },
  },
};

export const resetInventorySI = () => (dispatch: Dispatch<BaseAction>): any =>
  dispatch(fetchAndMergeInventoryResetAction());

export const searchInventorySI = (filters: Filters, offset: number, limit: number) => async (
  dispatch: Dispatch<BaseAction>
): Promise<ActionMeta<SIInventoryAndLocationPreprocess, {}> | null> => {
  try {
    dispatch(fetchAndMergeInventoryAction());

    const variables = {
      filters: applyFilterMappers(filters),
      pagination: { offset, limit },
    };
    const inventory: SIInventoryResponse = await fetchInventory(inventorySearchQuery, variables);
    if (inventory.error) {
      return dispatch(fetchAndMergeInventoryFailAction(inventory.error));
    }

    const { locationResponses, errors } = await locationsForInventory(
      inventory.data.searchInventory.data
    );
    // extract required location ids
    if (errors && errors.length > 0) {
      return dispatch(fetchAndMergeInventoryFailAction(errors));
    }
    const locations: Array<SILocation> = locationResponses.map(resp => resp.data.location);

    // combine results
    const result: SIInventoryAndLocationPreprocess = {
      locations,
      inventory: inventory.data.searchInventory,
      isSearchGroups: filters.searchGroups || false,
    };

    return dispatch(fetchAndMergeInventorySuccessAction(result));
  } catch (err: any) {
    return dispatch(fetchAndMergeInventoryFailAction(err));
  }
};

const reducer = createRequestReducer<SIInventoryRenderableResult, SIInventoryAndLocationPreprocess>(
  [
    SEARCH_INVENTORY_SI,
    SEARCH_INVENTORY_SI_SUCCESS,
    SEARCH_INVENTORY_SI_FAIL,
    SEARCH_INVENTORY_SI_RESET,
  ],
  initialResultState,
  (res, prevState) => {
    const serializedResult = serializeSearchResultSI(res?.payload, SerializersMapWithItems);
    const inventory = res.payload.isSearchGroups ? prevState.inventory : serializedResult.inventory;
    const inventoryGroups = res.payload.isSearchGroups
      ? serializedResult.inventory
      : prevState.inventoryGroups;

    const metadata = res.payload.isSearchGroups ? prevState.metadata : serializedResult.metadata;
    const groupsMetadata = res.payload.isSearchGroups
      ? serializedResult.metadata
      : prevState.metadata;

    const result = {
      inventory,
      inventoryGroups,
      metadata,
      groupsMetadata,
      rawPayload: serializedResult.rawPayload,
    };

    return result;
  }
);

export default reducer;
