import { createAction } from 'redux-actions';
import { EventTypes } from 'redux-segment';

import { updateEmployeeLocation } from 'features/employees/redux/employees/actions';
import { EventNameTypes } from 'store/constants';
import { BaseAction, Dispatch, GetGlobalState } from 'store/types';

import {
  AUTHORIZE,
  AUTHORIZE_FAIL,
  AUTHORIZE_SUCCESS,
  LOGOUT,
  UPDATE_USER_INFO,
} from './constants';
import { getUserInfoFromAuth } from './selectors';

const analyticsMeta = (label: string, properties: {} = {}) => ({
  eventType: EventTypes.track,
  eventPayload: {
    event: EventNameTypes.interaction,
    properties: {
      event: 'Authentication',
      label,
      ...properties,
    },
  },
});

const updateUserInfo = createAction(UPDATE_USER_INFO);

export const saveLocationAsDefault = (location: { uuid: string }) => (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  const employeeUuid = getUserInfoFromAuth(getState()).uuid ?? '';

  if (!employeeUuid) {
    throw Promise.reject(new Error('Unable to save default location, missing employeeUuid'));
  }

  return dispatch(updateEmployeeLocation(employeeUuid, location.uuid)).then(() =>
    dispatch(updateUserInfo({ locationUuid: location.uuid }))
  );
};

export const authorizeBegin = createAction(AUTHORIZE, null, () => ({
  analytics: analyticsMeta('authorize_begin'),
}));

export const authorizeSuccess = createAction(AUTHORIZE_SUCCESS, null, () => ({
  analytics: analyticsMeta('authorize_success'),
}));

export const authorizeFail = createAction(AUTHORIZE_FAIL, null, (_, meta) => ({
  ...meta,
  analytics: analyticsMeta('authorize_failure'),
}));

export const logoutAction = createAction(LOGOUT, null, () => ({
  analytics: analyticsMeta('logout'),
}));
