import { handleActions, createAction } from 'redux-actions';

import { GlobalState } from 'store/modules';
import { PaperworkSubset } from 'features/paperwork/ducks';

export const SET_SF_OPPORTUNITY_ID = 'SET_SF_OPPORTUNITY_ID';

export interface OpportunityState {
  id: string;
}

export const getSfOpportunity = (state: GlobalState | PaperworkSubset) => {
  return state.paperwork.agreementManagement.opportunity;
};

export const getSfOpportunityId = (state: GlobalState | PaperworkSubset) => {
  return getSfOpportunity(state).id;
};

export const initialState = { id: '' };

export const setSfOpportunity = createAction(SET_SF_OPPORTUNITY_ID, (id: string) => ({ id }));

const reducer = handleActions<OpportunityState, any>(
  {
    [SET_SF_OPPORTUNITY_ID]: (state, action) => ({
      ...state,
      id: action.payload.id,
    }),
  },
  initialState
);

export default reducer;
