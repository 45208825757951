import { ActionType, Method } from 'store/util/createEntity/types';
import createEntity from 'store/util/createEntity';
import config from 'config';
import { BaseAction, GetGlobalState, Dispatch } from 'store/types';
import { GlobalState } from 'store/modules';
import {
  mapAccountUserToAccountUserPublicData,
  mapAccountUserToAccountUserPrivateData,
} from 'store/modules/accounts/mappers';
import { AccountUser } from 'store/modules/accounts/types';

export type Member = {
  phone: string;
  email: string;
  seats: Array<string>;
  status: string;
  location_uuid: string;
  uuid: string;
  name: string;
  date_of_birth: string;
};

const customActions = {
  activate: {
    type: 'update' as ActionType,
    method: 'POST' as Method,
    endpoint: ({ id }) => `/api/v2/users/${id}/activate`,
  },
  deactivate: {
    type: 'update' as ActionType,
    method: 'POST' as Method,
    endpoint: ({ id }) => `/api/v2/users/${id}/deactivate`,
  },
  resetPassword: {
    type: 'update' as ActionType,
    method: 'POST' as Method,
    endpoint: ({ id }) => `/api/v2/users/${id}/reset_password_instructions`,
  },
};

const actions = {
  baseUrl: config.id.uri,
  endpoint: '', // Overridden per action
  getPayloadFromResponse: ({ result }) => result,
  overrides: {
    query: {
      endpoint: '/api/v3/users',
    },
    find: {
      // Bypass Spacestation's API gateway, since ID can now handle employee JWT's
      // This is necessary because the gateway rewrites the auth header, and this ID endpoint relies on claims in the
      // JWT for authorization.
      baseUrl: config.id.webUri,
      endpoint: ({ id }) => `/api/v3/users/${id}`,
    },
    update: {
      endpoint: ({ id }) => `/api/v2/users/${id}`,
      getPayloadFromResponse: ({ result }) => result.user,
    },
    create: {
      endpoint: ({ id: companyUuid }) => `/api/v2/companies/${companyUuid}/members`,
      getPayloadFromResponse: ({ result }) => result.user,
    },
  },
  customActions,
};

const Members = createEntity<Member, typeof customActions>('members', actions);

const accountMemberActions = {
  baseUrl: config.accountService.uri,
  endpoint: '/v1/users',
  getPayloadFromResponse: ({ result }) => result,
};

const AccountMembers = createEntity<AccountUser>('accountMembers', accountMemberActions);

export default {
  ...Members,
  update: (uuid: string, req: { body: AccountUser }) => {
    return (dispatch: Dispatch<BaseAction, GlobalState>, getState: GetGlobalState) => {
      return Promise.all([
        AccountMembers.update(uuid, { body: mapAccountUserToAccountUserPrivateData(req.body) })(
          dispatch,
          getState
        ),
        Members.update(uuid, { body: mapAccountUserToAccountUserPublicData(req.body) })(
          dispatch,
          getState
        ),
      ]);
    };
  },
};
