import React from 'react';
import cn from 'classnames';

import styles from './formControl.scss';

type Props = Readonly<{
  children?: React.ReactNode;
  size?: string;
  inline?: boolean;
  height?: string;
  display?: string;
  className: string | null | undefined;
}>;

const FormControl = ({ children, size, inline, display, height, className }: Props) => (
  <div
    className={cn(styles.base, className, {
      [styles.sizeTiny]: size === 'tiny',
      [styles.sizeSmall]: size === 'small',
      [styles.inline]: inline,
      [styles.heightSquashed]: height === 'squashed',
      [styles.displayBlock]: display === 'block',
    })}
  >
    {children}
  </div>
);

FormControl.defaultProps = {
  className: null,
};

export default FormControl;
