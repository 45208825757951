import React from 'react';
import { OperatorServicePermissionsMap } from '@wework/we-auth-react';
import { IconName } from '@wework/ray2';

import { Location } from 'store/modules/siLocations/types';
import { getSpacemanCode } from 'store/modules/siLocations/utils';
import { Options } from 'store/modules/analytics';
import { ExperimentGroups } from 'store/middlewares/experimentsHelper';
import navBuildingIcon from 'images/building-sidebar-icon.svg';
import navInventoryIcon from 'images/inventory-sidebar-icon.svg';
import navMembersIcon from 'images/members-sidebar-icon.svg';
import navMimoIcon from 'images/mimo-sidebar-icon.svg';
import navAdminIcon from 'images/nav-admin.svg';
import { EmployeePermissions } from 'lib/permissions';

import {
  EMPLOYEES,
  CONTRACTORS,
  FLOOR_PLAN_3D,
  MICROSITE_POC,
  OPERATORS,
  ROLES_AND_PERMISSIONS,
  ID_SCIM,
  SSO_CONFIGS,
  ACCESS_LEVEL_GROUPS,
  ACCOUNT_SERVICE_DNS_VERIFICATIONS,
  SERVICE_CLIENTS,
  SERVICE_CLIENT_PERMISSIONS,
  MICROFRONTEND_APP_TEMPLATE,
} from '../singleSpa/routes';

export type Item = {
  hidden?: boolean;
  icon?: string;
  rayIcon?: IconName;
  id: string;
  items?: Array<Item>;
  label?: string;
  linkComponent?: React.ReactNode;
  tagText?: string;
  onlyWithExperiment?: string;
  onlyWithPermissions?: Array<string>;
  dataQa?: string;
  url?: string;
  trackEvent?: string;
  trackEventMetadata?: Options;
};

const isNotEmpty = ({ items }: Item) => (items ? items.length > 0 : false);

const getBuildingItems = (): Item => ({
  label: 'Building',
  id: 'home',
  dataQa: 'building-link',
  icon: navBuildingIcon,
  rayIcon: IconName.BUILDING,
  items: [
    {
      label: 'About',
      id: 'about',
      dataQa: 'about-link',
      url: '/about',
    },
    {
      label: 'Visitors',
      id: 'visitors',
      dataQa: 'visitors-link',
      url: '/visitors',
    },
    {
      label: 'Events',
      id: 'events',
      dataQa: 'events-link',
      url: '/events',
    },
    {
      label: 'Communications',
      id: 'communcations',
      dataQa: 'communcations-link',
      url: '/communications',
    },
    {
      label: 'Room bookings',
      id: 'rooms',
      dataQa: 'rooms-link',
      url: '/rooms',
      trackEvent: 'View Rooms',
      trackEventMetadata: {
        workflow: 'homepage',
        feature: 'roomsOverview',
        featureContext: 'navbar',
      },
    },
    {
      label: 'Billing',
      id: 'billing',
      dataQa: 'billing-link',
      url: '/invoiceReview',
    },
    {
      label: FLOOR_PLAN_3D.label,
      id: '3DFloorPlan',
      onlyWithPermissions: [
        EmployeePermissions.spacestation_floor_plan_view,
        EmployeePermissions.spacestation_floor_plan_asset_download,
      ],
      dataQa: '3d-floor-plan-link',
      url: FLOOR_PLAN_3D.path,
    },
  ],
});

const getInventoryItems = (locationCode: string): Item => ({
  dataQa: 'inventory-link',
  label: 'Inventory',
  id: 'inventory',
  icon: navInventoryIcon,
  rayIcon: IconName.FLOOR_PLAN,
  onlyWithPermissions: [EmployeePermissions.view_occupancy_info],
  items: [
    {
      label: 'Inventory manager',
      id: 'inventorymanager',
      onlyWithPermissions: [EmployeePermissions.view_occupancy_info],
      dataQa: 'inventory-manager-link',
      url: '/inventory-manager',
    },
    {
      label: 'Your inventory',
      id: 'your.inventory',
      onlyWithPermissions: [EmployeePermissions.view_occupancy_info],
      dataQa: 'your-inventory-link',
      url: `/locations/${locationCode}/inventory`,
    },
    {
      label: 'Inventory search',
      id: 'inventory.search',
      onlyWithPermissions: [EmployeePermissions.view_occupancy_info],
      dataQa: 'inventory-search-link',
      url: '/locations/inventory/search',
    },
    {
      label: 'Renewals management',
      id: 'renewals',
      dataQa: 'renewals-link',
      onlyWithExperiment: 'ssRenewals',
      url: '/renewals',
    },
  ],
});

const getMemberItems = (): Item => ({
  dataQa: 'members-link',
  label: 'Members',
  id: 'members',
  icon: navMembersIcon,
  rayIcon: IconName.PEOPLE,
  items: [
    {
      label: 'Directory',
      id: 'buildingDirectory',
      dataQa: 'building-directory-link',
      url: '/directory',
    },
    {
      label: 'Milestones',
      id: 'milestones',
      dataQa: 'milestones-link',
      url: '/milestones',
      trackEvent: 'View Milestones',
      trackEventMetadata: {
        workflow: 'homepage',
        feature: 'memberMilestones',
        featureContext: 'navbar',
      },
    },
    {
      label: 'Check-ins',
      id: 'check-ins',
      dataQa: 'check-ins-link',
      url: '/check-ins',
      trackEvent: 'Click check-ins side nav',
      trackEventMetadata: {
        label: 'spacestation_checkins_enter_click',
        object: 'enter',
        screen_name: 'checkins',
        feature_group: 'checkins',
        action: 'click',
        event: 'Interaction',
        tool: 'spacestation',
        workflow: '',
        featureContext: '',
        feature: '',
      },
    },
  ],
});

const getMimoItems = (): Item => ({
  label: 'MIMO',
  id: 'mimo',
  icon: navMimoIcon,
  rayIcon: IconName.TRUCK,
  items: [
    {
      label: 'MIMO tracker',
      id: 'mimo-tracker',
      dataQa: 'mimo-tracker-link',
      url: '/mimo-tracker',
    },
    {
      label: 'Move requests',
      id: 'mimo-requests',
      dataQa: 'mimo-requests-link',
      url: '/mimo-requests',
    },
  ],
});

export const getMicrositeItems = (): Item => ({
  label: 'Microsites',
  id: 'microsites',
  icon: navMembersIcon,
  rayIcon: IconName.PEOPLE,
  items: [
    {
      label: MICROSITE_POC.label,
      id: 'microsite',
      onlyWithExperiment: 'ssMicrositePOC',
      dataQa: 'microsite-link',
      url: MICROSITE_POC.path,
    },
    {
      label: MICROFRONTEND_APP_TEMPLATE.label,
      id: 'micro-frontend-template',
      onlyWithExperiment: 'ssMicrositePOC',
      dataQa: 'micro-frontend-template-link',
      url: MICROFRONTEND_APP_TEMPLATE.path,
    },
  ],
});

const getAdminItems = (locationCode: string): Item => ({
  dataQa: 'admin-link',
  label: 'Admin',
  id: 'admin',
  icon: navAdminIcon,
  rayIcon: IconName.UTILITIES,
  items: [
    {
      label: OPERATORS.label,
      id: 'operators',
      onlyWithPermissions: [
        EmployeePermissions.opsrv_manage_operators,
        EmployeePermissions.opsrv_view_operators,
      ],
      dataQa: 'operators-link',
      url: OPERATORS.path,
    },
    {
      label: ROLES_AND_PERMISSIONS.label,
      id: 'roles-and-permissions',
      onlyWithPermissions: [
        EmployeePermissions.opsrv_manage_roles_and_permissions,
        EmployeePermissions.opsrv_view_roles_and_permissions,
      ],
      dataQa: 'roles-and-permissions-link',
      url: ROLES_AND_PERMISSIONS.path,
    },
    {
      label: ID_SCIM.label,
      id: 'id-scim',
      onlyWithPermissions: [EmployeePermissions.id_scim_manage_tenants],
      dataQa: 'id-scim-link',
      url: ID_SCIM.path,
    },
    {
      label: EMPLOYEES.label,
      id: 'employees',
      onlyWithPermissions: [EmployeePermissions.opsrv_view_employees],
      dataQa: 'employees-link',
      url: EMPLOYEES.path,
    },
    {
      label: CONTRACTORS.label,
      id: 'contractors',
      onlyWithPermissions: [EmployeePermissions.id_view_contractors],
      dataQa: 'contractors-link',
      url: CONTRACTORS.path,
    },
    {
      label: 'Office loader',
      id: 'offices',
      onlyWithPermissions: [EmployeePermissions.update_occupiable_pricing_capacity],
      dataQa: 'office-loader-link',
      url: `/locations/${locationCode}/offices`,
    },
    {
      label: 'Promotions',
      id: 'promotions',
      onlyWithExperiment: 'ssPromotions',
      dataQa: 'promotions-link',
      url: '/promotions',
    },
    {
      label: 'Access Reports',
      id: 'accessReports',
      onlyWithPermissions: [EmployeePermissions.spacestation_security_only_access],
      onlyWithExperiment: 'ssAccessReports',
      dataQa: 'access-reports-link',
      url: '/access-reports',
    },
    {
      label: ACCESS_LEVEL_GROUPS.label,
      id: 'accessLevelGroups',
      onlyWithPermissions: [EmployeePermissions.spacestation_security_only_access],
      onlyWithExperiment: 'ssAccessLevelGroups',
      dataQa: 'access-level-groups-link',
      url: ACCESS_LEVEL_GROUPS.path,
    },
    {
      label: SSO_CONFIGS.label,
      id: 'ssoConfigs',
      onlyWithPermissions: [EmployeePermissions.id_scim_manage_sso_configs],
      dataQa: 'sso-configs-link',
      url: SSO_CONFIGS.path,
    },
    {
      label: ACCOUNT_SERVICE_DNS_VERIFICATIONS.label,
      id: 'accountDnsVerifications',
      onlyWithPermissions: [EmployeePermissions.account_service_view_all_dns_verifications],
      dataQa: 'account-dns-verifications',
      url: ACCOUNT_SERVICE_DNS_VERIFICATIONS.path,
    },
    {
      label: SERVICE_CLIENTS.label,
      id: 'serviceClients',
      onlyWithPermissions: [EmployeePermissions.opsrv_read_service_clients],
      url: SERVICE_CLIENTS.path,
    },
    {
      label: SERVICE_CLIENT_PERMISSIONS.label,
      id: 'serviceClientPermissions',
      onlyWithPermissions: [EmployeePermissions.opsrv_read_service_permissions],
      url: SERVICE_CLIENT_PERMISSIONS.path,
    },
  ],
});

export const getItems = (
  experimentGroups: ExperimentGroups,
  requestedPermissions: OperatorServicePermissionsMap,
  currentLocation: Location | null | undefined
): Array<Item> => {
  const locationCode = currentLocation ? getSpacemanCode(currentLocation) : '';
  const isItemShown = ({ hidden, onlyWithExperiment, onlyWithPermissions }: Item) => {
    if (hidden) return false;
    const matchesExperiment = !onlyWithExperiment || experimentGroups[onlyWithExperiment] === 'on';
    const matchesPermissions =
      !onlyWithPermissions ||
      onlyWithPermissions.some(permission => requestedPermissions[permission]);
    return matchesExperiment && matchesPermissions;
  };

  const items = [
    getBuildingItems(),
    getInventoryItems(locationCode),
    getMemberItems(),
    getMimoItems(),
    getMicrositeItems(),
    getAdminItems(locationCode),
  ];

  // @ts-ignore Only for E2E Cypress test PoC
  if (window.__Cypress__ || window.Cypress || window.cy) {
    // @ts-ignore
    window.cypressMenuItems = items
      .slice(0, -1) // Don't include the Admin items
      .map(item => ({ ...item, items: item.items && item.items.filter(isItemShown) }))
      .filter(isItemShown)
      .filter(isNotEmpty);
  }

  return items
    .map(item => ({ ...item, items: item.items && item.items.filter(isItemShown) }))
    .filter(isItemShown)
    .filter(isNotEmpty);
};
