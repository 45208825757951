import { createAction } from 'redux-actions';
import moment from 'moment-timezone';
import { startOfMonth, addMonths, format } from 'date-fns';

import { createRequestConstantNames } from 'store/util/createConstants';
import { createRequestAction } from 'store/util';
import config from 'config';
import cc from 'store/util/createReduxConstant';
import { camelCaseJson } from 'lib/util';
import { BaseAction, Dispatch } from 'store/types';

import { AccountLocation, UpdateNotesResponse, UpcomingBillResponse } from '../types';

// Action Constants
export const FETCH_INVOICES = cc('FETCH_INVOICES');
export const FETCH_INVOICES_SUCCESS = cc('FETCH_INVOICES_SUCCESS');
export const FETCH_INVOICES_FAIL = cc('FETCH_INVOICES_FAIL');

export const FETCH_CURRENT_LOCATION = cc('FETCH_CURRENT_LOCATION');
export const FETCH_CURRENT_LOCATION_SUCCESS = cc('FETCH_CURRENT_LOCATION_SUCCESS');
export const FETCH_CURRENT_LOCATION_FAIL = cc('FETCH_CURRENT_LOCATION_FAIL');

export const FETCH_ADDONS = cc('FETCH_ADDONS');
export const FETCH_ADDONS_SUCCESS = cc('FETCH_ADDONS_SUCCESS');
export const FETCH_ADDONS_FAIL = cc('FETCH_ADDONS_FAIL');

export const UPDATE_ACCOUNT = cc('UPDATE_ACCOUNT');

export const SET_ACCOUNTS_FILTER = cc('SET_ACCOUNTS_FILTER');

export const FETCH_RISK_ASSESSMENT = cc('FETCH_RISK_ASSESSMENT');
export const FETCH_RISK_ASSESSMENT_SUCCESS = cc('FETCH_RISK_ASSESSMENT_SUCCESS');
export const FETCH_RISK_ASSESSMENT_FAIL = cc('FETCH_RISK_ASSESSMENT_FAIL');

export const FETCH_ACCOUNT_HOLDS = cc('FETCH_ACCOUNT_HOLDS');
export const FETCH_ACCOUNT_HOLDS_SUCCESS = cc('FETCH_ACCOUNT_HOLDS_SUCCESS');
export const FETCH_ACCOUNT_HOLDS_FAIL = cc('FETCH_ACCOUNT_HOLDS_FAIL');

export const SET_REVIEWED = cc('SET_REVIEWED');
export const SET_REVIEWED_SUCCESS = cc('SET_REVIEWED_SUCCESS');
export const SET_REVIEWED_FAIL = cc('SET_REVIEWED_FAIL');

export const INVOICE_REVIEW_UPDATE_NOTES = cc('INVOICE_REVIEW_UPDATE_NOTES');
export const INVOICE_REVIEW_UPDATE_NOTES_SUCCESS = cc('INVOICE_REVIEW_UPDATE_NOTES_SUCCESS');
export const INVOICE_REVIEW_UPDATE_NOTES_FAIL = cc('INVOICE_REVIEW_UPDATE_NOTES_FAIL');

export const [
  SET_ALWAYS_FLAGGED,
  SET_ALWAYS_FLAGGED_SUCCESS,
  SET_ALWAYS_FLAGGED_FAIL,
] = createRequestConstantNames(cc('SET_ALWAYS_FLAGGED'));

export const [
  FETCH_UPCOMING_BILLS,
  FETCH_UPCOMING_BILLS_SUCCESS,
  FETCH_UPCOMING_BILLS_FAIL,
] = createRequestConstantNames(cc('FETCH_UPCOMING_BILLS'));

export const SELECT_FILTER_VALUE = cc('SELECT_FILTER_VALUE');

export const SET_INVOICE_LOADING = cc('SET_INVOICE_LOADING');

export const SET_ACCOUNT_UI_REVIEWED = cc('SET_ACCOUNT_UI_REVIEWED');

export const [FETCH_TAGS, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAIL] = createRequestConstantNames(
  cc('FETCH_TAGS')
);

export const setInvoiceLoading = () => ({
  type: SET_INVOICE_LOADING,
});

export const setAccountUIReviewed = createAction(
  SET_ACCOUNT_UI_REVIEWED,
  (accountUuid: string, reviewed: boolean) => ({
    accountUuid,
    reviewed,
  })
);

export const setSearchFilter = createAction(SET_ACCOUNTS_FILTER);

export const fetchCurrentLocation = (locationUuid: string) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'GET',
    endpoint: `${config.spaceman.uri}/api/locations/${locationUuid}`,
    types: [FETCH_CURRENT_LOCATION, FETCH_CURRENT_LOCATION_SUCCESS, FETCH_CURRENT_LOCATION_FAIL],
    getPayloadFromResponse: camelCaseJson,
  });

  return dispatch(requestAction);
};

export const fetchInvoices = (locationUuid: string, accountUuid: string) => (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.bpGateway.uri}`,
    types: [FETCH_INVOICES, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_FAIL],
    getPayloadFromResponse: camelCaseJson,
    body: {
      query: `{
  invoices(account: "${accountUuid}", location: "${locationUuid}", startDate:"${moment()
        .subtract(1, 'months')
        .startOf('month')
        .toString()}", endDate:"${moment().endOf('month').toString()}") {
    uuid,
    issuedOn,
    taxLineItems {
      id,
      name,
      rate,
      totalSum {
        amount,
        currency
      }
    }
    pdfLink
    invoiceLink
    kind
    totalSum {
      amount,
      currency
    }
    period
    lineItems {
      category,
      endDate,
      id,
      reservationUuid,
      reservationId,
      productDetails{
        inventory,
        type,
        unitPrice{
          amount,
          currency
        }
      },
      discountDetails {
        reservationUuid,
        totalSum {
          amount,
          currency
        }
      }
      quantity,
      startDate,
      totalSum{
        amount,
        currency
      }
    }
  }
}`,
    },
  });

  return dispatch(requestAction);
};

export const fetchUpcomingBill = (locationUuid: string, accountUuid: string) => {
  const params = {
    paying_entity_uuid: `${accountUuid}`,
    receiving_entity_uuid: `${locationUuid}`,
    started_on: `${format(startOfMonth(addMonths(new Date(), 1)), 'yyyy-MM-dd')}`,
    locale: `en_US`,
  };

  return createRequestAction<UpcomingBillResponse>({
    method: 'GET',
    endpoint: `${config.upcomingBill.uri}/previews?${new URLSearchParams(params).toString()}`,
    types: [FETCH_UPCOMING_BILLS, FETCH_UPCOMING_BILLS_SUCCESS, FETCH_UPCOMING_BILLS_FAIL],
    getPayloadFromResponse: camelCaseJson,
  });
};

export const fetchTags = (accountUuids: Array<string>) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.spaceman.uri}/api/v5/account_tagger/tags_by_accounts`,
    types: [FETCH_TAGS, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAIL],
    body: { accounts: accountUuids },
  });

  return requestAction;
};
export const fetchAddons = (accountUuid: string) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'GET',
    endpoint: `${config.spaceman.uri}/api/v3/accounts/${accountUuid}/reservations?include_authorizations=true`,
    types: [FETCH_ADDONS, FETCH_ADDONS_SUCCESS, FETCH_ADDONS_FAIL],
    getPayloadFromResponse: camelCaseJson,
  });

  return dispatch(requestAction);
};

export const fetchRiskAssessment = (accountsLocations: Array<AccountLocation>) => (
  dispatch: Dispatch<BaseAction>
) => {
  let queryString = '{ accountsRiskAssessment( accountsLocations: [';
  accountsLocations.forEach(({ account, location }) => {
    queryString += `{location: "${location}", account: "${account}"}\n`;
  });
  queryString +=
    ']){ score{accountUuid\nlocationUuid\nscore\nmainReason\nreasonValue\n}\nreviewInfo{\nupdatedBy\nreviewed\n}\n}\n}';

  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: config.bpGateway.uri,
    types: [FETCH_RISK_ASSESSMENT, FETCH_RISK_ASSESSMENT_SUCCESS, FETCH_RISK_ASSESSMENT_FAIL],
    body: { query: queryString },
  });

  return dispatch(requestAction);
};

export const setReviewed = (
  accountUuid: string,
  locationUuid: string,
  userUuid: string,
  reviewed: boolean
) => (dispatch: Dispatch<BaseAction>) => {
  const mutationString = `mutation{setAccountReview( accountAndLocation: { account: "${accountUuid}" location: "${locationUuid}"}, reviewed: ${reviewed.toString()}, updatedBy: "${userUuid}"){status}}`;

  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: config.bpGateway.uri,
    types: [SET_REVIEWED, SET_REVIEWED_SUCCESS, SET_REVIEWED_FAIL],
    meta: { accountUuid, userUuid, reviewed },
    body: { query: mutationString },
  });

  return dispatch(requestAction);
};

export const updateNotes = (accountUuid: string, locationUuid: string, notes: string) => (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction<UpdateNotesResponse>({
    method: 'PUT',
    endpoint: `${config.spaceman.uri}/api/v2/accounts/${accountUuid}/update_notes?location_uuid=${locationUuid}`,
    types: [
      INVOICE_REVIEW_UPDATE_NOTES,
      INVOICE_REVIEW_UPDATE_NOTES_SUCCESS,
      INVOICE_REVIEW_UPDATE_NOTES_FAIL,
    ],
    meta: { accountUuid, locationUuid, notes },
    body: { notes },
  });

  return dispatch(requestAction);
};

export const setAlwaysFlagged = (accountShortId: string, accountUuid: string, flagged: boolean) => {
  return createRequestAction({
    method: flagged ? 'POST' : 'DELETE',
    endpoint: `${config.spaceman.uri}/api/v5/account_tagger${
      flagged
        ? `?tag_id=26&short_code=${accountShortId}`
        : `/delete?short_code=${accountShortId}&tag_id=26`
    }`,
    types: [SET_ALWAYS_FLAGGED, SET_ALWAYS_FLAGGED_SUCCESS, SET_ALWAYS_FLAGGED_FAIL],
    meta: { accountUuid, flagged },
  });
};

export const selectFilterValue = (filterValue: string | undefined) => ({
  type: SELECT_FILTER_VALUE,
  payload: filterValue,
});

export const fetchAccountHolds = (locationUuid: string) => (dispatch: Dispatch<BaseAction>) => {
  const endpoint = `${config.spaceman.uri}/api/locations/${locationUuid}/account_holds?page=1&per_page=1000`;

  const requestAction = createRequestAction({
    endpoint,
    method: 'GET',
    types: [FETCH_ACCOUNT_HOLDS, FETCH_ACCOUNT_HOLDS_SUCCESS, FETCH_ACCOUNT_HOLDS_FAIL],
    getPayloadFromResponse: res => res.result,
  });

  return dispatch(requestAction);
};
