import { handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';
import {
  BookingCreditOverageLimitState,
  BookingCreditOverage,
} from 'store/modules/userGroups/types';
import {
  FETCH_BOOKING_CREDIT_OVERAGE_LIMIT,
  FETCH_BOOKING_CREDIT_OVERAGE_LIMIT_SUCCESS,
  FETCH_BOOKING_CREDIT_OVERAGE_LIMIT_FAIL,
} from 'store/modules/userGroups/constants';

const initialBookingCreditOverageLimitState: BookingCreditOverageLimitState = {
  bookingCreditOverageLimit: {
    limit: null,
  },
  bookingCreditOverageLimitLoading: false,
};

const bookingCreditOverageLimitReducer = handleActions<
  BookingCreditOverageLimitState,
  BookingCreditOverage
>(
  {
    [FETCH_BOOKING_CREDIT_OVERAGE_LIMIT]: (state: BookingCreditOverageLimitState) => ({
      ...state,
      bookingCreditOverageLimitLoading: true,
    }),
    [FETCH_BOOKING_CREDIT_OVERAGE_LIMIT_SUCCESS]: (
      state: BookingCreditOverageLimitState,
      action: ActionWithPayload<BookingCreditOverage>
    ) => ({
      ...state,
      bookingCreditOverageLimit: action.payload,
      bookingCreditOverageLimitLoading: false,
    }),
    [FETCH_BOOKING_CREDIT_OVERAGE_LIMIT_FAIL]: (state: BookingCreditOverageLimitState) => ({
      ...state,
      bookingCreditOverageLimitLoading: false,
    }),
  },
  initialBookingCreditOverageLimitState
);

export default bookingCreditOverageLimitReducer;
