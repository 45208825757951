import { combineReducers } from 'redux';

import { RequestState } from 'store/util/createRequestReducer';
import { InventoryQueryResult } from 'features/renewals/redux/inventoryItems/types';
import addressNames from 'features/renewals/redux/addressNames/reducer';
import inventoryItems from 'features/renewals/redux/inventoryItems/reducers';
import commitmentItems from 'features/renewals/redux/commitmentItems/reducer';
import { LocationState } from 'features/renewals/redux/locations/types';
import locations from 'features/renewals/redux/locations/reducer';
import availableDiscounts from 'features/renewals/redux/availableDiscounts/reducer';
import { Tasks } from 'features/renewals/redux/tasks/types';
import tasks from 'features/renewals/redux/tasks/reducer';
import { Suggestions } from 'features/renewals/redux/availableDiscounts/types';
import { AccountExpiringCommitmentTerms } from 'features/renewals/redux/commitmentItems/types';
import priceChanges from 'features/renewals/redux/priceChanges/reducers';
import { PriceChangeReservation } from 'features/renewals/redux/priceChanges/types';
import { ContractDetails, Opportunity } from 'features/renewals/redux/contractDetails/types';
import {
  contractReducer as contractDetails,
  opportunityReducer as opportunity,
} from 'features/renewals/redux/contractDetails/reducer';
import accountDiscounts from 'features/renewals/redux/accountDiscounts/reducer';
import { DiscountReservation } from 'features/renewals/redux/accountDiscounts/types';
import { NoticePeriodSelection } from 'features/renewals/redux/noticePeriodSelection/types';

import selectedNoticePeriod from './noticePeriodSelection/reducer';
import selectedLocations from './reducer';

export interface State {
  addressNames: RequestState<{ [key: string]: string } | null>;
  availableDiscounts: RequestState<Hash<Suggestions>>;
  commitmentItems: RequestState<AccountExpiringCommitmentTerms[] | null>;
  inventoryItems: RequestState<InventoryQueryResult>;
  locations: LocationState;
  priceChanges: RequestState<PriceChangeReservation[] | null>;
  selectedLocations: string[];
  selectedNoticePeriod: NoticePeriodSelection;
  tasks: RequestState<Tasks | null>;
  contractDetails: RequestState<Hash<ContractDetails>>;
  opportunity: RequestState<Opportunity | null>;
  accountDiscounts: RequestState<Hash<Array<DiscountReservation>>>;
}

export interface RenewalsSubset {
  renewals: State;
}

export default combineReducers<State>({
  addressNames,
  availableDiscounts,
  commitmentItems,
  inventoryItems,
  locations,
  priceChanges,
  selectedLocations,
  selectedNoticePeriod,
  tasks,
  contractDetails,
  opportunity,
  accountDiscounts,
});
