import React from 'react';
import { Tag } from '@wework/dieter-ui';
import cn from 'classnames';

import config from 'config';

interface Props {
  isMigrated: boolean;
  isPartiallyMigrated?: boolean;
  locationId?: string;
  relativeUrl?: string;
}

const MigratedToKubeTag = ({
  locationId,
  isMigrated = false,
  isPartiallyMigrated = false,
  relativeUrl,
}: Props) => {
  const kubeLocationLink =
    (locationId || relativeUrl) &&
    `${config.kube.uri}${relativeUrl || `/reseller/content/#/app/property/${locationId}`}`;

  if (!isMigrated && !isPartiallyMigrated) return null;
  return (
    <div className={cn(['mt-1 z-50 relative', { 'pointer-events-none': !kubeLocationLink }])}>
      <a href={kubeLocationLink || '#'} target="_blank" rel="noreferrer">
        <Tag color="green">{isMigrated ? 'migrated to kube' : 'partially migrated to kube'}</Tag>
      </a>
    </div>
  );
};

export default MigratedToKubeTag;
