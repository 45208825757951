import { groupBy } from 'lodash';
import { Reducer } from 'redux-actions';

import { Reason } from 'features/paperwork/globalAccess/form/downgradeReason';
import { createRequestAction, createRequestReducer } from 'store/util';
import { GlobalState } from 'store/modules';
import config from 'config';
import cc from 'store/util/createReduxConstant';

export const FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS = cc('FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS');
export const FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS_SUCCESS = cc(
  'FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS_SUCCESS'
);
export const FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS_FAIL = cc(
  'FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS_FAIL'
);

type Result = Array<Reason>;

export interface State {
  loaded: boolean;
  loading: boolean;
  error: null | Error;
  result: Result;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  result: [],
  error: null,
};

export const reducer: Reducer<State, any> = createRequestReducer<Result, {}>(
  [
    FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS,
    FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS_SUCCESS,
    FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS,
  ],
  []
);

export const fetchMembershipUpdateReasonOptions = () =>
  createRequestAction({
    endpoint: `${config.spacemoney.uri}/api/v1/selectable_reason_options`,
    method: 'GET',
    types: [
      FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS,
      FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS_SUCCESS,
      FETCH_MEMBERSHIP_UPDATE_REASON_OPTIONS_FAIL,
    ],
  });

// Selectors
export const getMembershipUpdateReasonOptions = (state: GlobalState): Hash<Array<Reason>> =>
  groupBy(
    state.paperwork.membershipUpdateReasonOptions.result,
    option => option.membership_update_type
  );

export default reducer;
