import config from 'config';
import ApiRequest from 'lib/api/apiRequest';
import { GetUsersResponse } from 'lib/api/id/types';
import { FetchCompaniesFromIDResponse } from 'features/mimo/types';

export default class IdAPI extends ApiRequest {
  static MAX_UUID_COUNT = 100;

  constructor() {
    super(config.id.uri);
  }

  getCompaniesFromId = async (uuids: string[]) => {
    const config = {
      isCamelCase: true,
    };
    const path = 'api/v3/companies';
    const promises = [] as Array<Promise<FetchCompaniesFromIDResponse>>;
    let offset = 0;

    while (offset < uuids.length) {
      const params = {
        'filter:uuid': uuids.slice(offset, offset + IdAPI.MAX_UUID_COUNT).join(','),
        include: '',
      };

      promises.push(this.get({ config, params, path }));
      offset += IdAPI.MAX_UUID_COUNT;
    }

    const resolved = await Promise.all(promises);
    return resolved.reduce((results, { result }) => {
      return [...results, ...result];
    }, []);
  };

  getUsersByUuid = async (uuids: string[]): Promise<GetUsersResponse> => {
    const request = {
      path: `api/v4/users?uuids=${uuids.toString()}`,
      config: {
        isCamelCase: false,
      },
    };

    return this.get(request);
  };
}
