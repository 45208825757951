import balloonEmoji from 'images/balloon-emoji.svg';
import buildingEmoji from 'images/building-emoji.svg';
import calendarEmoji from 'images/calendar-emoji.svg';
import checkBoxGreyEmoji from 'images/check-box-grey-emoji.svg';
import cocktailGlassEmoji from 'images/cocktail-glass-emoji.svg';
import couchWithLampEmoji from 'images/couch-with-lamp-emoji.svg';
import eyesEmoji from 'images/eyes-emoji.svg';
import faceWithCrossedEyesEmoji from 'images/face-with-crossed-eyes-emoji.svg';
import magnifyingGlassEmoji from 'images/magnifying-glass-emoji.svg';
import medalEmoji from 'images/medal-emoji.svg';
import memoEmoji from 'images/memo-emoji.svg';
import moneyBagEmoji from 'images/money-bag-emoji.svg';
import pageFacingUpEmoji from 'images/page-facing-up-emoji.svg';
import paletteEmoji from 'images/palette-emoji.svg';
import partyPopperEmoji from 'images/party-popper-emoji.svg';
import pensiveFaceEmoji from 'images/pensive-face-emoji.svg';
import pointingUpEmoji from 'images/pointing-up-emoji.svg';
import seeNoEvilMonkeyEmoji from 'images/see-no-evil-monkey-emoji.svg';
import slightlyFrowningFaceEmoji from 'images/slightly-frowning-face-emoji.svg';
import smilingFaceWithOpenHandsEmoji from 'images/smiling-face-with-open-hands-emoji.svg';
import sparkleEmoji from 'images/sparkle-emoji.svg';
import speakNoEvilMonkeyEmoji from 'images/speak-no-evil-monkey-emoji.svg';
import thinkingFaceEmoji from 'images/thinking-face-emoji.svg';
import threeOClockEmoji from 'images/three-o-clock-emoji.svg';
import thumbsUpEmoji from 'images/thumbs-up-emoji.svg';
import triangularFlagEmoji from 'images/triangular-flag-emoji.svg';
import truckEmoji from 'images/truck-emoji.svg';
import twoPeopleEmoji from 'images/two-people-emoji.svg';
import warningEmoji from 'images/warning-emoji.svg';
import wavingHandEmoji from 'images/waving-hand-emoji.svg';
import megaphoneEmoji from 'images/megaphone-emoji.svg';
import notificationBellEmoji from 'images/notification-bell-emoji.svg';

import { EmojiName } from './emojiName';

export const EmojiMap = {
  [EmojiName.BALLOON]: balloonEmoji,
  [EmojiName.BUILDING]: buildingEmoji,
  [EmojiName.CALENDAR]: calendarEmoji,
  [EmojiName.CHECK_BOX_GREY]: checkBoxGreyEmoji,
  [EmojiName.COCKTAIL_GLASS]: cocktailGlassEmoji,
  [EmojiName.COUCH_WITH_LAMP]: couchWithLampEmoji,
  [EmojiName.EYES]: eyesEmoji,
  [EmojiName.FACE_WITH_CROSSED_EYES]: faceWithCrossedEyesEmoji,
  [EmojiName.MAGNIFYING_GLASS]: magnifyingGlassEmoji,
  [EmojiName.MEDAL]: medalEmoji,
  [EmojiName.MEMO]: memoEmoji,
  [EmojiName.MONEY_BAG]: moneyBagEmoji,
  [EmojiName.PAGE_FACING_UP]: pageFacingUpEmoji,
  [EmojiName.PALETTE]: paletteEmoji,
  [EmojiName.PARTY_POPPER]: partyPopperEmoji,
  [EmojiName.PENSIVE_FACE]: pensiveFaceEmoji,
  [EmojiName.POINTING_UP]: pointingUpEmoji,
  [EmojiName.SEE_NO_EVIL_MONKEY]: seeNoEvilMonkeyEmoji,
  [EmojiName.SLIGHTLY_FROWNING_FACE]: slightlyFrowningFaceEmoji,
  [EmojiName.SMILING_FACE_WITH_OPEN_HANDS]: smilingFaceWithOpenHandsEmoji,
  [EmojiName.SPARKLE]: sparkleEmoji,
  [EmojiName.SPEAK_NO_EVIL_MONKEY]: speakNoEvilMonkeyEmoji,
  [EmojiName.THINKING_FACE]: thinkingFaceEmoji,
  [EmojiName.THREE_O_CLOCK]: threeOClockEmoji,
  [EmojiName.THUMBS_UP]: thumbsUpEmoji,
  [EmojiName.TRIANGULAR_FLAG]: triangularFlagEmoji,
  [EmojiName.TRUCK]: truckEmoji,
  [EmojiName.TWO_PEOPLE]: twoPeopleEmoji,
  [EmojiName.WARNING]: warningEmoji,
  [EmojiName.WAVING_HAND]: wavingHandEmoji,
  [EmojiName.MEGAPHONE]: megaphoneEmoji,
  [EmojiName.NOTIFICATION_BELL]: notificationBellEmoji,
};
