import config from 'config';
import ApiRequest from 'lib/api/apiRequest';

export default class BanAPI extends ApiRequest {
  constructor() {
    super(config.bans.uri);
  }

  /*
   * example:
   * getEvent(id: string) {
   *   const requestParams : RequestMethodsParams = { path: '/api/v1/events', params: { id } }
   *   return this.get(requestParams);
   * }
   */
}
