import { isRSAA, RSAA } from 'redux-api-middleware';

import config from 'config';
import rollbar from 'lib/rollbar';
import { BaseAction, Dispatch } from 'store/types';
import { notifyLogoutAndFail } from 'store/util/createRequestActionUtil';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

type Next = Dispatch<BaseAction>;
type RequestAction = {
  types: [
    {
      meta: {
        token: string;
        noAuthHeader?: boolean;
      };
      type: string;
    }
  ];
  headers: {};
  endpoint: string;
  method: string;
};

function extraErrorData(requestAction: RequestAction) {
  return {
    type: requestAction.types[0].type,
    request: {
      endpoint: requestAction.endpoint,
      method: requestAction.method,
    },
  };
}

const ignoreUnauthorized = (endpoint: string) => {
  if (endpoint.startsWith(config.salesAPI.uri)) {
    return true;
  }
  const url = new URL(endpoint);

  // TODO(grozki): Currently, Welkio's server returns a false 401 in the staging environment.
  //  To not take any chances, any 401 from Welkio gets a pass on notifying the user he's logged out.
  // This Regex tests for "welkio" as a whole word (that is, isn't a part of a longer string, like "awelkiob").
  return /\bwelkio\b/.test(url.hostname);
};

const addAuthorizationHeader = async (requestAction: RequestAction, next: Next) => {
  const {
    types: [
      {
        meta: { token, noAuthHeader },
      },
    ],
    headers,
  } = requestAction;

  if (noAuthHeader) {
    return headers;
  }

  if (token) {
    return {
      ...headers,
      Authorization: `Token token=${token}`,
    };
  }

  const accessToken = await getAccessToken();
  if (!accessToken) {
    return notifyLogoutAndFail(next, window.navigator.onLine);
  }

  return {
    Authorization: `Token token=${accessToken}`,
    ...headers,
  };
};

export default () => (next: Next) => async (action: BaseAction) => {
  if (!isRSAA(action)) {
    return next(action);
  }

  const requestAction = action[RSAA];
  const endpoint = requestAction.endpoint;

  const headers = await addAuthorizationHeader(requestAction, next);

  const actionWithAuthHeaders = {
    [RSAA]: {
      ...requestAction,
      headers,
    },
  };

  try {
    const promise = next(actionWithAuthHeaders);
    const nextAction = await promise;

    if (nextAction.error) {
      const error = nextAction.payload;

      if (error.status === 401 && !ignoreUnauthorized(endpoint)) {
        return notifyLogoutAndFail(next, window.navigator.onLine);
      }

      return Promise.reject(error);
    }

    return promise;
  } catch (error) {
    rollbar.error(
      `An error occurred while executing a request: ${error.message}`,
      error,
      extraErrorData(requestAction)
    );

    throw error;
  }
};
