import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { debounce, get, includes, last } from 'lodash';
import { HotKeys } from 'react-hotkeys';
import { Modal, Input } from '@wework/dieter-ui';
import cn from 'classnames';

import Trackable, { TrackableType } from 'components/decorators/trackable';
import withToasts, { ToastsProps } from 'components/decorators/withToasts';
import { saveLocationAsDefault } from 'features/auth/actions';
import { LOCATION_IN_URL } from 'lib/constants';
import { GlobalState } from 'store/modules';
import { addRecentLocation } from 'store/modules/siLocations';
import {
  getLocationLatLong,
  getSpacemanCode,
  getSpacemanId,
} from 'store/modules/siLocations/utils';
import {
  getCurrentLocation,
  getHomeLocation,
  getRecentLocations,
} from 'store/modules/siLocations/selectors';
import { Location } from 'store/modules/siLocations/types';
import { ReduxProps } from 'store/types';
import Loading from 'components/loading';

import Locations from './locations';
import DefaultLocationPicker from './defaultLocationPicker';
import styles from './index.scss';
import {
  getNearbyLocations,
  getSearchLoading,
  getSearchLoaded,
  getSearchResults,
} from './selectors';
import { fetchNearby, searchLocations } from './ducks';

const QUERY_LENGTH_THRESHOLD = 2;

type InterfaceProps = Readonly<{
  isOpen: boolean;
  closeLocationPicker: () => void;
}>;

type State = Readonly<{
  query: string;
  selectedIdx: number;
  modalOpened: boolean;
}>;

const mapStateToProps = (state: GlobalState) => ({
  currentLocation: getCurrentLocation(state),
  defaultLocation: getHomeLocation(state),
  recentLocations: getRecentLocations(state),
  nearbyLocations: getNearbyLocations(state),
  searchResults: getSearchResults(state),
  searchLoading: getSearchLoading(state),
  searchLoaded: getSearchLoaded(state),
});

const mapDispatchToProps = {
  fetchNearby,
  saveLocationAsDefault,
  addRecentLocation,
  searchLocations,
};

export type Props = Readonly<
  InterfaceProps &
    RouteComponentProps &
    TrackableType &
    ToastsProps &
    ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>
>;

const keyMap = {
  handleDismiss: 'esc',
  handleNextResult: ['down', 'tab'],
  handlePrevResult: ['up', 'shift+tab'],
  handleSelectResult: ['enter', 'right'],
};

const resultTypes = {
  none: 'none',
  result: 'result',
  recent: 'recent',
  nearby: 'nearby',
  default: 'default',
};

export class LocationPicker extends React.Component<Props, State> {
  state: State = {
    query: '',
    selectedIdx: -1,
    modalOpened: false,
  };

  topInput: React.RefObject<InstanceType<typeof Input>> = React.createRef();
  timeOfFirstSearch: Date | null | undefined;
  timeOpened: Date | null | undefined;

  componentDidUpdate(prevProps: Props) {
    const { currentLocation, fetchNearby, isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      const dimmer = document.querySelector('.ui.page.modals.dimmer');
      // eslint-disable-next-line css-modules/no-undef-class
      if (dimmer) dimmer.classList.add(styles.hideDimmer);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ modalOpened: true });

      if (this.topInput.current && this.topInput.current.focus) {
        this.topInput.current.focus();
      }

      if (currentLocation) {
        const { latitude, longitude } = getLocationLatLong(currentLocation);
        fetchNearby(latitude, longitude);
      }
    }
  }

  keyHandlers = () => ({
    handleDismiss: this.handleDismiss,
    handleNextResult: this.handleNextResult,
    handlePrevResult: this.handlePrevResult,
    handleSelectResult: this.handleSelectResult,
  });

  showSearchResults = () =>
    this.state.query.length >= QUERY_LENGTH_THRESHOLD &&
    (this.props.searchLoading || this.props.searchLoaded);

  debounceSearch = debounce((query: string) => {
    this.props.searchLocations(query);
  }, 100);

  computeCursorResult(): [string, Location | null | undefined] {
    if (this.showSearchResults()) {
      if (this.props.searchResults.length === 1)
        return [resultTypes.result, this.props.searchResults[0]];

      const result = this.props.searchResults[this.state.selectedIdx];

      if (result) return [resultTypes.result, result];
      return [resultTypes.none, null];
    }

    const recent = this.props.recentLocations[this.state.selectedIdx];
    if (recent) return [resultTypes.recent, recent];

    const nearby = this.props.nearbyLocations[
      this.state.selectedIdx - this.props.recentLocations.length
    ];
    if (nearby) return [resultTypes.nearby, nearby];

    return [resultTypes.none, null];
  }

  handleNextResult = (evt: KeyboardEvent) => {
    evt.preventDefault();
    this.setState(({ selectedIdx }) => ({ selectedIdx: selectedIdx + 1 }));
  };

  handlePrevResult = (evt: KeyboardEvent) => {
    evt.preventDefault();
    this.setState(({ selectedIdx }) => ({ selectedIdx: Math.max(selectedIdx - 1, -1) }));
  };

  handleSelectResult = () => {
    const [, result] = this.computeCursorResult();

    if (result) this.handleLocationChange(result);
  };

  handleDismiss = (): void => {
    this.handleCloseLocationSearch();
  };

  handleCloseLocationSearch = (): void => {
    const { closeLocationPicker } = this.props;
    this.setState({ query: '', selectedIdx: -1, modalOpened: false }, () =>
      setTimeout(closeLocationPicker, 300)
    );
  };

  handleSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value;

    this.setState(
      {
        query,
        selectedIdx: -1,
      },
      () => {
        this.timeOfFirstSearch = this.timeOfFirstSearch || new Date();

        if (this.state.query.length >= QUERY_LENGTH_THRESHOLD) {
          this.debounceSearch(this.state.query);
        }
      }
    );
  };

  handleSaveAsDefault = async () => {
    const { currentLocation, notifyError, notifySuccess, saveLocationAsDefault } = this.props;

    if (!currentLocation) {
      notifyError(
        'Failed to update default location, because current selected location is not valid.'
      );
      return;
    }

    const legacyId = getSpacemanId(currentLocation);

    this.handleCloseLocationSearch();

    await saveLocationAsDefault({ uuid: legacyId });

    notifySuccess(`Your default location was updated to ${get(currentLocation, 'name')}`);
  };

  handleClickSearchResult = (selectedLocation: Location) => {
    this.handleLocationChange(selectedLocation);
  };

  handleClickRecentLocation = (selectedLocation: Location) => {
    this.handleLocationChange(selectedLocation);
  };

  handleClickNearbyLocation = (selectedLocation: Location) => {
    this.handleLocationChange(selectedLocation);
  };

  handleClickDefaultLocation = () => {
    this.props.defaultLocation && this.handleLocationChange(this.props.defaultLocation);
  };

  // TODO(yale): Move all the routing logic into the top-level `/location` route
  handleLocationChange = (selectedLocation: Location) => {
    if (!selectedLocation) return;

    this.props.addRecentLocation(selectedLocation);
    const { latitude, longitude } = getLocationLatLong(selectedLocation);
    this.props.fetchNearby(latitude, longitude);
    this.setState({ query: '', selectedIdx: -1 });
    this.timeOfFirstSearch = null;

    const routeParts = this.props.location.pathname.split(LOCATION_IN_URL);
    const currentRoute = last(routeParts);
    const locationRoutes = [
      'members',
      'companies',
      'offices',
      'occupancy',
      'sales',
      'inventory',
      'inventory/nearby',
      'mimo',
      'emailTemplates',
    ];

    const spacemanCode = getSpacemanCode(selectedLocation);
    let newPath = `/locations/${spacemanCode}/`;

    // If we were already on a building page, go to that sub-page for the new building
    // e.g. if we were on /locations/NY01/tours, go to /locations/NY02/tours
    const isBuildingRoute = includes(locationRoutes, currentRoute);

    if (isBuildingRoute) {
      newPath += currentRoute;
      this.props.history.push(newPath);
    }

    this.props.notifySuccess(
      'Location changed!',
      `Your current location was changed to ${selectedLocation.name}`,
      null,
      this.getNotificationAction(selectedLocation)
    );

    this.handleCloseLocationSearch();
  };

  getNotificationAction(selectedLocation: Location) {
    if (
      !this.props.defaultLocation ||
      getSpacemanId(selectedLocation) !== getSpacemanId(this.props.defaultLocation)
    ) {
      return {
        action: {
          label: 'Set as default',
          callback: () => this.handleSaveAsDefault(),
        },
      };
    }

    return {};
  }

  render() {
    const { isOpen, currentLocation, defaultLocation, searchResults } = this.props;
    const recentLocations = this.props.recentLocations.filter(
      loc => loc.id !== currentLocation?.id
    );
    const nearbyLocations = this.props.nearbyLocations.filter(
      loc => loc.id !== currentLocation?.id
    );

    const { query, selectedIdx, modalOpened } = this.state;

    return (
      <>
        <Modal // eslint-disable-next-line css-modules/no-undef-class
          className={cn(styles.locationPicker, 'bg-transparent', {
            // eslint-disable-next-line css-modules/no-undef-class
            [styles.rendered]: modalOpened,
          })}
          open={isOpen}
          onClose={this.handleCloseLocationSearch}
        >
          <HotKeys className={styles.hotkeyWrapper} keyMap={keyMap} handlers={this.keyHandlers()}>
            {/* eslint-disable-next-line css-modules/no-undef-class */}
            <Modal.Header className={styles.locationPickerHeader}>
              <Input
                /* eslint-disable-next-line css-modules/no-undef-class */
                className={styles.locationPickerInput}
                type="search"
                icon="search"
                iconPosition="left"
                fluid
                onChange={this.handleSearch}
                ref={this.topInput}
              />
            </Modal.Header>
            {/* eslint-disable-next-line css-modules/no-undef-class */}
            <Modal.Content className={styles.locationPickerContent}>
              {this.props.searchLoading ? (
                <Loading />
              ) : (
                <Locations
                  nearbyLocations={nearbyLocations}
                  onClickNearbyLocation={this.handleClickNearbyLocation}
                  onClickRecentLocation={this.handleClickRecentLocation}
                  onClickSearchResult={this.handleClickSearchResult}
                  query={query}
                  recentLocations={recentLocations}
                  searchResults={searchResults}
                  selectedIdx={selectedIdx}
                  showSearchResults={this.showSearchResults()}
                />
              )}
              {!!currentLocation && (
                <DefaultLocationPicker
                  defaultLocation={defaultLocation}
                  currentLocation={currentLocation}
                  onSaveAsDefault={this.handleSaveAsDefault}
                  onClickDefaultLocation={this.handleClickDefaultLocation}
                  closeLocationPicker={this.handleCloseLocationSearch}
                />
              )}
            </Modal.Content>
          </HotKeys>
        </Modal>
      </>
    );
  }
}

export default compose<React.ComponentType<InterfaceProps>>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withToasts,
  Trackable({
    workflow: 'changeProductLocation',
    featureContext: 'currentLocation',
    feature: 'changeProductLocation',
  })
)(LocationPicker);
