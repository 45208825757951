import { round } from 'lodash';

// We assume that a number is separated by either a comma, an apostrophe, or a period.
// All exotic separators will default to a period.
export const separator = (input: string): string => {
  if (input.includes(',')) {
    return ',';
  }

  if (input.includes("'")) {
    return "'";
  }

  return '.';
};

// Round in two parts because some decimals cannot be represented exactly.
export const roundDiscount = (discount: number): number => round(round(discount * 100, 4), 2);

export const formatIntlDiscount = (discount: number, locale: string): string => {
  const roundedDiscount = roundDiscount(discount);
  const maximumFractionDigits = 2;
  const minimumFractionDigits = Number.isInteger(roundedDiscount) ? 0 : 2;
  return Intl.NumberFormat(locale, { maximumFractionDigits, minimumFractionDigits }).format(
    roundedDiscount
  );
};
