export const LOADING_STATUS = Object.freeze({
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
});

export type LoadingStatus = typeof LOADING_STATUS[keyof typeof LOADING_STATUS];
export type LoadingStatusMap = {
  [key: string]: LoadingStatus;
};

export type ConferenceRoom = {
  id: number;
  name: string;
  tags: Array<string>;
  uuid: string;
  notes: string | null;
  capacity: number;
  floor_id: number;
  open_date: string | null;
  sell_date: string | null;
  created_at: string;
  deleted_at: string | null;
  updated_at: string;
  archived_at: string | null;
  square_feet: number | null;
  community_id: string | null;
  extra_fields: {};
  sellable_capacity: string | null;
  physical_open_date: string | null;
  entity_published_at: string | null;
};

export type Floor = {
  id: number;
  name: string;
  uuid: string;
  sg_uuid: string | null;
  open_date: string | null;
  created_at: string;
  updated_at: string;
  archived_at: string | null;
  location_id: number;
  square_feet: number | null;
  floor_number: number;
  conference_rooms: Array<ConferenceRoom>;
  workday_location_name: string | null;
};

export type FloorsMap = {
  [key: string]: Array<Floor>;
};
