import { createRequestReducer } from 'store/util';
import { InventoryQueryResult } from 'features/renewals/redux/inventoryItems/types';

import { QUERY_IQS, QUERY_IQS_FAIL, QUERY_IQS_SUCCESS } from './constants';

const handlePayload = (response, previousState): InventoryQueryResult => {
  return {
    byLocationAndName: {
      ...(previousState.byLocationAndName ?? {}),
      ...(response.payload.byLocationAndName ?? {}),
    },
  };
};

const initial: InventoryQueryResult = { byLocationAndName: {} };

const reducer = createRequestReducer<InventoryQueryResult>(
  [QUERY_IQS, QUERY_IQS_SUCCESS, QUERY_IQS_FAIL],
  initial,
  handlePayload
);

export default reducer;
