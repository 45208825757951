import { combineReducers } from 'redux';

import { RequestState } from 'store/util/createRequestReducer';
import { Location } from 'store/modules/siLocations';

import { Suggestions } from '../config/discountTypes';
import { Filters } from '../config/types';
import {
  Building,
  CommunityByBuilding,
  InventoryItem,
  InventorySearchResult,
  LocationAutocompleteSingleResponse,
  LocationAutocompleteResponse,
  ReactSelectOption,
  SIInventorySearchResult,
  SIInventoryRenderableResult,
} from '../types';

import buildings from './buildingQuerySi';
import communityByBuilding from './communityQuery';
import discountsApi from './discountsApi';
import inventoryItemSI from './inventoryItemSI';
import inventoryQueryService from './inventoryQueryService';
import inventoryQueryServiceSI from './inventoryQueryServiceSI';
import inventoryQueryElasticsearchService from './inventoryQueryElasticsearchService';
import locationSearchText from './locationSearchText';
import commitmentTerm from './commitmentTerm';
import placePredictions from './locationAutocomplete';
import selectPlace from './selectPlace';
import selectedFilters from './selectedFilters';
import selectedLocation from './selectedLocation';
import collapsibleSections from './collapsibleSections';
import modals, { State as ModalsState } from './modals';
import selectedInventoryItems, {
  State as SelectedInventoryItemsState,
} from './selectedInventoryItems';
import SILocationsById from './fetchSiLocations';

export interface State {
  buildings: RequestState<Record<string, Building>>;
  communityByBuilding: RequestState<CommunityByBuilding>;
  inventoryItemSI: RequestState<Array<InventoryItem>>;
  inventoryQueryServiceSI: RequestState<SIInventoryRenderableResult>;
  inventoryQueryService: RequestState<InventorySearchResult>;
  inventoryQueryElasticsearchService: RequestState<SIInventorySearchResult>;
  locationSearchText: string;
  placePredictions: RequestState<Array<LocationAutocompleteResponse>>;
  selectPlace: RequestState<LocationAutocompleteSingleResponse | null | undefined>;
  selectedFilters: Filters;
  selectedLocation: ReactSelectOption | null;
  modals: ModalsState;
  discountsApi: RequestState<Hash<Suggestions>>;
  selectedInventoryItems: SelectedInventoryItemsState;
  commitmentTerm: string;
  collapsibleSections: Record<string, boolean>;
  siLocations: RequestState<Array<Location>>;
}

export interface InventorySearchSubset {
  inventorySearch: State;
}

export default combineReducers<State>({
  buildings,
  communityByBuilding,
  siLocations: SILocationsById,

  // iqs
  inventoryQueryService,
  inventoryQueryElasticsearchService,

  // si
  inventoryItemSI,
  inventoryQueryServiceSI,

  locationSearchText,
  placePredictions,
  selectPlace,
  selectedFilters,
  selectedLocation,
  modals,
  discountsApi,
  selectedInventoryItems,
  commitmentTerm,
  collapsibleSections,
});
