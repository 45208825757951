import React, { Suspense } from 'react';
import { WithRequestedAuthzProps } from '@wework/we-auth-react';

import { lazy } from 'lib/lazyCatch';

const LazyDeveloperToolbox = lazy(
  () =>
    import(
      /* webpackChunkName: "developerToolbox" */
      './developerToolbox'
    )
);

type Props = WithRequestedAuthzProps;

function DeveloperToolbox({ requestedRoles }: Props) {
  if (!requestedRoles.developer) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyDeveloperToolbox />
    </Suspense>
  );
}

export default DeveloperToolbox;
