import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

import resultStyles from './index.scss';
import styles from './cell.scss';

export type Props = HTMLAttributes<HTMLDivElement> & { inactive?: boolean };

const Cell = ({ inactive = false, className, ...props }: Props) => (
  <div
    className={cn(styles.cell, className, {
      [resultStyles.inactiveCell]: inactive,
    })}
    {...props}
  />
);

export default Cell;
