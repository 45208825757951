import { combineReducers } from 'redux';

import { RequestState } from 'store/util/createRequestReducer';
import { InventorySearchMetrics } from 'features/building/types';

import { Location } from '../types';

import metrics from './inventoryMetricsReducer';
import location from './locationReducer';

export interface State {
  location: RequestState<Location | null>;
  metrics: RequestState<InventorySearchMetrics | null>;
}

export interface AboutLocationSubset {
  aboutLocation: State;
}

export default combineReducers<State>({
  location,
  metrics,
});
