import { invokeMap } from 'lodash';

import AlgoliaTokenClient from './algoliaTokenClient';

const algoliaTokenClient = new AlgoliaTokenClient();

const store = Object.freeze({
  algolia: algoliaTokenClient,
});

export type RegistryStore = typeof store;

class TokenRegistry {
  store: RegistryStore;

  constructor(store: RegistryStore) {
    this.store = store;
  }

  getAlgoliaClient() {
    return this.store.algolia;
  }

  async clear() {
    return Promise.all(invokeMap(this.store, 'clear'));
  }
}

const tokenRegistry = new TokenRegistry(store);

export default tokenRegistry;
