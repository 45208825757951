import React from 'react';
import {
  WithRequestedAuthz as WithRequestedAuthzOriginal,
  WithRequestedAuthzByUser as WithRequestedAuthzByUserOriginal,
  WithRequestedAuthzByAccount as WithRequestedAuthzByAccountOriginal,
  withRequestedAuthz as withRequestedAuthzOriginal,
  withRequestedAuthzByUser as withRequestedAuthzByUserOriginal,
  withRequestedAuthzByAccount as withRequestedAuthzByAccountOriginal,
  Permitted as PermittedOriginal,
  PermittedByUser as PermittedByUserOriginal,
  PermittedByAccount as PermittedByAccountOriginal,
  permitted as permittedOriginal,
  permittedByUser as permittedByUserOriginal,
  permittedByAccount as permittedByAccountOriginal,
  ErrorComponentProps,
  OperatorServiceError,
  OperatorServiceErrorResponse,
  DefaultErrorComponent,
} from '@wework/we-auth-react';

import Loading from 'components/loading';
import { FallbackError } from 'store/errors';

export const parseOperatorServiceErrorMessage = (json: OperatorServiceErrorResponse): string => {
  if (json?.fieldErrors) {
    return json.fieldErrors.reduce(
      (acc, error) => ` ${acc}, ${error.field} ${error.message}`,
      json.message
    );
  }

  return json?.message;
};

const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => {
  const operatorServiceError = error as OperatorServiceError;
  if (operatorServiceError.response) {
    throw new FallbackError(
      operatorServiceError.response.status,
      operatorServiceError.response.url,
      parseOperatorServiceErrorMessage(operatorServiceError.data),
      operatorServiceError.response.headers['x-request-id']
    );
  }

  return null;
};

export const WithRequestedAuthz: typeof WithRequestedAuthzOriginal = props => (
  <WithRequestedAuthzOriginal
    errorComponent={ErrorComponent}
    loadingComponent={Loading}
    {...props}
  />
);

export const WithRequestedAuthzByUser: typeof WithRequestedAuthzByUserOriginal = props => (
  <WithRequestedAuthzByUserOriginal
    errorComponent={ErrorComponent}
    loadingComponent={Loading}
    {...props}
  />
);

export const WithRequestedAuthzByAccount: typeof WithRequestedAuthzByAccountOriginal = props => (
  <WithRequestedAuthzByAccountOriginal
    errorComponent={ErrorComponent}
    loadingComponent={Loading}
    {...props}
  />
);

export const withRequestedAuthz: typeof withRequestedAuthzOriginal = hocProps =>
  withRequestedAuthzOriginal({
    ...hocProps,
    errorComponent: ErrorComponent,
    loadingComponent: Loading,
  });

export const withRequestedAuthzByUser: typeof withRequestedAuthzByUserOriginal = hocProps =>
  withRequestedAuthzByUserOriginal({
    ...hocProps,
    errorComponent: ErrorComponent,
    loadingComponent: Loading,
  });

export const withRequestedAuthzByAccount: typeof withRequestedAuthzByAccountOriginal = hocProps =>
  withRequestedAuthzByAccountOriginal({
    ...hocProps,
    errorComponent: ErrorComponent,
    loadingComponent: Loading,
  });

export const Permitted: typeof PermittedOriginal = props => (
  <PermittedOriginal loadingComponent={Loading} {...props} />
);

export const PermittedByUser: typeof PermittedByUserOriginal = props => (
  <PermittedByUserOriginal loadingComponent={Loading} {...props} />
);

export const PermittedByAccount: typeof PermittedByAccountOriginal = props => (
  <PermittedByAccountOriginal loadingComponent={Loading} {...props} />
);

export const permitted: typeof permittedOriginal = allowedPermissions =>
  permittedOriginal(allowedPermissions, DefaultErrorComponent, Loading);

export const permittedByUser: typeof permittedByUserOriginal = (userUuid, allowedPermissions) =>
  permittedByUserOriginal(userUuid, allowedPermissions, DefaultErrorComponent, Loading);

export const permittedByAccount: typeof permittedByAccountOriginal = (
  accountUuid,
  allowedPermissions
) => permittedByAccountOriginal(accountUuid, allowedPermissions, DefaultErrorComponent, Loading);
