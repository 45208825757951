import { createSelector } from 'reselect';
import { handleActions, Action } from 'redux-actions';

import { CommunityEventsApiWeeklyPosterResponse, Poster } from 'features/events/ducks/types';
import createRequestAction from 'store/util/createRequestAction';
import config from 'config';
import cc from 'store/util/createReduxConstant';

// Action Constants
export const GET_WEEKLY_POSTER = cc('GET_WEEKLY_POSTER');
export const GET_WEEKLY_POSTER_SUCCESS = cc('GET_WEEKLY_POSTER_SUCCESS');
export const GET_WEEKLY_POSTER_FAIL = cc('GET_WEEKLY_POSTER_FAIL');

interface State {
  loaded: boolean;
  result: Poster | null;
  loading: boolean;
  // TODO: Type this.
  error: any | null;
}

export interface EventWeeklyPosterSubset {
  eventWeeklyPoster: State;
}

const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  result: null,
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [GET_WEEKLY_POSTER]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [GET_WEEKLY_POSTER_SUCCESS]: (
      state,
      action: Action<CommunityEventsApiWeeklyPosterResponse>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      result: action.payload.result,
    }),
    [GET_WEEKLY_POSTER_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload,
    }),
  },
  initialState
);

export const createWeeklyPoster = (
  events: Array<string> = [],
  operatorServiceSplitHeader?: Record<string, string>
) =>
  createRequestAction<CommunityEventsApiWeeklyPosterResponse>({
    types: [GET_WEEKLY_POSTER, GET_WEEKLY_POSTER_SUCCESS, GET_WEEKLY_POSTER_FAIL],
    endpoint: `${config.communityEventsApi.uri}/api/v1/events/weekly_poster`,
    body: { events: events.map(uuid => ({ uuid })) },
    method: 'POST',
    headers: {
      ...operatorServiceSplitHeader,
    },
  });

export const getWeeklyPosterUrls = createSelector(
  ({ eventWeeklyPoster }) => eventWeeklyPoster.result,
  eventWeeklyPoster => eventWeeklyPoster
);

export default reducer;
