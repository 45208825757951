import { handleActions, Reducer } from 'redux-actions';
import { map, sortBy } from 'lodash';

import { ActionWithMeta, ActionWithPayload, ActionWithMetaPayload } from 'store/types';
import cc from 'store/util/createReduxConstant';

export const FETCH_COMMITMENT_BASED_OFFERINGS = cc('FETCH_COMMITMENT_BASED_OFFERINGS');
export const FETCH_COMMITMENT_BASED_OFFERINGS_SUCCESS = cc(
  'FETCH_COMMITMENT_BASED_OFFERINGS_SUCCESS '
);
export const FETCH_COMMITMENT_BASED_OFFERINGS_FAILURE = cc(
  'FETCH_COMMITMENT_BASED_OFFERINGS_FAILURE'
);
export const RESET_COMMITMENT_BASED_OFFERINGS = cc('RESET_COMMITMENT_BASED_OFFERINGS');
export const SELECT_COMMITMENT_BASED_OFFERING = cc('SELECT_COMMITMENT_BASED_OFFERING');
export const SET_DISCOUNT_OVERRIDE_AMOUNT = cc('SET_DISCOUNT_OVERRIDE_AMOUNT');
export const SET_DISCOUNT_OVERRIDE_DURATION = cc('SET_DISCOUNT_OVERRIDE_DURATION');
export const TOGGLE_DISCOUNT_OVERRIDE = cc('TOGGLE_DISCOUNT_OVERRIDE');

type Offering = {
  months: number;
  details: Array<{ percentage: number }>;
};

export interface State {
  availableOfferings: Hash<Array<{}>>;
  defaultOfferings: Hash<{}>;
  errors: Hash<boolean | null | undefined>;
  loading: Hash<boolean>;
  monthToMonth: Hash<{}>;
  overrides: Hash<boolean>;
  selectedOfferings: Hash<{
    percentage?: number;
    months?: number;
    name?: string;
    tag?: string;
  }>;
  overrideAmounts: Hash<string | number>;
  overrideDurations: Hash<string>;
}

// Helper function
const sortOfferings = (offerings: Array<Offering>) => {
  return sortBy(
    map(offerings, offering => ({
      ...offering,
      details: sortBy(offering.details, 'percentage'),
    })),
    'months'
  );
};

// Initial state
export const initialState: State = {
  availableOfferings: {},
  defaultOfferings: {},
  errors: {},
  loading: {},
  monthToMonth: {},
  overrideAmounts: {},
  overrides: {},
  selectedOfferings: {},
  overrideDurations: {},
};

// Reducer
type FetchOfferingsSuccess = ActionWithMetaPayload<
  { key: string },
  {
    month_to_month: {};
    default: {};
    offerings: Array<Offering>;
  }
>;
export const reducer: Reducer<State, any> = handleActions<State, any, { key: string }>(
  {
    [FETCH_COMMITMENT_BASED_OFFERINGS]: (
      state: State,
      action: ActionWithMeta<{ key: string }>
    ) => ({
      ...state,
      availableOfferings: {
        ...state.availableOfferings,
        [action.meta.key]: [],
      },
      defaultOfferings: {
        ...state.defaultOfferings,
        [action.meta.key]: {},
      },
      errors: {
        ...state.errors,
        [action.meta.key]: false,
      },
      loading: {
        ...state.loading,
        [action.meta.key]: true,
      },
      monthToMonth: {
        ...state.monthToMonth,
        [action.meta.key]: {},
      },
      overrideAmounts: {
        ...state.overrideAmounts,
        [action.meta.key]: '',
      },
      overrideDurations: {
        ...state.overrideDurations,
        [action.meta.key]: '',
      },
      overrides: {
        ...state.overrides,
        [action.meta.key]: false,
      },
      selectedOfferings: {
        ...state.selectedOfferings,
        [action.meta.key]: {},
      },
    }),

    [FETCH_COMMITMENT_BASED_OFFERINGS_SUCCESS]: (state: State, action: FetchOfferingsSuccess) => ({
      ...state,
      availableOfferings: {
        ...state.availableOfferings,
        [action.meta.key]: sortOfferings(action.payload.offerings),
      },
      defaultOfferings: {
        ...state.defaultOfferings,
        [action.meta.key]: action.payload.default,
      },
      errors: {
        ...state.errors,
        [action.meta.key]: false,
      },
      loading: {
        ...state.loading,
        [action.meta.key]: false,
      },
      monthToMonth: {
        ...state.monthToMonth,
        [action.meta.key]: action.payload.month_to_month,
      },
    }),

    [FETCH_COMMITMENT_BASED_OFFERINGS_FAILURE]: (
      state: State,
      action: ActionWithMeta<{ key: string }>
    ) => ({
      ...state,
      errors: {
        ...state.errors,
        [action.meta.key]: action.error,
      },
      loading: {
        ...state.loading,
        [action.meta.key]: false,
      },
    }),

    [RESET_COMMITMENT_BASED_OFFERINGS]: () => ({
      ...initialState,
    }),
    [SELECT_COMMITMENT_BASED_OFFERING]: (
      state: State,
      action: ActionWithPayload<{
        key: string;
        offering: {};
      }>
    ) => ({
      ...state,
      overrideAmounts: {
        ...state.overrideAmounts,
        [action.payload.key]: '',
      },
      overrideDurations: {
        ...state.overrideDurations,
        [action.payload.key]: '',
      },
      overrides: {
        ...state.overrides,
        [action.payload.key]: false,
      },
      selectedOfferings: {
        ...state.selectedOfferings,
        [action.payload.key]: action.payload.offering,
      },
    }),
    [SET_DISCOUNT_OVERRIDE_AMOUNT]: (
      state: State,
      action: ActionWithPayload<{
        key: string;
        amount: string;
      }>
    ) => ({
      ...state,
      overrideAmounts: {
        ...state.overrideAmounts,
        [action.payload.key]: action.payload.amount,
      },
    }),
    [SET_DISCOUNT_OVERRIDE_DURATION]: (
      state: State,
      action: ActionWithPayload<{
        key: string;
        duration: string;
      }>
    ) => ({
      ...state,
      overrideDurations: {
        ...state.overrideDurations,
        [action.payload.key]: action.payload.duration,
      },
    }),
    [TOGGLE_DISCOUNT_OVERRIDE]: (state: State, action: ActionWithPayload<{ key: string }>) => ({
      ...state,
      overrideAmounts: {
        ...state.overrideAmounts,
        [action.payload.key]: state.overrides[action.payload.key]
          ? ''
          : state.selectedOfferings[action.payload.key]?.percentage ?? '',
      },
      overrides: {
        ...state.overrides,
        [action.payload.key]: !state.overrides[action.payload.key],
      },
    }),
  },
  initialState
);

export const selectCommitmentBasedOffering = (key: string, offering: Offering) => ({
  type: SELECT_COMMITMENT_BASED_OFFERING,
  payload: { key, offering },
});

export const setDiscountOverrideAmount = (key: string, amount: number) => ({
  type: SET_DISCOUNT_OVERRIDE_AMOUNT,
  payload: { key, amount },
});

export const setDiscountOverrideDuration = (key: string, duration: string) => ({
  type: SET_DISCOUNT_OVERRIDE_DURATION,
  payload: { key, duration },
});

export const toggleDiscountOverride = (key: string) => ({
  type: TOGGLE_DISCOUNT_OVERRIDE,
  payload: { key },
});

export default reducer;
