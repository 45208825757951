import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  GET_COMPANY_AUDIT_HISTORY,
  GET_USER_ACOMPANY_HISTORY_SUCCESS,
  GET_COMPANY_AUDIT_HISTORY_FAIL,
] = createRequestConstantNames(cc('GET_COMPANY_AUDIT_HISTORY'));

export const [
  GET_ACCESS_AUDIT_LOG,
  GET_ACCESS_AUDIT_LOG_SUCCESS,
  GET_ACCESS_AUDIT_LOG_FAIL,
] = createRequestConstantNames(cc('GET_ACCESS_AUDIT_LOG'));
