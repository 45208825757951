import React from 'react';
import { compose } from 'redux';

import Cell from 'features/search/resultSets/cell';
import { formatDateTime } from 'features/visitors/components/cards/util';

const CHECKED_IN_TITLE = 'Checked in:';

type Props = {
  checkInTime?: Date;
};

const CheckInStatusCell = React.memo(function CheckInStatusCell({ checkInTime }: Props) {
  const checkInTimeText = checkInTime ? formatDateTime(checkInTime) : null;
  return (
    <Cell inactive>
      {checkInTime && (
        <>
          <p className="text-3xs text-primary hover:text-primary">{CHECKED_IN_TITLE}</p>
          <p className="leading-none text-3xs text-primary hover:text-primary">{checkInTimeText}</p>
        </>
      )}
    </Cell>
  );
});

export default compose()(CheckInStatusCell);
