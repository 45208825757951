import { handleActions } from 'redux-actions';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import { reject } from 'lodash';

import { BaseAction, Dispatch, GetGlobalState } from 'store/types';
import { createRequestAction } from 'store/util';
import config from 'config';
import { camelCaseJson } from 'lib/util';
import { NotableType, Note } from 'features/memberNotes/types';
import cc from 'store/util/createReduxConstant';
import { getCurrentLocationUuid } from 'store/modules/siLocations/selectors';

// Action Constants
export const FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE = cc('FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE');
export const FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_SUCCESS = cc(
  'FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_SUCCESS'
);
export const FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_FAIL = cc(
  'FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_FAIL'
);

export const CREATE_NOTE = cc('CREATE_NOTE');
export const CREATE_NOTE_SUCCESS = cc('CREATE_NOTE_SUCCESS');
export const CREATE_NOTE_FAIL = cc('CREATE_NOTE_FAIL');

export const UPDATE_NOTE = cc('UPDATE_NOTE');
export const UPDATE_NOTE_SUCCESS = cc('UPDATE_NOTE_SUCCESS');
export const UPDATE_NOTE_FAIL = cc('UPDATE_NOTE_FAIL');

export const DELETE_NOTE = cc('DELETE_NOTE');
export const DELETE_NOTE_SUCCESS = cc('DELETE_NOTE_SUCCESS');
export const DELETE_NOTE_FAIL = cc('DELETE_NOTE_FAIL');

export interface State {
  filter: {};
  submitting: boolean;
  submitted: boolean;
  // TODO: Type this.
  data: any[];
  notesLoaded: boolean;
  error: boolean | null | undefined;
  notesLoading: boolean;
}

export interface MemberNotesSubset {
  memberNotes: State;
}

// Initial State
export const initialState: State = {
  data: [],
  error: null,
  notesLoaded: false,
  notesLoading: false,
  submitting: false,
  submitted: false,
  filter: {},
};

export function combineNotes(existingNotes: Array<Note> = [], newNotes: Array<Note> = []) {
  return [
    ...newNotes,
    ...reject(existingNotes, note => newNotes.map(note => note.id).includes(note.id)),
  ];
}

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE]: (state: State) => ({
      ...state,
      notesLoading: true,
      notesLoaded: false,
    }),

    [FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_SUCCESS]: (state: State, action) => ({
      ...state,
      data: combineNotes(state.data, action.payload.result.notes),
      notesLoading: false,
      notesLoaded: true,
      error: null,
    }),

    [FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_FAIL]: (state: State, action) => ({
      ...state,
      notesLoading: false,
      notesLoaded: false,
      error: action.error,
    }),

    [CREATE_NOTE]: (state: State) => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [CREATE_NOTE_SUCCESS]: (state: State, action) => ({
      ...state,
      data: combineNotes(state.data, [action.payload.result.note]),
      submitting: false,
      submitted: true,
      error: null,
    }),

    [CREATE_NOTE_FAIL]: (state: State, action) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.error,
    }),

    [UPDATE_NOTE]: (state: State) => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [UPDATE_NOTE_SUCCESS]: (state: State, action) => ({
      ...state,
      data: combineNotes(state.data, [action.payload.result.note]),
      submitting: false,
      submitted: true,
      error: null,
    }),

    [UPDATE_NOTE_FAIL]: (state: State, action) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.error,
    }),

    [DELETE_NOTE]: (state: State) => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [DELETE_NOTE_SUCCESS]: (state: State, action) => ({
      ...state,
      data: [...reject(state.data, { id: action.meta.id })],
      submitting: false,
      submitted: true,
      error: null,
    }),

    [DELETE_NOTE_FAIL]: (state: State, action) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.error,
    }),
  },
  initialState
);

export type QueryParams = {
  notable_id?: string;
  notable_type?: string;
  building_uuid?: string;
  type?: string;
};

export function fetchNotes(
  queryParams: QueryParams,
  operatorServiceSplitHeader?: Record<string, string>
) {
  return (dispatch: Dispatch<BaseAction>) => {
    // $FlowFixMe TODO (kangax) mixed (expected by stringify) incompatible with string
    const query = queryString.stringify(queryParams || {});

    const requestAction = createRequestAction({
      endpoint: `${config.skywriter.uri}/api/v1/notes?${query}`,
      method: 'GET',
      headers: {
        ...operatorServiceSplitHeader,
      },
      getPayloadFromResponse: camelCaseJson,
      types: [
        FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE,
        FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_SUCCESS,
        FETCH_NOTES_BY_NOTABLE_ID_AND_TYPE_FAIL,
      ],
    });

    return dispatch(requestAction);
  };
}

interface NotePayload {
  id: number;
  type: string;
  building_uuid: string | undefined;
  notable_id: string;
  notable_type: NotableType;
  notable_name: string;
  rank: number;
  text: string;
}

export function createNewNote(note: Note, operatorServiceSplitHeader?: Record<string, string>) {
  return (dispatch: Dispatch<BaseAction>, getState: GetGlobalState) => {
    const { notableId, notableType } = note;

    const params: NotePayload = {
      id: note.id,
      type: note.type,
      building_uuid: getCurrentLocationUuid(getState()) ?? undefined,
      notable_id: note.notableId,
      notable_type: note.notableType,
      notable_name: note.notableName,
      rank: note.rank || 0,
      text: note.text,
    };

    const requestAction = createRequestAction({
      endpoint: `${config.skywriter.uri}/api/v1/notes`,
      method: 'POST',
      headers: {
        ...operatorServiceSplitHeader,
      },
      getPayloadFromResponse: camelCaseJson,
      body: {
        note: params,
      },
      types: [
        CREATE_NOTE,
        {
          type: CREATE_NOTE_SUCCESS,
          meta: { notableId, notableType },
        },
        CREATE_NOTE_FAIL,
      ],
    });

    return dispatch(requestAction);
  };
}

export function updateNote(note: Note, operatorServiceSplitHeader?: Record<string, string>) {
  return (dispatch: Dispatch<BaseAction>) => {
    const { id, notableId, notableType } = note;

    const requestAction = createRequestAction({
      endpoint: `${config.skywriter.uri}/api/v1/notes/${id}`,
      method: 'PUT',
      headers: {
        ...operatorServiceSplitHeader,
      },
      getPayloadFromResponse: camelCaseJson,
      body: {
        note: {
          id: note.id,
          notable_id: note.notableId,
          notable_type: note.notableType,
          rank: note.rank,
          text: note.text,
        },
      },
      types: [
        UPDATE_NOTE,
        {
          type: UPDATE_NOTE_SUCCESS,
          meta: {
            notableId,
            notableType,
          },
        },
        UPDATE_NOTE_FAIL,
      ],
    });

    return dispatch(requestAction);
  };
}

export function deleteNote(
  { id, notableId, notableType }: Note,
  operatorServiceSplitHeader?: Record<string, string>
) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.skywriter.uri}/api/v1/notes/${id}`,
      method: 'DELETE',
      headers: {
        ...operatorServiceSplitHeader,
      },
      getPayloadFromResponse: camelCaseJson,
      getMetaFromResponse: res => res,
      types: [
        DELETE_NOTE,
        {
          type: DELETE_NOTE_SUCCESS,
          meta: {
            id,
            notableId,
            notableType,
          },
        },
        DELETE_NOTE_FAIL,
      ],
    });

    return dispatch(requestAction);
  };
}

// Selectors
export const getNotesByNotable = (
  state: MemberNotesSubset,
  props: {
    notableType: NotableType;
    notableId: string;
  }
) =>
  state.memberNotes.data.filter(
    item => item.notableId === props.notableId && item.notableType === props.notableType
  );

export const getNotesByNotableAndType = createSelector(
  [getNotesByNotable, (_: unknown, props: { noteType: string }) => props.noteType],
  (notes, noteType) => notes.filter(note => note.type === noteType)
);

export const getInitialFormValuesFromNote = (
  _: unknown,
  props: { note?: Note | null | undefined }
) => {
  const { note } = props;

  if (!note) {
    return {
      text: '',
    };
  }

  return {
    text: note.text,
  };
};

export default reducer;
