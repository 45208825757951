import config from '../config';

import WeWorkBase from './weworkBase';

export default class Spacemoney extends WeWorkBase {
  constructor() {
    super(config.spacemoney.uri);
  }

  uploadAgreement(params: {}) {
    return this.post('/api/v1/agreement_uploads', params);
  }
}
