import { handleActions } from 'redux-actions';

import {
  GET_BANK_ACCOUNT_INFO_SUCCESS,
  GET_BANK_ACCOUNT_INFO_FAIL,
  GET_BANK_ACCOUNT_INFO,
} from './constants';

// Initial State
export interface State {
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

export interface BankAccountInfoCollectorSubset {
  bankAccountInfoCollector: State;
}

const initialState: State = {
  loading: false,
  loaded: false,
  error: false,
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [GET_BANK_ACCOUNT_INFO]: (state: State): State => ({ ...state, loading: true, loaded: false }),

    [GET_BANK_ACCOUNT_INFO_SUCCESS]: (state: State): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: false,
    }),

    [GET_BANK_ACCOUNT_INFO_FAIL]: (state: State): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: true,
    }),
  },
  initialState
);

export default reducer;
