import { createConstants, createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';
import { dateFormatsDeprecated } from 'lib/constants';

export const constants = createConstants([
  ...createRequestConstantNames('FETCH_RESERVATIONS'),
  ...createRequestConstantNames('FETCH_CONFERENCE_ROOM_RESERVATIONS_BY_COMPANY'),
]);
export const DATE_FORMAT = dateFormatsDeprecated.iso_date;
export const CHANGE_GRANULARITY = createReduxConstant('CHANGE_GRANULARITY');

export const UPDATE_RESERVATIONS_SEARCH_VALUE = createReduxConstant(
  'UPDATE_RESERVATIONS_SEARCH_VALUE'
);
export const CLEAR_GRANULARITY_AND_TIME_STATE = createReduxConstant(
  'CLEAR_GRANULARITY_AND_TIME_STATE'
);

export const START_TIME_IN_LOCATION_DESK = '09:00 AM';
export const NO_HOUR_DEFINED = '-';
export const DEFAULT_RESERVATIONS_PER_PAGE = 50;
