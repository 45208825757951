import { v4 as uuidv4 } from 'uuid';

import { ApiError } from 'store/errors';
import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

export const fetchLocation = async (query: string, variables: Hash<any>): Promise<any> => {
  const accessToken = await getAccessToken();
  const response = await fetch(`${config.siGateway.uri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'apollographql-client-name': 'spacestation-web',
      'x-request-id': uuidv4(),
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  if (response.status !== 200) {
    throw new ApiError(
      response.status,
      response.statusText,
      response,
      `Failed to fetch location from SI Gateway (${response.status})`,
      response.url
    );
  }

  return response.json();
};
