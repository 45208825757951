import React, { useState, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Dropdown, SkeletonImage } from '@wework/dieter-ui';

import { getUserInfoFromAuth } from 'features/auth/selectors';
import { logout } from 'features/auth/requestActions';
import { GlobalState } from 'store/modules';
import { ReduxProps } from 'store/types';

import Avatar from '../../../components-ray/avatar';
import { createAvatarUrlById } from '../resultSets/utils';

import styles from './avatarMenu.scss';

type OwnProps = {
  hidden: boolean;
  className?: string;
  loading: boolean;
};

const mapStateToProps = (state: GlobalState) => ({
  user: getUserInfoFromAuth(state),
});

const mapDispatchToProps = { logout };

type Props = OwnProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

const AvatarWithLoading: React.FC<Pick<Props, 'loading' | 'user'> & { isActive: boolean }> = ({
  isActive,
  loading,
  user,
}) => {
  if (loading) {
    return <SkeletonImage size="small" />;
  } else {
    return (
      <Avatar
        buttonAriaLabel="Log out"
        imageUrl={user.userUuid && createAvatarUrlById(user.userUuid)}
        isActive={isActive}
        onClick={() => {}}
        size="medium"
      />
    );
  }
};

const AvatarMenu = ({ user, hidden, logout, className, loading }: Props) => {
  const [open, setOpen] = useState(false);
  const openMenu = () => setOpen(true);
  const closeMenu = () => setOpen(false);
  const handleLogout = useCallback(() => {
    setOpen(false);
    logout();
  }, [setOpen, logout]);

  return (
    <Dropdown // eslint-disable-next-line css-modules/no-undef-class
      className={cn({ [styles.hidden]: hidden }, className)}
      trigger={<AvatarWithLoading isActive={open} loading={loading} user={user} />}
      direction="left"
      icon={null}
      open={open}
      onOpen={openMenu}
      onClose={closeMenu}
    >
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <Dropdown.Menu className={styles.menu}>
        {/* eslint-disable-next-line css-modules/no-undef-class */}
        <Dropdown.Item className={styles.menuItem} onClick={handleLogout}>
          Log out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default compose<React.ComponentType<any>>(connect(mapStateToProps, mapDispatchToProps))(
  AvatarMenu
);
