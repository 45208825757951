import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';
import { FetchSeatsOptions } from 'features/companies/redux/seats/types';

export const [FETCH_SEATS, FETCH_SEATS_SUCCESS, FETCH_SEATS_FAIL] = createRequestConstantNames(
  cc('FETCH_SEATS')
);

export const [CREATE_SEAT, CREATE_SEAT_SUCCESS, CREATE_SEAT_FAIL] = createRequestConstantNames(
  cc('CREATE_SEAT')
);

export const [
  REACTIVATE_SEAT,
  REACTIVATE_SEAT_SUCCESS,
  REACTIVATE_SEAT_FAIL,
] = createRequestConstantNames(cc('REACTIVATE_SEAT'));

export const [
  DEACTIVATE_SEAT,
  DEACTIVATE_SEAT_SUCCESS,
  DEACTIVATE_SEAT_FAIL,
] = createRequestConstantNames(cc('DEACTIVATE_SEAT'));

export const [DELETE_SEAT, DELETE_SEAT_SUCCESS, DELETE_SEAT_FAIL] = createRequestConstantNames(
  cc('DELETE_SEAT')
);

export const [
  CHANGE_LICENSEE,
  CHANGE_LICENSEE_SUCCESS,
  CHANGE_LICENSEE_FAIL,
] = createRequestConstantNames(cc('CHANGE_LICENSEE'));

export const [
  TOGGLE_BILLING,
  TOGGLE_BILLING_SUCCESS,
  TOGGLE_BILLING_FAIL,
] = createRequestConstantNames(cc('TOGGLE_BILLING'));

export const SELECT_SEAT = cc('SELECT_SEAT');

export const DEFAULT_FETCH_SEATS_OPTIONS: FetchSeatsOptions = {
  page: 1,
  query: '',
  filter: {
    status: 'active',
    locationUuid: null,
    userUuids: null,
  },
};

export const DEFAULT_PER_PAGE = 30;

export enum SeatStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum SeatKind {
  LICENSEE = 'Licensee',
  BILLING_CONTACT = 'Billing Contact',
  EMPLOYEE = 'Employee',
}

export enum SeatUserKind {
  MEMBER = 'member',
}

export enum SeatUserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
