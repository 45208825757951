import { handleActions } from 'redux-actions';

import { Reservation } from 'features/companies/redux/reservations/types';
import {
  FETCH_CONTRACTS,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAIL,
  FETCH_CONTRACTS_BY_LOCATION,
  FETCH_CONTRACTS_BY_LOCATION_SUCCESS,
  FETCH_CONTRACTS_BY_LOCATION_FAIL,
} from 'features/inventory/redux/contracts/constants';

interface State {
  loaded: boolean;
  loading: boolean;
  error: boolean | null | undefined;
  byLocationUuid: Hash<{}>;
  byAccountUuid: Hash<Array<Reservation>>;
}

export interface ContractsSubset {
  contracts: State;
}

const initialState: State = {
  loading: false,
  loaded: false,
  byLocationUuid: {},
  byAccountUuid: {},
  error: null,
};

export const reducer = handleActions<State, any, { locationUuid: string; accountUuid: string }>(
  {
    [FETCH_CONTRACTS]: state => ({ ...state, loading: true, loaded: false }),

    [FETCH_CONTRACTS_SUCCESS]: (
      state,
      action: {
        payload: Array<Reservation>;
        meta: { accountUuid: string };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byAccountUuid: {
        ...state.byAccountUuid,
        [action.meta.accountUuid]: action.payload,
      },
    }),

    [FETCH_CONTRACTS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),

    [FETCH_CONTRACTS_BY_LOCATION]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_CONTRACTS_BY_LOCATION_SUCCESS]: (
      state,
      action: {
        payload: {};
        meta: { locationUuid: string };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byLocationUuid: {
        ...state.byLocationUuid,
        [action.meta.locationUuid]: action.payload,
      },
    }),

    [FETCH_CONTRACTS_BY_LOCATION_FAIL]: (state, action: { error?: boolean }) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
