import { set, merge } from 'dot-prop-immutable';
import { map, includes, reject, get } from 'lodash';

import { EntityAction } from '../types';

const initialState = {};

const lookupReducer = (state: {} = initialState, action: EntityAction) => {
  if (!action.meta || !action.meta.success) {
    return state;
  }

  const { entity } = action.meta;

  if (
    !entity ||
    !includes(['query', 'destroy', 'create'], entity.actionType) ||
    !entity.key ||
    !entity.idAttribute
  ) {
    return state;
  }

  switch (entity.actionType) {
    case 'query':
      if (get(entity, 'params.merge')) {
        return merge(state, entity.key, map(action.payload, entity.idAttribute));
      }

      return set(state, entity.key, map(action.payload, entity.idAttribute));
    case 'destroy':
      return set(
        state,
        entity.key,
        reject(state[entity.key], id => id === get(action.meta, 'params.id'))
      );
    case 'create':
      return set(state, entity.key, [
        get(action.payload, entity.idAttribute),
        ...(state[entity.key] || []),
      ]);
    default:
      return state;
  }
};

export default lookupReducer;
