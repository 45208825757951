import { get } from 'lodash';

import { ActionWithMeta } from 'store/types';
import { GlobalState } from 'store/modules';

type Next = (arg0: {}) => unknown;
type Store = { getState: () => GlobalState };

export type PersistMetaFields = {
  persist: boolean;
  path: string;
};

export default (store: Store) => (next: Next) => (action: ActionWithMeta<PersistMetaFields>) => {
  const returnValue = next(action);

  if (action.meta && action.meta.persist && action.meta.path) {
    const path = action.meta.path;
    const subState = get(store.getState(), path);
    localStorage.setItem(path, JSON.stringify(subState));
  }

  return returnValue;
};
