import { handleActions } from 'redux-actions';
import { Moment } from 'moment-timezone';

import { SET_MOVES_LIST_DATE_RANGE } from 'features/mimo/redux/constants';
import { getMimoDateRange } from 'features/mimo/utils';

export interface State {
  startDate: Moment;
  endDate: Moment;
}

const initialState: State = {
  ...getMimoDateRange(),
};

const reducerDefinition = {
  [SET_MOVES_LIST_DATE_RANGE]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
};

const reducer = handleActions<State, any>(reducerDefinition, initialState);

export default reducer;
