import moment from 'moment-timezone';
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import modals from 'features/rooms/components/modals/redux/reducers';
import { State as ModalsState } from 'features/rooms/components/modals/redux/types';

import { Filters } from '../types';

import {
  State as ReservationsState,
  LocationActionWithMeta,
  LocationActionWithMetaAndPayLoad,
  ReservationActionWithMetaAndPayLoad,
  FetchReservationReasonsPayload,
  CurrentTimeFrameActionPayload,
  RoomFiltersActionPayload,
  FetchReservationReservablePayloadWithMeta,
  SwitchRoomFiltersActionPayload,
  CapacityRoomFiltersActionPayload,
  TimeRoomFiltersActionPayload,
  AmenityRoomFiltersActionPayload,
  RoomsActiveReservationActionPayload,
} from './types';
import {
  convertConferencePayload,
  convertReservationPayload,
  convertReservationByReservablePayload,
  convertRoomReservationReasonsPayload,
} from './util/reducerUtil';
import {
  FETCH_CONFERENCE_ROOMS_BY_LOCATION,
  FETCH_CONFERENCE_ROOMS_BY_LOCATION_SUCCESS,
  FETCH_CONFERENCE_ROOMS_BY_LOCATION_FAIL,
  FETCH_RESERVATION_BY_LOCATION,
  FETCH_RESERVATION_BY_LOCATION_SUCCESS,
  FETCH_RESERVATION_BY_LOCATION_FAIL,
  FETCH_RESERVATION_BY_RESERVABLE_SUCCESS,
  FETCH_ROOM_RESERVATION_REASONS,
  FETCH_ROOM_RESERVATION_REASONS_SUCCESS,
  FETCH_ROOM_RESERVATION_REASONS_FAIL,
  UPDATE_CURRENT_TIME_FRAME,
  UPDATE_ROOMS_FILTERS,
  ROOMS_SET_CURRENT_LOCATION,
  TOGGLE_ROOMS_FILTERS,
  TOGGLE_INTERNAL_FILTER,
  SET_CAPACITY_FILTER,
  SET_TIME_FILTER,
  CLEAR_ALL_ROOMS_FILTERS,
  TOGGLE_AMENITY_FILTER,
  CLEAR_AMENITIES_FILTER,
  ROOMS_CLEAR_ACTIVE_RESERVATION,
  ROOMS_SET_ACTIVE_RESERVATION,
  FETCH_RESERVATION_BY_RESERVABLE_FAIL,
  FETCH_RESERVATION_BY_RESERVABLE,
} from './constants';

export const initialFiltersState: Filters = {
  searchValue: '',
  capacityFilter: 0,
  timeFilter: '',
  internalOnly: false,
  amenitiesFilter: new Set(),
};

const initialState: ReservationsState = {
  location: null,
  byLocationUuid: {},
  currentTimeFrame: moment().format(),
  filters: initialFiltersState,
  isFiltersViewOpen: false,
  roomReservationReasons: {
    data: null,
    loading: false,
    error: null,
  },
  activeReservation: null,
};

export const reservations = handleActions<ReservationsState, any, any>(
  {
    [FETCH_CONFERENCE_ROOMS_BY_LOCATION]: (
      state: ReservationsState,
      action: LocationActionWithMeta
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          conferenceRooms: {
            loading: true,
            loaded: false,
            error: null,
            data: {},
          },
        },
      },
    }),
    [FETCH_CONFERENCE_ROOMS_BY_LOCATION_SUCCESS]: (
      state: ReservationsState,
      action: LocationActionWithMetaAndPayLoad
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          conferenceRooms: {
            loading: false,
            loaded: true,
            error: null,
            data: convertConferencePayload(action.payload, state.currentTimeFrame),
          },
        },
      },
    }),
    [FETCH_CONFERENCE_ROOMS_BY_LOCATION_FAIL]: (
      state: ReservationsState,
      action: LocationActionWithMeta
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          conferenceRooms: {
            loading: false,
            loaded: false,
            error: action.error,
            data: {},
          },
        },
      },
    }),
    [FETCH_RESERVATION_BY_LOCATION]: (
      state: ReservationsState,
      action: ReservationActionWithMetaAndPayLoad
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          reservations:
            state.byLocationUuid[action.meta.locationUuid]?.reservations?.currentTime ===
            action.meta.currentTime
              ? {
                  ...(state.byLocationUuid[action.meta.locationUuid]?.reservations || {}),
                }
              : {
                  currentTime: action.meta.currentTime,
                  loading: true,
                  loaded: false,
                  error: null,
                  data: {},
                },
        },
      },
    }),
    [FETCH_RESERVATION_BY_LOCATION_SUCCESS]: (
      state: ReservationsState,
      action: ReservationActionWithMetaAndPayLoad
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          reservations: {
            ...(state.byLocationUuid[action.meta.locationUuid]?.reservations || {}),
            loading: false,
            loaded: true,
            error: null,
            data: convertReservationPayload(
              action.payload,
              action.meta.currentTime,
              action.meta.timeZone
            ),
          },
        },
      },
    }),
    [FETCH_RESERVATION_BY_LOCATION_FAIL]: (
      state: ReservationsState,
      action: ReservationActionWithMetaAndPayLoad
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          reservations: {
            ...(state.byLocationUuid[action.meta.locationUuid]?.reservations || {}),
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      },
    }),
    [FETCH_RESERVATION_BY_RESERVABLE]: (
      state: ReservationsState,
      action: FetchReservationReservablePayloadWithMeta
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          reservations: {
            ...state.byLocationUuid[action.meta.locationUuid]?.reservations,
            data: {
              ...state.byLocationUuid[action.meta.locationUuid]?.reservations?.data,
              [action.meta.uuid]: {
                ...(state.byLocationUuid[action.meta.locationUuid]?.reservations?.data || {})[
                  action.meta.uuid
                ],
                loading: true,
                loaded: false,
              },
            },
          },
        },
      },
    }),
    [FETCH_RESERVATION_BY_RESERVABLE_SUCCESS]: (
      state: ReservationsState,
      action: FetchReservationReservablePayloadWithMeta
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          reservations: {
            ...state.byLocationUuid[action.meta.locationUuid]?.reservations,
            data: {
              ...state.byLocationUuid[action.meta.locationUuid]?.reservations?.data,
              [action.meta.uuid]: convertReservationByReservablePayload(
                action.payload,
                action.meta.currentTime,
                action.meta.uuid,
                action.meta.timeZone
              ),
            },
          },
        },
      },
    }),
    [FETCH_RESERVATION_BY_RESERVABLE_FAIL]: (
      state: ReservationsState,
      action: FetchReservationReservablePayloadWithMeta
    ) => ({
      ...state,
      byLocationUuid: {
        [action.meta.locationUuid]: {
          ...state.byLocationUuid[action.meta.locationUuid],
          reservations: {
            ...state.byLocationUuid[action.meta.locationUuid]?.reservations,
            loaded: false,
            loading: false,
            error: action.error,
          },
        },
      },
    }),
    [FETCH_ROOM_RESERVATION_REASONS]: (state: ReservationsState) => ({
      ...state,
      roomReservationReasons: {
        loading: true,
        data: {},
        error: null,
      },
    }),
    [FETCH_ROOM_RESERVATION_REASONS_FAIL]: (
      state: ReservationsState,
      action: FetchReservationReasonsPayload
    ) => ({
      ...state,
      roomReservationReasons: {
        loading: false,
        data: {},
        error: action.error,
      },
    }),
    [FETCH_ROOM_RESERVATION_REASONS_SUCCESS]: (
      state: ReservationsState,
      action: FetchReservationReasonsPayload
    ) => ({
      ...state,
      roomReservationReasons: {
        loading: true,
        data: convertRoomReservationReasonsPayload(action.payload.data),
        error: null,
      },
    }),
    [UPDATE_CURRENT_TIME_FRAME]: (
      state: ReservationsState,
      action: CurrentTimeFrameActionPayload
    ) => ({
      ...state,
      currentTimeFrame: action.payload.currentTimeFrame,
      byLocationUuid: {
        [action.payload.locationUuid]: {
          ...state.byLocationUuid[action.payload.locationUuid],
          reservations: {
            ...state.byLocationUuid[action.payload.locationUuid]?.reservations,
            loading: true,
            loaded: false,
            error: null,
          },
        },
      },
    }),
    [UPDATE_ROOMS_FILTERS]: (state, action: RoomFiltersActionPayload) => ({
      ...state,
      filters: action.payload,
    }),
    [ROOMS_SET_CURRENT_LOCATION]: (state, action) => ({
      ...state,
      location: action.payload,
    }),
    [TOGGLE_ROOMS_FILTERS]: (state, action: SwitchRoomFiltersActionPayload) => ({
      ...state,
      isFiltersViewOpen: action.payload,
    }),
    [TOGGLE_INTERNAL_FILTER]: state => ({
      ...state,
      filters: {
        ...state.filters,
        internalOnly: !state.filters.internalOnly,
      },
    }),
    [SET_CAPACITY_FILTER]: (state, action: CapacityRoomFiltersActionPayload) => ({
      ...state,
      filters: {
        ...state.filters,
        capacityFilter: action.payload,
      },
    }),
    [SET_TIME_FILTER]: (state, action: TimeRoomFiltersActionPayload) => ({
      ...state,
      filters: {
        ...state.filters,
        timeFilter: action.payload,
      },
    }),
    [TOGGLE_AMENITY_FILTER]: (state, action: AmenityRoomFiltersActionPayload) => {
      const amenitiesFilter = new Set(state.filters.amenitiesFilter);

      if (state.filters.amenitiesFilter.has(action.payload)) {
        amenitiesFilter.delete(action.payload);
      } else {
        amenitiesFilter.add(action.payload);
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          amenitiesFilter,
        },
      };
    },
    [CLEAR_AMENITIES_FILTER]: state => ({
      ...state,
      filters: {
        ...state.filters,
        amenitiesFilter: new Set(),
      },
    }),
    [CLEAR_ALL_ROOMS_FILTERS]: state => ({
      ...state,
      filters: initialFiltersState,
    }),
    [ROOMS_SET_ACTIVE_RESERVATION]: (state, action: RoomsActiveReservationActionPayload) => ({
      ...state,
      activeReservation: action.payload,
    }),
    [ROOMS_CLEAR_ACTIVE_RESERVATION]: state => ({
      ...state,
      activeReservation: null,
    }),
  },
  initialState
);

export interface State {
  reservations: ReservationsState;
  modals: ModalsState;
}

export interface RoomsSubset {
  rooms: State;
}

const roomsReducers = {
  reservations,
  modals,
};
export default combineReducers<State>(roomsReducers);
