import { createRequestConstantsWithPrefix } from 'store/util/createReduxConstant';

const ORGANIZATION_PREFIX = 'organization';

export const [
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
] = createRequestConstantsWithPrefix('fetchOrganization', ORGANIZATION_PREFIX);

export const [
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
] = createRequestConstantsWithPrefix('updateOrganization', ORGANIZATION_PREFIX);
