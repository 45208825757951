import React from 'react';

import Page from 'components/page';

const NotFound = () => {
  return (
    <Page title="404" name="404">
      Route not found :/
    </Page>
  );
};

export default NotFound;
