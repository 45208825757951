import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { createRequestAction } from 'store/util/createRequestAction';
import config from 'config';
import cc from 'store/util/createReduxConstant';

import { LocationAutocompleteSingleResponse, InventoryErrorResponse } from '../types';

// Action Constants
export const FETCH_PLACE_DETAILS = cc('FETCH_PLACE_DETAILS');
export const FETCH_PLACE_DETAILS_SUCCESS = cc('FETCH_PLACE_DETAILS_SUCCESS');
export const FETCH_PLACE_DETAILS_FAIL = cc('FETCH_PLACE_DETAILS_FAIL');

const types: RequestActionTypes = [
  FETCH_PLACE_DETAILS,
  FETCH_PLACE_DETAILS_SUCCESS,
  FETCH_PLACE_DETAILS_FAIL,
];

export const fetchPlaceDetails = (id: string, sessionToken: string) => (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction<
    LocationAutocompleteSingleResponse | InventoryErrorResponse
  >({
    endpoint: `${config.inventoryQuery.uri}/v1/locationAutocomplete/${id}`,
    errorMessageFromJSON: (json: InventoryErrorResponse) => json.message,
    headers: { 'Session-Token': sessionToken },
    method: 'GET',
    types,
  });

  return dispatch(requestAction);
};

const initialResult: LocationAutocompleteSingleResponse | null | undefined = null;
export default createRequestReducer<
  LocationAutocompleteSingleResponse | null | undefined,
  LocationAutocompleteSingleResponse
>(types, initialResult);
