import { RequestActionTypes, RequestActionHeaders } from 'store/types';
import createRequestAction from 'store/util/createRequestAction';

// TODO(grozki): These are the Param types for the older createRequestAction, here only to serve
//  the adapter temporarily.
export type Params<Payload> = {
  endpoint: string;
  types: RequestActionTypes;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';
  token?: string;
  body?: any;
  meta?: object;
  jsonHeaders?: boolean;
  headers?: RequestActionHeaders;

  getErrorMessageFromResponse?: (res: Response, json: Payload) => string | null | undefined;
  getMetaFromResponse?: (json: Payload, res: Response) => any;
  getFailureMeta?: (error: Error, json: Payload) => any;
  getPayloadFromResponse?: (json: Payload, meta: object | null | undefined) => Promise<any> | any;

  isRequestSuccess?: (response: Response) => boolean;
};

/*
  @deprecated please use `createRequestAction` directly when possible.
 */
const createRequestActionAdapter = <Payload = any>(params: Params<Payload>) =>
  createRequestAction<Payload>({
    endpoint: params.endpoint,
    body: params.body,
    types: params.types,
    token: params.token,
    meta: params.meta,
    method: params.method,
    headers: params.headers,
    metaFromJSON: params.getMetaFromResponse,
    payloadFromJSON: params.getPayloadFromResponse,
    failureMetaFromErrorAndJSON: params.getFailureMeta,
    isRequestSuccess: params.isRequestSuccess,
    errorMessageFromJSON: (json, err) => {
      if (params.getErrorMessageFromResponse) {
        // getErrorMessageFromResponse signature is the opposite - (response/err, json)
        return params.getErrorMessageFromResponse(err, json);
      }

      return undefined;
    },
  });

export default createRequestActionAdapter;
