import React from 'react';
import { connect, ResolveThunks } from 'react-redux';

import {
  notifySuccess,
  notifyError,
  notifyClear,
  notifyInfo,
  notifyWarn,
} from 'store/modules/toasts/actions';
import { ToastCTA } from 'store/modules/toasts/types';
import { BaseAction, Dispatch } from 'store/types';

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  notifyClear: (
    title: string,
    message?: string | null | undefined,
    topic?: string | null | undefined,
    action?: ToastCTA
  ) => dispatch(notifyClear(title, message, topic, action)),
  notifyError: (
    title: string,
    message?: string | null | undefined,
    topic?: string | null | undefined,
    action?: ToastCTA
  ) => dispatch(notifyError(title, message, topic, action)),
  notifyInfo: (
    title: string,
    message?: string | null | undefined,
    topic?: string | null | undefined,
    action?: ToastCTA
  ) => dispatch(notifyInfo(title, message, topic, action)),
  notifySuccess: (
    title: string,
    message?: string | null | undefined,
    topic?: string | null | undefined,
    action?: ToastCTA
  ) => dispatch(notifySuccess(title, message, topic, action)),
  notifyWarn: (
    title: string,
    message?: string | null | undefined,
    topic?: string | null | undefined,
    action?: ToastCTA
  ) => dispatch(notifyWarn(title, message, topic, action)),
});

export type ToastsProps = ResolveThunks<ReturnType<typeof mapDispatchToProps>>;

// TODO(grozki): Remove ts-ignore and fix (https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31363).
export default <Props extends ToastsProps>(Component: React.ComponentType<Props>) =>
  connect(
    null,
    mapDispatchToProps
    // @ts-ignore
  )(Component);
