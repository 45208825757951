import { handleActions, Action } from 'redux-actions';
import qs from 'query-string';

import config from 'config';
import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

import { AgreementHistory, SpacemanApiAgreementHistoryResponse } from './types';

export const [
  FETCH_AGREEMENT_HISTORY,
  FETCH_AGREEMENT_HISTORY_SUCCESS,
  FETCH_AGREEMENT_HISTORY_FAIL,
] = createRequestConstantNames(cc('FETCH_AGREEMENT_HISTORY'));

export interface State {
  loading: boolean;
  data: Array<AgreementHistory>;
}

export interface AgreementHistorySubset {
  agreementHistory: State;
}

const initialState: State = {
  loading: false,
  data: [],
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_AGREEMENT_HISTORY]: (state: State): State => ({
      ...state,
      loading: true,
    }),

    [FETCH_AGREEMENT_HISTORY_SUCCESS]: (
      state: State,
      action: Action<SpacemanApiAgreementHistoryResponse>
    ): State => ({
      ...state,
      loading: false,
      data: action.payload.result,
    }),

    [FETCH_AGREEMENT_HISTORY_FAIL]: (state: State): State => ({
      ...state,
      loading: false,
    }),
  },
  initialState
);

export const fetchAgreementHistory = (accountUuid: string, locationUuid?: string) =>
  createRequestAction<SpacemanApiAgreementHistoryResponse>({
    method: 'GET',
    endpoint: `${config.spaceman.uri}/api/legal_contracts?${qs.stringify({
      account_uuid: accountUuid,
      location_uuid: locationUuid,
    })}`,
    types: [FETCH_AGREEMENT_HISTORY, FETCH_AGREEMENT_HISTORY_SUCCESS, FETCH_AGREEMENT_HISTORY_FAIL],
  });

export default reducer;
