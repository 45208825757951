import { LocationAmenities as MLSLocationAmenities } from '@wework/proton-grpcwebtext/space/marketable_location/models_pb';

import { base64ToUuid, bytesToUuid } from 'lib/binToHex';
import { LocationAmenities } from 'features/building/types';

export const getLocationAmenities = (
  amenities: MLSLocationAmenities[] | undefined
): LocationAmenities => {
  if (!amenities) return {};

  return amenities.reduce((result, amenity) => {
    result[
      base64ToUuid(amenity.getLocationUuid_asB64())
    ] = (amenity.getAmenityUuidsList() as Array<Uint8Array>).map(id => bytesToUuid(id));
    return result;
  }, {});
};
