import { combineReducers } from 'redux';

import buildingSettings, { State as BuildingSettingsState } from './buildingSettings/reducer';
import emails, { State as EmailsState } from './emails/reducer';
import reservables, { State as ReservableState } from './reservables/reducer';
import filters, { State as FiltersState } from './filters/reducer';
import emailLocales from './emailLocales/reducer';
import { State as EmailLocalesState } from './emailLocales/types';
import emailTemplates from './emailTemplates/reducer';
import { State as EmailTemplatesState } from './emailTemplates/types';
import emailTemplateTypes from './emailTemplateTypes/reducer';
import { State as EmailTemplateTypesState } from './emailTemplateTypes/types';
import serviceRetainer, { State as ServiceRetainerState } from './serviceRetainer/reducer';
import requests, { State as RequestsState } from './requests/reducer';
import base, { State as BaseState } from './reducer';

export interface State {
  buildingSettings: BuildingSettingsState;
  emails: EmailsState;
  reservables: ReservableState;
  filters: FiltersState;
  emailLocales: EmailLocalesState;
  emailTemplates: EmailTemplatesState;
  emailTemplateTypes: EmailTemplateTypesState;
  serviceRetainer: ServiceRetainerState;
  requests: RequestsState;
  base: BaseState;
}

export interface MimoSubset {
  mimo: State;
}

const mimoReducers = {
  buildingSettings,
  emails,
  reservables,
  filters,
  emailLocales,
  emailTemplates,
  emailTemplateTypes,
  serviceRetainer,
  requests,
  base,
};

const combinedReducer = combineReducers<State>(mimoReducers);

export type MimoState = ReturnType<typeof combinedReducer>;
export default combinedReducer;
