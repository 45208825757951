import React, { useState } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  InfiniteLoader,
} from 'react-virtualized';
import { Skeleton } from '@wework/ray2';

import NotificationItem from 'features/notifications/components/notificationItem';
import spaceperson from 'images/spaceperson.svg';
import { EmojiName } from 'components-ray/emoji/emojiName';
import { EmptyStateTable } from 'components-ray/emptyState/emptyStateTable';

import { DEFAULT_ROW_HEIGHT, FOOTER, getPopoverStyles, MIN_ROW_HEIGHT } from './constants';
import {
  NotificationItemProps,
  PopoverCellMeasurerProps,
  PopoverNotificationItemProps,
  PopoverProps,
  PopoverWrapperProps,
} from './types';

export const Popover = ({
  hasNextPage = false,
  hasNotificationLoadError,
  isInitialPageLoading,
  isOpen,
  loadNextPage,
  notificationList,
}: PopoverProps): JSX.Element => {
  const [hasLoadedExtraNotifications, setHasLoadedExtraNotifications] = useState<boolean>(false);

  const notificationListLength = notificationList?.length ?? 0;
  const notificationCount =
    hasNextPage || hasLoadedExtraNotifications
      ? notificationListLength + 1
      : notificationListLength;
  const loadedRowsMap = {};
  const isRowLoaded = ({ index }) => {
    if (loadedRowsMap[index]) return true;
    loadedRowsMap[index] = true;
    return false;
  };
  const isLoadingNotificationLoaded = ({ index }) =>
    hasNextPage && index === notificationListLength;
  const isFinalNotificationLoaded = ({ index }) => !hasNextPage && index === notificationListLength;

  const cellMeasurerCache = new CellMeasurerCache({
    defaultHeight: DEFAULT_ROW_HEIGHT,
    fixedWidth: true,
    minHeight: MIN_ROW_HEIGHT,
  });

  /**
   * Helper function that either calls the loadNextPage prop or
   * else resolves an empty Promise
   * @returns {Promise} Promise resolving the fetch request
   */
  const loadMoreNotifications = async () => {
    if (!hasNextPage) {
      return () =>
        new Promise((resolve, reject) => {
          try {
            resolve({});
          } catch (error) {
            reject(error);
          }
        });
    }
    setHasLoadedExtraNotifications(true);
    return loadNextPage();
  };

  /**
   * Memoized NotificationItem for use in the virtualized List
   */
  const NotificationItem: React.FC<NotificationItemProps> = React.memo(
    function PopoverNotificationRow({ componentKey, index, parent, style }) {
      return (
        <Popover.NotificationItem
          cellMeasurerCache={cellMeasurerCache}
          componentKey={componentKey}
          index={index}
          list={notificationList}
          parent={parent}
          style={style}
        />
      );
    }
  );

  const initialLoader: JSX.Element = (
    <>
      <Popover.Loading />
      <Popover.Loading />
    </>
  );

  const renderRows = ({ key, index, parent, style }) => {
    if (hasLoadedExtraNotifications && isFinalNotificationLoaded({ index })) {
      return (
        <Popover.EndOfList
          componentKey={key}
          cellMeasurerCache={cellMeasurerCache}
          index={index}
          parent={parent}
          style={style}
        />
      );
    } else if (isLoadingNotificationLoaded({ index })) {
      return (
        <Popover.Loading
          key={`${key}-loading`}
          componentKey={key}
          cellMeasurerCache={cellMeasurerCache}
          index={index}
          parent={parent}
          style={style}
        />
      );
    }

    return (
      <NotificationItem
        componentKey={key}
        key={`${key}-notification-item`}
        index={index}
        parent={parent}
        style={style}
      />
    );
  };

  const renderList = (height, onRowsRendered, registerChild, width): JSX.Element => {
    if (notificationListLength === 0 && isInitialPageLoading) return initialLoader;

    cellMeasurerCache.clearAll();

    return (
      // @ts-ignore
      <List
        ref={registerChild}
        onRowsRendered={onRowsRendered}
        height={height}
        width={width}
        rowCount={notificationCount}
        deferredMeasurementCache={cellMeasurerCache}
        rowHeight={({ index }) => cellMeasurerCache.rowHeight({ index })}
        rowRenderer={renderRows}
        noRowsRenderer={() => <Popover.Empty />}
      />
    );
  };

  const renderPopover = (): JSX.Element => {
    if (!isOpen) return <></>;
    else if (hasNotificationLoadError) return <Popover.Error />;
    else if (notificationListLength === 0 && !isInitialPageLoading) return <Popover.Empty />;
    else if (isInitialPageLoading) return initialLoader;

    return (
      // @ts-ignore
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreNotifications}
        rowCount={notificationCount}
        threshold={5}
      >
        {({ onRowsRendered, registerChild }): JSX.Element => (
          // @ts-ignore
          <AutoSizer>
            {({ height, width }): JSX.Element =>
              renderList(height, onRowsRendered, registerChild, width)
            }
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  };

  /**
   * TODO: When we receive a new Pusher payload of notifications, and Popover isOpen,
   * we can render them as expected, minus the negative height of the new Pusher items.
   * Then on load, transition the notifications back to a neutral position (translateY(0))
   * {@link https://jira.weworkers.io/browse/SPSN-11399}
   */
  return (
    <aside
      aria-label="Notifications popover"
      className={getPopoverStyles(
        isInitialPageLoading,
        isOpen,
        hasNotificationLoadError,
        notificationCount
      )}
    >
      {renderPopover()}
    </aside>
  );
};

/**
 * Wrapper component used to coordinate the measurement of a List item
 * from react-virtualized
 * @returns JSX.Element
 */
Popover.CellMeasurer = function PopoverCellMeasurer({
  cellMeasurerCache,
  children,
  index,
  parent,
}: PopoverCellMeasurerProps): JSX.Element {
  return (
    // @ts-ignore
    <CellMeasurer cache={cellMeasurerCache} parent={parent} columnIndex={0} rowIndex={index}>
      {children}
    </CellMeasurer>
  );
};

/**
 * A regular Notification that appears in the List
 * @returns JSX.Element
 */
Popover.NotificationItem = function PopoverNotificationItem({
  cellMeasurerCache,
  componentKey = -1,
  index = 0,
  list,
  parent,
  style,
}: PopoverNotificationItemProps): JSX.Element {
  const notification = list[index];

  return (
    <Popover.CellMeasurer
      key={componentKey}
      cellMeasurerCache={cellMeasurerCache}
      index={index}
      parent={parent}
    >
      <NotificationItem notification={notification} style={style} />
    </Popover.CellMeasurer>
  );
};

/**
 * Loading state (group of Skeletons)
 * @returns JSX.Element
 */
Popover.Loading = function PopoverLoading({
  cellMeasurerCache,
  componentKey = -1,
  index = 0,
  parent,
  style,
}: PopoverWrapperProps): JSX.Element {
  const loadingItem = (
    <div className="flex p-sm" style={style}>
      <div className="mr-xs w-10 h-10">
        <Skeleton />
      </div>
      <div className="w-[316px] h-10">
        <Skeleton />
      </div>
    </div>
  );

  if (cellMeasurerCache && parent) {
    return (
      <Popover.CellMeasurer
        key={componentKey}
        cellMeasurerCache={cellMeasurerCache}
        index={index}
        parent={parent}
      >
        {loadingItem}
      </Popover.CellMeasurer>
    );
  }

  return loadingItem;
};

/**
 * Empty state for when there are no notifications
 * @returns JSX.Element
 */
Popover.Empty = function PopoverEmpty(): JSX.Element {
  return (
    <table className="h-full w-full">
      <tbody>
        <EmptyStateTable
          centered
          small
          emoji={EmojiName.EYES}
          headlineContent="Nothing to see here"
          subHeadlineContent="There are no notifications, check back soon!"
        />
      </tbody>
    </table>
  );
};

/**
 * Error state for failing to retrieve Notifications
 * @returns JSX.Element
 */
Popover.Error = function PopoverError(): JSX.Element {
  return (
    <table className="h-full w-full">
      <tbody>
        <EmptyStateTable
          centered
          small
          emoji={EmojiName.FACE_WITH_CROSSED_EYES}
          headlineContent="Oops!"
          subHeadlineContent="Something went wrong loading this info, we are working on a fix!"
        />
      </tbody>
    </table>
  );
};

/**
 * End of feed list item
 * @returns JSX.Element
 */
Popover.EndOfList = function PopoverEndOfList({
  cellMeasurerCache,
  componentKey = -1,
  index = 0,
  parent,
  style,
}: PopoverWrapperProps): JSX.Element {
  const endOfListItem = (
    <footer className="bg-gray-95 flex pt-xs pl-sm pb-0 pr-lg" style={style}>
      <img
        src={spaceperson}
        className="mr-sm h-9 w-8"
        loading="lazy"
        alt="Spaceperson logo representing Spacestation"
      />
      <p className="text-body text-3xs text-gray-40 leading-6">{FOOTER.MISSION_ACCOMPLISHED}</p>
    </footer>
  );

  if (cellMeasurerCache && parent) {
    return (
      <Popover.CellMeasurer
        key={componentKey}
        cellMeasurerCache={cellMeasurerCache}
        index={index}
        parent={parent}
      >
        {endOfListItem}
      </Popover.CellMeasurer>
    );
  }

  return endOfListItem;
};
