import React from 'react';
import { connect } from 'react-redux';

import ModalManager from 'components/modals/manager';

import { getMemberNotificationModalStatus } from '../redux/selectors';

import MemberNotificationModal from './memberNotificationModal';

function MemberNotificationsModalManager({
  currentModal,
}: {
  currentModal: string | null | undefined;
}) {
  return (
    <ModalManager currentModal={currentModal}>
      <MemberNotificationModal key="memberNotificationModal" />
    </ModalManager>
  );
}

const mapStateToProps = state => ({
  currentModal: getMemberNotificationModalStatus(state).name,
});

export default connect(mapStateToProps)(MemberNotificationsModalManager);
