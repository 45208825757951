import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_DOOR_BUILDING_SWIPE_SEARCH,
  FETCH_DOOR_BUILDING_SWIPE_SEARCH_SUCCESS,
  FETCH_DOOR_BUILDING_SWIPE_SEARCH_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_DOOR_BUILDING_SWIPE_SEARCH'));

export const [
  FETCH_DOOR_BUILDING_SWIPE_CONTROLLER,
  FETCH_DOOR_BUILDING_SWIPE_CONTROLLER_SUCCESS,
  FETCH_DOOR_BUILDING_SWIPE_CONTROLLER_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_DOOR_BUILDING_SWIPE_CONTROLLER'));

export const [
  FETCH_DOOR_BUILDING_SWIPE_PARTITIONS,
  FETCH_DOOR_BUILDING_SWIPE_PARTITIONS_SUCCESS,
  FETCH_DOOR_BUILDING_SWIPE_PARTITIONS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_DOOR_BUILDING_SWIPE_PARTITIONS'));

export const [
  FETCH_DOOR_BUILDING_SWIPE_READERS,
  FETCH_DOOR_BUILDING_SWIPE_READERS_SUCCESS,
  FETCH_DOOR_BUILDING_SWIPE_READERS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_DOOR_BUILDING_SWIPE_READER'));

export const [GET_DOOR_BUILDING_SWIPE_SELECTED_DATA] = createRequestConstantNames(
  createReduxConstant('GET_DOOR_BUILDING_SWIPE_SELECTED_DATA')
);
