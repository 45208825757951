import { handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

export const UPDATE_CONTRACT_DETAILS = cc('UPDATE_CONTRACT_DETAILS');

export interface State {
  expiration_period: number | undefined;
  is_manual_finalize_sr: boolean;
  manual_finalize_sr_reason: string | null | undefined;
  reason_option_description: string | null | undefined;
  note: string | null | undefined;
}

export const initialState: State = {
  expiration_period: undefined,
  is_manual_finalize_sr: false,
  manual_finalize_sr_reason: null,
  reason_option_description: null,
  note: null,
};

export type ContractDetails = State;

export const reducer = handleActions<State, any>(
  {
    [UPDATE_CONTRACT_DETAILS]: (state, action: { payload: Partial<State> }) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);

export const updateContractDetails = (payload: Partial<State>) => ({
  type: UPDATE_CONTRACT_DETAILS,
  payload,
});

export default reducer;
