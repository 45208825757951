import config from 'config';

export const getContentfulUri = (
  preview: boolean,
  contentType: string,
  mlsIds: string[] | null
): string => {
  const uri = preview ? config.contentful.preview_uri : config.contentful.cdn_uri;

  return `${
    `${uri}/spaces/${config.contentful.spaceCorePlatform}` +
    `/environments/${config.contentful.environment}/entries` +
    `?locale=en-US&limit=1000&content_type=${contentType}`
  }${mlsIds ? `&fields.mlsUuid[in]=${mlsIds.join(',')}` : ''}`;
};

export const getContentfulUriWithFields = (
  preview: boolean,
  contentType: string,
  fields: string[],
  sys: string[],
  page: number
): string => {
  const uri = preview ? config.contentful.preview_uri : config.contentful.cdn_uri;

  const selected = fields.map(field => `fields.${field}`).concat(sys.map(sss => `sys.${sss}`));

  return `${
    `${uri}/spaces/${config.contentful.spaceCorePlatform}` +
    `/environments/${config.contentful.environment}/entries` +
    `?locale=en-US&limit=1000&content_type=${contentType}&select=${selected.join(',')}&skip=${page}`
  }`;
};
