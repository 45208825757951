import { isSameDay } from 'date-fns';

import { assertIsDefined } from 'lib/assert';
import { GetGlobalState } from 'store/types';
import { fetchGuests } from 'features/visitors/lib/welkioAPI';
import { getCurrentLocation } from 'store/selectors';
import welkioGuestUpdater from 'features/visitors/lib/pusher/welkioGuestUpdater';
import { Location } from 'store/modules/locations/types';
import { createFuzzySearches, Searchable } from 'features/visitors/lib/search';
import { guestSearchFields } from 'features/visitors/constants';
import { parseDate, todayByLocation } from 'features/visitors/util';

import { EntityType, SearchRequest, AdapterResult, GuestSearchResultItem } from '../types';
import { Adapter } from '../searchService';

export default class WelkioGuestAdapter implements Adapter {
  getState: GetGlobalState;
  currentLocation: Location;
  currentDate: Date;
  searchableCache: Searchable<GuestSearchResultItem> | null; // cached guests searchable

  constructor(getState: GetGlobalState) {
    this.getState = getState;
    welkioGuestUpdater.get(this.getState).then(updater => updater.register(this.handleWelkioPush));
  }

  handleWelkioPush = (_id, _type, pushedLocationId: string, pushedDate: string) => {
    if (!this.searchableCache) {
      return;
    }

    const date = parseDate(pushedDate, this.currentLocation?.time_zone);
    if (this.isSameLocationAndDay(pushedLocationId, date)) {
      this.searchableCache = null; // Invalidate cache on relevant push
    }
  };

  isSameLocationAndDay = (locationId: string, date: Date) =>
    this.currentLocation &&
    this.currentDate &&
    this.currentLocation.uuid === locationId &&
    isSameDay(this.currentDate, date);

  validateSearchableGuestCache() {
    const state = this.getState();
    const location = getCurrentLocation(state, {});

    assertIsDefined(location);

    const today = todayByLocation(location);
    if (!this.isSameLocationAndDay(location.uuid, today)) {
      this.searchableCache = null;
    }

    this.currentLocation = location;
    this.currentDate = today;
  }

  async search(request: SearchRequest): Promise<AdapterResult> {
    const query = request.query?.toLowerCase();
    const limit = request.limit;
    this.validateSearchableGuestCache();

    if (!this.searchableCache) {
      const guests = await fetchGuests(this.currentLocation, this.currentDate);
      this.searchableCache = createFuzzySearches(guests, guestSearchFields);
    }

    const items = this.searchableCache.search(query || '').slice(0, limit);

    return {
      [EntityType.GUEST]: {
        items,
        totalCount: items.length,
        facets: undefined,
      },
    };
  }
}
