import { isEmpty } from 'lodash';
import { handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';

import {
  FETCH_MARKETABLE_LOCATIONS,
  FETCH_MARKETABLE_LOCATIONS_FAILURE,
  FETCH_MARKETABLE_LOCATIONS_SUCCESS,
  FETCH_SEARCH_SI_LOCATIONS,
  FETCH_SEARCH_SI_LOCATIONS_FAILURE,
  FETCH_SEARCH_SI_LOCATIONS_SUCCESS,
} from './constants';
import { LocationPayload, LocationState } from './types';

const intialState: LocationState = {
  locationIndex: {},
  searchResult: [],
  loadingCount: 0,
};

const cloneStateIncLoading = (state: LocationState): LocationState => {
  state = { ...state, loadingCount: state.loadingCount + 1 };
  return state;
};

const cloneStateDecLoading = (state: LocationState): LocationState => {
  state = { ...state, loadingCount: state.loadingCount - 1 };
  return state;
};

const mergeLocations = (state: LocationState, action: ActionWithPayload<LocationPayload>) => {
  if (isEmpty(action.payload.locations)) {
    return;
  }
  state.locationIndex = { ...state.locationIndex };
  for (const location of action.payload.locations) {
    state.locationIndex[location.id] = location;
  }
};

const reducer = handleActions<LocationState, LocationPayload>(
  {
    [FETCH_MARKETABLE_LOCATIONS]: cloneStateIncLoading,
    [FETCH_SEARCH_SI_LOCATIONS]: cloneStateIncLoading,

    [FETCH_MARKETABLE_LOCATIONS_FAILURE]: cloneStateDecLoading,
    [FETCH_SEARCH_SI_LOCATIONS_FAILURE]: cloneStateDecLoading,

    [FETCH_MARKETABLE_LOCATIONS_SUCCESS]: (
      state: LocationState,
      action: ActionWithPayload<LocationPayload>
    ): LocationState => {
      state = cloneStateDecLoading(state);
      mergeLocations(state, action);
      return state;
    },
    [FETCH_SEARCH_SI_LOCATIONS_SUCCESS]: (
      state: LocationState,
      action: ActionWithPayload<LocationPayload>
    ): LocationState => {
      state = cloneStateDecLoading(state);
      state.searchResult = action.payload.locations.map(item => item.id);
      mergeLocations(state, action);
      return state;
    },
  },
  intialState
);

export default reducer;
