import { createConstants, createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const constants = createConstants([
  ...createRequestConstantNames('GET_COMPANY'),
  ...createRequestConstantNames('GET_COMPANY_SF_URL'),
  ...createRequestConstantNames('GET_COMPANY_SF_ORG_URL'),
  ...createRequestConstantNames('GET_COMPANY_ACCOUNT_MANAGER'),
  ...createRequestConstantNames('UPDATE_COMPANY'),
  ...createRequestConstantNames('ACTIVATE_COMPANY'),
  ...createRequestConstantNames('FETCH_CONFERENCE_ROOM_CREDIT_USAGE_BY_COMPANY'),
  ...createRequestConstantNames('GET_ACCOUNT_SERVICE_ACCOUNT'),
  ...createRequestConstantNames('CREATE_ORGANIZATION'),
  ...createRequestConstantNames('UPDATE_ORGANIZATION'),
  ...createRequestConstantNames('DELETE_ORGANIZATION'),
]);

export const [
  DISPATCH_ACTIVATE_COMPANY,
  DISPATCH_ACTIVATE_COMPANY_SUCCESS,
  DISPATCH_ACTIVATE_COMPANY_FAIL,
] = createRequestConstantNames(cc('DISPATCH_ACTIVATE_COMPANY'));

export const [
  DISPATCH_DEACTIVATE_COMPANY,
  DISPATCH_DEACTIVATE_COMPANY_SUCCESS,
  DISPATCH_DEACTIVATE_COMPANY_FAIL,
] = createRequestConstantNames(cc('DISPATCH_DEACTIVATE_COMPANY'));

export const [
  SYNC_COMPANY_WITH_SPACEMAN,
  SYNC_COMPANY_WITH_SPACEMAN_SUCCESS,
  SYNC_COMPANY_WITH_SPACEMAN_FAIL,
] = createRequestConstantNames(cc('SYNC_COMPANY_WITH_SPACEMAN'));

export const [
  UPDATE_COMPANY_OVERAGE_LIMIT,
  UPDATE_COMPANY_OVERAGE_LIMIT_SUCCESS,
  UPDATE_COMPANY_OVERAGE_LIMIT_FAIL,
] = createRequestConstantNames(cc('UPDATE_COMPANY_OVERAGE_LIMIT'));

export const [
  DELETE_COMPANY_OVERAGE_LIMIT,
  DELETE_COMPANY_OVERAGE_LIMIT_SUCCESS,
  DELETE_COMPANY_OVERAGE_LIMIT_FAIL,
] = createRequestConstantNames(cc('DELETE_COMPANY_OVERAGE_LIMIT'));
