import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { store } from 'store/getOrInitStore';
import config from 'config';
import { getRedirectUrl } from 'lib/tokenRegistry/auth0Provider';
import { Auth0Wrapper } from 'lib/tokenRegistry/auth0Wrapper';
import createHistory from 'lib/createHistory';
import RouteErrorBoundary from 'features/app/routeErrorBoundary';
import { loginSuccess } from 'features/auth/requestActions';
import { mxGqlClient } from 'lib/apollo';

moment.locale('en-US');

const AppContainer = () => {
  const onRedirectCallback = async (appState: AppState): Promise<void> => {
    const url = new URL(appState?.returnTo || window.location.href);

    // @ts-ignore
    await store.dispatch(loginSuccess);
    window.location.replace(url.toString());
  };

  return (
    <Auth0Provider
      audience="wework"
      cacheLocation="localstorage"
      clientId={config.auth0.clientId}
      domain={config.auth0.domain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={getRedirectUrl()}
      useRefreshTokens
    >
      {/* @ts-ignore */}
      <Provider store={store}>
        <ApolloProvider client={mxGqlClient}>
          <Router history={createHistory()}>
            <RouteErrorBoundary>
              <Auth0Wrapper />
            </RouteErrorBoundary>
          </Router>
        </ApolloProvider>
      </Provider>
    </Auth0Provider>
  );
};
export default hot(AppContainer);
