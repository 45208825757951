import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';

import { sendTimingEvent } from 'lib/timing';

import 'focus-visible';
import 'lib/analytics';
import 'lib/rollbar';

import AppContainer from './appContainer';

// This is needed so screen readers don't see main content when modal is opened.
ReactModal.setAppElement('#app');

function render(Component) {
  // Add this regex to make the headless testing work
  if (window.chrome || /Chrome/.test(window.navigator.userAgent)) {
    const root = createRoot(document.getElementById('app')!);
    root.render(<Component />);
  } else {
    const preload = document.getElementById('preload');

    if (preload) {
      preload.style.display = 'block';
    }
  }

  // Send timing event on tick after the load event fires
  window.addEventListener('load', () => setTimeout(sendTimingEvent));
}

render(AppContainer);
