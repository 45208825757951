export type TimeWatch = {
  stop: () => void;
  id: string;
};

export type TrackOptions = {
  startTime: number;
  endTime: number;
  passedTime: number;
};

export type Track = (arg0: TrackOptions) => void;

export type Event = {
  startTime: number;
  endTime: number;
  eventLabel: string;
  track: Track;
};

const eventsMap: Hash<Event> = {};

const validateEvent = (eventLabel: string): boolean => {
  if (eventsMap[eventLabel]) {
    return true;
  }

  if (__DEVELOPMENT__) {
    // eslint-disable-next-line no-console
    console.warn(`Trying to measure event: ${eventLabel} that wasn't initialized.`);
  }

  return false;
};

const validateEventUnique = (eventLabel: string): boolean => {
  if (!eventsMap[eventLabel]) {
    return true;
  }

  if (__DEVELOPMENT__) {
    // eslint-disable-next-line no-console
    console.warn(`Trying to initialize event: ${eventLabel} that was already initialized.`);
  }

  return false;
};

const trackEvent = (evt: Event) => {
  evt.track({
    startTime: evt.startTime,
    endTime: evt.endTime,
    passedTime: evt.endTime - evt.startTime,
  });
};

export function stopTracking(eventLabel: string) {
  if (!validateEvent(eventLabel)) {
    return;
  }

  eventsMap[eventLabel].endTime = Date.now();

  trackEvent(eventsMap[eventLabel]);
  delete eventsMap[eventLabel];
}

export function startTracking(eventLabel: string, track: Track): TimeWatch | null | undefined {
  if (!validateEventUnique(eventLabel)) {
    return null;
  }

  eventsMap[eventLabel] = {
    eventLabel,
    track,
    startTime: Date.now(),
    endTime: 0,
  };

  return {
    id: eventLabel,
    stop: () => {
      stopTracking(eventLabel);
    },
  };
}
