import { BaseAction, Dispatch } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import cc from 'store/util/createReduxConstant';
import { Transportation, TransportationPayload } from 'features/building/types';
import { getContentfulUri } from 'features/building/ducks/contentfulUriUtil';
import createRequestAction from 'store/util/createRequestAction';
import config from 'config';

import { getTransportationByID } from '../serializers/transitSerializer';

// Action Constants
export const FETCH_TRANSIT = cc('FETCH_TRANSIT');
export const FETCH_TRANSIT_SUCCESS = cc('FETCH_TRANSIT_SUCCESS');
export const FETCH_TRANSIT_FAIL = cc('FETCH_TRANSIT_FAIL');

export const fetchTransportation = () => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'GET',
    endpoint: getContentfulUri(false, 'transportationAsset', null),
    headers: {
      Authorization: `Bearer ${config.contentful.cdn_token}`,
    },
    types: [{ type: FETCH_TRANSIT }, { type: FETCH_TRANSIT_SUCCESS }, { type: FETCH_TRANSIT_FAIL }],
  });

  return dispatch(requestAction);
};

const reducer = createRequestReducer<
  { [key: string]: Transportation } | null,
  TransportationPayload | undefined
>([FETCH_TRANSIT, FETCH_TRANSIT_SUCCESS, FETCH_TRANSIT_FAIL], null, res =>
  getTransportationByID(res.payload)
);

export default reducer;
