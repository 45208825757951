import {
  createConstantWithPrefix,
  createRequestConstantsWithPrefix,
} from 'store/util/createReduxConstant';

const ORGANIZATION_NAME_VALIDATION_PREFIX = 'organizationNameValidation';

export const [
  VALIDATE_ORGANIZATION_NAME,
  VALIDATE_ORGANIZATION_NAME_SUCCESS,
  VALIDATE_ORGANIZATION_NAME_FAIL,
] = createRequestConstantsWithPrefix(
  'validateOrganizationName',
  ORGANIZATION_NAME_VALIDATION_PREFIX
);

export const SET_IS_ORGANIZATION_VALID = createConstantWithPrefix(
  'setIsOrganizationValid',
  ORGANIZATION_NAME_VALIDATION_PREFIX
);
