import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

import LoadingPage from 'components/loadingPage';
import { getUserLocationUuidFromAuth } from 'features/auth/selectors';
import styles from 'features/app/app.scss';
import EditLocationModal from 'features/app/editHomeLocationModal';
import { ReduxProps } from 'store/types';

type OwnProps = {
  children: React.ReactElement;
  isPreloading: boolean;
};

const mapStateToProps = state => ({
  needsToSetDefaultLocation: !getUserLocationUuidFromAuth(state),
});

type Props = OwnProps & ReduxProps<typeof mapStateToProps> & WithAuth0Props;

const AppLoadingComponent = ({
  auth0,
  children,
  isPreloading,
  needsToSetDefaultLocation,
}: Props) => {
  if (isPreloading) {
    return <LoadingPage invert>Logging in now...</LoadingPage>;
  }

  // Render the default location modal if the user needs to set their default location
  if (auth0.isAuthenticated && !isPreloading && needsToSetDefaultLocation) {
    return (
      <div className={styles.modalWrapper}>
        <EditLocationModal onDismiss={() => undefined} isOpen={needsToSetDefaultLocation} />
      </div>
    );
  }

  return children;
};

export const TestableAppLoadingComponent = AppLoadingComponent;

export const AppLoading = compose<React.ComponentType<OwnProps>>(
  withAuth0,
  connect(mapStateToProps)
)(AppLoadingComponent);
