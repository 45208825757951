import { Member } from 'features/locations/buildingDirectory/types';
import { MemberNotificationTemplate } from 'features/communication/memberNotifications/types';

export type Variable = {
  value: string;
  text: string;
  name: string;
  description: string;
  replaceValue: (member: Member) => string;
};

export const injectableVariables: Array<Variable> = [
  {
    value: 'memberFirstName',
    text: 'member first name',
    name: 'memberFirstName',
    description: 'The first name of the member',
    replaceValue: (member: Member): string => member?.name.split(' ', 1)[0],
  },
  {
    value: 'memberLastName',
    text: 'member last name',
    name: 'memberLastName',
    description: 'The last name of the member',
    replaceValue: (member: Member): string => member?.name.split(' ').pop() ?? '',
  },
  {
    value: 'firstName',
    text: 'first Name',
    name: 'firstName',
    description: 'The first name of the member',
    replaceValue: (member: Member): string => member?.name.split(' ', 1)[0],
  },
  {
    value: 'lastName',
    text: 'last Name',
    name: 'lastName',
    description: 'The last name of the member',
    replaceValue: (member: Member): string => member?.name.split(' ').pop() ?? '',
  },
  {
    value: 'companyName',
    text: 'company Name',
    name: 'companyName',
    description: 'The name of the company',
    replaceValue: (member: Member): string => member?.companies[0]?.name,
  },
];

export function replaceVariables(
  str: string,
  member: Member,
  variables = injectableVariables
): string {
  return str.replace(/{{(.*?)}}/g, (substring, variableName): string => {
    const variableDef = variables.find((variable): boolean => variable?.name === variableName);

    if (variableDef) {
      return variableDef.replaceValue(member);
    }

    throw new Error(`Could not replace "${substring}", no such variable definition provided.`);
  });
}

export function replaceVariablesInTemplate(template: MemberNotificationTemplate, member: Member) {
  return {
    title: replaceVariables(template.title, member),
    body: template.body ? replaceVariables(template.body, member) : '',
    type: template.type,
  };
}
