import { handleActions, Action } from 'redux-actions';

import { SpacemanApiCancelWaiveResponse } from 'features/companies/addOnsSection/redux/waiveRequests/types';
import { BaseState } from 'store/types';
import {
  CANCEL_WAIVE_REQUEST,
  CANCEL_WAIVE_REQUEST_SUCCESS,
  CANCEL_WAIVE_REQUEST_FAIL,
} from 'features/companies/addOnsSection/redux/waiveRequests/constants';

export interface State extends BaseState {
  loading: boolean;
  loaded: boolean;
  success: boolean;
  error: boolean | null | undefined;
}

export interface WaiveRequestsSubset {
  waiveRequests: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  success: false,
  error: null,
};

export const reducer = handleActions<State, any>(
  {
    [CANCEL_WAIVE_REQUEST]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [CANCEL_WAIVE_REQUEST_SUCCESS]: (
      state: State,
      _action: Action<SpacemanApiCancelWaiveResponse>
    ): State => ({
      ...state,
      loading: false,
      loaded: true,
      success: true,
      error: null,
    }),
    [CANCEL_WAIVE_REQUEST_FAIL]: (state: State, action: Action<unknown>): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
