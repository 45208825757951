import { RequestActionTypes } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';

import {
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION,
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION_SUCCESS,
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION_FAIL,
} from './constants';
import { AccountExpiringCommitmentTerms } from './types';

const types: RequestActionTypes = [
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION,
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION_SUCCESS,
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION_FAIL,
];

const reducer = createRequestReducer<
  AccountExpiringCommitmentTerms[],
  AccountExpiringCommitmentTerms[]
>(types, [], res => res.payload);
export default reducer;
