import { createAction, Action } from 'redux-actions';

import { TOAST, ToastType } from './constants';
import { ToastCTA, Toast } from './types';

export type ToastCreator = (
  title: string,
  message?: string | null | undefined,
  topic?: string | null | undefined,
  action?: ToastCTA
) => Toast;

type ToastActionCreator = (
  title: string,
  message?: string | null | undefined,
  topic?: string | null | undefined,
  action?: ToastCTA
) => Action<Toast>;

const createToastAction = (type: ToastType): ToastActionCreator =>
  createAction(
    TOAST,
    (title, message, topic, action): Toast => ({
      type,
      title,
      message,
      topic,
      ...action,
    })
  );

// Leaving these as `notifyX` for historic purposes and to mirror other services that use
// this language for similar features
export const notifyInfo = createToastAction(ToastType.INFO);
export const notifyError = createToastAction(ToastType.ERROR);
export const notifySuccess = createToastAction(ToastType.SUCCESS);
export const notifyWarn = createToastAction(ToastType.WARN);
export const notifyClear = createToastAction(ToastType.CLEAR);
