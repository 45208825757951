import { ModalTypes } from '../config/types';

const modalTypes: Hash<ModalTypes> = {
  addNoteModal: 'addNoteModal',
  officeHoldModal: 'officeHoldModal',
  // this key is needed to keep transition between OfficeHoldModal and RemoveOfficeHoldModal working
  removeOfficeHoldModal: 'removeOfficeHoldModal',
};

export default modalTypes;
