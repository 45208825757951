import ApiRequest from 'lib/api/apiRequest';
import config from 'config';

export default class OperatorServiceAPI extends ApiRequest {
  constructor() {
    super(config.operatorService.uri);
  }

  getHasPermissionResponse = async (
    employeeUuid: string,
    userUuid: string,
    permissions: string
  ): Promise<Record<string, Record<string, boolean>>> => {
    const request = {
      path: 'v1/authz/has_permissions/by_users_spaceman_locations',
      params: {
        accumulator: 'ANY',
        employee_uuid: employeeUuid,
        perms: permissions,
        user_uuids: userUuid,
      },
      config: {
        isCamelCase: false,
      },
    };

    return this.get(request);
  };
}
