import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_MOVE_TIMES_SETTINGS,
  FETCH_MOVE_TIMES_SETTINGS_SUCCESS,
  FETCH_MOVE_TIMES_SETTINGS_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_MOVE_TIMES_SETTINGS'));

export const [
  SET_MOVE_TIMES_SETTINGS,
  SET_MOVE_TIMES_SETTINGS_SUCCESS,
  SET_MOVE_TIMES_SETTINGS_FAILURE,
] = createRequestConstantNames(cc('mimo/SET_MOVE_TIMES_SETTINGS'));

export const [
  FETCH_MMP_BUILDING_SETTINGS,
  FETCH_MMP_BUILDING_SETTINGS_SUCCESS,
  FETCH_MMP_BUILDING_SETTINGS_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_MMP_BUILDING_SETTINGS'));

export const [
  SET_MMP_BUILDING_SETTINGS,
  SET_MMP_BUILDING_SETTINGS_SUCCESS,
  SET_MMP_BUILDING_SETTINGS_FAILURE,
] = createRequestConstantNames(cc('mimo/SET_MMP_BUILDING_SETTINGS'));

export const [
  UPLOAD_COI_TEMPLATE,
  UPLOAD_COI_TEMPLATE_SUCCESS,
  UPLOAD_COI_TEMPLATE_FAILURE,
] = createRequestConstantNames(cc('mimo/UPLOAD_COI_TEMPLATE'));

export const SET_SETTINGS_LOCATION_UUID = cc('mimo/SET_SETTINGS_LOCATION_UUID');

export enum LoadState {
  LOADING,
  SUCCESS,
  FAILURE,
}
