import { createSelector } from 'reselect';

import DESCRIPTORS from 'features/search/resultSets/descriptors';
import { Result, ResultSet } from 'features/search/resultSets/types';
import { SearchableEntity } from 'features/search/types';
import {
  MemberSearchResultItem,
  CompanySearchResultItem,
  Status,
} from 'store/modules/search/searchService/types';
import { getSearchFilters } from 'store/modules/search';
import { getCurrentLocationUuid } from 'store/selectors';
import { GlobalState } from 'store/modules';

import { RecentlyViewedItem } from '../types';

import { getRecentlyViewedMap } from './recentlyViewedMap';

const newestFirst = (firstItem: RecentlyViewedItem, secondItem: RecentlyViewedItem) =>
  secondItem.viewDate - firstItem.viewDate;

const recentlyViewedToSearchResult = ({ searchResult, entityType }: RecentlyViewedItem) =>
  searchResult && DESCRIPTORS[entityType]?.mapToResult(searchResult);

const matchSearchFilters = (searchFilters: Hash<boolean>, currentLocationUuid: string) => ({
  searchResult,
  entityType,
}: RecentlyViewedItem): boolean => {
  if (!searchResult) {
    return false;
  }

  let matchesFilters = true;
  const isCompany = entityType === SearchableEntity.COMPANIES;
  const isMember = entityType === SearchableEntity.MEMBERS;

  if ((isCompany || isMember) && searchFilters.currentLocation && currentLocationUuid) {
    const { location_uuids } = searchResult as MemberSearchResultItem | CompanySearchResultItem;
    matchesFilters = location_uuids?.includes(currentLocationUuid) ?? false;
  }

  if (matchesFilters && isMember && searchFilters.activeMembers) {
    const isActive = (searchResult as MemberSearchResultItem).status === Status.ACTIVE;
    matchesFilters = isActive;
  }

  return matchesFilters;
};

export const getRecentlyViewedLoading = (state: GlobalState): boolean =>
  state.recentlyViewed.loading;
export const getRecentlyViewed = createSelector(
  [getRecentlyViewedLoading, getRecentlyViewedMap, getSearchFilters, getCurrentLocationUuid],
  (isLoading, recentlyViewedMap, searchFilters, currentLocationUuid) => {
    if (isLoading) {
      return [];
    }

    const searchResults = Object.keys(recentlyViewedMap)
      .map(id => recentlyViewedMap[id])
      // @ts-ignore
      .filter(matchSearchFilters(searchFilters, currentLocationUuid))
      .sort(newestFirst)
      .map(recentlyViewedToSearchResult)
      .filter(Boolean) as Array<Result>;

    const searchResultSets: Array<ResultSet> = [
      {
        key: SearchableEntity.RECENTLY_VIEWED,
        title: 'Recently viewed',
        results: searchResults,
      },
    ];
    return searchResultSets;
  }
);
