import config from '../config';

import WeWorkBase from './weworkBase';

export default class SalesAPI extends WeWorkBase {
  constructor() {
    super(config.salesAPI.uri);
  }

  getOpportunityStage(id: string) {
    return this.get(`/v2/opportunities/${id}`);
  }
}
