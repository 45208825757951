import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_CONTRACT_DETAILS,
  FETCH_CONTRACT_DETAILS_SUCCESS,
  FETCH_CONTRACT_DETAILS_FAIL,
] = createRequestConstantNames('FETCH_CONTRACT_DETAILS');

export const [
  FETCH_ALL_CONTRACT_DETAILS,
  FETCH_ALL_CONTRACT_DETAILS_SUCCESS,
  FETCH_ALL_CONTRACT_DETAILS_FAIL,
] = createRequestConstantNames('FETCH_ALL_CONTRACT_DETAILS');

export const [
  FETCH_RENEWAL_OPPORTUNITY,
  FETCH_RENEWAL_OPPORTUNITY_SUCCESS,
  FETCH_RENEWAL_OPPORTUNITY_FAIL,
] = createRequestConstantNames('FETCH_RENEWAL_OPPORTUNITY');
