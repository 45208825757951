export const CURRENT_LOCATION_EVENT = 'current-location-event';
export const EXPERIMENT_CHANGED_EVENT = 'experiment-changed-event';
export const ROUTING_EVENT = 'single-spa:routing-event';
export const TO_DYNAMIC_PATH_REGEX = /.+?(?=\/:)/;
export const MC_SLACK_URI = 'https://wework.slack.com/archives/C03EPL6TGP5';

export const SS_MC_OPERATOR_ROUTE_MAP = {
  '/admin/operators': '/identity/operators',
  '/admin/roles-and-permissions/roles': '/identity/roles-and-permissions/roles',
  '/admin/roles-and-permissions/permissions': '/identity/roles-and-permissions/permissions',
  '/admin/employees': '/identity/employees',
  '/admin/contractors': '/identity/contractors',
  '/admin/sso-configs': '/identity/sso-configs',
  '/admin/scim-tenants': '/identity/scim-tenants',
  '/admin/account-dns-verifications': '/identity/account-dns-verifications',
};

export const MC_OPERATOR_ENDPOINTS = Object.keys(SS_MC_OPERATOR_ROUTE_MAP);

export const MC_OPERATOR_PAGE_NAMES = {
  '/admin/operators': 'Operators',
  '/admin/roles-and-permissions/roles': 'Roles and Permissions',
  '/admin/roles-and-permissions/permissions': 'Roles and Permissions',
  '/admin/employees': 'Employees',
  '/admin/contractors': 'Contractors',
  '/admin/sso-configs': 'SSO Configs',
  '/admin/scim-tenants': 'ID SCIM',
  '/admin/account-dns-verifications': 'Account DNS Verifications',
};

export const ROUTER_TYPE_HASH = 'HASH';
