import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { Memberships } from 'features/reservations/types';
import { AuthPrefix, httpFetcher } from 'lib/fetchAPI';

const fetcher = httpFetcher({
  domain: config.pegasus.uri,
  authPrefix: AuthPrefix.BEARER,
  entry: 'result',
  getAccessToken,
});

export async function fetchMemberships(userIds: Array<string>): Promise<Memberships> {
  return fetcher(
    'user_memberships',
    {
      user_uuids: userIds,
      status_scope: 'active',
    },
    true
  );
}
