import { TabDefinition, SearchableEntity } from './types';
import { ResultSet } from './resultSets/types';

const FILTER_KEY_CURRENT_LOCATION = 'currentLocation';
const FILTER_KEY_ACTIVE_MEMBERS = 'activeMembers';

const SEARCH_RESULTS_LIMIT_PER_SET = 5;

type CreateTabSelectionParams = {
  filterByKey?: SearchableEntity;
  limitItems?: boolean;
  hideAllTitles?: boolean;
};

type CreateResultSelectionParams = {
  filterByEntityType?: SearchableEntity;
  hideAllTitles?: boolean;
};

/**
 * Creates a new selection function which first filters specific result sets and slices them.
 *
 * For example, the Companies tab needs to only display the company result set.
 */
const createTabSelectResultSet = ({
  filterByKey,
  limitItems,
  hideAllTitles,
}: CreateTabSelectionParams) => (resultSets: Array<ResultSet>): Array<ResultSet> =>
  resultSets
    .filter(filterByKey ? set => set?.key === filterByKey : Boolean)
    .map(({ results, hideTitle, ...rest }) => ({
      ...rest,
      results: limitItems ? results?.slice(0, SEARCH_RESULTS_LIMIT_PER_SET) : results,
      hideTitle: hideAllTitles ?? hideTitle,
    }));

const tabAllSelectResultFn = createTabSelectResultSet({
  limitItems: true,
});

const createSelectResults = ({
  filterByEntityType,
  hideAllTitles,
}: CreateResultSelectionParams) => (resultSets: Array<ResultSet>): Array<ResultSet> =>
  resultSets.map(({ results, hideTitle, ...rest }) => ({
    ...rest,
    results: results.filter(
      filterByEntityType ? result => result?.entityType === filterByEntityType : Boolean
    ),
    hideTitle: hideAllTitles ?? hideTitle,
  }));

export const TABS: Array<TabDefinition> = [
  {
    key: 'all',
    title: 'All',
    selectResultSet: tabAllSelectResultFn,
    selectResults: (resultSets: Array<ResultSet>) => resultSets,
    options: [
      {
        id: FILTER_KEY_CURRENT_LOCATION,
        title: 'Only current location',
        defaultValue: true,
      },
      {
        id: FILTER_KEY_ACTIVE_MEMBERS,
        title: 'Active only',
        defaultValue: true,
      },
    ],
  },
  {
    key: 'companies',
    title: 'Companies',
    selectResultSet: createTabSelectResultSet({
      filterByKey: SearchableEntity.COMPANIES,
      hideAllTitles: true,
    }),
    selectResults: createSelectResults({
      filterByEntityType: SearchableEntity.COMPANIES,
      hideAllTitles: true,
    }),
    options: [
      {
        id: FILTER_KEY_CURRENT_LOCATION,
        title: 'Only current location',
        defaultValue: true,
      },
      {
        id: FILTER_KEY_ACTIVE_MEMBERS,
        title: 'Active only',
        defaultValue: true,
      },
    ],
  },
  {
    key: 'members',
    title: 'Members',
    selectResultSet: createTabSelectResultSet({
      filterByKey: SearchableEntity.MEMBERS,
      hideAllTitles: true,
    }),
    selectResults: createSelectResults({
      filterByEntityType: SearchableEntity.MEMBERS,
      hideAllTitles: true,
    }),
    options: [
      {
        id: FILTER_KEY_CURRENT_LOCATION,
        title: 'Only current location',
        defaultValue: true,
      },
      {
        id: FILTER_KEY_ACTIVE_MEMBERS,
        title: 'Active only',
        defaultValue: true,
      },
    ],
  },
  {
    key: 'guests',
    title: 'Guests',
    selectResultSet: createTabSelectResultSet({
      filterByKey: SearchableEntity.GUESTS,
      hideAllTitles: true,
    }),
    selectResults: createSelectResults({
      filterByEntityType: SearchableEntity.GUESTS,
      hideAllTitles: true,
    }),
    options: [
      {
        id: FILTER_KEY_CURRENT_LOCATION,
        title: 'Only current location',
        disabled: true,
        defaultValue: true,
        overrideValue: true,
      },
    ],
  },
];

export default TABS;
