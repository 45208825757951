import { handleActions, createAction } from 'redux-actions';

import { GlobalState } from 'store/modules';
import { PaperworkSubset } from 'features/paperwork/ducks';

export const SET_SERVICE_CLOUD_NUMBER = 'SET_SERVICE_CLOUD_NUMBER';

export interface ServiceCloudNumberState {
  serviceCloudNumber: string;
}

export const getServiceCloudNumber = (state: GlobalState | PaperworkSubset) => {
  return state.paperwork.agreementManagement.serviceCloudNumber;
};

export const getServiceCloudNumberValue = (state: GlobalState | PaperworkSubset) => {
  return getServiceCloudNumber(state).serviceCloudNumber;
};

export const initialState = { serviceCloudNumber: '' };

export const setServiceCloudNumber = createAction(
  SET_SERVICE_CLOUD_NUMBER,
  (serviceCloudNumber: string) => ({ serviceCloudNumber })
);

const reducer = handleActions<ServiceCloudNumberState, any>(
  {
    [SET_SERVICE_CLOUD_NUMBER]: (state, action) => ({
      ...state,
      serviceCloudNumber: action.payload.serviceCloudNumber,
    }),
  },
  initialState
);

export default reducer;
