import { compact } from 'lodash';

import { Location } from 'store/modules/locations';
import { ON_DEMAND_PRODUCT_UUIDS } from 'store/modules/userMemberships/constants';
import { formatDateWithTimeZone } from 'features/visitors/util';
import { ERRORS } from 'features/reservations/constants';

import { NO_DESK_TOOLTIP, NO_MEMBER_TOOLTIP } from '../constants';

export const getLocationAddress = location => {
  return compact([
    location.name,
    location.address.city,
    location.address.state,
    location.address.country,
  ]).join(', ');
};

export const isOnDemand = productUuid => {
  return ON_DEMAND_PRODUCT_UUIDS.includes(productUuid);
};

export const accountIsOnDemandOnly = (memberships, accountUuid): boolean => {
  if (!memberships?.length) return false;
  return memberships
    .filter(membership => membership.accountUuid === accountUuid)
    .every(membership => isOnDemand(membership.productUuid));
};

// Quote API requires that the start and end time are at least 30 minutes apart
export const getStartTimeAndEndTimeForQuotes = (bookingDate: Date, location: Location) => {
  const incrementedDate = new Date(bookingDate.getTime() + 30 * 60000);
  return {
    startTime: formatDateWithTimeZone(bookingDate, location),
    endTime: formatDateWithTimeZone(incrementedDate, location),
  };
};

// Rooms API provides an open and close time from /daily_desks
// We use those values here to build a start time and end time, and send that to Rooms in UTC format
export const getStartTimeAndEndTimeForRooms = (
  bookingDate: Date,
  location: Location,
  closeTime: string,
  openTime: string
) => {
  const closeTimeValues = closeTime.split(':');
  const openTimeValues = openTime.split(':');
  const startTime = bookingDate.setHours(
    parseInt(openTimeValues[0], 10),
    parseInt(openTimeValues[1], 10),
    0,
    0
  );

  const endTime = bookingDate.setHours(
    parseInt(closeTimeValues[0], 10),
    parseInt(closeTimeValues[1], 10),
    0,
    0
  );

  return {
    startTime: formatDateWithTimeZone(new Date(startTime), location),
    endTime: formatDateWithTimeZone(new Date(endTime), location),
  };
};

export const priceBreakdown = (price: string) => {
  const credits_regex = /(\d+) credits/g;
  const price_amount = credits_regex.exec(price)?.[1];
  return price_amount
    ? {
        price_amount: Number(price_amount),
        price_units: 'credits',
      }
    : {};
};

export const renderToolTipMessage = (typeOfError: string): string => {
  switch (typeOfError) {
    case ERRORS.DESK:
      return NO_DESK_TOOLTIP;
    case ERRORS.MEMBERSHIP:
      return NO_MEMBER_TOOLTIP;
    default:
      return '';
  }
};

export const renderCapacityTooltipMessage = (isAvailableSpace: boolean): string =>
  !isAvailableSpace ? ERRORS.NO_SPACES_AVAILABLE : '';
