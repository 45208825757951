import { useEffect, useState } from 'react';
import { getMountedApps } from 'single-spa';

import { ROUTING_EVENT, TO_DYNAMIC_PATH_REGEX } from './constants';
import { ROUTES } from './routes';

const hashToRoute = hash =>
  ROUTES.find(({ path }) => {
    const toDynamicPath = TO_DYNAMIC_PATH_REGEX.exec(path)?.[0] || path;
    return hash.includes(toDynamicPath);
  });

type AppDetails = {
  app: string | null;
  title: string | null;
};

const useAppDetails = (): AppDetails => {
  const [appDetails, setAppDetails] = useState<AppDetails>({
    app: null,
    title: null,
  });
  const appDetailsListener = () => {
    const currentApp = getMountedApps()[0];
    const route = hashToRoute(location?.hash);
    setAppDetails({
      app: currentApp || null,
      title: route?.label || null,
    });
  };
  useEffect(() => {
    addEventListener(ROUTING_EVENT, appDetailsListener);
    return () => removeEventListener(ROUTING_EVENT, appDetailsListener);
  }, []);
  return appDetails;
};

export default useAppDetails;
