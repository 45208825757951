import { IconName } from '@wework/ray2';

import {
  MemberNotificationDeliveryType,
  MemberNotificationIconType,
} from 'features/communication/memberNotifications/types';
import { MemberNotificationsSubset } from 'features/communication/memberNotifications/redux';
import FoodDeliveryIcon from 'images/search-action-food.svg';
import PackageDeliveryIcon from 'images/search-action-package.svg';

import { MemberNotificationAction, MemberNotificationEventLabel } from './types';

export const iconCodeToEventLabelMap: Record<
  MemberNotificationIconType,
  MemberNotificationEventLabel
> = {
  hamburger: MemberNotificationEventLabel.FOOD_DELIVERY,
  package: MemberNotificationEventLabel.PACKAGE_DELIVERY,
};

export const iconCodeToActionTypeMap: Record<
  MemberNotificationIconType,
  MemberNotificationDeliveryType
> = {
  hamburger: 'food',
  package: 'package',
};

export const iconCodeToIconMap: Record<MemberNotificationIconType, string> = {
  hamburger: FoodDeliveryIcon,
  package: PackageDeliveryIcon,
};

const iconCodeToRayIconMap: Record<MemberNotificationIconType, IconName> = {
  hamburger: IconName.UTENSILS,
  package: IconName.MAILBOX,
};

export function getMemberNotificationActions(
  state: MemberNotificationsSubset
): Array<MemberNotificationAction> {
  const templates = state.memberNotifications.templates.items;
  const actions = templates.reduce<Array<MemberNotificationAction>>((aggregator, template) => {
    const actionType = iconCodeToActionTypeMap[template.icon_code];
    const eventLabel = iconCodeToEventLabelMap[template.icon_code];
    const iconSource = iconCodeToIconMap[template.icon_code];
    const rayIconSource = iconCodeToRayIconMap[template.icon_code];

    if (actionType && eventLabel && iconSource) {
      aggregator.push({
        ...template,
        actionType,
        eventLabel,
        iconSource,
        rayIconSource,
      });
    }
    return aggregator;
  }, []);

  return actions;
}
