import { get, isUndefined, isNull } from 'lodash';

import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export const booleanSerializer = (
  inventoryPayloadItem: InventoryPayloadItem,
  serializerKey: string
): InventoryAttribute | null | undefined => {
  const attribute = get(inventoryPayloadItem, serializerKey);

  if (isUndefined(attribute) || isNull(attribute)) {
    return null;
  }

  const value = !attribute ? 'No' : 'Yes';

  return { value, meta: null };
};

export default booleanSerializer;
