import { Location } from 'store/modules/siLocations';

import { SIFetchInventoryResult } from '../types';

export const inventorySearchQuery: string = `query search($filters: InventorySearchFilters!, $pagination: PaginationInput) {
  searchInventory(filters: $filters, pagination: $pagination) {
    data {
      id
      name
      productType
      displayProductType
      filterableGate
      startDate
      availability
      capacity
      seatCount
      workUnits
      spatialWorkUnits
      rentableSquareFootage
      squareFootage
      usableSquareFootage
      internalRoomCount
      hasWindow
      openDate
      buildingClass
      buildingTier
      notes
      alias
      archivedDate
      doNotSell
      floorName
      price {
        amount
        currency
        hasDiscountFlag
        discountDetails {
          type
          value
        }
      }
      locationId {
        value
      }
      floorId
      isAvailable
      isOnHold
      isPaperworkPending
      hold {
        id
        isActive
        beginsAt
        endsAt
      }
      currentMoveIn
      currentMoveOut
      nextMoveIn
      tags {
        name
        description
      }
		}
		pageInfo {
			totalCount
			limit
			offset
		}
    meta {
      availableCount {
        locationId
        count
      }
    }
  }
}`;

export const findLocationQuery = `query GetLocation($id: ID!) {
  location(id: $id, type: SPACEMAN) {
    id
    name
    openDate
    isMigrated
    identifiers {
      type,
      value,
    },
    addresses {
      type,
      line1,
      line2,
      line3,
      dependentLocality,
      locality,
      administrativeArea,
      postalCode,
      latitude
      longitude
      countryISO3
    }
    buildings {
      floors {
        id
        status
      }
    }
  }
}`;

export type SILocationResponse = {
  data: {
    location: Location;
  };
  error;
};

export type SIInventoryResponse = {
  data: {
    searchInventory: SIFetchInventoryResult;
  };
  error;
};
