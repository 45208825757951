import { handleActions } from 'redux-actions';

import { Money, ServiceRetainer } from 'features/mimo/types';

import {
  FETCH_SERVICE_RETAINER,
  FETCH_SERVICE_RETAINER_FAIL,
  FETCH_SERVICE_RETAINER_SUCCESS,
} from './constants';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: boolean | null | undefined;
  data: Hash<ServiceRetainer>;
}

type AccountServiceRetainerResponse = Readonly<
  ServiceRetainer & {
    accountUuid: string;
    totalExpectedSr: Money;
  }
>;

type ServiceRetainerResponse = {
  data: {
    accountsServiceRetainer: {
      accounts: Array<AccountServiceRetainerResponse>;
    } | null;
  } | null;
  errors: undefined | Array<{ message: string }>;
};

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

const srAdapter = (response: ServiceRetainerResponse): Hash<ServiceRetainer> => {
  if (response.errors?.length) {
    const messages = response.errors.map(error => error.message);
    throw new Error(messages.join(' ; '));
  }

  const data = response.data?.accountsServiceRetainer?.accounts ?? [];

  return data.reduce((srData: Hash<ServiceRetainer>, sr: AccountServiceRetainerResponse) => {
    const { accountUuid, totalExpectedSr, ...accountSr } = sr;

    srData[accountUuid] = accountSr;

    return srData;
  }, {});
};

const reducer = handleActions<State, any>(
  {
    [FETCH_SERVICE_RETAINER]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_SERVICE_RETAINER_SUCCESS]: (
      state: State,
      { payload: response }: { payload: ServiceRetainerResponse }
    ) => ({
      ...state,
      loaded: true,
      loading: false,
      data: srAdapter(response),
    }),
    [FETCH_SERVICE_RETAINER_FAIL]: (state: State, { error }) => ({
      ...state,
      loading: false,
      loaded: false,
      data: {},
      error,
    }),
  },
  initialState
);

export default reducer;
