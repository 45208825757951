import {
  Location,
  Action,
  TransitionPromptHook,
  HashHistoryBuildOptions,
  History,
  createHashHistory,
} from 'history';

export type Unblock = () => void;

// A custom History based on HashHistory that allows multiple block hooks/prompts.
export default function createHistory(props?: HashHistoryBuildOptions): History {
  const history = createHashHistory(props);

  let listeners: Array<string | TransitionPromptHook> = [];

  const block = (prompt: string | TransitionPromptHook): Unblock => {
    listeners.unshift(prompt);

    return () => {
      listeners = listeners.filter(listener => listener !== prompt);
    };
  };

  history.block((location: Location, action: Action): string | false | void => {
    for (let i = 0, len = listeners.length; i < len; i += 1) {
      const listener = listeners[i];

      // If we have a text prompt, we definitely want to block.
      if (typeof listener === 'string') {
        return listener;
      }

      // If we have a function, only block if it returns false, otherwise just continue.
      if (typeof listener === 'function') {
        const result = listener(location, action);

        if (result === false || typeof result === 'string') {
          return result;
        }
      }
    }

    return undefined;
  });

  history.block = block;

  return history;
}
