import { omitBy, isNil, pick } from 'lodash';

import {
  AccountUser,
  AccountUserPrivateData,
  AccountUserPublicData,
} from 'store/modules/accounts/types';

const SAFE_PROPERTIES = [
  'uuid',
  'image',
  'memberships',
  'roles',
  'allows',
  'notes',
  'location_uuid',
  'date_of_birth',
  'locale_preference',
  'kind',
];

// TODO: Finish migrating from ID to Account Service for member data

export const mapAccountUserToAccountUserPrivateData = ({
  name,
  email,
  phone,
}: AccountUser): AccountUserPrivateData =>
  omitBy(
    {
      name,
      email,
      phone,
    },
    isNil
  ) as AccountUserPrivateData;

export const mapAccountUserToAccountUserPublicData = (
  accountUser: AccountUser
): AccountUserPublicData => pick(accountUser, SAFE_PROPERTIES) as AccountUserPublicData;
