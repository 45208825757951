import { createRequestReducer } from 'store/util/createRequestReducer';

import { FETCH_TASKS, FETCH_TASKS_FAIL, FETCH_TASKS_SUCCESS } from './constants';
import { Tasks } from './types';

const tasksReducer = createRequestReducer<Tasks | null, Tasks | undefined>(
  [FETCH_TASKS, FETCH_TASKS_SUCCESS, FETCH_TASKS_FAIL],
  [],
  res => res.payload || null
);

export default tasksReducer;
