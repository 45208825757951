import React, { Component } from 'react';
import { Modal } from '@wework/dieter-ui';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { match } from 'react-router-dom';

import { replaceVariablesInTemplate } from 'features/communication/memberNotifications/templates/memberNotificationVariables';
import { Member } from 'features/locations/buildingDirectory/types';
import {
  closeSendMemberNotificationModal,
  sendMemberNotification,
  updateMemberNotificationModal,
} from 'features/communication/memberNotifications/redux';
import { getMemberNotificationModalStatus } from 'features/communication/memberNotifications/redux/selectors';
import { GlobalState } from 'store/modules';
import { getCurrentLocationUuid } from 'store/selectors';
import { ReduxProps } from 'store/types';
import { trackAnalyticsFor } from 'lib/analytics/analytics';
import { AnalyticsEventName } from 'lib/analytics/constants';

import SendMemberNotificationForm from './sendMemberNotificationForm';

const mapStateToProps = (state: GlobalState, props: { match: match }) => {
  return {
    currentModal: getMemberNotificationModalStatus(state),
    currentLocationUuid: getCurrentLocationUuid(state, props)!,
  };
};

const mapDispatchToProps = {
  sendMemberNotification,
  closeSendMemberNotificationModal,
  updateMemberNotificationModal,
};

type Props = Readonly<ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>>;

class MemberNotificationModal extends Component<Props> {
  cancelMemberNotification = () => {
    this.props.closeSendMemberNotificationModal();
  };

  sendMemberNotification = () => {
    const {
      sendMemberNotification,
      currentModal: { member, template, source },
    } = this.props;

    // @ts-ignore
    sendMemberNotification(member).finally(() =>
      trackAnalyticsFor(AnalyticsEventName.comms_send_notification_success, {
        source,
        type: template?.title || '',
      })
    );
  };

  onSelectMember = (member: Member) => {
    this.props.updateMemberNotificationModal(member);
  };

  getFormTitle = (member: Member, templateName: string) => {
    const { showMemberSearchInput } = this.props.currentModal;
    return member && !showMemberSearchInput
      ? `Send ${member.name} a message`
      : `Send a ${templateName} notification`;
  };

  render() {
    const { template, member, showMemberSearchInput } = this.props.currentModal;

    // @ts-ignore
    const templateName = template.name.toLowerCase();

    // @ts-ignore
    const formTitle = this.getFormTitle(member, templateName);

    let tmpl = template;

    if (member) {
      // @ts-ignore
      tmpl = replaceVariablesInTemplate(template, member);
    }

    return (
      <Modal size="small" open onClose={this.cancelMemberNotification}>
        <Modal.Header>{formTitle}</Modal.Header>
        <SendMemberNotificationForm
          initialValues={{
            member,
            body: tmpl?.body ?? '',
            type: tmpl?.type ?? '',
          }}
          cancelMemberNotification={this.cancelMemberNotification}
          sendMemberNotification={this.sendMemberNotification}
          onSelectMember={this.onSelectMember}
          member={member}
          currentLocationUuid={this.props.currentLocationUuid}
          showMemberSearchInput={showMemberSearchInput}
        />
      </Modal>
    );
  }
}

export default compose<React.ComponentType<{}>>(connect(mapStateToProps, mapDispatchToProps))(
  MemberNotificationModal
);
