import { AddProductOptions } from 'features/paperwork/addProduct/constants';

export const GA_CONTRACT_TYPE = 'GA';
export const ALL_ACCESS = 'allAccess';
export const ALL_ACCESS_RESERVABLE = 'All Access';
export const PRIVATE_ACCESS_RESERVABLE = 'Private Access';
export const ALL_ACCESS_RESERVABLE_TYPE = 'Resource';
export const WEWORK_ALL_ACCESS = 'WeWork All Access Plus membership plan';
export const TRANSFER_ERROR_MESSAGE =
  "The 'Transfer' feature cannot be used to move All Access to a physical product within one agreement (and vice versa). Instead, you must separately send move out/drop paperwork to end one product and send a new agreement to start a new product.";

export const GLOBAL_ACCESS_LOCATION_INPUT_NAME = 'gaLocation';

export const GLOBAL_ACCESS_COUNTRY_FORM_NAME = 'globalAccessSelectCountry';
export const GLOBAL_ACCESS_COUNTRY_INPUT_NAME = 'gaCountry';
export const GLOBAL_ACCESS_COUNTRY_SELECTOR_LABEL = 'Country';
export const GLOBAL_ACCESS_COUNTRY_SELECTOR_DESCRIPTION =
  'Country determines billing currency and the membership agreement’s language.';
export const GLOBAL_ACCESS_QUANTITY_INPUT_NAME = 'quantity';
export const GLOBAL_ACCESS_START_DATE_INPUT_NAME = 'startDate';
export const GLOBAL_ACCESS_FORMS_INPUT_NAME = 'forms';

export const GLOBAL_ACCESS_PRODUCT_NAME_MAP = {
  [AddProductOptions.PRIVATE_ACCESS]: 'Workplace Subscription',
  [AddProductOptions.GLOBAL_ACCESS]: 'Global Access', // this should be refactored to All Access so we don;t need the next line
  [ALL_ACCESS]: 'All Access',
  [AddProductOptions.GLOBAL_ACCESS_FLEX]: 'Global Access Dynamic',
  [AddProductOptions.GLOBAL_ACCESS_PAY_PER_USE]: 'Global Access Pay Per Use',
  [AddProductOptions.GLOBAL_ACCESS_SAKSWORKS]: 'Global Access SaksWorks',
  [AddProductOptions.GLOBAL_ACCESS_IVANHOE]: 'Global Access Ivanhoé',
  [AddProductOptions.GLOBAL_ACCESS_BASIC]: 'Global Access Basic',
  [AddProductOptions.JAPAN_PASSPORT]: 'Japan Passport',
  [AddProductOptions.SS_BUSINESS_ADDRESS]: 'Business Address',
};

export const GLOBAL_ACCESS_MODAL_HEADER_TEXT_MAP = {
  [AddProductOptions.PRIVATE_ACCESS]: 'Add Workplace Subscription',
  [AddProductOptions.GLOBAL_ACCESS]: 'Add All Access',
  [AddProductOptions.GLOBAL_ACCESS_FLEX]: 'Add All Access Dynamic',
  [AddProductOptions.GLOBAL_ACCESS_PAY_PER_USE]: 'Add All Access Pay Per Use',
  [AddProductOptions.GLOBAL_ACCESS_SAKSWORKS]: 'Add SaksWorks Membership',
  [AddProductOptions.GLOBAL_ACCESS_IVANHOE]: 'Add Ivanhoé Cambridge Membership',
  [AddProductOptions.GLOBAL_ACCESS_BASIC]: 'Add All Access Basic',
  [AddProductOptions.JAPAN_PASSPORT]: 'Add Japan Passport',
  [AddProductOptions.SS_BUSINESS_ADDRESS]: 'Add Business Address',
};

export const GLOBAL_ACCESS_ENTITY_SELECTOR_MODAL_HEADER = {
  [AddProductOptions.PRIVATE_ACCESS]: 'Select Billing Location',
  [AddProductOptions.GLOBAL_ACCESS]: 'Select Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_FLEX]: 'Select Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_BASIC]: 'Select Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_PAY_PER_USE]: 'Select Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_SAKSWORKS]: 'Select Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_IVANHOE]: 'Select Billing Location',
  [AddProductOptions.JAPAN_PASSPORT]: 'Choose a Location',
  [AddProductOptions.SS_BUSINESS_ADDRESS]: 'Select Billing Location',
};

export const GLOBAL_ACCESS_ENTITY_SELECTOR_LABEL = {
  [AddProductOptions.PRIVATE_ACCESS]: 'Billing Location',
  [AddProductOptions.GLOBAL_ACCESS]: 'Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_FLEX]: 'Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_PAY_PER_USE]: 'Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_SAKSWORKS]: 'Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_IVANHOE]: 'Billing Location',
  [AddProductOptions.GLOBAL_ACCESS_BASIC]: 'Billing Location',
  [AddProductOptions.SS_BUSINESS_ADDRESS]: 'Billing Location',
};

// Copy is used below in more than one entry, so lifting it to const
const SELECTOR_DESCRIPTION_COPY =
  'Billing location determines billing currency and the membership agreement’s language.';

export const GLOBAL_ACCESS_ENTITY_SELECTOR_DESCRIPTION = {
  [AddProductOptions.PRIVATE_ACCESS]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.GLOBAL_ACCESS]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.GLOBAL_ACCESS_FLEX]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.GLOBAL_ACCESS_PAY_PER_USE]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.GLOBAL_ACCESS_SAKSWORKS]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.GLOBAL_ACCESS_IVANHOE]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.GLOBAL_ACCESS_BASIC]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.SS_BUSINESS_ADDRESS]: SELECTOR_DESCRIPTION_COPY,
  [AddProductOptions.JAPAN_PASSPORT]:
    'The location determines the home location of the membership, where the member will receive free 24/7 access.',
};
