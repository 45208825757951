import config from 'config';
import ApiRequest from 'lib/api/apiRequest';
import { TeamMember } from 'lib/api/locations/types';
import { ApiError } from 'store/errors';

export default class LocationsAPI extends ApiRequest {
  constructor() {
    super(config.locationsAPI.uri);
  }

  getTeamMembers = async (locationUuid: string): Promise<TeamMember[]> => {
    const request = {
      config: {
        getEntry: true,
        withRetry: true,
      },
      path: `api/v3/buildings/${locationUuid}/team_members`,
    };

    try {
      return await this.get(request);
    } catch (error) {
      if (error.status === 404) return [];
      else
        throw new ApiError(
          error.status,
          error.statusText,
          error,
          `Failed to fetch team members ${error.message}`
        );
    }
  };
}
