import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_ALL_FEATURE_FLAGS,
  FETCH_ALL_FEATURE_FLAGS_SUCCESS,
  FETCH_ALL_FEATURE_FLAGS_FAIL,
] = createRequestConstantNames(cc('FETCH_ALL_FEATURE_FLAGS'));

export const [
  UPDATE_FEATURE_FLAG,
  UPDATE_FEATURE_FLAG_SUCCESS,
  UPDATE_FEATURE_FLAG_FAIL,
] = createRequestConstantNames(cc('UPDATE_FEATURE_FLAG'));

export const [
  FETCH_ACCOUNT_FEATURE_FLAGS,
  FETCH_ACCOUNT_FEATURE_FLAGS_SUCCESS,
  FETCH_ACCOUNT_FEATURE_FLAGS_FAIL,
] = createRequestConstantNames(cc('FETCH_ACCOUNT_FEATURE_FLAGS'));

export const FEATURE_FLAGS_PATH = 'v1/feature_flags';

export const FEATURE_FLAGS_ACCOUNT_PATH = 'features';
