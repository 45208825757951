import { handleActions, ReducerMap, Action } from 'redux-actions';

import { MimoEmail, MimoManualMessage, MimoEmailUser } from 'features/mimo/types';
import { UserInfo } from 'features/auth/reducer';

import {
  FETCH_EMAILS_BY_MOVE_IDS,
  FETCH_EMAILS_BY_MOVE_IDS_SUCCESS,
  FETCH_EMAILS_BY_MOVE_IDS_FAIL,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  FLAG_MOVE_MANUAL_MESSAGE,
} from './constants';

export interface State {
  byMoveUuid: Hash<MimoEmail>;
  loading: boolean;
  loaded: boolean;
  error: boolean;
  sending: boolean;
  sent: boolean;
  sendError: boolean;
}

type MarkPayload = {
  moveIds: Array<string>;
  unmark: boolean;
  userInfo: UserInfo;
};

const initialState: State = {
  byMoveUuid: {},
  loading: false,
  loaded: false,
  error: false,
  sending: false,
  sent: false,
  sendError: false,
};

const reducerDefinition: ReducerMap<State, Hash<MimoEmail> | MarkPayload> = {
  [FETCH_EMAILS_BY_MOVE_IDS]: state => ({
    ...state,
    loading: true,
    loaded: false,
    error: false,
  }),
  [FETCH_EMAILS_BY_MOVE_IDS_SUCCESS]: (state, { payload }: Action<Hash<MimoEmail>>) => ({
    ...state,
    loading: false,
    loaded: true,
    byMoveUuid: {
      ...state.byMoveUuid,
      ...payload,
    },
  }),
  [FETCH_EMAILS_BY_MOVE_IDS_FAIL]: state => ({
    ...state,
    loading: false,
    loaded: false,
    error: true,
  }),

  [SEND_EMAIL]: (state: State): State => ({
    ...state,
    sending: true,
    sent: false,
    sendError: false,
  }),

  [SEND_EMAIL_SUCCESS]: state => ({
    ...state,
    sending: false,
    sent: true,
  }),

  [SEND_EMAIL_FAIL]: state => ({
    ...state,
    sending: false,
    sent: false,
    sendError: true,
  }),

  [FLAG_MOVE_MANUAL_MESSAGE]: (
    state,
    { payload: { moveIds, unmark, userInfo } }: Action<MarkPayload>
  ) => {
    const createdAt: number = Date.now();
    let movesEmailData: Hash<MimoEmail> = { ...state.byMoveUuid };

    const user: MimoEmailUser = {
      // @ts-ignore
      name: userInfo.fullName,
      // @ts-ignore
      uuid: userInfo.uuid,
    };

    const manualMessage: MimoManualMessage | undefined = unmark
      ? undefined
      : {
          createdAt,
          user,
        };

    moveIds.forEach((moveId): void => {
      const prevMoveData = movesEmailData[moveId];
      if (prevMoveData) {
        if (!prevMoveData.message && unmark) {
          // TODO: Does this mean the type is Hash<MimoEmail | null>?
          // @ts-ignore
          movesEmailData[moveId] = null;
          return;
        }
        movesEmailData[moveId] = {
          ...prevMoveData,
          manualMessage,
        };
      } else if (!unmark) {
        movesEmailData = {
          ...movesEmailData,
          [moveId]: { manualMessage },
        };
      }
    });

    return {
      ...state,
      byMoveUuid: movesEmailData,
    };
  },
};

const reducer = handleActions(reducerDefinition, initialState);

export default reducer;
