import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_PRIMARY_RESERVATIONS,
  FETCH_PRIMARY_RESERVATIONS_SUCCESS,
  FETCH_PRIMARY_RESERVATIONS_FAIL,
] = createRequestConstantNames(cc('FETCH_PRIMARY_RESERVATIONS'));

export const [
  CANCEL_MODIFICATION_REQUEST,
  CANCEL_MODIFICATION_REQUEST_SUCCESS,
  CANCEL_MODIFICATION_REQUEST_FAIL,
] = createRequestConstantNames(cc('CANCEL_MODIFICATION_REQUEST'));

export const [
  FETCH_KUBE_RESERVATIONS,
  FETCH_KUBE_RESERVATIONS_SUCCESS,
  FETCH_KUBE_RESERVATIONS_FAIL,
] = createRequestConstantNames(cc('FETCH_KUBE_RESERVATIONS'));
