import { handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';
import {
  CANCEL_OD_ROOM_RESERVATION,
  CANCEL_OD_ROOM_RESERVATION_SUCCESS,
  CANCEL_OD_ROOM_RESERVATION_FAIL,
} from 'features/rooms/components/modals/cancelReservationModal/redux/constants';
import {
  REFUND_ROOM_RESERVATION,
  REFUND_ROOM_RESERVATION_SUCCESS,
  REFUND_ROOM_RESERVATION_FAIL,
} from 'features/rooms/components/modals/refundReservationModal/redux/constants';
import { RoomBookingReservation } from 'features/rooms/components/modals/types';
import { CANCEL_BOOKING_MODAL_FORM } from 'features/rooms/components/modals/cancelReservationModal/constants';
import {
  REFUND_BOOKING_MODAL_FORM,
  PAST_MONTH_REFUND_MODAL_NAME,
} from 'features/rooms/components/modals/refundReservationModal/constants';

import {
  State,
  RefundRoomSuccessActionWithMetaPayload,
  CancelOnDemandSuccessPayload,
} from './types';
import {
  CLOSE_ROOM_RESERVATION_MODAL,
  OPEN_CANCEL_ROOM_RESERVATION_MODAL,
  OPEN_REFUND_ROOM_RESERVATION_MODAL,
  OPEN_PAST_MONTH_REFUND_MODAL,
} from './constants';

const initialState: State = {
  currentModal: '',
  currentModalBookable: null,
  currentModalRefundCancel: null,
  cancelRefundStatus: {
    success: false,
    loading: false,
    error: false,
    cancelRefundReason: '',
  },
};

export const reducer = handleActions<State, any, any>(
  {
    [CLOSE_ROOM_RESERVATION_MODAL]: (state: State): State => ({
      ...state,
      currentModal: '',
      currentModalBookable: null,
      currentModalRefundCancel: null,
      cancelRefundStatus: {
        success: false,
        loading: false,
        error: false,
        cancelRefundReason: '',
      },
    }),
    [OPEN_CANCEL_ROOM_RESERVATION_MODAL]: (
      state: State,
      action: ActionWithPayload<RoomBookingReservation | null | undefined>
    ): State => ({
      ...state,
      currentModal: CANCEL_BOOKING_MODAL_FORM.NAME,
      currentModalRefundCancel: action.payload ?? null,
    }),
    [OPEN_REFUND_ROOM_RESERVATION_MODAL]: (
      state: State,
      action: ActionWithPayload<RoomBookingReservation | null | undefined>
    ): State => ({
      ...state,
      currentModal: REFUND_BOOKING_MODAL_FORM.NAME,
      currentModalRefundCancel: action.payload ?? null,
    }),
    [OPEN_PAST_MONTH_REFUND_MODAL]: (state: State): State => ({
      ...state,
      currentModal: PAST_MONTH_REFUND_MODAL_NAME,
    }),
    [REFUND_ROOM_RESERVATION]: (state: State): State => ({
      ...state,
      cancelRefundStatus: {
        success: false,
        loading: true,
        error: false,
        cancelRefundReason: '',
      },
    }),
    [REFUND_ROOM_RESERVATION_SUCCESS]: (
      state: State,
      action: RefundRoomSuccessActionWithMetaPayload
    ): State => ({
      ...state,
      cancelRefundStatus: {
        success: true,
        loading: false,
        error: false,
        cancelRefundReason: action.meta.refundReason,
      },
    }),
    [REFUND_ROOM_RESERVATION_FAIL]: (state: State, action: { payload: boolean }): State => ({
      ...state,
      cancelRefundStatus: {
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
    [CANCEL_OD_ROOM_RESERVATION]: (state: State): State => ({
      ...state,
      cancelRefundStatus: {
        success: false,
        loading: true,
        error: false,
        cancelRefundReason: '',
      },
    }),
    [CANCEL_OD_ROOM_RESERVATION_SUCCESS]: (
      state: State,
      action: CancelOnDemandSuccessPayload
    ): State => {
      return action.payload[0].status !== 'FAILED'
        ? {
            ...state,
            cancelRefundStatus: {
              success: true,
              loading: false,
              error: false,
              cancelRefundReason: '',
            },
          }
        : {
            ...state,
            currentModal: '',
            currentModalBookable: null,
            currentModalRefundCancel: null,
            cancelRefundStatus: {
              loading: false,
              success: false,
              error: true,
            },
          };
    },
    [CANCEL_OD_ROOM_RESERVATION_FAIL]: (state: State, action: { payload: boolean }): State => ({
      ...state,
      cancelRefundStatus: {
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
  initialState
);

export default reducer;
