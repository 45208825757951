import moment, { Moment } from 'moment-timezone';
import { isEmpty, toPairs, memoize } from 'lodash';
import { isAfter, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  BookingReservationTypes,
  TIME_FORMAT,
  TIME_INTERVAL_MINUTES,
  MIN_START_TIME,
  MAX_END_TIME,
  HOUR_TIME_FORMAT,
  EXTENDED_BOOKING_MINUTES,
  CELL_SIZE,
} from 'features/rooms/constants';
import { BookingTimeSlot, HourValue, HourValues } from 'features/rooms/redux/types';
import {
  BookingReservationType,
  RoomReservation,
  CalculateSlotsProps,
  HoursToLengthMap,
  TimeIndicator,
} from 'features/rooms/types';
import config from 'config';
import { dateFormats } from 'lib/constants';

const emptyRoomBookingSlot: BookingTimeSlot = {
  size: 1,
  isNextAvailableSlot: false,
  type: BookingReservationTypes.OPEN,
  reservationsList: [],
};

export const moment24h = (value?: string): Moment => moment(value, dateFormats.time24);

export const getInitialAndFinalTime = (
  time: string,
  timeZone: string
): {
  initialTime: Moment;
  finalTime: Moment;
} => {
  const finalTime = moment
    .tz(time, timeZone)
    .startOf('day')
    .add(parseInt(MAX_END_TIME, 10), 'hours')
    .subtract(TIME_INTERVAL_MINUTES, 'minutes');

  const initialTime = moment
    .tz(time, timeZone)
    .startOf('day')
    .add(parseInt(MIN_START_TIME, 10), 'hours');

  return {
    initialTime,
    finalTime,
  };
};

export const getCurrentTimeZoneAndMoment = (
  timeZone: string | null | undefined,
  currentTime?: Moment | null | undefined
): {
  currentTimezone: string;
  currentMoment: Moment;
} => {
  const currentTimezone = timeZone || moment.tz.guess();
  const currentMoment = currentTime || moment.tz(moment.utc().valueOf(), currentTimezone);
  return {
    currentTimezone,
    currentMoment,
  };
};

const getEmptyBookingSlotType = (
  hour: string,
  timeZone: string,
  selectedTimeFrame: string
): BookingReservationType => {
  const [timeHour, timeMinute] = hour.split(':').map(val => Number(val));
  const selectedTimeFrameValue = moment.tz(selectedTimeFrame, timeZone);
  const now = moment.tz(moment.utc().valueOf(), timeZone);

  let bookingReservationType = BookingReservationTypes.PAST_OPEN;
  if (now.isSame(selectedTimeFrameValue, 'day')) {
    const extendedRequestHour = moment
      .tz(selectedTimeFrame, timeZone)
      .hour(timeHour)
      .minute(timeMinute + EXTENDED_BOOKING_MINUTES)
      .second(0)
      .millisecond(0);
    if (now.isBefore(extendedRequestHour)) {
      bookingReservationType = BookingReservationTypes.OPEN;
    }
  } else if (now.isBefore(selectedTimeFrameValue)) {
    bookingReservationType = BookingReservationTypes.OPEN;
  }
  return bookingReservationType;
};

const getReservationType = (
  reservation: RoomReservation,
  hour: string,
  zone: string,
  selectedTimeFrame: string
): BookingReservationType => {
  if (reservation) {
    const { zone, start, finish } = reservation;
    const selectedTimeFrameValue = moment.tz(selectedTimeFrame, zone);
    const now = moment.tz(moment().valueOf(), zone);
    const reservationFinished = moment.tz(finish, zone);
    const reservationStarted = moment.tz(start, zone);
    let bookingReservationType = BookingReservationTypes.PAST;
    // this for checking use cases when the selected date is today
    if (now.isSame(selectedTimeFrameValue, 'day')) {
      if (now.isSameOrBefore(reservationStarted)) {
        bookingReservationType = BookingReservationTypes.UPCOMING;
      } else if (now.isBetween(reservationStarted, reservationFinished)) {
        bookingReservationType = BookingReservationTypes.ONGOING;
      } else {
        bookingReservationType = BookingReservationTypes.PAST;
      }
      // checking other use cases if it's a future date or past one.
    } else if (now.isBefore(reservationStarted)) {
      bookingReservationType = BookingReservationTypes.UPCOMING;
    }
    return bookingReservationType;
  }
  return getEmptyBookingSlotType(hour, zone, selectedTimeFrame);
};

export const calcTimeLineBookingSlot = (
  hour: string,
  hourValue: HourValue,
  timeZone: string,
  selectedTimeFrame: string
): BookingTimeSlot => {
  const type = getEmptyBookingSlotType(hour, timeZone, selectedTimeFrame);

  const { nextAvailableSlot: isNextAvailableSlot } = hourValue;

  const { size } = emptyRoomBookingSlot;

  return {
    hour,
    type,
    size,
    isNextAvailableSlot,
    reservationsList: [],
  };
};

export const calcReservationsBookingSlot = (
  hour: string,
  hourValue: HourValue,
  timeZone: string,
  selectedTimeFrame: string
): BookingTimeSlot => {
  const { counter = 1, reservations, doesMatchesSearchQuery } = hourValue;
  if (!isEmpty(reservations)) {
    // some data, ex. start & end time will be similar for all reservations in the same time slot
    const firstReservation = reservations[0];
    const type = getReservationType(firstReservation, hour, timeZone, selectedTimeFrame);

    return {
      type,
      doesMatchesSearchQuery,
      reservationsList: reservations.map(reservation => ({
        reservationId: reservation.uuid,
        notes: reservation.notes,
        credits: reservation.credits,
        companyId: reservation.company?.uuid || null,
        isWework: reservation.company?.uuid === config.wework_company_uuid,
        title: reservation.company?.name || '',
        userId: reservation.user?.uuid || '',
        userName: reservation.user?.name || '',
        isBookedOnBehalf: reservation.bookOnBehalf,
        bookedOnBehalfUserUuid: reservation.bookedByUser?.uuid || '',
        bookedOnBehalfUserName: reservation.bookedByUser?.name || '',
        refunded: reservation.refunded,
        isOnDemand: reservation.isOnDemand,
        sourceSystem: reservation.sourceSystem,
      })),
      roomUuid: firstReservation.reservable?.uuid,
      size: counter,
      start: firstReservation.start,
      finish: firstReservation.finish,
      isNextAvailableSlot: false,
    };
  }

  return calcTimeLineBookingSlot(hour, hourValue, timeZone, selectedTimeFrame);
};

export const createMomentFromString = (time: string, format: string = TIME_FORMAT): Moment =>
  moment(time, format);

const sortHours = ([hourA]: [string, {}], [hourB]: [string, {}]): number => {
  const hourATime = createMomentFromString(hourA);
  const hourBTime = createMomentFromString(hourB);
  return hourATime.diff(hourBTime);
};

export const calculateSlotsProps = (
  reservations: Hash<HourValue>,
  timeline: HourValues,
  currentTimeZone: string,
  selectedTimeFrame: string
): Array<CalculateSlotsProps> => {
  const { data, calculateSlotProps } = isEmpty(reservations)
    ? { data: timeline, calculateSlotProps: calcTimeLineBookingSlot }
    : { data: reservations, calculateSlotProps: calcReservationsBookingSlot };

  return toPairs(data)
    .sort(sortHours)
    .map(([hourValue, reservationValue]) => ({
      key: hourValue,
      ...calculateSlotProps(hourValue, reservationValue, currentTimeZone, selectedTimeFrame),
    }));
};

export const getHoursToLengthMap = (timeZone?: string): HoursToLengthMap => {
  const currentTimezone = timeZone || moment.tz.guess();
  const finalTime = moment.tz(MAX_END_TIME, HOUR_TIME_FORMAT, currentTimezone);
  const timeValue = moment.tz(MIN_START_TIME, HOUR_TIME_FORMAT, currentTimezone);
  const options = {};
  let counter = 0;
  while (timeValue.isSameOrBefore(finalTime)) {
    const formatTime = timeValue.format(TIME_FORMAT);
    options[formatTime] = CELL_SIZE * counter++;
    timeValue.add(TIME_INTERVAL_MINUTES, 'minutes');
  }
  return options;
};

export const shouldDisplayTimeIndicator = (
  currentTimeFrame: Moment,
  currentTimeZone: string
): boolean => {
  const now = moment.tz(moment().valueOf(), currentTimeZone);
  return now.isSame(currentTimeFrame, 'day');
};

// Returns a roundup to the nearest interval: 9:14 -> 9:00, 9:15 -> 9:30, 9:31 -> 9:30, 9:45 -> 10:00
export const roundToNearestTimeInterval = (time: Moment): Moment => {
  const timeClone = time.clone();
  const minutes = timeClone.get('minutes');
  const remainder = minutes % TIME_INTERVAL_MINUTES;
  let quotient = Math.floor(minutes / TIME_INTERVAL_MINUTES);
  if (remainder >= EXTENDED_BOOKING_MINUTES) {
    quotient += 1;
  }
  return timeClone
    .startOf('hour')
    .add(quotient * TIME_INTERVAL_MINUTES, 'minutes')
    .startOf('minute');
};

export const getRoundedTime = (
  timeZone: string,
  selectedTime: Moment | null | undefined,
  initialTime: Moment,
  finalTime: Moment
): Moment => {
  const { currentMoment } = getCurrentTimeZoneAndMoment(timeZone, selectedTime);
  let timeIndicator = roundToNearestTimeInterval(currentMoment);
  if (timeIndicator.isBefore(initialTime)) {
    timeIndicator = initialTime;
  } else if (timeIndicator.isAfter(finalTime)) {
    timeIndicator = finalTime;
  }
  return timeIndicator;
};

export const getTimeIndicator = (timeZone: string): TimeIndicator => {
  const { currentTimezone, currentMoment } = getCurrentTimeZoneAndMoment(timeZone);
  const { initialTime, finalTime } = getInitialAndFinalTime(
    currentMoment.format(),
    currentTimezone
  );
  finalTime.add(TIME_INTERVAL_MINUTES, 'minutes');

  const time = getRoundedTime(timeZone, null, initialTime, finalTime);
  const position = parseFloat(
    (Math.abs(initialTime.diff(time, 'minute', true)) / TIME_INTERVAL_MINUTES).toFixed(1)
  );

  return {
    time,
    position,
  };
};

export const isReservationFromPastMonth = (startTime: string): boolean => {
  return moment(startTime).isBefore(moment.utc(), 'month');
};

export const getDatetimeByHour = (date: Moment, time: string): Moment => {
  const [timeHour, timeMinute] = time.split(':').map(val => Number(val));
  return date.clone().hour(timeHour).minute(timeMinute).second(0).millisecond(0);
};

export const hoursToLengthMap = memoize(getHoursToLengthMap);

export const getMembershipType = (
  isOnDemand?: boolean | Boolean
): 'On Demand' | 'Physical Space' => {
  return isOnDemand ? 'On Demand' : 'Physical Space';
};

export const isSelectedTimeFrameAvailable = (
  timeStart: Date | undefined | null,
  timeEnd: Date | undefined | null,
  reservations: Hash<HourValue>,
  timeZone: string,
  reservationId?: string
): Boolean => {
  if (!timeStart || !timeEnd || !reservations || !timeZone || timeStart >= timeEnd) return true;

  const reservedHours = Object.entries(reservations)
    .filter(([_, reservation]) => reservation?.reservations?.at(0)?.uuid !== reservationId)
    .map(([_, reservation]) => reservation?.reservations?.at(0));

  const isTimeFrameAvailable = reservedHours
    .map(reservation => ({
      start: reservation?.start,
      finish: reservation?.finish,
    }))
    .reduce((result, currentReservationTime) => {
      if (!currentReservationTime.start || !currentReservationTime.finish) {
        return result;
      }

      const reservedTimeStart = Number(new Date(currentReservationTime.start)) + 1;
      const reservedTimeEnd = Number(new Date(currentReservationTime.finish)) - 1;

      const isNotIntersecting =
        isBefore(timeEnd, utcToZonedTime(reservedTimeStart, timeZone)) ||
        isAfter(timeStart, utcToZonedTime(reservedTimeEnd, timeZone));

      return result && isNotIntersecting;
    }, true);

  return isTimeFrameAvailable;
};
