import { SuggestionPayload, toSuggestion } from 'features/paperwork/contracts-flow/types';
import { PPW_ERROR } from 'features/paperwork/contracts-flow/types/errors';

import {
  FETCH_DISCOUNT_SUGGESTIONS,
  FETCH_DISCOUNT_SUGGESTIONS_SUCCESS,
  FETCH_DISCOUNT_SUGGESTIONS_FAIL,
} from './consts';

import { State } from '.';

const reducer = {
  [FETCH_DISCOUNT_SUGGESTIONS]: (state: State) => ({
    ...state,
    discountSuggestions: {
      ...state.discountSuggestions,
      loading: true,
    },
  }),
  [FETCH_DISCOUNT_SUGGESTIONS_SUCCESS]: (
    state: State,
    action: {
      payload: {
        suggestions: Array<{
          result: { contracts: Hash<{ promotion_suggestions: Array<SuggestionPayload> }> };
        }>;
      };
    }
  ) => ({
    ...state,
    discountSuggestions: {
      ...state.discountSuggestions,
      loading: false,
      suggestions: {
        ...state.discountSuggestions.suggestions,
        ...action.payload.suggestions.reduce(
          (contractsSuggestions, { result: { contracts } }) => ({
            ...contractsSuggestions,
            ...Object.keys(contracts).reduce((acc, contractKey) => {
              acc[contractKey] = {
                ...state.discountSuggestions.suggestions[contractKey],
                ...contractsSuggestions[contractKey],
                ...contracts[contractKey].promotion_suggestions.reduce((byLength, suggestion) => {
                  if (byLength[suggestion.commitment_length]) {
                    byLength[suggestion.commitment_length].push(toSuggestion(suggestion));
                  } else {
                    byLength[suggestion.commitment_length] = [toSuggestion(suggestion)];
                  }
                  return byLength;
                }, {}),
              };

              return acc;
            }, {}),
          }),
          {}
        ),
      },
    },
  }),
  [FETCH_DISCOUNT_SUGGESTIONS_FAIL]: (state: State, action: { payload: { message: string } }) => ({
    ...state,
    loading: false,
    error: {
      message: action?.payload?.message,
      code: PPW_ERROR.SUGGESTIONS,
    },
  }),
};

export default reducer;
