import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  GET_MEMBER_INVITES,
  GET_MEMBER_INVITES_SUCCESS,
  GET_MEMBER_INVITES_FAIL,
] = createRequestConstantNames(cc('GET_MEMBER_INVITES'));

export const [
  ACCEPT_MEMBER_INVITE,
  ACCEPT_MEMBER_INVITE_SUCCESS,
  ACCEPT_MEMBER_INVITE_FAIL,
] = createRequestConstantNames(cc('ACCEPT_MEMBER_INVITE'));

export const [
  CANCEL_MEMBER_INVITE,
  CANCEL_MEMBER_INVITE_SUCCESS,
  CANCEL_MEMBER_INVITE_FAIL,
] = createRequestConstantNames(cc('CANCEL_MEMBER_INVITE'));

export const [
  RESEND_MEMBER_INVITE,
  RESEND_MEMBER_INVITE_SUCCESS,
  RESEND_MEMBER_INVITE_FAIL,
] = createRequestConstantNames(cc('RESEND_MEMBER_INVITE'));
