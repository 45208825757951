import config from 'config';
import ApiRequest from 'lib/api/apiRequest';
import { RequestMethodsParams } from 'lib/api/type';
import { MedalliaSurveyResponse } from 'features/check-ins/viewCheckIn/components/medalliaInsights';
import { TaskPriority } from 'features/check-ins/types';
import { MedalliaRelationshipSurveyResponse } from 'features/check-ins/viewCheckIn/components/medalliaSidePanel';

export default class CommunityMetricsAPI extends ApiRequest {
  constructor() {
    super(config.communityMetrics.uri);
  }

  async getBuildingStats(buildingUuid: string) {
    const requestParams: RequestMethodsParams = {
      path: `city-membership-stats/${buildingUuid}`,
      config: {
        getEntry: true,
      },
    };
    return (await this.get(requestParams)).filter(item => item.locationUuid === buildingUuid);
  }

  async getTaskPriorities(accountUuids: string[], locationUuid: string): Promise<TaskPriority[]> {
    const requestParams: RequestMethodsParams = {
      path: 'snowflake-rest-api/task-priority',
      data: {
        accountUuids,
        locationUuid,
      },
      config: {
        useArrayFormatBrackets: false,
      },
    };

    return this.post(requestParams);
  }

  async getMedalliaInsights(
    accountUuid: string,
    locationUuid: string,
    signal: AbortSignal
  ): Promise<MedalliaSurveyResponse[]> {
    const requestParams: RequestMethodsParams = {
      path: 'snowflake-rest-api/medallia-insights-survey-by-location',
      data: {
        accountUuid,
        locationUuid,
      },
      config: {
        signal,
        useArrayFormatBrackets: false,
      },
    };

    return this.post(requestParams);
  }

  async getMedalliaRelationshipSurvey(
    id: string,
    signal: AbortSignal
  ): Promise<MedalliaRelationshipSurveyResponse> {
    const requestParams: RequestMethodsParams = {
      path: 'snowflake-rest-api/medallia-insights-survey',
      data: {
        id,
      },
      config: {
        signal,
        useArrayFormatBrackets: false,
      },
    };

    return this.post(requestParams);
  }
}
