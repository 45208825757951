import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_INVALIDATIONS,
  FETCH_INVALIDATIONS_SUCCESS,
  FETCH_INVALIDATIONS_FAIL,
] = createRequestConstantNames(cc('FETCH_INVALIDATIONS'));

export const [
  CREATE_INVALIDATION,
  CREATE_INVALIDATION_SUCCESS,
  CREATE_INVALIDATION_FAIL,
] = createRequestConstantNames(cc('CREATE_INVALIDATION'));

export const [
  DELETE_INVALIDATION,
  DELETE_INVALIDATION_SUCCESS,
  DELETE_INVALIDATION_FAIL,
] = createRequestConstantNames(cc('DELETE_INVALIDATION'));

export const INVALIDATIONS_PATH = 'invalidations';
