import { createAction, handleActions } from 'redux-actions';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { groupBy } from 'lodash';

import config from 'config';
import { getUserUuids } from 'features/companies/redux/seats/selectors';
import { createRequestAction as createRequestActionAdapter } from 'store/util';
import { BaseAction, Dispatch, GetGlobalState } from 'store/types';
import cc from 'store/util/createReduxConstant';
import { dateFormatsDeprecated } from 'lib/constants';
import { ToastType } from 'store/modules/toasts/constants';

import {
  FetchCompanyMembershipAction,
  FetchUserMembershipAction,
  PromiseUserMembershipFetchResult,
  State,
  UserMembershipFetchResult,
  UserMembershipPayload,
} from './types';

// Action Constants
export const ASSIGN_USER = cc('ASSIGN_USER');
export const ASSIGN_USER_SUCCESS = cc('ASSIGN_USER_SUCCESS');
export const ASSIGN_USER_FAIL = cc('ASSIGN_USER_FAIL');

export const FETCH_ACCOUNT_MEMBERSHIPS = cc('FETCH_ACCOUNT_MEMBERSHIPS');
export const FETCH_ACCOUNT_MEMBERSHIPS_SUCCESS = cc('FETCH_ACCOUNT_MEMBERSHIPS_SUCCESS');
export const FETCH_ACCOUNT_MEMBERSHIPS_FAIL = cc('FETCH_ACCOUNT_MEMBERSHIPS_FAIL');

export const RECONCILE_ACCOUNT = cc('RECONCILE_ACCOUNT');
export const RECONCILE_ACCOUNT_SUCCESS = cc('RECONCILE_ACCOUNT_SUCCESS');
export const RECONCILE_ACCOUNT_FAIL = cc('RECONCILE_ACCOUNT_FAIL');

export const FETCH_USER_MEMBERSHIPS_INTERNAL_NAME = cc('FETCH_USER_MEMBERSHIPS_INTERNAL_NAME');
export const FETCH_USER_MEMBERSHIPS_INTERNAL_NAME_SUCCESS = cc(
  'FETCH_USER_MEMBERSHIPS_INTERNAL_NAME_SUCCESS'
);
export const FETCH_USER_MEMBERSHIPS_INTERNAL_NAME_FAIL = cc(
  'FETCH_USER_MEMBERSHIPS_INTERNAL_NAME_FAIL'
);
export const FETCH_USER_MEMBERSHIPS = cc('FETCH_USER_MEMBERSHIPS');
export const FETCH_USER_MEMBERSHIPS_SUCCESS = cc('FETCH_USER_MEMBERSHIPS_SUCCESS');
export const FETCH_USER_MEMBERSHIPS_FAIL = cc('FETCH_USER_MEMBERSHIPS_FAIL');

export const initialState: State = {
  loading: false,
  loaded: false,
  data: [],
  error: null,
  byUserUuid: {},
  byCompanyUuid: {},
};

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [ASSIGN_USER]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [ASSIGN_USER_SUCCESS]: (state: State) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
    [ASSIGN_USER_FAIL]: (state: State, action: BaseAction) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
    [FETCH_ACCOUNT_MEMBERSHIPS]: (state: State, action: FetchCompanyMembershipAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: true,
          loaded: false,
          error: null,
          data: [],
        },
      },
    }),
    [FETCH_ACCOUNT_MEMBERSHIPS_SUCCESS]: (state: State, action: FetchCompanyMembershipAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: false,
          loaded: true,
          error: null,
          data: action.payload.result,
        },
      },
    }),
    [FETCH_ACCOUNT_MEMBERSHIPS_FAIL]: (state: State, action: FetchCompanyMembershipAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: false,
          loaded: false,
          error: action.error,
          data: [],
        },
      },
    }),
    [FETCH_USER_MEMBERSHIPS]: (state: State, action: FetchUserMembershipAction) => {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
        byUserUuid: action.meta.userUuids.reduce((userMemberships, userUuid) => {
          return {
            ...userMemberships,
            [userUuid]: {
              data: [],
              loading: true,
              loaded: false,
              error: null,
            },
          };
        }, {}),
      };
    },
    [FETCH_USER_MEMBERSHIPS_SUCCESS]: (state: State, action: FetchUserMembershipAction) => {
      const payload = groupBy(action.payload, 'user_uuid');
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        byUserUuid: Object.keys(payload).reduce((userMemberships, userUuid) => {
          return {
            ...userMemberships,
            [userUuid]: {
              data: payload[userUuid],
              loading: false,
              loaded: true,
              error: null,
            },
          };
        }, {}),
      };
    },
    [FETCH_USER_MEMBERSHIPS_FAIL]: (state: State, action: FetchUserMembershipAction) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        data: [],
        byUserUuid: action.meta.userUuids.reduce((userMemberships, userUuid) => {
          return {
            ...userMemberships,
            [userUuid]: {
              data: [],
              loading: false,
              loaded: false,
              error: action.error,
            },
          };
        }, {}),
      };
    },
  },
  initialState
);

// Action Creators
export const assignUserMembership = (
  userUuid: string,
  companyUuid: string,
  membershipUuid: string
) => {
  const now = moment().format(dateFormatsDeprecated.iso_date);

  const user = {
    uuid: userUuid,
    started_on: now,
    membership_uuid: membershipUuid,
  };

  return createRequestActionAdapter({
    endpoint: `${config.pegasus.uri}/accounts/${companyUuid}/user_memberships`,
    method: 'POST',
    types: [ASSIGN_USER, ASSIGN_USER_SUCCESS, ASSIGN_USER_FAIL],
    body: { user },
    getErrorMessageFromResponse: (_res, json) => {
      return json.result;
    },
  });
};

export const fetchMembershipsForCompany = (companyUuid: string) =>
  createRequestActionAdapter({
    endpoint: `${config.pegasus.uri}/accounts/${companyUuid}/memberships`,
    method: 'GET',
    types: [
      FETCH_ACCOUNT_MEMBERSHIPS,
      FETCH_ACCOUNT_MEMBERSHIPS_SUCCESS,
      FETCH_ACCOUNT_MEMBERSHIPS_FAIL,
    ],
    meta: {
      companyUuid,
    },
  });

export const reconcileAccountMemberships = (accountUuid: string) =>
  createRequestActionAdapter({
    endpoint: `${config.accountService.uri}/v1/accounts/${accountUuid}/reconciliations`,
    method: 'POST',
    types: [RECONCILE_ACCOUNT, RECONCILE_ACCOUNT_SUCCESS, RECONCILE_ACCOUNT_FAIL],
  });

const fetchUserMembershipsAction = createAction(FETCH_USER_MEMBERSHIPS);

const fetchUserMembershipsSuccessAction = createAction(
  FETCH_USER_MEMBERSHIPS_SUCCESS,
  allUserMemberships => allUserMemberships
);

const fetchUserMembershipsFailureAction = createAction(
  FETCH_USER_MEMBERSHIPS_FAIL,
  () => {},
  (err: string) => ({
    notification: {
      message: err,
      type: ToastType.ERROR,
    },
  })
);

const fetchUserMembershipsByChunkedUuids = (
  userUuids: string[],
  companyUuid: string,
  userUuid?: string
) => {
  const queryParams = {
    user_uuids: userUuids,
    status_scope: 'active',
    ...(userUuid ? {} : { account_uuid: companyUuid }),
  };

  const query = queryString.stringify(queryParams, { arrayFormat: 'bracket' });

  return createRequestActionAdapter<UserMembershipFetchResult>({
    method: 'GET',
    endpoint: `${config.pegasus.uri}/user_memberships?${query}`,
    headers: { 'Cache-Control': 'no-cache' },
    getPayloadFromResponse: payload => payload.result,
    // uses `INTERNAL_NAME` as a way to dispatch subcall processes obfuscated
    // from the main action for this multi-call fetch step
    types: [
      { type: FETCH_USER_MEMBERSHIPS_INTERNAL_NAME, meta: { userUuids } },
      { type: FETCH_USER_MEMBERSHIPS_INTERNAL_NAME_SUCCESS, meta: { userUuids } },
      { type: FETCH_USER_MEMBERSHIPS_INTERNAL_NAME_FAIL, meta: { userUuids } },
    ],
  });
};

/**
 * Combines all the calls of a multi-call response into a single value.
 * This call takes a potentially long query string (over 4,000 characters)
 * and chunks it into several smaller pieces, before stitching them together
 * in a single response. This falls outside any pagination features and is
 * used to prevent a request URL character count limitation.
 */
const getAllUserMemberships = async (
  fetch,
  dispatch,
  userUuids: string[],
  companyUuid: string,
  userUuid?: string
) => {
  const promises: Promise<PromiseUserMembershipFetchResult>[] = [];
  const userUuidOffset: number = 40;
  const firstChunk: PromiseUserMembershipFetchResult = await dispatch(
    fetch(userUuids.slice(0, userUuidOffset), companyUuid, userUuid)
  );
  const results: UserMembershipPayload[] = firstChunk.payload;

  for (
    let offset = userUuidOffset, totalPages = userUuids.length;
    offset < totalPages;
    offset += userUuidOffset
  ) {
    promises.push(
      dispatch(fetch(userUuids.slice(offset, offset + userUuidOffset), companyUuid, userUuid))
    );
  }

  const payloads = await Promise.all(promises);
  return payloads.reduce((acc, payload) => acc.concat(payload.payload), results);
};

export const fetchUserMemberships = (userUuid?: string) => async (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  const { seats, page } = getState();
  const companyUuid: string = page.metadata.params?.uuid;
  const userUuids = userUuid ? [userUuid] : getUserUuids(seats.byUuid[companyUuid] || []);

  if (userUuids.length <= 0) return null;

  dispatch(fetchUserMembershipsAction);

  try {
    const allUserMemberships = await getAllUserMemberships(
      fetchUserMembershipsByChunkedUuids,
      dispatch,
      userUuids,
      companyUuid,
      userUuid
    );
    return dispatch(fetchUserMembershipsSuccessAction(allUserMemberships));
  } catch (err) {
    dispatch(fetchUserMembershipsFailureAction(err));
  }
};

export default reducer;
