import React from 'react';

const CloseIconSmall: React.FC<{
  className?: string;
  onClick?: (e: React.SyntheticEvent<SVGElement>) => void;
}> = ({ className, onClick, ...props }) => (
  <svg
    aria-labelledby="close-icon"
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    fill="none"
    className={className}
    onClick={onClick}
    {...props}
  >
    <title id="close-icon">Close icon</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8536 0.853591L0.853591 11.8536L0.146484 11.1465L11.1465 0.146484L11.8536 0.853591Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1465 11.8536L0.146484 0.853591L0.853591 0.146484L11.8536 11.1465L11.1465 11.8536Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIconSmall;
