import { handleActions } from 'redux-actions';
import { Action } from 'redux';

import rollbar from 'lib/rollbar';
import { ActionWithPayload, Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';

import { FETCH_BUILDINGS, FETCH_BUILDINGS_FAIL, FETCH_BUILDINGS_SUCCESS } from '../buildings';

import { Location } from './types';
import {
  BuildingResponse,
  fromLocationsResponseToBuildings,
  fromLocationsResponseToSMLocation,
} from './convertors';
import { fetchAllLocations } from './locationsFetcher';

export * from './types';

// Action Constants
export const FETCH_LOCATIONS = cc('FETCH_LOCATIONS');
export const FETCH_LOCATIONS_SUCCESS = cc('FETCH_LOCATIONS_SUCCESS');
export const FETCH_LOCATIONS_FAIL = cc('FETCH_LOCATIONS_FAIL');

export type State = {
  loaded: boolean;
  loading: boolean;
  error: boolean | null | undefined;
  data: Array<Location>;
};

export interface LocationsSubset {
  locations: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  data: [],
  error: null,
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_LOCATIONS]: state => ({ ...state, loading: true, loaded: false }),
    [FETCH_LOCATIONS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.payload,
      error: null,
    }),
    [FETCH_LOCATIONS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export const fetchLocations = (
  shouldFetchLocations: boolean,
  shouldFetchBuildings: boolean
) => async (dispatch: Dispatch<ActionWithPayload<BuildingResponse | Array<Location>> | Action>) => {
  if (shouldFetchBuildings) {
    dispatch({
      type: FETCH_BUILDINGS,
    });
  }

  if (shouldFetchLocations) {
    dispatch({
      type: FETCH_LOCATIONS,
    });
  }

  try {
    const locationsRes = await fetchAllLocations();

    if (shouldFetchBuildings) {
      dispatch({
        type: FETCH_BUILDINGS_SUCCESS,
        payload: fromLocationsResponseToBuildings(locationsRes),
      });
    }

    if (shouldFetchLocations) {
      dispatch({
        type: FETCH_LOCATIONS_SUCCESS,
        payload: fromLocationsResponseToSMLocation(locationsRes),
      });
    }
  } catch (error) {
    rollbar.warn(error); // Using warn while we debug https://jira.weworkers.io/browse/SPSN-8846
    if (shouldFetchBuildings) {
      dispatch({
        type: FETCH_BUILDINGS_FAIL,
        error,
      });
    }

    if (shouldFetchLocations) {
      dispatch({
        type: FETCH_LOCATIONS_FAIL,
        error,
      });
    }
  }
};

export default reducer;
