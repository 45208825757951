import { get, isUndefined, isNull, sortBy } from 'lodash';

import { InventoryPayloadItem, TagPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export const inventoryTagsSerializer = (
  inventoryPayloadItem: InventoryPayloadItem,
  serializerKey: string
): InventoryAttribute | null | undefined => {
  const attribute = get(inventoryPayloadItem, serializerKey);

  if (isUndefined(attribute) || isNull(attribute)) {
    return null;
  }

  let value;
  if (attribute) {
    value = sortBy(attribute, ['name'])
      .map(tag => tag.name)
      .join(', ');
  }

  const tags = attribute as Array<TagPayloadItem>;
  return {
    value,
    meta: {
      tags,
    },
  };
};

export default inventoryTagsSerializer;
