import { getFloor } from 'features/floors/utils';

import { InventoryPayloadItem } from '../config/types';
import { Building, InventoryAttribute } from '../types';
import isInventoryGroup from '../actions/isInventoryGroup';
import { Payload } from '../ducks/buildingQuerySi';

const SPACEMAN_IDENTIFIER_TYPE = 'SPACEMAN';

export default (inventory: InventoryPayloadItem): InventoryAttribute | null | undefined => {
  const buildingUuid = inventory.location?.uuid;
  const country = inventory.location?.country;
  const displayType = inventory.displayType;
  const isGroup = isInventoryGroup(inventory);
  const floorName = inventory.floor?.name;
  const locationName = inventory.location?.name;
  const name = inventory.name;
  const isMigrated = !!inventory.location?.isMigrated;
  const buildings = inventory.location?.buildings || [];

  if (displayType == null || locationName == null || name == null) {
    return null;
  }

  const floor = floorName === name ? null : getFloor(floorName);

  return {
    value: '',
    meta: {
      buildingUuid,
      country,
      displayType,
      floor,
      isGroup,
      locationName,
      name,
      isMigrated,
      buildings,
    },
  };
};

export const normalizeLocations = (rawPayload: Payload | undefined): Array<Building> => {
  const locations = rawPayload?.data?.searchLocations?.data ?? [];
  return locations.map(rawBuilding => {
    const id = rawBuilding.identifiers?.find(ident => ident.type === SPACEMAN_IDENTIFIER_TYPE)
      ?.value;
    const name = rawBuilding.name;
    const lat = rawBuilding.addresses?.[0].latitude;
    const lng = rawBuilding.addresses?.[0].longitude;

    return <Building>{
      id,
      name,
      lat,
      lng,
    };
  });
};
