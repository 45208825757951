import { handleActions, Action } from 'redux-actions';

import { BaseAction } from 'store/types';

import { InvoiceReview as State, Invoice, Addon, UpcomingBillResponse } from '../types';

import {
  FETCH_INVOICES,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAIL,
  FETCH_UPCOMING_BILLS,
  FETCH_UPCOMING_BILLS_SUCCESS,
  FETCH_UPCOMING_BILLS_FAIL,
  FETCH_CURRENT_LOCATION,
  FETCH_CURRENT_LOCATION_SUCCESS,
  FETCH_CURRENT_LOCATION_FAIL,
  SET_REVIEWED,
  SET_REVIEWED_SUCCESS,
  SET_REVIEWED_FAIL,
  INVOICE_REVIEW_UPDATE_NOTES,
  INVOICE_REVIEW_UPDATE_NOTES_SUCCESS,
  INVOICE_REVIEW_UPDATE_NOTES_FAIL,
  FETCH_ADDONS,
  FETCH_ADDONS_SUCCESS,
  FETCH_ADDONS_FAIL,
  FETCH_ACCOUNT_HOLDS,
  FETCH_ACCOUNT_HOLDS_SUCCESS,
  FETCH_ACCOUNT_HOLDS_FAIL,
  FETCH_RISK_ASSESSMENT,
  FETCH_RISK_ASSESSMENT_SUCCESS,
  FETCH_RISK_ASSESSMENT_FAIL,
  SELECT_FILTER_VALUE,
  SET_INVOICE_LOADING,
  SET_ACCOUNT_UI_REVIEWED,
  SET_ALWAYS_FLAGGED,
  SET_ALWAYS_FLAGGED_SUCCESS,
  SET_ALWAYS_FLAGGED_FAIL,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAIL,
  SET_ACCOUNTS_FILTER,
} from './actions';

const riskAssessmentFilters = {
  SHOW_FLAGGED: 'Show Flagged',
  SHOW_REVIEWED: 'Show Reviewed',
  SHOW_ALL: 'Show All',
};

const initialState: State = {
  spacemanLocation: {
    error: false,
    data: { id: '-1', uuid: '' },
  },
  tags: {
    loading: false,
    loaded: false,
    data: {},
    error: false,
  },
  searchFilter: '',
  bills: {
    loading: false,
    loaded: false,
    data: [],
    error: false,
  },
  upcomingBill: {
    loading: false,
    loaded: false,
    data: [],
    error: '',
  },
  riskAssessment: {
    loading: false,
    loaded: false,
    data: [],
    error: false,
    filters: {
      values: Object.keys(riskAssessmentFilters).map(key => riskAssessmentFilters[key]),
      currentValue: riskAssessmentFilters.SHOW_ALL,
    },
  },
  addons: {
    loading: false,
    loaded: false,
    data: [],
    error: false,
  },
  setReviewed: {
    loading: false,
    loaded: false,
    error: false,
  },
  updateNotes: {
    loading: false,
    loaded: false,
    error: false,
  },
  setFlagged: {
    loading: false,
    loaded: false,
    error: false,
  },
  accountHolds: {
    loading: false,
    loaded: false,
    data: [],
    error: false,
  },
};

type PayloadAction = Readonly<
  BaseAction & {
    payload: { data: [] };
    error?: boolean | null | undefined;
  }
>;

type FetchInvoiceAction = Readonly<
  BaseAction & {
    payload: { data: { invoices: Array<Invoice> } };
    error?: boolean | null | undefined;
  }
>;

type FetchAddonsAction = Readonly<
  BaseAction & {
    payload: { result: Array<Addon> };
    error?: boolean | null | undefined;
  }
>;

type StringPayloadAction = Readonly<
  BaseAction & {
    payload: string;
    error?: boolean | null | undefined;
  }
>;

// Reducer
export const reducer = handleActions<
  State,
  any,
  { accountUuid: string; reviewed: boolean; userUuid: string; flagged: boolean }
>(
  {
    [SET_ACCOUNTS_FILTER]: (state: State, action) => ({
      ...state,
      searchFilter: action.payload,
    }),
    [SET_INVOICE_LOADING]: (state: State) => ({
      ...state,
      bills: {
        ...state.bills,
        data: [],
        loaded: false,
        loading: true,
      },
    }),
    [FETCH_INVOICES]: (state: State) => ({
      ...state,
      bills: {
        ...state.bills,
        data: [],
        loading: true,
        loaded: false,
      },
    }),
    [FETCH_INVOICES_SUCCESS]: (state: State, action: FetchInvoiceAction) => ({
      ...state,
      bills: {
        ...state.bills,
        loading: false,
        loaded: true,
        data: action.payload?.data?.invoices,
        error: null,
      },
    }),
    [FETCH_INVOICES_FAIL]: (state: State, action) => ({
      ...state,
      bills: {
        ...state.bills,
        data: [],
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),
    [FETCH_UPCOMING_BILLS]: (state: State) => ({
      ...state,
      upcomingBill: {
        ...state.upcomingBill,
        data: [],
        loading: true,
        loaded: false,
      },
    }),
    [FETCH_UPCOMING_BILLS_SUCCESS]: (state: State, action: Action<UpcomingBillResponse>) => {
      return {
        ...state,
        upcomingBill: {
          ...state.upcomingBill,
          loading: false,
          loaded: true,
          data: action.payload,
          error: null,
        },
      };
    },
    [FETCH_UPCOMING_BILLS_FAIL]: (state: State, action) => ({
      ...state,
      upcomingBill: {
        ...state.upcomingBill,
        data: [],
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),
    [FETCH_CURRENT_LOCATION]: (state: State) => ({
      ...state,
      spacemanLocation: {
        ...state.spacemanLocation,
        data: { id: '-1', uuid: '' },
      },
    }),
    [FETCH_CURRENT_LOCATION_SUCCESS]: (state: State, action: any) => ({
      ...state,
      spacemanLocation: {
        ...state.spacemanLocation,
        data: action.payload,
        error: null,
      },
    }),
    [FETCH_CURRENT_LOCATION_FAIL]: (state: State, action) => ({
      ...state,
      spacemanLocation: {
        ...state.spacemanLocation,
        data: { id: '-1', uuid: '' },
        error: action.error,
      },
    }),
    [FETCH_TAGS]: (state: State) => ({
      ...state,
      tags: {
        ...state.tags,
        loading: true,
        loaded: false,
        data: {},
        error: false,
      },
    }),
    [FETCH_TAGS_SUCCESS]: (state: State, action: any) => ({
      ...state,
      tags: {
        ...state.tags,
        loading: false,
        loaded: true,
        data: action.payload?.result,
        error: false,
      },
    }),
    [FETCH_TAGS_FAIL]: (state: State, action) => ({
      ...state,
      tags: {
        ...state.tags,
        loaded: false,
        data: {},
        error: action.error,
      },
    }),
    [SET_REVIEWED]: (state, action) => {
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          data: state.riskAssessment.data.map(account => {
            if (account.score.accountUuid === action.meta.accountUuid) {
              return {
                ...account,
                reviewInfo: {
                  ...account.reviewInfo,
                  reviewed: action.meta.reviewed,
                  updatedBy: action.meta.userUuid,
                },
              };
            }

            return account;
          }),
        },
        setReviewed: {
          ...state.setReviewed,
          loading: true,
          loaded: false,
        },
      };
    },
    [SET_REVIEWED_SUCCESS]: (state: State) => {
      return {
        ...state,
        setReviewed: {
          ...state.setReviewed,
          loading: false,
          loaded: true,
        },
      };
    },
    [SET_REVIEWED_FAIL]: (state: State, action) => ({
      ...state,
      setReviewed: {
        ...state.setReviewed,
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),

    [INVOICE_REVIEW_UPDATE_NOTES]: state => {
      return {
        ...state,
        updateNotes: {
          ...state.updateNotes,
          loading: true,
          loaded: false,
        },
      };
    },
    [INVOICE_REVIEW_UPDATE_NOTES_SUCCESS]: (state: State) => {
      return {
        ...state,
        updateNotes: {
          ...state.updateNotes,
          loading: false,
          loaded: true,
        },
      };
    },
    [INVOICE_REVIEW_UPDATE_NOTES_FAIL]: (state: State, action) => ({
      ...state,
      updateNotes: {
        ...state.updateNotes,
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),

    [SET_ALWAYS_FLAGGED]: (state, action) => {
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          data: state.riskAssessment.data.map(account => {
            if (account.score.accountUuid === action.meta.accountUuid) {
              return {
                ...account,
                alwaysFlaggedInfo: { alwaysFlagged: action.meta.flagged },
              };
            }
            return account;
          }),
        },
        setFlagged: {
          ...state.setFlagged,
          loading: true,
          loaded: false,
        },
      };
    },
    [SET_ALWAYS_FLAGGED_SUCCESS]: (state: State) => {
      return {
        ...state,
        setFlagged: {
          ...state.setFlagged,
          loading: false,
          loaded: true,
        },
      };
    },
    [SET_ALWAYS_FLAGGED_FAIL]: (state: State, action) => ({
      ...state,
      setFlagged: {
        ...state.setFlagged,
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),
    [FETCH_ADDONS]: (state: State) => {
      return {
        ...state,
        addons: {
          ...state.addons,
          data: [],
          loading: true,
          loaded: false,
          error: null,
        },
      };
    },
    [FETCH_ADDONS_SUCCESS]: (state: State, action: FetchAddonsAction) => {
      return {
        ...state,
        addons: {
          ...state.addons,
          data: action.payload?.result,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    },
    [FETCH_ADDONS_FAIL]: (state, action) => {
      return {
        ...state,
        addons: {
          ...state.addons,
          data: [],
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    },
    [FETCH_ACCOUNT_HOLDS]: (state: State): State => ({
      ...state,
      accountHolds: {
        ...state.accountHolds,
        loading: true,
        loaded: false,
        error: undefined,
        data: [],
      },
    }),
    [FETCH_ACCOUNT_HOLDS_SUCCESS]: (state: State, action): State => ({
      ...state,
      accountHolds: {
        ...state.accountHolds,
        loading: false,
        loaded: true,
        data: action.payload,
      },
    }),
    [FETCH_ACCOUNT_HOLDS_FAIL]: (state: State, action: PayloadAction): State => ({
      ...state,
      accountHolds: {
        data: [],
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),
    [FETCH_RISK_ASSESSMENT]: (state: State): State => ({
      ...state,
      riskAssessment: {
        ...state.riskAssessment,
        data: [],
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_RISK_ASSESSMENT_SUCCESS]: (state, action) => {
      const riskAssessmentAccounts = action.payload?.data?.accountsRiskAssessment || [];
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          loading: false,
          loaded: true,
          data: riskAssessmentAccounts.map((account: { reviewInfo: { reviewed: any } }) => {
            return {
              ...account,
              reviewInfo: { ...account.reviewInfo, reviewedUI: account.reviewInfo.reviewed },
            };
          }),
          error: null,
        },
      };
    },
    [FETCH_RISK_ASSESSMENT_FAIL]: (state, action) => ({
      ...state,
      riskAssessment: {
        ...state.riskAssessment,
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),
    [SET_ACCOUNT_UI_REVIEWED]: (state, action) => {
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          data: state.riskAssessment.data.map(account => {
            if (account.score.accountUuid === action.payload.accountUuid) {
              return {
                ...account,
                reviewInfo: {
                  ...account.reviewInfo,
                  reviewedUI: action.payload.reviewed,
                },
              };
            }

            return account;
          }),
        },
      };
    },
    [SELECT_FILTER_VALUE]: (state: State, action: StringPayloadAction): State => {
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          filters: {
            ...state.riskAssessment.filters,
            currentValue: action.payload,
          },
        },
      };
    },
  },
  initialState
);

export default reducer;
