import { handleActions, createAction } from 'redux-actions';
import { differenceInDays } from 'date-fns';
import { identity, isObject } from 'lodash';

import { BaseAction, Dispatch, GetGlobalState, ActionWithMetaPayload } from 'store/types';
import cc from 'store/util/createReduxConstant';
import { PersistMetaFields } from 'store/middlewares/persistToLocalStorage';
import {
  SearchResultItem,
  FailedResult,
  SuccessResult,
} from 'store/modules/search/searchService/types';
import searchService from 'store/modules/search/searchServiceWrapper';
import { SearchableEntity } from 'features/search/types';

import { RecentlyViewedItem } from './types';
import { getRecentlyViewedMap } from './selectors/recentlyViewedMap';

export const ADD_RECENTLY_VIEWED = cc('ADD_RECENTLY_VIEWED');
export const SET_RECENTLY_VIEWED = cc('SET_RECENTLY_VIEWED');
export const SET_RECENTLY_VIEWED_LOADING = cc('SET_RECENTLY_VIEWED_LOADING');
export const SET_RECENTLY_VIEWED_FINISHED_LOADING = cc('SET_RECENTLY_VIEWED_FINISHED_LOADING');
export const SET_SEARCH_RESULTS = cc('SET_SEARCH_RESULTS');
export const CLEAR_OLD_RECENTLY_VIEWED = cc('CLEAR_OLD_RECENTLY_VIEWED');
const RECENTLY_VIEWED_CACHE_PATH = 'recentlyViewed';
const RECENTLY_VIEWED_MAX_DAYS = 3;
const RECENTLY_VIEWED_MAX_ITEMS = 50;

export type State = {
  items: Hash<RecentlyViewedItem>;
  loading: boolean;
};

export interface RecentlyViewedSubset {
  recentlyViewed: State;
}

type PersistedAction<P> = ActionWithMetaPayload<PersistMetaFields, P>;
type AddRecentlyViewedPayload = { id: string } & RecentlyViewedItem;

const getPersistConfig = (): PersistMetaFields => ({
  persist: true,
  path: RECENTLY_VIEWED_CACHE_PATH,
});

const noOlderThan = (itemMap: Hash<RecentlyViewedItem>, now: number, maxDayAmount: number) => {
  const freshItems = Object.keys(itemMap).reduce<Hash<RecentlyViewedItem>>((aggregator, id) => {
    const { viewDate } = itemMap[id];
    const diffInDays = differenceInDays(viewDate, now);
    if (diffInDays <= maxDayAmount) {
      aggregator[id] = itemMap[id];
    }
    return aggregator;
  }, {});

  return freshItems;
};

const getRecentlyViewedFromCache = (): State => {
  const initialState: State = {
    items: {},
    loading: false,
  };

  try {
    const value = localStorage.getItem(RECENTLY_VIEWED_CACHE_PATH);

    if (!value) {
      return initialState;
    }

    const cachedState = JSON.parse(value);

    if (isObject(cachedState)) {
      return { ...cachedState, loading: false } as State;
    }
  } catch {}

  return initialState;
};

const getMaxRecentlyViewedSearches = (itemMap: Hash<RecentlyViewedItem>) => {
  if (Object.keys(itemMap).length < RECENTLY_VIEWED_MAX_ITEMS) {
    return itemMap;
  }

  return [...Object.entries(itemMap)]
    .sort(
      (entryA: [string, RecentlyViewedItem], entryB: [string, RecentlyViewedItem]) =>
        entryB[1].viewDate - entryA[1].viewDate
    )
    .slice(0, RECENTLY_VIEWED_MAX_ITEMS)
    .reduce((acc, searchEntry) => {
      acc[searchEntry[0]] = searchEntry[1];
      return acc;
    }, {});
};

export const reducer = handleActions<State, any, PersistMetaFields>(
  {
    [ADD_RECENTLY_VIEWED]: (
      state: State,
      {
        payload: { id, entityType, viewDate, searchResult },
      }: PersistedAction<AddRecentlyViewedPayload>
    ): State => ({
      ...state,
      items: {
        ...getMaxRecentlyViewedSearches(state.items),
        [id]: {
          viewDate,
          searchResult: searchResult || state.items[id]?.searchResult || null,
          entityType,
        },
      },
    }),
    [SET_RECENTLY_VIEWED]: (
      _: State,
      action: PersistedAction<Hash<RecentlyViewedItem>>
    ): State => ({
      items: {
        ...action.payload,
      },
      loading: false,
    }),
    [SET_RECENTLY_VIEWED_LOADING]: (state: State): State => ({
      ...state,
      loading: true,
    }),
    [SET_RECENTLY_VIEWED_FINISHED_LOADING]: (state: State): State => ({
      ...state,
      loading: false,
    }),
    [SET_SEARCH_RESULTS]: (
      state: State,
      action: PersistedAction<Hash<SearchResultItem>>
    ): State => ({
      ...state,
      items: {
        ...state.items,
        ...Object.entries(action.payload).reduce((items, [id, searchResult]) => {
          const originalItem = state.items[id];
          if (originalItem) {
            items[id] = {
              ...originalItem,
              searchResult,
            };
          }
          return items;
        }, {}),
      },
    }),
    [CLEAR_OLD_RECENTLY_VIEWED]: (state: State, action: PersistedAction<number>): State => ({
      ...state,
      items: noOlderThan(state.items, action.payload, RECENTLY_VIEWED_MAX_DAYS),
    }),
  },
  getRecentlyViewedFromCache()
);

export const setRecentlyViewed = createAction<Hash<RecentlyViewedItem>, PersistMetaFields>(
  SET_RECENTLY_VIEWED,
  identity,
  getPersistConfig
);

export const setRecentlyViewedLoading = createAction(SET_RECENTLY_VIEWED_LOADING);

export const setRecentlyViewedFinishedLoading = createAction(SET_RECENTLY_VIEWED_FINISHED_LOADING);

export const setSearchResults = createAction<Hash<SearchResultItem>, PersistMetaFields>(
  SET_SEARCH_RESULTS,
  identity,
  getPersistConfig
);

export const clearOldRecentlyViewedItems = createAction<number, PersistMetaFields>(
  CLEAR_OLD_RECENTLY_VIEWED,
  identity,
  getPersistConfig
);

const getItemId = (
  item: { uuid?: string; id?: string } | null | undefined
): string | null | undefined => item?.uuid ?? item?.id;

function isSearchSuccessful(
  searchResult: SuccessResult<any> | FailedResult | undefined
): searchResult is SuccessResult<any> {
  return searchResult?.success ?? false;
}

export const searchRecentlyViewed = async (
  getState: GetGlobalState,
  itemIds: Array<string>
): Promise<Hash<SearchResultItem>> => {
  const searchResultsMap = await searchService.get(getState).search({
    limit: itemIds.length,
    filters: {
      ids: itemIds,
    },
  });

  return Object.entries(searchResultsMap).reduce<Hash<SearchResultItem>>(
    (aggregator, [_, searchResult]) => {
      if (isSearchSuccessful(searchResult)) {
        searchResult.value.items.forEach(item => {
          const itemId = getItemId(item);

          if (itemId) {
            aggregator[itemId] = item;
          }
        });
      }
      return aggregator;
    },
    {}
  );
};

export const fetchSearchResults = () => async (
  dispatch: Dispatch<any>,
  getState: GetGlobalState
) => {
  const items = getRecentlyViewedMap(getState());
  const newItemIds = Object.entries(items).reduce<Array<string>>((ids, [id, item]) => {
    if (!item?.searchResult) {
      ids.push(id);
    }
    return ids;
  }, []);

  if (newItemIds.length > 0) {
    const newSearchResultsMap = await searchRecentlyViewed(getState, newItemIds);
    dispatch(setSearchResults(newSearchResultsMap));
  }
};

const _addRecentlyViewed = createAction<AddRecentlyViewedPayload, PersistMetaFields>(
  ADD_RECENTLY_VIEWED,
  identity,
  getPersistConfig
);

export const addRecentlyViewed = (
  entityType: SearchableEntity,
  id: string,
  searchResult?: SearchResultItem | null | undefined
) => async (dispatch: Dispatch<PersistedAction<AddRecentlyViewedPayload>>) => {
  await dispatch(
    _addRecentlyViewed({ id, entityType, viewDate: new Date().getTime(), searchResult })
  );
  if (!searchResult) {
    window.requestIdleCallback(() => dispatch(fetchSearchResults()));
  }
};

export const fetchRecentlyViewed = () => async (dispatch: Dispatch<BaseAction>) => {
  dispatch(setRecentlyViewedLoading());
  dispatch(clearOldRecentlyViewedItems(new Date().getTime()));
  await dispatch(fetchSearchResults());
  dispatch(setRecentlyViewedFinishedLoading());
};

export default reducer;
