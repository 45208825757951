import React, { FC, useState } from 'react';
import { usePopper } from 'react-popper';
import cn from 'classnames';

export enum TooltipPlacement {
  AUTO = 'auto',
  AUTO_START = 'auto-start',
  AUTO_END = 'auto-end',
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
}

export interface TooltipProps extends React.HTMLAttributes<HTMLSpanElement> {
  offsetX?: number;
  offsetY?: number;
  className?: string;
  placement?: TooltipPlacement;
  tooltip?: React.ReactNode;
  children: React.ReactNode;
}

const Tooltip: FC<TooltipProps> = ({
  placement = TooltipPlacement.TOP,
  offsetX = 0,
  offsetY = 12,
  tooltip,
  className,
  children,
  id,
  ...props
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [offsetX, offsetY] } }],
  });

  if (!tooltip) return <>{children}</>;

  return (
    <span>
      <span ref={setReferenceElement} className="peer" aria-describedby={id}>
        {children}
      </span>
      <div
        ref={setPopperElement}
        style={styles.popper}
        role="tooltip"
        id={id}
        className={cn(
          'peer-hover:visible peer-hover:auto invisible pointer-events-none',
          'py-2xs px-xs bg-white rounded-sm shadow font-main text-gray-40',
          className
        )}
        {...attributes.popper}
        {...props}
      >
        {tooltip}
      </div>
    </span>
  );
};

export default Tooltip;
