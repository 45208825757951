import { handleActions } from 'redux-actions';

import { Floor, Location, MLSLocationPayload } from 'features/building/types';
import {
  FETCH_MARKETABLE_LOCATIONS,
  FETCH_MARKETABLE_LOCATIONS_FAILURE,
  FETCH_MARKETABLE_LOCATIONS_SUCCESS,
} from 'features/building/ducks/locationConsts';

export type MLSLocationState = {
  locations: Hash<Location>;
  floors: Hash<Floor>;
};

const intialState: MLSLocationState = {
  locations: {},
  floors: {},
};

const reducer = handleActions<MLSLocationState, { locations: Hash<Location>; floors: Hash<Floor> }>(
  {
    [FETCH_MARKETABLE_LOCATIONS]: (state: MLSLocationState): MLSLocationState => ({
      ...state,
    }),
    [FETCH_MARKETABLE_LOCATIONS_SUCCESS]: (
      state: MLSLocationState,
      action: MLSLocationPayload
    ): MLSLocationState => ({
      ...state,
      locations: action.payload.locations,
      floors: action.payload.floors,
    }),
    [FETCH_MARKETABLE_LOCATIONS_FAILURE]: (state: MLSLocationState): MLSLocationState => ({
      ...state,
      locations: {},
      floors: {},
    }),
  },
  intialState
);

export default reducer;
