import React, { ReactElement } from 'react';
import { Route, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

type Props = Readonly<
  RouteComponentProps & {
    children: ReactElement<typeof Route> | ReactElement<typeof Route>[];
  }
>;

export const ProtectedRoute = (props: Props) => {
  const auth0 = useAuth0();
  const RenderRoute = ({ children, history }: Props) => {
    if (auth0.isAuthenticated) {
      return children;
    }

    const search = new URLSearchParams([
      ['nextPath', history.location.pathname],
      ['nextQuery', history.location.search],
    ]);

    // handle errors that are passed to the app via query parameters
    // if an auth0 rule fails during the login process, the user will be returned to the app with error query params
    const url = new URL(window.location.href);
    if (url.searchParams.has('error') && url.searchParams.has('error_description')) {
      // add error to the new search params
      search.append('error', url.searchParams.get('error_description') || '');

      // Remove the error query params from the url to make it neat again
      url.searchParams.delete('error');
      url.searchParams.delete('error_description');
      url.searchParams.delete('state');
      window.history.replaceState(null, '', url.toString());
    }

    const to = {
      pathname: '/login',
      search: search.toString(),
    };

    return <Redirect to={to} />;
  };

  return <Route render={() => RenderRoute(props)} />;
};

export default withRouter(ProtectedRoute);
