import React, { Component } from 'react';
import cn from 'classnames';
import { sortBy } from 'lodash';

import ReactSelect from 'components-dieter/base/legacy-react-select';
import { deprecated_isPresent } from 'lib/util';

import style from './selectInput.scss';

export interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

export type Selected = Array<Option> | Option;

export type Value = Array<number | string> | number | string;

export interface Props {
  allowSelectAll?: boolean;
  clearable?: boolean;
  defaultAllOption: Option;
  error?: boolean | string;
  multi?: boolean;
  removeSelected?: boolean;
  closeOnSelect?: boolean;
  name?: string;
  onBlur?: (value: Value | null | undefined) => void;
  onOpen?: () => any;
  onChange?: (value: Value | null | undefined, name?: string | null | undefined) => void;
  options: Array<Option>;
  placeholder?: string;
  sort?: boolean;
  value?: Value | null | undefined;
  className?: string;
  disabled?: boolean;
  searchable?: boolean;
}

// https://github.com/erikras/redux-form/issues/82
export default class SelectInput extends Component<Props> {
  static defaultProps = {
    defaultAllOption: {
      label: 'All',
      value: '*',
    },
  };

  get allOptionsSelected() {
    const { multi, options, value } = this.props;

    if (
      multi &&
      deprecated_isPresent(value) &&
      Array.isArray(value) &&
      deprecated_isPresent(options) &&
      options.find(option => option.value === '*')
    ) {
      return value.length === options.length;
    }

    return false;
  }

  getOptions = (): Array<Option> => {
    const { allowSelectAll, defaultAllOption, options, sort } = this.props;

    let results: Array<Option> = []; // eslint-disable-line fp/no-let

    if (options && options.length > 0) {
      results = options;
    }

    if (sort) {
      results = sortBy(results, 'label');
    }

    if (allowSelectAll) {
      if (this.allOptionsSelected) {
        return [];
      }

      return [defaultAllOption, ...results];
    }

    return results;
  };

  handleBlur = (_: React.FocusEvent<HTMLDivElement | HTMLInputElement>): void => {
    const { onBlur, value } = this.props;

    if (onBlur) {
      onBlur(value);
    }
  };

  handleChange = (selected: Selected | null): void => {
    const { allowSelectAll, defaultAllOption, multi, onChange, options, name } = this.props;

    const change = (values: Value | null | undefined, name?: string | null | undefined): void => {
      if (onChange) {
        onChange(values, name);
      }
    };

    if (!selected) {
      change('', name);
      return;
    }

    if (!multi && !Array.isArray(selected)) {
      change(selected.value, name);
      return;
    }

    if (
      selected &&
      allowSelectAll &&
      Array.isArray(selected) &&
      selected.some(item => item.value === defaultAllOption.value)
    ) {
      change(
        options.map(opt => opt.value),
        name
      );
      return;
    }

    if (Array.isArray(selected)) {
      change(
        selected.map(item => item.value),
        name
      );
    }
  };

  getSelectedValues = (): Array<Option> | Value | undefined => {
    const { allowSelectAll, defaultAllOption, value } = this.props;

    if (allowSelectAll && this.allOptionsSelected) {
      return [defaultAllOption];
    }

    return value ?? undefined;
  };

  render() {
    const {
      allowSelectAll,
      closeOnSelect,
      defaultAllOption,
      error,
      removeSelected,
      sort,
      className,
      ...rest
    } = this.props;

    const selectStyle = cn(style.selectInput, className, {
      [style.selectInputError]: error,
    });

    return (
      <ReactSelect
        {...rest}
        className={selectStyle}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        options={this.getOptions()}
        value={this.getSelectedValues()}
      />
    );
  }
}
