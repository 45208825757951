import { merge, some, startsWith } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import config from '../../config';

function applyXRequestIdHeader(_, headers) {
  headers['x-request-id'] = uuidv4();
}

function removeXRequestIdHeader(_, headers) {
  delete headers?.['x-request-id'];
}

function startsWithAny(list: Array<string>) {
  return str => some(list, prefix => startsWith(str, prefix));
}

/**
 * All special rules should go here.
 *
 * Design note: the purpose of this file is to start tracking apis that deviate from everyone else
 * by requiring them to stand out by having custom rules.
 *
 * Also design note: this file should be moved next to the other routes file
 */
const routingRuleSet = [
  {
    test: () => true,
    fn: applyXRequestIdHeader,
  },
  {
    // TODO: once welkio's cors policiy is updated to accept x-request-id in the request header, remove welkio calls from this list
    test: startsWithAny([
      config.welkioAuthProxy.uri,
      config.welkioAccess.uri,
      config.welkioUploads.uri,
      config.contentful.cdn_uri,
      config.contentful.preview_uri,
      config.algolia.securedKeyUri,
    ]),
    fn: removeXRequestIdHeader,
  },
];

/**
 * Given a set of rules, apply them all in order.
 *
 * This version assumes you're operating on something in-memory.
 *
 * Runs the test function, and if it passes, runs fn.
 * @param {[{test: Function, fn: Function}]} ruleSet - a list of rules
 * @param {args[]} args - a list of arguments to pass to the rules
 * @returns {undefined}
 */
export function applyRuleSet(ruleSet, ...args) {
  for (let i = 0; i < ruleSet.length; i++) {
    const rule = ruleSet[i];
    if (rule.test(...args)) {
      rule.fn(...args);
    }
  }
}

export const applyRules = (url: string, headers: {}) => {
  applyRuleSet(routingRuleSet, url, headers);
};

export function setHeaders(url: string, headers: any = {}) {
  headers = merge({}, headers);
  applyRules(url, headers);
  return headers;
}
