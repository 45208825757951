import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_ACCESS_LEVELS,
  FETCH_ACCESS_LEVELS_SUCCESS,
  FETCH_ACCESS_LEVELS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_LEVELS'));

export const [
  FETCH_READER_GROUPS,
  FETCH_READER_GROUPS_SUCCESS,
  FETCH_READER_GROUPS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_READER_GROUPS'));

export const [
  FETCH_TIME_SPEC_GROUPS,
  FETCH_TIME_SPEC_GROUPS_SUCCESS,
  FETCH_TIME_SPEC_GROUPS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_TIME_SPEC_GROUPS'));

export const [
  CREATE_ACCESS_LEVEL,
  CREATE_ACCESS_LEVEL_SUCCESS,
  CREATE_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(createReduxConstant('CREATE_ACCESS_LEVEL'));

export const [
  UPDATE_ACCESS_LEVEL,
  UPDATE_ACCESS_LEVEL_SUCCESS,
  UPDATE_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(createReduxConstant('UPDATE_ACCESS_LEVEL'));

export const [
  DELETE_ACCESS_LEVEL,
  DELETE_ACCESS_LEVEL_SUCCESS,
  DELETE_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(createReduxConstant('DELETE_ACCESS_LEVEL'));

export const [
  FETCH_ACCESS_TYPES,
  FETCH_ACCESS_TYPES_SUCCESS,
  FETCH_ACCESS_TYPES_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_TYPES'));

export const [
  FETCH_ACCESS_ASSIGNMENTS,
  FETCH_ACCESS_ASSIGNMENTS_SUCCESS,
  FETCH_ACCESS_ASSIGNMENTS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_ASSIGNMENTS'));

export const [
  FETCH_S2_SERVERS,
  FETCH_S2_SERVERS_SUCCESS,
  FETCH_S2_SERVERS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_S2_SERVERS'));

export const DESTROY_ACCESS_ASSIGNMENTS = createReduxConstant('DESTROY_ACCESS_ASSIGNMENTS');

export const DESTROY_ACCESS_CONTROL_DATA = createReduxConstant('DESTROY_ACCESS_CONTROL_DATA');

export const [
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAIL,
] = createRequestConstantNames(createReduxConstant('CREATE_SCHEDULE'));

export const [
  EDIT_SCHEDULE,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAIL,
] = createRequestConstantNames(createReduxConstant('EDIT_SCHEDULE'));

export const [GET_SCHEDULE, GET_SCHEDULE_SUCCESS, GET_SCHEDULE_FAIL] = createRequestConstantNames(
  createReduxConstant('GET_SCHEDULE')
);

export const [
  GET_SCHEDULES_LIST,
  SET_SCHEDULES_LIST_SUCCESS,
  SET_SCHEDULES_LIST_FAIL,
] = createRequestConstantNames(createReduxConstant('GET_SCHEDULES_LIST'));

export const [
  GET_ACCESS_LEVEL,
  SET_ACCESS_LEVEL_SUCCESS,
  SET_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(createReduxConstant('GET_ACCESS_LEVEL'));

export const [
  UPDATE_FEENICS_ACCESS_LEVEL,
  UPDATE_FEENICS_ACCESS_LEVEL_SUCCESS,
  UPDATE_FEENICS_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(createReduxConstant('UPDATE_FEENICS_ACCESS_LEVEL'));

export const [
  GET_COMPANY_INFO,
  GET_COMPANY_INFO_SUCCESS,
  GET_COMPANY_INFO_FAIL,
] = createRequestConstantNames(createReduxConstant('GET_COMPANY_INFO'));

export const [
  GET_COMPANY_RESERVATIONS,
  GET_COMPANY_RESERVATIONS_SUCCESS,
  GET_COMPANY_RESERVATIONS_FAIL,
] = createRequestConstantNames(createReduxConstant('GET_COMPANY_RESERVATIONS'));

export const [
  CREATE_CUSTOM_ACCESS_LEVEL,
  CREATE_CUSTOM_ACCESS_LEVEL_SUCCESS,
  CREATE_CUSTOM_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(createReduxConstant('CREATE_CUSTOM_ACCESS_LEVEL'));

export const DESTROY_CREATE_ACCESS_CONTROL_LEVEL_FORM = createReduxConstant(
  'DESTROY_CREATE_ACCESS_CONTROL_LEVEL_FORM'
);

export const [
  FETCH_ACCESS_LEVEL_PARTITION,
  FETCH_ACCESS_LEVEL_PARTITION_SUCCESS,
  FETCH_ACCESS_LEVEL_PARTITION_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_LEVEL_PARTITION'));

export const [
  GET_LOCATION_CUSTOM_ACCESS_LEVELS,
  GET_LOCATION_CUSTOM_ACCESS_LEVELS_SUCCESS,
  GET_LOCATION_CUSTOM_ACCESS_LEVELS_FAIL,
] = createRequestConstantNames(createReduxConstant('GET_LOCATION_CUSTOM_ACCESS_LEVELS'));

export const [
  REMOVE_CUSTOM_ACCESS_LEVEL,
  REMOVE_CUSTOM_ACCESS_LEVEL_SUCCESS,
  REMOVE_CUSTOM_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(createReduxConstant('REMOVE_CUSTOM_ACCESS_LEVEL'));

export const [
  GET_ELEVATORS_LIST,
  GET_ELEVATORS_LIST_SUCCESS,
  GET_ELEVATORS_LIST_FAIL,
] = createRequestConstantNames(createReduxConstant('GET_ELEVATORS_LIST'));
