import {
  ROOM_RESERVATION_REASON_FORM_FIELD,
  ROOM_RESERVATION_NOTE_FORM_FIELD,
} from 'features/rooms/components/modals/constants';

export const REFUND_BOOKING_MODAL_FORM = {
  NAME: 'REFUND_BOOKING_FORM',
  FIELDS: {
    NOTE: ROOM_RESERVATION_NOTE_FORM_FIELD,
    REASON: ROOM_RESERVATION_REASON_FORM_FIELD,
  },
};

export const PAST_MONTH_REFUND_MODAL_NAME = 'PAST_MONTH_REFUND_MODAL_NAME';
export const REFUND_NOTIFICATION_BOX_TEXT =
  'The member will receive a full refund of the credits charged for this reservation';
export const MODAL_TITLE = 'Refund Room Credits';
export const MODAL_TITLE_DIETER = 'Refund booking';
export const MODAL_SUBTITLE_DIETER =
  'Are you sure you want to refund this booking? Refunding a booking will refund all credits back to the company.';
export const REFUND_REASON_TITLE = 'Refund reason*';
export const REFUND_REASON_PLACEHOLDER = 'Select a refund reason';
export const REFUND_REASON_PLACEHOLDER_DIETER = 'Select reason for refund';
export const REFUND_CONFIRMATION_MESSAGE = 'Reservation has been fully refunded.';
