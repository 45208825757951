import { handleActions, Reducer } from 'redux-actions';
import { map } from 'lodash';

import { PaperworkSubset } from 'features/paperwork/ducks/index';
import { createRequestAction } from 'store/util';
import { Dispatch, BaseAction } from 'store/types';
import config from 'config';
import cc from 'store/util/createReduxConstant';

export const SET_SELECTED_MODIFICATION_TYPES = cc('SET_SELECTED_MODIFICATION_TYPES');
export const FETCH_AGREEMENT_MODIFICATIONS = cc('FETCH_AGREEMENT_MODIFICATIONS');
export const FETCH_AGREEMENT_MODIFICATIONS_SUCCESS = cc('FETCH_AGREEMENT_MODIFICATIONS_SUCCESS');
export const FETCH_AGREEMENT_MODIFICATIONS_FAIL = cc('FETCH_AGREEMENT_MODIFICATIONS_FAIL');

export interface State {
  loaded: boolean;
  modificationTypes: {};
  loading: boolean;
  error?: boolean | null | undefined;
  selectedModificationTypeIds: {};
}

export const initialState: State = {
  loading: false,
  loaded: false,
  modificationTypes: {},
  error: null,
  selectedModificationTypeIds: {},
};

export const reducer: Reducer<State, any> = handleActions<State, any>(
  {
    [FETCH_AGREEMENT_MODIFICATIONS]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_AGREEMENT_MODIFICATIONS_SUCCESS]: (state: State, action: { payload: {} }) => ({
      ...state,
      modificationTypes: action.payload,
      loading: false,
      loaded: true,
      error: null,
    }),

    [FETCH_AGREEMENT_MODIFICATIONS_FAIL]: (
      state: State,
      action: { error?: boolean | null | undefined }
    ) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),

    [SET_SELECTED_MODIFICATION_TYPES]: (state: State, action: { payload: {} }) => ({
      ...state,
      selectedModificationTypeIds: action.payload,
    }),
  },
  initialState
);

export const setSelectedModificationTypeIds = (modificationTypes: {}) => {
  return {
    type: SET_SELECTED_MODIFICATION_TYPES,
    payload: modificationTypes,
  };
};

export const clearSelectedModificationTypeIds = () => ({
  type: SET_SELECTED_MODIFICATION_TYPES,
  payload: {},
});

export const fetchAgreementModifications = () => (dispatch: Dispatch<BaseAction>) => {
  // The endpoint goes to "exception_types" and not a more appropriate "modification_types" since the time to change the api endpoint in spaceman is too long.
  const requestAction = createRequestAction({
    endpoint: `${config.spaceman.uri}/api/v3/exception_types`,
    method: 'GET',
    types: [
      FETCH_AGREEMENT_MODIFICATIONS,
      FETCH_AGREEMENT_MODIFICATIONS_SUCCESS,
      FETCH_AGREEMENT_MODIFICATIONS_FAIL,
    ],
  });

  return dispatch(requestAction);
};

const sortModifications = (modificationsTypes: {}) => {
  // $FlowFixMe TODO (kangax) can't figure out how to make map understand return values
  const optionsArray = map(modificationsTypes, (label: string, value: string) => {
    return { label, value };
  });

  optionsArray.sort((obj1, obj2) => {
    if (obj1.label < obj2.label) return -1;
    if (obj1.label > obj2.label) return 1;
    return 0;
  });

  const indexOfOther = optionsArray.findIndex(obj => obj.value === 'other');
  if (indexOfOther > -1) {
    optionsArray.push(optionsArray.splice(indexOfOther, 1)[0]);
  }
  return optionsArray;
};

// Selectors
export const getSortedAgreementModifications = (state: PaperworkSubset) =>
  sortModifications(state.paperwork.agreementModifications.modificationTypes);

export default reducer;
