import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_MARKETABLE_LOCATIONS,
  FETCH_MARKETABLE_LOCATIONS_SUCCESS,
  FETCH_MARKETABLE_LOCATIONS_FAILURE,
] = createRequestConstantNames(cc('FETCH_MARKETABLE_LOCATIONS'));

export const [
  FETCH_SEARCH_SI_LOCATIONS,
  FETCH_SEARCH_SI_LOCATIONS_SUCCESS,
  FETCH_SEARCH_SI_LOCATIONS_FAILURE,
] = createRequestConstantNames(cc('FETCH_SEARCH_SI_LOCATIONS'));

export const SEARCH_LIMIT = 10;
