import { get, isNil } from 'lodash';

import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

const FloorPlanLinkSerializer = (
  inventoryPayloadItem: InventoryPayloadItem
): InventoryAttribute | null | undefined => {
  const floorUuid = get(inventoryPayloadItem, 'stargateFloorUuid');
  const locationCode = get(inventoryPayloadItem, 'location.code');
  const physicalSpaceUuid = get(inventoryPayloadItem, 'physicalSpaceUuid');
  const type = inventoryPayloadItem?.type;

  if (isNil(floorUuid) || isNil(locationCode)) return null;

  const baseUrl = `/inventory-manager/${locationCode}?initialFloorUuid=${floorUuid}`;
  const url = isNil(physicalSpaceUuid)
    ? baseUrl
    : `${baseUrl}&initialSelectSpace=${physicalSpaceUuid}`;

  return {
    value: String(floorUuid),
    meta: { type, url },
  };
};

export default FloorPlanLinkSerializer;
