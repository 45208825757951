import { combineReducers } from 'redux';

import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { Location } from 'store/modules/siLocations';
import { createRequestAction, createRequestReducer } from 'store/util';
import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';
import { RequestState } from 'store/util/createRequestReducer';
import { BaseAction, Dispatch } from 'store/types';

const DEFAULT_RADIUS = 40233; // default for nearby inventory == 25 miles

const searchQuery = `query SearchQuery($query: String!) {
  searchLocations(filters: {query: $query}) {
    data {
      id,
      name,
      identifiers {
        type,
        value,
      },
      defaultAddress {
        type,
        line1,
        line2,
        locality,
        dependentLocality,
        postalCode,
        countryISO3,
        country,
      }
    }
  }
}`;

const nearbySearchQuery = `query NearByQuery($nearbyFilter: NearByFilter!) {
  searchLocations(filters: {nearby: $nearbyFilter}) {
    data {
      id,
      name,
      identifiers {
        type,
        value,
      },
      defaultAddress {
        type,
        line1,
        line2,
        locality,
        dependentLocality,
        postalCode,
        countryISO3,
        country,
      }
    }
  }
}`;

const [
  SEARCH_LOCATIONS,
  SEARCH_LOCATIONS_SUCCESS,
  SEARCH_LOCATIONS_FAILURE,
] = createRequestConstantNames(cc('SEARCH_LOCATIONS'));

const [
  SEARCH_NEARBY_LOCATION,
  SEARCH_NEARBY_LOCATION_SUCCESS,
  SEARCH_NEARBY_LOCATION_FAILURE,
] = createRequestConstantNames(cc('SEARCH_NEARBY_LOCATION'));

export const searchLocations = (query: string) => async (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.siGateway.uri}`,
    types: [SEARCH_LOCATIONS, SEARCH_LOCATIONS_SUCCESS, SEARCH_LOCATIONS_FAILURE],
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'apollographql-client-name': 'spacestation-web',
    },
    body: {
      query: searchQuery,
      variables: {
        query,
      },
    },
  });

  return dispatch(requestAction);
};

export const fetchNearby = (latitude = 0, longitude = 0) => async (
  dispatch: Dispatch<BaseAction>
) => {
  const nearbyFilter = {
    latitude,
    longitude,
    radius: DEFAULT_RADIUS,
  };
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.siGateway.uri}`,
    types: [SEARCH_NEARBY_LOCATION, SEARCH_NEARBY_LOCATION_SUCCESS, SEARCH_NEARBY_LOCATION_FAILURE],
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'apollographql-client-name': 'spacestation-web',
    },
    body: {
      query: nearbySearchQuery,
      variables: {
        nearbyFilter,
      },
    },
  });
  return dispatch(requestAction);
};

export interface SearchLocationResponse {
  data: {
    searchLocations: {
      data: Array<Location>;
    };
  };
}

const searchReducer = createRequestReducer<Array<Location>, SearchLocationResponse>(
  [SEARCH_LOCATIONS, SEARCH_LOCATIONS_SUCCESS, SEARCH_LOCATIONS_FAILURE],
  [],
  res => res.payload?.data?.searchLocations?.data || []
);

const nearbyReducer = createRequestReducer<Array<Location>, SearchLocationResponse>(
  [SEARCH_NEARBY_LOCATION, SEARCH_NEARBY_LOCATION_SUCCESS, SEARCH_NEARBY_LOCATION_FAILURE],
  [],
  res => res.payload?.data?.searchLocations?.data || []
);

export type State = {
  search: RequestState<Array<Location>>;
  nearby: RequestState<Array<Location>>;
};

export type CurrentLocationSearchSubset = {
  currentLocationSearch: State;
};

export default combineReducers({
  search: searchReducer,
  nearby: nearbyReducer,
});
