// this component made for backward compatibility for components based on v1 react-select API
// for new components please use styled-react-select.tsx
import React, { CSSProperties } from 'react';
import { components, OptionsOrGroups, OptionProps, GroupBase } from 'react-select';
import type { Props as SelectProps } from 'react-select/dist/declarations/src';
import cn from 'classnames';

import StyledReactSelect from './styled-react-select';

type DefaultOption = {
  value: any | null;
  label: string;
  disabled?: boolean;
  className?: string;
};

type AllOptionsType<OptionType> = OptionsOrGroups<OptionType, GroupBase<OptionType>>;

type StyledReactSelectProps<OptionType, ValueType> = Omit<
  SelectProps<OptionType, false, any>,
  'options' | 'value'
> & {
  options?: AllOptionsType<OptionType>;
  disabled?: boolean;
  multi?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  backspaceRemoves?: boolean;
  style?: CSSProperties;
  value: ValueType;
  onOpen?: () => any;
};

export type ReactSelectProps<T = DefaultOption, V = unknown> = StyledReactSelectProps<T, V>;

const isOption = (option: DefaultOption | GroupBase<DefaultOption>): option is DefaultOption => {
  return option.hasOwnProperty('value');
};

function findOption<T extends DefaultOption>(options?: AllOptionsType<T>, value?: unknown) {
  if (!options) return;

  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (isOption(option) && option.value === value) return option;
  }
  return null;
}

function findOptions<T extends DefaultOption>(options: AllOptionsType<T> = [], value: T[]) {
  return value.reduce((acc: T[], v) => {
    const res = findOption(options, v);
    if (res) acc.push(res);
    return acc;
  }, []);
}

export const Option = ({ data, ...props }: OptionProps<any, false, any>): JSX.Element => {
  return (
    <components.Option
      data={data}
      {...props}
      isDisabled={Boolean(data.disabled)}
      className={cn(data.className)}
    >
      {props.children}
    </components.Option>
  );
};

const LegacyReactSelect = <T extends DefaultOption, V = unknown>({
  options,
  value,
  multi,
  disabled,
  clearable,
  searchable,
  isMulti,
  isDisabled,
  isClearable,
  isSearchable,
  backspaceRemoves,
  backspaceRemovesValue,
  style,
  styles,
  onOpen,
  ...props
}: StyledReactSelectProps<T, V>): JSX.Element => {
  const isMultiple = isMulti || multi;
  const selectedOption =
    isMultiple && Array.isArray(value) ? findOptions(options, value) : findOption(options, value);
  return (
    <StyledReactSelect
      isMulti={isMultiple}
      isDisabled={disabled || isDisabled}
      isClearable={clearable || isClearable}
      isSearchable={searchable || isSearchable}
      backspaceRemovesValue={backspaceRemoves || backspaceRemovesValue}
      styles={{
        container: provided => ({ ...provided, ...style }),
        ...styles,
      }}
      onMenuOpen={onOpen}
      {...props}
      options={options}
      value={selectedOption}
      components={{
        Option,
        ...props.components,
      }}
    />
  );
};

export default LegacyReactSelect;
