import { v4 as uuidv4 } from 'uuid';

export type DiscountInputAttrs = {
  termId?: string;
  startDate: string;
  endDate: string;
  amount: number;
  errors?: Hash<string>;
};

export type DiscountInput = {
  type?: string;
  id?: string;
  key: string;
  termId?: string;
  startDate: string;
  endDate: string;
  amount: number;
  errors: Hash<string>;
};

export type DiscountPayload = {
  type?: string;
  id?: string;
  term_id?: string;
  started_on: string;
  ended_on: string;
  amount: string;
};

export const toDiscountInput = (discount: DiscountPayload): DiscountInput => ({
  id: discount.id,
  key: uuidv4(),
  termId: discount.term_id,
  startDate: discount.started_on,
  endDate: discount.ended_on,
  type: discount.type,
  amount: Number(discount.amount),
  errors: {},
});

export const toDiscountPayload = (discount: DiscountInput): DiscountPayload => ({
  id: discount.id,
  term_id: discount.termId,
  started_on: discount.startDate,
  ended_on: discount.endDate,
  amount: String(discount.amount),
});
