import { endOfDay, startOfDay } from 'date-fns';

import config from 'config';
import { Location } from 'store/modules/locations';
import { formatDateWithTimeZone } from 'features/visitors/util';
import * as mappings from 'features/visitors/redux/serverMapping';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { AuthPrefix, httpFetcher } from 'lib/fetchAPI';

import { WelkioUser, CheckedInGuestTimeline, CheckedInGuest, Registration } from '../redux/types';

const welkioParams = (location: Location, date: Date) => ({
  location_id: location?.uuid,
  start_at: formatDateWithTimeZone(startOfDay(date), location),
  end_at: formatDateWithTimeZone(endOfDay(date), location),
  page: '1',
  per_page: '1000',
});

const fetcher = httpFetcher({
  domain: `${config.welkioAuthProxy.uri}/access/v2`,
  authPrefix: AuthPrefix.TOKEN,
  getAccessToken,
});

export const fetchUser = (id: string) => {
  return fetcher<WelkioUser>(`users/${id}`);
};

const fetchCheckedInGuests = (location: Location, date: Date) => {
  // We don't use the visits API because it doesn't expand the user property
  return fetcher<CheckedInGuestTimeline>('timeline', {
    ...welkioParams(location, date),
    expand: 'user,registration,photo',
  });
};

export const fetchCheckedInGuest = (id: string) => {
  return fetcher<CheckedInGuest>(`visits/${id}`, {
    expand: 'registration,photo',
  });
};

const fetchNonCheckedInGuests = (location: Location, date: Date) => {
  return fetcher<Array<Registration>>('registrations', {
    ...welkioParams(location, date),
    expand: 'host',
    visit: 'false',
  });
};

export const fetchNonCheckedInGuest = (id: string) => {
  return fetcher<Registration>(`registrations/${id}`, {
    expand: 'host',
  });
};

export const fetchGuests = async (location: Location, date: Date) => {
  const [checkedInGuests, nonCheckedInGuests] = await Promise.all([
    fetchCheckedInGuests(location, date),
    fetchNonCheckedInGuests(location, date),
  ]);

  return [
    ...checkedInGuests
      .filter(item => item.type === 'visit')
      .map(guest => mappings.checkedInGuest(location?.time_zone, guest)),
    ...nonCheckedInGuests.map(guest => mappings.nonCheckedInGuest(location?.time_zone, guest)),
  ];
};

export const getWelkioCsvPath = async csv => {
  const formData = new FormData();
  formData.append('file', csv);

  const response = await fetch(`${config.welkioUploads.uri}/v1/files/spreadsheets`, {
    method: 'POST',
    body: formData,
    headers: config.welkioAccess.xEnvAuthentication
      ? { 'x-env-authentication': config.welkioAccess.xEnvAuthentication }
      : undefined,
  });
  const { data } = await response.json();
  return data;
};
