import { Action, handleActions } from 'redux-actions';
import { groupBy } from 'lodash';

import { createRequestAction } from 'store/util';
import config from 'config';
import { BaseAction, BaseState, Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';
import { DiscountReason } from 'features/companies/addOnsSection/modals/resourceReservationModal/types';
import { SpacemanResponse } from 'types/api';

// Action Constants
export const FETCH_REASONS = cc('FETCH_REASONS');
export const FETCH_REASONS_SUCCESS = cc('FETCH_REASONS_SUCCESS');
export const FETCH_REASONS_FAIL = cc('FETCH_REASONS_FAIL');

type ReasonsResponse = SpacemanResponse<Array<DiscountReason>, { reason: {} }>;

export interface State extends BaseState {
  loading: boolean;
  loaded: boolean;
  error?: boolean | null | undefined;
  byReasonType: {
    [key: string]: Array<DiscountReason> | null;
  };
}

export interface ReasonsSubset {
  reasons: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  byReasonType: {
    operational_discount: null,
    billing_hold: null,
    cm_hold: null,
  },
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_REASONS]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_REASONS_SUCCESS]: (state: State, action: Action<ReasonsResponse>): State => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        byReasonType: {
          ...state.byReasonType,
          ...groupBy(action.payload.result, 'reason_type'),
        },
      };
    },

    [FETCH_REASONS_FAIL]: (state: State, action: Action<string>): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

// Action Creators
export const fetchReasons = () => (dispatch: Dispatch<BaseAction>) => {
  const url = `${config.spaceman.uri}/api/reasons`;
  const endpoint = `${url}`;

  const requestAction = createRequestAction<ReasonsResponse>({
    endpoint,
    types: [FETCH_REASONS, FETCH_REASONS_SUCCESS, FETCH_REASONS_FAIL],
  });

  return dispatch(requestAction);
};

export default reducer;
