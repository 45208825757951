import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import {
  MimoFilterGroup,
  MoveStatusFilterGroup,
  MoveTypeFilterGroup,
  OfficeTypeFilterGroup,
  OpenTasksFilterGroup,
} from 'features/mimo/mimoTracker-ray/components/mimoTrackerFilters/types';

import {
  CLEAR_ALL_MIMO_FILTERS,
  CLEAR_MIMO_CHIPS_FILTERS,
  CLEAR_FILTER_SESSION_ID,
  REFRESH_FILTER_SESSION_ID,
  SET_FILTER_ANALYTICS_IS_SENT,
  SET_MIMO_FILTER,
} from './constants';

type AnalyticsState = {
  filterSessionId: string | null | undefined;
  isSessionAnalyticsSent: boolean;
};

type FilterChipsState = {
  [MimoFilterGroup.MOVE_TYPE]: Array<MoveTypeFilterGroup> | null;
  [MimoFilterGroup.MOVE_STATUS]: Array<MoveStatusFilterGroup> | null;
  [MimoFilterGroup.OPEN_TASKS]: Array<OpenTasksFilterGroup> | null;
  [MimoFilterGroup.OFFICE_TYPE]: Array<OfficeTypeFilterGroup> | null;
  [MimoFilterGroup.FLOORS]: Array<string> | null;
};

export type State = FilterChipsState &
  AnalyticsState & {
    [MimoFilterGroup.SEARCH]: string | null | undefined;
  };

const filterChipsInitState: FilterChipsState = {
  [MimoFilterGroup.MOVE_TYPE]: null,
  [MimoFilterGroup.MOVE_STATUS]: null,
  [MimoFilterGroup.OPEN_TASKS]: null,
  [MimoFilterGroup.OFFICE_TYPE]: null,
  [MimoFilterGroup.FLOORS]: null,
};

const initialState: State = {
  filterSessionId: null,
  isSessionAnalyticsSent: false,
  [MimoFilterGroup.SEARCH]: null,
  ...filterChipsInitState,
};

const reducerDefinition = {
  [SET_FILTER_ANALYTICS_IS_SENT]: (state, { payload }) => ({
    ...state,
    isSessionAnalyticsSent: payload,
  }),
  [CLEAR_FILTER_SESSION_ID]: state => ({
    ...state,
    filterSessionId: null,
  }),
  [REFRESH_FILTER_SESSION_ID]: state => ({
    ...state,
    filterSessionId: uuidv4(),
    isSessionAnalyticsSent: false,
  }),
  [SET_MIMO_FILTER]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [CLEAR_ALL_MIMO_FILTERS]: state => ({
    ...state,
    [MimoFilterGroup.SEARCH]: '',
    ...filterChipsInitState,
  }),
  [CLEAR_MIMO_CHIPS_FILTERS]: state => ({
    ...state,
    ...filterChipsInitState,
  }),
};

const reducer = handleActions<State, any>(reducerDefinition, initialState);

export default reducer;
