import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export const rsfSerializer = (
  inventoryPayloadItem: InventoryPayloadItem
): InventoryAttribute | null | undefined => {
  const rsf = inventoryPayloadItem?.rentableSquareFootage;
  if (!rsf) return null;

  const pricePayload = inventoryPayloadItem?.price;
  const currency = pricePayload?.currency;
  const price = pricePayload?.amount ?? null;
  const pricePerRSF = price ? parseFloat((price / rsf).toFixed(2)) : null;
  const meta = pricePerRSF && price && currency ? { currency, price, pricePerRSF } : null;

  return {
    value: new Intl.NumberFormat().format(rsf),
    meta,
  };
};

export default rsfSerializer;
