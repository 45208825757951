import { createAction, handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

// Action Constants
export const UPDATE_LOCATION_TEXT = cc('UPDATE_LOCATION_TEXT');

export const setLocationSearchText = createAction<string>(UPDATE_LOCATION_TEXT);

export const reducer = handleActions<string>(
  {
    [UPDATE_LOCATION_TEXT]: (_state: string, { payload: locationSearchText }) => locationSearchText,
  },
  ''
);

export default reducer;
