import React from 'react';
import cn from 'classnames';
import { Text } from '@wework/dieter-ui';

import { Location as LocationType } from 'store/modules/siLocations';

import Location from '../location';

import styles from './index.scss';

const DEFAULT_EMPTY_TEXT = 'No locations found.';

const SectionHeader = ({
  className,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <header
    {...props}
    className={cn('bg-gray-97 font-mono text-label text-4xs text-gray-40 py-1.5 px-md', className)}
  />
);

type Props = {
  headerText?: string;
  emptyText?: string;
  query?: string;
  locations: Array<LocationType>;
  startPosition?: number;
  selectedIdx: number;
  onClickItem?: (location: LocationType) => void;
  className?: string;
};

const Section: React.FC<Props> = ({
  headerText,
  emptyText = DEFAULT_EMPTY_TEXT,
  locations,
  query,
  startPosition = 0,
  selectedIdx,
  onClickItem,
  className,
  ...props
}) => (
  <div {...props} className={cn(styles.section, className)}>
    {!!headerText && <SectionHeader>{headerText}</SectionHeader>}
    <div className={styles.locations}>
      {locations.length ? (
        locations.map((location, index) => (
          <Location
            location={location}
            query={query}
            selected={index === selectedIdx - startPosition}
            key={index}
            onClick={() => {
              if (onClickItem) onClickItem(location);
            }}
          />
        ))
      ) : (
        <Text className={styles.emptyText}>{emptyText}</Text>
      )}
    </div>
  </div>
);

export default Section;
