import { handleActions, Action } from 'redux-actions';
import { reject, includes, get } from 'lodash';
import { change } from 'redux-form';

import { ThunkAction, BaseAction, Dispatch, GetGlobalState } from 'store/types';
import cc from 'store/util/createReduxConstant';
// actions
export const SUBSCRIBE_TO_FORM = cc('SUBSCRIBE_TO_FORM');
export const UNSUBSCRIBE_FROM_FORM = cc('UNSUBSCRIBE_FROM_FORM');
export const PUSH_FORM_HISTORY = cc('PUSH_FORM_HISTORY');
export const POP_FORM_HISTORY = cc('POP_FORM_HISTORY');
export const RESET_FORM_HISTORY = cc('RESET_FORM_HISTORY');

// types
type HistoricalState = {
  field: string;
  value: string | null | undefined;
};

interface ReduxFormUndoState {
  formSubscriptions: Array<string>;
  historyByForm: Hash<Array<HistoricalState>>;
}

export interface ReduxFormUndoSubset {
  reduxFormUndo: ReduxFormUndoState;
}

type FormAction = Action<null> & {
  form: string;
};

type SubscribeAction = FormAction;
type UnsubscribeAction = FormAction;
type PopFormHistoryAction = FormAction;
type ResetFormHistoryAction = FormAction;

type PushFormHistoryAction = FormAction & {
  formState: HistoricalState;
};

// type FormActions =
//   | SubscribeAction
//   | UnsubscribeAction
//   | PushFormHistoryAction
//   | PopFormHistoryAction
//   | ResetFormHistoryAction;

export const initialState: ReduxFormUndoState = {
  formSubscriptions: [],
  historyByForm: {},
};

const maxHistorySize = 10;

// reducer
export const reducer = handleActions<ReduxFormUndoState, any>(
  {
    [SUBSCRIBE_TO_FORM]: (
      state: ReduxFormUndoState,
      action: SubscribeAction
    ): ReduxFormUndoState => ({
      ...state,
      formSubscriptions: includes(state.formSubscriptions, action.form)
        ? state.formSubscriptions
        : [...state.formSubscriptions, action.form],
    }),

    [UNSUBSCRIBE_FROM_FORM]: (
      state: ReduxFormUndoState,
      action: UnsubscribeAction
    ): ReduxFormUndoState => ({
      ...state,
      formSubscriptions: reject(state.formSubscriptions, form => form === action.form),
    }),

    [PUSH_FORM_HISTORY]: (
      state: ReduxFormUndoState,
      action: PushFormHistoryAction
    ): ReduxFormUndoState => {
      const historicalWindow = get(state.historyByForm, action.form, []).slice(
        0,
        maxHistorySize - 1
      );

      return {
        ...state,
        historyByForm: {
          ...state.historyByForm,
          [action.form]: [action.formState, ...historicalWindow],
        },
      };
    },

    [POP_FORM_HISTORY]: (
      state: ReduxFormUndoState,
      action: PopFormHistoryAction
    ): ReduxFormUndoState => ({
      ...state,
      historyByForm: {
        ...state.historyByForm,
        [action.form]: get(state.historyByForm, action.form, []).slice(1),
      },
    }),

    [RESET_FORM_HISTORY]: (
      state: ReduxFormUndoState,
      action: ResetFormHistoryAction
    ): ReduxFormUndoState => ({
      ...state,
      historyByForm: { ...state.historyByForm, [action.form]: [] },
    }),
  },
  initialState
);

export default reducer;

// private action creators

export const pushFormHistory = (
  form: string,
  formState: HistoricalState
): PushFormHistoryAction => ({
  type: PUSH_FORM_HISTORY,
  form,
  formState,
  payload: null,
});

export const resetFormHistory = (form: string): ResetFormHistoryAction => ({
  type: RESET_FORM_HISTORY,
  form,
  payload: null,
});

export const popFormHistory = (form: string): PopFormHistoryAction => ({
  type: POP_FORM_HISTORY,
  form,
  payload: null,
});

export type Subscribe = (form: string) => SubscribeAction;
export type Unsubscribe = (form: string) => UnsubscribeAction;
export type Undo = (form: string) => ThunkAction<BaseAction>;

// public action creators
type Actions = {
  subscribe: Subscribe;
  unsubscribe: Unsubscribe;
  undo: Undo;
};

export const actions: Actions = {
  subscribe: form => ({ type: SUBSCRIBE_TO_FORM, form, payload: null }),
  unsubscribe: form => ({ type: UNSUBSCRIBE_FROM_FORM, form, payload: null }),
  undo: form => (dispatch: Dispatch<BaseAction>, getState: GetGlobalState): void => {
    const peekHistorical: HistoricalState | undefined = (getState().reduxFormUndo.historyByForm[
      form
    ] ?? [])[0];

    if (!peekHistorical) {
      return;
    }

    dispatch(popFormHistory(form));
    dispatch(
      (change as (
        form: string,
        field: string,
        value: any,
        touch?: boolean,
        persistentSubmitErrors?: boolean
      ) => FormAction)(form, peekHistorical.field, peekHistorical.value, true, false)
    );
    // calling change will cause the middleware to push the current state back onto history.
    // We need to remove this event from the store so everything works as expected
    dispatch(popFormHistory(form));
  },
};

// selectors
export const getSubscriptions = (state: ReduxFormUndoSubset) =>
  state.reduxFormUndo.formSubscriptions;
