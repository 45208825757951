import React from 'react';
import cn from 'classnames';

import { EmojiName } from './emojiName';
import { EmojiMap } from './emojiMap';
import { EmojiAriaLabelMap } from './emojiAriaLabelMap';

export interface Props {
  emoji: EmojiName;
  className?: string;
}

const Emoji = ({ emoji, className }: Props) => {
  return (
    <img
      src={EmojiMap[emoji]}
      aria-label={EmojiAriaLabelMap[emoji]}
      alt=""
      className={cn(className, 'align-top')}
    />
  );
};

export default Emoji;
