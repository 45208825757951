import { handleActions, ReducerMap, Action } from 'redux-actions';

import {
  BuildingSettings,
  MoveTimes,
  MMPBuildingSetting,
  MMPBuildingSettings,
  MMPFurnitureAvailability,
} from 'features/mimo/types';
import {
  FETCH_MOVE_TIMES_SETTINGS,
  FETCH_MOVE_TIMES_SETTINGS_SUCCESS,
  FETCH_MOVE_TIMES_SETTINGS_FAILURE,
  FETCH_MMP_BUILDING_SETTINGS,
  FETCH_MMP_BUILDING_SETTINGS_SUCCESS,
  FETCH_MMP_BUILDING_SETTINGS_FAILURE,
  LoadState,
  SET_MOVE_TIMES_SETTINGS_SUCCESS,
  SET_MMP_BUILDING_SETTINGS_SUCCESS,
  SET_SETTINGS_LOCATION_UUID,
} from 'features/mimo/redux/buildingSettings/constants';
import { MOVE_IN_DEFAULT_TIME, MOVE_OUT_DEFAULT_TIME } from 'features/mimo/constants';

export interface State {
  locationUuid: string | null;
  settings: BuildingSettings;
  loadingState: { [K in keyof BuildingSettings]: LoadState };
}

export const INITIAL_MOVE_TIMES = Object.freeze({
  move_in_time: MOVE_IN_DEFAULT_TIME,
  move_out_time: MOVE_OUT_DEFAULT_TIME,
});

const INITIAL_MMP_SETTINGS = Object.freeze({
  [MMPBuildingSetting.Enabled]: false,
  [MMPBuildingSetting.Freight]: false,
  [MMPBuildingSetting.COITemplate]: null,
  [MMPBuildingSetting.COIRequirement]: [],
  [MMPBuildingSetting.Furniture]: ['chair', 'desk', 'cabinet'] as MMPFurnitureAvailability[],
});

const initialState: State = {
  locationUuid: null,
  settings: {
    moveTimes: INITIAL_MOVE_TIMES,
    mmp: INITIAL_MMP_SETTINGS,
  },
  loadingState: {
    moveTimes: LoadState.LOADING,
    mmp: LoadState.LOADING,
  },
};

const reducerDefinition: ReducerMap<State, string | MoveTimes | MMPBuildingSettings> = {
  [FETCH_MOVE_TIMES_SETTINGS]: state => ({
    ...state,
    settings: {
      ...state.settings,
      moveTimes: INITIAL_MOVE_TIMES,
    },
    loadingState: {
      ...state.loadingState,
      moveTimes: LoadState.LOADING,
    },
  }),
  [FETCH_MOVE_TIMES_SETTINGS_SUCCESS]: (state, { payload: moveTimes }: Action<MoveTimes>) => ({
    ...state,
    settings: {
      ...state.settings,
      moveTimes,
    },
    loadingState: {
      ...state.loadingState,
      moveTimes: LoadState.SUCCESS,
    },
  }),
  [FETCH_MOVE_TIMES_SETTINGS_FAILURE]: state => ({
    ...state,
    loadingState: {
      ...state.loadingState,
      moveTimes: LoadState.FAILURE,
    },
  }),
  [SET_MOVE_TIMES_SETTINGS_SUCCESS]: (state, { payload: moveTimes }: Action<MoveTimes>) => ({
    ...state,
    settings: {
      ...state.settings,
      moveTimes,
    },
  }),
  [FETCH_MMP_BUILDING_SETTINGS]: state => ({
    ...state,
    settings: {
      ...state.settings,
      mmp: INITIAL_MMP_SETTINGS,
    },
    loadingState: {
      ...state.loadingState,
      mmp: LoadState.LOADING,
    },
  }),
  [FETCH_MMP_BUILDING_SETTINGS_SUCCESS]: (
    state,
    { payload: mmp }: Action<MMPBuildingSettings>
  ) => ({
    ...state,
    settings: {
      ...state.settings,
      mmp,
    },
    loadingState: {
      ...state.loadingState,
      mmp: LoadState.SUCCESS,
    },
  }),
  [FETCH_MMP_BUILDING_SETTINGS_FAILURE]: state => ({
    ...state,
    settings: {
      ...state.settings,
    },
    loadingState: {
      ...state.loadingState,
      mmp: LoadState.FAILURE,
    },
  }),
  [SET_MMP_BUILDING_SETTINGS_SUCCESS]: (state, { payload: mmp }: Action<MMPBuildingSettings>) => ({
    ...state,
    settings: {
      ...state.settings,
      mmp,
    },
  }),
  [SET_SETTINGS_LOCATION_UUID]: (state, { payload: locationUuid }: Action<string>) => ({
    ...state,
    locationUuid,
  }),
};

const reducer = handleActions(reducerDefinition, initialState);

export default reducer;
