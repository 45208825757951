import { handleActions, Action } from 'redux-actions';

import {
  FETCH_EMAIL_LOCALES,
  FETCH_EMAIL_LOCALES_SUCCESS,
  FETCH_EMAIL_LOCALES_FAIL,
  SELECT_EMAIL_LOCALE,
} from 'features/mimo/redux/emailLocales/constants';
import { State, Locale } from 'features/mimo/redux/emailLocales/types';

// Initial State
const initialState = {
  loading: false,
  loaded: false,
  data: [],
  error: false,
  selected: null,
};

export const reducer = handleActions<State, Array<Locale> | string>(
  {
    [FETCH_EMAIL_LOCALES]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: false,
    }),

    [FETCH_EMAIL_LOCALES_SUCCESS]: (state, { payload: data }: Action<Array<Locale>>) => ({
      ...state,
      loading: false,
      loaded: true,
      data,
    }),

    [FETCH_EMAIL_LOCALES_FAIL]: state => ({
      ...state,
      loading: false,
      loaded: false,
      error: true,
    }),

    [SELECT_EMAIL_LOCALE]: (state, { payload: selected }: Action<string>) => ({
      ...state,
      selected,
    }),
  },
  initialState
);

export default reducer;
