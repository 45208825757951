import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { createRequestAction, createRequestReducer } from 'store/util';
import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';
import { BaseAction, Dispatch } from 'store/types';
import { BuildingPayloadItemLocation } from 'features/inventorySearch/config/types';
import { Building } from 'features/inventorySearch/types';
import { normalizeLocations } from 'features/inventorySearch/serializers/locationSerializer';

const searchQuery = `query SearchQuery($query: String, $ids: [String!]) {
  searchLocations(filters: {query: $query, ids: $ids}) {
    data {
      id,
      name,
      identifiers {
        type,
        value,
      },
      addresses {
        type,
        latitude,
        longitude
      }
    }
  }
}`;

const [
  SEARCH_LOCATIONS,
  SEARCH_LOCATIONS_SUCCESS,
  SEARCH_LOCATIONS_FAILURE,
] = createRequestConstantNames(cc('SEARCH_LOCATIONS_FILTER'));

export const searchLocations = (query?: string, ids?: Array<string>) => async (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.siGateway.uri}`,
    types: [SEARCH_LOCATIONS, SEARCH_LOCATIONS_SUCCESS, SEARCH_LOCATIONS_FAILURE],
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'apollographql-client-name': 'spacestation-web',
    },
    body: {
      query: searchQuery,
      variables: {
        ...(query && { query }),
        ...(ids && { ids }),
      },
    },
  });
  return dispatch(requestAction);
};

export interface Payload {
  data: {
    searchLocations: {
      data: Array<BuildingPayloadItemLocation>;
    };
  };
}

const reducer = createRequestReducer<Record<string, Building>, Payload>(
  [SEARCH_LOCATIONS, SEARCH_LOCATIONS_SUCCESS, SEARCH_LOCATIONS_FAILURE],
  {},
  res => {
    const locationArr = normalizeLocations(res.payload);
    return locationArr.reduce((rec, bld) => {
      rec[bld.id] = bld;
      return rec;
    }, {});
  }
);

export default reducer;
