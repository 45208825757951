import { createSelector } from 'reselect';

import { Location } from 'store/modules/locations';
import { getLocations } from 'store/selectors';

import { MemberCellProps } from './member/types';
import { CompanyCellProps } from './company/types';

export const getFormattedLocations = (locations: Array<Location>) =>
  locations.reduce(
    (locationString, { name }, index) => (index === 0 ? name : `${locationString}, ${name}`),
    ''
  );

export const getLocationsByUuid = (locations: Array<Location>, locationUuids: Array<string>) =>
  locations.filter(({ uuid }) => locationUuids.includes(uuid));

const getMemberLocationUUIDs = (_, props: MemberCellProps) => props.member?.location_uuids;

export const getMemberPrimaryLocation = createSelector(
  [getLocations, getMemberLocationUUIDs],
  (locations, locationUuids) =>
    locations && locationUuids && getLocationsByUuid(locations, locationUuids)[0]
);

export const getMemberFormattedLocations = createSelector(
  [getLocations, getMemberLocationUUIDs],
  (locations, locationUuids) =>
    locations && locationUuids
      ? getFormattedLocations(getLocationsByUuid(locations, locationUuids))
      : ''
);

const getCompanyLocationUUIDs = (_, props: CompanyCellProps) => props.company?.location_uuids;

export const getCompanyFormattedLocations = createSelector(
  [getLocations, getCompanyLocationUUIDs],
  (locations, locationUuids) =>
    locations && locationUuids
      ? getFormattedLocations(getLocationsByUuid(locations, locationUuids))
      : ''
);
