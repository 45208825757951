import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, FormField, Modal } from '@wework-dev/plasma';
import { isNil } from 'lodash';
import Formsy from 'formsy-react';

import LocationSelect from 'components/inputs/location';
import Trackable, { TrackableType } from 'components/decorators/trackable';
import { fetchCurrentLocationById } from 'store/modules/siLocations';
import { saveLocationAsDefault } from 'features/auth/actions';
import { getUserInfoFromAuth } from 'features/auth/selectors';
import { getLocations } from 'store/selectors';
import { ReduxProps } from 'store/types';

import styles from './editHomeLocationModal.scss';

const mapStateToProps = state => {
  const userInfo = getUserInfoFromAuth(state);

  return {
    homeLocationUuid: userInfo.locationUuid ?? '',
    submitting: state.employees.posting,
    locations: getLocations(state),
  };
};

const mapDispatchToProps = {
  fetchCurrentLocationById,
  saveLocationAsDefault,
};

type InterfaceProps = Readonly<{
  isOpen: boolean;
  onDismiss: () => void;
}>;

type Props = Readonly<
  TrackableType & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & InterfaceProps
>;

export const EditHomeLocationModal = (props: Props) => {
  const {
    homeLocationUuid,
    onDismiss,
    isOpen,
    locations,
    saveLocationAsDefault,
    fetchCurrentLocationById,
    submitting,
    trackInteraction,
  } = props;
  const [locationUuid, setLocationUuid] = useState<string | undefined>(homeLocationUuid);

  const dismiss = () => {
    setLocationUuid(undefined);
    onDismiss();
  };

  const handleDismiss = () => {
    trackInteraction('Close Modal');
    dismiss();
  };

  const dirty = () => locationUuid && locationUuid !== homeLocationUuid;

  const save = () => {
    trackInteraction('Save');

    saveLocationAsDefault({ uuid: locationUuid ?? '' }).then(() => {
      if (!isNil(locationUuid)) {
        fetchCurrentLocationById(locationUuid);
      }
      dismiss();
    });
  };

  const onSelectLocation = (location?: { uuid: string; name: string }): void => {
    if (!location) {
      return setLocationUuid(undefined);
    }

    trackInteraction('Select Location', {
      value: location.uuid,
    });

    setLocationUuid(location.uuid);
  };

  return (
    <Modal onDismiss={handleDismiss} title="Set your default location" visible={isOpen}>
      <p className={styles.description}>
        This is where you select the location (building) you will use as your "homebase." You can
        always change it later.
      </p>
      <Formsy>
        <div className={styles.form}>
          <FormField data={{ test: 'change-home-location' }} label="Default location">
            <LocationSelect
              value={homeLocationUuid}
              className={styles.noPadding}
              locations={locations}
              name="locationUuid"
              sort
              required
              onSelect={onSelectLocation}
              loading={!locations.length}
            />
          </FormField>
        </div>
      </Formsy>
      <div className={styles.actions}>
        {homeLocationUuid && (
          <Button
            type="secondary"
            onClick={handleDismiss}
            disabled={!homeLocationUuid || submitting}
          >
            Close
          </Button>
        )}
        <Button type="primary" onClick={save} disabled={!locationUuid || !dirty() || submitting}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default compose<React.ComponentType<InterfaceProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  Trackable({
    workflow: 'changeSettings',
    featureContext: 'modal.settings',
    feature: 'changeHomeLocation',
  })
)(EditHomeLocationModal);
