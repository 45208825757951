import NotifyPusherClient from 'features/notifications/pusher/notifyPusherClient';
import { PusherEvent } from 'features/notifications/pusher/constants';
import { Notification } from 'lib/api/notify/types';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { assertIsDefined } from 'lib/assert';

class Handler {
  pusher: NotifyPusherClient;

  constructor(channelName: string, accessToken: string) {
    this.pusher = new NotifyPusherClient(channelName, accessToken);
  }

  registerEventHandler(event: PusherEvent, handler: (notification: Notification) => void) {
    this.pusher.bind(event, handler);
  }

  connect() {
    this.pusher.subscribe();
  }

  disconnect() {
    this.pusher.unbind(PusherEvent.NOTIFICATION);
    this.pusher.unsubscribe();
  }
}

export const notificationsHandler = async (
  channelName: string,
  employeeUuid: string
): Promise<Handler> => {
  const accessToken = await getAccessToken();

  assertIsDefined(employeeUuid);

  return new Handler(channelName, accessToken);
};
