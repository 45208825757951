import { createAction } from 'redux-actions';

import { SEARCH_SET_FILTER_EVENT, SEARCH_SET_FILTER_EVENT_LABEL } from 'features/search/constants';

import { createAnalyticsMetadata } from '../analytics';

import {
  SEARCH,
  SEARCH_FAIL,
  SEARCH_RESET,
  SEARCH_SUCCESS,
  SET_SEARCH_FILTER,
  SEARCH_ENRICHMENT_USER_SPACES,
  SEARCH_CHECK_IN_GUEST_SUCCESS,
} from './constants';

export const search = createAction(SEARCH, null, (query: string) => ({
  query,
}));
export const searchReset = createAction(SEARCH_RESET);
export const searchFail = createAction(SEARCH_FAIL, null, (_: Error, query: string) => ({ query }));
export const searchSuccess = createAction(SEARCH_SUCCESS, null, (_: any, query: string) => ({
  query,
}));

export const searchEnrichmentUserSpaces = createAction(SEARCH_ENRICHMENT_USER_SPACES);

export const setSearchFilter = createAction(
  SET_SEARCH_FILTER,
  (filterName: string, value: boolean) => ({
    filterName,
    value,
  }),
  (filterName: string, value: boolean, meta: { details: {} }) => ({
    analytics: createAnalyticsMetadata(SEARCH_SET_FILTER_EVENT, {
      label: SEARCH_SET_FILTER_EVENT_LABEL,
      action: 'click',
      object: 'change filter in search',
      screen_name: 'Global Search',
      feature_group: 'Global Search',
      target_text: filterName,
      target_value: value.toString(),
      details: meta.details,
    }),
  })
);

export const searchGuestCheckInSuccess = createAction(
  SEARCH_CHECK_IN_GUEST_SUCCESS,
  (visitId: string, checkInTime: Date) => ({
    visitId,
    checkInTime,
  })
);
