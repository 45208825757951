export interface Option {
  value: string | number;
  text?: string;
  onClick?: (_: React.MouseEvent, data: Option) => void;
}

export enum VisibilityUpdateOnType {
  REPAINT = 'repaint',
  EVENTS = 'events',
}
