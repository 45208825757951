import { handleActions, ActionMeta, Action } from 'redux-actions';

import {
  ARCHIVE_OCCUPIABLE,
  ARCHIVE_OCCUPIABLE_FAIL,
  ARCHIVE_OCCUPIABLE_SUCCESS,
  BULK_CREATE_OCCUPIABLES,
  BULK_CREATE_OCCUPIABLES_FAIL,
  BULK_CREATE_OCCUPIABLES_SUCCESS,
  BULK_UPDATE_OCCUPIABLES,
  BULK_UPDATE_OCCUPIABLES_FAIL,
  BULK_UPDATE_OCCUPIABLES_SUCCESS,
  FETCH_OCCUPIABLES_BY_LOCATION,
  FETCH_OCCUPIABLES_BY_LOCATION_FAIL,
  FETCH_OCCUPIABLES_BY_LOCATION_SUCCESS,
  FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS,
  FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_SUCCESS,
  FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_FAIL,
  PUBLISH_OCCUPIABLE,
  PUBLISH_OCCUPIABLE_FAIL,
  PUBLISH_OCCUPIABLE_SUCCESS,
  REFRESH_OCCUPIABLE,
  REFRESH_OCCUPIABLE_FAIL,
  REFRESH_OCCUPIABLE_SUCCESS,
  SAVE_OCCUPIABLE,
  SAVE_OCCUPIABLE_FAIL,
  SAVE_OCCUPIABLE_SUCCESS,
  UNARCHIVE_OCCUPIABLE,
  UNARCHIVE_OCCUPIABLE_FAIL,
  UNARCHIVE_OCCUPIABLE_SUCCESS,
  UPDATE_OCCUPIABLE_NOTES,
  UPDATE_OCCUPIABLE_NOTES_FAIL,
  UPDATE_OCCUPIABLE_NOTES_SUCCESS,
} from 'store/modules/occupiables/constants';
import {
  IMSOccupiableGet,
  SpacemanApiOccupiablesGetResponse,
  State,
} from 'store/modules/occupiables/types';

type FailAction = Action<string>;

export type { OccupiablesSubset } from './types';

// Initial State
const initialState: State = {
  byLocationUuid: {},
  byLocationUuidFromIMS: [],
  error: null,
  loaded: false,
  loading: false,
  posted: false,
  posting: false,
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_OCCUPIABLES_BY_LOCATION]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_OCCUPIABLES_BY_LOCATION_SUCCESS]: (
      state: State,
      action: ActionMeta<SpacemanApiOccupiablesGetResponse, { locationUuid: string }>
    ): State => {
      const { locationUuid } = action.meta;

      const occupiables = action.payload.map(occupiable => ({
        ...occupiable,
        location_uuid: locationUuid,
      }));

      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        byLocationUuid: {
          ...state.byLocationUuid,
          [locationUuid]: occupiables,
        },
      };
    },
    [FETCH_OCCUPIABLES_BY_LOCATION_FAIL]: (
      state: State,
      action: ActionMeta<string, { locationUuid: string }>
    ): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload,
    }),
    [FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_SUCCESS]: (
      state: State,
      action: ActionMeta<IMSOccupiableGet, { floorUuid: string }>
    ): State => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        byLocationUuidFromIMS: [...state.byLocationUuidFromIMS, ...action.payload.data],
      };
    },
    [FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_FAIL]: (
      state: State,
      action: ActionMeta<string, { floorUuid: string }>
    ): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload,
    }),
    [SAVE_OCCUPIABLE]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [SAVE_OCCUPIABLE_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [SAVE_OCCUPIABLE_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
    [ARCHIVE_OCCUPIABLE]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [ARCHIVE_OCCUPIABLE_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [ARCHIVE_OCCUPIABLE_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
    [UNARCHIVE_OCCUPIABLE]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [UNARCHIVE_OCCUPIABLE_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [UNARCHIVE_OCCUPIABLE_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
    [PUBLISH_OCCUPIABLE]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [PUBLISH_OCCUPIABLE_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [PUBLISH_OCCUPIABLE_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
    [REFRESH_OCCUPIABLE]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [REFRESH_OCCUPIABLE_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [REFRESH_OCCUPIABLE_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
    [UPDATE_OCCUPIABLE_NOTES]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [UPDATE_OCCUPIABLE_NOTES_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [UPDATE_OCCUPIABLE_NOTES_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
    [BULK_CREATE_OCCUPIABLES]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [BULK_CREATE_OCCUPIABLES_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [BULK_CREATE_OCCUPIABLES_FAIL]: (state: State, action): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
    [BULK_UPDATE_OCCUPIABLES]: (state: State): State => ({
      ...state,
      posting: true,
      posted: false,
      error: null,
    }),
    [BULK_UPDATE_OCCUPIABLES_SUCCESS]: (state: State): State => ({
      ...state,
      posting: false,
      posted: true,
      error: null,
    }),
    [BULK_UPDATE_OCCUPIABLES_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      posting: false,
      posted: false,
      error: action.payload,
    }),
  },
  initialState
);

export default reducer;
