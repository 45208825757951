export enum EmojiName {
  BALLOON = 'balloonEmoji',
  BUILDING = 'buildingEmoji',
  CALENDAR = 'calendarEmoji',
  CHECK_BOX_GREY = 'checkBoxGreyEmoji',
  COCKTAIL_GLASS = 'cocktailGlassEmoji',
  COUCH_WITH_LAMP = 'couchWithLampEmoji',
  EYES = 'eyesEmoji',
  FACE_WITH_CROSSED_EYES = 'faceWithCrossedEyesEmoji',
  MAGNIFYING_GLASS = 'magnifyingGlassEmoji',
  MEDAL = 'medailEmoji',
  MEMO = 'memoEmoji',
  MONEY_BAG = 'moneyBagEmoji',
  PAGE_FACING_UP = 'pageFacingUpEmoji',
  PALETTE = 'paletteEmoji',
  PARTY_POPPER = 'partyPopperEmoji',
  PENSIVE_FACE = 'pensiveFaceEmoji',
  POINTING_UP = 'pointingUpEmoji',
  SEE_NO_EVIL_MONKEY = 'seeNoEvilMonkeyEmoji',
  SLIGHTLY_FROWNING_FACE = 'slightlyFrowningFaceEmoji',
  SMILING_FACE_WITH_OPEN_HANDS = 'smilingFaceWithOpenHandsEmoji',
  SPARKLE = 'sparkleEmoji',
  SPEAK_NO_EVIL_MONKEY = 'speakNoEvilMonkeyEmoji',
  THINKING_FACE = 'thinkingFaceEmoji',
  THREE_O_CLOCK = 'threeOClockEmoji',
  THUMBS_UP = 'thumbsUpEmoji',
  TRIANGULAR_FLAG = 'triangularFlagEmoji',
  TRUCK = 'truckEmoji',
  TWO_PEOPLE = 'twoPeopleEmoji',
  WARNING = 'warningEmoji',
  WAVING_HAND = 'wavingHand',
  MEGAPHONE = 'megaphoneEmoji',
  NOTIFICATION_BELL = 'notificationBellEmoji',
}
