import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Modal, Button, TextArea, Form } from '@wework/dieter-ui';

import { Validators } from 'lib/validations';
import createFormField from 'components/formFields/createFormField';
import MemberSearchAutoComplete from 'components/inputs/memberSearchAutocomplete';
import { Member } from 'features/locations/buildingDirectory/types';

import { MAX_BODY_LENGTH, SEND_MEMBER_NOTIFICATION_FORM_NAME } from '../constants';

const FORM_NAME = 'SEND_NOTIFICATION';

export type FormData = {
  type: string;
  body: string;
  member: Member | null | undefined;
};

interface OwnProps {
  sendMemberNotification: () => void;
  cancelMemberNotification: () => void;
  member?: Member;
  currentLocationUuid: string;
  onSelectMember?: (arg0: Member) => void;
  showMemberSearchInput?: boolean;
}

type Props = Readonly<OwnProps & InjectedFormProps<FormData, OwnProps>>;

const { notBlank, maxLength } = Validators;

const bodyValidators = [notBlank, maxLength(MAX_BODY_LENGTH)];

const ReduxTextField = field => {
  return (
    <Form.Field
      label="Message"
      name="body"
      control={TextArea}
      disabled={field.disabled}
      outline
      rows="4"
      maxLength={250}
      onChange={field.input.onChange}
      value={field.input.value}
    />
  );
};

const SendMemberNotificationForm = (props: Props) => {
  const {
    cancelMemberNotification,
    sendMemberNotification,
    member,
    currentLocationUuid,
    onSelectMember,
    showMemberSearchInput,
  } = props;

  const onChangeMemberSearch = (_evt, member) => {
    onSelectMember && onSelectMember(member);
  };

  return (
    <>
      <Modal.Content>
        <Form id={FORM_NAME} onSubmit={sendMemberNotification}>
          {showMemberSearchInput && (
            <Field
              component={createFormField(MemberSearchAutoComplete)}
              searchOptions={{ locations: [currentLocationUuid] }}
              placeholder="Choose a member"
              name="member"
              label="Recipient Name"
              validate={notBlank}
              onChange={onChangeMemberSearch}
            />
          )}
          <Field
            name="body"
            component={ReduxTextField}
            disabled={!member}
            validate={bodyValidators}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic primary onClick={cancelMemberNotification}>
          Cancel
        </Button>
        <Button primary type="submit" disabled={!member} form={FORM_NAME}>
          Send
        </Button>
      </Modal.Actions>
    </>
  );
};

export default reduxForm<FormData, OwnProps>({
  form: SEND_MEMBER_NOTIFICATION_FORM_NAME,
  enableReinitialize: true,
})(SendMemberNotificationForm);
