import { get, isNil } from 'lodash';

import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export const squareFootageSerializer = (
  inventoryPayloadItem: InventoryPayloadItem,
  serializerKey: string
): InventoryAttribute | null | undefined => {
  const attribute = get(inventoryPayloadItem, serializerKey);

  if (isNil(attribute)) {
    return null;
  }

  const value = new Intl.NumberFormat().format(attribute);

  return { value, meta: { removeFromDrawer: inventoryPayloadItem.displayType === 'Full Floor' } };
};

export default squareFootageSerializer;
