import React, { useMemo } from 'react';
import { Popup, Text } from '@wework/dieter-ui';

import Cell, { Props as CellProps } from 'features/search/resultSets/cell';

import styles from './officeNumbers.scss';

type OfficesToDisplay = {
  text: string;
  tooltipText: string | null;
};
export const MAX_OFFICES_TO_SHOW_INLINE = 6;

const getOfficesToDisplay = (offices: Array<string>): OfficesToDisplay => {
  const allOfficesText = offices.join(', ');

  if (offices.length > MAX_OFFICES_TO_SHOW_INLINE) {
    return {
      text: `${offices.slice(0, MAX_OFFICES_TO_SHOW_INLINE).join(', ')}...`,
      tooltipText: allOfficesText,
    };
  }

  return {
    text: allOfficesText,
    tooltipText: null,
  };
};

type Props = { offices: Array<string> } & CellProps;

const OfficeNumbersCell: React.FC<Props> = ({ offices, inactive = true, ...cellProps }) => {
  const { text, tooltipText } = useMemo(() => getOfficesToDisplay(offices), [offices]);
  const textElement = <p className="text-black text-3xs">{text}</p>;

  return (
    <Cell inactive={inactive} {...cellProps}>
      {tooltipText ? (
        <Popup
          trigger={textElement}
          content={
            <Text className={styles.popupText} size="tiny">
              {tooltipText}
            </Text>
          }
          size="tiny"
          on="hover"
          position="top center"
          hoverable
        />
      ) : (
        textElement
      )}
    </Cell>
  );
};

export default OfficeNumbersCell;
