import { pick } from 'lodash';

import {
  MemberSearchResultItem,
  AlgoliaMember,
  MemberMapperKeys,
} from 'store/modules/search/searchService/types';
import config from 'config';

import { Mapper } from './types';

const FIELD_LOCATION = 'location_uuids';
const FIELD_UUID = 'uuid';
const FIELD_STATUS = 'status';
const FIELD_LICENSE = 'is_active_licensee';
const LOCATIONS = 'locations';
const ACTIVE = 'active';
const ACTIVE_LICENSE = 'activeLicense';

const MEMBER_FIELDS: Array<MemberMapperKeys> = [
  'uuid',
  'name',
  'location_uuids',
  'email',
  'companies',
  'past_companies',
  'status',
  'kind',
  'normalized_phone',
  'is_licensee',
  'objectID',
  'company_names',
  'is_active_licensee',
  'memberships',
];

interface Filter {
  field: string;
  values: Array<string>;
  isOptional?: boolean;
}

const mapper = filters => {
  const ret: Array<Filter> = [];

  const optionalFilters = filters.optionalFilters || [];
  filters.locations &&
    ret.push({
      field: FIELD_LOCATION,
      values: filters.locations,
      isOptional: optionalFilters.includes(LOCATIONS),
    });

  if (filters.ids) {
    ret.push({ field: FIELD_UUID, values: filters.ids });
  }

  if (filters.active !== undefined) {
    ret.push({
      field: FIELD_STATUS,
      values: [filters.active ? 'active' : 'inactive'],
      isOptional: optionalFilters.includes(ACTIVE),
    });
  }

  if (filters.activeLicense !== undefined) {
    ret.push({
      field: FIELD_LICENSE,
      values: [String(filters.activeLicense)],
      isOptional: optionalFilters.includes(ACTIVE_LICENSE),
    });
  }
  return ret;
};

export const itemMapper = (algoliaItem: AlgoliaMember): MemberSearchResultItem => {
  const result = pick(algoliaItem, MEMBER_FIELDS);
  const resultWithIsPrimary = Object.assign(result, { isPrimary: result.is_active_licensee });

  return resultWithIsPrimary;
};

const memberMapper: Mapper = {
  index: config.algolia.indices.user,
  mapper,
  itemMapper,
};

export default memberMapper;
