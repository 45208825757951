import Algolia, { SearchClient } from 'algoliasearch/lite';
import { MultipleQueriesQuery, MultipleQueriesResponse } from '@algolia/client-search';

import config from 'config';
import { AlgoliaToken } from 'lib/tokenRegistry/algoliaTokenClient';
import { IAlgoliaTokenClient } from 'lib/tokenRegistry/types';

export interface AlgoliaClient {
  search<T>(queries: Array<MultipleQueriesQuery>): Promise<MultipleQueriesResponse<T>>;
}

export default class AlgoliaClientImpl implements AlgoliaClient {
  algoliaTokenClient: IAlgoliaTokenClient;

  currentAlgoliaToken: AlgoliaToken | null = null;

  client: SearchClient;

  constructor(tokenClient: IAlgoliaTokenClient) {
    this.algoliaTokenClient = tokenClient;
  }

  async getClient(): Promise<SearchClient> {
    const token = await this.algoliaTokenClient.getOrRefresh();

    // We track the token for changes to know whether the client needs to be renewed.
    if (token !== this.currentAlgoliaToken) {
      this.currentAlgoliaToken = token;

      this.client = Algolia(config.algolia.appId, this.currentAlgoliaToken);
    }

    return this.client;
  }

  async search<T>(queries: Array<MultipleQueriesQuery>): Promise<MultipleQueriesResponse<T>> {
    const client = await this.getClient();

    return client.search(queries);
  }
}
