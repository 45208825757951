import { getOrdinal } from 'lib/util';

export const getFloor = (floor: string | null | undefined): string | null | undefined => {
  if (floor === null || floor === undefined) {
    return floor;
  }

  const floorNumber = parseInt(floor, 10);

  return `${floorNumber}` === floor ? `${getOrdinal(floorNumber)} floor` : floor;
};
