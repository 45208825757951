import Pusher, { Channel } from 'pusher-js';

import config from 'config';
import { camelCaseJson } from 'lib/util';

import { PusherEvent } from './constants';
import { PusherEventHandler } from './types';

class WelkioPusherClient {
  pusher: Pusher;
  channel: Channel;
  channelName: string;

  constructor(userUuid: string, accessToken: string) {
    this.channelName = `private-user.${userUuid}`;

    // eslint-disable-next-line new-cap
    this.pusher = new Pusher(config.welkioPusherKey, {
      authEndpoint: `${config.welkioAuthProxy.uri}/id/v1/socket/authorize`,
      cluster: 'mt1',
      authTransport: 'ajax',
      auth: {
        params: {},
        headers: {
          Authorization: `Token token=${accessToken}`,
        },
      },
    });
  }

  subscribe() {
    this.channel = this.pusher.subscribe(this.channelName);
  }

  unsubscribe() {
    this.pusher.unsubscribe(this.channelName);
  }

  bind(eventType: PusherEvent, handler: PusherEventHandler) {
    this.channel.bind(eventType, event => handler(camelCaseJson(event)));
  }

  unbind(eventType: PusherEvent, handler?: PusherEventHandler) {
    this.channel && this.channel.unbind(eventType, handler);
  }
}

export default WelkioPusherClient;
