import { MOVE_DIRECTION_TYPES } from 'features/mimo/constants';
import { MimoReservable, Move } from 'features/mimo/types';

export const convertMovesToMimoReservables = (moves: Move[]): MimoReservable[] => {
  const reservableHash: Hash<MimoReservable> = {};

  moves.forEach(move => {
    if (move.reservable) {
      if (!reservableHash[move.reservable.uuid]) {
        reservableHash[move.reservable.uuid] = {
          ...move.reservable,
          moveIns: [],
          moveOuts: [],
        };
      }

      if (move.moveDirection === MOVE_DIRECTION_TYPES.moveIn) {
        reservableHash[move.reservable.uuid].moveIns.push(move);
      } else if (move.moveDirection === MOVE_DIRECTION_TYPES.moveOut) {
        reservableHash[move.reservable.uuid].moveOuts.push(move);
      }
    }
  });

  return Object.values(reservableHash);
};
