import { MemberNotificationTemplate } from 'features/communication/memberNotifications/types';

import { MemberNotificationsSubset } from './index';

export function getMemberNotificationTemplates(
  state: MemberNotificationsSubset
): Array<MemberNotificationTemplate> {
  return state.memberNotifications.templates.items;
}

export const getMemberNotificationSelectedTemplateId = (state: MemberNotificationsSubset) =>
  state.memberNotifications.templates.selectedId;

export function getMemberNotificationTemplatesStatus(state: MemberNotificationsSubset) {
  const internalState = state.memberNotifications.templates;

  return {
    loading: internalState.loading,
    loaded: internalState.loaded,
    error: internalState.error,
  };
}

export function getMemberNotificationModalStatus(state: MemberNotificationsSubset) {
  return state.memberNotifications.currentModal;
}

export const getUserName = (state: MemberNotificationsSubset, userId: string) => {
  return state.memberNotifications.history.users[userId];
};
