import timing from 'timing.js';

import { trackAnalyticsFor } from 'lib/analytics/analytics';
import { AnalyticsEventName } from 'lib/analytics/constants';

export const sendTimingEvent = () =>
  trackAnalyticsFor(AnalyticsEventName.timing, {
    path: window.location.pathname,
    timing: timing.getTimes(),
    url: window.location.href,
  });
