import { createRequestReducer } from 'store/util/createRequestReducer';

import {
  FETCH_ALL_CONTRACT_DETAILS,
  FETCH_ALL_CONTRACT_DETAILS_FAIL,
  FETCH_ALL_CONTRACT_DETAILS_SUCCESS,
  FETCH_RENEWAL_OPPORTUNITY,
  FETCH_RENEWAL_OPPORTUNITY_FAIL,
  FETCH_RENEWAL_OPPORTUNITY_SUCCESS,
} from './constants';
import { ContractDetails, Opportunity } from './types';

const handleContractPayload = (response, previousState): Hash<ContractDetails> => {
  return {
    ...previousState,
    ...response.payload,
  };
};

export const contractReducer = createRequestReducer<Hash<ContractDetails>, Hash<ContractDetails>>(
  [FETCH_ALL_CONTRACT_DETAILS, FETCH_ALL_CONTRACT_DETAILS_SUCCESS, FETCH_ALL_CONTRACT_DETAILS_FAIL],
  {},
  handleContractPayload
);

export const opportunityReducer = createRequestReducer<Opportunity | null, Opportunity>(
  [FETCH_RENEWAL_OPPORTUNITY, FETCH_RENEWAL_OPPORTUNITY_SUCCESS, FETCH_RENEWAL_OPPORTUNITY_FAIL],
  null,
  res => res.payload
);
