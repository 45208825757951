import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_RESERVATIONS_BY_ACCOUNT,
  FETCH_RESERVATIONS_BY_ACCOUNT_SUCCESS,
  FETCH_RESERVATIONS_BY_ACCOUNT_FAIL,
] = createRequestConstantNames(cc('FETCH_RESERVATIONS_BY_ACCOUNT'));

export const [
  REFRESH_RESERVATIONS_BY_ACCOUNT,
  REFRESH_RESERVATIONS_BY_ACCOUNT_SUCCESS,
  REFRESH_RESERVATIONS_BY_ACCOUNT_FAIL,
] = createRequestConstantNames(cc('REFRESH_RESERVATIONS_BY_ACCOUNT'));

export const [
  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAIL,
] = createRequestConstantNames(cc('CREATE_RESERVATION'));

export const [
  UPDATE_RESERVATION,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAIL,
] = createRequestConstantNames(cc('UPDATE_RESERVATION'));
