import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [CREATE_HOLDS, CREATE_HOLDS_SUCCESS, CREATE_HOLDS_FAIL] = createRequestConstantNames(
  cc('CREATE_HOLDS')
);

export const [FETCH_HOLDS, FETCH_HOLDS_SUCCESS, FETCH_HOLDS_FAIL] = createRequestConstantNames(
  cc('FETCH_HOLDS')
);

export const UPDATE_CURRENT_PAGE = cc('ACCOUNT_HOLDS/UPDATE_CURRENT_PAGE');
