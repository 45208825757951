import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce, truncate, get } from 'lodash';

import BaseSearchAutocomplete, {
  Option,
  Props as BaseProps,
} from 'components/inputs/baseSearchAutocomplete';
import Avatar from 'components/avatar';
import { performSearch, getMembers } from 'store/modules/search';
import {
  EntityType,
  Filters,
  MemberSearchResultItem,
} from 'store/modules/search/searchService/types';
import { ReduxProps } from 'store/types';

import style from './memberSearchAutocomplete.scss';

const mapStateToProps = state => ({
  members: getMembers(state),
});

const mapDispatchToProps = {
  performSearch,
};

type Props = Readonly<
  ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
    BaseProps & {
      memberCompanySearchRef?: () => void;
      required?: boolean;
      searchOptions?: Filters;
      title?: string;
    }
>;

type Data = {
  company_names: Array<string>;
  uuid: string;
  name: string;
};

/*

usage:

<MemberCompanySearchAutocomplete
  title="Member or Company"
  name="memberCompany"
  memberCompanySearchRef={ref => {
    this.memberCompanySearch = ref;
  }}
  placeholder="Select..."
/>

*/
class MemberCompanySearchAutocomplete extends Component<Props> {
  getOptionLabel = (option: MemberSearchResultItem): string => {
    return option.name;
  };

  doSearchAction = debounce((query: string, callback: () => void) => {
    const { performSearch, searchOptions } = this.props;
    const request = {
      query,
      limit: 30,
      filters: {
        types: [EntityType.MEMBER],
        ...searchOptions,
      },
    };
    performSearch(request).then(callback);
  }, 200);

  getResults = (): Array<MemberSearchResultItem> => this.props.members;

  renderOption = (option: Option<Data>): React.ReactElement => this.renderMember(option.data);

  renderMember = (data: Data): React.ReactElement => {
    const totalLineLength = 48;
    const memberNameLength = data.name.length;
    const companyNameAllowedLength = 15;

    const companyName = truncate(get(data, 'company_names[0]', ''), {
      length: totalLineLength - memberNameLength - companyNameAllowedLength,
    });

    const displayName = `${data.name} ${data.uuid ? '' : ' - NEW'}`;

    return (
      <div className={style.option}>
        <Avatar userInfo={{ id: data.uuid }} size="tiny" isCompany={false} />
        <span className={style.memberOrCompanyName}>{displayName}</span>
        {data.uuid && (
          <span>
            <span> · </span>
            <span className={style.companyName}>{companyName}</span>
            <span className={style.type}>Person</span>
          </span>
        )}
      </div>
    );
  };

  filterOptions = () => true;

  render() {
    const {
      members,
      performSearch,
      required,
      searchOptions,
      title,
      optionLabel,
      filterOptions,
      doSearchAction,
      getResults,
      ...componentProps
    } = this.props;
    const currentProps = {
      optionLabel: optionLabel ?? this.getOptionLabel,
      filterOptions: filterOptions ?? this.filterOptions,
      doSearchAction: doSearchAction ?? this.doSearchAction,
      getResults: getResults ?? this.getResults,
      renderOption: this.renderOption,
      ...componentProps,
    };

    // $FlowFixMe TODO props hell.
    return <BaseSearchAutocomplete {...currentProps} ref={this.props.memberCompanySearchRef} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberCompanySearchAutocomplete);
