import { createSelector } from 'reselect';
import { utcToZonedTime } from 'date-fns-tz';

import { guestSearchFields, VisitType } from 'features/visitors/constants';
import { Visit, CheckedInGuestVisit, ScheduledVisit, GuestVisit } from 'features/visitors/types';
import { FiltersState, FiltersInfo, VisitsInfo } from 'features/visitors/redux/types';
import { createFuzzySearches } from 'features/visitors/lib/search';
import { VisitorsSubset } from 'features/visitors/redux/reducers';
import { getCurrentLocation } from 'store/selectors';

export const getVisitors = (state: VisitorsSubset) => state.visitors;
export const getVisits = createSelector([getVisitors], visitors => visitors.visits.data);
const getFilters = createSelector([getVisitors], visitors => visitors.visits.filters);
export const getLoading = createSelector([getVisitors], visitors => visitors.visits.loading);
export const getInProgressVisitIdUpdates = createSelector(
  [getVisitors],
  visitors => visitors.visits.inProgressVisitIdUpdates
);
export const getSelectedDate = createSelector(
  [getVisitors],
  visitors => visitors.visits.selectedDate
);
export const getSelectedDateZoned = createSelector(
  [getSelectedDate, getCurrentLocation],
  (selectedDate, location) => {
    return location ? utcToZonedTime(selectedDate, location.time_zone) : selectedDate;
  }
);

function filterVisits<T extends Visit & { host? }>(
  data: Array<T>,
  filtersState: FiltersState
): Array<T> {
  const activeToggleFilters = Object.keys(VisitType).reduce(
    (types, type) => (filtersState[type] === true ? [...types, type] : types),
    []
  );

  const filteredData = activeToggleFilters.length
    ? data.filter(({ type }) => activeToggleFilters.includes(type))
    : data;

  return createFuzzySearches<T>(filteredData, guestSearchFields).search(filtersState.search);
}

const isCheckedInGuest = (visit: Visit): visit is GuestVisit =>
  visit?.type === VisitType.GUEST && visit?.hasOwnProperty('checkInTime');

const isScheduledVisit = (visit: Visit): visit is ScheduledVisit => !isCheckedInGuest(visit);

export const getFiltersInfo = createSelector(
  [getVisits, getFilters],
  (visitors, filters): FiltersInfo => ({
    search: filters.search,
    [VisitType.GUEST]: {
      active: filters[VisitType.GUEST],
      count: visitors.filter(({ type }) => type === VisitType.GUEST).length,
    },
    [VisitType.MEMBER]: {
      active: filters[VisitType.MEMBER],
      count: visitors.filter(({ type }) => type === VisitType.MEMBER).length,
    },
    [VisitType.MEMBER_FIRST_TIME]: {
      active: filters[VisitType.MEMBER_FIRST_TIME],
      count: visitors.filter(({ type }) => type === VisitType.MEMBER_FIRST_TIME).length,
    },
    [VisitType.TOUR]: {
      active: filters[VisitType.TOUR],
      count: visitors.filter(({ type }) => type === VisitType.TOUR).length,
    },
    [VisitType.ON_DEMAND]: {
      active: filters[VisitType.ON_DEMAND],
      count: visitors.filter(({ type }) => type === VisitType.ON_DEMAND).length,
    },
    [VisitType.ON_DEMAND_FIRST_TIME]: {
      active: filters[VisitType.ON_DEMAND_FIRST_TIME],
      count: visitors.filter(({ type }) => type === VisitType.ON_DEMAND_FIRST_TIME).length,
    },
  })
);

export const getGuests = (state: VisitorsSubset): Array<Visit> =>
  getVisits(state).filter(({ type }) => type === VisitType.GUEST);

export const getCheckedInVisits = createSelector(
  [getVisits, getFilters],
  (visitors, filters): VisitsInfo<CheckedInGuestVisit> => {
    const guests = visitors.filter(visit => isCheckedInGuest(visit)) as Array<CheckedInGuestVisit>;
    return {
      totalCount: guests.length,
      visits: filterVisits<CheckedInGuestVisit>(guests, filters),
    };
  }
);

export const getScheduledVisit = createSelector(
  [getVisits, getFilters],
  (visitors, filters): VisitsInfo<ScheduledVisit> => {
    const guests = visitors.filter(isScheduledVisit);

    return {
      totalCount: guests.length,
      visits: filterVisits(guests, filters),
    };
  }
);
