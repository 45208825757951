import { SemanticCOLORS } from '@wework/dieter-ui';

import { CheckIns, NewStatuses } from 'features/check-ins/types';

export const COMPONENT_NAME = 'CheckInsTable';
export const FAILED_PRIORITY_EMOJI = '⚠️';
export const PRIORITY_EMOJI = '🚩';

export const ASSIGNEE_COLUMN = 'ASSIGNEE';
export const COLUMN_COUNT = 7;
export const COMPLETED_BY_COLUMN = 'COMPLETED BY';
export const COMPLETED_ON_COLUMN = 'COMPLETED ON';
export const DUE_DATE_COLUMN = 'DUE DATE';
export const FAILED_PRIORITY_COLUMN = FAILED_PRIORITY_EMOJI;
export const NEW_COMPANY_COLUMN = 'COMPANY';
export const NEW_DESK_COLUMN = 'DESKS';
export const NEW_OFFICE_COLUMN = 'OFFICES';
export const NEW_TCV_COLUMN = 'TCV';
export const PRIORITY_COLUMN = PRIORITY_EMOJI;
export const STATUS_COLUMN = 'STATUS';
export const TYPE_COLUMN = 'TYPE';

export const ASCENDING = 'ascending';
export const ASC = 'asc';
export const CHANGE_SORT = 'CHANGE_SORT';
export const DESCENDING = 'descending';
export const DESC = 'desc';
export const IN_PROGRESS = 'IN_PROGRESS';
export const LOADING = 'LOADING';
export const TO_DO = 'TO_DO';
export const UPDATE_SORT = 'UPDATE_SORT';
export const RESET_DATA = 'RESET_DATA';

export const UPCOMING_TIMEFRAME_THRESHOLD = 30;
export const COMPLETED_ON_DATE = 'completedOnDate';
export const DUE_DATE = 'dueDate';

export const NOTIFICATIONS = {
  GET_TASKS: 'Unable to update list of check-ins',
  LOCATION_ERROR: 'Unable to retrieve current location',
  TASK_COMPLETE: 'Task is complete and has been moved to the Done tab',
  TASK_DECLINED: 'Task is marked as declined and has been moved to the Done tab',
  TASK_UPDATE_ERROR: 'There was an error updating the task',
};

export const DECLINED_REASONS = {
  CUSTOMER_SUCCESS_TEAM: {
    value: 'CUSTOMER_SUCCESS_TEAM',
    label: 'Customer Success Team has asked Community not to conduct check-in at this time',
  },
  COMMUNITY_TEAM: {
    value: 'COMMUNITY_TEAM',
    label: 'Declined by Community Team',
  },
  MEMBER_DECLINED: {
    value: 'MEMBER_DECLINED',
    label: 'Member does not wish to meet at this time',
  },
  NO_RESPONSE: {
    value: 'NO_RESPONSE',
    label: 'Member did not respond',
  },
  MOVING_OUT: {
    value: 'MOVING_OUT',
    label: 'Member is moving out',
  },
  OTHER: {
    value: 'OTHER',
    label: 'Other:',
  },
};

export const columnToPropertyMap = {
  [ASSIGNEE_COLUMN]: 'assigneeName',
  [COMPLETED_BY_COLUMN]: 'completedByName',
  [COMPLETED_ON_COLUMN]: COMPLETED_ON_DATE,
  [DUE_DATE_COLUMN]: DUE_DATE,
  [FAILED_PRIORITY_COLUMN]: 'priorityFailed',
  [NEW_COMPANY_COLUMN]: 'companyName',
  [NEW_DESK_COLUMN]: 'desk',
  [NEW_OFFICE_COLUMN]: 'office',
  [NEW_TCV_COLUMN]: 'tcv',
  [STATUS_COLUMN]: 'status',
  [TYPE_COLUMN]: 'type',
  [PRIORITY_COLUMN]: 'priority',
};

const iconCell = 'min-w-[48px] w-[48px]';
const largeCell = 'min-w-[175px] max-w-[175px]';
const mediumCell = 'min-w-[135px] max-w-[135px]';
const smallCell = 'min-w-[80px]';

export const columnToClassNameMap = {
  [ASSIGNEE_COLUMN]: largeCell,
  [COMPLETED_BY_COLUMN]: largeCell,
  [COMPLETED_ON_COLUMN]: 'min-w-[125px]',
  [DUE_DATE_COLUMN]: 'min-w-[110px]',
  [FAILED_PRIORITY_COLUMN]: iconCell,
  [NEW_COMPANY_COLUMN]: largeCell,
  [NEW_DESK_COLUMN]: smallCell,
  [NEW_OFFICE_COLUMN]: mediumCell,
  [NEW_TCV_COLUMN]: mediumCell,
  [STATUS_COLUMN]: 'min-w-[150px] w-[150px]',
  [PRIORITY_COLUMN]: iconCell,
};

export const TooltipHeader = [NEW_DESK_COLUMN, NEW_OFFICE_COLUMN, NEW_TCV_COLUMN];

export const TooltipHeaderPropertyMap = {
  [NEW_DESK_COLUMN]: 'Total desks',
  [NEW_OFFICE_COLUMN]: 'Offices in your building',
  [NEW_TCV_COLUMN]: 'Total contract value',
};
export enum CheckinStatusDisplayName {
  TO_DO = 'To-Do',
  NOT_STARTED = 'Not Started',
  PAST_DUE = 'Past-due',
  IN_PROGRESS = 'In Progress',
  DECLINED = 'Declined',
  DONE = 'Done',
}

export const STATUSES = {
  TO_DO: {
    color: 'yellow' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.TO_DO,
    value: 'TO DO',
  },
  IN_PROGRESS: {
    color: 'grey' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.IN_PROGRESS,
    value: 'IN PROGRESS',
  },
  DECLINED: {
    color: 'red' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.DECLINED,
    value: 'DECLINED',
  },
  DONE: {
    color: 'green' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.DONE,
    value: 'DONE',
  },
};

export const NEW_STATUSES: NewStatuses = {
  TO_DO: {
    color: 'yellow' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.NOT_STARTED,
    value: 'TO DO',
  },
  PAST_DUE: {
    color: 'red' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.PAST_DUE,
    value: 'PAST-DUE',
  },
  IN_PROGRESS: {
    color: 'teal' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.IN_PROGRESS,
    value: 'IN PROGRESS',
  },
  DECLINED: {
    color: 'red' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.DECLINED,
    value: 'DECLINED',
  },
  DONE: {
    color: 'green' as SemanticCOLORS,
    displayName: CheckinStatusDisplayName.DONE,
    value: 'DONE',
  },
  EMPTY: {
    color: 'red' as SemanticCOLORS,
    displayName: '',
    value: '',
  },
};

export enum CheckinFrequencyDisplayName {
  FIRST = '1st',
  QUARTERLY = 'Quarterly',
  TWO_WEEK = 'Two Week',
}

export const CHECKINS: CheckIns = {
  FIRST: {
    type: 'checkin-15-day',
    displayName: CheckinFrequencyDisplayName.FIRST,
    longDisplayName: `${CheckinFrequencyDisplayName.FIRST} check-in`,
  },
  QUARTERLY: {
    type: 'checkin-quarterly',
    displayName: CheckinFrequencyDisplayName.QUARTERLY,
    longDisplayName: `${CheckinFrequencyDisplayName.QUARTERLY} check-in`,
  },
  TWO_WEEK: {
    type: 'checkin-two-week',
    displayName: CheckinFrequencyDisplayName.TWO_WEEK,
    longDisplayName: `${CheckinFrequencyDisplayName.TWO_WEEK} check-in`,
  },
};

export const FILTERS = {
  DATE: 'date',
  LOCATION: 'location',
  TYPE: 'type',
  CLEAR: 'clear',
  SEARCH: 'search',
};

export const TABLEAU_BASE_URL =
  'https://prod-useast-a.online.tableau.com/#/site/wework/views/VitalStatsDashboard/VitalStats?';

export const DAY = 'day';
export const EN = 'en';
export const LONG = 'long';
export const NUMERIC = 'numeric';
export const SHORT = 'short';
export const TODAY = 'Today';
