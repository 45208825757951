import { upperFirst } from 'lodash';

import Discount from 'features/paperwork/discounts/commitmentBasedPromotions/models/discount';
import { VariantGroup } from 'features/paperwork/discounts/commitmentBasedPromotions/types';

export interface SuggestionType {
  averageDiscount: string;
  code: string;
  commitmentLength: number;
  maxDiscount: string;
  maxDiscountError: string;
  schedule: Array<string>;
  uuid: string;
  variant: string;
}

const EQUALLY_EVERY_MONTH = 'Equally, every month';
export const MONTH_TO_MONTH_GROUP_ID = 'month-to-month';

export default class Suggestion {
  averageDiscount: number;
  code: string;
  commitmentLength: number;
  maxDiscount: number;
  maxDiscountError: string;
  schedule: Array<number>;
  uuid: string;
  variant: string;

  constructor(suggestion: SuggestionType) {
    this.averageDiscount = parseFloat(suggestion.averageDiscount);
    this.code = suggestion.code;
    this.commitmentLength = suggestion.commitmentLength;
    this.maxDiscount = parseFloat(suggestion.maxDiscount);
    this.maxDiscountError = suggestion.maxDiscountError;
    this.schedule = suggestion.schedule.map(parseFloat);
    this.uuid = suggestion.uuid;
    this.variant = suggestion.variant;
  }

  baseVariant = (): string => {
    if (this.isFall321()) {
      return 'fall321';
    }

    if (!this.isOverride()) {
      return this.variant;
    }

    const index = this.variant.indexOf('override');
    // Use index - 1 to account for the separator before override.
    return this.variant.substring(0, index - 1);
  };

  discounts = (locale: string): Array<Discount> =>
    this.schedule.map(discount => Discount.fromSuggestion(discount, locale));

  duration = (): number => this.schedule.length;

  // Number of months that have discounts
  discountDuration = (): number => this.schedule.filter(discount => discount > 0).length;

  group = (): VariantGroup => {
    const { commitmentLength } = this;
    const id = commitmentLength === 1 ? MONTH_TO_MONTH_GROUP_ID : `${commitmentLength}-months`;
    return {
      commitmentLength: this.commitmentLength,
      id,
      variant: this.baseVariant(),
    };
  };

  isDefault = (): boolean => this.variant.startsWith('default');

  isFreeMonths = (): boolean => /^free(_|-)months/.test(this.variant);

  isFall321 = (): boolean => this.variant.startsWith('fall321');

  isOtherTerm = (): boolean => this.code.startsWith('CUSTOM');

  // Assume that all override suggestion variants from Discounts API will have the variant field
  // suffixed with override. For example 'default_override' or 'free_months_override'.
  isOverride = (): boolean => this.variant.endsWith('override');

  toString = (): string => {
    if (this.isDefault()) {
      return EQUALLY_EVERY_MONTH;
    }

    if (this.isOverride()) {
      const labelParts = this.variant.replace(/-/, '_').split('_');
      const label = labelParts.slice(0, labelParts.length - 1).join(' ');
      return `${upperFirst(label)}`;
    }

    return upperFirst(this.variant.replace(/-/, '_').split('_').join(' '));
  };

  getAverageDiscount = (): number => this.averageDiscount;
  getCode = (): string => this.code;
  getCommitmentLength = (): number => this.commitmentLength;
  getMaxDiscount = (): number => this.maxDiscount;
  getMaxDiscountError = (): string => this.maxDiscountError;
  getSchedule = (): Array<number> => this.schedule;
  getUuid = (): string => this.uuid;
}
