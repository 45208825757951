import { ActionMeta, handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';
import { ErrorContainer } from 'store/errors';

import { CreditUsage, Company, SalesforceAccountManagerResponse } from './types';
import {
  constants,
  DISPATCH_ACTIVATE_COMPANY,
  DISPATCH_ACTIVATE_COMPANY_SUCCESS,
  DISPATCH_ACTIVATE_COMPANY_FAIL,
  DISPATCH_DEACTIVATE_COMPANY,
  DISPATCH_DEACTIVATE_COMPANY_SUCCESS,
  DISPATCH_DEACTIVATE_COMPANY_FAIL,
  SYNC_COMPANY_WITH_SPACEMAN,
  SYNC_COMPANY_WITH_SPACEMAN_SUCCESS,
  SYNC_COMPANY_WITH_SPACEMAN_FAIL,
} from './constants';

export interface State {
  loaded: boolean;
  accountManagerByUuid: Hash<string | null>;
  updating: boolean;
  creditUsage: {
    byUuid: Hash<CreditUsage>;
    status: {
      loaded: boolean;
      loading: boolean;
      error: null;
    };
  };
  activating: boolean;
  loading: boolean;
  error: ErrorContainer | null | undefined;
  updated: boolean;
  byUuid: Hash<Company>;
  activated: boolean;
  activatingCompany: boolean;
  activatingCompanySuccess: boolean;
  activatingCompanyFail: boolean;
  deactivatingCompany: boolean;
  deactivatingCompanySuccess: boolean;
  deactivatingCompanyFail: boolean;
  syncingCompanyWithSpaceman: boolean;
  syncingCompanyWithSpacemanSuccess: boolean;
  syncingCompanyWithSpacemanFail: boolean;
}

export interface CompaniesSubset {
  companies: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byUuid: {},
  accountManagerByUuid: {},
  updating: false,
  updated: false,
  activating: false,
  activated: false,
  error: null,
  creditUsage: {
    status: {
      loading: false,
      loaded: false,
      error: null,
    },
    byUuid: {},
  },
  activatingCompany: false,
  activatingCompanySuccess: false,
  activatingCompanyFail: false,
  deactivatingCompany: false,
  deactivatingCompanySuccess: false,
  deactivatingCompanyFail: false,
  syncingCompanyWithSpaceman: false,
  syncingCompanyWithSpacemanSuccess: false,
  syncingCompanyWithSpacemanFail: false,
};

type FetchCompanyRequestPayload = { result: Company };

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [constants.GET_COMPANY]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [constants.GET_COMPANY_SUCCESS]: (
      state: State,
      action: ActionWithPayload<FetchCompanyRequestPayload>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byUuid: {
        ...state.byUuid,
        [action.payload.result.uuid]: action.payload.result,
      },
    }),
    [constants.GET_COMPANY_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
      },
    }),
    [constants.GET_ACCOUNT_SERVICE_ACCOUNT]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [constants.GET_ACCOUNT_SERVICE_ACCOUNT_SUCCESS]: (
      state: State,
      action: ActionWithPayload<Company>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byUuid: {
        ...state.byUuid,
        [action.payload.uuid]: {
          ...action.payload,
          authorized_signatory_email: action.payload.authorized_signatory.email,
          authorized_signatory_name: action.payload.authorized_signatory.name,
          is_active: action.payload.active,
          is_potential: action.payload.potential,
          printer_login: action.payload.printer?.login,
          printer_pin: action.payload.printer?.pin,
        },
      },
    }),
    [constants.GET_ACCOUNT_SERVICE_ACCOUNT_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
        xRequestId: action.payload.response?.headers.get('x-request-id'),
      },
    }),
    [constants.GET_COMPANY_ACCOUNT_MANAGER_SUCCESS]: (
      state: State,
      action: ActionMeta<SalesforceAccountManagerResponse, { uuid: string }>
    ) => ({
      ...state,
      accountManagerByUuid: {
        ...state.accountManagerByUuid,
        [action.meta.uuid]: action.payload?.accountManager || null,
      },
    }),
    [constants.GET_COMPANY_ACCOUNT_MANAGER_FAIL]: (
      state: State,
      action: {
        payload: string;
        meta: { uuid: string };
      }
    ) => ({
      ...state,
      // Handles errors silently for this case
      accountManagerByUuid: {
        ...state.accountManagerByUuid,
        [action.meta.uuid]: null,
      },
      // added here to emphasize we shouldn't set 'error: action.payload', since page can be displayed even without the accountManager
    }),
    [constants.UPDATE_COMPANY]: (state: State) => ({
      ...state,
      updating: true,
      updated: false,
      error: null,
    }),
    [constants.UPDATE_COMPANY_SUCCESS]: (state: State) => ({
      ...state,
      updating: false,
      updated: true,
      error: null,
    }),
    [constants.UPDATE_COMPANY_FAIL]: (state: State, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
        xRequestId: action.payload.response?.headers.get('x-request-id'),
      },
    }),
    [constants.ACTIVATE_COMPANY]: (state: State) => ({
      ...state,
      activating: true,
      activated: false,
      error: null,
    }),
    [constants.ACTIVATE_COMPANY_SUCCESS]: (state: State) => ({
      ...state,
      activating: false,
      activated: true,
      error: null,
    }),
    [constants.ACTIVATE_COMPANY_FAIL]: (state: State, action) => ({
      ...state,
      activating: false,
      activated: false,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
        xRequestId: action.payload.response?.headers.get('x-request-id'),
      },
    }),
    [constants.FETCH_CONFERENCE_ROOM_CREDIT_USAGE_BY_COMPANY]: (state: State) => ({
      ...state,
      creditUsage: {
        ...state.creditUsage,
        status: {
          loading: true,
          loaded: false,
          error: null,
        },
      },
    }),
    [constants.FETCH_CONFERENCE_ROOM_CREDIT_USAGE_BY_COMPANY_SUCCESS]: (
      state: State,
      {
        payload,
        meta: { companyUuid },
      }: {
        payload: CreditUsage;
        meta: { companyUuid: string };
      }
    ) => ({
      ...state,
      creditUsage: {
        ...state.creditUsage,
        status: {
          loading: false,
          loaded: true,
          error: null,
        },
        byUuid: {
          ...state.creditUsage.byUuid,
          [companyUuid]: payload,
        },
      },
    }),
    [constants.FETCH_CONFERENCE_ROOM_CREDIT_USAGE_BY_COMPANY_FAIL]: (state, { payload }) => ({
      ...state,
      creditUsage: {
        ...state.creditUsage,
        status: {
          loading: false,
          loaded: false,
          error: payload,
        },
      },
    }),

    [DISPATCH_ACTIVATE_COMPANY]: (state: State) => ({
      ...state,
      activatingCompany: true,
    }),

    [DISPATCH_ACTIVATE_COMPANY_SUCCESS]: (state: State) => ({
      ...state,
      activatingCompany: false,
      activatingCompanySuccess: true,
    }),

    [DISPATCH_ACTIVATE_COMPANY_FAIL]: (state: State) => ({
      ...state,
      activatingCompany: false,
      activatingCompanySuccess: false,
      activatingCompanyFail: true,
    }),

    [DISPATCH_DEACTIVATE_COMPANY]: (state: State) => ({
      ...state,
      deactivatingCompany: true,
    }),

    [DISPATCH_DEACTIVATE_COMPANY_SUCCESS]: (state: State) => ({
      ...state,
      deactivatingCompany: false,
      deactivatingCompanySuccess: true,
    }),

    [DISPATCH_DEACTIVATE_COMPANY_FAIL]: (state: State) => ({
      ...state,
      deactivatingCompany: false,
      deactivatingCompanySuccess: false,
      deactivatingCompanyFail: true,
    }),

    [SYNC_COMPANY_WITH_SPACEMAN]: (state: State) => ({
      ...state,
      syncingCompanyWithSpaceman: true,
    }),
    [SYNC_COMPANY_WITH_SPACEMAN_SUCCESS]: (state: State) => ({
      ...state,
      syncingCompanyWithSpaceman: false,
      syncingCompanyWithSpacemanSuccess: true,
    }),
    [SYNC_COMPANY_WITH_SPACEMAN_FAIL]: (state: State) => ({
      ...state,
      syncingCompanyWithSpaceman: false,
      syncingCompanyWithSpacemanSuccess: false,
      syncingCompanyWithSpacemanFail: true,
    }),
    [constants.CREATE_ORGANIZATION]: (state: State) => ({
      ...state,
      creating: true,
      created: false,
      error: null,
    }),
    [constants.CREATE_ORGANIZATION_SUCCESS]: (state: State) => ({
      ...state,
      creating: false,
      created: true,
      error: null,
    }),
    [constants.CREATE_ORGANIZATION_FAIL]: (state: State, action) => ({
      ...state,
      creating: false,
      created: false,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
        xRequestId: action.payload.response?.headers.get('x-request-id'),
      },
    }),
  },
  initialState
);

export default reducer;
