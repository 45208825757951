import { handleActions } from 'redux-actions';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';

import { NoticePeriodSelection } from 'features/renewals/redux/noticePeriodSelection/types';
import { UPDATE_NOTICE_PERIOD } from 'features/renewals/redux/noticePeriodSelection/constants';

const initialState: NoticePeriodSelection = {
  from: startOfMonth(new Date()),
  to: endOfMonth(addMonths(new Date(), 3)),
};

export const reducer = handleActions<NoticePeriodSelection, NoticePeriodSelection>(
  {
    [UPDATE_NOTICE_PERIOD]: (_state, action) => action.payload ?? initialState,
  },
  initialState
);

export default reducer;
