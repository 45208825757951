import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Tag as RayTag } from '@wework/ray2';

import Cell, { Props as CellProps } from 'features/search/resultSets/cell';
import { INACTIVE_LABEL, PRIMARY_LABEL } from 'features/search/resultSets/constants';
import {
  MemberSearchResultItem,
  PrimaryMember,
  Status,
} from 'store/modules/search/searchService/types';

import { memberActionURL } from '../utils';
import { MemberCellProps } from '../types';

type Props = CellProps &
  Pick<MemberCellProps, 'inactive'> & {
    isPrimary?: boolean;
    member: MemberSearchResultItem | PrimaryMember;
    secondRowContent?: React.ReactNode;
  };

const MemberNameCell = ({ inactive, isPrimary, member, secondRowContent }: Props) => {
  if (!member) return <div />;

  const isInactive = member.status === Status.INACTIVE;
  const primaryLabel = (
    <div className="mr-[5px]">
      <RayTag theme="white">
        <p>{PRIMARY_LABEL}</p>
      </RayTag>
    </div>
  );
  const inactiveLabel = <RayTag theme="gray">{INACTIVE_LABEL}</RayTag>;
  return (
    <Cell inactive={inactive}>
      <div className="flex !items-center">
        <Link
          className="min-w-[24px] text-primary text-3xs truncate mr-[5px] hover:text-primary hover:underline"
          to={memberActionURL(member)}
        >
          {member.name}
        </Link>
        {isPrimary && primaryLabel}
        {isInactive && inactiveLabel}
      </div>
      <div>{secondRowContent}</div>
    </Cell>
  );
};
export default compose<React.ComponentType<Props>>()(MemberNameCell);
