import { handleActions, ActionMeta } from 'redux-actions';

import { Anniversary } from 'features/memberMilestones/types';
import {
  FETCH_ANNIVERSARIES_BY_LOCATION,
  FETCH_ANNIVERSARIES_BY_LOCATION_SUCCESS,
  FETCH_ANNIVERSARIES_BY_LOCATION_FAIL,
} from 'features/memberMilestones/redux/constants';

export interface State {
  byLocationUuid: Hash<Array<Anniversary>>;
  loaded: boolean;
  loading: boolean;
  error: boolean | null | undefined;
}

export interface AnniversariesSubset {
  anniversaries: State;
}

// Initial State
export const initialState: State = {
  byLocationUuid: {},
  error: null,
  loaded: false,
  loading: false,
};

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [FETCH_ANNIVERSARIES_BY_LOCATION]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_ANNIVERSARIES_BY_LOCATION_SUCCESS]: (
      state: State,
      action: ActionMeta<{ result: Array<Anniversary> }, { locationUuid: string }>
    ) => ({
      ...state,
      byLocationUuid: {
        ...state.byLocationUuid,
        [action.meta.locationUuid]: action.payload.result,
      },
      loading: false,
      loaded: true,
      error: null,
    }),
    [FETCH_ANNIVERSARIES_BY_LOCATION_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
