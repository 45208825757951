import AccountService from 'lib/api/accountService';
import BanAPI from 'lib/api/banAPI';
import CommunityEventsAPI from 'lib/api/communityEventsAPI';
import CommunityMetricsAPI from 'lib/api/communityMetricsAPI';
import IdAPI from 'lib/api/id';
import LocationsAPI from 'lib/api/locations';
import NotifyAPI from 'lib/api/notify';
import OperatorServiceAPI from 'lib/api/operatorService';
import SkywriterAPI from 'lib/api/skywriter';
import TaskAPI from 'lib/api/taskAPI';
import WelkioAuthProxy from 'lib/api/welkioAuthProxy';

export const accountService = new AccountService();
export const banAPI = new BanAPI();
export const communityEventsAPI = new CommunityEventsAPI();
export const communityMetricsAPI = new CommunityMetricsAPI();
export const idAPI = new IdAPI();
export const locationsAPI = new LocationsAPI();
export const notifyAPI = new NotifyAPI();
export const operatorServiceAPI = new OperatorServiceAPI();
export const skywriterAPI = new SkywriterAPI();
export const taskAPI = new TaskAPI();
export const welkioAuthProxy = new WelkioAuthProxy();
