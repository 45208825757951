import createReduxConstant from 'store/util/createReduxConstant';

export const OPEN_REFUND_ROOM_RESERVATION_MODAL = createReduxConstant(
  'OPEN_REFUND_ROOM_RESERVATION_MODAL'
);

export const OPEN_PAST_MONTH_REFUND_MODAL = createReduxConstant('OPEN_PAST_MONTH_REFUND_MODAL');
export const CLOSE_PAST_MONTH_REFUND_MODAL = createReduxConstant('CLOSE_PAST_MONTH_REFUND_MODAL');

export const CLOSE_ROOM_RESERVATION_MODAL = createReduxConstant('CLOSE_ROOM_RESERVATION_MODAL');

export const OPEN_EDIT_ROOM_RESERVATION_MODAL = createReduxConstant(
  'OPEN_EDIT_ROOM_RESERVATION_MODAL'
);

export const OPEN_CANCEL_ROOM_RESERVATION_MODAL = createReduxConstant(
  'OPEN_CANCEL_ROOM_RESERVATION_MODAL'
);
