import { Action } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import { createRequestAction } from 'store/util';
import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { GlobalState } from 'store/modules';
import config from 'config';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { PaperworkSubset } from 'features/paperwork/ducks';

export const UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT = 'UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT';
export const UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_SUCCESS =
  'UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_SUCCESS';
export const UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_FAIL = 'UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_FAIL';
export const UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_RESET = 'UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_RESET';

const types: RequestActionTypes = [
  UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT,
  UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_SUCCESS,
  UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_FAIL,
];

export interface UploadAgreementResult {
  agreement_id?: string;
  fileName?: string;
  type?: string;
  result?: string;
}

export interface UploadAgreementPayload {
  agreementId?: string;
  fileName?: string;
  url?: string;
}

export const getFileName = (state: GlobalState) => {
  return state.uploadedAgreements.uploadedAgreements.result.fileName;
};

export const getSignedAgreementUrl = (state: GlobalState | PaperworkSubset) => {
  return state.uploadedAgreements.uploadedAgreements.result.url;
};

export const getSignedAt = (state: GlobalState | PaperworkSubset) => {
  // @ts-ignore
  return state?.form?.UploadAgreement?.values?.signedAt;
};

export const initialState = {};

const handlePayload = (response: Action<any>): Hash<UploadAgreementPayload> => {
  return {
    agreementId: response.payload.params.agreement_id,
    fileName: response.payload.params.fileName,
    url: response.payload.result,
  };
};

export const uploadAddOnAgreement = ({ data, fileName }: { data?: string; fileName?: string }) => (
  dispatch: Dispatch<BaseAction>
) => {
  const body = {
    agreement_id: uuidv4(),
    type: 'membership_agreement_enterprise',
    data,
    fileName,
  };

  const requestAction = createRequestAction({
    body,
    endpoint: `${config.spacemoney.uri}/api/v1/agreement_uploads`,
    method: 'POST',
    types,
  });

  return dispatch(requestAction);
};

export const resetUploadedAddOnAgreement = () => (dispatch: Dispatch<BaseAction>) =>
  dispatch({ type: UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_RESET });

const uploadedAgreements = createRequestReducer<UploadAgreementResult, UploadAgreementPayload>(
  [...types, UPLOAD_ADD_ON_MEMBERSHIP_AGREEMENT_RESET],
  initialState,
  handlePayload
);
export default uploadedAgreements;
