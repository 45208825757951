const COLORS = {
  defaultTextColor: '#666',
  redTextColor: '#660009',
  greenTextColor: '#086600',
  lightBorderColor: '#B0B0B0',
  offWhite: '#F7F7F7',
  greenBackground: '#E8FFE6',
  redBackground: '#FFE6E8',
  blue: '#07c',
};

const DIETER_FONT = 'Apercu';

const notificationStyles = {
  NotificationItem: {
    DefaultStyle: {
      display: 'grid',
      padding: `0.5rem 0.75rem`,
      borderRadius: 4,
      color: COLORS.defaultTextColor,
      border: `1px solid ${COLORS.lightBorderColor}`,
      backgroundColor: COLORS.offWhite,
      boxShadow: 'none',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
    success: {
      color: COLORS.greenTextColor,
      borderColor: COLORS.greenTextColor,
      backgroundColor: COLORS.greenBackground,
    },
    error: {
      color: COLORS.redTextColor,
      borderColor: COLORS.redTextColor,
      backgroundColor: COLORS.redBackground,
    },
  },
  Title: {
    DefaultStyle: {
      color: COLORS.defaultTextColor,
      fontFamily: DIETER_FONT,
      fontSize: '0.875rem',
      marginBottom: 8,
      gridRow: 1,
      gridColumn: 1,
    },
    success: {
      color: COLORS.greenTextColor,
    },
    error: {
      color: COLORS.redTextColor,
    },
  },
  MessageWrapper: {
    DefaultStyle: {
      gridRow: 2,
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  Action: {
    DefaultStyle: {
      fontFamily: DIETER_FONT,
      fontSize: '0.875rem',
      fontWeight: 'normal',
      background: 'none',
      color: COLORS.blue,
      margin: 0,
      padding: 0,
    },
    success: {
      backgroundColor: 'none',
      color: COLORS.blue,
    },
    error: {
      backgroundColor: 'none',
      color: COLORS.blue,
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      gridRow: 1,
      gridColumn: 2,
    },
  },
};

export default notificationStyles;
