/**
 * These formats are used for the old momentjs implementation -- for all new formatting please use date-fns and the
 * formats defined in `dateFormats`
 *
 * For an explanation of the differences please see here:
 *
 * This version of date fns uses the new unicode standard as explained here: date-fns.org/v2.2.1/docs/format
 *
 * ⚠️ Please note that the format tokens differ from Moment.js and other libraries. See: git.io/fxCyr
 *
 * The characters wrapped between two single quotes characters (') are escaped. Two single quotes in a row, whether inside or outside a quoted sequence, represent a 'real' single quote. (see the last example
 * Format of the string is based on Unicode Technical Standard #35: unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table with a few additions.
 *
 * @deprecated in favor of `dateFormats` and date-fns
 */
export const dateFormatsDeprecated = Object.freeze({
  day: 'ddd',
  day_long: 'dddd',
  date_with_ordinal: 'Do',
  date_only: 'D',
  date_only_long: 'DD',
  short: 'MMM D',
  short_with_year: 'MMM D, YYYY',
  long: 'ddd, MMM D',
  long_with_year: 'ddd, MMM D, YYYY',
  month: 'MMMM',
  month_short: 'M',
  month_long: 'MM',
  month_with_day: 'MMMM dddd',
  month_with_date: 'MMMM D',
  month_with_ordinal_date: 'MMMM Do',
  month_short_with_ordinal_date: 'MMM Do',
  month_with_ordinal_date_and_year: 'MMMM Do, YYYY',
  month_short_with_ordinal_date_and_year: 'MMM Do, YYYY',
  month_with_long_date_and_year: 'MMM DD, YYYY',
  month_with_year: 'MMMM Y',
  month_number_one_based_index: 'M',
  long_month_with_date_and_year: 'MMMM D, YYYY',
  year: 'YYYY',
  year_long: 'Y',
  iso_date: 'YYYY-MM-DD',
  iso_date_with_slashes: 'YYYY/MM/DD',
  iso_date_and_time: 'YYYY-MM-DD H:mm:ss',
  date_localized: 'L',
  date_localized_long: 'll',
  usa: 'MM-DD-YYYY',
  usa_with_slashes: 'MM/DD/YYYY',
  usa_with_slashes_short: 'M/D/YY',
  usa_date_time_slashes: 'MM/DD/YYYY hh:mm a',
  day_month_year: "MMM D 'YY",
  time: 'h:mm a',
  time_tight: 'h:mma',
  time_long: 'hh:mm A',
  hour24: 'HH',
  time24: 'HH:mm',
  time24_with_seconds: 'HH:mm:ss',
  time_hour: 'ha',
  time_hour_long: 'hh a',
  time_localized: 'LT',
  time_localized_with_tz: 'LT Z',
  tz: 'Z',
});

export const dateFormats = Object.freeze({
  day: 'EEE',
  day_long: 'EEEE',
  date_with_ordinal: 'do',
  date_only: 'd',
  date_only_long: 'dd',
  short: 'MMM d',
  short_with_year: 'MMM d, yyyy',
  long: 'EEE, MMM d',
  long_with_year: 'EEE, MMM d, yyy',
  full_day_full_month: 'EEEE, MMMM do',
  month: 'MMMM',
  month_short: 'M',
  month_long: 'MM',
  month_abbr: 'MMM',
  month_with_day: 'MMMM EEEE',
  month_with_date: 'MMMM d',
  month_with_ordinal_date: 'MMMM do',
  month_with_ordinal_date_and_week_day: 'iiii, MMMM do',
  month_short_with_ordinal_date: 'MMM do',
  month_with_ordinal_date_and_year: 'MMMM do, yyyy',
  month_short_with_ordinal_date_and_year: 'MMM do, yyyy',
  month_with_long_date_and_year: 'MMM dd, yyyy',
  month_with_year: 'MMMM y',
  month_number_one_based_index: 'M',
  long_month_with_date_and_year: 'MMMM d, yyyy',
  year: 'yyyy',
  year_long: 'y',
  iso_date: 'yyyy-MM-dd',
  iso_date_time24: 'yyyy-MM-dd HH:mm',
  iso_date_with_slashes: 'yyyy/MM/dd',
  iso_date_and_time: 'yyyy-MM-dd H:mm:ss',
  iso_date_and_time24_with_seconds: 'yyyy-MM-dd HH:mm:ss',
  iso_date_and_time_with_tz: "yyyy-MM-dd'T'HH:mm:ssxxx",
  date_localized: 'P',
  date_localized_long: 'PP',
  usa: 'MM-dd-yyyy',
  usa_with_slashes: 'MM/dd/yyyy',
  usa_with_slashes_short: 'M/d/yy',
  usa_date_time_slashes: "MM/dd/yyyy hh:mm aaaaa'm",
  day_month_year: "MMM d ''yy",
  time: "h:mm aaaaa'm",
  time_tight: "h:mmaaaaa'm",
  time_long: 'hh:mm a',
  time_short: 'h:mm a',
  hour: 'ha',
  hour24: 'HH',
  time24: 'HH:mm',
  time24_with_seconds: 'HH:mm:ss',
  time_hour: "haaaaa'm",
  time_hour_long: "hh aaaaa'm",
  time_localized: 'p',
  time_localized_with_tz: 'p xxx',
  tz: 'xxx',
});

export const LOCATION_IN_URL = /(locations\/[\w-]+\/)/;

const LOCALE_TO_LANGUAGE = {
  en: 'English',
  nl: 'Dutch',
  'nl-NL': 'Dutch',
  he: 'Hebrew',
  'he-IL': 'Hebrew',
  'en-US': 'English (US)',
  'en-AU': 'English (Australia)',
  'es-MX': 'Spanish (Mexico)',
  'zh-CN': 'Chinese (Simplified)',
  'fr-FR': 'French',
  'fr-CA': 'French Canadian',
  'en-GB': 'English (United Kingdom)',
  'ko-KR': 'Korean',
  'de-DE': 'German',
  'zh-TW': 'Chinese (Traditional)',
};

export const getLanguageByLocale = (locale: string) => LOCALE_TO_LANGUAGE[locale] ?? locale;

export const PERCENT = {
  ENCODED: '%25',
  DECODED: '%',
};
