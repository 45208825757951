import { handleActions, Action } from 'redux-actions';
import { findIndex, reduce } from 'lodash';

import cc from 'store/util/createReduxConstant';
import { SAVE_COMMITMENT_BASED_PROMOTIONS } from 'features/paperwork/discounts/commitmentBasedPromotions/ducks/commitmentBasedPromotions';

import { TerminationOption, TerminationOptionComposedKey } from './types';

export const ADD_EARLY_TERMINATION_OPTION = cc('ADD_EARLY_TERMINATION_OPTION');
export const REMOVE_EARLY_TERMINATION_OPTION = cc('REMOVE_EARLY_TERMINATION_OPTION');
export const RESET_EARLY_TERMINATION_OPTIONS = cc('RESET_EARLY_TERMINATION_OPTIONS');
export const RESET_DRAFT_TERMINATION_OPTIONS = cc('RESET_DRAFT_TERMINATION_OPTIONS');
export const SAVE_EARLY_TERMINATION_OPTIONS = cc('SAVE_EARLY_TERMINATION_OPTIONS');
export const INIT_DRAFT_TERMINATION_OPTIONS = cc('INIT_DRAFT_TERMINATION_OPTIONS');

export const addEarlyTerminationOption = (option: TerminationOption) => {
  return {
    type: ADD_EARLY_TERMINATION_OPTION,
    payload: { option },
  };
};

export const removeEarlyTerminationOption = ({ storeKey, date }: TerminationOptionComposedKey) => {
  return {
    type: REMOVE_EARLY_TERMINATION_OPTION,
    payload: { storeKey, date },
  };
};

export const saveEarlyTerminationOption = () => {
  return {
    type: SAVE_EARLY_TERMINATION_OPTIONS,
  };
};

export const resetEarlyTermination = () => {
  return {
    type: RESET_EARLY_TERMINATION_OPTIONS,
  };
};

export interface State {
  [key: string]: {
    options?: Array<TerminationOption>;
  };
}

export const resetDraftTerminationOptions = () => {
  return {
    type: RESET_DRAFT_TERMINATION_OPTIONS,
  };
};

export const initEdition = () => {
  return {
    type: INIT_DRAFT_TERMINATION_OPTIONS,
  };
};

export const initialState: State = {
  draft: {},
  saved: {},
};

export const reducer = handleActions<State, any>(
  {
    [INIT_DRAFT_TERMINATION_OPTIONS]: state => {
      return {
        ...state,
        draft: {
          ...state.saved,
        },
      };
    },
    [ADD_EARLY_TERMINATION_OPTION]: (
      state,
      { payload: { option } }: Action<{ option: TerminationOption }>
    ) => {
      const options = state.draft[option.storeKey];
      return {
        ...state,
        draft: {
          ...state.draft,
          [option.storeKey]: options ? [...options, option] : [option],
        },
      };
    },
    [REMOVE_EARLY_TERMINATION_OPTION]: (
      state,
      { payload: { storeKey, date } }: Action<TerminationOption>
    ) => {
      const idx = findIndex(state.draft[storeKey], { storeKey, date });
      return {
        ...state,
        draft: {
          ...state.draft,
          [storeKey]: [
            ...state.draft[storeKey].slice(0, idx),
            ...state.draft[storeKey].slice(idx + 1),
          ],
        },
      };
    },
    [SAVE_COMMITMENT_BASED_PROMOTIONS]: (state, { payload }) => {
      return {
        ...state,
        saved: reduce(
          payload,
          (acc, discount, storeKey: string) => {
            if (!discount.suggestion) {
              delete acc[storeKey];
            }
            return acc;
          },
          { ...state.saved }
        ),
      };
    },
    [SAVE_EARLY_TERMINATION_OPTIONS]: state => {
      return {
        ...state,
        saved: {
          ...state.draft,
        },
        draft: {},
      };
    },
    [RESET_DRAFT_TERMINATION_OPTIONS]: state => {
      return {
        ...state,
        draft: {},
      };
    },
    [RESET_EARLY_TERMINATION_OPTIONS]: () => {
      return initialState;
    },
  },
  initialState
);

export default reducer;
