import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import Cell from 'features/search/resultSets/cell';
import VisitorsTags from 'features/visitors/components/tags/visitorsTags';

import { GUEST_RESERVATIONS_ACTION_URL } from '../constants';
import { GuestCellProps } from '../types';

const GuestNameCell = ({ guestVisit }: GuestCellProps) => {
  const {
    visitor: { firstName, lastName },
    tags,
  } = guestVisit;

  return (
    <Cell>
      <Link
        to={GUEST_RESERVATIONS_ACTION_URL}
        className="min-w-[24px] text-primary text-3xs truncate mr-[5px] hover:text-primary hover:underline"
      >
        {`${firstName} ${lastName}`}
      </Link>
      <VisitorsTags tags={tags} />
    </Cell>
  );
};

export default compose<React.ComponentType<GuestCellProps>>()(GuestNameCell);
