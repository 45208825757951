// we can use a high order component to wrap the component in an analytics provider
import React, { useMemo } from 'react';
import { omit, camelCase, upperFirst } from 'lodash';

import { useSendEvent } from '../sendEvent';
import { AnalyticsAction, AnalyticsConsumerProps } from '../types';

import { AnalyticsHocProps } from './types';

const classCase = (str: string) => upperFirst(camelCase(str));
const toMethodName = (str: string) => `on${classCase(str)}`;
const toToggleProp = (str: string) => `track${classCase(str)}`;
const formatAction = (
  action: string | AnalyticsAction
): {
  // No optional properties
  name: string;
  method: string;
  toggleProp: string;
  trackByDefault: boolean;
} => {
  if (typeof action === 'string') {
    return {
      name: action,
      method: toMethodName(action),
      toggleProp: toToggleProp(action),
      trackByDefault: true,
    };
  }

  const {
    name,
    method = toMethodName(name),
    toggleProp = toToggleProp(name),
    trackByDefault = true,
  } = action;

  return {
    name,
    method,
    toggleProp,
    trackByDefault,
  };
};

export const withAnalytics = <OwnProps extends {}, ToggleProps extends {} = {}>({
  actions = [],
}: AnalyticsHocProps) => (Component: React.ComponentType<any>) => {
  type WrappedProps = OwnProps & AnalyticsConsumerProps & ToggleProps;
  const WrappedComponent = React.memo(
    React.forwardRef(function WrappedComponent(
      { analyticsKey, analyticsData, ...props }: WrappedProps,
      ref
    ) {
      const sendEvent = useSendEvent(analyticsKey, analyticsData);

      const formattedActions = actions.map(formatAction);
      const togglePropNames = formattedActions.map(action => action.toggleProp);
      const toggleProps = togglePropNames.reduce<ToggleProps>(
        (aggregator, propName) => ({
          ...aggregator,
          [propName]: props[propName],
        }),
        {} as ToggleProps
      );

      const componentProps = omit(props, Object.keys(toggleProps));

      const trackableActionsDependencies = [
        sendEvent,
        ...formattedActions.flatMap(({ method, toggleProp }) => [props[method], props[toggleProp]]),
      ];

      const trackableActions = useMemo(
        () =>
          formattedActions.reduce<Hash<(...args) => void>>(
            (propsMap, { name: action, method, toggleProp, trackByDefault }) => {
              const isActionEnabled =
                typeof props[toggleProp] === 'boolean' ? props[toggleProp] : trackByDefault;

              if (isActionEnabled) {
                propsMap[method] = (...args) => {
                  sendEvent(action);
                  if (props[method]) {
                    props[method](...args);
                  }
                };
              }

              return propsMap;
            },
            {}
          ),
        trackableActionsDependencies
      );

      return <Component {...componentProps} {...trackableActions} ref={ref} />;
    })
  );
  WrappedComponent.displayName = `withAnalytics(${Component.displayName})`;

  return WrappedComponent as React.ComponentType<WrappedProps>;
};
