export const ON_DEMAND_PRODUCT_UUIDS = [
  '5fcf397b-077d-4449-8639-ad91aa6f4a12', // On Demand Subscription
  '9222203f-d6ed-4fbb-8acf-714b6313ac39', // On Demand
];

export const ErrorMessages = Object.freeze({
  '10001': {
    errorCode: '10001',
    errorName: 'USER_CREATION_FAILED',
    errorMessage: 'Failed to create new user, please try again.',
  },
  '10002': {
    errorCode: '10002',
    errorName: 'SEAT_CREATION_FAILED',
    errorMessage: 'Failed to create new seat, please try again.',
  },
  '10003': {
    errorCode: '10003',
    errorName: 'MEMBERSHIP_ASSIGNMENT_FAILED',
    errorMessage: 'Failed to assign user to membership slot, please try again.',
  },
});
