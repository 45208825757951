import { dateFormatsDeprecated } from 'lib/constants';

import { Amenity, AmenityOptionType } from './types';

export const RESERVATION_STATUS = Object.freeze({
  PAST: 'PAST',
  ONGOING: 'ONGOING',
  FUTURE: 'FUTURE',
});

export enum RoomReservationTypes {
  CONFERENCE = 'ConferenceRoom',
  SHARED_WORKSPACE = 'SharedWorkspace',
  DESK = 'DailyDesk',
  PRIVATE_OFFICE = 'PrivateOffice',
  PRIVATE_ACCESS_AREA = 'PrivateAccessArea',
}

export const RESERVATION_TYPE_TEXT_MAP = Object.freeze({
  [RoomReservationTypes.CONFERENCE]: 'Conference Room',
  [RoomReservationTypes.SHARED_WORKSPACE]: 'Private Office',
  [RoomReservationTypes.DESK]: 'Hot Desk',
  [RoomReservationTypes.PRIVATE_OFFICE]: 'Office',
  [RoomReservationTypes.PRIVATE_ACCESS_AREA]: 'Workplace Private Access Area',
});

export const BookingReservationTypes = Object.freeze({
  PAST: 'Past',
  PAST_OPEN: 'PastOpen',
  ONGOING: 'OnGoing',
  UPCOMING: 'Upcoming',
  OPEN: 'Open',
});

export const AnalyticsReservationTiming = Object.freeze({
  IN_PROGRESS: 'in progress',
  LESS_THAN_ONE_HOUR: 'less than one hour',
  FUTURE: 'future',
});

export const CancellationStatus = Object.freeze({
  NO_REASON: 'NO_REASON', // in this state we don't need to provide a reason for the booking cancellation
  WITH_REASON: 'WITH_REASON', // in this state we are required to provide a reason (optional: note) and a credit value (0 by default)
});

export const CELL_SIZE = 31;
export const MAX_END_TIME = '21'; // will be eval to 21:00
export const MIN_START_TIME = '7'; // will be eval to 07:00;
export const HOUR_TIME_FORMAT = 'H HH';

export const TIME_FORMAT = dateFormatsDeprecated.time24;
export const LONG_TIME_FORMAT_WITH_LABEL = dateFormatsDeprecated.time;
export const SHORT_TIME_FORMAT = dateFormatsDeprecated.time_hour;
export const LABEL_TIME_FORMAT = 'h:mm';
export const TIME_INTERVAL_MINUTES = 30;
export const EXTENDED_BOOKING_MINUTES = 15;
export const DATE_FORMAT = dateFormatsDeprecated.month_short_with_ordinal_date_and_year;
export const GLOBAL_PAGE_REFRESH_RATE = 60 * 1000;
export const ROOM_CARD_TITLE = 'All conference rooms';

export const AmenityType: Hash<Amenity> = Object.freeze({
  PHONE: 'phone',
  WHITEBOARD: 'whiteboard',
  TV: 'tv',
  PROJECTOR: 'projector',
});

export const ON_DEMAND_AMENITY_OPTION: AmenityOptionType = 'on demand';

export const ON_DEMAND_MEMBERSHIP_TYPE = 'On Demand';

export const QOF_POLLING_INTERVAL = 200;

export const QOF_POLLING_TIMEOUT = 7 * 1000;
