import config from 'config';
import ApiRequest from 'lib/api/apiRequest';

import { Notification } from './types';

export default class NotifyAPI extends ApiRequest {
  constructor() {
    super(config.notifyService.uri);
  }

  // currently there is only "one" kind that applies to SSWeb but will eventually expand to other usages
  static TASK_ASSIGNMENT_KIND = 'spacestation-task-assignment';

  getNotifications({ page = 1 }: { page?: number } = {}): Promise<Notification[]> {
    const request = {
      config: {
        isCamelCase: false,
      },
      params: {
        kinds: [NotifyAPI.TASK_ASSIGNMENT_KIND],
        page,
      },
      path: 'api/v1/notifications',
    };

    return this.get(request);
  }

  markNotificationAsRead(notificationUuid: string): Promise<Notification> {
    const request = {
      config: {
        isCamelCase: false,
      },
      path: `api/v1/notifications/${notificationUuid}/mark_as_read`,
    };

    return this.get(request);
  }

  // returns a 204 with no content
  markAllNotificationsAsRead(): Promise<void> {
    const request = {
      config: {
        isCamelCase: false,
      },
      path: `api/v1/notifications/mark_all_as_read`,
    };

    return this.patch(request);
  }
}
