import { assertIsDefined } from 'lib/assert';
import lazySingleton, { LazySingleton } from 'lib/lazySingleton';
import { getUserUuidFromAuth } from 'features/auth/selectors';
import { GetGlobalState } from 'store/types';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

import { Subscriber, WelkioPusherEvent, WelkioRegistrationEvent, WelkioVisitEvent } from './types';
import { PusherEvent } from './constants';
import WelkioPusherClient from './WelkioPusherClient';

const {
  VISIT_CREATED,
  REGISTRATION_CREATED,
  REGISTRATION_UPDATED,
  REGISTRATION_DELETED,
} = PusherEvent;

class GuestUpdater {
  pusher: WelkioPusherClient;
  subscribers: Array<Subscriber> = [];

  constructor(currentUserUuid: string, accessToken: string) {
    this.pusher = new WelkioPusherClient(currentUserUuid, accessToken);
  }

  connect() {
    this.pusher.subscribe();
    this.pusher.bind(VISIT_CREATED, (event: WelkioVisitEvent) =>
      this.publish(event, VISIT_CREATED, event.signedInAt, event.userId)
    );
    this.pusher.bind(REGISTRATION_CREATED, (event: WelkioRegistrationEvent) =>
      this.publish(event, REGISTRATION_CREATED, event.expectedAt)
    );
    this.pusher.bind(REGISTRATION_UPDATED, (event: WelkioRegistrationEvent) =>
      this.publish(event, REGISTRATION_UPDATED, event.expectedAt)
    );
    this.pusher.bind(REGISTRATION_DELETED, (event: WelkioRegistrationEvent) =>
      this.publish(event, REGISTRATION_DELETED, event.expectedAt)
    );
  }

  disconnect() {
    this.pusher.unbind(VISIT_CREATED);
    this.pusher.unbind(REGISTRATION_CREATED);
    this.pusher.unbind(REGISTRATION_UPDATED);
    this.pusher.unbind(REGISTRATION_DELETED);
    this.pusher.unsubscribe();
  }

  register(subscriber: Subscriber) {
    if (!this.subscribers.length) {
      this.connect();
    }

    this.subscribers.push(subscriber);

    return () => {
      this.subscribers.splice(this.subscribers.indexOf(subscriber), 1);
      if (!this.subscribers.length) {
        this.disconnect();
      }
    };
  }

  publish(event: WelkioPusherEvent, type: PusherEvent, date: string, userId?: string) {
    const { id, locationId } = event;
    this.subscribers.forEach(subscriber => subscriber(id, type, locationId, date, userId));
  }
}

const welkioGuestUpdater: LazySingleton<Promise<GuestUpdater>> = lazySingleton(
  async (getState: GetGlobalState) => {
    const currentUserUuid = getUserUuidFromAuth(getState());
    const accessToken = await getAccessToken();

    assertIsDefined(currentUserUuid);

    return new GuestUpdater(currentUserUuid, accessToken);
  }
);

export default welkioGuestUpdater;
