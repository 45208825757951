import { handleActions } from 'redux-actions';

import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';
import config from 'config';
import { BaseAction, BaseState, RSAAType } from 'store/types';

// Types
export type GlobalAccessLocation = {
  uuid: string;
  name: string;
  country_code: string;
  country: string;
  default_currency: string;
  locale: string | undefined;
  isMigrated: boolean;
};
export type GlobalAccessLocations = Array<GlobalAccessLocation>;

// Action Constants
export const FETCH_GLOBAL_ACCESS_LOCATIONS = cc('FETCH_GLOBAL_ACCESS_LOCATIONS');
export const FETCH_GLOBAL_ACCESS_LOCATIONS_SUCCESS = cc('FETCH_GLOBAL_ACCESS_LOCATIONS_SUCCESS');
export const FETCH_GLOBAL_ACCESS_LOCATIONS_FAIL = cc('FETCH_GLOBAL_ACCESS_LOCATIONS_FAIL');

export interface State extends BaseState {
  data: GlobalAccessLocations;
}

export interface GlobalAccessLocationsSubset {
  globalAccessLocations: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  data: [],
  error: null,
};

interface ActionPayload extends BaseAction {
  payload: GlobalAccessLocations;
}

// Reducer
export const reducer = handleActions<State, GlobalAccessLocations>(
  {
    [FETCH_GLOBAL_ACCESS_LOCATIONS]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_GLOBAL_ACCESS_LOCATIONS_SUCCESS]: (state, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.payload,
      error: null,
    }),
    [FETCH_GLOBAL_ACCESS_LOCATIONS_FAIL]: (state, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export const fetchGlobalAccessLocations = (): RSAAType =>
  createRequestAction({
    endpoint: `${config.spaceman.uri}/api/billing_entities`,
    types: [
      FETCH_GLOBAL_ACCESS_LOCATIONS,
      FETCH_GLOBAL_ACCESS_LOCATIONS_SUCCESS,
      FETCH_GLOBAL_ACCESS_LOCATIONS_FAIL,
    ],
  });

export default reducer;
