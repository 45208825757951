import React from 'react';
import { Segment } from '@wework/dieter-ui';

import { Location as LocationType } from 'store/modules/siLocations';
import { getSpacemanId } from 'store/modules/siLocations/utils';

import Location from './components/location';
import styles from './defaultLocationPicker.scss';

type Props = {
  defaultLocation?: LocationType | null;
  currentLocation: LocationType;
  onSaveAsDefault: () => Promise<any>;
  onClickDefaultLocation: () => void;
  closeLocationPicker: () => void;
};

export const formatMiddleEllipsis = (str: string | undefined) => {
  if (str! && str.length > 25) {
    return `${str.substr(0, 11)}…${str.substr(str.length - 11, str.length)}`;
  }
  return str;
};

const DefaultLocationPicker = ({
  defaultLocation,
  currentLocation,
  onSaveAsDefault,
  onClickDefaultLocation,
  closeLocationPicker,
}: Props) => {
  let currentLocationIsDefault = false;
  if (defaultLocation) {
    currentLocationIsDefault = getSpacemanId(defaultLocation) === getSpacemanId(currentLocation);
  }
  return (
    <>
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <Segment className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.title}>Default Location</div>
          {defaultLocation && (
            <Location
              location={defaultLocation}
              className={styles.defaultLocation}
              onClick={currentLocationIsDefault ? closeLocationPicker : onClickDefaultLocation}
            />
          )}
        </div>

        {!currentLocationIsDefault && (
          <div className={styles.setDefault} onClick={onSaveAsDefault}>
            {`Set ${formatMiddleEllipsis(currentLocation.name)} as default`}
          </div>
        )}
      </Segment>
    </>
  );
};

export default DefaultLocationPicker;
