import { handleActions } from 'redux-actions';

import {
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_V3,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS_V3,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL_V3,
  REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT,
  REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS,
  REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL,
  CREATE_ADD_ON_AGREEMENT,
  CREATE_ADD_ON_AGREEMENT_SUCCESS,
  CREATE_ADD_ON_AGREEMENT_FAIL,
  DISCARD_ADD_ON_AGREEMENT,
  DISCARD_ADD_ON_AGREEMENT_SUCCESS,
  DISCARD_ADD_ON_AGREEMENT_FAIL,
} from 'features/companies/addOnsSection/redux/addOnAgreements/constants';

import { AddOnAgreementData } from './types';

export interface State {
  loaded: boolean;
  refreshing: boolean;
  addOnAgreements: Array<any>;
  addons: Array<AddOnAgreementData>;
  error: boolean | null | undefined;
  loading: boolean;
}

export interface AddOnAgreementsSubset {
  addOnAgreements: State;
}

// Initial State
const initialState: State = {
  addOnAgreements: [],
  addons: [],
  error: null,
  loading: false,
  loaded: false,
  refreshing: false,
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      addOnAgreements: action.payload.result,
    }),
    [FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
    [FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_V3]: state => ({
      ...state,
      loading: true,
    }),
    [FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS_V3]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      addons: action.payload.result,
    }),
    [FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL_V3]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),

    [REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT]: state => ({
      ...state,
      refreshing: true,
    }),
    [REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS]: (state, action) => ({
      ...state,
      refreshing: false,
      addons: action.payload.result,
    }),
    [REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL]: (state, action) => ({
      ...state,
      refreshing: false,
      error: action.error,
    }),

    [CREATE_ADD_ON_AGREEMENT]: state => ({
      ...state,
    }),
    [CREATE_ADD_ON_AGREEMENT_SUCCESS]: state => ({
      ...state,
    }),
    [CREATE_ADD_ON_AGREEMENT_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DISCARD_ADD_ON_AGREEMENT]: state => ({
      ...state,
    }),
    [DISCARD_ADD_ON_AGREEMENT_SUCCESS]: state => ({
      ...state,
    }),
    [DISCARD_ADD_ON_AGREEMENT_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
