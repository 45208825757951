import config from 'config';
import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { createRequestAction } from 'store/util';

import { CBPItems } from './types';
import {
  FETCH_DISCOUNT_SUGGESTIONS,
  FETCH_DISCOUNT_SUGGESTIONS_SUCCESS,
  FETCH_DISCOUNT_SUGGESTIONS_FAIL,
} from './constants';

type InventoryItemDiscountsRequest = {
  contracts: Hash<CBPItems>;
  kind: string;
  commitment_length?: string;
};

export const requestActionTypes: RequestActionTypes = [
  {
    type: FETCH_DISCOUNT_SUGGESTIONS,
    meta: { keepCurrentResult: true },
  },
  FETCH_DISCOUNT_SUGGESTIONS_SUCCESS,
  FETCH_DISCOUNT_SUGGESTIONS_FAIL,
];

export const fetchInventoryItemDiscounts = (
  uuid: string | null | undefined,
  cbpItems: CBPItems | null | undefined,
  commitmentLength?: string
) => (dispatch: Dispatch<BaseAction>) => {
  const itemUuid = uuid || '';
  const body: InventoryItemDiscountsRequest = {
    contracts: {
      [itemUuid]: cbpItems,
    },
    kind: 'Variable',
  };

  if (commitmentLength != null) {
    body.commitment_length = commitmentLength;
  }

  const requestAction = createRequestAction({
    body,
    endpoint: `${config.discounts.uri}/api/v1/suggestions/available`,
    method: 'POST',
    types: requestActionTypes,
  });

  return dispatch(requestAction);
};
