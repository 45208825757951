export enum LOCATION_CHANGE_ITEM_TYPES {
  GO_BACK = 'Go Back',
  NEW_LOCATION = 'New Location',
}
export interface LocationChangeItem {
  item: LOCATION_CHANGE_ITEM_TYPES.GO_BACK | LOCATION_CHANGE_ITEM_TYPES.NEW_LOCATION;
  workflow: string;
}
export interface NotificationsCardClicked {
  category: string;
  date_populated: string;
  free_text: string;
  unread: boolean;
  workflow: string;
}

export enum NotificationBellState {
  COLLAPSED = 'Collapsed',
  EXPANDED = 'Expanded',
}
export interface NotificationsBellToggled {
  state: NotificationBellState.COLLAPSED | NotificationBellState.EXPANDED;
  unread: boolean;
  workflow: string;
}
