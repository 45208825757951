import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_S2_SEARCHED_KEYCARDS,
  FETCH_S2_SEARCHED_KEYCARDS_SUCCESS,
  FETCH_S2_SEARCHED_KEYCARDS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_S2_SEARCHED_KEYCARDS'));

export const [
  REMOVE_KEYCARD,
  REMOVE_KEYCARD_SUCCESS,
  REMOVE_KEYCARD_FAIL,
] = createRequestConstantNames(createReduxConstant('REMOVE_KEYCARD'));
