import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_S2FEENICS_LOGIN_SEARCH,
  FETCH_S2FEENICS_LOGIN_SEARCH_SUCCESS,
  FETCH_S2FEENICS_LOGIN_SEARCH_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_S2FEENICS_LOGIN_SEARCH'));

export const [
  FETCH_S2FEENICS_LOGIN_ROLES,
  FETCH_S2FEENICS_LOGIN_ROLES_SUCCESS,
  FETCH_S2FEENICS_LOGIN_ROLES_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_S2FEENICS_LOGIN_ROLES'));

export const [
  FETCH_S2FEENICS_LOGIN_CONTROLLERS,
  FETCH_S2FEENICS_LOGIN_CONTROLLERS_SUCCESS,
  FETCH_S2FEENICS_LOGIN_CONTROLLERS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_S2FEENICS_LOGIN_CONTROLLERS'));

export const [GET_S2FEENICS_LOGIN_SELECTED_DATA] = createRequestConstantNames(
  createReduxConstant('GET_S2FEENICS_LOGIN_SELECTED_DATA')
);
