import { handleActions, Action, ActionMeta } from 'redux-actions';
import moment, { Moment } from 'moment-timezone';
import { get } from 'lodash';

import { Granularity } from 'components/carouselDatePicker';

import { getCurrentReservationTimeFrame, convertV7PayloadToV4 } from './util';
import { State, CompanyReservationActionWithMetaPayload } from './types';
import {
  constants,
  DEFAULT_RESERVATIONS_PER_PAGE,
  UPDATE_RESERVATIONS_SEARCH_VALUE,
  CHANGE_GRANULARITY,
  CLEAR_GRANULARITY_AND_TIME_STATE,
} from './constants';

// Initial State
const initialState: State = {
  modalName: '',
  byCompanyUuid: {
    loading: false,
    loaded: false,
    error: null,
    items: {},
    page: 1,
    totalPages: 1,
    perPage: DEFAULT_RESERVATIONS_PER_PAGE,
    startDate: moment(),
  },
  success: false,
  loading: false,
  loaded: false,
  error: null,
  searchValue: '',
  currentDayDate: moment(),
  granularity: null,
  currentReservationsTimeFrame: moment(),
  lastSelectedDay: moment(),
};

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [constants.FETCH_RESERVATIONS]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),

    [constants.FETCH_RESERVATIONS_FAIL]: (state: State, action: Action<any>): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
    [constants.FETCH_CONFERENCE_ROOM_RESERVATIONS_BY_COMPANY]: (
      state: State,
      action: ActionMeta<any, { currentReservationsTimeFrame: Moment }>
    ) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        loading: true,
        loaded: false,
        error: null,
        totalPages: 0,
      },
      currentReservationsTimeFrame: action.meta.currentReservationsTimeFrame,
    }),
    [constants.FETCH_CONFERENCE_ROOM_RESERVATIONS_BY_COMPANY_SUCCESS]: (
      state: State,
      action: CompanyReservationActionWithMetaPayload
    ) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        page: action.meta.page,
        loading: false,
        loaded: true,
        error: null,
        total: get(action, 'payload.meta.stats.total.count', {}),
        totalPages: Math.ceil(
          (get(action, 'payload.meta.stats.total.count', 1) * 1.0) / DEFAULT_RESERVATIONS_PER_PAGE
        ),
        items: {
          ...state.byCompanyUuid.items,
          [action.meta.companyUuid]: convertV7PayloadToV4(action.payload),
        },
      },
    }),
    [constants.FETCH_CONFERENCE_ROOM_RESERVATIONS_BY_COMPANY_FAIL]: (
      state: State,
      action: Action<any>
    ) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        loading: false,
        loaded: false,
        error: action.error,
      },
    }),
    [UPDATE_RESERVATIONS_SEARCH_VALUE]: (state: State, action: Action<string>) => ({
      ...state,
      searchValue: action.payload,
    }),
    [CHANGE_GRANULARITY]: (state: State, action: Action<string>) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        page: 1,
        totalPages: 1,
      },
      granularity: action.payload as Granularity,
      lastSelectedDay: getCurrentReservationTimeFrame(action.payload, state),
      currentReservationsTimeFrame: getCurrentReservationTimeFrame(action.payload, state),
    }),
    [CLEAR_GRANULARITY_AND_TIME_STATE]: (state: State) => ({
      ...state,
      granularity: null,
      currentReservationsTimeFrame: moment(),
      lastSelectedDay: moment(),
    }),
  },
  initialState
);

export default reducer;
