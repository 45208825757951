import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_EMAIL_LOCALES,
  FETCH_EMAIL_LOCALES_SUCCESS,
  FETCH_EMAIL_LOCALES_FAIL,
] = createRequestConstantNames(cc('FETCH_EMAIL_LOCALES'));

export const SELECT_EMAIL_LOCALE = cc('SELECT_EMAIL_LOCALE');
