import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

function isClassComponent<Props>(
  Component: React.ComponentType<Props>
): Component is React.ComponentClass<Props> {
  return typeof Component === 'function' && !!Component.prototype?.isReactComponent;
}

const duplicateComponent = <Props extends {}, Statics extends {}>(
  Component: React.ComponentType<Props>,
  staticKeys: Array<keyof Statics>
): React.ComponentType<Props> => {
  if (isClassComponent(Component)) {
    // eslint-disable-next-line react/prefer-stateless-function
    return class ComponentWithoutStatics extends Component {};
  }
  type StaticKeysMap = Record<keyof Statics, true>;
  const staticKeysMap = staticKeys.reduce<StaticKeysMap>(
    (aggregator, key) => ({ ...aggregator, [key]: true }),
    {} as StaticKeysMap
  );
  const EncapsulatedComponent = React.forwardRef(function EncapsulatedComponent(
    props,
    ref: React.Ref<typeof Component>
  ) {
    return <Component {...props} ref={ref} />;
  }) as React.FC<Props>;
  const ComponentWithoutStatics: typeof EncapsulatedComponent &
    hoistNonReactStatics.NonReactStatics<typeof Component, StaticKeysMap> = hoistNonReactStatics(
    EncapsulatedComponent,
    Component,
    staticKeysMap
  );
  return ComponentWithoutStatics;
};

const replaceComponentStatics = <Props extends {}, Statics extends {}>(
  Component: React.ComponentType<Props>,
  statics: Statics
) => {
  const staticKeys = Object.keys(statics) as Array<keyof Statics>;
  const ComponentWithoutStatics = duplicateComponent(Component, staticKeys);
  const ComponentWithReplacedStatics = staticKeys.reduce((ComponentWithStatics, key) => {
    ComponentWithStatics[key as any] = statics[key];
    return ComponentWithStatics;
  }, ComponentWithoutStatics) as typeof ComponentWithoutStatics & Statics;

  ComponentWithReplacedStatics.displayName = Component.displayName ?? Component.name;

  return ComponentWithReplacedStatics;
};

export default replaceComponentStatics;
