import moment, { Moment } from 'moment-timezone';
import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns';

import { Options as AnalyticsOptions } from 'store/modules/analytics';
import { dateFormatsDeprecated } from 'lib/constants';
import { EmailTemplateData } from 'features/mimo/redux/emailTemplateTypes/types';
import { Location } from 'store/modules/locations';
import { todayByLocation } from 'features/visitors/util';

import {
  Account,
  EmailVariable,
  EmailReservableVariable,
  MimoEmail,
  MimoReservable,
  Move,
  MoveDirection,
  ReservableRequestWithCapacity,
  SentEmailData,
  ServiceRetainer,
} from './types';
import {
  ANALYTICS_PAGE_TYPES,
  EMAIL_DISPLAY_TEXT_FOR_TYPE,
  MOVE_DIRECTION_TYPES,
  PARAM_TYPE,
  TIME_FORMATS,
} from './constants';

const MIMO_DAY_OFFSET = 6; // Day in month is zero-indexed, so 6 is actually the 7th of the month

export const getMimoDateRange = (
  date: Date | Moment = moment()
): {
  startDate: Moment;
  endDate: Moment;
} => {
  const dayInMonth = moment(date).format(dateFormatsDeprecated.date_only_long);
  let endDate;

  if (Number(dayInMonth) <= MIMO_DAY_OFFSET) {
    endDate = moment(date)
      .startOf('month')
      .add(MIMO_DAY_OFFSET - 1, 'days');
  } else {
    endDate = moment(date)
      .add(1, 'months')
      .startOf('month')
      .add(MIMO_DAY_OFFSET - 1, 'days');
  }

  const startDate = moment(endDate).subtract(1, 'months').add(1, 'days');

  return { startDate, endDate };
};

// Analytics
export const interactionMetadata = ({
  move,
  pageType = ANALYTICS_PAGE_TYPES.MIMO_TRACKER,
}: {
  reservable?: MimoReservable;
  direction?: MoveDirection;
  move?: Move;
  pageType?: typeof ANALYTICS_PAGE_TYPES[keyof typeof ANALYTICS_PAGE_TYPES];
} = {}): Partial<AnalyticsOptions> => ({
  page_type: pageType,
  move_id: move?.id,
});

export const moveTime = (move: Move, fmt: string = TIME_FORMATS.CSV_12_HOUR): string =>
  moment(`${move.moveDate} ${move.moveTime}`).format(fmt);

export const FURNITURE_REQUESTS = [
  'Space / Office number',
  'Company name',
  'Capacity',
  'Chairs - Ask',
  'Chairs - Actual',
  'Desks - Ask',
  'Desks - Actual',
  'Cabinets - Ask',
  'Cabinets - Actual',
  'Status',
];

export const escapeCSVQuotes = (str: string | null | undefined) =>
  str ? `"${str.replace(/"/g, '""')}"` : '""';

export const mergeMimoCSVHeaders = (mimoLines: Array<string>, headers: Array<string>): string =>
  [headers.map(escapeCSVQuotes).join('\t'), ...mimoLines].join('\n');

export const isSrPaid = (sr: ServiceRetainer) => sr.finalized && sr.outstandingSr.amount <= 0;

export const getServiceRetainerStatus = (sr: ServiceRetainer) => {
  const label =
    (!sr.finalized && 'SR Invoice Not Finalized') ||
    (isSrPaid(sr) ? 'SR Paid' : 'Outstanding SR Debt');

  return {
    isPaid: isSrPaid(sr),
    label,
  };
};

export const getCSVLinesForFurnitureRequest = ({
  capacity,
  company,
  data: { reservableName, chairs, desks, cabinets, ticketStatus },
}: ReservableRequestWithCapacity): string =>
  [
    reservableName,
    company?.name,
    String(capacity),
    String(chairs),
    '',
    String(desks),
    '',
    String(cabinets),
    '',
    ticketStatus,
  ]
    .map(escapeCSVQuotes)
    .join('\t');

export const getCSVFurnitureRequests = (requests: Array<ReservableRequestWithCapacity>): string => {
  const requestLinesCSV = requests.map(getCSVLinesForFurnitureRequest);
  return mergeMimoCSVHeaders(requestLinesCSV, FURNITURE_REQUESTS);
};

export const updateReservableMove = (
  reservableToUpdate: MimoReservable,
  move: Move
): MimoReservable => {
  if (move.moveDirection === MOVE_DIRECTION_TYPES.moveIn) {
    return {
      ...reservableToUpdate,
      moveIns: reservableToUpdate.moveIns.map(moveToUpdate => {
        if (moveToUpdate.id !== move.id) return moveToUpdate;

        return {
          ...moveToUpdate,
          note: move.note ?? moveToUpdate.note,
          moveDate: move.moveDate ?? moveToUpdate.moveDate,
          moveTime: move.moveTime ?? moveToUpdate.moveTime,
          moveStatus: move.moveStatus ?? moveToUpdate.moveStatus,
        };
      }),
    };
  }

  return {
    ...reservableToUpdate,
    moveOuts: reservableToUpdate.moveOuts.map(moveToUpdate => {
      if (moveToUpdate.id !== move.id) return moveToUpdate;

      return {
        ...moveToUpdate,
        note: move.note ?? moveToUpdate.note,
        moveDate: move.moveDate ?? moveToUpdate.moveDate,
        moveTime: move.moveTime ?? moveToUpdate.moveTime,
        moveStatus: move.moveStatus ?? moveToUpdate.moveStatus,
      };
    }),
  };
};

const formatReservables = (reservables: Array<MimoReservable>): Array<EmailReservableVariable> =>
  reservables.map(reservable => ({
    reservableTypeCategory: EMAIL_DISPLAY_TEXT_FOR_TYPE[reservable.type] || reservable.type,
    reservableName: reservable.name,
  }));

export const getEmailVariablesFor = (
  account: Account,
  moveInReservables: Array<MimoReservable>,
  moveOutReservables: Array<MimoReservable>
): Array<EmailVariable> => {
  // Naive selection by first move, same as current approach in MIMO emails - MIMO-185
  const firstMoveIn = moveInReservables.find(reservable => !!reservable.moveIns.length)?.moveIns[0];
  const firstMoveOut = moveOutReservables.find(reservable => !!reservable.moveOuts.length)
    ?.moveOuts[0];
  const displayMoveInDate =
    firstMoveIn && moment(firstMoveIn.moveDate).format(dateFormatsDeprecated.iso_date);
  const displayMoveOutDate =
    firstMoveOut && moment(firstMoveOut.moveDate).format(dateFormatsDeprecated.iso_date);
  const moveInLocationName = account.emailCommunications.transferLocationName;

  const moveInOffices = formatReservables(moveInReservables);
  const moveOutOffices = formatReservables(moveOutReservables);

  return [
    { name: 'memberName', value: account.primaryMemberName, type: PARAM_TYPE.STRING },
    { name: 'companyName', value: account.name, type: PARAM_TYPE.STRING },
    { name: 'moveInDate', value: displayMoveInDate, type: PARAM_TYPE.DATE },
    { name: 'moveInTime', value: firstMoveIn?.moveTime, type: PARAM_TYPE.TIME },
    { name: 'moveInOffices', value: moveInOffices, type: PARAM_TYPE.RESERVABLE_LIST },
    { name: 'moveInLocation', value: moveInLocationName, type: PARAM_TYPE.STRING },
    { name: 'moveOutDate', value: displayMoveOutDate, type: PARAM_TYPE.DATE },
    { name: 'moveOutOffices', value: moveOutOffices, type: PARAM_TYPE.RESERVABLE_LIST },
    { name: 'moveOutTime', value: firstMoveOut?.moveTime, type: PARAM_TYPE.TIME },
  ];
};

export const emailSentAt = (email: MimoEmail): string | null | undefined =>
  email.message?.sentAt || email.message?.createdAt;
const emailSentBy = (email: MimoEmail): string | null | undefined =>
  email.message?.user?.id || email.manualMessage?.user?.id;

export const convertToSentEmailData = (
  mimoEmail?: MimoEmail,
  emailTypes: Hash<EmailTemplateData> | null | undefined = null,
  options: Partial<SentEmailData> = {}
): SentEmailData | typeof undefined => {
  const templateType = mimoEmail?.message?.type;
  const templateName = emailTypes && templateType && emailTypes[templateType]?.name;

  return (
    mimoEmail && {
      sentAt: emailSentAt(mimoEmail),
      sentBy: emailSentBy(mimoEmail),
      templateType,
      templateName,
      isManuallySent: !mimoEmail.message,
      ...options,
    }
  );
};

export const isTodayByLocationBetween = (
  location: Location,
  startDate: string,
  dueDate: string
) => {
  const today = todayByLocation(location);
  const start = parseISO(startDate);
  const due = parseISO(dueDate);
  const isSameOrAfterStart = isAfter(today, start) || isSameDay(today, start);
  const isSameOrBeforeDue = isBefore(today, due) || isSameDay(today, due);
  return isSameOrAfterStart && isSameOrBeforeDue;
};
