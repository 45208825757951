// Action Constants
import cc from 'store/util/createReduxConstant';

export const FETCH_OCCUPANCIES_BY_LOCATION = cc('FETCH_OCCUPANCIES_BY_LOCATION');
export const FETCH_OCCUPANCIES_BY_LOCATION_SUCCESS = cc('FETCH_OCCUPANCIES_BY_LOCATION_SUCCESS');
export const FETCH_OCCUPANCIES_BY_LOCATION_FAIL = cc('FETCH_OCCUPANCIES_BY_LOCATION_FAIL');

export const FETCH_OCCUPANCY = cc('FETCH_OCCUPANCY');
export const FETCH_OCCUPANCY_SUCCESS = cc('FETCH_OCCUPANCY_SUCCESS');
export const FETCH_OCCUPANCY_FAIL = cc('FETCH_OCCUPANCY_FAIL');

export const FETCH_ENDED_OCCUPANCIES = cc('FETCH_ENDED_OCCUPANCIES');
export const FETCH_ENDED_OCCUPANCIES_SUCCESS = cc('FETCH_ENDED_OCCUPANCIES_SUCCESS');
export const FETCH_ENDED_OCCUPANCIES_FAIL = cc('FETCH_ENDED_OCCUPANCIES_FAIL');
