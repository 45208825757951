import { handleActions } from 'redux-actions';

import {
  GET_COMPANY_CUSTOM_ACCESS_LEVELS,
  GET_COMPANY_CUSTOM_ACCESS_LEVELS_SUCCESS,
  GET_COMPANY_CUSTOM_ACCESS_LEVELS_FAIL,
  REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL,
  REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL_SUCCESS,
  REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL_FAIL,
  GET_BUILDINGS_LIST,
  GET_BUILDINGS_LIST_SUCCESS,
  GET_BUILDINGS_LIST_FAIL,
  GET_BUILDING_CUSTOM_ACCESS_LEVELS,
  GET_BUILDING_CUSTOM_ACCESS_LEVELS_SUCCESS,
  GET_BUILDING_CUSTOM_ACCESS_LEVELS_FAIL,
  ASSOCIATE_ACCESS_LEVEL_TO_COMPANY,
  ASSOCIATE_ACCESS_LEVEL_TO_COMPANY_SUCCESS,
  ASSOCIATE_ACCESS_LEVEL_TO_COMPANY_FAIL,
} from 'features/companies/redux/customAccessLevels/constants';
import { CustomAccessLevels } from 'features/companies/redux/customAccessLevels/types';

export interface State {
  companyCustomAccessLevels: CustomAccessLevels[];
  companyCustomAccessLevelsLoading: boolean;
  companyCustomAccessLevelsLoadingFail: boolean;
  removingCompanyCustomAccessLevel: boolean;
  removingCompanyCustomAccessLevelSuccess: boolean;
  removingCompanyCustomAccessLevelFail: boolean;
  buildingsList: any[];
  loadingBuildingsList: boolean;
  loadingBuildingsListSuccess: boolean;
  loadingBuildingsListFail: boolean;
  buildingCustomAccessLevels: CustomAccessLevels[];
  buildingCustomAccessLevelsLoading: boolean;
  buildingCustomAccessLevelsLoadingSuccess: boolean;
  buildingCustomAccessLevelsLoadingFail: boolean;
  associatingAccessLevelToCompany: boolean;
  associatingAccessLevelToCompanySuccess: boolean;
  associatingAccessLevelToCompanyFail: boolean;
}

export interface CompanyCustomAccessLevelsSubset {
  companyCustomAccessLevels: State;
}

const initialState: State = {
  companyCustomAccessLevels: [],
  companyCustomAccessLevelsLoading: false,
  companyCustomAccessLevelsLoadingFail: false,
  removingCompanyCustomAccessLevel: false,
  removingCompanyCustomAccessLevelSuccess: false,
  removingCompanyCustomAccessLevelFail: false,
  buildingsList: [],
  loadingBuildingsList: false,
  loadingBuildingsListSuccess: false,
  loadingBuildingsListFail: false,
  buildingCustomAccessLevels: [],
  buildingCustomAccessLevelsLoading: false,
  buildingCustomAccessLevelsLoadingSuccess: false,
  buildingCustomAccessLevelsLoadingFail: false,
  associatingAccessLevelToCompany: false,
  associatingAccessLevelToCompanySuccess: false,
  associatingAccessLevelToCompanyFail: false,
};

export const reducer = handleActions<State, any, any>(
  {
    [GET_COMPANY_CUSTOM_ACCESS_LEVELS]: (state: State): State => ({
      ...state,
      companyCustomAccessLevelsLoading: true,
      companyCustomAccessLevelsLoadingFail: false,
    }),

    [GET_COMPANY_CUSTOM_ACCESS_LEVELS_SUCCESS]: (state: State, action): State => ({
      ...state,
      companyCustomAccessLevels: action.payload.result,
      companyCustomAccessLevelsLoading: false,
    }),

    [GET_COMPANY_CUSTOM_ACCESS_LEVELS_FAIL]: (state: State): State => ({
      ...state,
      companyCustomAccessLevels: [],
      companyCustomAccessLevelsLoading: false,
      companyCustomAccessLevelsLoadingFail: true,
    }),

    [REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL]: (state: State): State => ({
      ...state,
      removingCompanyCustomAccessLevel: true,
    }),

    [REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL_SUCCESS]: (state: State): State => ({
      ...state,
      removingCompanyCustomAccessLevel: false,
      removingCompanyCustomAccessLevelSuccess: true,
    }),

    [REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL_FAIL]: (state: State): State => ({
      ...state,
      removingCompanyCustomAccessLevel: false,
      removingCompanyCustomAccessLevelSuccess: false,
      removingCompanyCustomAccessLevelFail: true,
    }),

    [GET_BUILDINGS_LIST]: (state: State): State => ({
      ...state,
      loadingBuildingsList: true,
    }),

    [GET_BUILDINGS_LIST_SUCCESS]: (state: State, action): State => ({
      ...state,
      buildingsList: action.payload.data,
      loadingBuildingsList: false,
      loadingBuildingsListSuccess: true,
    }),

    [GET_BUILDINGS_LIST_FAIL]: (state: State): State => ({
      ...state,
      buildingsList: [],
      loadingBuildingsList: false,
      loadingBuildingsListSuccess: false,
      loadingBuildingsListFail: true,
    }),

    [GET_BUILDING_CUSTOM_ACCESS_LEVELS]: (state: State): State => ({
      ...state,
      buildingCustomAccessLevelsLoading: true,
      buildingCustomAccessLevelsLoadingSuccess: false,
      buildingCustomAccessLevelsLoadingFail: false,
    }),

    [GET_BUILDING_CUSTOM_ACCESS_LEVELS_SUCCESS]: (state: State, action): State => ({
      ...state,
      buildingCustomAccessLevels: action.payload.result,
      buildingCustomAccessLevelsLoadingSuccess: true,
      buildingCustomAccessLevelsLoading: false,
    }),

    [GET_BUILDING_CUSTOM_ACCESS_LEVELS_FAIL]: (state: State): State => ({
      ...state,
      buildingCustomAccessLevels: [],
      buildingCustomAccessLevelsLoading: false,
      buildingCustomAccessLevelsLoadingSuccess: false,
      buildingCustomAccessLevelsLoadingFail: true,
    }),

    [ASSOCIATE_ACCESS_LEVEL_TO_COMPANY]: (state: State): State => ({
      ...state,
      associatingAccessLevelToCompany: true,
      associatingAccessLevelToCompanySuccess: false,
      associatingAccessLevelToCompanyFail: false,
    }),

    [ASSOCIATE_ACCESS_LEVEL_TO_COMPANY_SUCCESS]: (state: State): State => ({
      ...state,
      associatingAccessLevelToCompany: false,
      associatingAccessLevelToCompanySuccess: true,
    }),

    [ASSOCIATE_ACCESS_LEVEL_TO_COMPANY_FAIL]: (state: State): State => ({
      ...state,
      associatingAccessLevelToCompany: false,
      associatingAccessLevelToCompanySuccess: false,
      associatingAccessLevelToCompanyFail: true,
    }),
  },
  initialState
);

export default reducer;
