import { handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

export const BUILD_TERMS = cc('BUILD_TERMS');
export const UPDATE_TERMS_FOR_CONTRACT = cc('UPDATE_TERMS_FOR_CONTRACT');
export const CLEAR_TERMS = cc('CLEAR_TERMS');

export const initialState = {};

type TermProfile = {
  storeKey: string;
  locationUUID: string;
  moveIn: string;
};

export type Terms = {
  location_uuid: string;
  move_in_date: string;
  move_out_notice: number;
};

export interface State {
  [key: string]: Terms;
}

export const reducer = handleActions<State, any>(
  {
    [BUILD_TERMS]: (state, action: { payload: Array<TermProfile> }) =>
      action.payload.reduce(
        (result, profile) => ({
          ...result,
          [profile.storeKey]: state[profile.storeKey] || {
            location_uuid: profile.locationUUID,
            move_in_date: profile.moveIn,
            move_out_notice: 1,
          },
        }),
        {}
      ),
    [UPDATE_TERMS_FOR_CONTRACT]: (state, action) => ({
      ...state,
      [action.payload.key]: {
        ...state[action.payload.key],
        ...action.payload.terms,
      },
    }),
    [CLEAR_TERMS]: () => ({}),
  },
  initialState
);

export const buildTerms = (profiles: Array<TermProfile>) => ({
  type: BUILD_TERMS,
  payload: profiles,
});

export const updateTermsForContract = (key: string, terms: Partial<Terms>) => ({
  type: UPDATE_TERMS_FOR_CONTRACT,
  payload: { key, terms },
});

export const clearTerms = () => ({ type: CLEAR_TERMS });

export default reducer;
