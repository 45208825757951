import { handleActions } from 'redux-actions';

import {
  GET_MEMBER_INVITES,
  GET_MEMBER_INVITES_SUCCESS,
  GET_MEMBER_INVITES_FAIL,
  ACCEPT_MEMBER_INVITE,
  ACCEPT_MEMBER_INVITE_SUCCESS,
  ACCEPT_MEMBER_INVITE_FAIL,
  CANCEL_MEMBER_INVITE,
  CANCEL_MEMBER_INVITE_SUCCESS,
  CANCEL_MEMBER_INVITE_FAIL,
  RESEND_MEMBER_INVITE,
  RESEND_MEMBER_INVITE_SUCCESS,
  RESEND_MEMBER_INVITE_FAIL,
} from 'features/companies/redux/invites/constants';
import { ErrorContainer } from 'store/errors';

import { Invitation } from './types';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: ErrorContainer | null | undefined;
  invitations: Invitation[];
  inviteOperationInProgress: boolean;
}

export interface MemberInvitesSubset {
  memberInvites: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  invitations: [],
  error: null,
  inviteOperationInProgress: false,
};

export const reducer = handleActions<State, any>(
  {
    [GET_MEMBER_INVITES]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [GET_MEMBER_INVITES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      invitations: action.payload.invitations,
    }),
    [GET_MEMBER_INVITES_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: {
        status: action.payload.status,
        message: action.payload.message,
        type: action.payload.type,
        url: action.payload.response?.url,
      },
    }),
    [ACCEPT_MEMBER_INVITE]: state => ({
      ...state,
      inviteOperationInProgress: true,
    }),
    [ACCEPT_MEMBER_INVITE_SUCCESS]: state => ({
      ...state,
      inviteOperationInProgress: false,
    }),
    [ACCEPT_MEMBER_INVITE_FAIL]: state => ({
      ...state,
      inviteOperationInProgress: false,
    }),
    [CANCEL_MEMBER_INVITE]: state => ({
      ...state,
      inviteOperationInProgress: true,
    }),
    [CANCEL_MEMBER_INVITE_SUCCESS]: state => ({
      ...state,
      inviteOperationInProgress: false,
    }),
    [CANCEL_MEMBER_INVITE_FAIL]: state => ({
      ...state,
      inviteOperationInProgress: false,
    }),
    [RESEND_MEMBER_INVITE]: state => ({
      ...state,
      inviteOperationInProgress: true,
    }),
    [RESEND_MEMBER_INVITE_SUCCESS]: state => ({
      ...state,
      inviteOperationInProgress: false,
    }),
    [RESEND_MEMBER_INVITE_FAIL]: state => ({
      ...state,
      inviteOperationInProgress: false,
    }),
  },
  initialState
);

export default reducer;
