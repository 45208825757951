import { fetchMemberships } from 'features/reservations/lib/membershipAPI';
import { Memberships, MemberOption } from 'features/reservations/types';

import { DropDownOptions } from './types';

export const companiesToName = companies =>
  companies
    .map(company => company.name)
    .filter(name => !!name)
    .join(', ');

export const formatText = (member: MemberOption | null | undefined): string => {
  if (member) {
    const { name, companies } = member;
    return `${name}${companies.length ? ' - ' : ''}${companiesToName(companies)}`;
  }
  return '';
};

export const mapMemberToOptions = async (
  members: Array<MemberOption>
): Promise<DropDownOptions> => {
  const activeMemberships: Memberships = await fetchMemberships(
    members.map(member => member?.uuid).filter(Boolean)
  );
  const activeMemberUuids: string[] = activeMemberships.map(member => member.userUuid);

  return members
    .filter(member => activeMemberUuids.includes(member?.uuid))
    .map(member => ({
      key: member.uuid,
      title: formatText(member),
      value: member.uuid,
      member,
    }));
};
