import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

import { EntityType, SearchRequest, AdapterResult } from '../types';
import { Adapter } from '../searchService';

const validHotstamp = /^\d{2,}$/;

export default class AirlockAdapter implements Adapter {
  async search(request: SearchRequest): Promise<AdapterResult> {
    const query = request.query;
    const queryWithoutSpaces = (query || '').replace(/\s/g, '');
    const isHotstamp = validHotstamp.test(queryWithoutSpaces);

    if (!isHotstamp) {
      return {
        [EntityType.KEYCARD]: {
          items: [],
          totalCount: 0,
          facets: undefined,
        },
      };
    }

    const accessToken = await getAccessToken();
    const response = await fetch(`${config.airlock.uri}/api/v1/keycards/?hotstamp=${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token token=${accessToken}`,
        'x-request-id': uuidv4(),
      },
    });
    if (!response.ok) {
      throw new Error('Unable to fetch keycards');
    }

    const json = await response.json();
    if (!json.meta.success) {
      throw new Error(json?.result?.message || 'Unable to fetch keycards');
    }

    const items = json?.result || [];
    return {
      [EntityType.KEYCARD]: {
        items,
        totalCount: items.length,
        facets: undefined,
      },
    };
  }
}
