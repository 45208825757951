import { times } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { ApiError, messageHandler } from 'store/errors';
import config from 'config';

export type LocationData = {
  name: string;
  code: string;
  default_country: string;
  default_country_code: string;
  state: string;
  city: string;
  opened_on: string | null | undefined;
  time_zone: string;
  support_email: string;
  primary_gateway: string;
  ppwk_default_locale: string | null | undefined;
  normalized_default_currency: string;
  countrygeo: {};
  country?: {};
  default_locale: string;
  entrance_instructions: string;
  entrance_instructions_localized: string;
  geogroupings: Array<{}>;
  id: string;
  latitude: string;
  longitude: string;
  marketGeo?: string;
  marketGeoId?: string;
  default_name: string;
  netverify_policy: string;
  netverify_activated_on: string;
  submarket?: string;
  is_migrated: boolean;
};

export type LocationsResponse = {
  data: Array<{
    id: string;
    type: string;
    attributes: LocationData;
  }>;
};

const getLocationURL = (page?: number) => {
  const url = new URL(`${config.locationsAPI.uri}/api/v3/buildings`);
  url.searchParams.set('serializer', 'spacestation');
  url.searchParams.set('unfiltered', 'true');

  if (page) url.searchParams.set('page', page.toString());

  return url.toString();
};

const getPaginationFromHeader = (
  headers: Headers
): {
  perPage: number;
  total: number;
} => ({
  perPage: parseInt(headers.get('Per-Page') || '', 10) || 1,
  total: parseInt(headers.get('Total') || '', 10) || 0,
});

const fetchPageLocations = async (page: number, reqParams: RequestInit) => {
  const res = await fetch(getLocationURL(page), reqParams);
  if (!res.ok)
    throw new ApiError(
      res.status,
      res.statusText,
      res,
      messageHandler(res.status, res.statusText, config.locationsAPI.uri)
    );

  return res.json();
};

const unifyResponses = (values: Array<LocationsResponse>): LocationsResponse => {
  return values.reduce(
    (unifiedResponseData, localRes) => {
      unifiedResponseData.data = unifiedResponseData.data.concat(localRes.data);
      return unifiedResponseData;
    },
    { data: [] }
  );
};

export const fetchAllLocations = async (): Promise<LocationsResponse> => {
  const accessToken = await getAccessToken();
  const reqParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-request-id': uuidv4(),
    },
  };

  const res = await fetch(getLocationURL(), reqParams);
  if (!res.ok)
    throw new ApiError(
      res.status,
      res.statusText,
      res,
      messageHandler(res.status, res.statusText, config.locationsAPI.uri)
    );

  const { perPage, total } = getPaginationFromHeader(res.headers);

  if (perPage > total) return res.json();

  const remainingPages = Math.ceil(total / perPage) - 1;

  return Promise.all([
    res.json(),
    ...times(remainingPages, page => fetchPageLocations(page + 2, reqParams)),
  ]).then(unifyResponses);
};
