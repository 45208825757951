export interface RequestMethodsParams {
  path: string;
  params?: Object;
  data?: Object;
  config?: Config;
}

export enum ApiMethod {
  GET = 'get',
  PATCH = 'patch',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export enum AuthPrefix {
  TOKEN,
  BEARER,
}

/**
 * Config - interface of config information for the request
 * @attribute authPrefix? - AuthPrefix either Bearer or Token, default Bearer
 * @attribute isCamelCase? - boolean true if camelcase is needed, default TRUE
 * @attribute requireXRequestId? - boolean, default TRUE
 * @attribute useArrayFormatBrackets? - boolean, default FALSE
 * @attribute entry? - string use if a custom wrapper exists on data, default `data`
 * @attribute getEntry? - boolean return based on 'entry', default TRUE
 * @attribute headerConfig? - HeaderConfig, default {}
 * @attribute signal? - AbortSignal
 * */
export interface Config {
  entry?: string;
  getEntry?: boolean;
  header?: HeaderConfig;
  isCamelCase?: boolean;
  requireXRequestId?: boolean;
  signal?: AbortSignal;
  useArrayFormatBrackets?: boolean;
  withRetry?: boolean;
}

/**
 * HeaderConfig - interface of config information for the header
 * @attribute authPrefix? - AuthPrefix whether Bearer or Token, default BEARER
 * */
export interface HeaderConfig {
  authPrefix?: AuthPrefix;
  // custom headers
  [key: string]: any;
}
