import { handleActions } from 'redux-actions';
import { Moment } from 'moment-timezone';
import qs from 'query-string';
import { pick } from 'lodash';

import { BaseAction, ActionWithPayload, Dispatch } from 'store/types';
import { createRequestAction } from 'store/util';
import { camelCaseJson } from 'lib/util';
import config from 'config';
import cc from 'store/util/createReduxConstant';
import { dateFormatsDeprecated } from 'lib/constants';

import { Tour, ToursState } from './types';

// Constants
const TOUR_FIELDS = [
  'id',
  'buildingId',
  'date',
  'startedAt',
  'endedAt',
  'displayStartTime',
  'displayEndTime',
  'notes',
  'timeZone',
  'contactInfo.contactUuid',
  'contactInfo.contactName',
  'contactInfo.email',
  'contactInfo.phone',
  'contactInfo.companyUuid',
  'contactInfo.companyName',
  'contactInfo.numberOfDesks',
  'contactInfo.salesforceId',
  'assignedTo.id',
  'assignedTo.email',
  'assignedTo.name',
  'status',
];

// Action Constants
const FETCH_TODAYS_TOURS = cc('FETCH_TODAYS_TOURS');
const FETCH_TODAYS_TOURS_SUCCESS = cc('FETCH_TODAYS_TOURS_SUCCESS');
const FETCH_TODAYS_TOURS_FAIL = cc('FETCH_TODAYS_TOURS_FAIL');

// Initial State
const initialState: ToursState = {
  loading: false,
  loaded: false,
  error: null,
  items: [],
};

// Reducer
export const reducer = handleActions<ToursState, any>(
  {
    [FETCH_TODAYS_TOURS]: (state: ToursState) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_TODAYS_TOURS_SUCCESS]: (
      state: ToursState,
      action: ActionWithPayload<{ tours: Array<Tour> }>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      items: action.payload.tours.map(tour => pick(tour, TOUR_FIELDS) as Tour),
    }),
    [FETCH_TODAYS_TOURS_FAIL]: (state: ToursState, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
      items: [],
    }),
  },
  initialState
);

// Action Creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchTours = (locationUuid: string, date: Moment) => (
  dispatch: Dispatch<BaseAction>
) => {
  const params = qs.stringify({
    building_id: locationUuid,
    date: date.format(dateFormatsDeprecated.iso_date),
  });
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/tours?${params}`,
    types: [FETCH_TODAYS_TOURS, FETCH_TODAYS_TOURS_SUCCESS, FETCH_TODAYS_TOURS_FAIL],
    getPayloadFromResponse: camelCaseJson,
  });
  return dispatch(requestAction);
};

export default reducer;
