import { Action, handleActions } from 'redux-actions';
import { find, get, keyBy, compact } from 'lodash';

import { createRequestAction } from 'store/util';
import { BaseAction, Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';
import { UpdateBuildingResponse } from 'store/modules/buildings/types';
import config from 'config';

import { BuildingResponseItem } from '../locations/convertors';

export interface Building {
  code: string;
  country?: {
    iso?: string;
  };
  countrygeo: {
    id?: string;
    iso?: string;
    name?: string;
  };
  default_locale: string;
  entrance_instructions: string;
  entrance_instructions_localized: string | null;
  geogroupings: Array<{}>;
  id: string;
  latitude: string;
  longitude: string;
  marketGeo?: string;
  marketGeoId?: string;
  name: string;
  netverify_policy: string;
  netverify_activated_on: string;
  submarket?: string;
}

export interface Buildings {
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updated: boolean;
  byId: {};
  error: string | null | undefined;
}

// Action Constants
export const FETCH_BUILDINGS = cc('FETCH_BUILDINGS');
export const FETCH_BUILDINGS_SUCCESS = cc('FETCH_BUILDINGS_SUCCESS');
export const FETCH_BUILDINGS_FAIL = cc('FETCH_BUILDINGS_FAIL');

export const UPDATE_BUILDING = cc('UPDATE_BUILDING');
export const UPDATE_BUILDING_SUCCESS = cc('UPDATE_BUILDING_SUCCESS');
export const UPDATE_BUILDING_FAIL = cc('UPDATE_BUILDING_FAIL');

export interface State {
  byId: Hash<Building>;
  error: any;
  loaded: boolean;
  loading: boolean;
  updated: boolean;
  updating: boolean;
}

export interface BuildingsSubset {
  buildings: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  updating: false,
  updated: false,
  byId: {},
  error: null,
};

function simplifyData(building: Building): Building {
  const market = find(building.geogroupings, { type: 'Marketgeo' });
  const submkt = find(building.geogroupings, { type: 'Submarket' });
  const country = building.countrygeo;

  return {
    ...building,
    marketGeo: get(market, 'name', 'Unknown Market'),
    marketGeoId: get(market, 'id', ''),
    submarket: get(submkt, 'name', ''),
    country,
  };
}

function keyById(data: Array<any>): Hash<any> {
  return keyBy(data, 'id');
}

function updateBuildingEntranceInstructions(
  byId,
  building: Partial<Building> = {}
): Hash<Building> {
  if (!building.id || !byId[building.id]) {
    return byId;
  }

  return {
    ...byId,
    [building.id]: {
      ...byId[building.id],
      entrance_instructions: building.entrance_instructions,
      entrance_instructions_localized: building.entrance_instructions_localized,
    },
  };
}

const getSimplifiedData = (data: Array<BuildingResponseItem>) =>
  compact<BuildingResponseItem>(data).map(building => simplifyData(building.attributes));

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_BUILDINGS]: state => ({ ...state, loading: true, loaded: false }),

    [FETCH_BUILDINGS_SUCCESS]: (state, action) => {
      const rawData = get(action, 'payload.data', {});
      const data = getSimplifiedData(rawData);

      return {
        ...state,
        loading: false,
        loaded: true,
        byId: keyById(data),
        error: null,
      };
    },

    [FETCH_BUILDINGS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),

    [UPDATE_BUILDING]: state => ({ ...state, updating: true, updated: false }),

    [UPDATE_BUILDING_SUCCESS]: (state: State, action: Action<UpdateBuildingResponse>): State => ({
      ...state,
      updating: false,
      updated: true,
      error: null,
      byId: updateBuildingEntranceInstructions(state.byId, action.payload.building),
    }),

    [UPDATE_BUILDING_FAIL]: (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    }),
  },
  initialState
);

// Action Creators
export const fetcher = () => {
  return createRequestAction({
    endpoint: `${config.locationsAPI.uri}/api/v2/buildings?serializer=spacestation&unfiltered=true`,
    method: 'GET',
    types: [FETCH_BUILDINGS, FETCH_BUILDINGS_SUCCESS, FETCH_BUILDINGS_FAIL],
  });
};

export const updater = (uuid: string, params: {}) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction<UpdateBuildingResponse>({
    endpoint: `${config.locationsAPI.uri}/api/v1/buildings/${uuid}`,
    method: 'PUT',
    body: params,
    types: [UPDATE_BUILDING, UPDATE_BUILDING_SUCCESS, UPDATE_BUILDING_FAIL],
  });

  return dispatch(requestAction);
};

export default reducer;
