import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

import config from 'config';
// Styles
import 'features/app/globalStyle';
// Redux
import { getCurrentLocation } from 'store/selectors';
import { getUserInfoFromAuth } from 'features/auth/selectors';
import { ReduxProps } from 'store/types';
import { toggleAvatarMenu, toggleHomeLocationModal } from 'store/modules/app';
import { ToastType } from 'store/modules/toasts/constants';
import { getLastNotification } from 'store/modules/toasts/selectors';
import AppVersionUpdater from 'features/app/appVersionUpdater';
import ReloadRequiredMessage from 'features/app/reloadRequiredMessage';
import Login from 'features/auth/login';
import ProtectedRoute from 'features/auth/routes/protectedRoute';
import NetworkStateObserver from 'features/app/networkStateObserver';
import AppLayout from 'features/app/appLayout';
import EditLocationModal from 'features/app/editHomeLocationModal';
import DeveloperToolbox from 'features/developerToolbox';
import Router from 'features/app/router';
import { EmployeeRoles } from 'lib/permissions';
import { WithRequestedAuthz } from 'features/app/authorization';
import LocationChangeModal from 'features/locations/modals/locationChangeModal';

import routes from './routes';
import notificationStyles from './notificationStyle';

function isSetNoLayout(location) {
  // If new paperwork flow passes ?noLayout=true to route:
  const search = new URLSearchParams(document.location.search);

  const routerSearch = new URLSearchParams(location.search);

  return search.has('noLayout') || routerSearch.has('noLayout');
}

const mapStateToProps = (state, props) => {
  return {
    avatarMenuOpen: state.app.avatarMenuOpen,
    defaultLocation: getCurrentLocation(state, props),
    homeLocationModalOpen: state.app.homeLocationModalOpen,
    lastNotification: getLastNotification(state),
    userInfo: getUserInfoFromAuth(state),
  };
};

const mapDispatchToProps = {
  toggleAvatarMenu,
  toggleHomeLocationModal,
};

type OwnProps = {
  isPreloading: boolean;
};

export type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  OwnProps &
  RouteComponentProps &
  WithAuth0Props;

class App extends React.Component<Props> {
  notificationSystem: any;

  componentDidUpdate(prevProps) {
    const { lastNotification } = this.props;

    if (prevProps.lastNotification !== lastNotification) {
      this.handleNotification(lastNotification);
    }
  }

  handleNotification = (message): void => {
    if (!message?.type) return;

    const notification = {
      message: message.message,
      title: message.title,
      level: message.type,
      action: message.action,
      autoDismiss: message.autoDismiss || 5,
      dismissible: 'click',
    };

    if (message.type === ToastType.ERROR && !message.autoDismiss) {
      notification.autoDismiss = 0;
    }

    if (this.notificationSystem) {
      this.notificationSystem.addNotification(notification);
    }
  };

  render() {
    const {
      auth0,
      avatarMenuOpen,
      defaultLocation,
      homeLocationModalOpen,
      location,
      isPreloading,
      toggleAvatarMenu,
      toggleHomeLocationModal,
    } = this.props;

    const shouldRenderVersionFlag = config.appEnv !== 'production';
    const notificationSystem = (
      <NotificationSystem
        ref={ref => {
          this.notificationSystem = ref;
        }}
        style={notificationStyles}
      />
    );

    const layoutProps = {
      avatarMenuOpen,
      notificationSystem,
      onClickAvatar: toggleAvatarMenu,
      onCloseAvatarMenu: toggleAvatarMenu,
      shouldRenderVersionFlag,
      showSideNav: !isSetNoLayout(location),
    };

    return (
      <>
        <AppVersionUpdater reloadRoutes={routes} />
        <ReloadRequiredMessage />
        <NetworkStateObserver />
        {auth0.isAuthenticated && (
          <WithRequestedAuthz roles={[EmployeeRoles.developer]}>
            <DeveloperToolbox requestedRoles={{}} requestedPermissions={{}} />
          </WithRequestedAuthz>
        )}
        <Switch>
          <Route path="/login" component={() => <Login />} />
          <ProtectedRoute>
            <AppLayout loading={isPreloading} {...layoutProps}>
              <Router />
            </AppLayout>
          </ProtectedRoute>
        </Switch>
        {auth0.isAuthenticated && defaultLocation && (
          <EditLocationModal onDismiss={toggleHomeLocationModal} isOpen={homeLocationModalOpen} />
        )}
        <LocationChangeModal />
      </>
    );
  }
}

export const TestableApp = App;

export default compose<React.ComponentType<OwnProps>>(
  withAuth0,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
