import { getChargePrice } from 'lib/discountModalUtil';

import { DiscountPayload, DiscountInput, toDiscountInput } from './discounts';
import {
  CommitmentTermPayloadSubmit,
  CommitmentTermInput,
  CommitmentTermsPayload,
  toCommitmentTermsInput,
} from './commitmentTerm';

export * from './commitmentTerm';
export * from './discounts';
export * from './suggestions';
export * from './membershipFee';
export * from './officeInfo';

export type DateRange = {
  startDate: string;
  endDate: string;
};

export type ProductReservationChanges = {
  startDate?: Date;
  endDate?: Date;
};

export type ProductReservationUpdate = {
  id?: string;
  type: string;
  uuid: string;
  locationUuid: string;
  reservableUuid: string;
  reservableType: string;
  selectedPromotionCode: string;
  price: number;
  changes?: ProductReservationChanges;
  discounts: Array<DiscountPayload>;
  terms: Array<CommitmentTermPayloadSubmit>;
  officeNumber: string;
  capacity?: number;
  currency: string;
  maxDiscount: string;
};

export type ProductReservationAttrs = {
  startDate?: string;
  endDate?: string;
  setupFee?: number;
};

export type ProductReservationAttrsUpdate = {
  startDate?: Date;
  endDate?: Date;
  setupFee?: number;
};

export enum OOPRationale {
  FREE_MONTH = 'Yes, deal has free months',
  ABOVE_CBP = 'Yes, deal has above CBP discounts',
  FREE_MONTH_ABOVE_CBP = 'Yes, deal has free months and above CBP discounts',
  PROMOTION_OOP = 'Yes, deal is using a promotion and is OOP',
}

export type OutOfPolicyAcknowledgement = {
  checked: boolean;
  discountReason?: string;
  approver: string;
  appliedPromoCode: string;
  rationale: OOPRationale;
};

export type Promotion = {
  amounts: Array<number>;
  duration: number | null;
  formStyle: string | null;
  initialTerms: number | null;
  perpetuating: boolean | null;
  rate: number | null;
  type: string | null;
};

export type ProductReservationInput = {
  id: string;
  uuid: string;
  productType: string;
  startDate: {
    value: string;
    editable: boolean;
  };
  endDate: {
    value: string;
    editable: boolean;
  };
  canceledAt: {
    value: string;
    editable: boolean;
  };
  officeNum: string;
  price: number;
  marketPrice?: number;
  chargePrice: number;
  reservableType: string;
  reservableUuid: string;
  accountUuid: string;
  type: string;
  moveInType: string;
  moveOutType: string;
  locationId: string;
  locationUuid: string;
  notes?: Array<string>;
  name: string;
  task?: null;
};

export interface ProductReservation {
  id: string;
  uuid: string;
  started_on: string;
  ended_on: string;
  canceled_at: string;
  office_num: string;
  price: number;
  market_price: number;
  reservable_type: string;
  reservable_uuid: string;
  account_uuid: string;
  type: string;
  move_in_type: string;
  move_out_type: string;
  location_id: string;
  location_uuid: string;
  notes: Array<string>;
  name: string;
  task: null;
  editable: Array<string>;
}

export interface LocaleInfoPayload {
  currency_code: string;
  country_code: string;
  locale: string;
}

export interface LocaleInfo {
  currencyCode: string;
  countryCode: string;
  locale: string;
}

export interface ProductReservationPayload {
  product_reservation: ProductReservation;
  locale_info: LocaleInfoPayload;
  discounts: Array<DiscountPayload>;
  terms: Array<CommitmentTermsPayload>;
  prorated_price: number;
}

export interface ProductItem {
  productReservation: ProductReservationInput;
  productReservationOrigin: ProductReservationInput;
  discounts: Array<DiscountInput>;
  discountsOrigin: Array<DiscountInput>;
  terms: Array<CommitmentTermInput>;
  termsOrigin: Array<CommitmentTermInput>;
  localeInfo: LocaleInfo;
  proratedPrice: number;
  setupFee?: number;
}

export interface PreviousAgreement {
  id: string;
  uuid: string;
  productType: string;
  startDate: {
    value: string;
    editable: boolean;
  };
  endDate: {
    value: string;
    editable: boolean;
  };
  canceledAt: {
    value: string;
    editable: boolean;
  };
  officeNum: string;
  price: number;
  reservableType: string;
  reservableUuid: string;
  accountUuid: string;
  type: string;
  moveInType: string;
  moveOutType: string;
  locationId: string;
  locationUuid: string;
  notes?: Array<string>;
  name: string;
  discounts: Array<DiscountInput>;
  terms: Array<CommitmentTermInput>;
  localeInfo: LocaleInfo;
}

export const toLocaleInfoInput = (localeInfo: LocaleInfoPayload): LocaleInfo => ({
  currencyCode: localeInfo.currency_code,
  countryCode: localeInfo.country_code,
  locale: localeInfo.locale,
});

export const toProductsReservationInput = ({
  product_reservation: productReservation,
}: {
  product_reservation: ProductReservation;
}): ProductReservationInput => ({
  id: productReservation.id,
  uuid: productReservation.uuid,
  productType: productReservation.reservable_type,
  startDate: {
    value: productReservation.started_on,
    editable: productReservation.editable.includes('started_on'),
  },
  endDate: {
    value: productReservation.ended_on,
    editable: productReservation.editable.includes('ended_on'),
  },
  canceledAt: {
    value: productReservation.canceled_at,
    editable: productReservation.editable.includes('canceled_at'),
  },
  officeNum: productReservation.office_num,
  price: productReservation.price,
  chargePrice: getChargePrice(productReservation.market_price, productReservation.price),
  marketPrice: productReservation.market_price,
  reservableType: productReservation.reservable_type,
  reservableUuid: productReservation.reservable_uuid,
  accountUuid: productReservation.account_uuid,
  type: productReservation.type,
  moveInType: productReservation.move_in_type,
  moveOutType: productReservation.move_out_type,
  locationId: productReservation.location_id,
  locationUuid: productReservation.location_uuid,
  notes: productReservation.notes,
  name: productReservation.name,
  task: productReservation.task,
});

export const payloadToInputFields = ({
  product_reservation,
  discounts,
  terms,
  locale_info,
  prorated_price,
}: ProductReservationPayload): ProductItem => {
  const productReservationInput = toProductsReservationInput({ product_reservation });
  const discountsInput = discounts?.map(toDiscountInput);
  return {
    productReservation: productReservationInput,
    productReservationOrigin: productReservationInput,
    discounts: discountsInput,
    discountsOrigin: discountsInput,
    terms: terms.map(toCommitmentTermsInput),
    termsOrigin: terms.map(toCommitmentTermsInput),
    localeInfo: toLocaleInfoInput(locale_info),
    proratedPrice: Number(prorated_price),
  };
};

export const payloadToPreviousAgreement = ({
  product_reservation,
  discounts,
  terms,
  locale_info,
}: ProductReservationPayload): PreviousAgreement => {
  const productReservationInput = toProductsReservationInput({ product_reservation });
  const discountsInput = discounts?.map(toDiscountInput);
  return {
    ...productReservationInput,
    discounts: discountsInput,
    terms: terms.map(toCommitmentTermsInput),
    localeInfo: toLocaleInfoInput(locale_info),
  };
};

export const payloadToCommitmentRenewals = ({
  product_reservation,
  locale_info,
}: ProductReservationPayload): ProductItem => {
  const productReservationInput = toProductsReservationInput({ product_reservation });

  return {
    productReservation: productReservationInput,
    productReservationOrigin: productReservationInput,
    discounts: [],
    discountsOrigin: [],
    terms: [],
    termsOrigin: [],
    localeInfo: toLocaleInfoInput(locale_info),
    proratedPrice: 0,
  };
};

export const toProductReservationPayload = (changes: ProductReservationChanges) => ({
  started_on: changes.startDate,
  ended_on: changes.endDate,
});

const DEFAULT_SETUP_FEE_PER_WORK_UNIT = 100;

export const mapInventoryOfficeToProductItem = (
  inventoryOffice,
  startDate,
  localeInfo
): ProductItem => {
  const productReservationInput: ProductReservationInput = {
    id: inventoryOffice.id,
    uuid: '',
    productType: inventoryOffice.reservable_type,
    startDate: {
      value: startDate,
      editable: true,
    },
    endDate: {
      value: '',
      editable: true,
    },
    canceledAt: {
      value: '',
      editable: true,
    },
    officeNum: inventoryOffice.name,
    price: inventoryOffice.price,
    chargePrice: inventoryOffice.price,
    marketPrice: inventoryOffice.price,
    reservableType: inventoryOffice.type,
    reservableUuid: inventoryOffice.uuid,
    accountUuid: inventoryOffice.account_uuid,
    moveInType: '',
    moveOutType: '',
    locationId: inventoryOffice.location_id,
    locationUuid: inventoryOffice.location.uuid,
    name: inventoryOffice.name,
    type: '',
  };

  return {
    productReservation: productReservationInput,
    productReservationOrigin: productReservationInput,
    discounts: [],
    discountsOrigin: [],
    terms: [],
    termsOrigin: [],
    localeInfo,
    proratedPrice: 0,
    setupFee: inventoryOffice?.workUnits * DEFAULT_SETUP_FEE_PER_WORK_UNIT || 0,
  };
};
