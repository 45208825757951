import { handleActions } from 'redux-actions';

import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';
import { BaseAction, BaseState } from 'store/types';
import { AccountRoles, Role } from 'store/modules/accounts/types';

// Action Constants
export const [
  FETCH_ROLES_BY_ACCOUNT,
  FETCH_ROLES_BY_ACCOUNT_SUCCESS,
  FETCH_ROLES_BY_ACCOUNT_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_USER_BY_UUID'));

export interface State extends BaseState {
  roles: Array<Role>;
}

export interface ActionPayload extends BaseAction {
  payload: AccountRoles;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  roles: [],
};

// Reducer
export const reducer = handleActions<State, AccountRoles>(
  {
    [FETCH_ROLES_BY_ACCOUNT]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_ROLES_BY_ACCOUNT_SUCCESS]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: true,
      error: action.error,
      roles: action.payload.roles,
    }),
    [FETCH_ROLES_BY_ACCOUNT_FAIL]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error || true,
    }),
  },
  initialState
);

export default reducer;
