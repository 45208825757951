import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_NOTICE_PERIOD,
  FETCH_NOTICE_PERIOD_SUCCESS,
  FETCH_NOTICE_PERIOD_FAIL,
] = createRequestConstantNames(cc('FETCH_NOTICE_PERIOD'));

export const [
  UPDATE_NOTICE_PERIOD,
  UPDATE_NOTICE_PERIOD_SUCCESS,
  UPDATE_NOTICE_PERIOD_FAIL,
] = createRequestConstantNames(cc('UPDATE_NOTICE_PERIOD'));
