import { InventoryItem } from 'store/modules/siInventory/types';
import { locationIdsForInventory } from 'store/modules/siInventory/utils';
import { fetchLocation } from 'store/modules/siLocations/api';

import { findLocationQuery, SILocationResponse } from '../config/siQueries';

export type LocationsAndErrors = {
  locationResponses: Array<SILocationResponse>;
  errors?: string;
};

export const locationsForInventory = async (
  inventory: Array<InventoryItem>
): Promise<LocationsAndErrors> => {
  const locationIds: Array<string> = locationIdsForInventory(inventory);
  const locationPromises: Promise<SILocationResponse>[] = [];
  locationIds
    .map(id => fetchLocation(findLocationQuery, { id }))
    .forEach(promise => locationPromises.push(promise));
  const locationResponses: Array<SILocationResponse> = await Promise.all(locationPromises);
  const errors: string | undefined = locationResponses
    .filter(resp => !!resp.error)
    .map(resp => JSON.stringify(resp.error))
    .join('\n');
  return {
    locationResponses,
    errors,
  };
};
