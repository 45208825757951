// Usage:
import { EntitySubset } from 'store/util/createEntity/reducer';
import { ErrorContainer } from 'store/errors';

//
// const Seats = createEntity('seats', {
//   baseUrl: '',
//   endpoint: '...',
//   token: '...',
// });
//
// dispatch(Seats.fetch(companyUuid, { lookupBy: 'locuuid.page' }));
//
// dispatch(Seats.update(id, payload))
//
import {
  createQueryAction,
  createFindAction,
  createUpdateAction,
  createCreateAction,
  createDestroyAction,
  createEntityConstantsForAllActions,
  createCustomActions,
} from './createEntity/actions';
import { EntityOptions, CustomActions, ActionStatus } from './createEntity/types';
import {
  getAllEntity,
  getEntityById,
  getEntityByKey,
  getEntityStatusByKey,
  getEntityStatusByAction,
  getEntityStatusByActionAndId,
  getEntityErrorByActionAndId,
} from './createEntity/selectors';
import { Status } from './createEntity/reducers/status';

export type Entity<T extends {}, U extends CustomActions = {}> = {
  query: ReturnType<typeof createQueryAction>;
  find: ReturnType<typeof createFindAction>;
  update: ReturnType<typeof createUpdateAction>;
  create: ReturnType<typeof createCreateAction>;
  destroy: ReturnType<typeof createDestroyAction>;
  constants: Hash<
    {
      [key in ActionStatus]: string;
    }
  >;
  getById: (
    id: string | null | undefined
  ) => (state: EntitySubset, props?: {} | null | undefined) => T | ''; // TODO: fix the default response to null
  getAll: () => (state: { entity: {} }, props?: {} | null | undefined) => Array<T>;
  getByKey: (
    id: string | null | undefined
  ) => (state: EntitySubset, props?: {} | null | undefined) => Array<T>;
  getStatusByKey: (
    key: string
  ) => (state: EntitySubset, props?: Record<string, any> | null | undefined) => Status;
  getStatusByAction: (
    key: string
  ) => (state: EntitySubset, props?: {} | null | undefined) => Status;
  getStatusByActionAndId: (
    action: string,
    id: string | null | undefined
  ) => (state: EntitySubset, props?: {} | null | undefined) => Status;
  getErrorByActionAndId: (
    action: string,
    id: string | null | undefined
  ) => (state: EntitySubset, props?: {} | null | undefined) => ErrorContainer | null | undefined;
} & { [P in keyof U]: Function };

const createEntity = <T extends {}, U extends CustomActions = {}>(
  name: string,
  options: EntityOptions<U>
): Entity<T, U> => ({
  // Actions
  query: createQueryAction('query', name, options),
  find: createFindAction('find', name, options),
  update: createUpdateAction('update', name, options),
  create: createCreateAction('create', name, options),
  destroy: createDestroyAction('destroy', name, options),

  // Custom actions
  ...(createCustomActions(name, options) as { [P in keyof U]: Function }),

  // Action contants (for use in custom reducers)
  constants: createEntityConstantsForAllActions(name, options),

  // Selectors
  getAll: getAllEntity(name),
  getById: getEntityById(name),
  getByKey: getEntityByKey(name),
  getStatusByKey: getEntityStatusByKey(name),
  getStatusByAction: getEntityStatusByAction(name),
  getStatusByActionAndId: getEntityStatusByActionAndId(name),
  getErrorByActionAndId: getEntityErrorByActionAndId(name),
});

export default createEntity;
