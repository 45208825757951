import { createSelector } from 'reselect';

import { SearchResultItem } from 'store/modules/search/searchService/types';
import { getSearchResults, SearchResults } from 'store/modules/search';

import { ResultSet } from './resultSets/types';
import DESCRIPTORS from './resultSets/descriptors';

const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

const getTitleWithCount = (title: string, count: number) => (count ? `${title} (${count})` : title);

export const searchResultsToCountMap = (searchResults: SearchResults): Hash<number> => ({
  companies: searchResults.company?.value?.totalCount ?? 0,
  members: searchResults.member?.value?.totalCount ?? 0,
  keycards: searchResults.keycard?.value?.totalCount ?? 0,
  guests: searchResults.guest?.value?.totalCount ?? 0,
});

export const searchResultsToMap = (
  searchResults: SearchResults
): Hash<Array<SearchResultItem>> => ({
  companies: searchResults.company?.value?.items ?? [],
  members: searchResults.member?.value?.items ?? [],
  keycards: searchResults.keycard?.value?.items ?? [],
  guests: searchResults.guest?.value?.items ?? [],
});

export const formatSearchResults = (searchResults: SearchResults): Array<ResultSet> => {
  const resultsMap = searchResultsToMap(searchResults);
  const resultCountMap = searchResultsToCountMap(searchResults);

  const resultSets = keys(DESCRIPTORS)
    .map(key => {
      const descriptor = DESCRIPTORS[key];

      if (!descriptor) {
        return null;
      }

      const results = resultsMap[key]?.map(result => descriptor?.mapToResult(result)) ?? [];
      const resultCount = resultCountMap[key];

      return {
        key,
        title: getTitleWithCount(descriptor.title, resultCount),
        results,
        resultCount,
      };
    })
    .filter(resultSet => resultSet && resultSet.results.length > 0) as Array<ResultSet>;

  return resultSets;
};

export const getFormattedSearchResults = createSelector([getSearchResults], formatSearchResults);

export const getSearchResultCounts = createSelector([getSearchResults], searchResultsToCountMap);
