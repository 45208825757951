import { handleActions } from 'redux-actions';

import { ActionWithMetaPayload, ActionWithPayload } from 'store/types';

import {
  FetchOrganizationAccountPayload,
  FetchOrganizationAccountsMeta,
  OrganizationAccountsState,
} from './types';
import {
  FETCH_ORGANIZATION_ACCOUNTS,
  FETCH_ORGANIZATION_ACCOUNTS_SUCCESS,
  FETCH_ORGANIZATION_ACCOUNTS_FAIL,
  SET_ORGANIZATION_ACCOUNTS_PAGE,
  SET_ORGANIZATION_ACCOUNTS_NEXT_PAGE,
  SET_ORGANIZATION_ACCOUNTS_QUERY,
  ADD_ACCOUNT_TO_ORGANIZATION,
  ADD_ACCOUNT_TO_ORGANIZATION_SUCCESS,
  ADD_ACCOUNT_TO_ORGANIZATION_FAIL,
  DELETE_ACCOUNT_FROM_ORGANIZATION,
  DELETE_ACCOUNT_FROM_ORGANIZATION_SUCCESS,
  DELETE_ACCOUNT_FROM_ORGANIZATION_FAIL,
  CLEAR_ORGANIZATION_ACCOUNTS,
  CLEAR_ORGANIZATION_ACCOUNTS_STATE,
} from './constants';

const initialState: OrganizationAccountsState = {
  organizationUuid: undefined,
  organizationAccounts: [],
  organizationAccountsLoading: false,
  organizationAccountsLoaded: false,
  organizationAccountsPage: 1,
  organizationAccountsQuery: undefined,
  organizationAccountsCurrentQuery: undefined,
  organizationAccountsCurrentPage: 0,
  organizationAccountsTotalPages: 0,
};

export const reducer = handleActions<OrganizationAccountsState, any, any>(
  {
    [FETCH_ORGANIZATION_ACCOUNTS]: (state: OrganizationAccountsState) => ({
      ...state,
      organizationAccountsLoading: true,
    }),
    [FETCH_ORGANIZATION_ACCOUNTS_SUCCESS]: (
      state: OrganizationAccountsState,
      action: ActionWithMetaPayload<FetchOrganizationAccountsMeta, FetchOrganizationAccountPayload>
    ) => ({
      ...state,
      organizationAccountsLoading: false,
      organizationAccountsLoaded: true,
      organizationAccountsCurrentPage: action.payload.page.number,
      organizationAccountsCurrentQuery: action.meta.organizationAccountsQuery,
      organizationAccountsPage: action.payload.page.number,
      organizationAccountsTotalPages: action.payload.page.total,
      organizationAccounts: [...state.organizationAccounts, ...action.payload.accounts],
    }),
    [FETCH_ORGANIZATION_ACCOUNTS_FAIL]: (state: OrganizationAccountsState, action) => ({
      ...state,
      organizationAccountsLoading: false,
      organizationAccountsLoaded: true,
      organizationAccountsPage: state.organizationAccountsCurrentPage,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
      },
    }),
    [SET_ORGANIZATION_ACCOUNTS_PAGE]: (
      state: OrganizationAccountsState,
      action: ActionWithPayload<number>
    ) => ({
      ...state,
      organizationAccountsPage: action.payload,
    }),
    [SET_ORGANIZATION_ACCOUNTS_NEXT_PAGE]: (state: OrganizationAccountsState) => ({
      ...state,
      organizationAccountsPage: state.organizationAccountsCurrentPage + 1,
    }),
    [SET_ORGANIZATION_ACCOUNTS_QUERY]: (
      state: OrganizationAccountsState,
      action: ActionWithPayload<string>
    ) => ({
      ...state,
      organizationAccountsQuery: action.payload,
    }),
    [ADD_ACCOUNT_TO_ORGANIZATION]: (state: OrganizationAccountsState) => ({
      ...state,
      organizationAccountsLoading: true,
      organizationAccounts: initialState.organizationAccounts,
    }),
    [ADD_ACCOUNT_TO_ORGANIZATION_SUCCESS]: (state: OrganizationAccountsState) => ({
      ...state,
      ...initialState,
    }),
    [ADD_ACCOUNT_TO_ORGANIZATION_FAIL]: (state: OrganizationAccountsState, action) => ({
      ...state,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
      },
      ...initialState,
    }),
    [DELETE_ACCOUNT_FROM_ORGANIZATION]: (state: OrganizationAccountsState) => ({
      ...state,
      organizationAccountsLoading: true,
      organizationAccounts: initialState.organizationAccounts,
    }),
    [DELETE_ACCOUNT_FROM_ORGANIZATION_SUCCESS]: (state: OrganizationAccountsState) => ({
      ...state,
      ...initialState,
    }),
    [DELETE_ACCOUNT_FROM_ORGANIZATION_FAIL]: (state: OrganizationAccountsState, action) => ({
      ...state,
      error: {
        status: action.payload.status,
        url: action.payload.response?.url,
        message: action.payload.message,
        type: action.payload.type,
      },
      ...initialState,
    }),
    [CLEAR_ORGANIZATION_ACCOUNTS]: (state: OrganizationAccountsState) => ({
      ...state,
      organizationAccounts: initialState.organizationAccounts,
    }),
    [CLEAR_ORGANIZATION_ACCOUNTS_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
