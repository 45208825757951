import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const FETCH_USERS_BY_ACCOUNT_DEFAULT_PAGE_NUMBER = 1;
export const FETCH_USERS_BY_ACCOUNT_DEFAULT_PAGE_SIZE = 30;
export const FETCH_USERS_BY_ACCOUNT_DEFAULT_PAGE_TOTAL = 1;

export const DEFAULT_FILTER_STATE = {
  name: '',
  status: 'active',
  location: null,
  purpose: null,
  user_group: null,
};

export const DEFAULT_INACTIVE_FILTER_STATE = {
  name: '',
  status: 'all',
  location: null,
  purpose: null,
  user_group: null,
};

export const DEFAULT_PAGE_STATE = {
  number: FETCH_USERS_BY_ACCOUNT_DEFAULT_PAGE_NUMBER,
  size: FETCH_USERS_BY_ACCOUNT_DEFAULT_PAGE_SIZE,
  total: FETCH_USERS_BY_ACCOUNT_DEFAULT_PAGE_TOTAL,
};

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum RoleSlug {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  BILLING = 'billing',
}

export enum Purpose {
  PHYSICAL_ACCESS = 'PHYSICAL_ACCESS',
  SECURITY = 'SECURITY',
  EXPORT_ROSTER = 'EXPORT_ROSTER',
}

export const [UPDATE_ROLE, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAIL] = createRequestConstantNames(
  createReduxConstant('FETCH_USER_BY_UUID')
);

export const DEFAULT_EXPORT_SIZE = 100000;
