import { compact, map } from 'lodash';

import { InventoryPayloadItem, CBPItem } from '../config/types';
import { InventoryAttribute } from '../types';
import isInventoryGroup from '../actions/isInventoryGroup';

export const getCBPItem = (inventoryPayloadItem: InventoryPayloadItem): CBPItem | null => {
  // Work units should be same as capacity
  const workUnits = inventoryPayloadItem?.workUnits;
  const type = inventoryPayloadItem?.type;
  const uuid = inventoryPayloadItem?.uuid;
  const locationUuid = inventoryPayloadItem?.location?.uuid;

  if (!workUnits || !type || !uuid || !locationUuid) {
    return null;
  }

  return {
    action: 'add',
    capacity: workUnits,
    location_uuid: locationUuid,
    office_type: type,
    office: uuid,
  };
};

export const priceAndCBPSerializer = (
  inventoryPayloadItem: InventoryPayloadItem,
  serializerKey: string
): InventoryAttribute | null | undefined => {
  const pricePayload = inventoryPayloadItem?.[serializerKey];

  const currency = pricePayload?.currency;
  const price = pricePayload?.amount;

  if (!pricePayload || !price || !currency) return null;

  const items = inventoryPayloadItem?.items;
  const cbpItems = isInventoryGroup(inventoryPayloadItem)
    ? compact(map(items, getCBPItem))
    : [getCBPItem(inventoryPayloadItem)];

  return {
    value: String(price),
    meta: {
      cbpItems,
      currency,
      price,
    },
  };
};

export default priceAndCBPSerializer;
