export enum PPW_ERROR {
  INVENTORY = 'inventory',
  RESERVATIONS = 'reservations',
  SUGGESTIONS = 'suggestions',
}

export type ContractsError = {
  message: string;
  customMessage?: string;
  code: PPW_ERROR;
};
