import * as JSONAPI from 'jsonapi-typescript';
import { entries, forIn, isEmpty, mapValues, pick, reduce, set } from 'lodash';
import moment, { Moment } from 'moment-timezone';

import {
  RoomBookingReservation,
  RoomReservationReason,
} from 'features/rooms/components/modals/types';
import {
  MAX_END_TIME,
  MIN_START_TIME,
  TIME_FORMAT,
  TIME_INTERVAL_MINUTES,
} from 'features/rooms/constants';
import { RoomReservation, ConferenceRoom } from 'features/rooms/types';
import {
  getCurrentTimeZoneAndMoment,
  getInitialAndFinalTime,
  getRoundedTime,
} from 'features/rooms/utils';

import {
  ConferenceRoomResponse,
  CreditMappings,
  HourValue,
  HourValues,
  ParsedIncluded,
  RelationshipItem,
  RelationshipsAcc,
  RoomReservationData,
  RoomReservationsResponse,
} from '../types';

const getNextAvailableSlot = (timeZone?: string): string => {
  const { currentTimezone, currentMoment } = getCurrentTimeZoneAndMoment(timeZone);
  const { initialTime, finalTime } = getInitialAndFinalTime(
    currentMoment.format(),
    currentTimezone
  );
  return getRoundedTime(currentTimezone, currentMoment, initialTime, finalTime).format(TIME_FORMAT);
};

const createHoursArray = (): Array<string> => {
  const timeValue: Moment = moment(MIN_START_TIME, 'H');
  const finalTime: Moment = moment(MAX_END_TIME, 'H');
  const hours: Array<string> = [];

  while (timeValue.isBefore(finalTime)) {
    hours.push(timeValue.format(TIME_FORMAT));

    timeValue.add(TIME_INTERVAL_MINUTES, 'minutes');
  }

  return hours;
};

const hoursArray = createHoursArray();

export const createHoursMap = (): HourValues =>
  hoursArray.reduce((acc: HourValues, hour): HourValues => {
    acc[hour] = { reservations: [] };
    return acc;
  }, {});

const trimCreditMappings = (creditMappings: Hash<number>): Hash<number> =>
  pick(creditMappings, hoursArray);

const castCreditMappings = (creditMappings: Hash<number>): CreditMappings =>
  mapValues(creditMappings, Number);

export const getHoursMapBySelectedDatetime = (
  timeZone: string,
  selectedDatetime: Moment,
  addNextAvailableSlot = true
): HourValues => {
  const hourMap = createHoursMap();
  const currentDatetime = moment().tz(timeZone);
  if (currentDatetime.isSame(selectedDatetime, 'day') && addNextAvailableSlot) {
    const currentTimeValue = getNextAvailableSlot(timeZone);
    hourMap[currentTimeValue] = { nextAvailableSlot: true, reservations: [] };
    return hourMap;
  }
  return hourMap;
};

// this function takes the included attributes from the JSONAPI format and extract them to a map by type and id to enable faster value extraction
// TODO(grozki): Make ResourceObject more specific if possible.
const parseIncluded = (
  included: Array<JSONAPI.ResourceObject> | null | undefined
): ParsedIncluded =>
  (included ?? []).reduce((obj, item) => {
    const { type, id, attributes } = item;
    if (type && id) {
      set(obj, `${type}.${id}`, attributes || {});
    }
    return obj;
  }, {});

// This function takes relationships keys from the JSONAPI call and and extract the values to be appended to the response object
const flattenRelationships = (
  relationships: JSONAPI.RelationshipsObject,
  included: // Used lodash entries and not Object.values because of flow
  ParsedIncluded
): RelationshipsAcc =>
  entries(relationships).reduce(
    (relationshipAcc: RelationshipsAcc, val: [string, JSONAPI.RelationshipsWithData]) => {
      if (val) {
        const [key, objVal] = val;
        const { data } = objVal;
        if (data) {
          if (Array.isArray(data)) {
            const relationshipArray: RelationshipItem[] = [];
            data.forEach(({ type, id }) => {
              relationshipArray.push(included[type][id]);
            });
            relationshipAcc[key] = relationshipArray;
          } else if (data) {
            const { type, id } = data;
            if (type && id) {
              relationshipAcc[key] = included[type][id];
            }
          }
        }
      }
      return relationshipAcc;
    },
    {}
  );

const isBookOnBehalf = (userUuid: string, bookedByUserUuid: string): boolean => {
  return userUuid !== bookedByUserUuid;
};

// This function takes relationships from the JSONAPI call and take the object values and adding them to the response object under one object
// TODO(grozki): Make ResourceObject more specific with type.
const concatReservationPayload = (
  // @ts-ignore
  data: Array<JSONAPI.ResourceObject<string, RoomBookingReservation>>,
  included: ParsedIncluded
): RoomReservationsResponse => {
  return reduce(
    data,
    (result, value) => {
      const { relationships, attributes } = value;

      if (relationships && attributes) {
        const bookOnBehalf = isBookOnBehalf(attributes.user_uuid, attributes.bookedByUser?.uuid);

        let setValue = {
          ...attributes,
          ...flattenRelationships(relationships, included),
          bookOnBehalf,
        };

        setValue = { ...setValue, refunded: !!setValue.reservationRefunds?.length };

        const reservableData = 'data' in relationships.reservable && relationships.reservable.data;

        if (!Array.isArray(reservableData) && !isEmpty(reservableData)) {
          const { id } = reservableData || {};
          if (id) {
            result[id] = result[id] || [];
            result[id].push(setValue);
          }
        }
      }
      return result;
    },
    {}
  );
};

// This function takes relationships from the JSONAPI call
// and take the object values and adding them to the response
// object under one object. then we add the timeline to use as an empty shell
// TODO(grozki): Make ResourceObject more specific with type and attributes.
const concatConferencePayload = (
  // @ts-ignore
  data: Array<JSONAPI.ResourceObject<string, ConferenceRoom>> | null | undefined,
  included: ParsedIncluded,
  selectedTimeFrame: string
): ConferenceRoomResponse => {
  return reduce(
    data,
    (result, value) => {
      const { relationships, attributes } = value;
      if (relationships && attributes) {
        const { uuid } = attributes;
        const flattenedRelationships = flattenRelationships(relationships, included);

        const timeZone = (flattenedRelationships.location as RelationshipItem)?.timeZone;
        const weeklySchedules = flattenedRelationships?.weeklySchedules as RelationshipItem[];

        const setValue = {
          ...attributes,
          creditMappings: castCreditMappings(trimCreditMappings(attributes.creditMappings)),
          ...flattenedRelationships,
          timeline: {
            ...getHoursMapBySelectedDatetime(timeZone, moment.tz(selectedTimeFrame, timeZone)),
          },
          odEnabled: !!attributes.odEnabled,
          weeklySchedules,
        };

        if (uuid) {
          set(result, `${uuid.toString()}`, setValue || {});
        }
      }
      return result;
    },
    {}
  );
};

const checkInitialTime = (timeValue: Moment, initialTime: Moment, finalTime: Moment): string => {
  if (timeValue.isSameOrBefore(initialTime)) {
    return initialTime.format(TIME_FORMAT);
  } else if (timeValue.isBetween(initialTime, finalTime)) {
    return timeValue.format(TIME_FORMAT);
  } else {
    return finalTime.format(TIME_FORMAT);
  }
};

const skipTimeFrame = (timeValue: Moment, hourValue: HourValue): Moment => {
  const { counter } = hourValue;
  if (counter && counter > 1) {
    timeValue.add(TIME_INTERVAL_MINUTES * (counter - 1), 'minutes');
  }
  return timeValue;
};

export const calculateNextAvailableSlot = (
  hourValues: HourValues,
  _selectedDatetime: string,
  timeZone: string
): HourValues => {
  const { currentTimezone, currentMoment } = getCurrentTimeZoneAndMoment(timeZone);
  const { initialTime, finalTime } = getInitialAndFinalTime(
    currentMoment.format(),
    currentTimezone
  );
  const currentTimeValue = getRoundedTime(currentTimezone, currentMoment, initialTime, finalTime);
  let timeValue = initialTime.clone();
  while (!timeValue.isSameOrAfter(finalTime)) {
    const timeValueFormat = timeValue.format(TIME_FORMAT);
    if (isEmpty(hourValues[timeValueFormat])) {
      // I only want to check cells that are after or the same as the current time
      if (timeValue.isSameOrAfter(currentTimeValue)) {
        hourValues[timeValueFormat] = { nextAvailableSlot: true, reservations: [] };
        break;
      }
    } else {
      timeValue = skipTimeFrame(timeValue, hourValues[timeValueFormat]);
    }
    timeValue.add(TIME_INTERVAL_MINUTES, 'minutes');
  }
  return hourValues;
};

export const reservationToHourArray = (
  reservation: RoomReservation,
  selectedDatetime: string,
  hourValues?: HourValues
): HourValues => {
  const { location, start, finish } = reservation;
  const { timeZone } = location;
  // the final time according to design is 9:00pm (even if the reservation is at a later time)
  const { initialTime, finalTime } = getInitialAndFinalTime(start, timeZone);
  const timeValue = moment.tz(start, timeZone);
  const maxTime = moment.tz(finish, timeZone);
  const selectedDatetimeValue = moment.tz(selectedDatetime, timeZone);

  if (!hourValues) {
    hourValues = getHoursMapBySelectedDatetime(timeZone, selectedDatetimeValue, false);
  }
  const initialKey = checkInitialTime(timeValue, initialTime, finalTime);
  let counter = 0;
  while (timeValue.isSameOrBefore(maxTime) && !timeValue.isAfter(finalTime)) {
    const timeValueFormat = timeValue.format(TIME_FORMAT);
    if (timeValue.isSameOrAfter(initialTime)) {
      // we want to mark a slot as selected before the time has ended and before the max available time for that day
      if (timeValue.isSameOrBefore(finalTime) && !timeValue.isSame(maxTime)) {
        counter++;
        if (counter > 1) {
          // deleting the object key because it is a scoped object (a new mutation) that isn't used by any other scope.
          delete hourValues[timeValueFormat];
        }
      }
    }
    timeValue.add(TIME_INTERVAL_MINUTES, 'minutes');
  }

  const hourValue = hourValues?.[initialKey] ?? {};
  hourValues[initialKey] = {
    ...hourValue,
    reservations: [...(hourValue?.reservations || []), reservation],
    counter,
  };
  return hourValues;
};

// this method will place each existing reservation in an object of the room timeline => {'07:00': { reservation, counter}}
export const reservationReduced = (
  reservationResponse: RoomReservationsResponse,
  currentTime: string,
  timeZone: string
): Hash<RoomReservationData> => {
  const reservationData: { [key: string]: RoomReservationData } = {};

  const reservationHourValues: { [key: string]: HourValues } = {};
  const currentDatetime = moment().tz(timeZone);
  const selectedTimeFrame = moment.tz(currentTime, timeZone);

  forIn(reservationResponse, (reservations, roomUuid) => {
    reservations.forEach(reservation => {
      reservationHourValues[roomUuid] = reservationToHourArray(
        reservation,
        currentTime,
        reservationHourValues[roomUuid]
      );
    });

    if (currentDatetime.isSame(selectedTimeFrame, 'day')) {
      reservationHourValues[roomUuid] = calculateNextAvailableSlot(
        reservationHourValues[roomUuid],
        currentTime,
        timeZone
      );
    }
  });

  Object.entries(reservationHourValues).forEach(([id, data]) => {
    reservationData[id] = {
      loading: false,
      loaded: true,
      error: null,
      reservations: data,
    };
  });

  return reservationData;
};

export const convertConferencePayload = (
  // @ts-ignore
  payload: JSONAPI.CollectionResourceDoc<string, ConferenceRoom> | null | undefined,
  selectedTimeFrame: string
): ConferenceRoomResponse => {
  const payloadData = payload?.data ?? [];
  const payloadIncluded = parseIncluded(payload?.included);

  return concatConferencePayload(payloadData, payloadIncluded, selectedTimeFrame);
};

// TODO(grozki): Change type (T in CollectionResourceDoc<T, ...>) to be specific type.
export const convertReservationPayload = (
  // @ts-ignore
  payload: JSONAPI.CollectionResourceDoc<string, RoomBookingReservation> | null | undefined,
  currentTime: string,
  timeZone: string
): Hash<RoomReservationData> => {
  const payloadData = payload?.data ?? [];
  const payloadIncluded = parseIncluded(payload?.included);
  const reservationPayload = concatReservationPayload(payloadData, payloadIncluded);

  return reservationReduced(reservationPayload, currentTime, timeZone);
};

// TODO(grozki): Change type (T in CollectionResourceDoc<T, ...>) to be specific type.
export const convertReservationByReservablePayload = (
  // @ts-ignore
  payload: JSONAPI.CollectionResourceDoc<string, RoomBookingReservation> | null | undefined,
  currentTime: string,
  uuid: string,
  timeZone: string
): RoomReservationData => {
  const payloadData = payload?.data ?? [];

  if (payloadData.length > 0) {
    const payloadIncluded = parseIncluded(payload?.included);
    const reservationPayload = concatReservationPayload(payloadData, payloadIncluded);

    return reservationReduced(reservationPayload, currentTime, timeZone)[uuid];
  }

  return {};
};

// TODO(grozki): Make ResourceObject more specific with attributes.
export const convertRoomReservationReasonsPayload = (
  payload: Array<JSONAPI.ResourceObject> | null | undefined
): Hash<Array<RoomReservationReason>> => {
  const payloadData: Array<JSONAPI.ResourceObject> = payload ?? [];

  return payloadData.reduce((result, value) => {
    const { attributes } = value;
    if (attributes) {
      const { types, id, name, requireAdditionalNotes } = attributes;

      if (Array.isArray(types)) {
        types.forEach((type: string) => {
          result[type] = result[type] || [];
          result[type].push({ id, name, requireAdditionalNotes });
        });
      }
    }

    return result;
  }, {});
};
