import { combineReducers } from 'redux';

import { RequestState } from 'store/util/createRequestReducer';
import commitmentBasedPromotions, {
  State as CommitmentBasedPromotionsState,
} from 'features/paperwork/discounts/commitmentBasedPromotions/ducks/commitmentBasedPromotions';
import discountsApi, {
  State as DiscountsApiState,
} from 'features/paperwork/discounts/commitmentBasedPromotions/ducks/discountsApi';
import earlyTermination, {
  State as EarlyTerminationState,
} from 'features/paperwork/earlyTermination/ducks';
import agreementManagement, {
  State as AgreementManagementState,
} from 'features/paperwork/contracts-flow/ducks';
import { State as UploadedAgreementState } from 'features/companies/addOnsSection/redux/index';

import addons, { State as AddonsState } from './addons';
import agreementModifications, {
  State as AgreementModificationsState,
} from './agreementModifications';
import commitmentBasedDiscounts, {
  State as CommitmentBasedDiscountsState,
} from './commitmentBasedDiscounts';
import contractDetails, { State as ContractDetailsState } from './contractDetails';
import customTermsOfService, { State as CustomTermsOfServiceState } from './customTermsOfService';
import docusignInfo, { State as DocusignInfoState } from './docusignInfo';
import discounts, { State as DiscountsState } from './discounts';
import globalAccessLocations, {
  State as GlobalAccessLocationsState,
} from './globalAccessLocations/reducer';
import membershipUpdateReasonOptions, {
  State as MembershipUpdateReasonOptionsState,
} from './membershipUpdateReasonOptions';
import opportunities, { State as OpportunitiesState } from './opportunities/reducer';
import opportunityUserInput, { State as OpportunitiyState } from './opportunity/reducer';
import pendingMoveIns, { State as PendingMoveInsState } from './pendingMoveIns';
import pendingMoveOuts, { State as PendingMoveOutsState } from './pendingMoveOuts';
import sfOpportunityIds, { State as SfOpportunityIdsState } from './sfOpportunityIds';
import sfOpportunity, { State as SfOpportunityState } from './salesforce';
import step, { State as StepState } from './step';
import terms, { State as TermsState } from './terms';
import chinaUploadContracts, { State as ChinaUploadContractsState } from './chinaUploadContracts';
import membershipAgreements, { State as MembershipAgreementsState } from './membershipAgreements';
import paperworkLocales, { State as PaperworkLocalesState } from './paperworkLocales';
import pendingAmendments from './amendments/pendingAmendments';
import { PendingAmendments } from './amendments/types';

export interface State {
  addons: AddonsState;
  agreementModifications: AgreementModificationsState;
  chinaUploadContracts: ChinaUploadContractsState;
  commitmentBasedDiscounts: CommitmentBasedDiscountsState;
  commitmentBasedPromotions: CommitmentBasedPromotionsState;
  contractDetails: ContractDetailsState;
  customTermsOfService: CustomTermsOfServiceState;
  discounts: DiscountsState;
  discountsApi: DiscountsApiState;
  docusignInfo: DocusignInfoState;
  earlyTermination: EarlyTerminationState;
  globalAccessLocations: GlobalAccessLocationsState;
  membershipAgreements: MembershipAgreementsState;
  membershipUpdateReasonOptions: MembershipUpdateReasonOptionsState;
  step: StepState;
  sfOpportunity: SfOpportunityState;
  sfOpportunityIds: SfOpportunityIdsState;
  opportunities: OpportunitiesState;
  opportunityUserInput: OpportunitiyState;
  paperworkLocales: PaperworkLocalesState;
  pendingMoveIns: PendingMoveInsState;
  pendingMoveOuts: PendingMoveOutsState;
  terms: TermsState;
  agreementManagement: AgreementManagementState;
  pendingAmendments: RequestState<PendingAmendments>;
}

export interface PaperworkSubset {
  paperwork: State;
  uploadedAgreements: UploadedAgreementState;
}

export default combineReducers<State>({
  addons,
  agreementModifications,
  chinaUploadContracts,
  commitmentBasedDiscounts,
  commitmentBasedPromotions,
  contractDetails,
  customTermsOfService,
  discounts,
  discountsApi,
  docusignInfo,
  earlyTermination,
  globalAccessLocations,
  membershipAgreements,
  membershipUpdateReasonOptions,
  step,
  sfOpportunity,
  sfOpportunityIds,
  opportunities,
  opportunityUserInput,
  paperworkLocales,
  pendingMoveIns,
  pendingMoveOuts,
  terms,
  agreementManagement,
  pendingAmendments,
});
