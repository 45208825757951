import moment from 'moment-timezone';
import { get, filter, keys, toArray, map, union, reduce } from 'lodash';

import { GlobalState } from 'store/modules';
import { deprecated_isPresent } from 'lib/util';
import { dateFormatsDeprecated } from 'lib/constants';
import Spacemoney from 'api/spacemoney';
import Spaceman from 'api/spaceman';
import SalesAPI from 'api/sales';
import { getDiscountsV2Values } from 'features/paperwork/ducks/selectors';
import { serializeDiscountsForContract } from 'features/paperwork/discounts/commitmentBasedPromotions/serializers/discountSerializer';
import { serializeInitialTermsForContract } from 'features/paperwork/discounts/initialTermsSerializer';
import { PendingMoveIn } from 'features/paperwork/ducks/pendingMoveIns';
import { PendingMoveOut } from 'features/paperwork/ducks/pendingMoveOuts';
import { Terms } from 'features/paperwork/ducks/terms';
import { State as CommitmentBasedDiscounts } from 'features/paperwork/ducks/commitmentBasedDiscounts';
import Suggestion from 'features/paperwork/discounts/commitmentBasedPromotions/models/suggestion';
import { Addons } from 'features/paperwork/ducks/addons';
import {
  SerializedReservation,
  InitialTermsType,
  InitialTerms,
  SetupFee,
} from 'features/paperwork/types';
import { DiscountType } from 'features/paperwork/discounts/commitmentBasedPromotions/types';
import { Company } from 'store/modules/companies/types';
import { ContractDetails } from 'features/paperwork/ducks/contractDetails';
import { Signatory } from 'features/paperwork/ducks/docusignInfo';
import { TerminationOption } from 'features/paperwork/earlyTermination/types';
import { ALL_ACCESS_RESERVABLE } from 'features/paperwork/globalAccess/constants';

type PaperworkType = {
  agreementModifications: {
    modificationTypes: {};
    selectedModificationTypeIds: {};
  };
  contractDetails: ContractDetails;
  pendingMoveIns: Array<PendingMoveIn>;
  pendingMoveOuts: Array<PendingMoveOut>;
  discounts: {
    products: Array<string>;
    promotionCodes: Array<string>;
    discountReasons: {};
  };
  commitmentBasedDiscounts: CommitmentBasedDiscounts;
  terms: {
    [key: string]: Terms;
  };
};

export interface Discount {
  amount: number;
  currency: string;
  endedOn: string;
  index: number;
  locationUUID: string;
  month: string;
  moveIn: string;
  percentageConversion: number;
  perpetuating: boolean;
  promotionCode: string | null | undefined;
  promotionRate: string;
  prorating: boolean;
  startedOn: string;
  title: string;
  type: string;
}

type SerializedAddon = {
  location_uuid: string | null | undefined;
  started_on: string;
  ended_on: string | null | undefined;
  amount: number;
  quantity: number;
  resource_uuid: string;
};

type SerializedSetupFee = {
  started_on: string;
  location_uuid: string;
  amount: number;
};

type PaperworkRequestPayload = Partial<{
  reservations: Array<SerializedReservation | PendingMoveOut>;
  discounts: Array<DiscountType>;
  contract_terms: Array<{}>;
  terms: Array<InitialTerms>;
  setup_fees: Array<SerializedSetupFee>;
  location_uuid: string;
  locale?: string;
  account_uuid: string;
  override: boolean;
  account_admin_uuid: string;
  signed_pdf_url: string | null | undefined;
  downgrade_reason_description: string | null | undefined;
  downgrade_note: string | null | undefined;
  illegal_move_out: IllegalMoveOut | null;
  waive_commitment_penalty: boolean;
  expiration_period: number | null | undefined;
  sign_date: string | null | undefined;
  add_ons: Array<SerializedAddon>;
  service_retainer_info: {
    is_manual_finalize_sr: boolean;
    manual_finalize_sr_reason: string | null | undefined;
  };
  self_executing: boolean;
  source_app_name: string | null | undefined;
  send_to_sigma: boolean;
  is_draft: boolean;
  signatories?: Array<Signatory>;
  early_termination_options: Array<TerminationOption>;
  early_termination_penalty: {
    charge_service_retainer: boolean;
    charge_remaining_commitment: boolean;
    service_retainer: number;
    ratio: number;
    remaining_commitment: number;
    total: number;
  } | null;
  multiple_all_access_reservation: boolean;
}>;

export type PaperworkValidateParams = {
  company: Company;
  override: boolean;
  suggestions: Hash<Suggestion>;
  contractsWithCommitmentBasedPromotions: Hash<boolean>;
  setupFees: Hash<SetupFee>;
  initialTerms: Hash<InitialTermsType>;
};

export type IllegalMoveOut = {
  reason: string;
  approver: { email: string; full_name: string };
  eviction: boolean;
};

export type EarlyTerminationPenalty = {
  chargeServiceRetainer: boolean;
  chargeRemainingCommitment: boolean;
  serviceRetainer: number;
  refundServiceRetainer: boolean;
  remainingCommitment: number;
  total: number;
  ratio: number;
};

export type PaperworkCreateParams = PaperworkValidateParams & {
  addons?: Addons | null | undefined;
  commitmentBasedPromotionDiscounts: {};
  currentLocations: Array<{}>;
  illegalMoveOut: IllegalMoveOut | null;
  moveOutLocations: Array<{}>;
  sfOpportunityId: string | null | undefined;
  sourceAppName: string | null | undefined;
  signDate: string | null | undefined;
  uploadedContractLink: string | null | undefined;
  waiveCommitmentPenalty: boolean;
  enableSelfExecution: boolean;
  sendToDocuSign: boolean;
  signatory: Signatory | null | undefined;
  locale?: string;
  earlyTermination: Hash<Array<TerminationOption>>;
  termsOfServiceUrl: string;
  earlyTerminationPenalty: EarlyTerminationPenalty | null | undefined;
  isDraft: boolean;
  outOfPolicyInfo?: object;
  serviceCloudNumber?: string | null | undefined;
};

export type UpdateMembershipSignedAgreementParams = {
  contractId: string;
  contractType: string;
  publicUrl: string;
  contract: { location: { uuid: string } };
  sourceAppName: string;
};

export type UploadAgreementParams = {
  uploadedContract: string;
  contractId: string;
  contractType: string;
  countryCodes: Array<string>;
};

// NOTE(grozki): We can't know the type of the state without creating another circular dependency.
function getPaperworkFromState(state: {}): PaperworkType {
  return get(state, 'paperwork');
}

// TODO: Rename this something sensible.
interface ExtractedTerminationOption {
  required_notice_days: number;
  penalty_amount: number;
  penalty_type: string;
  move_in_date: any;
  option_end_date: string;
  location_uuid: any;
}

const extractTerminationOptions = (
  earlyTermination: Hash<Array<TerminationOption>>,
  pendingMoveIns: Array<PendingMoveIn>
): Array<ExtractedTerminationOption> =>
  reduce(
    earlyTermination,
    (
      acc: Array<ExtractedTerminationOption>,
      options: Array<TerminationOption>,
      key: string
    ): Array<ExtractedTerminationOption> => {
      const location = pendingMoveIns.find(
        ({ buildingName, moveIn }): boolean => `${buildingName} - ${moveIn}` === key
      );

      if (location) {
        const updatedOptions: Array<ExtractedTerminationOption> = options.map(
          ({
            date,
            noticeDays,
            penalty,
            penaltyType,
          }: TerminationOption): ExtractedTerminationOption => ({
            penalty_amount: penalty,
            penalty_type: penaltyType,
            option_end_date: moment(date).format(dateFormatsDeprecated.iso_date),
            required_notice_days: noticeDays,
            move_in_date: location.moveIn,
            location_uuid: location.building_uuid,
          })
        );

        acc.push(...updatedOptions);
      }

      return acc;
    },
    []
  );

export default class PaperworkClient {
  // Creating paperwork requires _all this stuff_
  getState: () => GlobalState;
  spacemoney: Spacemoney;
  spaceman: Spaceman;
  sales: SalesAPI;
  addons: Addons | null | undefined;
  commitmentBasedPromotionDiscounts: {};
  company: Company;
  contractDetails: ContractDetails;
  contractsWithCommitmentBasedPromotions: Hash<boolean>;
  currentLocations: Array<{}>;
  discountReasons: {};
  discounts: {};
  enableSelfExecution: boolean;
  illegalMoveOut: IllegalMoveOut | null;
  initialTerms: Hash<InitialTermsType>;
  moveOutLocations: Array<{}>;
  moveInLocations: Array<{}>;
  override: boolean;
  pendingMoveIns: Array<PendingMoveIn>;
  pendingMoveOuts: Array<PendingMoveOut>;
  selectedModificationTypeIds: Hash<string>;
  setupFees: Hash<SetupFee>;
  sfOpportunityId: string | null | undefined;
  signDate: string | null | undefined;
  sourceAppName: string | null | undefined;
  suggestions: Hash<Suggestion>;
  terms: Hash<Terms>;
  uploadedContractLink: string | null | undefined;
  waiveCommitmentPenalty: boolean;
  sendToDocuSign: boolean;
  signatory: Signatory | null | undefined;
  locale: string | null | undefined;
  earlyTermination: Hash<Array<TerminationOption>>;
  earlyTerminationPenalty: EarlyTerminationPenalty | null | undefined;
  termsOfServiceUrl: string;
  isDraft: boolean;
  outOfPolicyInfo?: any;
  serviceCloudNumber?: string | null | undefined;

  constructor(getState: () => GlobalState) {
    this.getState = getState;
    this.spacemoney = new Spacemoney();
    this.spaceman = new Spaceman();
    this.sales = new SalesAPI();

    this.commitmentBasedPromotionDiscounts = {};
    this.contractsWithCommitmentBasedPromotions = {};
    this.currentLocations = [];
    this.enableSelfExecution = false;
    this.illegalMoveOut = null;
    this.moveOutLocations = [];
    this.moveInLocations = [];
    this.override = false;
    this.waiveCommitmentPenalty = false;
  }

  hydrateData() {
    const {
      terms,
      pendingMoveIns,
      pendingMoveOuts,
      discounts: { discountReasons },
      agreementModifications: { selectedModificationTypeIds },
      contractDetails,
    } = getPaperworkFromState(this.getState());

    this.pendingMoveIns = pendingMoveIns;
    this.pendingMoveOuts = pendingMoveOuts;
    this.discounts = getDiscountsV2Values(this.getState());
    this.terms = terms;
    this.discountReasons = discountReasons;
    this.contractDetails = contractDetails;
    this.selectedModificationTypeIds = selectedModificationTypeIds;
  }

  validate({
    company,
    setupFees,
    initialTerms,
    suggestions,
    contractsWithCommitmentBasedPromotions,
  }: PaperworkValidateParams) {
    this.hydrateData();

    this.company = company;
    this.setupFees = setupFees;
    this.initialTerms = initialTerms;
    this.suggestions = suggestions || {};
    this.contractsWithCommitmentBasedPromotions = contractsWithCommitmentBasedPromotions || {};

    return this.spaceman.validateMembershipActions(this.getPayload());
  }

  create({
    company,
    override,
    suggestions,
    contractsWithCommitmentBasedPromotions,
    setupFees,
    initialTerms,
    addons,
    commitmentBasedPromotionDiscounts,
    currentLocations,
    illegalMoveOut,
    moveOutLocations,
    sfOpportunityId,
    sourceAppName,
    signDate,
    uploadedContractLink,
    waiveCommitmentPenalty,
    enableSelfExecution,
    sendToDocuSign,
    signatory,
    locale,
    earlyTermination,
    termsOfServiceUrl,
    earlyTerminationPenalty,
    isDraft,
    outOfPolicyInfo,
    serviceCloudNumber,
  }: PaperworkCreateParams) {
    this.hydrateData();

    this.company = company;
    this.setupFees = setupFees;
    this.initialTerms = initialTerms;
    this.override = override;
    this.addons = addons;
    this.commitmentBasedPromotionDiscounts = commitmentBasedPromotionDiscounts || {};
    this.contractsWithCommitmentBasedPromotions = contractsWithCommitmentBasedPromotions || {};
    this.currentLocations = currentLocations || [];
    this.illegalMoveOut = illegalMoveOut || null;
    this.moveOutLocations = moveOutLocations || [];
    this.sfOpportunityId = sfOpportunityId || null;
    this.sourceAppName = sourceAppName || null;
    this.signDate = signDate || null;
    this.suggestions = suggestions || {};
    this.uploadedContractLink = uploadedContractLink || null;
    this.waiveCommitmentPenalty = waiveCommitmentPenalty || false;
    this.enableSelfExecution = enableSelfExecution || false;
    this.sendToDocuSign = sendToDocuSign || false;
    this.isDraft = isDraft || false;
    this.signatory = signatory;
    this.locale = locale;
    this.outOfPolicyInfo = outOfPolicyInfo || undefined;
    this.serviceCloudNumber = serviceCloudNumber || null;

    /**
     * earlyTermination - Early Termination Options, right to end contract before full term
     *                    This piece of data is configured for move in ppwk
     *
     * earlyTerminationPenalty - Penalties being charged for not fulfilling contract to its full term.
     *                           This is configured for move out ppwk
     */
    this.earlyTermination = earlyTermination;
    this.earlyTerminationPenalty = earlyTerminationPenalty || null;
    this.termsOfServiceUrl = termsOfServiceUrl;

    const primaryMember = get(this.company, 'primary_member', {});

    // If there is no primary member, do not create membership agreement/reservations
    if (!deprecated_isPresent(primaryMember)) {
      const reason = 'Company is missing a primary member.';
      return Promise.reject(reason);
    }

    return this.createPaperworks();
  }

  createPaperworks() {
    const { illegalMoveOut, spaceman, uploadedContractLink, outOfPolicyInfo } = this;

    const payload = this.getPayload();

    if (uploadedContractLink) {
      // Creates membership agreement and reservations in one swoop, skips member signing digitally
      return spaceman.createMembershipAgreementAndMoveIn(payload);
    } else if (illegalMoveOut || outOfPolicyInfo?.usingApprovalFlow) {
      // Sends email to city lead and creates a draft agreement
      return spaceman
        .createOutOfPolicyApprovalRequest(payload)
        .then(() =>
          spaceman.createMembershipAgreement({ ...payload, is_draft: true, expiration_period: 72 })
        );
    }

    // Default create
    return spaceman.createMembershipAgreement(payload);
  }

  uploadAgreement({
    uploadedContract,
    contractId,
    contractType,
    countryCodes,
  }: UploadAgreementParams) {
    return this.spacemoney.uploadAgreement({
      agreement_id: contractId,
      type: contractType,
      data: uploadedContract,
      countryCodes,
    });
  }

  validateOpportunity(sfOpportunityId: string) {
    return this.sales.getOpportunityStage(window.atob(sfOpportunityId));
  }

  validateSecurityDepositExemptionForReservation(reservationUuid: string, locationUuid: string) {
    return this.spaceman.validateSecurityDepositExemptionForReservation(
      reservationUuid,
      locationUuid
    );
  }

  updateMembershipSignedAgreementAndMoveIn({
    contractId,
    contractType,
    publicUrl,
    contract,
    sourceAppName,
  }: UpdateMembershipSignedAgreementParams) {
    return this.spaceman.createMembershipAgreementAndMoveIn({
      membership_agreement_uuids: contractId,
      type: contractType,
      signed_pdf_url: publicUrl,
      location_uuid: contract.location.uuid,
      source_app_name: sourceAppName,
    });
  }

  // Private methods

  // Serializers

  contractKey(pendingMoveIn: Partial<PendingMoveIn>): string {
    return `${pendingMoveIn.buildingName?.replace('.', '')} - ${pendingMoveIn.moveIn}`;
  }

  serializeDiscounts(discounts: Array<Discount> | null | undefined): Array<DiscountType> {
    if (discounts == null) {
      return [];
    }

    return discounts.reduce(
      (serializedDiscounts: Array<DiscountType>, discount): Array<DiscountType> => {
        if (!discount.amount || Number(discount.amount) === 0) {
          return serializedDiscounts;
        }

        if (discount.perpetuating) {
          // discounts using percent with prorated 1st month will have different price for mo 1 and the rest
          if (moment.utc(discount.startedOn).date() > 1) {
            const nextStartedOn = moment
              .utc(discount.endedOn)
              .add(1, 'day')
              .format(dateFormatsDeprecated.iso_date);
            const isPercent = discount.type === 'percent';

            const fullDiscount = {
              ...discount,
              endedOn: '',
              percentageConversion: isPercent ? discount.percentageConversion : discount.amount,
              startedOn: nextStartedOn,
            };

            const proratedDiscount = this.serializeDiscount(discount);
            const serializedDiscount = this.serializeDiscount(fullDiscount);

            return serializedDiscounts.concat(proratedDiscount, serializedDiscount);
          }

          discount.endedOn = '';

          return serializedDiscounts.concat(this.serializeDiscount(discount));
        }

        return serializedDiscounts.concat(this.serializeDiscount(discount));
      },
      []
    );
  }

  compileDiscounts(): Array<DiscountType> {
    const {
      commitmentBasedPromotionDiscounts,
      contractsWithCommitmentBasedPromotions,
      discounts,
      pendingMoveIns,
      suggestions,
    } = this;

    const contractKeys = union(
      Object.keys(discounts),
      Object.keys(commitmentBasedPromotionDiscounts)
    );
    return contractKeys.reduce((serializedDiscounts: Array<DiscountType>, contractKey) => {
      if (contractsWithCommitmentBasedPromotions[contractKey]) {
        const filteredPendingMoveIns = filter(
          pendingMoveIns,
          pendingMoveIn => this.contractKey(pendingMoveIn) === contractKey
        );
        return serializedDiscounts.concat(
          serializeDiscountsForContract(
            commitmentBasedPromotionDiscounts[contractKey],
            suggestions[contractKey],
            filteredPendingMoveIns
          )
        );
      }

      if (discounts[contractKey] != null) {
        return serializedDiscounts.concat(
          this.serializeDiscounts(discounts[contractKey].discounts)
        );
      }

      return serializedDiscounts;
    }, []);
  }

  compileSetupFees(): Array<SerializedSetupFee> {
    return map(keys(this.setupFees), key => {
      return this.serializeSetupFee(this.setupFees[key]);
    });
  }

  compileInitialTerms(): Array<InitialTerms> {
    const {
      initialTerms,
      contractsWithCommitmentBasedPromotions,
      pendingMoveIns,
      serializeInitialTerm,
      suggestions,
    } = this;

    const contractKeys = union(Object.keys(suggestions), Object.keys(initialTerms));
    return contractKeys.reduce(
      (serializedInitialTerms: Array<InitialTerms>, contractKey): Array<InitialTerms> => {
        if (contractsWithCommitmentBasedPromotions[contractKey]) {
          if (suggestions[contractKey] == null) {
            return serializedInitialTerms;
          }

          const pendingMoveIn = pendingMoveIns.find(
            (pendingMoveIn): boolean => this.contractKey(pendingMoveIn) === contractKey
          );
          const initialTermsForContract = serializeInitialTermsForContract(
            suggestions[contractKey].getCommitmentLength(),
            pendingMoveIn
          );

          return initialTermsForContract != null
            ? serializedInitialTerms.concat(initialTermsForContract)
            : serializedInitialTerms;
        }

        if (initialTerms[contractKey] != null) {
          return serializedInitialTerms.concat(serializeInitialTerm(initialTerms[contractKey]));
        }

        return serializedInitialTerms;
      },
      []
    );
  }

  compileAddons(): Array<SerializedAddon> {
    if (!this.addons || !this.addons.inProgress) {
      return [];
    }

    const { locationUuid } = this.addons;

    return this.addons.addonFields.map(addonField => ({
      location_uuid: locationUuid,
      started_on: moment(addonField.startedOn).format(dateFormatsDeprecated.iso_date),
      ended_on:
        addonField.endedOn && moment(addonField.endedOn).format(dateFormatsDeprecated.iso_date),
      amount: addonField.price,
      quantity: addonField.quantity,
      resource_uuid: addonField.addonUuid,
      product_uuid: addonField.productUuid,
    }));
  }

  serialize(obj: PendingMoveIn): SerializedReservation {
    const buildingMoveInKey = this.contractKey(obj);
    const initialTerms = this.initialTerms
      ? this.initialTerms[buildingMoveInKey]
      : {
          calendarUnit: 'month',
          count: 1,
          locationUUID: obj.building_uuid,
          moveInDate: obj.moveIn,
        };
    const discountReason = this.discountReasons ? this.discountReasons[buildingMoveInKey] : null;
    const selectedModificationTypeIds = getPaperworkFromState(this.getState())
      .agreementModifications.selectedModificationTypeIds;
    const selectedModificationTypes = deprecated_isPresent(selectedModificationTypeIds)
      ? selectedModificationTypeIds[buildingMoveInKey]
      : '';

    return {
      action: 'add',
      type: 'move_in',
      change_type: 'Add',
      // @ts-ignore
      capacity: obj.capacity,
      quantity: obj.quantity,
      committed: false,
      location_uuid: obj.building_uuid,
      market_price: obj.price,
      move_in_date: obj.moveIn,
      move_out_date: null,
      initial_terms: initialTerms,
      reservable_uuid: obj.office,
      reservable_number: obj.officeNum,
      reservable_type: obj.officeType,
      agreement_notes: obj.agreementModifications,
      modification_types: selectedModificationTypes,
      long_term_agreement: obj.longTermAgreement,
      notice_requirement: obj.noticeRequirement,
      sf_opportunity_id: this.sfOpportunityId || obj.sf_opportunity_id,
      membership_agreement_uuid: obj.membership_agreement_uuid,
      discount_reason: discountReason ? discountReason.value : null,
      product_uuid: obj.productUuid,
    };
  }

  compileContractTerms() {
    return toArray(this.terms);
  }

  serializeInitialTerm(initialTerms: InitialTermsType): InitialTerms {
    return {
      calendarUnit: initialTerms.calendarUnit,
      count: initialTerms.count,
      location_uuid: initialTerms.locationUUID,
      started_on: initialTerms.moveInDate,
    };
  }

  serializeDiscount(obj: Discount): DiscountType {
    return {
      location_uuid: obj.locationUUID,
      move_in_date: obj.moveIn,
      started_on: obj.startedOn,
      ended_on: obj.endedOn,
      amount: obj.type === 'amount' ? obj.amount : obj.percentageConversion,
      currency: obj.currency,
      promotion_code: obj.promotionCode,
    };
  }

  serializeSetupFee(obj: SetupFee): SerializedSetupFee {
    return {
      started_on: obj.startedOn,
      location_uuid: obj.locationUUID,
      amount: obj.amount,
    };
  }

  hasMultipleAllAccessReservation = (reservations): boolean => {
    return (
      reservations.filter(reservation => reservation.reservable_number === ALL_ACCESS_RESERVABLE)
        .length > 1
    );
  };

  getPayload(): PaperworkRequestPayload {
    const contractDetails = getPaperworkFromState(this.getState()).contractDetails;

    const reservations = [
      ...this.pendingMoveIns.map(moveIn => this.serialize(moveIn)),
      ...this.pendingMoveOuts,
    ];
    const multipleAllAccessReservation = this.hasMultipleAllAccessReservation(reservations);
    const discounts = this.compileDiscounts();
    const contractTerms = this.compileContractTerms();
    const terms = this.compileInitialTerms();
    const setupFees = this.compileSetupFees();
    const accountUuid = this.company.uuid;
    const { override } = this;
    // only need one of the location_uuids from move ins and move outs for Spaceman Tenant
    const locationUuid = reservations[0].location_uuid;
    const accountAdminUuid = this.company.primary_member.uuid;
    const signedPdfUrl = this.uploadedContractLink;
    const signDate = this.signDate;
    const downgradeReasonDescription = contractDetails.reason_option_description;
    const downgradeNote = contractDetails.note;
    const illegalMoveOut = this.illegalMoveOut;
    const expirationPeriod = contractDetails.expiration_period;
    const addOns = this.compileAddons();
    const serviceCloudNumber = this.serviceCloudNumber;
    const serviceRetainerInfo = {
      is_manual_finalize_sr: contractDetails.is_manual_finalize_sr,
      manual_finalize_sr_reason: contractDetails.manual_finalize_sr_reason,
    };
    const selfExecuting = !!this.enableSelfExecution;
    const { sourceAppName, sendToDocuSign, signatory, locale, isDraft } = this;
    const earlyTerminationOptions = extractTerminationOptions(
      this.earlyTermination,
      this.pendingMoveIns
    );

    const waiveCommitmentPenalty =
      this.waiveCommitmentPenalty || !!this.earlyTerminationPenalty?.refundServiceRetainer;

    const outOfPolicyInfo = this.outOfPolicyInfo;

    const payload: PaperworkRequestPayload = {
      reservations,
      discounts,
      contract_terms: contractTerms,
      terms,
      setup_fees: setupFees,
      location_uuid: locationUuid,
      account_uuid: accountUuid,
      override,
      account_admin_uuid: accountAdminUuid,
      signed_pdf_url: signedPdfUrl,
      downgrade_reason_description: downgradeReasonDescription,
      downgrade_note: downgradeNote,
      illegal_move_out: illegalMoveOut,
      waive_commitment_penalty: waiveCommitmentPenalty,
      expiration_period: expirationPeriod,
      sign_date: signDate,
      add_ons: addOns,
      service_retainer_info: serviceRetainerInfo,
      self_executing: selfExecuting,
      source_app_name: sourceAppName,
      send_to_sigma: sendToDocuSign,
      is_draft: isDraft,
      out_of_policy_discount: outOfPolicyInfo,
      // @ts-ignore
      early_termination_options: earlyTerminationOptions,
      custom_tos_url: this.termsOfServiceUrl,
      service_cloud_number: serviceCloudNumber,
      early_termination_penalty: this.earlyTerminationPenalty
        ? {
            charge_service_retainer: this.earlyTerminationPenalty.chargeServiceRetainer,
            charge_remaining_commitment: this.earlyTerminationPenalty.chargeRemainingCommitment,
            service_retainer: this.earlyTerminationPenalty.serviceRetainer,
            ratio: this.earlyTerminationPenalty.ratio,
            remaining_commitment: this.earlyTerminationPenalty.remainingCommitment,
            total: this.earlyTerminationPenalty.total,
          }
        : null,
      multiple_all_access_reservation: multipleAllAccessReservation,
    };
    // Only send to docusign if we have a signatory: necessary for Flow.
    if (sendToDocuSign && signatory) {
      payload.signatories = [signatory];
    }
    if (sendToDocuSign && locale) {
      payload.locale = locale;
    }
    return payload;
  }
}
