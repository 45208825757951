import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { fetchExperiments } from '@wework/we-experiment-react';

import { ReduxProps } from 'store/types';
import { getTracker } from 'features/performance/track';
import { startTracking, stopTracking } from 'features/performance/service';
import { loadMedallia, MedalliaAppWrapperProps, withMedallia } from 'features/survey/medallia';
import { getCurrentBuildingCountryCode } from 'store/selectors';
import { getUserInfoFromAuth, getUserLocationUuidFromAuth } from 'features/auth/selectors';
import { fetchLocations } from 'store/modules/locations';
import {
  fetchCurrentLocationById,
  fetchHomeLocation,
  loadRecentLocations,
} from 'store/modules/siLocations';
import {
  getCurrentLocation,
  getHomeLocation,
  getCurrentLocationUuid,
} from 'store/modules/siLocations/selectors';
import { getSpacemanId } from 'store/modules/siLocations/utils';
import config from 'config';
import { snakeCaseJson } from 'lib/util';
import { fetchMemberNotificationTemplates } from 'features/communication/memberNotifications/redux';

const APP_BOOTSTRAP_EVENT = 'app_bootstrap';
const appBootstrapTracker = getTracker({
  event: APP_BOOTSTRAP_EVENT,
  label: 'App Loaded',
});

type OwnProps = {
  children: React.ReactElement;
  isPreloading: boolean;
  userLoggedIn: boolean;
};

const mapStateToProps = (state, props) => ({
  buildingCountryCode: getCurrentBuildingCountryCode(state, props),
  currentLocationUuid: getCurrentLocationUuid(state),
  defaultLocation: getCurrentLocation(state),
  homeLocation: getHomeLocation(state),
  homeLocationUuid: getUserLocationUuidFromAuth(state),
  userInfo: getUserInfoFromAuth(state),
});

const mapDispatchToProps = {
  fetchExperiments,
  fetchLocations,
  fetchCurrentLocationById,
  fetchHomeLocation,
  fetchMemberNotificationTemplates,
  loadRecentLocations,
};

export type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  OwnProps &
  MedalliaAppWrapperProps &
  WithAuth0Props;

export class AppBootstrapComponent extends React.Component<Props> {
  componentDidMount(): void {
    this.props.loadRecentLocations();
  }

  componentDidUpdate(prevProps) {
    const {
      auth0,
      fetchExperiments,
      fetchMemberNotificationTemplates,
      isPreloading,
      userInfo,
      buildingCountryCode,
      currentLocationUuid,
      userLoggedIn,
      homeLocationUuid,
      fetchCurrentLocationById,
      fetchHomeLocation,
    } = this.props;

    this.updateTracking(prevProps);
    this.updateMedallia(prevProps);

    if (!auth0.isAuthenticated || isPreloading) return;

    const defaultLocationUuid = userInfo?.locationUuid;
    const previousLocationUuid = prevProps.userInfo?.locationUuid;
    const defaultLocationChanged =
      defaultLocationUuid && defaultLocationUuid !== previousLocationUuid;
    const currentLocationChanged =
      currentLocationUuid !== prevProps.currentLocationUuid ||
      buildingCountryCode !== prevProps.buildingCountryCode;

    const userJustLoggedIn = !prevProps.userLoggedIn && userLoggedIn;

    if (userJustLoggedIn || defaultLocationChanged || currentLocationChanged) {
      if (currentLocationUuid) {
        fetchCurrentLocationById(currentLocationUuid);
      } else if (defaultLocationUuid) {
        fetchCurrentLocationById(defaultLocationUuid);
      }

      if (homeLocationUuid) {
        fetchHomeLocation(homeLocationUuid);
      }

      // TODO: Fetch experiments by tag (instead of by name)
      fetchExperiments(userInfo.uuid, config.split.token, config.split.experiments, {
        userAttributes: snakeCaseJson({
          ...userInfo,
          buildingCountryCode,
        }),
      }).finally((): void => {
        // Loads up notification templates (food delievered / package arrived)
        fetchMemberNotificationTemplates();
      });
    }
  }

  // Using window.analytics instead of Trackable because of Bug https://github.com/WeConnect/spacestation-web/pull/3879
  updateTracking = (prevProps): void => {
    const { auth0, defaultLocation, homeLocation, userInfo, userLoggedIn } = this.props;

    if (userLoggedIn && !prevProps.userLoggedIn) {
      startTracking(APP_BOOTSTRAP_EVENT, appBootstrapTracker);
    }

    if (!auth0.isAuthenticated && prevProps.auth0.isAuthenticated) {
      stopTracking(APP_BOOTSTRAP_EVENT);
    }

    if (
      // terse but effective check to reduce redundant identify calls
      defaultLocation?.id !== prevProps?.defaultLocation?.id ||
      homeLocation?.id !== prevProps?.homeLocation?.id
    )
      window?.analytics?.identify(userInfo?.uuid, {
        default_location_name: homeLocation?.name,
        location_city: defaultLocation?.defaultAddress?.locality,
        location_country: defaultLocation?.defaultAddress?.country,
        location_name: defaultLocation?.name,
        location_uuid: defaultLocation ? getSpacemanId(defaultLocation) : null,
        user_country: homeLocation?.defaultAddress?.country,
        user_uuid: userInfo?.userUuid,
        email: userInfo.email,
      });
  };

  updateMedallia = (prevProps: Props): void => {
    if (this.props.userInfo?.uuid !== prevProps.userInfo?.uuid) {
      loadMedallia(this.props.userInfo);
    }

    if (this.props.isMedalliaReady && !prevProps.isMedalliaReady) {
      this.props.showMedallia(this.props.userInfo);
    }
  };

  render() {
    return this.props.children;
  }
}

export const TestableAppBootstrapComponent = AppBootstrapComponent;

export const AppBootstrap = compose<React.ComponentClass<OwnProps>>(
  withAuth0,
  withMedallia,
  connect(mapStateToProps, mapDispatchToProps)
)(AppBootstrapComponent);
