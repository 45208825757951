import { Filters, SIFilters } from './types';

type SiFilterMapper = (filters: Filters, siFilters: SIFilters) => void;

const writeThroughCreator = (name: string): SiFilterMapper => (
  filters: Filters,
  siFilters: SIFilters
) => {
  if (filters[name]) {
    siFilters[name] = filters[name];
  }
};

const nameChangeCreator = (name: string, siName: string) => (
  filters: Filters,
  siFilters: SIFilters
) => {
  if (filters[name]) {
    siFilters[siName] = filters[name];
  }
};

const locationIdFilterMapper = (filters: Filters, siFilters: SIFilters) => {
  siFilters.locationIds = filters.locationUuids?.map(id => ({ type: 'SPACEMAN', value: id })) || [];
};

const sortByFilterMapper = (filters: Filters, siFilters: SIFilters) => {
  if (filters.sortBy) {
    siFilters.sortBy = {
      field: filters.sortBy.fieldName,
      order: filters.sortBy.sortOrder?.toLocaleUpperCase('en-US'),
    };
  }
};

const filterableGatesFilterMapper = (filters: Filters, siFilters: SIFilters) => {
  if (filters.filterableGates) {
    siFilters.filterableGates = filters.filterableGates.map(gate =>
      gate === 'Open' ? 'OPEN' : gate
    );
  }

  if (siFilters.filterableGates) {
    siFilters.filterableGates.push('EMPTY');
  } else {
    siFilters.filterableGates = ['EMPTY'];
  }
};

// mapping from filter config name to SI filter mapper
const SiFilterMap: Hash<SiFilterMapper> = {
  locationUuids: locationIdFilterMapper,
  filterableGates: filterableGatesFilterMapper,
  productTypes: writeThroughCreator('productTypes'),
  attributes: writeThroughCreator('attributes'),
  availability: writeThroughCreator('availability'),
  maxPrice: writeThroughCreator('maxPrice'),
  minPrice: writeThroughCreator('minPrice'),
  maxFuzzyCapacity: writeThroughCreator('maxFuzzyCapacity'),
  minFuzzyCapacity: writeThroughCreator('minFuzzyCapacity'),
  maxSquareFootage: writeThroughCreator('maxSquareFootage'),
  minSquareFootage: writeThroughCreator('minSquareFootage'),
  status: writeThroughCreator('status'),
  searchGroups: nameChangeCreator('searchGroups', 'isSearchGroups'),
  startDate: nameChangeCreator('startDate', 'nextAvailableDate'),
  boundingBox: writeThroughCreator('boundingBox'),
  gates: writeThroughCreator('gates'),
  minCapacity: writeThroughCreator('minCapacity'),
  maxCapacity: writeThroughCreator('maxCapacity'),
  floorIds: writeThroughCreator('floorIds'),
  sortBy: sortByFilterMapper,
};

const applyFilterMappers = (filters: Filters) => {
  const siFilters: SIFilters = {};
  Object.keys(SiFilterMap).forEach(key => SiFilterMap[key](filters, siFilters));

  // removing boundingBox when location filter is available
  if (siFilters.boundingBox && siFilters.locationIds?.length) {
    delete siFilters.boundingBox;
  }

  siFilters.filterableGates = ['OPEN', 'A', 'B', 'C', 'D', 'E', 'EMPTY'];

  return siFilters;
};

export default applyFilterMappers;
