import React from 'react';
import cn from 'classnames';
import { compact } from 'lodash';

import { Location as LocationType } from 'store/modules/siLocations';
import ScrollIntoView from 'components/scrollIntoView';
import HighlightedText from 'components-dieter/highlightedText';

import styles from './index.scss';

type Props = Readonly<{
  location: LocationType;
  query?: string;
  id?: string;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
}>;

const formatAddress = address =>
  compact([address.locality, address.administrativeArea, address.countryISO3]).join(', ');

const HighlightAddress = ({ address, query }) => {
  if (!address) return null;
  return (
    <div className={styles.locationAddress}>
      <HighlightedText text={formatAddress(address)} query={query} />
    </div>
  );
};

const Location = ({ location, query, id, selected = false, onClick, className }: Props) => (
  <ScrollIntoView
    className={cn(styles.location, { [styles.selected]: selected }, className)}
    onClick={onClick}
    id={id}
    focused={selected}
  >
    <div className={styles.locationName}>
      <HighlightedText text={location?.name || ''} query={query} />
    </div>
    <HighlightAddress address={location?.defaultAddress} query={query} />
  </ScrollIntoView>
);

export default Location;
