import { handleActions, ActionMeta, Action } from 'redux-actions';

import {
  Ban,
  CreateBanBulkResponse,
  CreateBanSingleResponse,
  IncidentType,
  IncidentTypesResponse,
  UserStatusResponse,
} from 'features/profile/redux/types';
import {
  FETCH_INCIDENT_TYPES,
  FETCH_INCIDENT_TYPES_SUCCESS,
  FETCH_INCIDENT_TYPES_FAIL,
  FETCH_USER_STATUS,
  FETCH_USER_STATUS_SUCCESS,
  FETCH_USER_STATUS_FAIL,
  CREATE_BAN,
  CREATE_BAN_SUCCESS,
  CREATE_BAN_FAIL,
  GET_IMPERSONATION_HISTORY,
  GET_IMPERSONATION_HISTORY_SUCCESS,
  GET_IMPERSONATION_HISTORY_FAIL,
  IMPERSONATE_MEMBER,
  IMPERSONATE_MEMBER_SUCCESS,
  IMPERSONATE_MEMBER_FAIL,
  ANONYMIZE_USER,
  ANONYMIZE_USER_SUCCESS,
  ANONYMIZE_USER_FAIL,
  GET_USER_AUDIT_HISTORY,
  GET_USER_AUDIT_HISTORY_SUCCESS,
  GET_USER_AUDIT_HISTORY_FAIL,
  GET_LOCALE_CODES,
  GET_LOCALE_CODES_SUCCESS,
  GET_LOCALE_CODES_FAIL,
  GET_USER_KIND_TYPES,
  GET_USER_KIND_TYPES_SUCCESS,
  GET_USER_KIND_TYPES_FAIL,
  SYNC_USER_TO_MENA,
  SYNC_USER_TO_MENA_SUCCESS,
  SYNC_USER_TO_MENA_FAIL,
  GET_ACCOUNT_AUDIT_LOG,
  GET_ACCOUNT_AUDIT_LOG_SUCCESS,
  GET_ACCOUNT_AUDIT_LOG_FAIL,
} from 'features/profile/redux/constants';

export interface State {
  fetchIncidentTypesLoading: boolean;
  fetchUserStatusLoading: boolean;
  fetchUserStatusLoaded: boolean;
  fetchIncidentTypesLoaded: boolean;
  createBanLoading: boolean;
  createBanLoaded: boolean;
  fetchIncidentTypesError: string | null | undefined;
  fetchUserStatusError: string | null | undefined;
  createBanError: string | null | undefined;
  incidentTypes: Array<IncidentType>;
  // NOTE: Apparently we can get an empty object, when no ban exists, and store it as-is.
  byUuid: Hash<Ban | {}>;
  impersonationHistory: any[];
  impersonationHistoryLoading: boolean;
  impersonationHistoryFail: boolean;
  impersonatingMember: boolean;
  impersonatingMemberSuccess: boolean;
  impersonatingMemberFail: boolean;
  anonymizingUser: boolean;
  anonymizingUserSuccess: boolean;
  anonymizingUserFail: boolean;
  auditHistoryPages: {};
  userAuditHistory: any[];
  fetchingUserAuditHistory: boolean;
  fetchingUserAuditHistorySuccess: boolean;
  fetchingUserAuditHistoryFail: boolean;
  localeCodes: [];
  fetchingLocaleCodes: boolean;
  fetchingLocaleCodesSuccess: boolean;
  fetchingLocaleCodesFail: boolean;
  userKindTypes: [];
  fetchingUserKindTypes: boolean;
  fetchingUserKindTypesSuccess: boolean;
  fetchingUserKindTypesFail: boolean;
  syncingUserToMena: boolean;
  syncingUserToMenaSuccess: boolean;
  syncingUserToMenaFail: boolean;
  accountAccessLog: any[];
  accountAccessTotalPages: any;
  fetchingAccountAuditLog: boolean;
  fetchingAccountAuditLogSuccess: boolean;
  fetchingAccountAuditLogFail: boolean;
}

export interface UserProfileSubset {
  userProfileSettings: State;
}

const initialState: State = {
  fetchIncidentTypesLoading: false,
  fetchIncidentTypesLoaded: false,
  fetchIncidentTypesError: null,
  fetchUserStatusLoading: false,
  fetchUserStatusLoaded: false,
  fetchUserStatusError: null,
  createBanLoading: false,
  createBanLoaded: false,
  createBanError: null,
  incidentTypes: [],
  byUuid: {},
  impersonationHistory: [],
  impersonationHistoryLoading: false,
  impersonationHistoryFail: false,
  impersonatingMember: false,
  impersonatingMemberSuccess: false,
  impersonatingMemberFail: false,
  anonymizingUser: false,
  anonymizingUserSuccess: false,
  anonymizingUserFail: false,
  auditHistoryPages: {},
  userAuditHistory: [],
  fetchingUserAuditHistory: false,
  fetchingUserAuditHistorySuccess: false,
  fetchingUserAuditHistoryFail: false,
  localeCodes: [],
  fetchingLocaleCodes: false,
  fetchingLocaleCodesSuccess: false,
  fetchingLocaleCodesFail: false,
  userKindTypes: [],
  fetchingUserKindTypes: false,
  fetchingUserKindTypesSuccess: false,
  fetchingUserKindTypesFail: false,
  syncingUserToMena: false,
  syncingUserToMenaSuccess: false,
  syncingUserToMenaFail: false,
  accountAccessLog: [],
  accountAccessTotalPages: null,
  fetchingAccountAuditLog: false,
  fetchingAccountAuditLogSuccess: false,
  fetchingAccountAuditLogFail: false,
};

export const reducer = handleActions<State, any, any>(
  {
    [FETCH_INCIDENT_TYPES]: (state: State): State => ({
      ...state,
      fetchIncidentTypesLoading: true,
      fetchIncidentTypesLoaded: false,
    }),

    [FETCH_INCIDENT_TYPES_SUCCESS]: (
      state: State,
      action: Action<IncidentTypesResponse>
    ): State => {
      return {
        ...state,
        fetchIncidentTypesLoading: false,
        fetchIncidentTypesLoaded: true,
        incidentTypes: action.payload.result.incident_types,
      };
    },

    [FETCH_INCIDENT_TYPES_FAIL]: (state: State, action): State => ({
      ...state,
      fetchIncidentTypesLoading: false,
      fetchIncidentTypesLoaded: false,
      fetchIncidentTypesError: action.payload,
    }),

    [FETCH_USER_STATUS]: (state: State): State => ({
      ...state,
      fetchUserStatusLoading: true,
      fetchUserStatusLoaded: false,
    }),

    [FETCH_USER_STATUS_SUCCESS]: (
      state: State,
      action: ActionMeta<UserStatusResponse, { uuid: string }>
    ): State => {
      return {
        ...state,
        fetchUserStatusLoading: false,
        fetchUserStatusLoaded: true,
        byUuid: {
          ...state.byUuid,
          [action.meta.uuid]: action.payload.result.ban,
        },
      };
    },

    [FETCH_USER_STATUS_FAIL]: (state: State, action): State => ({
      ...state,
      fetchUserStatusLoading: false,
      fetchUserStatusLoaded: false,
      fetchUserStatusError: action.payload,
    }),

    [CREATE_BAN]: (state: State): State => ({
      ...state,
      createBanLoading: true,
      createBanLoaded: false,
    }),

    [CREATE_BAN_SUCCESS]: (
      state: State,
      action: Action<CreateBanSingleResponse | CreateBanBulkResponse>
    ): State => {
      const newData: Hash<Ban> = {};

      if ('bans' in action.payload.result) {
        action.payload.result.bans.forEach((ban): void => {
          newData[ban.userUuid] = ban;
        });
      } else {
        newData[action.payload.result.ban.userUuid] = action.payload.result.ban;
      }

      return {
        ...state,
        createBanLoading: false,
        createBanLoaded: true,
        byUuid: {
          ...state.byUuid,
          ...newData,
        },
      };
    },

    [CREATE_BAN_FAIL]: (state: State, action): State => ({
      ...state,
      createBanLoading: false,
      createBanLoaded: false,
      createBanError: action.payload,
    }),

    [GET_IMPERSONATION_HISTORY]: (state: State): State => ({
      ...state,
      impersonationHistory: [],
      impersonationHistoryLoading: true,
    }),

    [GET_IMPERSONATION_HISTORY_SUCCESS]: (state: State, action): State => ({
      ...state,
      impersonationHistory: action.payload.result,
      impersonationHistoryLoading: false,
    }),

    [GET_IMPERSONATION_HISTORY_FAIL]: (state: State): State => ({
      ...state,
      impersonationHistory: [],
      impersonationHistoryLoading: false,
      impersonationHistoryFail: true,
    }),

    [IMPERSONATE_MEMBER]: (state: State): State => ({
      ...state,
      impersonatingMember: true,
    }),

    [IMPERSONATE_MEMBER_SUCCESS]: (state: State): State => ({
      ...state,
      impersonatingMember: false,
      impersonatingMemberSuccess: true,
    }),

    [IMPERSONATE_MEMBER_FAIL]: (state: State): State => ({
      ...state,
      impersonatingMember: false,
      impersonatingMemberSuccess: false,
      impersonatingMemberFail: true,
    }),

    [ANONYMIZE_USER]: (state: State): State => ({
      ...state,
      anonymizingUser: true,
    }),

    [ANONYMIZE_USER_SUCCESS]: (state: State): State => ({
      ...state,
      anonymizingUser: false,
      anonymizingUserSuccess: true,
    }),

    [ANONYMIZE_USER_FAIL]: (state: State): State => ({
      ...state,
      anonymizingUser: false,
      anonymizingUserSuccess: false,
      anonymizingUserFail: true,
    }),

    [GET_USER_AUDIT_HISTORY]: (state: State): State => ({
      ...state,
      fetchingUserAuditHistory: true,
    }),

    [GET_USER_AUDIT_HISTORY_SUCCESS]: (state: State, action): State => ({
      ...state,
      auditHistoryPages: {
        currentPage: action.payload.meta.current_page,
        totalPages: action.payload.meta.total_pages,
      },
      userAuditHistory: action.payload.result,
      fetchingUserAuditHistory: false,
      fetchingUserAuditHistorySuccess: true,
    }),

    [GET_USER_AUDIT_HISTORY_FAIL]: (state: State): State => ({
      ...state,
      auditHistoryPages: {},
      userAuditHistory: [],
      fetchingUserAuditHistory: false,
      fetchingUserAuditHistorySuccess: false,
      fetchingUserAuditHistoryFail: true,
    }),

    [GET_LOCALE_CODES]: (state: State): State => ({
      ...state,
      fetchingLocaleCodes: true,
    }),

    [GET_LOCALE_CODES_SUCCESS]: (state: State, action): State => ({
      ...state,
      localeCodes: action.payload.result,
      fetchingLocaleCodes: false,
      fetchingLocaleCodesSuccess: true,
    }),

    [GET_LOCALE_CODES_FAIL]: (state: State): State => ({
      ...state,
      fetchingLocaleCodes: false,
      fetchingLocaleCodesSuccess: false,
      fetchingLocaleCodesFail: true,
    }),

    [GET_USER_KIND_TYPES]: (state: State): State => ({
      ...state,
      fetchingUserKindTypes: true,
    }),

    [GET_USER_KIND_TYPES_SUCCESS]: (state: State, action): State => ({
      ...state,
      userKindTypes: action.payload.result,
      fetchingUserKindTypes: false,
      fetchingUserKindTypesSuccess: true,
    }),

    [GET_USER_KIND_TYPES_FAIL]: (state: State): State => ({
      ...state,
      fetchingUserKindTypes: false,
      fetchingUserKindTypesSuccess: false,
      fetchingUserKindTypesFail: true,
    }),

    [SYNC_USER_TO_MENA]: (state: State): State => ({
      ...state,
      syncingUserToMena: true,
    }),

    [SYNC_USER_TO_MENA_SUCCESS]: (state: State): State => ({
      ...state,
      syncingUserToMena: false,
      syncingUserToMenaSuccess: true,
    }),

    [SYNC_USER_TO_MENA_FAIL]: (state: State): State => ({
      ...state,
      syncingUserToMena: false,
      syncingUserToMenaSuccess: false,
      syncingUserToMenaFail: true,
    }),

    [GET_ACCOUNT_AUDIT_LOG]: (state: State): State => ({
      ...state,
      fetchingAccountAuditLog: true,
    }),

    [GET_ACCOUNT_AUDIT_LOG_SUCCESS]: (state: State, action): State => ({
      ...state,
      accountAccessTotalPages: action.payload.result.total_pages,
      accountAccessLog: action.payload.result.log_entries,
      fetchingAccountAuditLog: false,
      fetchingAccountAuditLogSuccess: true,
    }),

    [GET_ACCOUNT_AUDIT_LOG_FAIL]: (state: State): State => ({
      ...state,
      accountAccessLog: [],
      accountAccessTotalPages: null,
      fetchingAccountAuditLog: false,
      fetchingAccountAuditLogSuccess: false,
      fetchingAccountAuditLogFail: true,
    }),
  },
  initialState
);

export default reducer;
