export enum AnalyticsEventName {
  about_document_clicked = 'document_clicked',
  about_document_upload_submit = 'document_upload_submit',
  about_view_allamenities_clicked = 'view_allamenities_clicked',
  about_avcontrols_clicked = 'about_avcontrols_clicked',
  checkins_add_note_submitted = 'checkins_add_note_submitted',
  checkins_assignment_selected = 'checkins_assignment_selected',
  checkins_change_status_clicked = 'checkins_change_status_clicked',
  checkins_checklist_clicked = 'checkins_checklist_clicked',
  checkins_company_profile_clicked = 'checkins_company_profile_clicked',
  checkins_email_copy_clicked = 'checkins_email_copy_clicked',
  checkins_medallia_details_clicked = 'checkins_medallia_details_clicked',
  checkins_medallia_view_in_clicked = 'checkins_medallia_view_in_clicked',
  checkins_medallia_view_more_clicked = 'checkins_medallia_view_more_clicked',
  checkins_my_checkins_selected = 'checkins_my_checkins_selected',
  checkins_past_checkin_clicked = 'checkins_past_checkin_clicked',
  checkins_section_clicked = 'checkins_section_clicked',
  checkins_table_sort = 'checkins_table_sort',
  checkins_task_clicked = 'checkins_task_clicked',
  checkins_vital_stats_clicked = 'checkins_vital_stats_clicked',
  comms_search_submitted = 'comms_search_submitted',
  comms_filter_selected = 'comms_filter_selected',
  comms_edit_templates = 'comms_edit_templates',
  comms_create_announcement = 'comms_create_announcement',
  comms_annon_template_view = 'comms_annon_template_view',
  comms_send_announcement = 'comms_send_announcement',
  comms_notif_template_variable = 'comms_notif_template_variable',
  comms_notif_template_test = 'comms_notif_template_test',
  comms_notif_template_save = 'comms_notif_template_save',
  comms_send_notification_clicked = 'comms_send_notification_clicked',
  comms_send_notification_success = 'comms_send_notification_success',
  company_menu_item_clicked = 'company_menu_item_clicked',
  company_note_added = 'company_note_added',
  company_note_clicked = 'company_note_clicked',
  company_note_deleted = 'company_note_deleted',
  company_notes_filter_selected = 'company_notes_filter_selected',
  invite_member_clicked = 'invite_member_clicked',
  invite_member_submitted = 'invite_member_submitted',
  invite_member_success = 'invite_member_success',
  locationchange_modal_clicked = 'locationchange_modal_clicked',
  member_filter_selected = 'member_filter_selected',
  member_view_toggle = 'member_view_toggle',
  mimo_move_card_edited = 'mimo_move_card_edited',
  mimo_move_details_clicked = 'mimo_move_details_clicked',
  mimo_exported = 'mimo_exported',
  mimo_email_modal_clicked = 'mimo_email_modal_clicked',
  mimo_email_sent = 'mimo_email_sent',
  mimo_filter_selected = 'mimo_filter_selected',
  mimo_move_note_saved = 'mimo_move_note_saved',
  navigation_clicked = 'navigation_clicked',
  notifications_bell_toggled = 'notifications_bell_toggled',
  notifications_card_clicked = 'notifications_card_clicked',
  onboarding_task_marked = 'onboarding_task_marked',
  onboarding_task_unmarked = 'onboarding_task_unmarked',
  rooms_book_room_clicked = 'rooms_book_room_clicked',
  rooms_book_room_submitted = 'rooms_book_room_submitted',
  rooms_book_room_success = 'rooms_book_room_success',
  rooms_cancel_booking_clicked = 'rooms_cancel_booking_clicked',
  rooms_cancel_booking_submitted = 'rooms_cancel_booking_submitted',
  rooms_cancel_booking_success = 'rooms_cancel_booking_success',
  rooms_refund_booking_clicked = 'rooms_refund_booking_clicked',
  rooms_refund_booking_submitted = 'rooms_refund_booking_submitted',
  rooms_refund_booking_success = 'rooms_refund_booking_success',
  rooms_edit_booking_clicked = 'rooms_edit_booking_clicked',
  rooms_edit_booking_submitted = 'rooms_edit_booking_submitted',
  rooms_edit_booking_success = 'rooms_edit_booking_success',
  rooms_filter_selected = 'rooms_filter_selected',
  rooms_today_selected = 'rooms_today_selected',
  search_results_returned = 'search_results_returned',
  search_tab = 'search_tab',
  subtab_navigation_clicked = 'subtab_navigation_clicked',
  timing = 'timing',
  upload_type_toggle = 'upload_type_toggle',
  view_member = 'View Member',
  visitors_actions_item_clicked = 'visitors_actions_item_clicked',
  visitors_actions_menu_clicked = 'visitors_actions_menu_clicked',
  visitors_book_desk_clicked = 'visitors_book_desk_clicked',
  visitors_book_desk_submitted = 'visitors_book_desk_submitted',
  visitors_book_desk_success = 'visitors_book_desk_success',
  visitors_calnav_clicked = 'visitors_calnav_clicked',
  visitors_filter = 'visitors_filter',
  visitors_visitorcard_action = 'visitors_visitorcard_action',
  visitors_visitorcard_clicked = 'visitors_visitorcard_clicked',
  wayf_bestpractices_clicked = 'wayf_bestpractices_clicked',
  wayf_entrance_field_edit_submitted = 'wayf_entrance_field_edit_submitted',
  wayf_field_edit_clicked = 'wayf_field_edit_clicked',
  wayf_field_edit_submitted = 'wayf_field_edit_submitted',
  wayf_section_clicked = 'wayf_section_clicked',

  /**
   * legacy
   */
  legacy_interaction = 'Interaction',

  /**
   * deprecated?
   */
  // error_occurred = 'error_occurred',
}

export enum AnalyticsWorkflow {
  ABOUT_WORKFLOW = 'About',
  CHECKINS_WORKFLOW = 'Check-ins',
  COMMUNICATIONS_WORKFLOW = 'Communications',
  COMPANIES_WORKFLOW = 'Companies',
  GLOBAL_SEARCH_WORKFLOW = 'Global Search',
  MIMO_WORKFLOW = 'MIMO Tracker',
  NOTIFICATIONS_WORKFLOW = 'Notifications',
  ROOMS_WORKFLOW = 'Rooms',
  VISITORS_WORKFLOW = 'Visitors',
}
