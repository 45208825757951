import { formatISO, addYears } from 'date-fns';

import { DiscountInputAttrs } from 'features/paperwork/contracts-flow/types';
import { getProductItemWithIndices } from 'features/paperwork/contracts-flow/sections/product/utils';
import { DiscountFieldOption } from 'features/paperwork/discounts/advanced';
import { getChargePrice } from 'lib/discountModalUtil';

import { ADD_PRODUCT_DISCOUNT, EDIT_PRODUCT_DISCOUNT, REMOVE_PRODUCT_DISCOUNT } from './consts';
import { validateDiscounts } from './validators';
import { getMaxSuggestedDiscount } from './selectors';

import { State } from '.';

const toAmount = (percentage: number, reservationPrice: number) =>
  (reservationPrice * percentage) / 100;

const defaultTerm = {
  startDate: { value: formatISO(Date.now()) },
  endDate: { value: formatISO(addYears(Date.now(), 100)) }, // 100 term lenght is temporary until we define how to treat out of term discounts while editing
  length: {},
};

const reducer = {
  [ADD_PRODUCT_DISCOUNT]: (
    state: State,
    action: {
      payload: {
        id: string;
        discountKey: string;
        attrs: DiscountInputAttrs;
        shouldAddForAllReservations: boolean;
      };
    }
  ) => {
    const { idx, discountIdx, productItem } = getProductItemWithIndices(
      state,
      action.payload.id,
      action.payload.discountKey
    );
    const term =
      productItem.terms?.find(
        ({ id }) => id === (productItem.discounts[discountIdx] || action.payload.attrs).termId
      ) || defaultTerm;

    let updatedItems;
    if (state.singleAgreement || action.payload.shouldAddForAllReservations) {
      updatedItems = state.items.map(item => ({
        ...item,
        discounts: validateDiscounts(
          [
            ...item.discounts,
            {
              ...action.payload.attrs,
              key: action.payload.discountKey,
              errors: {},
            },
          ],
          action.payload.discountKey,
          term,
          item.productReservation.chargePrice,
          getMaxSuggestedDiscount(
            state,
            item.productReservation.uuid || item.productReservation.id,
            term.length.value
          )
        ),
      }));
    } else {
      updatedItems = [
        ...state.items.slice(0, idx),
        {
          ...productItem,
          discounts: validateDiscounts(
            [
              ...productItem.discounts,
              {
                key: action.payload.discountKey,
                ...productItem.discounts[discountIdx],
                ...action.payload.attrs,
              },
            ],
            action.payload.discountKey,
            term,
            productItem.productReservation.chargePrice,
            getMaxSuggestedDiscount(
              state,
              productItem.productReservation.uuid || productItem.productReservation.id,
              term.length.value
            )
          ),
        },
        ...state.items.slice(idx + 1),
      ];
    }
    return {
      ...state,
      items: updatedItems,
    };
  },
  [EDIT_PRODUCT_DISCOUNT]: (
    state: State,
    action: { payload: { id: string; discountKey: number; attrs: DiscountFieldOption } }
  ) => {
    const { idx, discountIdx, productItem } = getProductItemWithIndices(
      state,
      action.payload.id,
      action.payload.discountKey
    );
    const term =
      productItem.terms?.find(
        ({ id }) => id === (productItem.discounts[discountIdx] || action.payload.attrs).termId
      ) || defaultTerm;

    let updatedItems;
    if (state.singleAgreement) {
      const totalRenewalPrice = state.items.reduce(
        (prevValue, item) =>
          prevValue +
          getChargePrice(item.productReservation.marketPrice, item.productReservation.price),
        0
      );
      updatedItems = state.items.map(item => {
        const reservationRenewalPrice = getChargePrice(
          item.productReservation.marketPrice,
          item.productReservation.price
        );
        let discountPayload;
        if (action.payload.attrs.amount) {
          const discountAmount =
            action.payload.attrs.amount.unit === '%'
              ? toAmount(action.payload.attrs.amount.amount, reservationRenewalPrice)
              : action.payload.attrs.amount.amount;

          if (
            action.payload.attrs.amount.unit !== '%' &&
            state.usingPromotion &&
            state.items.length > 1
          ) {
            const ratio = reservationRenewalPrice / totalRenewalPrice;
            discountPayload = {
              ...action.payload.attrs,
              amount: Math.round(discountAmount * ratio * 100) / 100,
            };
          } else {
            discountPayload = {
              ...action.payload.attrs,
              amount: discountAmount,
            };
          }
        } else {
          discountPayload = {
            ...action.payload.attrs,
          };
        }

        return {
          ...item,
          discounts: validateDiscounts(
            [
              ...item.discounts.slice(0, discountIdx),
              {
                ...item.discounts[discountIdx],
                ...discountPayload,
              },
              ...item.discounts.slice(discountIdx + 1),
            ],
            action.payload.discountKey,
            term,
            item.productReservation.chargePrice,
            getMaxSuggestedDiscount(
              state,
              item.productReservation.uuid || item.productReservation.id,
              term.length.value
            )
          ),
        };
      });
    } else {
      let discountPayload;
      if (action.payload.attrs.amount) {
        const discountAmount =
          action.payload.attrs.amount.unit === '%'
            ? toAmount(
                action.payload.attrs.amount.amount,
                productItem.productReservation.chargePrice
              )
            : action.payload.attrs.amount.amount;
        discountPayload = {
          ...action.payload.attrs,
          amount: discountAmount,
        };
      } else {
        discountPayload = {
          ...action.payload.attrs,
        };
      }
      updatedItems = [
        ...state.items.slice(0, idx),
        {
          ...productItem,
          discounts: validateDiscounts(
            [
              ...productItem.discounts.slice(0, discountIdx),
              {
                key: action.payload.discountKey,
                ...productItem.discounts[discountIdx],
                ...discountPayload,
              },
              ...productItem.discounts.slice(discountIdx + 1),
            ],
            action.payload.discountKey,
            term,
            productItem.productReservation.chargePrice,
            getMaxSuggestedDiscount(
              state,
              productItem.productReservation.uuid || productItem.productReservation.id,
              term.length.value
            )
          ),
        },
        ...state.items.slice(idx + 1),
      ];
    }
    return {
      ...state,
      items: updatedItems,
    };
  },
  [REMOVE_PRODUCT_DISCOUNT]: (
    state: State,
    action: { payload: { id: string; discountKey: number } }
  ) => {
    const { idx, discountIdx, productItem } = getProductItemWithIndices(
      state,
      action.payload.id,
      action.payload.discountKey
    );

    const term =
      productItem.terms?.find(({ id }) => id === productItem.discounts[discountIdx].termId) ||
      defaultTerm;

    let updatedItems;
    if (state.singleAgreement) {
      updatedItems = state.items.map(item => ({
        ...item,
        discounts: validateDiscounts(
          [...item.discounts.slice(0, discountIdx), ...item.discounts.slice(discountIdx + 1)],
          action.payload.discountKey,
          term,
          item.productReservation.chargePrice,
          getMaxSuggestedDiscount(
            state,
            item.productReservation.uuid || item.productReservation.id,
            term.length.value
          )
        ),
      }));
    } else {
      updatedItems = [
        ...state.items.slice(0, idx),
        {
          ...productItem,
          discounts: validateDiscounts(
            [
              ...productItem.discounts.slice(0, discountIdx),
              ...productItem.discounts.slice(discountIdx + 1),
            ],
            action.payload.discountKey,
            term,
            productItem.productReservation.chargePrice,
            getMaxSuggestedDiscount(
              state,
              productItem.productReservation.uuid || productItem.productReservation.id,
              term.length.value
            )
          ),
        },
        ...state.items.slice(idx + 1),
      ];
    }

    return {
      ...state,
      items: updatedItems,
    };
  },
};

export default reducer;
