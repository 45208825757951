import { handleActions } from 'redux-actions';

import {
  FETCH_MLS_UUID,
  FETCH_MLS_UUID_FAILURE,
  FETCH_MLS_UUID_SUCCESS,
} from 'features/building/ducks/mlsUuidConsts';
import { ActionWithPayload } from 'store/types';

const intialState: string = '';

const reducer = handleActions<string, string>(
  {
    [FETCH_MLS_UUID]: (state: string): string => state,
    [FETCH_MLS_UUID_SUCCESS]: (state: string, action: ActionWithPayload<string>): string =>
      action.payload || state,
    [FETCH_MLS_UUID_FAILURE]: (): string => '',
  },
  intialState
);

export default reducer;
