import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import { Icon, IconName, IconSize } from '@wework/ray2';

export interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
  label?: string;
  border?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode | string;
  role?: string;
  ariaSelected?: boolean;
  ariaControls?: string;
  tabindex?: number | string;
  icon?: boolean;
  vertical?: boolean;
}

const Tab = ({
  label,
  active,
  disabled,
  className,
  children,
  onClick,
  role,
  ariaSelected,
  ariaControls,
  tabIndex,
  icon,
  border = true,
  type = 'button',
  vertical,
}: TabProps): JSX.Element => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      role={role}
      aria-selected={ariaSelected}
      aria-controls={ariaControls}
      tabIndex={tabIndex}
      className={cn([
        'cursor-pointer w-full group focus-visible:outline-none',
        active && !vertical && 'text-primary border-primary border-b-2 pb-2xs',
        active && vertical && 'font-bold border-black border-l-2 pl-2xs py-2xs',
        !active && !vertical && 'text-gray-40 border-b border-gray-70 pb-[9px]',
        !active && vertical && 'text-gray-40 border-l border-gray-70 pl-[9px] pt-2xs pb-2xs',
        !active && disabled && 'text-gray-70',
        !border && 'border-none pb-0',
        !active &&
          !disabled &&
          border &&
          !vertical && [
            'hover:border-b-2 hover:pb-2xs hover:border-gray-60',
            'active:border-gray-50',
          ],
        !active &&
          !disabled &&
          border &&
          vertical && [
            'hover:border-l-2 hover:pl-2xs hover:border-gray-60',
            'active:border-gray-50',
          ],
        className,
      ])}
      onClick={onClick}
    >
      <div
        className={cn([
          !vertical && 'w-full flex justify-center items-center gap-2',
          vertical && 'w-full flex justify-left items-center gap-2',
          'border-none rounded group-focus-visible:shadow-[0_0_0_2px_rgba(102,102,255,1)]',
        ])}
      >
        {icon && <Icon icon={IconName.AIR} size={IconSize.SMALL} />}
        <p className="font-main select-none text-2xs">{label || children}</p>
      </div>
    </button>
  );
};

export default Tab;
