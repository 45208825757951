import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_EMAIL_TEMPLATE_TYPES,
  FETCH_EMAIL_TEMPLATE_TYPES_SUCCESS,
  FETCH_EMAIL_TEMPLATE_TYPES_FAIL,
] = createRequestConstantNames(cc('FETCH_EMAIL_TEMPLATE_TYPES'));

export const SELECT_EMAIL_TEMPLATE_TYPE = cc('SELECT_EMAIL_TEMPLATE_TYPE');
