import { Action, handleActions } from 'redux-actions';

import {
  FETCH_ACCESS_LEVELS_GROUPS,
  FETCH_ACCESS_LEVELS_SUCCESS_GROUPS,
  FETCH_ACCESS_LEVELS_FAIL_GROUPS,
  CREATE_ACCESS_LEVEL_GROUPS,
  CREATE_ACCESS_LEVEL_SUCCESS_GROUPS,
  CREATE_ACCESS_LEVEL_FAIL_GROUPS,
  UPDATE_ACCESS_LEVEL_GROUPS,
  UPDATE_ACCESS_LEVEL_SUCCESS_GROUPS,
  UPDATE_ACCESS_LEVEL_FAIL_GROUPS,
  DELETE_ACCESS_LEVEL_GROUPS,
  DELETE_ACCESS_LEVEL_SUCCESS_GROUPS,
  DELETE_ACCESS_LEVEL_FAIL_GROUPS,
  SEARCH_ACCESS_LEVEL_GROUPS,
  SEARCH_ACCESS_LEVEL_SUCCESS_GROUPS,
  SEARCH_ACCESS_LEVEL_FAIL_GROUPS,
  FETCH_SELECTED_ACCESS_LEVELS_GROUPS,
  FETCH_SELECTED_ACCESS_LEVELS_SUCCESS_GROUPS,
  FETCH_SELECTED_ACCESS_LEVELS_FAIL_GROUPS,
  FETCH_ACCESS_PARTITION_GROUPS,
  FETCH_ACCESS_PARTITION_SUCCESS_GROUPS,
  FETCH_ACCESS_PARTITION_FAIL_GROUPS,
  FETCH_ACCESS_SERVERS_AND_PARTITIONS_GROUPS,
  FETCH_ACCESS_SERVERS_AND_PARTITIONS_SUCCESS_GROUPS,
  FETCH_ACCESS_SERVERS_AND_PARTITIONS_FAIL_GROUPS,
} from 'features/accessLevelGroups/redux/constants';
import { Partition, State } from 'features/accessLevelGroups/redux/types';

const initialState: State = {
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  error: null,
  accessLevelsGroupLoading: false,
  accessLevelsGroupLoaded: false,
  pageSize: 25,
  accessLevelGroupsList: [],
  selectedLevelGroups: [],
  partition: {},
  partitionsByServerId: {},
  servers: [],
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_ACCESS_LEVELS_GROUPS]: state => ({
      ...state,
      accessLevelsGroupLoading: true,
      accessLevelsGroupLoaded: false,
    }),

    [FETCH_ACCESS_LEVELS_SUCCESS_GROUPS]: (state, action) => ({
      ...state,
      accessLevelsGroupLoading: false,
      accessLevelsGroupLoaded: true,
      accessLevelGroupsList: action.payload.result,
      error: null,
    }),

    [FETCH_ACCESS_LEVELS_FAIL_GROUPS]: (state, action) => ({
      ...state,
      accessLevelsGroupLoading: false,
      accessLevelsGroupLoaded: false,
      accessLevelGroupsList: [],
      error: action.payload.message,
    }),

    [CREATE_ACCESS_LEVEL_GROUPS]: state => ({
      ...state,
      creating: true,
      created: false,
    }),

    [CREATE_ACCESS_LEVEL_SUCCESS_GROUPS]: state => ({
      ...state,
      creating: false,
      created: true,
      error: null,
    }),

    [CREATE_ACCESS_LEVEL_FAIL_GROUPS]: (state, action) => ({
      ...state,
      creating: false,
      created: false,
      error: action.payload.message,
    }),

    [UPDATE_ACCESS_LEVEL_GROUPS]: state => ({
      ...state,
      updating: true,
      updated: false,
    }),

    [UPDATE_ACCESS_LEVEL_SUCCESS_GROUPS]: state => ({
      ...state,
      updating: false,
      updated: true,
      error: null,
    }),

    [UPDATE_ACCESS_LEVEL_FAIL_GROUPS]: (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.payload.message,
    }),

    [DELETE_ACCESS_LEVEL_GROUPS]: state => ({
      ...state,
      deleting: true,
      deleted: false,
    }),

    [DELETE_ACCESS_LEVEL_SUCCESS_GROUPS]: state => ({
      ...state,
      deleting: false,
      deleted: true,
      error: null,
    }),

    [DELETE_ACCESS_LEVEL_FAIL_GROUPS]: (state, action) => ({
      ...state,
      deleting: false,
      deleted: false,
      error: action.payload.message,
    }),

    [SEARCH_ACCESS_LEVEL_GROUPS]: state => ({
      ...state,
      accessLevelsGroupLoading: true,
      accessLevelsGroupLoaded: false,
    }),

    [SEARCH_ACCESS_LEVEL_SUCCESS_GROUPS]: (state, action) => ({
      ...state,
      accessLevelsGroupLoading: false,
      accessLevelsGroupLoaded: true,
      accessLevelGroupsList: action.payload.result,
      error: null,
    }),

    [SEARCH_ACCESS_LEVEL_FAIL_GROUPS]: (state, action) => ({
      ...state,
      accessLevelsGroupLoading: false,
      accessLevelsGroupLoaded: false,
      accessLevelGroupsList: [],
      error: action.payload.message,
    }),

    [FETCH_SELECTED_ACCESS_LEVELS_GROUPS]: state => ({
      ...state,
      accessLevelsGroupLoading: true,
      accessLevelsGroupLoaded: false,
    }),

    [FETCH_SELECTED_ACCESS_LEVELS_SUCCESS_GROUPS]: (state, action) => ({
      ...state,
      accessLevelsGroupLoading: false,
      accessLevelsGroupLoaded: true,
      selectedLevelGroups: action.payload.result,
      error: null,
    }),

    [FETCH_SELECTED_ACCESS_LEVELS_FAIL_GROUPS]: (state, action) => ({
      ...state,
      accessLevelsGroupLoading: false,
      accessLevelsGroupLoaded: false,
      selectedLevelGroups: [],
      error: action.payload.message,
    }),

    [FETCH_ACCESS_PARTITION_GROUPS]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),

    [FETCH_ACCESS_PARTITION_SUCCESS_GROUPS]: (
      state: State,
      action: Action<{ result: Partition }>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      partition: action.payload.result,
      error: null,
    }),

    [FETCH_ACCESS_PARTITION_FAIL_GROUPS]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      partition: {},
      error: action.payload.message,
    }),

    [FETCH_ACCESS_SERVERS_AND_PARTITIONS_GROUPS]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),

    [FETCH_ACCESS_SERVERS_AND_PARTITIONS_SUCCESS_GROUPS]: (
      state: State,
      action: Action<{
        result: Array<{
          id: string;
          partitions: Partition[];
        }>;
      }>
    ) => {
      const servers = action.payload.result;

      return {
        ...state,
        loading: false,
        loaded: true,
        partitionsByServerId: servers.reduce((accumulator, server) => {
          accumulator[server.id] = server.partitions;

          return accumulator;
        }, {}),
        servers,
        error: null,
      };
    },

    [FETCH_ACCESS_SERVERS_AND_PARTITIONS_FAIL_GROUPS]: (
      state: State,
      action: Action<{ message: string }>
    ) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
  },
  initialState
);

export default reducer;
