import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { pick } from 'lodash';
import { Helmet } from 'react-helmet';
import PlasmaPage from '@wework-dev/plasma/lib/components/layout/Page/Page';
import { compose } from 'redux';

import { getCurrentLocation } from 'store/selectors';
import { deprecated_isPresent } from 'lib/util';
import { GlobalState } from 'store/modules';
import { BaseAction, Dispatch } from 'store/types';
import { pageView, Payload } from 'store/modules/page';

type OwnProps = {
  actions?: Array<{}>;
  breadcrumb?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  // name: for tracking page views, default for the <title> tag too,
  name: string;
  // title: for displaying a title in Plasma's <Page /> component
  title?: string;
  noPadding?: boolean;
  data?: Hash<string>;
};

const mapStateToProps = (
  state: GlobalState,
  props: OwnProps & RouteComponentProps<{ code?: string }>
) => ({
  currentLocation: getCurrentLocation(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  pageView: (payload: Payload) => dispatch(pageView(payload)),
});

type Props = Readonly<RouteComponentProps<{ uuid?: string }> & OwnProps & PropsFromRedux>;

class Page extends React.Component<Props> {
  componentDidMount() {
    const { currentLocation, location, match, name, title, pageView } = this.props;

    if (deprecated_isPresent(name)) {
      pageView({
        name,
        title,
        path: location.pathname,
        metadata: {
          params: {
            uuid: match.params.uuid ?? '',
          },
          currentLocation: pick(currentLocation || {}, ['uuid', 'name']),
        },
      });
    }
  }

  render() {
    const { breadcrumb, children, className, name, title, noPadding, actions, data } = this.props;

    const content = (
      <div className={className}>
        <Helmet>
          <title>{`${name} | Spacestation`}</title>
        </Helmet>
        {children}
      </div>
    );

    if (noPadding) {
      return content;
    }

    return (
      <PlasmaPage title={title} actions={actions} breadcrumb={breadcrumb} data={data}>
        {content}
      </PlasmaPage>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose<React.ComponentType<OwnProps>>(connector, withRouter)(Page);
