import { Transportation, TransportationPayload } from 'features/building/types';

export const getTransportationByID = (
  transport: TransportationPayload | undefined
): { [key: string]: Transportation } => {
  if (!transport) return {};

  return transport.items.reduce((result, transportation) => {
    const asset = transportation.fields.icon.sys.id
      ? transport.includes.Asset?.find(ass => ass.sys.id === transportation.fields.icon.sys.id)
      : null;
    result[transportation.fields.transportationType] = {
      name: transportation.fields.transportationType,
      iconUrl: asset ? `https:${asset.fields.file.url}` : null,
    };

    return result;
  }, {});
};
