import { handleActions } from 'redux-actions';
import { Action } from 'redux';

import config from 'config';
import cc from 'store/util/createReduxConstant';
import { createRequestAction } from 'store/util';
import { createRequestConstantNames } from 'store/util/createConstants';
import PaperworkClient, {
  PaperworkValidateParams,
  PaperworkCreateParams,
  UpdateMembershipSignedAgreementParams,
  UploadAgreementParams,
} from 'features/paperwork/lib/paperworkClient';
import {
  Dispatch,
  GetGlobalState,
  BaseAction,
  ActionWithPayload,
  ActionWithMeta,
} from 'store/types';

export const CLEAR_MEMBERSHIP_AGREEMENTS = cc('CLEAR_MEMBERSHIP_AGREEMENTS');

export const [
  VALIDATE_MEMBERSHIP_AGREEMENT,
  VALIDATE_MEMBERSHIP_AGREEMENT_SUCCESS,
  VALIDATE_MEMBERSHIP_AGREEMENT_FAIL,
] = createRequestConstantNames(cc('VALIDATE_MEMBERSHIP_AGREEMENT'));

export const [
  VALIDATE_OPPORTUNITY,
  VALIDATE_OPPORTUNITY_SUCCESS,
  VALIDATE_OPPORTUNITY_FAIL,
] = createRequestConstantNames(cc('VALIDATE_OPPORTUNITY'));

export const [
  VALIDATE_SD_EXEMPTION,
  VALIDATE_SD_EXEMPTION_SUCCESS,
  VALIDATE_SD_EXEMPTION_FAIL,
] = createRequestConstantNames(cc('VALIDATE_SD_EXEMPTION'));

export const [
  UPLOAD_MEMBERSHIP_AGREEMENT,
  UPLOAD_MEMBERSHIP_AGREEMENT_SUCCESS,
  UPLOAD_MEMBERSHIP_AGREEMENT_FAIL,
] = createRequestConstantNames(cc('UPLOAD_MEMBERSHIP_AGREEMENT'));

export const [
  UPDATE_MEMBERSHIP_SIGNED_AGREEMENT_AND_MOVE_IN,
  UPDATE_MEMBERSHIP_SIGNED_AGREEMENT_AND_MOVE_IN_SUCCESS,
  UPDATE_MEMBERSHIP_SIGNED_AGREEMENT_AND_MOVE_IN_FAIL,
] = createRequestConstantNames(cc('UPDATE_MEMBERSHIP_SIGNED_AGREEMENT_AND_MOVE_IN'));

export const [
  CREATE_MEMBERSHIP_AGREEMENT,
  CREATE_MEMBERSHIP_AGREEMENT_SUCCESS,
  CREATE_MEMBERSHIP_AGREEMENT_FAIL,
] = createRequestConstantNames(cc('CREATE_MEMBERSHIP_AGREEMENT'));

export const [
  DISCARD_CONTRACT,
  DISCARD_CONTRACT_SUCCESS,
  DISCARD_CONTRACT_FAIL,
] = createRequestConstantNames(cc('DISCARD_CONTRACT'));

export const [
  RESEND_CONTRACT,
  RESEND_CONTRACT_SUCCESS,
  RESEND_CONTRACT_FAIL,
] = createRequestConstantNames(cc('RESEND_CONTRACT'));

export interface Contract {
  code?: string;
}

export interface State {
  submitting: boolean;
}

const initialState: State = { submitting: false };

export const reducer = handleActions<State, any>(
  {
    [CLEAR_MEMBERSHIP_AGREEMENTS]: (): State => initialState,

    [CREATE_MEMBERSHIP_AGREEMENT]: (state: State): State => ({
      ...state,
      submitting: true,
    }),

    [CREATE_MEMBERSHIP_AGREEMENT_SUCCESS]: (state: State): State => ({
      ...state,
      submitting: false,
    }),

    [CREATE_MEMBERSHIP_AGREEMENT_FAIL]: (state: State): State => ({
      ...state,
      submitting: false,
    }),
  },
  initialState
);

export const clearMembershipAgreements = () => ({ type: CLEAR_MEMBERSHIP_AGREEMENTS });

export const validateOpportunity = (sfOpportunityId: string) => async (
  dispatch: Dispatch<ActionWithPayload<string>>,
  getState: GetGlobalState
) => {
  dispatch({ type: VALIDATE_OPPORTUNITY, payload: sfOpportunityId });

  return new PaperworkClient(getState)
    .validateOpportunity(sfOpportunityId)
    .then(result => {
      dispatch({ type: VALIDATE_OPPORTUNITY_SUCCESS, payload: (result as unknown) as string });
      return result;
    })
    .catch(error => {
      dispatch({ type: VALIDATE_OPPORTUNITY_FAIL, payload: error });
      throw error;
    });
};

export const validateSecurityDepositExemptionForReservation = (
  reservationUuid: string,
  locationUuid: string
) => async (
  dispatch: Dispatch<
    BaseAction | ActionWithPayload<{ reservationUuid: string; locationUuid: string }>
  >,
  getState: GetGlobalState
) => {
  dispatch({ type: VALIDATE_SD_EXEMPTION, payload: { reservationUuid, locationUuid } });

  return new PaperworkClient(getState)
    .validateSecurityDepositExemptionForReservation(reservationUuid, locationUuid)
    .then(result => {
      dispatch({ type: VALIDATE_SD_EXEMPTION_SUCCESS });
      return result;
    })
    .catch(error => {
      dispatch({ type: VALIDATE_SD_EXEMPTION_FAIL });
      throw error;
    });
};

export const validateMembershipAgreement = (opts: PaperworkValidateParams) => (
  dispatch: Dispatch<ActionWithPayload<any>>,
  getState: GetGlobalState
) => {
  dispatch({ type: VALIDATE_MEMBERSHIP_AGREEMENT, payload: opts });

  return new PaperworkClient(getState)
    .validate(opts)
    .then(result => {
      dispatch({ type: VALIDATE_MEMBERSHIP_AGREEMENT_SUCCESS, payload: result });
      return result;
    })
    .catch(error => {
      dispatch({ type: VALIDATE_MEMBERSHIP_AGREEMENT_FAIL, payload: error, error: true });
      throw error;
    });
};

export const createMembershipAgreement = (opts: PaperworkCreateParams) => (
  dispatch: Dispatch<ActionWithPayload<any> | Action>,
  getState: GetGlobalState
) => {
  dispatch({ type: CREATE_MEMBERSHIP_AGREEMENT, payload: opts });

  return new PaperworkClient(getState)
    .create(opts)
    .then(result => {
      dispatch({ type: CREATE_MEMBERSHIP_AGREEMENT_SUCCESS });
      return result;
    })
    .catch(error => {
      dispatch({ type: CREATE_MEMBERSHIP_AGREEMENT_FAIL });
      throw error;
    });
};

export const uploadMembershipAgreement = (opts: UploadAgreementParams) => (
  dispatch: Dispatch<
    ActionWithMeta<{ notification: { type: string; message: string } }> | ActionWithPayload<any>
  >,
  getState: GetGlobalState
) => {
  dispatch({ type: UPLOAD_MEMBERSHIP_AGREEMENT, payload: opts });

  return new PaperworkClient(getState)
    .uploadAgreement(opts)
    .then(result => {
      dispatch({
        type: UPLOAD_MEMBERSHIP_AGREEMENT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Successfully uploaded paperwork' } },
      });
      return result;
    })
    .catch(err => {
      const { statusText = 'Unknown Status', data } = err;
      const error =
        data?.result?.error ||
        'Unknown Error, please refresh and try again. Contact Product Support if this error persists';

      const message = `Something went wrong with uploading ppwk: ${statusText}: ${error}`;

      dispatch({
        type: UPLOAD_MEMBERSHIP_AGREEMENT_FAIL,
        error: true,
        meta: { notification: { type: 'error', message } },
      });
      throw error;
    });
};

export const updateMembershipSignedAgreementAndMoveIn = (
  opts: UpdateMembershipSignedAgreementParams
) => (dispatch: Dispatch<Action | ActionWithPayload<any>>, getState: GetGlobalState) => {
  dispatch({ type: UPDATE_MEMBERSHIP_SIGNED_AGREEMENT_AND_MOVE_IN, payload: opts });

  return new PaperworkClient(getState)
    .updateMembershipSignedAgreementAndMoveIn(opts)
    .then(result => {
      dispatch({ type: UPDATE_MEMBERSHIP_SIGNED_AGREEMENT_AND_MOVE_IN_SUCCESS });
      return result;
    })
    .catch(error => {
      dispatch({ type: UPDATE_MEMBERSHIP_SIGNED_AGREEMENT_AND_MOVE_IN_FAIL });
      throw error;
    });
};

export const discardContract = (contract: Contract) =>
  createRequestAction({
    method: 'POST',
    endpoint: `${config.spaceman.uri}/api/v2/membership_agreements/${contract.code}/discard`,
    types: [DISCARD_CONTRACT, DISCARD_CONTRACT_SUCCESS, DISCARD_CONTRACT_FAIL],
  });

export const resendContract = (contract: Contract) =>
  createRequestAction({
    method: 'POST',
    endpoint: `${config.spaceman.uri}/api/v2/membership_agreements/${contract.code}/resend`,
    types: [RESEND_CONTRACT, RESEND_CONTRACT_SUCCESS, RESEND_CONTRACT_FAIL],
  });

export default reducer;
