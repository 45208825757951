import {
  roundDiscount,
  formatIntlDiscount,
  separator,
} from 'features/paperwork/discounts/commitmentBasedPromotions/utils/formatter';
import { parseDiscount } from 'features/paperwork/discounts/commitmentBasedPromotions/utils/parser';

export default class Discount {
  discount: string;

  constructor(discount: string) {
    this.discount = discount;
  }

  static fromSuggestion = (suggestedDiscount: number, locale: string): Discount =>
    new Discount(formatIntlDiscount(suggestedDiscount, locale));

  isValid = (maxDiscount: number): boolean => this.toNumber() <= maxDiscount;

  netPrice = (totalPrice: number): number => this.toNumber() * totalPrice;

  round = (): Discount => new Discount(this.format());

  shouldRound = (): boolean => {
    const seaparator = separator(this.discount);
    const discountParts = this.discount.split(seaparator);

    return discountParts.length > 1 && discountParts[1].length > 2;
  };

  toNumber = (): number => parseDiscount(this.discount);

  toString = (): string => this.discount;

  format = (): string => {
    const roundedDiscount = roundDiscount(this.toNumber());

    if (Number.isInteger(roundedDiscount)) {
      return roundedDiscount.toString();
    }

    return roundedDiscount.toFixed(2).replace(/\./, separator(this.toString()));
  };
}
