import { handleActions, createAction, Action } from 'redux-actions';
import { omit } from 'lodash';

import { Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';

import { InventoryItem } from '../types';

// Action Constants
export const SELECT_INVENTORY_ITEM = cc('SELECT_INVENTORY_ITEM');
export const DESELECT_INVENTORY_ITEM = cc('DESELECT_INVENTORY_ITEM');
export const DESELECT_ALL_INVENTORY_ITEMS = cc('DESELECT_ALL_INVENTORY_ITEMS');

// Initial State
export type State = Hash<InventoryItem>;

export const initialState = {};

export const selectInventoryItem = createAction(
  SELECT_INVENTORY_ITEM,
  (
    inventoryItemUuid: string,
    inventoryItem: InventoryItem
  ): { inventoryItem: InventoryItem; inventoryItemUuid: string } => ({
    inventoryItemUuid,
    inventoryItem,
  })
);

export const deselectInventoryItem = createAction(
  DESELECT_INVENTORY_ITEM,
  (inventoryItemUuid: string): { inventoryItemUuid: string } => ({ inventoryItemUuid })
);

export const deselectAllInventoryItems = () => (
  dispatch: Dispatch<{
    type: string;
  }>
) => dispatch({ type: DESELECT_ALL_INVENTORY_ITEMS });

// this is done in order to prevent introducing regressions to an uncharted code
// as we currently deal with types only
interface HackyInventoryActionPayload {
  inventoryItemUuid: string;
  inventoryItem?: InventoryItem;
}

// Reducer
export const reducer = handleActions<State, any>(
  {
    [SELECT_INVENTORY_ITEM]: (state: State, action: Action<HackyInventoryActionPayload>): State =>
      action.payload.inventoryItemUuid in state || !action.payload.inventoryItem
        ? state
        : {
            ...state,
            [action.payload.inventoryItemUuid]: action.payload.inventoryItem,
          },
    [DESELECT_INVENTORY_ITEM]: (
      state: State,
      action: Action<HackyInventoryActionPayload>
    ): State => ({
      ...omit(state, action.payload.inventoryItemUuid),
    }),
    [DESELECT_ALL_INVENTORY_ITEMS]: () => initialState,
  },
  initialState
);

export default reducer;
