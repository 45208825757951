import { createRequestReducer } from 'store/util/createRequestReducer';
import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';
import { InventorySearchMetrics } from 'features/building/types';

export const [
  FETCH_ALL_INVENTORY_METRICS,
  FETCH_ALL_INVENTORY_METRICS_SUCCESS,
  FETCH_ALL_INVENTORY_METRICS_FAIL,
] = createRequestConstantNames(cc('FETCH_ALL_INVENTORY_METRICS'));

export const [
  FETCH_INVENTORY_METRICS,
  FETCH_INVENTORY_METRICS_SUCCESS,
  FETCH_INVENTORY_METRICS_FAIL,
] = createRequestConstantNames(cc('FETCH_INVENTORY_METRICS'));

const reducer = createRequestReducer<InventorySearchMetrics, InventorySearchMetrics>(
  [
    FETCH_ALL_INVENTORY_METRICS,
    FETCH_ALL_INVENTORY_METRICS_SUCCESS,
    FETCH_ALL_INVENTORY_METRICS_FAIL,
  ],
  {},
  res => res.payload
);

export default reducer;
