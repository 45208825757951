import cc from 'store/util/createReduxConstant';

export const AUTHORIZE = cc('AUTHORIZE');
export const AUTHORIZE_SUCCESS = cc('AUTHORIZE_SUCCESS');
export const AUTHORIZE_FAIL = cc('AUTHORIZE_FAIL');

export const GET_ALGOLIA_SECURED_KEY = cc('GET_ALGOLIA_SECURED_KEY');
export const GET_ALGOLIA_SECURED_KEY_SUCCESS = cc('GET_ALGOLIA_SECURED_KEY_SUCCESS');
export const GET_ALGOLIA_SECURED_KEY_FAIL = cc('GET_ALGOLIA_SECURED_KEY_FAIL');

export const UPDATE_USER_INFO = cc('UPDATE_USER_INFO');

export const LOGOUT = cc('LOGOUT');
