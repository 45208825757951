import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { configure } from '@wework/we-auth-react';

import {
  Auth0User,
  employeeProfileFromAuth0User,
  setAuthInstance,
} from 'lib/tokenRegistry/auth0Provider';
import { appInitAuthorize } from 'features/auth/requestActions';
import App from 'features/app/app';
import { getBuildingsLoaded, getBuildingsLoading } from 'store/modules/buildings/selectors';
import { getLocationsLoaded, getLocationsLoading } from 'store/selectors';
import { fetchLocations } from 'store/modules/locations';
import { GlobalState } from 'store/modules';
import { ReduxProps } from 'store/types';
import config from 'config';
import { AppLoading } from 'features/app/appLoading';
import { AppBootstrap } from 'features/app/appBootstrap';

const mapStateToProps = (state: GlobalState) => ({
  shouldFetchBuildings: !(getBuildingsLoading(state) || getBuildingsLoaded(state)),
  shouldFetchLocations: !(getLocationsLoading(state) || getLocationsLoaded(state)),
});

const mapDispatchToProps = {
  appInitAuthorize,
  locationsFetcher: fetchLocations,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

export const Auth0WrapperComponent = ({
  appInitAuthorize,
  locationsFetcher,
  shouldFetchBuildings,
  shouldFetchLocations,
}: Props) => {
  const auth0: Auth0ContextInterface<Auth0User> = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (auth0.isAuthenticated && !isLoading) {
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!auth0.isLoading) {
      configure({
        environment: config.appEnv,
        contextClient: {
          getAccessToken: auth0.getAccessTokenSilently,
          getEmployeeUuid: () =>
            auth0.user ? employeeProfileFromAuth0User(auth0.user).employeeUuid : '',
        },
      });

      setAuthInstance(auth0);
      if (auth0.isAuthenticated) {
        setIsLoading(true);

        // preloading items
        appInitAuthorize().finally(() => {
          locationsFetcher(shouldFetchLocations, shouldFetchBuildings);
          setIsLoading(false);
        });
      }
    }
  }, [auth0.isLoading]);

  if (auth0.isLoading) {
    return null;
  }

  return (
    <AppLoading isPreloading={isLoading}>
      <AppBootstrap isPreloading={isLoading} userLoggedIn={userLoggedIn}>
        <App isPreloading={isLoading} />
      </AppBootstrap>
    </AppLoading>
  );
};

export const Auth0Wrapper = connect(mapStateToProps, mapDispatchToProps)(Auth0WrapperComponent);
