import { handleActions, createAction } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

import { ActionPayload, ModalTypes } from '../config/types';

// Action Constants
export const HIDE_MODAL = cc('HIDE_MODAL');
export const SHOW_MODAL = cc('SHOW_MODAL');

// Initial State
export interface State {
  selectedModal: string | null | undefined;
  payload: ActionPayload | null | undefined;
}

export const initialState = {
  selectedModal: null,
  payload: null,
};

export const showModal = createAction(
  SHOW_MODAL,
  (
    selectedModal: ModalTypes,
    payload: ActionPayload | null | undefined
  ): { selectedModal: ModalTypes; payload: ActionPayload | null | undefined } => ({
    selectedModal,
    payload,
  })
);

export const hideModal = createAction(HIDE_MODAL);

// Reducer
export const reducer = handleActions<State, any>(
  {
    [SHOW_MODAL]: (_state: State, action) => action.payload,
    [HIDE_MODAL]: () => initialState,
  },
  initialState
);

export default reducer;
