import { combineReducers } from 'redux';

import accounts, { State as AccountsState } from 'features/accounts/redux/reducer';

export interface State {
  accounts: AccountsState;
}

export interface SpacemanSubset {
  spaceman: State;
}

export const reducer = combineReducers<State>({
  accounts,
});

export default reducer;
