import { ActionPayload, IFrameInventoryAction, IFrameActionConfig } from '../config/types';

import { isAvailableForHolds } from './actionAvailability';
import isInventoryGroup from './isInventoryGroup';

export const addHold: IFrameInventoryAction = (payload: ActionPayload | null | undefined) => {
  const messagePayload = { action: 'createHold', reservableUuid: payload?.uuid };
  // $FlowFixMe TODO this will only get called in an iframe situation
  window.parent.postMessage(JSON.stringify(messagePayload), '*');
};

export const editHold: IFrameInventoryAction = (payload: ActionPayload | null | undefined) => {
  const messagePayload = { action: 'editHold', officeHoldUuid: payload?.hold?.uuid };
  // $FlowFixMe TODO this will only get called in an iframe situation
  window.parent.postMessage(JSON.stringify(messagePayload), '*');
};

export const removeHold: IFrameInventoryAction = (payload: ActionPayload | null | undefined) => {
  const messagePayload = { action: 'removeHold', officeHoldUuid: payload?.hold?.uuid };
  // $FlowFixMe TODO this will only get called in an iframe situation
  window.parent.postMessage(JSON.stringify(messagePayload), '*');
};

export const ActionMap: Hash<IFrameActionConfig> = {
  addHold: { key: 'addHold', text: 'Add Hold', action: addHold, dispatch: false },
  editHold: { key: 'editHold', text: 'Edit Hold', action: editHold, dispatch: false },
  removeHold: { key: 'removeHold', text: 'Remove Hold', action: removeHold, dispatch: false },
};

export const getActions = (params: ActionPayload): Array<IFrameActionConfig> => {
  const actions: Array<IFrameActionConfig> = [];

  if (isInventoryGroup(params)) {
    return actions;
  }

  if (isAvailableForHolds(params)) {
    if (params.onHold) {
      actions.push(ActionMap.editHold);
      actions.push(ActionMap.removeHold);
    } else {
      actions.push(ActionMap.addHold);
    }
  }

  return actions;
};
