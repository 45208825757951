import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Popover } from 'features/notifications/components/popover';
import { trackAnalyticsFor } from 'lib/analytics/analytics';
import { AnalyticsEventName } from 'lib/analytics/constants';
import { NotificationBellState } from 'lib/analytics/types/dynamic/notifications';
import { useGetCurrentWorkflow } from 'lib/analytics/types/useGetCurrentWorkflow';
import { BaseAction, Dispatch, ReduxProps } from 'store/types';
import { GlobalState } from 'store/modules';
import {
  getNotificationMessages,
  getHasMoreNotifications,
  getNotificationsError,
  getIsNotificationsLoading,
  getNotificationCurrentPage,
  CREATE_NEW_NOTIFICATION_ALERT,
} from 'features/notifications/ducks/notifications';
import { fetchNotifications, markAllNotificationsAsRead } from 'features/notifications/ducks/utils';
import { Notification } from 'lib/api/notify/types';
import { notificationsHandler } from 'features/notifications/pusher/notificationsHandler';
import { PusherEvent } from 'features/notifications/pusher/constants';
import { getUserInfoFromAuth } from 'features/auth/selectors';
import { getNotificationsDeepLink } from 'features/notifications/ducks/notificationDeepLink';

import NotificationIcon from './notificationIcon';

const mapStateToProps = (state: GlobalState) => ({
  currentPage: getNotificationCurrentPage(state),
  currentUser: getUserInfoFromAuth(state),
  hasMoreNotifications: getHasMoreNotifications(state),
  hasNotificationsError: getNotificationsError(state),
  isNotificationsLoading: getIsNotificationsLoading(state),
  notificationMessages: getNotificationMessages(state),
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  fetchNotifications: ({ page }: { page?: number }) => dispatch(fetchNotifications({ page })),
  markAllNotificationsAsRead: () => dispatch(markAllNotificationsAsRead()),
});

type NotificationsContainerProps = Readonly<
  ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>
>;

const NotificationsContainer = ({
  currentPage = 0,
  currentUser,
  fetchNotifications,
  hasMoreNotifications,
  hasNotificationsError,
  isNotificationsLoading,
  markAllNotificationsAsRead,
  notificationMessages = [],
}: NotificationsContainerProps) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInitialPageLoading, setIsInitialPageLoading] = useState<boolean>(false);
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { isModalOpen } = useSelector(getNotificationsDeepLink);
  const workflow = useGetCurrentWorkflow();

  const checkForUnreadNotifications = () => {
    // a real notification will have a `read_at` property that, if null/undefined,
    // means the notification is unread
    const unreadCount = notificationMessages.reduce(
      (unreadCount: number, notification: Notification) =>
        notification?.read_at ? unreadCount : unreadCount + 1,
      0
    );

    setUnreadNotifications(unreadCount);
  };

  const loadMoreNotifications = () => {
    return fetchNotifications({ page: currentPage + 1 });
  };

  const handleNotificationsToggle = async () => {
    if (isOpen) {
      if (unreadNotifications > 0) {
        await markAllNotificationsAsRead();
        setUnreadNotifications(0);
      }
      setIsOpen(false);
      // the unread state should go according to the notification bell blue icon
      trackAnalyticsFor(AnalyticsEventName.notifications_bell_toggled, {
        state: NotificationBellState.COLLAPSED,
        unread: !!unreadNotifications,
        workflow,
      });
    } else {
      trackAnalyticsFor(AnalyticsEventName.notifications_bell_toggled, {
        state: NotificationBellState.EXPANDED,
        unread: !!unreadNotifications,
        workflow,
      });
      setIsOpen(true);
    }
  };

  useEffect(() => {
    checkForUnreadNotifications();
    if (isInitialPageLoading && !isNotificationsLoading) {
      setIsInitialPageLoading(false);
    }
  }, [notificationMessages, isNotificationsLoading]);

  useEffect(() => {
    setIsInitialPageLoading(true);

    fetchNotifications({});
  }, []);

  // Event listeners for closing the Popover if clicking outside the component or hitting Escape
  useEffect(() => {
    if (!popoverRef.current || !isOpen || isModalOpen) return;
    let outsideClicks;
    let handleEscapeKeyPress;

    const removeOutsideEvents = () => {
      document.removeEventListener('click', outsideClicks);
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };

    outsideClicks = event => {
      if (popoverRef.current?.contains(event.target)) return;
      isOpen && handleNotificationsToggle();
      removeOutsideEvents();
    };

    handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleNotificationsToggle();
        removeOutsideEvents();
      }
    };

    document.addEventListener('click', outsideClicks);
    document.addEventListener('keydown', handleEscapeKeyPress);

    return removeOutsideEvents;
  }, [popoverRef.current, isOpen]);

  useEffect(() => {
    if (!currentUser?.uuid) {
      return;
    }

    const disconnectHandler = notificationsHandler(
      `private-spacestation-${currentUser?.uuid}`,
      currentUser?.uuid
    ).then(handler => {
      handler.connect();
      handler.registerEventHandler(PusherEvent.NOTIFICATION, (notification: Notification) => {
        dispatch({
          type: CREATE_NEW_NOTIFICATION_ALERT,
          payload: notification,
        });
      });
      return handler;
    });

    return () => {
      disconnectHandler.then(handler => handler.disconnect());
    };
  }, [currentUser?.uuid]);

  return (
    <div ref={popoverRef} style={{ display: 'inline', alignSelf: 'center' }}>
      <NotificationIcon
        onToggle={handleNotificationsToggle}
        isOpen={isOpen}
        unreadNotificationCount={unreadNotifications}
      />
      <Popover
        hasNextPage={hasMoreNotifications}
        hasNotificationLoadError={hasNotificationsError}
        isInitialPageLoading={isInitialPageLoading}
        isOpen={isOpen}
        loadNextPage={loadMoreNotifications}
        notificationList={notificationMessages}
      />
    </div>
  );
};

export const TestableNotificationsContainer = NotificationsContainer;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
