import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import config from 'config';
import { BaseAction, Dispatch, GetGlobalState } from 'store/types';
import { createRequestAction } from 'store/util';
import { GlobalState } from 'store/modules';
import cc from 'store/util/createReduxConstant';
import { getCountryInfo } from 'features/paperwork/ducks/selectors';

export const STANDARD_TOS = 'STANDARD_TOS';
export const PREVIOUS_TOS = 'PREVIOUS_TOS';
export const CUSTOM_TOS = 'CUSTOM_TOS';

// selectors
const getCustomTermsOfService = (state: GlobalState, props) => {
  return state.paperwork.customTermsOfService.byCompanyUuid[props.company.uuid] || [];
};

export const getSelectedTermOfService = (state: GlobalState) => {
  return state.paperwork.customTermsOfService.selected;
};

export const getUploadedCustomTermOfService = (state: GlobalState) => {
  return state.paperwork.customTermsOfService.uploaded;
};

export const getCustomTermsOfServiceForCountries = createSelector(
  [getCustomTermsOfService, getCountryInfo],
  (customTermsOfService, { countryCode }) =>
    customTermsOfService.filter((link: string) => link.includes(countryCode))
);

// reducer

export const FETCH_CUSTOM_TOS = cc('FETCH_CUSTOM_TOS');
export const FETCH_CUSTOM_TOS_SUCCESS = cc('FETCH_CUSTOM_TOS_SUCCESS');
export const FETCH_CUSTOM_TOS_FAIL = cc('FETCH_CUSTOM_TOS_FAIL');
export const UPDATE_COUNTRY_INFO = cc('UPDATE_COUNTRY_INFO');
export const UPLOAD_CUSTOM_TOS = cc('UPLOAD_CUSTOM_TOS');
export const UPLOAD_CUSTOM_TOS_SUCCESS = cc('UPLOAD_CUSTOM_TOS_SUCCESS');
export const UPLOAD_CUSTOM_TOS_FAIL = cc('UPLOAD_CUSTOM_TOS_FAIL');
export const SELECT_TERM_OF_SERVICE_DOC = cc('SELECT_TERM_OF_SERVICE_DOC');
export const CLEAR_CUSTOM_TOS = cc('CLEAR_CUSTOM_TOS');
export const LOAD_CUSTOM_TOS_FILE = cc('LOAD_CUSTOM_TOS_FILE');

export type FileUpload = {
  fileName: string | null | undefined;
  data: string | null | undefined;
};
export interface State {
  loaded: boolean;
  countryInfo: {
    countryCode?: string;
  };
  loading: boolean;
  error: string | null | undefined;
  byCompanyUuid: Hash<Array<{}>>;
  uploaded: FileUpload;
  selected: {
    key: string | null | undefined;
    data: FileUpload | string | null | undefined;
  };
}

export const initialState: State = {
  byCompanyUuid: {},
  countryInfo: {},
  loading: false,
  loaded: false,
  error: null,
  uploaded: {
    fileName: null,
    data: null,
  },
  selected: {
    key: STANDARD_TOS,
    data: null,
  },
};

export const reducer = handleActions<State, any, { companyUuid: string }>(
  {
    [FETCH_CUSTOM_TOS]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_CUSTOM_TOS_SUCCESS]: (
      state: State,
      action: {
        payload: { result: Array<any> };
        meta: { companyUuid: string };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: action.payload?.result || [],
      },
    }),
    [FETCH_CUSTOM_TOS_FAIL]: (state: State, action: { payload: { message: string } }) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
    [UPLOAD_CUSTOM_TOS]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [UPLOAD_CUSTOM_TOS_SUCCESS]: (
      state: State,
      action: {
        meta: { companyUuid: string };
        payload: { result: Array<{}> };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: [
          ...(state.byCompanyUuid[action.meta.companyUuid] || []),
          action.payload?.result,
        ],
      },
    }),
    [LOAD_CUSTOM_TOS_FILE]: (
      state: State,
      action: {
        payload: {
          file: FileUpload;
        };
      }
    ) => ({
      ...state,
      uploaded: action.payload.file,
      selected: {
        key: action.payload.file.fileName ? CUSTOM_TOS : STANDARD_TOS,
        data: action.payload.file.fileName ? action.payload.file : null,
      },
    }),
    [UPLOAD_CUSTOM_TOS_FAIL]: (state: State, action: { payload: { message: string } }) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
    [UPDATE_COUNTRY_INFO]: (state: State, action: { payload: {} }) => ({
      ...state,
      countryInfo: action.payload,
    }),
    [SELECT_TERM_OF_SERVICE_DOC]: (state: State, action: { payload: { selected } }) => ({
      ...state,
      selected: action.payload.selected,
    }),
    [CLEAR_CUSTOM_TOS]: () => initialState,
  },
  initialState
);

export default reducer;

// actions

export const updateCountryInfo = (countryInfo: {}) => ({
  type: UPDATE_COUNTRY_INFO,
  payload: countryInfo,
});

export const selectTermsOfService = selected => ({
  type: SELECT_TERM_OF_SERVICE_DOC,
  payload: {
    selected,
  },
});

export const clearCustomTermsOfService = () => ({
  type: CLEAR_CUSTOM_TOS,
});

export function fetchCustomTermsOfService(companyUuid: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.spacemoney.uri}/api/v1/accounts/${companyUuid}/terms_of_service`,
      types: [
        FETCH_CUSTOM_TOS,
        {
          type: FETCH_CUSTOM_TOS_SUCCESS,
          meta: { companyUuid },
        },
        FETCH_CUSTOM_TOS_FAIL,
      ],
    });

    return dispatch(requestAction);
  };
}

export function uploadCustomTermsOfService(companyUuid: string, terms: {}) {
  return (dispatch: Dispatch<BaseAction>, getState: GetGlobalState) => {
    const { countryCode } = getState().paperwork.customTermsOfService.countryInfo;

    const requestAction = createRequestAction({
      endpoint: `${config.spacemoney.uri}/api/v1/accounts/${companyUuid}/terms_of_service`,
      method: 'POST',
      body: {
        country_code: countryCode,
        terms,
      },
      types: [
        UPLOAD_CUSTOM_TOS,
        {
          type: UPLOAD_CUSTOM_TOS_SUCCESS,
          meta: { companyUuid },
        },
        UPLOAD_CUSTOM_TOS_FAIL,
      ],
    });

    return dispatch(requestAction);
  };
}

export const loadCustomTermsOfService = file => ({
  type: LOAD_CUSTOM_TOS_FILE,
  payload: {
    file,
  },
});
