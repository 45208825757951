export const DEFAULT_ROW_HEIGHT = 100;
export const MIN_ROW_HEIGHT = 72; // min-height for End of List component

export const FOOTER = {
  MISSION_ACCOMPLISHED:
    '“Mission accomplished! You made it to the end of the notification universe.”',
};

/**
 * Gets a list of class names for the Popover, given a set a variables
 * (Negative left value is 400px minus 40px for the Notification icon)
 * @param {Boolean} isLoading Is the page loading for the first time?
 * @param {Boolean} isOpen Is the Popover open?
 * @param {Boolean} hasNotificationLoadError Was there an error loading Notifications?
 * @param {Number} notificationCount How many notification exist
 * @returns {String} The class names
 */
export const getPopoverStyles = (
  isLoading: boolean,
  isOpen: boolean,
  hasNotificationLoadError: boolean,
  notificationCount: number
) => {
  const isOpenAndNotLoading = !isLoading && isOpen;

  return `
    absolute bg-white border border-gray-90
    right-10 max-h-[460px] min-h-[100px]
    overflow-x-hidden overflow-y-hidden rounded-sm
    scroll-smooth shadow top-14 w-[400px]
    ${!isOpen ? 'hidden' : ''}
    ${!isLoading ? 'overflow-y-auto' : ''}
    ${isLoading ? 'h-[100px]' : ''}
    ${isOpenAndNotLoading && notificationCount === 2 ? 'h-[200px]' : ''}
    ${isOpenAndNotLoading && notificationCount === 3 ? 'h-[300px]' : ''}
    ${isOpenAndNotLoading && notificationCount === 4 ? 'h-[400px]' : ''}
    ${
      (isOpenAndNotLoading && (notificationCount > 4 || notificationCount === 0)) ||
      hasNotificationLoadError
        ? 'h-[460px]'
        : ''
    }
  `;
};
