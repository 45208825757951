import { handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

export const SET_PENDING_MOVE_INS = cc('SET_PENDING_MOVE_INS');
export const CLEAR_PENDING_MOVE_INS = cc('CLEAR_PENDING_MOVE_INS');

export interface PendingMoveIn {
  action: 'add';
  buildingName: string;
  building_uuid: string;
  quantity?: number | null | undefined;
  capacity: number | null;
  country: string;
  countryCode: string;
  currency: string;
  index: number;
  membership_agreement_uuid?: string;
  sf_opportunity_id?: string;
  locale?: string;
  moveIn: string;
  office: string;
  officeNum: string;
  officeType: string;
  pending: boolean;
  price: number;
  uuid: string;
  longTermAgreement?: string;
  agreementModifications?: string;
  noticeRequirement?: string;
  contract_type?: string;
  productUuid?: string;
}

export type State = Array<PendingMoveIn>;

export const initialState: State = [];

export const reducer = handleActions<State, any>(
  {
    [SET_PENDING_MOVE_INS]: (_state: State, action: { payload: Array<PendingMoveIn> }) =>
      action.payload,
    [CLEAR_PENDING_MOVE_INS]: () => [],
  },
  initialState
);

export const setPendingMoveIns = (pendingMoveIns: Array<PendingMoveIn>) => ({
  type: SET_PENDING_MOVE_INS,
  payload: pendingMoveIns,
});

export const clearPendingMoveIns = () => ({ type: CLEAR_PENDING_MOVE_INS });

export default reducer;
