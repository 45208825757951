import React from 'react';

import {
  MemberSearchResultItem,
  CompanySearchResultItem,
  MemberCompany,
  Status,
} from 'store/modules/search/searchService/types';
import { SearchableEntity } from 'features/search/types';

import { Result, RowEndComponentProps } from '../types';
import { createAvatarUrlById } from '../utils';
import CompanyNameCell from '../company/cells/name';
import OfficesCell from '../company/cells/offices';
import ContactInfoCell from '../common/cells/contactInfo';

import NameCell from './cells/name';
import MemberNotificationActions from './memberNotificationActions';
import { memberActionURL } from './utils';
import { MemberCellProps } from './types';
import MemberLocation from './location';

export const invalidMember = {
  uuid: '',
  name: 'N/A',
  email: '',
};

export const invalidCompany: CompanySearchResultItem = {
  uuid: '',
  name: 'N/A',
  location_uuids: [],
  spaces: [],
  is_migrated: false,
  aliases: [],
  primary_member: invalidMember,
  enterprise: false,
  potential: false,
  short_code: '',
  objectID: '',
  status: Status.INACTIVE,
};

const MemberNameCell: React.FC<MemberCellProps> = ({ member }) => (
  <NameCell
    member={member}
    secondRowContent={<MemberLocation member={member} />}
    isPrimary={member.isPrimary ?? false}
  />
);
const MemberCompanyNameCell = ({ company }: MemberCellProps) =>
  company ? <CompanyNameCell company={company} inactive /> : null;
const MemberContactInfoCell = ({ member: { email, normalized_phone } }: MemberCellProps) => (
  <ContactInfoCell email={email} phoneNumber={normalized_phone} />
);
const MemberOfficeNumberCell = ({ company }: MemberCellProps) =>
  company ? <OfficesCell company={company} /> : null;

const createMemberCells = (member: MemberSearchResultItem, company: MemberCompany) =>
  [
    MemberNameCell,
    MemberCompanyNameCell,
    MemberContactInfoCell,
    MemberOfficeNumberCell,
  ].map((Cell, index) => <Cell member={member} company={company} key={index} />);

export const getMemberCompany = (member: MemberSearchResultItem): MemberCompany =>
  member.companies?.find(company => company.status === 'active') ||
  member.companies?.[0] ||
  member.past_companies?.[0] ||
  invalidCompany;

export const mapMemberResult = (member: MemberSearchResultItem): Result => {
  const company = getMemberCompany(member);
  const analyticsData = {
    member_uuid: member.uuid,
    account_uuid: company.uuid,
    target_entity: member.uuid,
  };
  function RowEndComponent({ focused }: RowEndComponentProps) {
    return <MemberNotificationActions member={member} hidden={!focused} />;
  }

  return {
    uuid: member.uuid,
    avatarUrl: createAvatarUrlById(member.uuid),
    cells: createMemberCells(member, company),
    RowEndComponent,
    entityType: SearchableEntity.MEMBERS,
    link: memberActionURL(member),
    analyticsData,
    data: member,
  };
};
