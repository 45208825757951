import { actionTypes, formValueSelector } from 'redux-form';
import { includes, values } from 'lodash';

import { getSubscriptions, pushFormHistory, resetFormHistory } from 'features/reduxFormUndo/ducks';
import { ActionWithMeta } from 'store/types';
import { GlobalState } from 'store/modules';

const reduxFormActions = values(actionTypes);

type Next = (arg0: {}) => unknown;
type Store = {
  getState: () => GlobalState;
  dispatch: (arg0: {}) => void;
};

export default (store: Store) => (next: Next) => (
  action: ActionWithMeta<{
    form: string | Array<string>;
    field: string;
  }>
) => {
  if (includes(reduxFormActions, action.type)) {
    if (action.type === actionTypes.DESTROY) {
      const formNames = Array.isArray(action.meta.form) ? action.meta.form : [action.meta.form];
      formNames
        .filter(formName => includes(getSubscriptions(store.getState()), formName))
        .forEach(formName => {
          store.dispatch(resetFormHistory(formName));
        });
    } else if (includes(getSubscriptions(store.getState()), action.meta.form)) {
      const formName = Array.isArray(action.meta.form) ? action.meta.form[0] : action.meta.form;

      if (actionTypes.CHANGE === action.type) {
        const fieldValue = formValueSelector(formName)(store.getState(), action.meta.field);

        store.dispatch(
          pushFormHistory(formName, { field: action.meta.field, value: fieldValue || null })
        );
      } else if (actionTypes.RESET === action.type) {
        store.dispatch(resetFormHistory(formName));
      }
    }
  }

  return next(action);
};
