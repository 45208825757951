export interface LazySingleton<T> {
  get(...args: Array<any>): T;
}

export default function lazySingleton<T>(resolver: (...args: Array<any>) => T): LazySingleton<T> {
  let singleton: T | null = null;

  return {
    get(...args: Array<any>): T {
      if (singleton === null) {
        singleton = resolver(...args);
      }

      return singleton;
    },
  };
}
