import { createSelector } from 'reselect';

import {
  getCurrentLocation as getCurrentSiLocation,
  getHomeLocation as getHomeSiLocation,
} from '../siLocations/selectors';

import { fromSILocationToSMLocation } from './convertors';

export const getCurrentLocationState = createSelector(getCurrentSiLocation, location =>
  fromSILocationToSMLocation(location)
);

export const getHomeLocationState = createSelector(getHomeSiLocation, location =>
  fromSILocationToSMLocation(location)
);
