import { formValueSelector } from 'redux-form';

import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

// Action Constants
export const RESET_CREATE_MEMBERS_STATUS = cc('RESET_CREATE_MEMBERS_STATUS');

export const [
  INVITE_BULK_USERS,
  INVITE_BULK_USERS_SUCCESS,
  INVITE_BULK_USERS_FAIL,
] = createRequestConstantNames(cc('INVITE_BULK_USERS'));

export const [
  INVITE_SINGLE_USER,
  INVITE_SINGLE_USER_SUCCESS,
  INVITE_SINGLE_USER_FAIL,
] = createRequestConstantNames(cc('INVITE_SINGLE_USER'));

export const [
  FETCH_EXTRA_MEMBER_FEE,
  FETCH_EXTRA_MEMBER_FEE_SUCCESS,
  FETCH_EXTRA_MEMBER_FEE_FAIL,
] = createRequestConstantNames(cc('FETCH_EXTRA_MEMBER_FEE'));

// Form constants
export const ADD_SINGLE_MEMBER_FORM_NAME = 'addSingleMemberForm';
export const SINGLE_MEMBER_INVITE_FORM_NAME = 'singleMemberInviteForm';
export const BULK_MEMBER_UPLOAD_FORM_NAME = 'bulkMemberUploadForm';

export const BULK_MEMBER_UPLOAD_MEMBERSHIP_TYPE_FIELD = 'bulkMemberUploadMembershipTypeField';
export const BULK_MEMBER_UPLOAD_LOCATION_FIELD = 'bulkMemberUploadLocationField';
export const BULK_MEMBER_UPLOAD_FILE_RESULTS = 'bulkMemberUploadFileResults';

export const MEMBER_INVITE_LOCALE_FIELD = 'localeLanguageTag';
export const MEMBER_INVITE_LOCATION_FIELD = 'selectedLocation';

export const addMemberFormSelector = formValueSelector(ADD_SINGLE_MEMBER_FORM_NAME);
export const inviteMemberFormSelector = formValueSelector(SINGLE_MEMBER_INVITE_FORM_NAME);

export const EXTRA_MEMBER_FEE_NAME = 'Extra Member';
