import { Location } from '@wework/ss-microfrontend-types';

type DynamicProps = {
  location: Location | null;
  experimentGroups: Record<string, string> | null;
};

const dynamicProps: DynamicProps = {
  location: null,
  experimentGroups: null,
};

export const getLocation = (): Location | null => dynamicProps.location;

export const getExperimentGroups = (): Record<string, string> | null =>
  dynamicProps.experimentGroups;

export default dynamicProps;
