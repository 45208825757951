import { handleActions, Action } from 'redux-actions';
import { isNil, omit, filter } from 'lodash';

import { BaseAction, Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';

import { FilterValue, Filters } from '../config/types';
import { FiltersDisplayConfig } from '../config/filtersDisplayConfig';

// Action Constants
export const RESET_FILTERS = cc('RESET_FILTERS');
export const RESET_ADVANCED_FILTERS = cc('RESET_ADVANCED_FILTERS');
export const UPDATE_FILTERS = cc('UPDATE_FILTERS');

// Initial State
const initialState: Filters = {};

export const updateFilters = (selectedFilter: string, selectedFilterValue: FilterValue) => (
  dispatch: Dispatch<Action<{ selectedFilter: string; selectedFilterValue: FilterValue }>>
) =>
  dispatch({
    type: UPDATE_FILTERS,
    payload: {
      selectedFilter,
      selectedFilterValue,
    },
  });

export const resetFilters = () => (dispatch: Dispatch<BaseAction>) =>
  dispatch({ type: RESET_FILTERS });

export const resetAdvancedFilters = () => (dispatch: Dispatch<BaseAction>) =>
  dispatch({ type: RESET_ADVANCED_FILTERS });

// Reducer
export const reducer = handleActions<Filters, any>(
  {
    [UPDATE_FILTERS]: (state, action) =>
      !isNil(action.payload.selectedFilterValue)
        ? {
            ...state,
            [action.payload.selectedFilter]: action.payload.selectedFilterValue,
          }
        : {
            ...omit(state, action.payload.selectedFilter),
          },
    [RESET_FILTERS]: () => ({}),
    [RESET_ADVANCED_FILTERS]: state => ({
      ...omit(
        state,
        filter(FiltersDisplayConfig, 'advancedPanel').map(filter => filter.key)
      ),
    }),
  },
  initialState
);

export default reducer;
