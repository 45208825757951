import { endOfDay, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';

import { Location } from 'store/modules/locations';
import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { ToastType } from 'store/modules/toasts/constants';
import createRequestAction, { Params } from 'store/util/createRequestAction';
import config from 'config';
import {
  UPDATE_VISITORS_CHECKED_IN_GUEST,
  UPDATE_VISITORS_CHECKED_IN_GUEST_SUCCESS,
  UPDATE_VISITORS_CHECKED_IN_GUEST_FAIL,
  UPDATE_VISITORS_GUEST,
  UPDATE_VISITORS_GUEST_SUCCESS,
  UPDATE_VISITORS_GUEST_FAIL,
  SEND_EMERGENCY_LIST,
  SEND_EMERGENCY_LIST_SUCCESS,
  SEND_EMERGENCY_LIST_FAIL,
  EXPORT_VISITOR_LIST,
  EXPORT_VISITOR_LIST_SUCCESS,
  EXPORT_VISITOR_LIST_FAIL,
  EXPORT_SCHEDULED_VISITOR_LIST,
  EXPORT_SCHEDULED_VISITOR_LIST_SUCCESS,
  EXPORT_SCHEDULED_VISITOR_LIST_FAIL,
  DELETE_VISITORS_GUEST,
  DELETE_VISITORS_GUEST_SUCCESS,
  DELETE_VISITORS_GUEST_FAIL,
  CREATE_NEW_GUEST_VISIT,
  CREATE_NEW_GUEST_VISIT_SUCCESS,
  CREATE_NEW_GUEST_VISIT_FAIL,
  PRINT_GUEST_BADGE,
  PRINT_GUEST_BADGE_SUCCESS,
  PRINT_GUEST_BADGE_FAIL,
  RESET_GUEST_PHOTO,
  RESET_GUEST_PHOTO_SUCCESS,
  RESET_GUEST_PHOTO_FAIL,
  DELETE_GUEST_PHOTO,
  DELETE_GUEST_PHOTO_SUCCESS,
  DELETE_GUEST_PHOTO_FAIL,
  CREATE_NEW_PRE_REGISTERED_GUEST,
  CREATE_NEW_PRE_REGISTERED_GUEST_SUCCESS,
  CREATE_NEW_PRE_REGISTERED_GUEST_FAIL,
  BULK_UPLOAD_PRE_REGISTERED_GUEST,
  BULK_UPLOAD_PRE_REGISTERED_GUEST_SUCCESS,
  BULK_UPLOAD_PRE_REGISTERED_GUEST_FAIL,
  EDIT_PRE_REGISTERED_GUEST,
  EDIT_PRE_REGISTERED_GUEST_SUCCESS,
  EDIT_PRE_REGISTERED_GUEST_FAIL,
  EDIT_GUEST_VISIT,
  EDIT_GUEST_VISIT_SUCCESS,
  EDIT_GUEST_VISIT_FAIL,
} from 'features/visitors/redux/constants';
import { camelCaseJson } from 'lib/util';
import { dateFormats } from 'lib/constants';
import { Guest } from 'features/visitors/types';
import {
  combineDateTime,
  formatDateWithTimeZone,
  memberOptionToHost,
  userName,
  zonedDateInUTC,
} from 'features/visitors/util';
import { getWelkioCsvPath } from 'features/visitors/lib/welkioAPI';
import { notifyError } from 'store/modules/toasts/actions';
import { MemberOption } from 'features/reservations/types';
import {
  GuestFormFields,
  PreRegisterGuestFormFields,
  EditPreregisterGuestFields,
} from 'features/visitors/components/modals/types';
import { BulkUploadBody } from 'features/visitors/redux/types';

const updateGuest = (
  id: string,
  location: Location,
  successMessage: string,
  errorMessage: string,
  body: any
) => {
  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/visits/${id}`,
    method: 'PATCH',
    body,
    meta: {
      id,
      timeZone: location?.time_zone,
    },
    types: [
      UPDATE_VISITORS_GUEST,
      {
        type: UPDATE_VISITORS_GUEST_SUCCESS,
        meta: {
          notification: {
            message: successMessage,
            type: ToastType.SUCCESS,
          },
        },
      },
      {
        type: UPDATE_VISITORS_GUEST_FAIL,
        meta: {
          notification: {
            message: errorMessage,
            type: ToastType.ERROR,
          },
        },
      },
    ],
    payloadFromJSON: camelCaseJson,
  });
};

const singlePreRegisteredRequest = (
  formValues: PreRegisterGuestFormFields,
  location: Location
): Params<any> => {
  const { memberOption, noteDesk, firstName, lastName, date, time, email, noteGuest } = formValues;
  const dateTime = combineDateTime(date, time);

  const types: RequestActionTypes = [
    CREATE_NEW_PRE_REGISTERED_GUEST,
    {
      type: CREATE_NEW_PRE_REGISTERED_GUEST_SUCCESS,
      meta: {
        notification: {
          message: 'Successfully created pre-registered guest!',
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: CREATE_NEW_PRE_REGISTERED_GUEST_FAIL,
      meta: {
        notification: {
          message: 'Failed to create pre-registered guest',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  return {
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/registrations`,
    method: 'POST',
    body: {
      first_name: firstName,
      last_name: lastName,
      comment: noteDesk,
      host_id: memberOption.uuid,
      email,
      send_guest_email: Boolean(email),
      subdomain: 'wework',
      location_id: location?.uuid,
      guest_comment: noteGuest,
      expected_at: format(
        zonedDateInUTC(dateTime, location?.time_zone),
        dateFormats.iso_date_time24
      ),
    },
    meta: {
      timeZone: location?.time_zone,
      visit: {
        host: memberOptionToHost(memberOption), // welkio missing field
      },
    },
    types,
    payloadFromJSON: camelCaseJson,
  };
};

const bulkUploadPreRegisteredRequest = (
  formValues: PreRegisterGuestFormFields,
  file,
  userUuid: string,
  location: Location
): Params<any> => {
  const { memberOption, date, time, noteDesk, noteGuest } = formValues;
  const dateTime = combineDateTime(date, time);

  const types: RequestActionTypes = [
    BULK_UPLOAD_PRE_REGISTERED_GUEST,
    {
      type: BULK_UPLOAD_PRE_REGISTERED_GUEST_SUCCESS,
      meta: {
        notification: {
          message: 'Your registered guests are being imported. Check back soon.',
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: BULK_UPLOAD_PRE_REGISTERED_GUEST_FAIL,
      meta: {
        notification: {
          message: 'Failed to create pre-registered guests',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  const body: BulkUploadBody = {
    host_id: memberOption.uuid,
    send_guest_email: true,
    location_id: location.uuid,
    expected_at: format(zonedDateInUTC(dateTime, location?.time_zone), dateFormats.iso_date_time24),
    registered_by: userUuid,
    comment: noteDesk,
    csv: file,
  };

  if (noteGuest) {
    body.guest_comment = noteGuest;
  }

  return {
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/registrations/import/csv`,
    method: 'POST',
    body,
    types,
    payloadFromJSON: camelCaseJson,
  };
};

export const checkInGuest = (id: string, location: Location, visitor: Guest) => {
  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/registrations/${id}/check-in`,
    method: 'POST',
    meta: {
      id,
      timeZone: location?.time_zone,
    },
    types: [
      UPDATE_VISITORS_CHECKED_IN_GUEST,
      {
        type: UPDATE_VISITORS_CHECKED_IN_GUEST_SUCCESS,
        meta: {
          notification: {
            message: `Checked in ${visitor.name}`,
            type: ToastType.SUCCESS,
          },
        },
      },
      {
        type: UPDATE_VISITORS_CHECKED_IN_GUEST_FAIL,
        meta: {
          notification: {
            message: `Failed to check in ${visitor.name}`,
            type: ToastType.ERROR,
          },
        },
      },
    ],
    payloadFromJSON: camelCaseJson,
  });
};

export const checkOutGuest = (id: string, location: Location, visitor: Guest) => {
  return updateGuest(
    id,
    location,
    `Successfully checked out ${visitor.name}`,
    `Failed to check out ${visitor.name}`,
    {
      signed_out_at: formatDateWithTimeZone(new Date(), location),
    }
  );
};

export const setIdVerified = (id: string, location: Location, visitor: Guest) => {
  return updateGuest(
    id,
    location,
    `${visitor.name} ID has been verified!`,
    `Failed to verify ID of ${visitor.name}.`,
    {
      id_verified: true,
    }
  );
};

export const sendEmergencyList = (locationId: string, userId: string) => {
  const types: RequestActionTypes = [
    SEND_EMERGENCY_LIST,
    {
      type: SEND_EMERGENCY_LIST_SUCCESS,
      meta: {
        notification: {
          message: 'An emergency list has been sent to your email.',
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: SEND_EMERGENCY_LIST_FAIL,
      meta: {
        notification: {
          message: 'Failed to send emergency list. Please try again later.',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/visits/emergency-list`,
    method: 'POST',
    body: {
      location_id: locationId,
      user_id: userId,
    },
    types,
  });
};

export const exportCheckInVisitorList = (locationId: string, date: Date) => {
  const types: RequestActionTypes = [
    EXPORT_VISITOR_LIST,
    {
      type: EXPORT_VISITOR_LIST_SUCCESS,
      meta: {
        notification: {
          message: `The checked in visitors list has been downloaded.`,
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: EXPORT_VISITOR_LIST_FAIL,
      meta: {
        notification: {
          message: `Failed to save checked in visitors list. Please try again later.`,
          type: ToastType.ERROR,
        },
      },
    },
  ];

  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/reports/visits/csv`,
    method: 'POST',
    body: {
      location_id: locationId,
      start_date: format(startOfDay(date), dateFormats.iso_date_and_time24_with_seconds),
      end_date: format(endOfDay(date), dateFormats.iso_date_and_time24_with_seconds),
    },
    types,
    payloadFromJSON: camelCaseJson,
  });
};

export const exportScheduledVisitorList = (locationId: string, date: Date) => {
  const types: RequestActionTypes = [
    EXPORT_SCHEDULED_VISITOR_LIST,
    {
      type: EXPORT_SCHEDULED_VISITOR_LIST_SUCCESS,
      meta: {
        notification: {
          message: `The scheduled visitors list has been downloaded.`,
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: EXPORT_SCHEDULED_VISITOR_LIST_FAIL,
      meta: {
        notification: {
          message: `Failed to save scheduled visitors list. Please try again later.`,
          type: ToastType.ERROR,
        },
      },
    },
  ];

  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/reports/registrations/csv`,
    method: 'POST',
    body: {
      location_id: locationId,
      start_date: format(startOfDay(date), dateFormats.iso_date_and_time24_with_seconds),
      end_date: format(endOfDay(date), dateFormats.iso_date_and_time24_with_seconds),
    },
    types,
    payloadFromJSON: camelCaseJson,
  });
};

export const deleteGuest = (id: string, visitor: Guest) => {
  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/registrations/${id}`,
    method: 'DELETE',
    meta: {
      id,
    },
    types: [
      DELETE_VISITORS_GUEST,
      {
        type: DELETE_VISITORS_GUEST_SUCCESS,
        meta: {
          notification: {
            message: `Successfully removed ${visitor.name}'s visit`,
            type: ToastType.SUCCESS,
          },
        },
      },
      {
        type: DELETE_VISITORS_GUEST_FAIL,
        meta: {
          notification: {
            message: `Failed to remove ${visitor.name}'s visit`,
            type: ToastType.ERROR,
          },
        },
      },
    ],
  });
};

export const createGuestVisit = (
  firstName: string,
  lastName: string,
  memberOption: MemberOption,
  note: string,
  location: Location
) => {
  const types: RequestActionTypes = [
    CREATE_NEW_GUEST_VISIT,
    {
      type: CREATE_NEW_GUEST_VISIT_SUCCESS,
      meta: {
        notification: {
          message: 'Successfully created a new guest visit',
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: CREATE_NEW_GUEST_VISIT_FAIL,
      meta: {
        notification: {
          message: 'Failed to create a new guest visit',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/v2signin`,
    method: 'POST',
    body: {
      first_name: firstName,
      last_name: lastName,
      comment: note,
      host_id: memberOption.uuid,
      location_id: location?.uuid,
    },
    meta: {
      timeZone: location?.time_zone,
      visit: {
        host: memberOptionToHost(memberOption), // welkio response missing field
      },
    },
    types,
    payloadFromJSON: camelCaseJson,
  });
};

export const printGuestBadge = (id: string, location: Location, visitor: Guest) => {
  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/visits/${id}/print-badge`,
    method: 'POST',
    body: {
      location_id: location?.uuid,
    },
    types: [
      // no reducer for types because there is not change in state when reset photo, only notification indication.
      PRINT_GUEST_BADGE,
      {
        type: PRINT_GUEST_BADGE_SUCCESS,
        meta: {
          notification: {
            message: `${visitor.name}'s badge should print shortly.`,
            type: ToastType.SUCCESS,
          },
        },
      },
      {
        type: PRINT_GUEST_BADGE_FAIL,
        meta: {
          notification: {
            message: `Failed to print badge for ${visitor.name}. We are unable to print as we couldn't find any online devices.`,
            type: ToastType.ERROR,
          },
        },
      },
    ],
  });
};

export const resetGuestPhoto = (id: string, location: Location, visitor: Guest) => {
  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/visitors/${visitor.id}/reset-photo`,
    method: 'POST',
    body: {
      location_id: location?.uuid,
    },
    meta: {
      id,
    },
    types: [
      // no reducer for types because there is not change in state when reset photo, only notification indication.
      RESET_GUEST_PHOTO,
      {
        type: RESET_GUEST_PHOTO_SUCCESS,
        meta: {
          notification: {
            message: `${visitor.name} will be asked to re-take a photo on next visit.`,
            type: ToastType.SUCCESS,
          },
        },
      },
      {
        type: RESET_GUEST_PHOTO_FAIL,
        meta: {
          notification: {
            message: `Failed to reset photo for ${visitor.name}.`,
            type: ToastType.ERROR,
          },
        },
      },
    ],
  });
};

export const deleteGuestPhoto = (id: string, location: Location, visitor: Guest) => {
  return createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/visits/${id}/photo`,
    method: 'DELETE',
    body: {
      location_id: location.uuid,
    },
    meta: {
      id,
    },
    types: [
      DELETE_GUEST_PHOTO,
      {
        type: DELETE_GUEST_PHOTO_SUCCESS,
        meta: {
          notification: {
            message: `${visitor.name}'s photo is deleted.`,
            type: ToastType.SUCCESS,
          },
        },
      },
      {
        type: DELETE_GUEST_PHOTO_FAIL,
        meta: {
          notification: {
            message: `Failed to delete photo for ${visitor.name}.`,
            type: ToastType.ERROR,
          },
        },
      },
    ],
  });
};

export const editPreRegisteredGuest = (
  visitId: string,
  formValues: EditPreregisterGuestFields,
  timeZone: string
) => async (dispatch: Dispatch<BaseAction>) => {
  const { memberOption, noteDesk, noteGuest, firstName, lastName, date, time, email } = formValues;

  const types: RequestActionTypes = [
    EDIT_PRE_REGISTERED_GUEST,
    {
      type: EDIT_PRE_REGISTERED_GUEST_SUCCESS,
      meta: {
        notification: {
          message: 'Successfully updated pre-registered guest!',
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: EDIT_PRE_REGISTERED_GUEST_FAIL,
      meta: {
        notification: {
          message: 'Failed to updated pre-registered guest',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  const dateTime = combineDateTime(date, time);
  const formatDateTime = format(zonedDateInUTC(dateTime, timeZone), dateFormats.iso_date_time24);

  await dispatch(
    createRequestAction({
      endpoint: `${config.welkioAuthProxy.uri}/access/v2/registrations/${visitId}`,
      method: 'PATCH',
      body: {
        first_name: firstName,
        last_name: lastName,
        comment: noteDesk,
        guest_comment: noteGuest,
        send_guest_email: Boolean(email),
        host_id: memberOption?.uuid,
        email,
        expected_at: formatDateTime,
      },
      meta: {
        timeZone,
        visit: {
          time: dateTime, // this is a welkio bug - the date is not returned updated
          name: userName(firstName, lastName), // this is a welkio bug - the firstName and lastName is not returned updated
          host: memberOptionToHost(memberOption), // welkio missing fields
        },
      },
      types,
      payloadFromJSON: camelCaseJson,
    })
  );
};

export const createPreRegisteredGuest = (
  userUuid: string,
  formValues: PreRegisterGuestFormFields,
  location: Location
) => async (dispatch: Dispatch<BaseAction>) => {
  let request;
  const { csv } = formValues;
  if (csv) {
    try {
      const file = await getWelkioCsvPath(csv);
      request = bulkUploadPreRegisteredRequest(formValues, file, userUuid, location);
    } catch (error) {
      return dispatch(notifyError('Failed to upload file to Welkio'));
    }
  } else {
    request = singlePreRegisteredRequest(formValues, location);
  }
  await dispatch(createRequestAction(request));
};

export const editGuestVisit = (
  visitId: string,
  location: Location,
  formValues: GuestFormFields
) => async (dispatch: Dispatch<BaseAction>) => {
  const { memberOption, firstName, lastName, note } = formValues;
  const types: RequestActionTypes = [
    EDIT_GUEST_VISIT,
    {
      type: EDIT_GUEST_VISIT_SUCCESS,
      meta: {
        notification: {
          message: 'Successfully updated visit!',
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: EDIT_GUEST_VISIT_FAIL,
      meta: {
        notification: {
          message: 'Failed to update visit',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  await dispatch(
    createRequestAction({
      endpoint: `${config.welkioAuthProxy.uri}/access/v2/visits/${visitId}`,
      method: 'PATCH',
      body: {
        first_name: firstName,
        last_name: lastName,
        comment: note || '',
        host_id: memberOption.uuid,
        visitId,
        location_id: location?.uuid,
      },
      meta: {
        timeZone: location?.time_zone,
        visit: {
          host: memberOptionToHost(memberOption), // welkio missing field
        },
      },
      types,
      payloadFromJSON: camelCaseJson,
    })
  );
};
