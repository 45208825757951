import React from 'react';
import { Button, Header, Text } from '@wework/dieter-ui';
import cn from 'classnames';

import config from 'config';
import spaceman from 'images/spaceman.png';

import style from './errorPage.scss';

type Props = Readonly<{
  serviceName?: string | undefined | null;
  customMessage?: string | null;
  returnPrevCompany?: {
    companyUuid: string;
  } | null;
  errorDetail?: {
    status: number | undefined | null;
    endPoint: string | undefined | null;
    xRequestId?: string | undefined | null;
  } | null;
}>;

const ErrorPage = ({ serviceName, customMessage, returnPrevCompany, errorDetail }: Props) => (
  <div className={cn('', style.errorContainer)}>
    <div className={style.block}>
      <div className={style.block}>
        <Header as="h2">Something went wrong!</Header>
      </div>
      <div className={style.block}>
        {serviceName ? (
          <Text size="medium">{`The ${serviceName} service is unavailable at this time.`}</Text>
        ) : null}
        {customMessage ? <Text size="medium">"{customMessage}"</Text> : null}
        <Text size="medium">
          Submit a support request in Salesforce Service Cloud making sure you're logged into Okta
          and try again later.
        </Text>
      </div>
      <div className={style.block}>
        <Text size="medium">
          {`Not sure how to submit a ticket? `}
          <a
            href="https://connect.weworkers.io/display/WWKB/Salesforce+Service+Cloud+-+How+To+Submit+Requests+to+the+Support+Team"
            target="blank"
            rel="noopener, noreferrer"
          >
            View instructions
          </a>
        </Text>
      </div>
      <div className={style.block}>
        <Button basic primary>
          <a href={config.salesforce.tickets.uri} target="blank" rel="noopener, noreferrer">
            Report a bug
          </a>
        </Button>
        {returnPrevCompany ? (
          <Button
            primary
            onClick={() => {
              window.location.href = `${window.location.origin}/#/companies/${returnPrevCompany?.companyUuid}`;
            }}
          >
            Return to Company page
          </Button>
        ) : null}
      </div>
      {errorDetail ? (
        <div className={style.errorInfo}>
          <Text
            className={style.Text}
            size="tiny"
          >{`EndPoint: ${errorDetail.endPoint} is returning an HTTP CODE ${errorDetail.status}.`}</Text>
          {errorDetail.xRequestId ? (
            <Text
              className={style.Text}
              size="tiny"
            >{`x-request-id: ${errorDetail.xRequestId}`}</Text>
          ) : null}
        </div>
      ) : null}
    </div>
    <img alt="" className={style.spaceman} src={spaceman} />
  </div>
);

export default ErrorPage;
