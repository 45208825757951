import {
  createConstantWithPrefix,
  createRequestConstantsWithPrefix,
} from 'store/util/createReduxConstant';

const ORGANIZATION_ACCOUNTS_PREFIX = 'organizationAccounts';

export const [
  FETCH_ORGANIZATION_ACCOUNTS,
  FETCH_ORGANIZATION_ACCOUNTS_SUCCESS,
  FETCH_ORGANIZATION_ACCOUNTS_FAIL,
] = createRequestConstantsWithPrefix('fetchOrganizationAccounts', ORGANIZATION_ACCOUNTS_PREFIX);

export const SET_ORGANIZATION_ACCOUNTS_PAGE = createConstantWithPrefix(
  'setOrganizationAccountsPage',
  ORGANIZATION_ACCOUNTS_PREFIX
);

export const SET_ORGANIZATION_ACCOUNTS_NEXT_PAGE = createConstantWithPrefix(
  'setOrganizationAccountsNextPage',
  ORGANIZATION_ACCOUNTS_PREFIX
);

export const SET_ORGANIZATION_ACCOUNTS_QUERY = createConstantWithPrefix(
  'setOrganizationAccountsQuery',
  ORGANIZATION_ACCOUNTS_PREFIX
);

export const [
  ADD_ACCOUNT_TO_ORGANIZATION,
  ADD_ACCOUNT_TO_ORGANIZATION_SUCCESS,
  ADD_ACCOUNT_TO_ORGANIZATION_FAIL,
] = createRequestConstantsWithPrefix('addAccountToOrganization', ORGANIZATION_ACCOUNTS_PREFIX);

export const [
  DELETE_ACCOUNT_FROM_ORGANIZATION,
  DELETE_ACCOUNT_FROM_ORGANIZATION_SUCCESS,
  DELETE_ACCOUNT_FROM_ORGANIZATION_FAIL,
] = createRequestConstantsWithPrefix('addAccountToOrganization', ORGANIZATION_ACCOUNTS_PREFIX);

export const CLEAR_ORGANIZATION_ACCOUNTS = createConstantWithPrefix(
  'clearOrganizationAccounts',
  ORGANIZATION_ACCOUNTS_PREFIX
);

export const CLEAR_ORGANIZATION_ACCOUNTS_STATE = createConstantWithPrefix(
  'clearOrganizationAccountsState',
  ORGANIZATION_ACCOUNTS_PREFIX
);
