import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import {
  ElevatorConfiguration,
  ElevatorConfigurationAPIResponse,
} from 'features/accessControl/accessLevels/modals/modalTypes';

export enum accessControlModals {
  CREATE_ACCESS = 'CREATE_ACCESS_MODAL',
  REMOVE_ACCESS = 'REMOVE_ACCESS_MODAL',
  CREATE_OFFICE_ACCESS = 'CREATE_OFFICE_ACCESS_MODAL',
  UPDATE_ACCESS = 'UPDATE_ACCESS_MODAL',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE_MODAL',
  EDIT_SCHEDULE = 'EDIT_SCHEDULE_MODAL',
  EDIT_FEENICS_ACCESS = 'EDIT_FEENICS_ACCESS',
  REMOVE_CUSTOM_ACCESS_LEVEL = 'REMOVE_CUSTOM_ACCESS_LEVEL',
}

export const MODALS_CONFIG = {
  createAccess: accessControlModals.CREATE_ACCESS,
  removeAccess: accessControlModals.REMOVE_ACCESS,
  createOfficeAccess: accessControlModals.CREATE_OFFICE_ACCESS,
  updateAccess: accessControlModals.UPDATE_ACCESS,
  createSchedule: accessControlModals.CREATE_SCHEDULE,
  editSchedule: accessControlModals.EDIT_SCHEDULE,
  editFeenicsAccessLevel: accessControlModals.EDIT_FEENICS_ACCESS,
  removeCustomAccessLevel: accessControlModals.REMOVE_CUSTOM_ACCESS_LEVEL,
};

export enum accessControlTabs {
  ACCESS_LEVELS = 'ACCESS_LEVELS',
  SCHEDULES = 'SCHEDULES',
}

export const accessControlTabsConfig = [
  { name: 'Access levels', activeTabName: accessControlTabs.ACCESS_LEVELS, disabledTab: false },
  { name: 'Schedules', activeTabName: accessControlTabs.SCHEDULES, disabledTab: true },
];

export const formatToMilitaryTime = (time: string) => moment(time, 'HH:mm').format('hh:mm a');

export const getDurationAsMinutes = (time: string) => moment.duration(time).asMinutes();

export const getEndScheduleTime = (startTime: string, duration: string) => {
  return moment(startTime, 'HH:mm:ss')
    .add(getDurationAsMinutes(duration), 'minutes')
    .format('HH:mm');
};

export const formatMilitaryTimeTo24HourNotation = (time: string) =>
  moment(time, 'HH:mm a').format('HH:mm:ss');

export const getDurationTimeIn24HourNotation = (startTime: string, endTime: string) => {
  return moment(endTime, 'HH:mm:ss')
    .subtract(getDurationAsMinutes(startTime), 'minutes')
    .format('HH:mm:ss');
};

export const weekdaysOrder = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const sortScheduleDays = (days: string[], daysOrder) =>
  days.sort((dayA: string, dayB: string) => {
    return daysOrder[dayA] - daysOrder[dayB];
  });

type ScheduleEntry = {
  schedule_key: string;
  schedule_name: string;
};

type ReaderEntry = {
  reader_key: string;
  reader_name: string;
};

export type AccessLevelEntries = {
  schedule: ScheduleEntry;
  readers: ReaderEntry[];
};

export const getReaderKeys = (configuration: ReaderEntry[]) =>
  configuration.map(config => config.reader_key);

export const mapAccessLevelEntriesToFormReadableData = (accessLevelConfig: AccessLevelEntries[]) =>
  accessLevelConfig.map(config => ({
    key: uuidv4(),
    scheduleName: config.schedule.schedule_key,
    readerGroupName: getReaderKeys(config.readers),
  }));

export const mapAccessLevelElevatorConfigurationToFormReadableData = (
  accessLevelElevatorsConfigurations: ElevatorConfigurationAPIResponse[]
): ElevatorConfiguration[] =>
  accessLevelElevatorsConfigurations.map(config => ({
    key: uuidv4(),
    elevatorGroupName: config.reader_key,
    elevatorAccessLevel: config.elevator_access_level_key,
  }));
