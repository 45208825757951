import { combineReducers } from 'redux';

import { RequestState } from 'store/util/createRequestReducer';
import mlsAmenities, { MLSAmenityState } from 'features/building/ducks/mlsAmenityReducer';
import buildings, { SRSBuildingState } from 'features/building/ducks/buildingReducer';

import {
  Amenity,
  LocationAmenities,
  LocationInfo,
  InventorySearchMetrics,
  Transportation,
  SavedFilter,
} from '../types';

import locations, { MLSLocationState } from './locationReducer';
import amenities from './amenities';
import inventorySearchMetrics from './inventorySearchMetricsReducer';
import locationInfos from './locationInfo';
import transportation from './transportation';
import locationAmenities from './locationAmenitiesReducer';
import mlsUuid from './mlsUuidReducer';
import savedFilter from './savedFilter';
import addressNames from './addressNameReducer';

export interface State {
  mlsUuid: string;
  buildings: SRSBuildingState;
  amenities: RequestState<{ [key: string]: Amenity } | null>;
  mlsAmenities: MLSAmenityState;
  locationAmenities: RequestState<LocationAmenities | null>;
  inventorySearchMetrics: RequestState<InventorySearchMetrics | null>;
  locationInfos: RequestState<{ [key: string]: LocationInfo } | null>;
  locations: MLSLocationState;
  transportation: RequestState<{ [key: string]: Transportation } | null>;
  savedFilter: RequestState<SavedFilter | null>;
  addressNames: RequestState<{ [key: string]: string } | null>;
}

export interface BuildingSubset {
  building: State;
}

export default combineReducers<State>({
  mlsUuid,
  buildings,
  amenities,
  mlsAmenities,
  inventorySearchMetrics,
  locationAmenities,
  locationInfos,
  locations,
  transportation,
  savedFilter,
  addressNames,
});
