import React from 'react';
import ReactModal, { Props } from 'react-modal';
import cn from 'classnames';
import { Icon, IconName, IconSize, IconType } from '@wework/ray2';

import Dimmer from 'components-ray/dimmer';

type ModalProps = {
  closeIcon?: boolean;
  closeIconClassName?: string;
} & Props;

const Modal: React.FC<ModalProps> = ({
  closeIcon = true,
  className,
  overlayClassName,
  closeIconClassName,
  children,
  ...rest
}) => {
  const dimmer = React.useCallback(
    (props, contentElement) => (
      <Dimmer {...props} active page className={cn('z-50', overlayClassName)}>
        {contentElement}
      </Dimmer>
    ),
    []
  );

  return (
    <ReactModal
      {...rest}
      className={cn(
        'overflow-y-auto bg-white shadow p-[40px] relative text-left m-lg rounded-sm',
        className
      )}
      overlayElement={dimmer}
    >
      {closeIcon && (
        <Icon
          icon={IconName.CLOSE}
          size={IconSize.SMALL}
          type={IconType.OUTLINE}
          className={cn('absolute right-sm top-sm cursor-pointer z-10', closeIconClassName)}
          onClick={rest.onRequestClose}
        />
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
