import React from 'react';
import { FormsyInjectedProps, withFormsy } from 'formsy-react';
import cn from 'classnames';
import { sortBy } from 'lodash';

import ReactSelect from 'components-dieter/base/legacy-react-select';
import formStyles from 'styles/form.scss';

import Hint from './hint';
import FormControl from './formControl';
import Label from './label';
import styles from './location.scss';

type Location = {
  code: string;
  name: string;
  uuid: string;
};

interface Props extends FormsyInjectedProps<string> {
  locations: Array<Location>;
  multiple?: boolean;
  setValue: (value: string | Array<string>) => void;
  onSelect?: (location: Location | undefined) => void;
  sort?: boolean;
  name: string;
  title?: string;
  display?: string;
  height?: string;
  disabled?: boolean;
  size?: 'tiny' | 'small';
  labelPlacement?: string;
  className?: string;
  loading?: boolean;
}

class LocationSelect extends React.Component<Props> {
  changeValue = (options): void => {
    const { locations, multiple, onSelect, setValue } = this.props;

    let value;
    if (Array.isArray(options)) {
      value = /* multiple && */ options.map(opt => opt.value);
    } else if (options && 'value' in options) {
      value = options.value;
    } else {
      value = '';
    }

    setValue(value);

    if (onSelect && !multiple) {
      onSelect(
        // this doesn't work for multi selects
        locations.find((location): boolean => location?.uuid === value)
      );
    }
  };

  className = (): string | null => {
    if (this.props.showRequired) return 'required';
    if (this.props.showError) return 'error';
    return null;
  };

  getOptions = () => {
    let options = this.props.locations
      .filter((location: Location) => Boolean(location))
      .map((location: Location) => {
        return {
          label: `${location.code} - ${location.name}`,
          value: location.uuid,
        };
      });

    if (this.props.sort) {
      options = sortBy(options, opt => opt.label);
    }

    return options;
  };

  render() {
    const {
      className,
      disabled,
      display,
      height,
      labelPlacement,
      loading,
      name,
      size,
      title,
    } = this.props;

    const errorMessage = this.props.errorMessage;
    const options = this.getOptions();

    return (
      <FormControl size={size} height={height} display={display} className={className}>
        {title && (
          <Label inline={labelPlacement === 'inline'} required={this.props.showRequired}>
            {title}
          </Label>
        )}

        <div
          data-test="location-select"
          className={cn(styles.base, size && styles[`size-${size}`], {
            [formStyles.inputModeDisabled]: disabled,
          })}
        >
          <ReactSelect
            onChange={this.changeValue}
            multi={this.props.multiple}
            disabled={disabled || this.props.isFormDisabled}
            value={this.props.value}
            options={options}
            className={name}
            isLoading={loading}
          />
        </div>
        {errorMessage && <Hint size={size}>{errorMessage}</Hint>}
      </FormControl>
    );
  }
}

export default withFormsy(LocationSelect);
