// Exports a method that creates the redux store
//
// http://redux.js.org/docs/api/createStore.html
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createTracker } from 'redux-segment';
import { apiMiddleware } from 'redux-api-middleware';

import rootReducer from 'store/modules';
import persistToLocalStorage from 'store/middlewares/persistToLocalStorage';
import toast from 'store/middlewares/toast';
import experimentsHelper from 'store/middlewares/experimentsHelper';
import requestActionMiddleware from 'store/middlewares/requestAction';
import reduxFormUndo from 'features/reduxFormUndo/middleware';

const tracker = createTracker();

export default function configureStore(initialState: {} = {}) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        requestActionMiddleware,
        apiMiddleware,
        persistToLocalStorage,
        toast,
        reduxFormUndo,
        experimentsHelper,
        tracker
      )
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('store/modules', () => {
      const nextRootReducer = require('store/modules'); // eslint-disable-line global-require, @typescript-eslint/no-var-requires
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
