import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_CONTRACTS,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAIL,
] = createRequestConstantNames(cc('FETCH_CONTRACTS'));

export const [
  FETCH_CONTRACTS_BY_LOCATION,
  FETCH_CONTRACTS_BY_LOCATION_SUCCESS,
  FETCH_CONTRACTS_BY_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_CONTRACTS_BY_LOCATION'));
