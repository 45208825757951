import { findIndex } from 'lodash';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import config from 'config';
import createReduxConstant from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';
import { createRequestAction } from 'store/util';
import {
  UPDATE_SEVERITIES,
  UPDATE_SEVERITY_LOW,
  UPDATE_SEVERITY_MEDIUM,
  UPDATE_SEVERITY_HIGH,
} from 'lib/updateSeverities';

// TODO(grozki): Figure out why $Values<typeof UPDATE_SEVERITIES> won't work. https://github.com/facebook/flow/issues/961.
type Severity =
  | typeof UPDATE_SEVERITY_LOW
  | typeof UPDATE_SEVERITY_MEDIUM
  | typeof UPDATE_SEVERITY_HIGH;

type Version = {
  commit: string;
  date: string;
  severity: Severity;
};

export interface VersionState {
  appInitTime: Date;
  currentBranch: string;
  currentVersion: string;
  latestBranch: string;
  latestVersion: string;
  latestVersionDate: string | null | undefined;
  severity: Severity;
}

export interface VersionSubset {
  version: VersionState;
}

// Action Constants

export const [
  FETCH_APP_VERSION_INFO,
  FETCH_APP_VERSION_INFO_SUCCESS,
  FETCH_APP_VERSION_INFO_FAIL,
] = createRequestConstantNames('FETCH_APP_VERSION_INFO').map(createReduxConstant);

// Helper functions
function maxSeverity(severities: Array<Severity>): Severity {
  if (!severities.length) {
    return UPDATE_SEVERITY_LOW;
  }

  const severityIndices = severities.map(severity => UPDATE_SEVERITIES.indexOf(severity));

  const maxSeverityIndex = Math.max.apply(null, severityIndices);

  return UPDATE_SEVERITIES[maxSeverityIndex] as Severity;
}

export function determineSeverity(versions: Array<Version>, currentVersion: string): Severity {
  const index = findIndex(versions, { commit: currentVersion });

  if (index === -1) {
    // Choose the highest severity in the list, with medium as the minimum.
    return maxSeverity(versions.map(ver => ver.severity).concat(UPDATE_SEVERITY_MEDIUM));
  }

  const versionDiff = versions.slice(0, index).filter(ver => ver.commit !== currentVersion);

  return maxSeverity(versionDiff.map(ver => ver.severity));
}

// Initial State
export const initialState: VersionState = {
  appInitTime: new Date(),
  currentBranch: config.branch,
  currentVersion: config.version, // We use this here so it's easier to modify in tests.
  latestBranch: config.branch,
  latestVersion: config.version,
  latestVersionDate: null,
  severity: UPDATE_SEVERITY_LOW,
};

// Reducer
export const reducer = handleActions<VersionState, any>(
  {
    [FETCH_APP_VERSION_INFO_SUCCESS](state, { payload }) {
      const versions = payload?.versions || [];
      return {
        ...state,
        latestBranch: payload?.branch ?? '',
        latestVersion: payload?.github_version ?? state.latestVersion,
        latestVersionDate: versions.length && versions[0].date,
        severity: determineSeverity(versions, state.currentVersion),
      };
    },
  },
  initialState
);

// Action Creators
export const fetchAppVersionInfo = () =>
  createRequestAction({
    endpoint: '/version.json',
    types: [FETCH_APP_VERSION_INFO, FETCH_APP_VERSION_INFO_SUCCESS, FETCH_APP_VERSION_INFO_FAIL],
  });

// Selectors
export const getVersionState = (state: VersionSubset): VersionState => state.version;

export const getAppInitTime = createSelector(getVersionState, version => version.appInitTime);

export const getCurrentBranch = createSelector(getVersionState, version => version.currentBranch);
export const getCurrentVersion = createSelector(getVersionState, version => version.currentVersion);
export const getLatestBranch = createSelector(getVersionState, version => version.latestBranch);
export const getLatestVersion = createSelector(getVersionState, version => version.latestVersion);

export const getLatestVersionDate = createSelector(
  getVersionState,
  version => version.latestVersionDate
);

export const getVersionUpdateSeverity = createSelector(
  getVersionState,
  version => version.severity
);

export const hasBranchChanged = createSelector(
  [getCurrentBranch, getLatestBranch],
  (currentBranch, latestBranch) => latestBranch && latestBranch !== currentBranch
);

export const hasNewVersion = createSelector(
  [getCurrentVersion, getLatestVersion],
  (currentVersion, latestVersion) => currentVersion !== latestVersion
);

export const hasNewVersionMediumSeverity = createSelector(
  [hasNewVersion, getVersionUpdateSeverity, hasBranchChanged],
  (hasNewVersion, severity, hasBranchChanged) =>
    (hasNewVersion && severity === UPDATE_SEVERITY_MEDIUM) || hasBranchChanged
);

export const hasNewVersionHighSeverity = createSelector(
  [hasNewVersion, getVersionUpdateSeverity],
  (hasNewVersion, severity) => hasNewVersion && severity === UPDATE_SEVERITY_HIGH
);

export default reducer;
