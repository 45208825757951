export enum FORM_FIELDS {
  MEMBER_DATA = 'memberData',
  BOOKING_DATE = 'bookingDate',
}

export const EMPTY_STATE = '-';

export const FORM_NAME = 'bookWorkspaceModal';

export const NO_MEMBER_TOOLTIP = 'No data available for this member';
export const NO_DESK_TOOLTIP = 'No desks available for this member';

export const hints = {
  [FORM_FIELDS.MEMBER_DATA]: 'You must enter the name of a member',
  [FORM_FIELDS.BOOKING_DATE]: 'You must select a date',
};

export const PRODUCT_TYPES = {
  DAILY_PASS: 'Day Pass',
  WORKSPACE: 'Desk',
};

export const RESERVABLE_TYPES = {
  DAILY_DESK: 'DailyDesk',
};

export const DAILY_PASS_PRODUCT_UUID = 'e92cd28c-307a-47cd-a444-f7bf656580b1';

export const STEP_INDICES = {
  FORM_STEP: 0,
  CONFIRMATION_STEP: 1,
};

export const DEFAULT_MODAL_DATA = {
  bookingAccount: EMPTY_STATE,
  capacity: 0,
  confirmedDate: '',
  memberData: null,
  price: EMPTY_STATE,
  productType: EMPTY_STATE,
  seatsUsed: 0,
  reservableData: null,
  disableSubmit: true,
  loading: false,
  stepIndex: STEP_INDICES.FORM_STEP,
  bookingDate: null,
  quoteUuid: '',
  typeOfError: '',
};
