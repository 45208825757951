import { handleActions, Action } from 'redux-actions';

import { AlgoliaSecureKeyResponse } from 'features/auth/types';
import { GET_EMPLOYEE_SUCCESS } from 'features/employees/redux/employees/constants';
import { ErrorContainer } from 'store/errors';

import {
  AUTHORIZE,
  AUTHORIZE_FAIL,
  AUTHORIZE_SUCCESS,
  GET_ALGOLIA_SECURED_KEY_SUCCESS,
  UPDATE_USER_INFO,
} from './constants';

export interface UserInfo {
  uuid?: string;
  fullName?: string;
  email?: string;
  /**
   * @deprecated Only fullName should be used.
   */
  firstName?: string;
  /**
   * @deprecated Only fullName should be used.
   */
  lastName?: string;
  /**
   * @deprecated Employees should not be assumed to have an associated User/Member uuid.
   */
  userUuid: string;
  /**
   * @deprecated Field going away soon.
   */
  locationUuid?: string;
}

export interface State {
  showTroubleshootCaption: boolean;
  data: {
    idToken: string | null | undefined;
    accessToken: string | null | undefined;
    userInfo: UserInfo;
    createdAt: Date | null | undefined;
    algolia: {
      securedKey: string | null;
      validUntil: number | null;
    };
    gmaps: {
      token: string | null | undefined;
    };
  };
  spacestationSession: {
    refreshing: boolean;
    refreshed: boolean;
    error: boolean | null | undefined;
  };
  error: ErrorContainer | null | undefined;
}

export interface AuthSubset {
  auth: State;
}

// Initial State
export const initialState: State = {
  showTroubleshootCaption: false,
  data: {
    idToken: null,
    accessToken: null,
    userInfo: {
      email: undefined,
      uuid: undefined,
      locationUuid: undefined,
      firstName: undefined,
      lastName: undefined,
      fullName: undefined,
      userUuid: '',
    },
    algolia: {
      securedKey: null,
      validUntil: null,
    },
    gmaps: {
      token: null,
    },
    createdAt: null,
  },
  spacestationSession: {
    refreshing: false,
    refreshed: false,
    error: null,
  },
  error: null,
};

// Reducer
export const reducer = handleActions<State, any, { showTroubleshootCaption: boolean }>(
  {
    [AUTHORIZE]: state => ({
      ...state,
      error: null,
    }),

    [AUTHORIZE_SUCCESS]: state => ({
      ...state,
      data: {
        ...state.data,
        createdAt: new Date(),
      },
      error: null,
    }),

    [AUTHORIZE_FAIL]: (state, action) => ({
      ...state,
      error: {
        status: action.payload.status,
        message: action.payload.message,
        type: action.payload.type,
        url: action.payload.response?.url,
      },
      showTroubleshootCaption: action.meta && action.meta.showTroubleshootCaption,
    }),

    [GET_ALGOLIA_SECURED_KEY_SUCCESS]: (
      state: State,
      action: Action<AlgoliaSecureKeyResponse>
    ): State => ({
      ...state,
      data: {
        ...state.data,
        algolia: {
          securedKey: action.payload.key,
          validUntil: action.payload.validUntil,
        },
      },
    }),

    [GET_EMPLOYEE_SUCCESS]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        userInfo: action.payload,
      },
    }),

    [UPDATE_USER_INFO]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        userInfo: {
          ...state.data.userInfo,
          ...action.payload,
        },
      },
    }),
  },
  initialState
);

export default reducer;
