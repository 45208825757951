import qs from 'query-string';
import { handleActions } from 'redux-actions';

import config from 'config';
import { BaseAction, Dispatch } from 'store/types';
import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';
import { OfficeHold } from 'features/inventory/types';

// HOLDS ORIGINS
export const HOLD_ORIGIN_INVENTORY = 'spacestation_inventory_search';
export const HOLD_ORIGIN_PAPERWORK = 'spacestation_paperwork_creation';
export const HOLD_ORIGIN_DEAL = 'spacestation_deal_association';

// Action Constants
export const CREATE_HOLDS = cc('CREATE_HOLDS');
export const CREATE_HOLDS_SUCCESS = cc('CREATE_HOLDS_SUCCESS');
export const CREATE_HOLDS_FAIL = cc('CREATE_HOLDS_FAIL');

export const UPDATE_HOLDS = cc('UPDATE_HOLDS');
export const UPDATE_HOLDS_SUCCESS = cc('UPDATE_HOLDS_SUCCESS');
export const UPDATE_HOLDS_FAIL = cc('UPDATE_HOLDS_FAIL');

export const REMOVE_HOLDS = cc('REMOVE_HOLDS');
export const REMOVE_HOLDS_SUCCESS = cc('REMOVE_HOLDS_SUCCESS');
export const REMOVE_HOLDS_FAIL = cc('REMOVE_HOLDS_FAIL');

export const BULK_REMOVE_HOLDS = cc('BULK_REMOVE_HOLDS');
export const BULK_REMOVE_HOLDS_SUCCESS = cc('BULK_REMOVE_HOLDS_SUCCESS');
export const BULK_REMOVE_HOLDS_FAIL = cc('BULK_REMOVE_HOLDS_FAIL');

export const FETCH_HOLDS_BY_LOCATION = cc('FETCH_HOLDS_BY_LOCATION');
export const FETCH_HOLDS_BY_LOCATION_SUCCESS = cc('FETCH_HOLDS_BY_LOCATION_SUCCESS');
export const FETCH_HOLDS_BY_LOCATION_FAIL = cc('FETCH_HOLDS_BY_LOCATION_FAIL');

export const FETCH_HOLDS_BY_COMPANY = cc('FETCH_HOLDS_BY_COMPANY');
export const FETCH_HOLDS_BY_COMPANY_SUCCESS = cc('FETCH_HOLDS_BY_COMPANY_SUCCESS');
export const FETCH_HOLDS_BY_COMPANY_FAIL = cc('FETCH_HOLDS_BY_COMPANY_FAIL');

export const FETCH_HOLDS_BY_RESERVABLE_UUIDS = cc('FETCH_HOLDS_BY_RESERVABLE_UUIDS');
export const FETCH_HOLDS_BY_RESERVABLE_UUIDS_SUCCESS = cc(
  'FETCH_HOLDS_BY_RESERVABLE_UUIDS_SUCCESS'
);
export const FETCH_HOLDS_BY_RESERVABLE_UUIDS_FAIL = cc('FETCH_HOLDS_BY_RESERVABLE_UUIDS_FAIL');

export interface State {
  dataByLocationUuid: {
    [uuid: string]: OfficeHold;
  };
  updating: boolean;
  removed: boolean;
  data: Array<OfficeHold>;
  created: boolean;
  creating: boolean;
  page: number;
  // TODO: Type this.
  error: any | null;
  updated: boolean;
  removing: boolean;
}

export interface OfficeHoldsSubset {
  officeHolds: State;
}

// Initial State
const initialState: State = {
  created: false,
  creating: false,
  removed: false,
  removing: false,
  updated: false,
  updating: false,
  error: null,
  page: 0,
  data: [],
  dataByLocationUuid: {},
};

// Reducer
export const reducer = handleActions<State, any, { locationUuid: string }>(
  {
    [CREATE_HOLDS]: state => ({ ...state, creating: true, created: false }),
    [CREATE_HOLDS_SUCCESS]: state => ({
      ...state,
      created: true,
      creating: false,
      error: null,
    }),
    [CREATE_HOLDS_FAIL]: (state, action) => ({
      ...state,
      creating: false,
      created: false,
      error: action.payload,
    }),

    [UPDATE_HOLDS]: state => ({ ...state, updating: true, updated: false }),
    [UPDATE_HOLDS_SUCCESS]: state => ({
      ...state,
      updated: true,
      updating: false,
      error: null,
    }),
    [UPDATE_HOLDS_FAIL]: (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.payload,
    }),

    [REMOVE_HOLDS]: state => ({ ...state, removing: true, removed: false }),
    [REMOVE_HOLDS_SUCCESS]: state => ({
      ...state,
      removed: true,
      removing: false,
      error: null,
    }),
    [REMOVE_HOLDS_FAIL]: (state, action) => ({
      ...state,
      removing: false,
      removed: false,
      error: action.payload,
    }),

    [BULK_REMOVE_HOLDS]: state => ({ ...state, removing: true, removed: false }),
    [BULK_REMOVE_HOLDS_SUCCESS]: state => ({
      ...state,
      removed: true,
      removing: false,
      error: null,
    }),
    [BULK_REMOVE_HOLDS_FAIL]: (state, action) => ({
      ...state,
      removing: false,
      removed: false,
      error: action.payload,
    }),

    [FETCH_HOLDS_BY_LOCATION]: state => ({ ...state, loading: true, loaded: false }),
    [FETCH_HOLDS_BY_LOCATION_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      data: action.payload.office_holds,
      dataByLocationUuid: {
        ...state.dataByLocationUuid,
        [action.meta.locationUuid]: action.payload.office_holds,
      },
    }),
    [FETCH_HOLDS_BY_LOCATION_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),

    [FETCH_HOLDS_BY_COMPANY]: state => ({ ...state, loading: true, loaded: false }),
    [FETCH_HOLDS_BY_COMPANY_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      data: action.payload.office_holds,
    }),
    [FETCH_HOLDS_BY_COMPANY_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),

    [FETCH_HOLDS_BY_RESERVABLE_UUIDS]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_HOLDS_BY_RESERVABLE_UUIDS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      data: action.payload.office_holds,
    }),
    [FETCH_HOLDS_BY_RESERVABLE_UUIDS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

// Action Creators
export const createHold = (params: Hash<any>) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/office_holds`,
    method: 'POST',
    body: params,
    types: [CREATE_HOLDS, CREATE_HOLDS_SUCCESS, CREATE_HOLDS_FAIL],
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
  return dispatch(requestAction);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateHold = (params: { id: string; [key: string]: any }) => (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/office_holds/${params.id}`,
    method: 'PUT',
    body: params,
    types: [UPDATE_HOLDS, UPDATE_HOLDS_SUCCESS, UPDATE_HOLDS_FAIL],
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
  return dispatch(requestAction);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeHold = (id: string) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/office_holds/${id}/remove`,
    method: 'POST',
    types: [REMOVE_HOLDS, REMOVE_HOLDS_SUCCESS, REMOVE_HOLDS_FAIL],
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
  return dispatch(requestAction);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const bulkRemoveHolds = (params: Hash<any>) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/office_holds/bulk_remove`,
    method: 'POST',
    body: params,
    types: [REMOVE_HOLDS, REMOVE_HOLDS_SUCCESS, REMOVE_HOLDS_FAIL],
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
  return dispatch(requestAction);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchHoldsByLocationUuid = (locationUuid: string) => (
  dispatch: Dispatch<BaseAction>
) => {
  const params = qs.stringify({
    building_id: locationUuid,
    include_opportunity_data: true,
  });
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/office_holds/fetch_active_by_building?${params}`,
    types: [FETCH_HOLDS_BY_LOCATION, FETCH_HOLDS_BY_LOCATION_SUCCESS, FETCH_HOLDS_BY_LOCATION_FAIL],
    meta: { locationUuid },
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
  return dispatch(requestAction);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchHoldsByCompanyUuid = (companyUuid: string) => (
  dispatch: Dispatch<BaseAction>
) => {
  const params = qs.stringify({
    company_uuid: companyUuid,
  });
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/office_holds/fetch_active_by_company?${params}`,
    types: [FETCH_HOLDS_BY_COMPANY, FETCH_HOLDS_BY_COMPANY_SUCCESS, FETCH_HOLDS_BY_COMPANY_FAIL],
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
  return dispatch(requestAction);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchHoldsByReservableUuids = (reservableUuids: Array<string>) => (
  dispatch: Dispatch<BaseAction>
) => {
  const params = qs.stringify(
    {
      reservable_uuids: reservableUuids,
      include_opportunity_data: true,
    },
    { arrayFormat: 'bracket' }
  );

  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v1/office_holds/fetch_active_by_reservable_uuids?${params}`,
    types: [
      FETCH_HOLDS_BY_RESERVABLE_UUIDS,
      FETCH_HOLDS_BY_RESERVABLE_UUIDS_SUCCESS,
      FETCH_HOLDS_BY_RESERVABLE_UUIDS_FAIL,
    ],
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
  return dispatch(requestAction);
};

export default reducer;
