import {
  isBefore,
  parseISO,
  formatISO,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  addMonths,
  isAfter,
  startOfMonth,
  endOfMonth,
  format,
  isValid,
  addDays,
} from 'date-fns';
import { property } from 'lodash';

import { dateFormats } from 'lib/constants';

export const byDateProp = (propPath: string) => (prev, next) => {
  const getDate = property(propPath);
  const prevDate = String(getDate(prev));
  const nextDate = String(getDate(next));
  if (isBefore(parseISO(prevDate), parseISO(nextDate))) {
    return -1;
  }
  if (isBefore(parseISO(nextDate), parseISO(prevDate))) {
    return 1;
  }
  return 0;
};

export const getProductItemWithIndices = (state, productItemId, discountKey) => {
  const idx = state.items.findIndex(
    ({ productReservation }) => productReservation.id === productItemId
  );

  const productItem = state.items[idx];

  let discountIdx = productItem.discounts.findIndex(({ key }) => key === discountKey);

  if (discountIdx < 0) {
    discountIdx = productItem.discounts.length;
  }

  return {
    idx,
    discountIdx,
    productItem,
  };
};

export const formatToISODate = date =>
  isValid(date) ? formatISO(date, { representation: 'date' }) : date;

export const formatToUSAWithSlashes = date =>
  isValid(date) ? format(date, dateFormats.usa_with_slashes) : date;

export const firstStartOfMonth = date =>
  isFirstDayOfMonth(parseISO(date))
    ? date
    : formatToISODate(startOfMonth(addMonths(parseISO(date), 1)));

export interface IDateRange {
  startDate: string;
  endDate: string;
}

export const isProrated = (startDate: string, endDate: string) =>
  startDate && endDate && !isFirstDayOfMonth(parseISO(startDate)) && startDate === endDate;

export const shiftDateRangesBy = (ranges: Array<IDateRange>, days: number): Array<IDateRange> =>
  ranges.map((range: IDateRange) => {
    const shiftedStart = addDays(parseISO(range.startDate), days);
    const shiftedEnd = addDays(parseISO(range.endDate), days);
    let nextStart;
    let nextEnd;

    if (days > 0) {
      nextStart = startOfMonth(shiftedStart);
      nextEnd = isLastDayOfMonth(shiftedEnd) ? shiftedEnd : endOfMonth(addMonths(shiftedEnd, -1));
    } else {
      nextStart = isFirstDayOfMonth(shiftedStart)
        ? shiftedStart
        : startOfMonth(addMonths(shiftedStart, 1));
      nextEnd = endOfMonth(addDays(parseISO(range.endDate), days));
    }
    return {
      ...range,
      startDate: formatToISODate(nextStart),
      endDate: formatToISODate(nextEnd),
    };
  });

export const isBetween = (date: string, start: string, end: string) =>
  !isBefore(parseISO(date), parseISO(start)) && !isAfter(parseISO(date), parseISO(end));
