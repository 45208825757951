import { get, isNil } from 'lodash';

import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export const numericSimpleSerializer = (
  inventoryPayloadItem: InventoryPayloadItem,
  serializerKey: string
): InventoryAttribute | null | undefined => {
  const attribute = get(inventoryPayloadItem, serializerKey);

  if (isNil(attribute)) {
    return null;
  }

  const value = new Intl.NumberFormat().format(attribute);

  return { value, meta: null };
};

export default numericSimpleSerializer;
