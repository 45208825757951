// https://github.com/WeConnect/spacestation-microsite-poc
export const MICROSITE_POC = {
  exact: false,
  label: 'Microsite',
  path: '/microsite',
};

// https://github.com/WeConnect/spacestation-microsite-operator-ui
export const OPERATORS = {
  exact: false,
  label: 'Operators',
  path: '/admin/operators',
};
export const ROLES_AND_PERMISSIONS = {
  exact: false,
  label: 'Roles & Permissions',
  path: '/admin/roles-and-permissions',
};
export const EMPLOYEES = {
  exact: false,
  label: 'Employees',
  path: '/admin/employees',
};
export const CONTRACTORS = {
  exact: false,
  label: 'Contractors',
  path: '/admin/contractors',
};
export const SSO_CONFIGS = {
  exact: false,
  label: 'SSO Configs',
  path: '/admin/sso-configs',
};

export const ACCESS_LEVEL_GROUPS = {
  exact: false,
  label: 'Access Level Groups',
  path: '/access-level-groups',
};

export const ID_SCIM = {
  exact: false,
  label: 'ID SCIM',
  path: '/admin/scim-tenants',
};

export const ACCOUNT_SERVICE_DNS_VERIFICATIONS = {
  exact: false,
  label: 'Account DNS Verifications',
  path: '/admin/account-dns-verifications',
};

export const SERVICE_CLIENTS = {
  exact: false,
  label: 'Service Auth Clients',
  path: '/admin/service-clients',
};

export const SERVICE_CLIENT_PERMISSIONS = {
  exact: false,
  label: 'Service Auth Permissions',
  path: '/admin/service-permissions',
};

// https://github.com/WeConnect/spacestation-microsite-3d-floor-plan
export const FLOOR_PLAN_3D = {
  exact: false,
  label: 'Floor Plans',
  path: '/floor-plans',
};

export const MICROFRONTEND_APP_TEMPLATE = {
  exact: false,
  label: 'Micro-frontend Template',
  path: '/template',
};

export const APPS_ROUTES = {
  '@wework/micro-frontend-app-template': [MICROFRONTEND_APP_TEMPLATE],
  '@wework/spacestation-microsite-poc': [MICROSITE_POC],
  '@wework/spacestation-microsite-operator-ui': [
    ACCOUNT_SERVICE_DNS_VERIFICATIONS,
    CONTRACTORS,
    EMPLOYEES,
    ID_SCIM,
    OPERATORS,
    ROLES_AND_PERMISSIONS,
    SERVICE_CLIENTS,
    SERVICE_CLIENT_PERMISSIONS,
    SSO_CONFIGS,
  ],
  '@wework/spacestation-microsite-3d-floor-plan': [FLOOR_PLAN_3D],
};

export const ROUTES = Object.values(APPS_ROUTES).flat();
