import { handleActions, createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

import { AddonField } from 'features/paperwork/ducks/types';
import cc from 'store/util/createReduxConstant';

// Action constants
export const SAVE_ADDONS = cc('paperwork/addons/SAVE_ADDONS');
export const CLEAR_ADDONS = cc('paperwork/addons/CLEAR_ADDONS');

export interface State {
  locationUuid: string | null | undefined;
  moveInDate: string | null | undefined;
  addonFields: Array<AddonField>;
  inProgress: boolean;
}

const initialState: State = {
  locationUuid: null,
  moveInDate: null,
  addonFields: [],
  inProgress: false,
};

type SaveAddonsActionType = {
  addonFields: Array<AddonField>;
  moveInDate: string;
  locationUuid: string;
};

export type Addons = State;

export const saveAddons = createAction<SaveAddonsActionType>(SAVE_ADDONS);
export const clearAddons = createAction(CLEAR_ADDONS);

export const reducer = handleActions<State, any>(
  {
    [SAVE_ADDONS]: (_state: State, action) => {
      const formValues = action.payload;
      const inProgress = !isEmpty(formValues.addonFields);

      return inProgress ? { ...action.payload, inProgress } : initialState;
    },

    [CLEAR_ADDONS]: () => ({ ...initialState }),
  },
  initialState
);

export default reducer;
