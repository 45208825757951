import { handleActions, ActionMeta } from 'redux-actions';
import { map, reduce, filter, uniq, keyBy } from 'lodash';

import {
  FETCH_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  FETCH_EMAIL_TEMPLATES_FAIL,
  CREATE_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_FAIL,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
} from 'features/mimo/redux/emailTemplates/constants';
import {
  State,
  EmailTemplateTypeMeta,
  EmailTemplateType,
} from 'features/mimo/redux/emailTemplates/types';

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  submitting: false,
  submitted: false,
  byLocationCode: {},
  error: false,
};

function keyByTypeAndLocale(templates: EmailTemplateType[]) {
  // keyBy only returns for the last occurance of 'type'
  // since we will have multiple objects with the same template type
  // we must define our own keyby implementation

  const uniqTemplateTypes = uniq(map(templates, 'type'));
  return reduce(
    uniqTemplateTypes,
    (result: {}, templateType: string) => ({
      ...result,
      [templateType]: keyBy(filter(templates, { type: templateType }), 'locale'),
    }),
    {}
  );
}

export const reducer = handleActions<
  State,
  EmailTemplateType[] | EmailTemplateType,
  EmailTemplateTypeMeta
>(
  {
    [FETCH_EMAIL_TEMPLATES]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: false,
    }),

    [FETCH_EMAIL_TEMPLATES_SUCCESS]: (
      state,
      action: ActionMeta<EmailTemplateType[], EmailTemplateTypeMeta>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      byLocationCode: {
        ...state.byLocationCode,
        [action.meta.params.locationCode]: keyByTypeAndLocale(action.payload),
      },
    }),

    [FETCH_EMAIL_TEMPLATES_FAIL]: state => ({
      ...state,
      loading: false,
      loaded: false,
      error: true,
    }),

    [CREATE_EMAIL_TEMPLATE]: state => ({
      ...state,
      submitting: true,
      submitted: false,
      error: false,
    }),

    [CREATE_EMAIL_TEMPLATE_SUCCESS]: (
      state,
      action: ActionMeta<EmailTemplateType, EmailTemplateTypeMeta>
    ) => ({
      ...state,
      submitting: false,
      submitted: true,
      byLocationCode: {
        ...state.byLocationCode,
        [action.meta.params.locationCode]: {
          ...(state.byLocationCode[action.meta.params.locationCode] || {}),
          [action.payload.type]: {
            ...((state.byLocationCode[action.meta.params.locationCode] || {})[
              action.payload.type
            ] || {}),
            [action.payload.locale]: action.payload,
          },
        },
      },
      error: false,
    }),

    [CREATE_EMAIL_TEMPLATE_FAIL]: state => ({
      ...state,
      submitting: false,
      submitted: false,
      error: true,
    }),

    [UPDATE_EMAIL_TEMPLATE]: state => ({
      ...state,
      submitting: true,
      submitted: false,
      error: false,
    }),

    [UPDATE_EMAIL_TEMPLATE_SUCCESS]: (
      state: State,
      action: ActionMeta<EmailTemplateType, EmailTemplateTypeMeta>
    ): State => ({
      ...state,
      submitting: false,
      submitted: true,
      byLocationCode: {
        ...state.byLocationCode,
        [action.meta.params.locationCode]: {
          ...(state.byLocationCode[action.meta.params.locationCode] || {}),
          [action.payload.type]: {
            ...((state.byLocationCode[action.meta.params.locationCode] || {})[
              action.payload.type
            ] || {}),
            [action.payload.locale]: action.payload,
          },
        },
      },
      error: false,
    }),
  },
  initialState
);

export default reducer;
