import { handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';
import { UserGroupState } from 'store/modules/userGroup/types';
import { UserGroup } from 'store/modules/userGroups/types';
import {
  FETCH_USER_GROUP,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAIL,
  UPDATE_USER_USER_GROUP,
  UPDATE_USER_USER_GROUP_SUCCESS,
  UPDATE_USER_USER_GROUP_FAIL,
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAIL,
  DELETE_USER_GROUP,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAIL,
  ADD_USERS_USER_GROUP,
  ADD_USERS_USER_GROUP_SUCCESS,
  ADD_USERS_USER_GROUP_FAIL,
} from 'store/modules/userGroup/constants';

const initialUserGroupState: UserGroupState = {
  userGroup: undefined,
  userGroupLoading: false,
};

const userGroupReducer = handleActions<UserGroupState, UserGroup>(
  {
    [FETCH_USER_GROUP]: (state: UserGroupState) => ({
      ...state,
      userGroupLoading: true,
    }),
    [FETCH_USER_GROUP_SUCCESS]: (state: UserGroupState, action: ActionWithPayload<UserGroup>) => ({
      ...state,
      userGroup: action.payload,
      userGroupLoading: false,
    }),
    [FETCH_USER_GROUP_FAIL]: (state: UserGroupState) => ({
      ...state,
      userGroupLoading: false,
    }),
    [UPDATE_USER_USER_GROUP]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: true,
    }),
    [UPDATE_USER_USER_GROUP_SUCCESS]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
    [UPDATE_USER_USER_GROUP_FAIL]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
    [UPDATE_USER_GROUP]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: true,
    }),
    [UPDATE_USER_GROUP_SUCCESS]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
    [UPDATE_USER_GROUP_FAIL]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
    [DELETE_USER_GROUP]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: true,
    }),
    [DELETE_USER_GROUP_SUCCESS]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
    [DELETE_USER_GROUP_FAIL]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
    [ADD_USERS_USER_GROUP]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: true,
    }),
    [ADD_USERS_USER_GROUP_SUCCESS]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
    [ADD_USERS_USER_GROUP_FAIL]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
  },
  initialUserGroupState
);

export default userGroupReducer;
