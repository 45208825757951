import { BaseAction, Dispatch } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import cc from 'store/util/createReduxConstant';
import { SavedFilter } from 'features/building/types';
import createRequestAction from 'store/util/createRequestAction';
import config from 'config';

// Action Constants
export const FETCH_WW_SAVED_FILTER = cc('FETCH_WW_SAVED_FILTER');
export const FETCH_WW_SAVED_FILTER_SUCCESS = cc('FETCH_WW_SAVED_FILTER_SUCCESS');
export const FETCH_WW_SAVED_FILTER_FAIL = cc('FETCH_WW_SAVED_FILTER_FAIL');

export const fetchSavedFilter = () => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction<SavedFilter>({
    method: 'GET',
    endpoint: `${config.inventoryQuery.uri}/v1/userfilters`,
    types: [
      {
        type: FETCH_WW_SAVED_FILTER,
        meta: { keepCurrentResult: true },
      },
      FETCH_WW_SAVED_FILTER_SUCCESS,
      FETCH_WW_SAVED_FILTER_FAIL,
    ],
  });

  return dispatch(requestAction);
};

export const updateSavedFilter = (savedFilter: { locationUuids: string[] }) => (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction<SavedFilter>({
    method: 'POST',
    endpoint: `${config.inventoryQuery.uri}/v1/userfilters`,
    types: [
      {
        type: FETCH_WW_SAVED_FILTER,
        meta: { keepCurrentResult: true },
      },
      FETCH_WW_SAVED_FILTER_SUCCESS,
      FETCH_WW_SAVED_FILTER_FAIL,
    ],
    body: { filters: savedFilter },
  });

  return dispatch(requestAction);
};

const reducer = createRequestReducer<SavedFilter | null, SavedFilter | undefined>(
  [FETCH_WW_SAVED_FILTER, FETCH_WW_SAVED_FILTER_SUCCESS, FETCH_WW_SAVED_FILTER_FAIL],
  null,
  res => res.payload || null
);

export default reducer;
