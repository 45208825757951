import { FilterDisplay } from '../types';

import { FilterKey, FilterType } from './types';

export const FiltersDisplayConfig: Array<FilterDisplay> = [
  {
    key: FilterKey.BoundingBox,
    displayName: 'Location',
    type: FilterType.Location,
    placeholder: null,
    index: 0,
    advancedPanel: false,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.FuzzyCapacity,
    displayName: 'Work Units',
    type: FilterType.Range,
    placeholder: '',
    index: 1,
    advancedPanel: false,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.StartDate,
    displayName: 'Move-In Date',
    placeholder: null,
    type: FilterType.Date,
    index: 2,
    advancedPanel: false,
    disabledText: null,
    tooltip: null,
  },

  // Advanced Filters
  {
    key: FilterKey.Availability,
    displayName: 'Availability',
    type: FilterType.SingleSelect,
    placeholder: null,
    index: 3,
    advancedPanel: false,
    disabledText: null,
    tooltip: null,
  },

  // Advanced Filters
  {
    key: FilterKey.Gates,
    displayName: 'Gate',
    placeholder: null,
    type: FilterType.MultiSelect,
    index: 0,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.LocationStatus,
    displayName: 'Location Status',
    placeholder: null,
    type: FilterType.MultiSelect,
    index: 0,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.ProductTypes,
    displayName: 'Product Types',
    placeholder: null,
    type: FilterType.MultiSelect,
    index: 1,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.Status,
    displayName: 'Status',
    placeholder: null,
    type: FilterType.MultiSelect,
    index: 2,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.LocationUuids,
    displayName: 'Building',
    placeholder: 'Select building',
    type: FilterType.MultiSelectDropdown,
    index: 3,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.Attributes,
    displayName: 'Attributes',
    placeholder: null,
    type: FilterType.MultiSelect,
    index: 4,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.MaxPrice,
    displayName: 'Price',
    type: FilterType.Number,
    placeholder: '$ Maximum',
    index: 5,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
  {
    key: FilterKey.MinSquareFootage,
    displayName: 'Min Sq. Ft.',
    placeholder: 'Minimum',
    type: FilterType.Number,
    index: 6,
    advancedPanel: true,
    disabledText: null,
    tooltip: 'For full floor inventory, square footage refers to RSF',
  },
  {
    key: FilterKey.MaxSquareFootage,
    displayName: 'Max Sq. Ft.',
    placeholder: 'Maximum',
    type: FilterType.Number,
    index: 7,
    advancedPanel: true,
    disabledText: null,
    tooltip: 'For full floor inventory, square footage refers to RSF',
  },
  {
    key: FilterKey.SearchGroups,
    displayName: 'Grouping',
    placeholder: null,
    type: FilterType.Toggle,
    index: 8,
    advancedPanel: true,
    disabledText: null,
    tooltip: null,
  },
];

export default FiltersDisplayConfig;
