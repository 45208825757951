import { BaseAction, Dispatch } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';
import config from 'config';

import { CommunityPayloadItem } from '../config/types';
import { CommunityByBuilding } from '../types';

// Action Constants
export const FETCH_COMMUNITY = cc('FETCH_COMMUNITY');
export const FETCH_COMMUNITY_SUCCESS = cc('FETCH_COMMUNITY_SUCCESS');
export const FETCH_COMMUNITY_FAIL = cc('FETCH_COMMUNITY_FAIL');

export const getSerializedCommunity = (
  previous: CommunityByBuilding,
  rawPayload: { building: CommunityPayloadItem }
): CommunityByBuilding => {
  const building = rawPayload?.building;

  if (!building) {
    return previous;
  }

  return {
    ...previous,
    [building.id]: building.team_members,
  };
};

export const fetchCommunity = (locationUuid: string) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    endpoint: `${config.locationsAPI.uri}/api/v2/buildings/${locationUuid}`,
    method: 'GET',
    types: [
      { type: FETCH_COMMUNITY },
      { type: FETCH_COMMUNITY_SUCCESS },
      { type: FETCH_COMMUNITY_FAIL },
    ],
    getErrorMessageFromResponse: (_res, json) => {
      return json.message;
    },
  });

  return dispatch(requestAction);
};

const reducer = createRequestReducer<any, any>(
  [FETCH_COMMUNITY, FETCH_COMMUNITY_SUCCESS, FETCH_COMMUNITY_FAIL],
  {},
  (res, previous) => getSerializedCommunity(previous, res.payload || [])
);

export default reducer;
