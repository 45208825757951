import { createRequestConstantsWithPrefix } from 'store/util/createReduxConstant';

const USER_GROUPS_PREFIX = 'userGroups';

export const [
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_FAIL,
] = createRequestConstantsWithPrefix('fetchUserGroups', USER_GROUPS_PREFIX);

export const [
  CREATE_USER_GROUP,
  CREATE_USER_GROUP_SUCCESS,
  CREATE_USER_GROUP_FAIL,
] = createRequestConstantsWithPrefix('createUserGroups', USER_GROUPS_PREFIX);

export const [
  FETCH_BOOKING_CREDIT_OVERAGE_LIMIT,
  FETCH_BOOKING_CREDIT_OVERAGE_LIMIT_SUCCESS,
  FETCH_BOOKING_CREDIT_OVERAGE_LIMIT_FAIL,
] = createRequestConstantsWithPrefix('fetchBookingCreditOverageLimit', USER_GROUPS_PREFIX);
