import { InitialTerms } from 'features/paperwork/types';
import { PendingMoveIn } from 'features/paperwork/ducks/pendingMoveIns';

export const serializeInitialTermsForContract = (
  count: number,
  pendingReservation: PendingMoveIn | null | undefined
): InitialTerms | null | undefined => {
  // Be defensive in case a defective contract key was used.
  if (pendingReservation == null) {
    return null;
  }

  const { building_uuid: locationUuid, moveIn: startedOn } = pendingReservation;

  return {
    calendarUnit: 'months',
    count,
    location_uuid: locationUuid,
    started_on: startedOn,
  };
};
