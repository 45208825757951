import React, { Component } from 'react';
import cn from 'classnames';
import { Image } from '@wework/dieter-ui';

import styles from 'components/avatar/index.scss';

import { createAvatarUrl, defaultAvatarUrl, sizes } from './constants';

export type UserInfo = {
  email?: string;
  id?: string;
};

export type AvatarSize = typeof sizes[keyof typeof sizes];

type AvatarUrlParams = {
  showDefaultAvatar?: boolean;
  imageUrl?: string;
  userInfo?: UserInfo;
};

type Props = Readonly<
  AvatarUrlParams & {
    centered?: boolean;
    className: string;
    isCompany?: boolean;
    onClick?: (arg0: Event) => void;
    size?: AvatarSize;
  }
>;

export const getAvatarUrl = ({ showDefaultAvatar, imageUrl, userInfo }: AvatarUrlParams) => {
  if (showDefaultAvatar) {
    return defaultAvatarUrl;
  } else if (imageUrl) {
    return imageUrl;
  }
  return createAvatarUrl(userInfo);
};

class Avatar extends Component<Props> {
  state = {
    loadImageError: false,
  };

  static defaultProps = { className: '', size: sizes.FLEXIBLE };

  handleError = () => {
    this.setState({ loadImageError: true });
  };

  getAvatarUrl = () => {
    const { showDefaultAvatar, imageUrl, userInfo } = this.props;
    const shouldShowDefault: boolean = showDefaultAvatar || this.state.loadImageError;

    return getAvatarUrl({ showDefaultAvatar: shouldShowDefault, imageUrl, userInfo });
  };

  render() {
    const { centered, className, size, isCompany, onClick } = this.props;

    const avatarClass = cn(styles.base, {
      [styles.flexible]: size === sizes.FLEXIBLE,
      [styles.nano]: size === sizes.NANO,
      [styles.tiny]: size === sizes.TINY,
      [styles.micro]: size === sizes.MICRO,
      [styles.extraSmall]: size === sizes.EXTRA_SMALL,
      [styles.small]: size === sizes.SMALL,
      [styles.medium]: size === sizes.MEDIUM,
      [styles.large]: size === sizes.LARGE,
      [styles.microTiny]: size === sizes.MICRO_TINY,
      [styles.centered]: centered,
      [styles.company]: isCompany,
      [className]: className,
    });

    return (
      <div className={avatarClass}>
        <Image
          className={styles.image}
          src={this.getAvatarUrl()}
          onClick={onClick}
          onError={this.handleError}
          alt="member avatar"
          loading="lazy"
        />
      </div>
    );
  }
}

export default Avatar;
