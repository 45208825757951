import { handleActions, ReducerMap, Action } from 'redux-actions';
import immutable from 'dot-prop-immutable';

import { MimoReservable, Move } from 'features/mimo/types';
import { updateReservableMove } from 'features/mimo/utils';
import { MOVE_DIRECTION_TYPES } from 'features/mimo/constants';

import {
  FETCH_MOVES_BY_LOCATION,
  FETCH_MOVES_BY_LOCATION_FAIL,
  FETCH_MOVES_BY_LOCATION_SUCCESS,
  UPDATE_RESERVABLE_MIMO,
} from './constants';
import { convertMovesToMimoReservables } from './util';

export interface State {
  data: Array<MimoReservable>;
  loading: boolean;
  loaded: boolean;
  error: boolean;
}

const initialState: State = {
  data: [],
  loading: false,
  loaded: false,
  error: false,
};

const reducerDefinition: ReducerMap<State, { data: Array<Move> } | Move> = {
  [FETCH_MOVES_BY_LOCATION]: state => ({
    ...state,
    loading: true,
    loaded: false,
    error: false,
  }),
  [FETCH_MOVES_BY_LOCATION_SUCCESS]: (
    state,
    { payload: { data } }: Action<{ data: Array<Move> }>
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    data: convertMovesToMimoReservables(data),
  }),
  [FETCH_MOVES_BY_LOCATION_FAIL]: state => ({
    ...state,
    loading: false,
    loaded: false,
    data: [],
    error: true,
  }),
  [UPDATE_RESERVABLE_MIMO]: (state, { payload: move }: Action<Move>) => {
    const reservables = state.data;
    const reservableToUpdate = reservables.find(reservable => {
      switch (move.moveDirection) {
        case MOVE_DIRECTION_TYPES.moveIn:
          return reservable.moveIns.some(moveIn => moveIn.id === move.id);
        case MOVE_DIRECTION_TYPES.moveOut:
          return reservable.moveOuts.some(moveOut => moveOut.id === move.id);
        default:
          return false;
      }
    });

    return reservableToUpdate
      ? immutable.set(
          state,
          `data.${reservables.indexOf(reservableToUpdate)}`,
          updateReservableMove(reservableToUpdate, move)
        )
      : state;
  },
};

const reducer = handleActions(reducerDefinition, initialState);

export default reducer;
