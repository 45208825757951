import { handleActions, Action } from 'redux-actions';
import queryString from 'query-string';

import { createRequestAction } from 'store/util';
import { Dispatch, BaseAction } from 'store/types';
import config from 'config';
import cc from 'store/util/createReduxConstant';
import { S2Partition as Partition } from 'features/accessControl/accessLevels/redux/types';

// Action Constants
export const FETCH_ACCESS_PARTITION = cc('FETCH_ACCESS_PARTITION');
export const FETCH_ACCESS_PARTITION_SUCCESS = cc('FETCH_ACCESS_PARTITION_SUCCESS');
export const FETCH_ACCESS_PARTITION_FAIL = cc('FETCH_ACCESS_PARTITION_FAIL');
export const FETCH_ACCESS_SERVERS_AND_PARTITIONS = cc('FETCH_ACCESS_SERVERS_AND_PARTITIONS');
export const FETCH_ACCESS_SERVERS_AND_PARTITIONS_SUCCESS = cc(
  'FETCH_ACCESS_SERVERS_AND_PARTITIONS_SUCCESS'
);
export const FETCH_ACCESS_SERVERS_AND_PARTITIONS_FAIL = cc(
  'FETCH_ACCESS_SERVERS_AND_PARTITIONS_FAIL'
);

type Partitions = Array<Partition>;

export interface State {
  loading: boolean;
  loaded: boolean;
  error: string | null | undefined;
  partition: Partial<Partition>;
  partitionsByServerId: Hash<Partitions>;
  servers: Array<{
    id: string;
    name?: string;
    partitions: Partitions;
  }>;
}

export interface AccessServersAndPartitionsSubset {
  accessServersAndPartitions: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  partition: {},
  partitionsByServerId: {},
  servers: [],
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_ACCESS_PARTITION]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),

    [FETCH_ACCESS_PARTITION_SUCCESS]: (state: State, action: Action<{ result: Partition }>) => ({
      ...state,
      loading: false,
      loaded: true,
      partition: action.payload.result,
      error: null,
    }),

    [FETCH_ACCESS_PARTITION_FAIL]: (state: State, action: Action<{ message: string }>) => ({
      ...state,
      loading: false,
      loaded: false,
      partition: {},
      error: action.payload.message,
    }),

    [FETCH_ACCESS_SERVERS_AND_PARTITIONS]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),

    [FETCH_ACCESS_SERVERS_AND_PARTITIONS_SUCCESS]: (
      state: State,
      action: Action<{
        result: Array<{
          id: string;
          partitions: Array<Partition>;
        }>;
      }>
    ) => {
      const servers = action.payload.result;

      return {
        ...state,
        loading: false,
        loaded: true,
        partitionsByServerId: servers.reduce((accumulator, server) => {
          accumulator[server.id] = server.partitions;

          return accumulator;
        }, {}),
        servers,
        error: null,
      };
    },

    [FETCH_ACCESS_SERVERS_AND_PARTITIONS_FAIL]: (
      state: State,
      action: Action<{ message: string }>
    ) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
  },
  initialState
);

// Action Creators
export function fetchAccessPartition(buildingUuid: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const queryParams = {
      building_uuid: buildingUuid,
    };

    const requestAction = createRequestAction({
      endpoint: `${config.airlock.uri}/api/v1/partitions?${queryString.stringify(queryParams)}`,
      types: [FETCH_ACCESS_PARTITION, FETCH_ACCESS_PARTITION_SUCCESS, FETCH_ACCESS_PARTITION_FAIL],
    });

    return dispatch(requestAction);
  };
}

export function fetchAccessServersAndPartitions() {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.airlock.uri}/api/v1/servers`,
      types: [
        FETCH_ACCESS_SERVERS_AND_PARTITIONS,
        FETCH_ACCESS_SERVERS_AND_PARTITIONS_SUCCESS,
        FETCH_ACCESS_SERVERS_AND_PARTITIONS_FAIL,
      ],
    });

    return dispatch(requestAction);
  };
}

export default reducer;
