import { Buffer } from 'buffer';

import { handleActions } from 'redux-actions';

import {
  CommunityEventsApiMediaResponse,
  CommunityEventsApiMediaUploadResponse,
  UploadResult,
} from 'features/events/ducks/types';
import config from 'config';
import { createRequestAction } from 'store/util';
import { BaseState, BaseAction, Dispatch } from 'store/types';
import { Media } from 'features/events/types';
import { camelCaseJson } from 'lib/util';
import cc from 'store/util/createReduxConstant';

export const CLEAR_MEDIA_SEARCH_RESULTS = cc('CLEAR_MEDIA_SEARCH_RESULTS');
export const FETCH_COMMUNITY_EVENT_MEDIA = cc('FETCH_COMMUNITY_EVENT_MEDIA');
export const FETCH_COMMUNITY_EVENT_MEDIA_FAIL = cc('FETCH_COMMUNITY_EVENT_MEDIA_FAIL');
export const FETCH_COMMUNITY_EVENT_MEDIA_SUCCESS = cc('FETCH_COMMUNITY_EVENT_MEDIA_SUCCESS');

export const FETCH_SEARCHED_MEDIA = cc('FETCH_SEARCHED_MEDIA');
export const FETCH_SEARCHED_MEDIA_FAIL = cc('FETCH_SEARCHED_MEDIA_FAIL');
export const FETCH_SEARCHED_MEDIA_SUCCESS = cc('FETCH_SEARCHED_MEDIA_SUCCESS');

export const FETCH_EVENTS_MEDIA_UPLOAD_URLS = cc('FETCH_EVENTS_MEDIA_UPLOAD_URLS');
export const FETCH_EVENTS_MEDIA_UPLOAD_URLS_FAIL = cc('FETCH_EVENTS_MEDIA_UPLOAD_URLS_FAIL');
export const FETCH_EVENTS_MEDIA_UPLOAD_URLS_SUCCESS = cc('FETCH_EVENTS_MEDIA_UPLOAD_URLS_SUCCESS');

export const EVENTS_MEDIA_UPLOAD = cc('EVENTS_MEDIA_UPLOAD');
export const EVENTS_MEDIA_UPLOAD_FAIL = cc('EVENTS_MEDIA_UPLOAD_FAIL');
export const EVENTS_MEDIA_UPLOAD_SUCCESS = cc('EVENTS_MEDIA_UPLOAD_SUCCESS');

type SuccessAction = {
  meta: { eventType: string };
  payload: CommunityEventsApiMediaResponse;
};
type FailAction = { payload: { message: boolean } };

export interface State extends BaseState {
  error: boolean | null | undefined;
  byEventType: Hash<Array<Media>>;
  bySearch: Array<Media>;
  noResults: boolean;
  searchedMediaLoading: boolean;
  searchedMediaLoaded: boolean;
}

export interface EventMediaSubset {
  eventMedia: State;
}

// fetching
export const fetchMediaByKeyword = (
  keyword: string,
  operatorServiceSplitHeader?: Record<string, string>
) =>
  createRequestAction<CommunityEventsApiMediaResponse>({
    endpoint: `${config.communityEventsApi.uri}/api/v1/media?keyword=${keyword}&page=1&per_page=100`,
    method: 'GET',
    headers: {
      ...operatorServiceSplitHeader,
    },
    types: [
      { type: FETCH_SEARCHED_MEDIA },
      { type: FETCH_SEARCHED_MEDIA_SUCCESS, meta: { keyword } },
      { type: FETCH_SEARCHED_MEDIA_FAIL },
    ],
  });

export const fetchCommunityEventMedia = (
  eventType: string,
  operatorServiceSplitHeader?: Record<string, string>
) =>
  createRequestAction<CommunityEventsApiMediaResponse>({
    endpoint: `${config.communityEventsApi.uri}/api/v1/event_types/${eventType}/media?page=1&per_page=100`,
    method: 'GET',
    headers: {
      ...operatorServiceSplitHeader,
    },
    types: [
      { type: FETCH_COMMUNITY_EVENT_MEDIA },
      { type: FETCH_COMMUNITY_EVENT_MEDIA_SUCCESS, meta: { eventType } },
      { type: FETCH_COMMUNITY_EVENT_MEDIA_FAIL },
    ],
  });

export const fetchEventMediaUploadUrls = (
  eventId: string,
  operatorServiceSplitHeader?: Record<string, string>
) => async (dispatch: Dispatch<BaseAction>): Promise<Array<UploadResult>> => {
  const response = await dispatch(
    createRequestAction<CommunityEventsApiMediaUploadResponse>({
      endpoint: `${config.communityEventsApi.uri}/api/v1/events/${eventId}/media/upload_urls`,
      method: 'GET',
      headers: {
        ...operatorServiceSplitHeader,
      },
      types: [
        { type: FETCH_EVENTS_MEDIA_UPLOAD_URLS },
        { type: FETCH_EVENTS_MEDIA_UPLOAD_URLS_SUCCESS, meta: { eventId } },
        { type: FETCH_EVENTS_MEDIA_UPLOAD_URLS_FAIL },
      ],
      getPayloadFromResponse: camelCaseJson,
    })
  );

  return response.payload?.result ?? [];
};

export const parseDataUri = (
  data: string
):
  | {
      extension: string;
      type: string;
      content: string;
    }
  | null
  | undefined => {
  const pattern = new RegExp('data:(.*?);base64,(.*)');
  const result = pattern.exec(data);

  if (result && result.length > 2) {
    const type = result[1]; // image/png
    const extension = type.split('/')[1]; // png
    const content = result[2]; // base64 data
    return { extension, type, content };
  }

  return null;
};

export const uploadEventMedia = (eventId: string, data: string) => async (
  dispatch: Dispatch<BaseAction>
): Promise<string> => {
  const urls = await dispatch(fetchEventMediaUploadUrls(eventId));
  const mediaData = parseDataUri(data);
  const url = urls.find(url => url.extension === mediaData?.extension);

  await dispatch(
    createRequestAction({
      meta: { noAuthHeader: true },
      method: 'PUT',
      endpoint: url.uploadUrl,
      body: Buffer.from(mediaData?.content || '', 'base64'),
      headers: { 'Content-Type': mediaData?.type || '' },
      types: [
        { type: EVENTS_MEDIA_UPLOAD },
        { type: EVENTS_MEDIA_UPLOAD_SUCCESS, meta: { eventId } },
        { type: EVENTS_MEDIA_UPLOAD_FAIL },
      ],
    })
  );

  return url.publicUrl;
};

const initialState = {
  loading: false,
  loaded: false,
  searchedMediaLoading: false,
  searchedMediaLoaded: false,
  error: null,
  byEventType: {},
  bySearch: [],
  noResults: true,
};

export const reducer = handleActions<State, any, { eventType: string }>(
  {
    [CLEAR_MEDIA_SEARCH_RESULTS]: (state: State): State => ({
      ...state,
      bySearch: [],
      noResults: false,
    }),
    [FETCH_COMMUNITY_EVENT_MEDIA]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_COMMUNITY_EVENT_MEDIA_SUCCESS]: (state: State, action: SuccessAction): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byEventType: {
        [action.meta.eventType]: action.payload?.result,
      },
    }),
    [FETCH_COMMUNITY_EVENT_MEDIA_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
    [FETCH_SEARCHED_MEDIA]: (state: State): State => ({
      ...state,
      searchedMediaLoading: true,
      error: null,
    }),
    [FETCH_SEARCHED_MEDIA_SUCCESS]: (state: State, action: SuccessAction): State => ({
      ...state,
      searchedMediaLoading: false,
      searchedMediaLoaded: true,
      error: null,
      bySearch: action.payload.result,
      noResults: action.payload.result.length === 0,
    }),
    [FETCH_SEARCHED_MEDIA_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      searchedMediaLoading: false,
      searchedMediaLoaded: false,
      error: action.payload.message,
      bySearch: [],
    }),
    [FETCH_EVENTS_MEDIA_UPLOAD_URLS]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_EVENTS_MEDIA_UPLOAD_URLS_SUCCESS]: (state: State): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
    [FETCH_EVENTS_MEDIA_UPLOAD_URLS_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
    [EVENTS_MEDIA_UPLOAD]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [EVENTS_MEDIA_UPLOAD_SUCCESS]: (state: State): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
    [EVENTS_MEDIA_UPLOAD_FAIL]: (state: State, action: FailAction): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
  },
  initialState
);

export default reducer;
