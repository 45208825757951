import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

const ACTIONS_PREFIX = 'NEW_VISITORS_';

export const [
  UPDATE_VISITORS_CHECKED_IN_GUEST,
  UPDATE_VISITORS_CHECKED_IN_GUEST_SUCCESS,
  UPDATE_VISITORS_CHECKED_IN_GUEST_FAIL,
] = createRequestConstantNames(
  createReduxConstant(`${ACTIONS_PREFIX}UPDATE_VISITORS_CHECKED_IN_GUEST`)
);

export const [
  UPDATE_VISITORS_GUEST,
  UPDATE_VISITORS_GUEST_SUCCESS,
  UPDATE_VISITORS_GUEST_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}UPDATE_VISITORS_GUEST`));

export const [FETCH_VISITORS, FETCH_VISITORS_SUCCESS] = createRequestConstantNames(
  createReduxConstant(`${ACTIONS_PREFIX}FETCH_VISITORS`)
);

export const [
  SEND_EMERGENCY_LIST,
  SEND_EMERGENCY_LIST_SUCCESS,
  SEND_EMERGENCY_LIST_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}SEND_EMERGENCY_LIST`));

export const [
  EXPORT_VISITOR_LIST,
  EXPORT_VISITOR_LIST_SUCCESS,
  EXPORT_VISITOR_LIST_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}EXPORT_VISITOR_LIST`));

export const [
  EXPORT_SCHEDULED_VISITOR_LIST,
  EXPORT_SCHEDULED_VISITOR_LIST_SUCCESS,
  EXPORT_SCHEDULED_VISITOR_LIST_FAIL,
] = createRequestConstantNames(
  createReduxConstant(`${ACTIONS_PREFIX}EXPORT_SCHEDULED_VISITOR_LIST`)
);

export const [
  DELETE_VISITORS_GUEST,
  DELETE_VISITORS_GUEST_SUCCESS,
  DELETE_VISITORS_GUEST_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}DELETE_VISITORS_GUEST`));

export const [
  CREATE_NEW_GUEST_VISIT,
  CREATE_NEW_GUEST_VISIT_SUCCESS,
  CREATE_NEW_GUEST_VISIT_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}CREATE_NEW_GUEST_VISIT`));

export const [
  PRINT_GUEST_BADGE,
  PRINT_GUEST_BADGE_SUCCESS,
  PRINT_GUEST_BADGE_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}PRINT_GUEST_BADGE`));

export const [
  RESET_GUEST_PHOTO,
  RESET_GUEST_PHOTO_SUCCESS,
  RESET_GUEST_PHOTO_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}RESET_GUEST_PHOTO`));

export const [
  DELETE_GUEST_PHOTO,
  DELETE_GUEST_PHOTO_SUCCESS,
  DELETE_GUEST_PHOTO_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}DELETE_GUEST_PHOTO`));

export const [
  CREATE_NEW_PRE_REGISTERED_GUEST,
  CREATE_NEW_PRE_REGISTERED_GUEST_SUCCESS,
  CREATE_NEW_PRE_REGISTERED_GUEST_FAIL,
] = createRequestConstantNames(
  createReduxConstant(`${ACTIONS_PREFIX}CREATE_NEW_PRE_REGISTERED_GUEST`)
);

export const [
  EDIT_PRE_REGISTERED_GUEST,
  EDIT_PRE_REGISTERED_GUEST_SUCCESS,
  EDIT_PRE_REGISTERED_GUEST_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}EDIT_PRE_REGISTERED_GUEST`));

export const [
  BULK_UPLOAD_PRE_REGISTERED_GUEST,
  BULK_UPLOAD_PRE_REGISTERED_GUEST_SUCCESS,
  BULK_UPLOAD_PRE_REGISTERED_GUEST_FAIL,
] = createRequestConstantNames(
  createReduxConstant(`${ACTIONS_PREFIX}BULK_UPLOAD_PRE_REGISTERED_GUEST`)
);

export const [
  EDIT_GUEST_VISIT,
  EDIT_GUEST_VISIT_SUCCESS,
  EDIT_GUEST_VISIT_FAIL,
] = createRequestConstantNames(createReduxConstant(`${ACTIONS_PREFIX}EDIT_GUEST_VISIT`));

export const VISITOR_CREATED = createReduxConstant(`${ACTIONS_PREFIX}VISITOR_CREATED`);
export const VISITOR_UPDATED = createReduxConstant(`${ACTIONS_PREFIX}VISITOR_UPDATED`);
export const VISITOR_DELETED = createReduxConstant(`${ACTIONS_PREFIX}VISITOR_DELETED`);
export const VISITORS_FILTER_CHANGE = createReduxConstant(
  `${ACTIONS_PREFIX}VISITORS_FILTER_CHANGE`
);
export const VISITORS_DATE_CHANGE = createReduxConstant(`${ACTIONS_PREFIX}VISITORS_DATE_CHANGE`);
