import { handleActions } from 'redux-actions';

import {
  GET_COMPANY_AUDIT_HISTORY,
  GET_USER_ACOMPANY_HISTORY_SUCCESS,
  GET_COMPANY_AUDIT_HISTORY_FAIL,
  GET_ACCESS_AUDIT_LOG,
  GET_ACCESS_AUDIT_LOG_SUCCESS,
  GET_ACCESS_AUDIT_LOG_FAIL,
} from 'features/companies/redux/auditLog/constants';

export interface State {
  companyAuditHistoryPages: {};
  companyAuditHistory: any[];
  fetchingCompanyAuditHistory: boolean;
  fetchingCompanyAuditHistorySuccess: boolean;
  fetchingCompanyAuditHistoryFail: boolean;
  accessAuditLog: any[];
  fetchingAccessAuditLog: boolean;
  fetchingAccessAuditLogSuccess: boolean;
  fetchingAccessAuditLogFail: boolean;
  accessAuditLogTotalPages: any;
}

export interface CompanyAuditHistorySubset {
  companyAuditHistory: State;
}

const initialState: State = {
  companyAuditHistoryPages: {},
  companyAuditHistory: [],
  fetchingCompanyAuditHistory: false,
  fetchingCompanyAuditHistorySuccess: false,
  fetchingCompanyAuditHistoryFail: false,
  accessAuditLog: [],
  fetchingAccessAuditLog: false,
  fetchingAccessAuditLogSuccess: false,
  fetchingAccessAuditLogFail: false,
  accessAuditLogTotalPages: null,
};

export const reducer = handleActions<State, any, any>(
  {
    [GET_COMPANY_AUDIT_HISTORY]: (state: State): State => ({
      ...state,
      fetchingCompanyAuditHistory: true,
    }),

    [GET_USER_ACOMPANY_HISTORY_SUCCESS]: (state: State, action): State => ({
      ...state,
      companyAuditHistoryPages: {
        currentPage: action.payload.meta.current_page,
        totalPages: action.payload.meta.total_pages,
      },
      companyAuditHistory: action.payload.result,
      fetchingCompanyAuditHistory: false,
      fetchingCompanyAuditHistorySuccess: true,
    }),

    [GET_COMPANY_AUDIT_HISTORY_FAIL]: (state: State): State => ({
      ...state,
      companyAuditHistoryPages: {},
      companyAuditHistory: [],
      fetchingCompanyAuditHistory: false,
      fetchingCompanyAuditHistorySuccess: false,
      fetchingCompanyAuditHistoryFail: true,
    }),

    [GET_ACCESS_AUDIT_LOG]: (state: State): State => ({
      ...state,
      fetchingAccessAuditLog: true,
    }),

    [GET_ACCESS_AUDIT_LOG_SUCCESS]: (state: State, action): State => ({
      ...state,
      accessAuditLogTotalPages: action.payload.result.total_pages,
      accessAuditLog: action.payload.result.log_entries,
      fetchingAccessAuditLog: false,
      fetchingAccessAuditLogSuccess: true,
    }),

    [GET_ACCESS_AUDIT_LOG_FAIL]: (state: State): State => ({
      ...state,
      accessAuditLog: [],
      accessAuditLogTotalPages: null,
      fetchingAccessAuditLog: false,
      fetchingAccessAuditLogSuccess: false,
      fetchingAccessAuditLogFail: true,
    }),
  },
  initialState
);

export default reducer;
