import { handleActions } from 'redux-actions';

import { Opportunity } from 'features/paperwork/modals/selectSfOpportunityModal/types';

import {
  FETCH_OPEN_OPPORTUNITY,
  FETCH_OPEN_OPPORTUNITY_SUCCESS,
  FETCH_OPEN_OPPORTUNITY_FAIL,
  CLEAR_SF_OPPORTUNITY,
} from './constants';

export type OpportunityFetchError = {
  name: string;
  status: number;
};

export interface State {
  loaded: boolean;
  loading: boolean;
  error: OpportunityFetchError | null;
  opportunity?: Opportunity;
}

export const initialState: State = {
  loaded: false,
  loading: true,
  error: null,
  opportunity: undefined,
};

const reducer = handleActions<State, any>(
  {
    [FETCH_OPEN_OPPORTUNITY]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_OPEN_OPPORTUNITY_SUCCESS]: (state: State, action) => ({
      ...state,
      opportunity: action.payload,
      loading: false,
      loaded: true,
      error: null,
    }),

    [FETCH_OPEN_OPPORTUNITY_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload,
      opportunity: undefined,
    }),

    [CLEAR_SF_OPPORTUNITY]: () => initialState,
  },
  initialState
);

export default reducer;
