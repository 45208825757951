import {
  EmployeePermissions as WeAuthEmployeePermissions,
  EmployeeRoles as OpServEmployeeRoles,
} from '@wework/we-auth-react';

// Add any new permissions not in we-auth-react here
enum SpacestationEmployeePermissions {
  account_service_edit_organization = 'account_service_edit_organization',
  account_service_manage_feature_flags = 'account_service_manage_feature_flags',
  account_service_manage_trusted_domain = 'account_service_manage_trusted_domain',
  account_service_view_all_dns_verifications = 'account_service_view_all_dns_verifications',
  account_service_view_organization = 'account_service_view_organization',
  account_service_view_trusted_domain = 'account_service_view_trusted_domain',
  account_service_view_user_groups = 'account_service_view_user_groups',
  account_service_manage_user_groups = 'account_service_manage_user_groups',
  account_service_view_invitations = 'account_service_view_invitations',
  account_service_manage_invitations = 'account_service_manage_invitations',
  account_service_accept_invitation = 'account_service_accept_invitation',
  id_admin_activate_companies = 'id_admin_activate_companies',
  id_admin_activate_employees = 'id_admin_activate_employees',
  id_admin_anonymize_users = 'id_admin_anonymize_users',
  id_admin_audit_companies = 'id_admin_audit_companies',
  id_admin_audit_users = 'id_admin_audit_users',
  id_admin_create_user_skeletonkeys = 'id_admin_create_user_skeletonkeys',
  id_admin_deactivate_companies = 'id_admin_deactivate_companies',
  id_admin_deactivate_employees = 'id_admin_deactivate_employees',
  id_admin_sync_companies = 'id_admin_sync_companies',
  id_admin_sync_users = 'id_admin_sync_users',
  id_admin_update_company_tags = 'id_admin_update_company_tags',
  id_admin_update_users = 'id_admin_update_users',
  id_admin_view_user_skeletonkeys = 'id_admin_view_user_skeletonkeys',
  id_admin_view_users = 'id_admin_view_users',
  id_scim_manage_sso_configs = 'id_scim_manage_sso_configs',
  id_scim_manage_tenants = 'id_scim_manage_tenants',
  id_view_contractors = 'id_view_contractors',
  opsrv_read_service_clients = 'opsrv_read_service_clients',
  opsrv_read_service_permissions = 'opsrv_read_service_permissions',
  opsrv_view_employees = 'opsrv_view_employees',
  spacestation_floor_plan_asset_download = 'spacestation_floor_plan_asset_download',
  spacestation_floor_plan_view = 'spacestation_floor_plan_view',
  view_account_info = 'view_account_info',
  view_member_info = 'view_member_info',
  edit_system_of_record = 'system_of_record',
  id_admin_edit_company_verification_bypass = 'id_admin_edit_company_verification_bypass',
}

export const EmployeePermissions = {
  ...SpacestationEmployeePermissions,
  ...WeAuthEmployeePermissions,
};

// Add any new roles not in we-auth-react here
export enum SpacestationEmployeeRoles {
  security_admin = 'security_admin',
  security_tier_one = 'security_tier_one',
  security_tier_two = 'security_tier_two',
  security_tier_three = 'security_tier_three',
}

export const EmployeeRoles = { ...SpacestationEmployeeRoles, ...OpServEmployeeRoles };
