import { handleActions, Action, Reducer } from 'redux-actions';

import { createRequestAction } from 'store/util';
import { Dispatch, BaseAction } from 'store/types';
import config from 'config';
import cc from 'store/util/createReduxConstant';

// Action Constants
export const FETCH_SF_OPPORTUNITY_IDS = cc('FETCH_SF_OPPORTUNITY_IDS');
export const FETCH_SF_OPPORTUNITY_IDS_SUCCESS = cc('FETCH_SF_OPPORTUNITY_IDS_SUCCESS');
export const FETCH_SF_OPPORTUNITY_IDS_FAIL = cc('FETCH_SF_OPPORTUNITY_IDS_FAIL');

export interface State {
  loaded: boolean;
  byMembershipAgreementUuid: {};
  loading: boolean;
  error?: boolean | null | undefined;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byMembershipAgreementUuid: {},
  error: null,
};

// Reducer
export const reducer: Reducer<State, any> = handleActions<State, any>(
  {
    [FETCH_SF_OPPORTUNITY_IDS]: (state: State) => ({ ...state, loading: true, loaded: false }),

    [FETCH_SF_OPPORTUNITY_IDS_SUCCESS]: (
      state: State,
      action: Action<{ result: {}; params: { id: string } }>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byMembershipAgreementUuid: {
        ...state.byMembershipAgreementUuid,
        [action.payload.params.id]: action.payload.result,
      },
    }),

    [FETCH_SF_OPPORTUNITY_IDS_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export const fetchSfOpportunityIds = (membershipAgreementUuid: string) => (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction({
    endpoint: `${config.spaceman.uri}/api/v2/membership_agreements/${membershipAgreementUuid}/sf_opportunity_ids`,
    types: [
      FETCH_SF_OPPORTUNITY_IDS,
      FETCH_SF_OPPORTUNITY_IDS_SUCCESS,
      FETCH_SF_OPPORTUNITY_IDS_FAIL,
    ],
  });

  return dispatch(requestAction);
};

export default reducer;
