import React from 'react';

import { Location } from 'store/modules/locations';

import { ResultSet } from './resultSets/types';

export enum SearchableEntity {
  GUESTS = 'guests',
  COMPANIES = 'companies',
  MEMBERS = 'members',
  KEYCARDS = 'keycards',
  RECENTLY_VIEWED = 'recently_viewed',
}

export type SearchResultSet<T, K extends SearchableEntity> = {
  results: Array<T>;
  key: K;
  name: string;
  color?: string;
  resultCount?: number;
};

export type SearchResultSets<T> = Array<SearchResultSet<T, SearchableEntity>>;

export type SearchResultObject<T> = {
  hitCount: number;
  resultSets: SearchResultSets<T>;
};

export interface ResultProps<T> {
  item: T;
  query: string;
  locations: Array<Location>;
  selected: boolean;
  overallIndex: number;
  relativeIndex: number;
  showOfficeNumber: boolean;
}

export interface ResultSetDescriptor<T, K extends SearchableEntity, P extends {} = {}> {
  visible: (props?: P, resultSet?: SearchResultSet<T, K>) => boolean;
  key: (item: T) => string;
  Result: (props: ResultProps<T>) => React.ReactElement;
}

export type ResultSetDescriptors = {
  [key in SearchableEntity]: ResultSetDescriptor<any, any, any>;
};

export type ProcessedSearchResults<T> = {
  resultCount: number;
  resultSets: SearchResultSets<T>;
};

export type SelectResultSetFn = (resultSets: Array<ResultSet>) => Array<ResultSet>;

export type FilterOption = {
  id: string;
  title: string;
  disabled?: boolean;
  defaultValue: boolean;
  overrideValue?: boolean;
};

export type TabDefinition = {
  key: string;
  title: string;
  selectResultSet: SelectResultSetFn;
  selectResults: SelectResultSetFn;
  options: Array<FilterOption>;
};
