import Fuse from 'fuse.js';

export type Searchable<T> = { search: (value: string) => Array<T> };

export function createFuzzySearches<T>(
  list: Array<T>,
  searches: Array<{ threshold?: number; keys: Array<string> }>,
  options?: Fuse.IFuseOptions<T>
): Searchable<T> {
  const fuses = searches.map(({ threshold = 0.34, keys }) => {
    return new Fuse<T>(list, {
      ...options,
      threshold,
      keys,
    });
  });

  return {
    search(value) {
      value = value.trim();
      if (!value) {
        return list;
      }
      const results = fuses
        .map(fuse => fuse.search(value))
        .flat()
        .map(result => result.item);
      return Array.from(new Set(results));
    },
  };
}
