import { createAction, handleActions, ReduxCompatibleReducer } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

// Action Constants
export const TOGGLE_AVATAR_MENU = cc('TOGGLE_AVATAR_MENU');
export const TOGGLE_HOME_LOCATION_MODAL_OPEN = cc('TOGGLE_HOME_LOCATION_MODAL_OPEN');
export const SET_RELOAD_REQUIRED_MESSAGE = cc('SET_RELOAD_REQUIRED_MESSAGE');

type MaybeCallback = (evt: React.MouseEvent) => void | null | undefined;

export interface State {
  avatarMenuOpen: boolean;
  homeLocationModalOpen: boolean;
  reloadRequired: {
    message: string | null;
    callback: MaybeCallback | null;
    isOnline: boolean;
  };
}

export interface AppSubset {
  app: State;
}

const initialState: State = {
  avatarMenuOpen: false,
  homeLocationModalOpen: false,
  reloadRequired: {
    message: null,
    callback: null,
    isOnline: true,
  },
};

// Reducer
export const reducer: ReduxCompatibleReducer<State, any> = handleActions<State, any, any>(
  {
    [TOGGLE_HOME_LOCATION_MODAL_OPEN]: state => ({
      ...state,
      homeLocationModalOpen: !state.homeLocationModalOpen,
    }),
    [TOGGLE_AVATAR_MENU]: state => ({
      ...state,
      avatarMenuOpen: !state.avatarMenuOpen,
    }),
    [SET_RELOAD_REQUIRED_MESSAGE]: (state, { payload, meta }) => ({
      ...state,
      reloadRequired: {
        ...payload,
        ...meta,
      },
    }),
  },
  initialState
);

// Action Creators
export const toggleAvatarMenu = createAction(TOGGLE_AVATAR_MENU);

export const toggleHomeLocationModal = createAction(TOGGLE_HOME_LOCATION_MODAL_OPEN);

export const setReloadRequiredMessage = createAction(
  SET_RELOAD_REQUIRED_MESSAGE,
  (message: string, callback?: MaybeCallback) => ({
    message,
    callback,
  }),
  (_message: string, _callback?: MaybeCallback, meta?: { isOnline: boolean }) => meta
);

export default reducer;
