import { createSelector } from 'reselect';

import { Location } from './types';
import { getSpacemanId } from './utils';

import { SiLocationsSubset } from './index';

export const getCurrentLocation = (state: SiLocationsSubset): Location | null =>
  state.siLocations.currentLocation?.result;

export const getCurrentLocationLoading = (state: SiLocationsSubset): boolean =>
  state.siLocations.currentLocation?.loading;

export const getCurrentLocationError = (state: SiLocationsSubset): boolean | null =>
  state.siLocations.currentLocation?.error;

export const getCurrentLocationSpacemanId = createSelector(getCurrentLocation, location =>
  location ? getSpacemanId(location) : null
);

export const getHomeLocation = (state: SiLocationsSubset): Location | null =>
  state.siLocations.homeLocation?.result;

export const getRecentLocations = (state: SiLocationsSubset): Array<Location> =>
  state.siLocations.recentLocations;

export const getRecentLocationSpacemanIds = createSelector(getRecentLocations, locations =>
  locations.map(getSpacemanId)
);

// returns spaceman location id
export const getCurrentLocationUuid = createSelector(
  getRecentLocationSpacemanIds,
  recentUuids => recentUuids?.[0] ?? undefined
);
