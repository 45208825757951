import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

import { Opaque } from 'lib/util';
import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

import { IAlgoliaTokenClient, ClearableTokenClient } from './types';

export type AlgoliaToken = Opaque<'AlgoliaToken', string>;

export const EXPIRATION_BUFFER_MINUTES = 30;

export default class AlgoliaTokenClient implements IAlgoliaTokenClient, ClearableTokenClient {
  pendingPromise: Promise<AlgoliaToken> | null;

  validUntil: number | null = null;
  token: AlgoliaToken | null = null;

  isExpired(): boolean {
    return moment
      .utc()
      .add(EXPIRATION_BUFFER_MINUTES, 'minutes')
      .isAfter(moment.utc(this.validUntil || 0));
  }

  // Private methods
  async request(): Promise<{
    key: string;
    validUntil: number;
  }> {
    const accessToken = await getAccessToken();
    const algoliaKeyEndpoint = `${config.algolia.securedKeyUri}/algolia/getAlgoliaKey`;
    const response = await fetch(algoliaKeyEndpoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${String(accessToken)}`,
        'x-request-id': uuidv4(),
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw new Error(json);
    }

    return json;
  }

  async getOrRefreshPromise(): Promise<AlgoliaToken> {
    if (!this.token || this.isExpired()) {
      const payload = await this.request();

      this.validUntil = payload.validUntil * 1000;
      this.token = payload.key as AlgoliaToken;
    }

    return this.token;
  }

  // TokenClient<AlgoliaToken>#getOrRefresh
  async getOrRefresh(): Promise<AlgoliaToken> {
    if (!this.pendingPromise) {
      this.pendingPromise = this.getOrRefreshPromise().finally(() => {
        this.pendingPromise = null;
      });
    }

    return this.pendingPromise;
  }

  // TokenClient<AlgoliaToken>#getTokenOptional
  async getTokenOptional(): Promise<AlgoliaToken | null> {
    return this.token;
  }

  // TokenClient<MilkywayToken>#getTokenPayload
  async getTokenPayload(): Promise<null> {
    throw new Error('Not implemented');
  }

  // ClearableTokenClient#clear
  async clear(): Promise<void> {
    this.validUntil = null;
    this.token = null;
  }
}
