import { connect } from 'react-redux';
import { omit } from 'lodash';
import React, { ComponentType } from 'react';

import { BaseAction, Dispatch } from 'store/types';
import { trackInteraction, Options } from 'store/modules/analytics';
import { startTracking, stopTracking } from 'features/performance/service';

export type TrackInteraction = (string, options?: Options) => void;
export type InteractionStart = (eventLabel: string, eventName?: string, options?: Options) => void;
export type InteractionEnd = (eventLabel: string) => void;

export type TrackableType = {
  getEventMetadata: () => Options;
  trackInteraction: TrackInteraction;
  interactionStart: InteractionStart;
  interactionEnd: InteractionEnd;
  eventMetadata?: Options;
  trackEventMetadata?: Options;
};

export type OptionalTrackableProps = {
  trackEventMetadata?: Options;
  onClick?: (...args: Array<any>) => void;
  href?: string;
  to?: string;
  trackEvent?: string;
};

export type MapPropsToEventMetadata<P = OptionalTrackableProps> = Options | ((props: P) => Options);

type TrackableWrapperProps = {
  trackableWrappedComponent: React.ComponentType<any>;
  trackableMapPropsToEventMetadata: MapPropsToEventMetadata;
  trackableTrackInteraction: TrackInteraction;
} & OptionalTrackableProps;

class TrackableWrapper extends React.Component<TrackableWrapperProps> {
  getEventMetadata = () => {
    const {
      trackableMapPropsToEventMetadata: mapPropsToEventMetadata,
      trackEventMetadata = {},
    } = this.props;

    const eventMetadataFromTrackable =
      typeof mapPropsToEventMetadata === 'function'
        ? mapPropsToEventMetadata(
            omit(this.props, [
              'trackableWrappedComponent',
              'trackableMapPropsToEventMetadata',
              'trackableTrackInteraction',
            ])
          )
        : mapPropsToEventMetadata;

    return {
      ...eventMetadataFromTrackable,
      ...trackEventMetadata,
    };
  };

  trackInteraction = (eventName: string, opts: Options = {}) => {
    const eventMetadata = this.getEventMetadata();

    return this.props.trackableTrackInteraction(eventName, { ...eventMetadata, ...opts });
  };

  interactionStart = (eventLabel: string, eventName: string = eventLabel, opts: Options = {}) => {
    startTracking(eventLabel, performanceMetrics => {
      this.trackInteraction(eventName, { ...performanceMetrics, ...opts });
    });
  };

  interactionEnd = (eventLabel: string) => {
    stopTracking(eventLabel);
  };

  onClick = (...clickArgs: Array<any>) => {
    const returnValue = this.props.onClick && this.props.onClick(...clickArgs);
    const eventMetadata = this.getEventMetadata();
    const metaEventName = this.props.trackEvent || eventMetadata.event || '';

    this.props.trackableTrackInteraction(metaEventName, eventMetadata);

    return returnValue;
  };

  render() {
    const {
      trackableWrappedComponent: Component,
      trackableMapPropsToEventMetadata,
      trackableTrackInteraction,
      ...componentProps
    } = this.props;

    const eventMetadata = this.getEventMetadata();
    const metaEventName = this.props.trackEvent || eventMetadata.event;

    if ((componentProps.onClick || componentProps.href || componentProps.to) && metaEventName) {
      componentProps.onClick = this.onClick;
    }

    return (
      <Component
        {...componentProps}
        eventMetadata={eventMetadata}
        getEventMetadata={this.getEventMetadata}
        trackInteraction={this.trackInteraction}
        interactionStart={this.interactionStart}
        interactionEnd={this.interactionEnd}
      />
    );
  }
}

const asyncTrackInteraction = (eventName: string, options: Options) => (
  dispatch: Dispatch<BaseAction>
) => {
  setTimeout(() => dispatch(trackInteraction(eventName, options)), 10);
};

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  trackableTrackInteraction(eventName: string, options: Options) {
    return dispatch(asyncTrackInteraction(eventName, options));
  },
});

// Usage:
//
// Example 1 - No configuration
//
// const TrackableLink = Trackable()(Link);
//
// <TrackableLink
//   trackEvent="Link Clicked"
//   trackEventMetadata={{ title: "Do Stuff" }}
//   onClick={doTheThing} title="Do Stuff" />
//
// This component will track an interaction called "Link Clicked" with extra
// metadata provided in the `trackEventMetadata` component. It piggybacks off
// of the `onClick` prop and includes dispatching an analytics event.
//
// Within the component, TrackableLink now has access to `this.props.trackInteraction` to track analytics ad-hoc.
//
//
// Example 2 - with an object
//
// const TrackableLink = Trackable({ event: "Link Clicked", label: "Link" })(Link);
//
// <TrackableLink title="Do Stuff" onClick={doTheThing} />
//
//
// Example 3 - with a mapPropsToEventMetadata function
//
// const TrackableLink = Trackable(props => ({
//   event: "Link Clicked", label: "Link", title: props.title
// }))(Link);
//
// <TrackableLink title="Do Stuff" onClick={doTheThing} />
//
// Using a function argument here is useful when the event you are tracking depends on the props
// that are passed into the component.
//
export default function Trackable(mapPropsToEventMetadata: MapPropsToEventMetadata = {}) {
  // __TS_TECH_DEBT: Add type notation
  return /* <P extends object> */ (Component: ComponentType<any>): ComponentType<any> => {
    const TrackableComponent = connect(
      () => ({
        trackableWrappedComponent: Component,
        trackableMapPropsToEventMetadata: mapPropsToEventMetadata,
      }),
      mapDispatchToProps
    )(TrackableWrapper);

    TrackableComponent.displayName = `WithTrackable(${
      Component.displayName || Component.name || 'Component'
    })`;

    return TrackableComponent;
  };
}
