import { EventNameTypes } from 'store/constants';

import { TrackOptions } from './service';

type TrackerParams = {
  event: string;
  label: string;
};

export function getTracker({ event, label }: TrackerParams) {
  return (performance: TrackOptions) => {
    if (window.analytics) {
      window.analytics.track(EventNameTypes.interaction, {
        event,
        label,
        ...performance,
      });
    }
  };
}
