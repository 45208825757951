import React, { useState, useContext, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { compose } from 'redux';

// eslint-disable-next-line import/no-named-default
import { default as RayAvatar } from 'components-ray/avatar';
import ScrollIntoView from 'components/scrollIntoView';
import { Result as ResultProps } from 'features/search/resultSets/types';
import { SearchContext } from 'features/search/context/search';
import AnalyticsProvider from 'lib/contextualAnalytics/provider';

import { ResultContext } from '../context/result';
import { SearchableEntity } from '../types';

import styles from './index.scss';
import { useSearchResultPosition } from './utils';

const ResultAvatar = ({
  avatarUrl,
  CustomAvatar,
  entityType,
}: Pick<ResultProps, 'avatarUrl' | 'CustomAvatar' | 'entityType'>) => {
  const isMember =
    entityType === SearchableEntity.MEMBERS || entityType === SearchableEntity.GUESTS || false;

  if (CustomAvatar) {
    return <CustomAvatar />;
  }

  return <RayAvatar rounded={isMember} size="medium" imageUrl={avatarUrl || ''} />;
};

type LastCellProps = Pick<ResultProps, 'RowEndComponent'> & {
  lastCell: React.ReactNode;
  focused: boolean;
};
const LastCell = ({ RowEndComponent, lastCell, focused }: LastCellProps) => {
  if (!RowEndComponent) {
    return <>{lastCell}</>;
  }
  return (
    <div className="flex flex-1">
      {lastCell}
      {RowEndComponent && <RowEndComponent focused={focused} />}
    </div>
  );
};

const Result: React.FC<ResultProps> = React.memo(function Result({
  uuid,
  entityType,
  avatarUrl,
  CustomAvatar,
  cells,
  RowEndComponent,
  data,
  analyticsData,
}) {
  const [hovering, setState] = useState<boolean>(false);
  const setHovering = useCallback(() => setState(true), [setState]);
  const unsetHovering = useCallback(() => setState(false), [setState]);
  const resultContextData = useMemo(() => ({ uuid, analyticsData, entityType, data }), [
    uuid,
    analyticsData,
    entityType,
    data,
  ]);

  const { resultSets, keyboardSelectedItemIndex } = useContext(SearchContext);
  const { overallPosition, sectionPosition } = useSearchResultPosition(resultSets, uuid);
  const selected = overallPosition === keyboardSelectedItemIndex;
  const contextualData = useMemo(
    () => ({
      entity_placement: sectionPosition !== undefined && sectionPosition + 1,
      entity_placement_in_general: overallPosition !== undefined && overallPosition + 1,
      ...analyticsData,
    }),
    [overallPosition, sectionPosition, analyticsData]
  );

  const focused = selected || hovering;
  const allButLastCell = useMemo(() => cells.slice(0, -1), [cells]);
  const lastCell = useMemo(() => cells[cells.length - 1], [cells]);
  const classes = cn(
    'font-main py-sm px-md flex items-center text-black h-[80px] border-t border-gray-90',
    { [styles.focus]: focused },
    styles.rayResult,
    styles.rayResultBackground
  );

  return (
    <AnalyticsProvider data={contextualData}>
      <ResultContext.Provider value={resultContextData}>
        <ScrollIntoView
          className={classes}
          focused={focused}
          onMouseEnter={setHovering}
          onMouseLeave={unsetHovering}
        >
          <ResultAvatar avatarUrl={avatarUrl} CustomAvatar={CustomAvatar} entityType={entityType} />
          <div className="flex flex-1 overflow-hidden pl-md h-full items-center">
            {allButLastCell}
            <LastCell RowEndComponent={RowEndComponent} lastCell={lastCell} focused={focused} />
          </div>
        </ScrollIntoView>
      </ResultContext.Provider>
    </AnalyticsProvider>
  );
});

export default compose<React.ComponentType<ResultProps>>()(Result);
