import { store } from 'store/getOrInitStore';
import getStaticValues from 'lib/analytics/types/static/values';
import { DynamicProperty } from 'lib/analytics/types/dynamic';
import { AnalyticsProperty } from 'lib/analytics/types';

import { AnalyticsEventName } from './constants';

const EMPTY_OBJECT = Object.freeze({});

export function trackAnalyticsFor<E extends AnalyticsEventName>(
  eventName: E,
  dynamicProperties: DynamicProperty<E>
): void {
  const dynamicProps = (dynamicProperties as object) || EMPTY_OBJECT;

  const properties: AnalyticsProperty<E> = {
    ...dynamicProps,
    ...getStaticValues(eventName),
  } as AnalyticsProperty<E>;

  window?.analytics?.track(eventName, {
    ...properties,
    experiments: store.getState()?.experiments.experimentGroups || {},
    traits: window?.analytics?.user ? window?.analytics?.user()?.traits() : {},
  });
}
