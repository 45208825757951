import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import { BaseAction, Dispatch } from 'store/types';
import { createRequestAction } from 'store/util';
import config from 'config';
import { deprecated_isPresent } from 'lib/util';
import cc from 'store/util/createReduxConstant';

import {
  KeycardCreateResponse,
  KeycardCreateParams,
  KeycardUpdateParams,
  ReservationParams,
  KeycardUpdateResponse,
  DeactivateProfileResponse,
} from './types';

// Types
export type Keycards = {
  submitting: boolean;
  submitted: boolean;
  error: string | null | undefined;
  byMemberUuid: {};
};

interface State {
  submitting: boolean;
  submitted: boolean;
  byMemberUuid: {
    // TODO: Type this.
    [uuid: string]: any;
  };
  error: null;
}

export interface KeycardsSubset {
  keycards: State;
}

// Action Constants
export const FETCH_KEYCARD = cc('FETCH_KEYCARD');
export const FETCH_KEYCARD_SUCCESS = cc('FETCH_KEYCARD_SUCCESS');
export const FETCH_KEYCARD_FAIL = cc('FETCH_KEYCARD_FAIL');
export const CREATE_KEYCARD = cc('CREATE_KEYCARD');
export const CREATE_KEYCARD_SUCCESS = cc('CREATE_KEYCARD_SUCCESS');
export const CREATE_KEYCARD_FAIL = cc('CREATE_KEYCARD_FAIL');
export const UPDATE_KEYCARD = cc('UPDATE_KEYCARD');
export const UPDATE_KEYCARD_SUCCESS = cc('UPDATE_KEYCARD_SUCCESS');
export const UPDATE_KEYCARD_FAIL = cc('UPDATE_KEYCARD_FAIL');
export const DEACTIVATE_KEYCARD = cc('DEACTIVATE_KEYCARD');
export const DEACTIVATE_KEYCARD_SUCCESS = cc('DEACTIVATE_KEYCARD_SUCCESS');
export const DEACTIVATE_KEYCARD_FAIL = cc('DEACTIVATE_KEYCARD_FAIL');
export const REMOVE_ACCESS_FROM_KEYCARD = cc('REMOVE_ACCESS_FROM_KEYCARD');
export const REMOVE_ACCESS_FROM_KEYCARD_SUCCESS = cc('REMOVE_ACCESS_FROM_KEYCARD_SUCCESS');
export const REMOVE_ACCESS_FROM_KEYCARD_FAIL = cc('REMOVE_ACCESS_FROM_KEYCARD_FAIL');

// Initial State
const initialState: State = {
  submitting: false,
  submitted: false,
  error: null,
  byMemberUuid: {},
};

export const reducer = handleActions<State, any, any>(
  {
    [FETCH_KEYCARD]: (state, action) => ({
      ...state,
      byMemberUuid: {
        ...state.byMemberUuid,
        [action.meta.memberUuid]: {
          loading: true,
          loaded: false,
          error: null,
        },
      },
    }),

    [FETCH_KEYCARD_SUCCESS]: (state, action) => {
      const result = action.payload.result;

      return {
        ...state,
        byMemberUuid: {
          ...state.byMemberUuid,
          [action.payload.params.id]: {
            keycardAssignments: result.keycard_assignments || [],
            accessAssignment: result.access_assignment || {},
            badgeImage: result.badge_image || {},
            accessChangeAttempt: result.access_change_attempt || {},
            expiration: result.expiration || {},
            s2ProfileId: result.s2_profile_id,
            loading: false,
            loaded: true,
            error: null,
          },
        },
      };
    },

    [FETCH_KEYCARD_FAIL]: (state, action) => ({
      ...state,
      byMemberUuid: {
        ...state.byMemberUuid,
        [action.meta.memberUuid]: {
          loading: false,
          loaded: false,
          error: action.payload.message,
        },
      },
    }),

    [CREATE_KEYCARD]: state => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [CREATE_KEYCARD_SUCCESS]: state => ({
      ...state,
      submitting: false,
      submitted: true,
      error: null,
    }),

    [CREATE_KEYCARD_FAIL]: (state, action) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.payload.message,
    }),

    [UPDATE_KEYCARD]: state => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [UPDATE_KEYCARD_SUCCESS]: state => ({
      ...state,
      submitting: false,
      submitted: true,
      error: null,
    }),

    [UPDATE_KEYCARD_FAIL]: (state, action) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.payload.message,
    }),

    [DEACTIVATE_KEYCARD]: state => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [DEACTIVATE_KEYCARD_SUCCESS]: state => ({
      ...state,
      submitting: false,
      submitted: true,
      error: null,
    }),

    [DEACTIVATE_KEYCARD_FAIL]: (state, action) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.payload.message,
    }),

    [REMOVE_ACCESS_FROM_KEYCARD]: state => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [REMOVE_ACCESS_FROM_KEYCARD_SUCCESS]: state => ({
      ...state,
      submitting: false,
      submitted: true,
      error: null,
    }),

    [REMOVE_ACCESS_FROM_KEYCARD_FAIL]: (state, action) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.payload.message,
    }),
  },
  initialState
);

// Action Creators
export function fetchKeycard(uuid: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.airlock.uri}/api/v1/users/${uuid}`,
      types: [
        { type: FETCH_KEYCARD, meta: { memberUuid: uuid } },
        FETCH_KEYCARD_SUCCESS,
        { type: FETCH_KEYCARD_FAIL, meta: { memberUuid: uuid } },
      ],
    });

    return dispatch(requestAction);
  };
}

export function createKeycard(
  memberUuid: string,
  keycardParams: KeycardCreateParams,
  locationUuid?: string
) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction<KeycardCreateResponse>({
      endpoint: `${config.airlock.uri}/api/v1/workers/keycard_create`,
      method: 'POST',
      types: [CREATE_KEYCARD, CREATE_KEYCARD_SUCCESS, CREATE_KEYCARD_FAIL],
      body: {
        user_uuid: memberUuid,
        location_uuid: locationUuid,
        ...keycardParams,
      },
      getErrorMessageFromResponse: (_, jsonRes) =>
        get(jsonRes, 'message') || get(jsonRes, 'result.error').join(', '),
    });

    return dispatch(requestAction);
  };
}

export function updateKeycard(
  memberUuid: string,
  keycardParams: KeycardUpdateParams,
  locationUuid?: string,
  seatCount?: string,
  reservationParams?: ReservationParams
) {
  const hasReservation = seatCount && deprecated_isPresent(reservationParams);
  return (dispatch: Dispatch<BaseAction>) => {
    const params = {
      user_uuid: memberUuid,
      location_uuid: locationUuid,
      ...keycardParams,
      seat_count: hasReservation && seatCount,
      reservation_params: hasReservation && reservationParams,
    };

    const requestAction = createRequestAction<KeycardUpdateResponse>({
      endpoint: `${config.airlock.uri}/api/v1/workers/keycard_update`,
      method: 'POST',
      types: [UPDATE_KEYCARD, UPDATE_KEYCARD_SUCCESS, UPDATE_KEYCARD_FAIL],
      body: params,
      getErrorMessageFromResponse: (_, jsonRes) =>
        get(jsonRes, 'message') || get(jsonRes, 'result.error').join(', '),
    });

    return dispatch(requestAction);
  };
}

export function deactivateKeycard(userUuid: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction<DeactivateProfileResponse>({
      endpoint: `${config.airlock.uri}/api/v1/workers/deactivate_profile`,
      method: 'POST',
      types: [DEACTIVATE_KEYCARD, DEACTIVATE_KEYCARD_SUCCESS, DEACTIVATE_KEYCARD_FAIL],
      body: {
        user_uuid: userUuid,
      },
    });

    return dispatch(requestAction);
  };
}

export function removeAccessFromKeycard(keycardAssignmentId: string, accessId: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.airlock.uri}/api/v1/workers/keycard_remove_access`,
      method: 'POST',
      types: [
        REMOVE_ACCESS_FROM_KEYCARD,
        REMOVE_ACCESS_FROM_KEYCARD_SUCCESS,
        REMOVE_ACCESS_FROM_KEYCARD_FAIL,
      ],
      body: {
        keycard_assignment_id: keycardAssignmentId,
        access_id: accessId,
      },
    });

    return dispatch(requestAction);
  };
}

export default reducer;
