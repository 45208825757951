import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_REQUESTS,
  FETCH_REQUESTS_SUCCESS,
  FETCH_REQUESTS_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_REQUESTS'));

export const [
  FETCH_PREVIOUS_REQUESTS,
  FETCH_PREVIOUS_REQUESTS_SUCCESS,
  FETCH_PREVIOUS_REQUESTS_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_PREVIOUS_REQUESTS'));

export const [
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_COMPANIES'));

export const [
  FETCH_MMP_WHITELIST,
  FETCH_MMP_WHITELIST_SUCCESS,
  FETCH_MMP_WHITELIST_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_MMP_WHITELIST'));

export const [FETCH_TASKS, FETCH_TASKS_SUCCESS, FETCH_TASKS_FAILURE] = createRequestConstantNames(
  cc('mimo/FETCH_TASKS_BY_REFERENCE_UUIDS')
);

export const [UPDATE_TASK, UPDATE_TASK_SUCCESS, UPDATE_TASK_FAILURE] = createRequestConstantNames(
  cc('mimo/UPDATE_MIMO_CHECKLIST_TASK')
);

export const [
  UPDATE_TASK_RAY,
  UPDATE_TASK_SUCCESS_RAY,
  UPDATE_TASK_FAILURE_RAY,
] = createRequestConstantNames(cc('mimo/UPDATE_MIMO_CHECKLIST_TASK_RAY'));

export const MMP_INDICATION_STATUS = Object.freeze({
  NOT_WHITELISTED: -1,
  HAS_NO_TASKS: 0,
  HAS_INCOMPLETE_TASKS: 1,
  ALL_TASKS_COMPLETED: 2,
});

export const [
  UPDATE_REQUEST,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_FAILURE,
] = createRequestConstantNames(cc('mimo/UPDATE_REQUEST'));

export const [
  FETCH_INVENTORY_CAPACITY,
  FETCH_INVENTORY_CAPACITY_SUCCESS,
  FETCH_INVENTORY_CAPACITY_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_INVENTORY_CAPACITY'));

export const [
  FETCH_REMINDER_INFO,
  FETCH_REMINDER_INFO_SUCCESS,
  FETCH_REMINDER_INFO_FAILURE,
] = createRequestConstantNames(cc('mimo/FETCH_REMINDER_INFO'));

export const UPDATE_TASK_OPTIMISTICALLY = 'UPDATE_TASK_OPTIMISTICALLY';
