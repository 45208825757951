import cc from 'store/util/createReduxConstant';

export const FETCH_OCCUPIABLES_BY_LOCATION = cc('FETCH_OCCUPIABLES_BY_LOCATION');
export const FETCH_OCCUPIABLES_BY_LOCATION_SUCCESS = cc('FETCH_OCCUPIABLES_BY_LOCATION_SUCCESS');
export const FETCH_OCCUPIABLES_BY_LOCATION_FAIL = cc('FETCH_OCCUPIABLES_BY_LOCATION_FAIL');

export const FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS = cc('FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS');
export const FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_SUCCESS = cc(
  'FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_SUCCESS'
);
export const FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_FAIL = cc(
  'FETCH_OCCUPIABLES_BY_LOCATION_FROM_IMS_FAIL'
);
export const SAVE_OCCUPIABLE = cc('SAVE_OCCUPIABLE');
export const SAVE_OCCUPIABLE_SUCCESS = cc('SAVE_OCCUPIABLE_SUCCESS');
export const SAVE_OCCUPIABLE_FAIL = cc('SAVE_OCCUPIABLE_FAIL');
export const ARCHIVE_OCCUPIABLE = cc('ARCHIVE_OCCUPIABLE');
export const ARCHIVE_OCCUPIABLE_SUCCESS = cc('ARCHIVE_OCCUPIABLE_SUCCESS');
export const ARCHIVE_OCCUPIABLE_FAIL = cc('ARCHIVE_OCCUPIABLE_FAIL');
export const UNARCHIVE_OCCUPIABLE = cc('UNARCHIVE_OCCUPIABLE');
export const UNARCHIVE_OCCUPIABLE_SUCCESS = cc('UNARCHIVE_OCCUPIABLE_SUCCESS');
export const UNARCHIVE_OCCUPIABLE_FAIL = cc('UNARCHIVE_OCCUPIABLE_FAIL');
export const PUBLISH_OCCUPIABLE = cc('PUBLISH_OCCUPIABLE');
export const PUBLISH_OCCUPIABLE_SUCCESS = cc('PUBLISH_OCCUPIABLE_SUCCESS');
export const PUBLISH_OCCUPIABLE_FAIL = cc('PUBLISH_OCCUPIABLE_FAIL');
export const REFRESH_OCCUPIABLE = cc('REFRESH_OCCUPIABLE');
export const REFRESH_OCCUPIABLE_SUCCESS = cc('REFRESH_OCCUPIABLE_SUCCESS');
export const REFRESH_OCCUPIABLE_FAIL = cc('REFRESH_OCCUPIABLE_FAIL');
export const UPDATE_OCCUPIABLE_NOTES = cc('UPDATE_OCCUPIABLE_NOTES');
export const UPDATE_OCCUPIABLE_NOTES_SUCCESS = cc('UPDATE_OCCUPIABLE_NOTES_SUCCESS');
export const UPDATE_OCCUPIABLE_NOTES_FAIL = cc('UPDATE_OCCUPIABLE_NOTES_FAIL');
export const BULK_CREATE_OCCUPIABLES = cc('BULK_CREATE_OCCUPIABLES');
export const BULK_CREATE_OCCUPIABLES_SUCCESS = cc('BULK_CREATE_OCCUPIABLES_SUCCESS');
export const BULK_CREATE_OCCUPIABLES_FAIL = cc('BULK_CREATE_OCCUPIABLES_FAIL');
export const BULK_UPDATE_OCCUPIABLES = cc('BULK_UPDATE_OCCUPIABLES');
export const BULK_UPDATE_OCCUPIABLES_SUCCESS = cc('BULK_UPDATE_OCCUPIABLES_SUCCESS');
export const BULK_UPDATE_OCCUPIABLES_FAIL = cc('BULK_UPDATE_OCCUPIABLES_FAIL');
