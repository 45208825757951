import { handleActions, Action } from 'redux-actions';

import {
  OpenDatesReservation,
  SpacemanApiUpdateOpenDatesResponse,
  SpacemanApiViewOpenDatesResponse,
} from 'features/inventory/redux/openDates/types';
import {
  FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION,
  FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_SUCCESS,
  FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_FAIL,
  UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION,
  UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_SUCCESS,
  UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_FAIL,
} from 'features/inventory/redux/openDates/constants';

export interface State {
  loaded: boolean;
  submitted: boolean;
  reservations: Array<OpenDatesReservation>;
  loading: boolean;
  // TODO: Find out the errors are.
  errors: Array<any>;
}

export interface OpenDatesSubset {
  openDates: State;
}

const initialState: State = {
  loading: false,
  loaded: true,
  submitted: false,
  errors: [],
  reservations: [],
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_SUCCESS]: (
      state: State,
      action: Action<SpacemanApiViewOpenDatesResponse>
    ): State => ({
      ...state,
      loading: false,
      loaded: true,
      errors: [],
      reservations: action.payload.reservations,
    }),

    [FETCH_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_FAIL]: (state: State): State => ({
      ...state,
      loading: false,
      loaded: true,
      errors: [],
      reservations: [],
    }),

    [UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
      errors: [],
      reservations: [],
    }),

    [UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_SUCCESS]: (
      state: State,
      action: Action<SpacemanApiUpdateOpenDatesResponse>
    ): State => ({
      ...state,
      loading: false,
      loaded: true,
      submitted: true,
      errors: action.payload.result.errors,
      reservations: action.payload.result.reservations,
    }),

    [UPDATE_RESERVATIONS_BY_ACCOUNT_AND_LOCATION_FAIL]: (
      state: State,
      action: Action<unknown>
    ): State => ({
      ...state,
      loading: false,
      loaded: false,
      submitted: true,
      errors: [action.error],
      reservations: [],
    }),
  },
  initialState
);

export default reducer;
