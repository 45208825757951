import React from 'react';

const ChevronIconSmall: React.FC<{ className?: string }> = ({ className, ...props }) => (
  <svg
    aria-labelledby="chevron-icon"
    width="12px"
    height="7px"
    viewBox="0 0 12 7"
    fill="none"
    className={className}
    {...props}
  >
    <title id="chevron-icon">Chevron Icon</title>
    <path
      d="M10.5866 0H1.41149C0.520588 0 0.0770901 1.07714 0.707055 1.70711L5.29284 6.29289C5.68337 6.68342 6.31653 6.68342 6.70706 6.29289L11.2928 1.70711C11.9228 1.07714 11.4775 0 10.5866 0Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronIconSmall;
