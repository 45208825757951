import React from 'react';

import { AnalyticsEvent } from 'lib/contextualAnalytics/types';
import { SearchResultItem } from 'store/modules/search/searchService/types';

import { SearchableEntity } from '../types';

export interface ResultContextType {
  uuid: string;
  analyticsData?: Partial<AnalyticsEvent>;
  entityType: SearchableEntity;
  data: SearchResultItem;
}

export const ResultContext = React.createContext<ResultContextType | null>(null);
