import React, { useMemo } from 'react';

import AnalyticsProvider from 'lib/contextualAnalytics/provider';

import { ResultSet as ResultSetType } from './types';
import Result from './result';

const ResultSet: React.FC<{ data: ResultSetType }> = ({
  data: { key, title, hideTitle, results },
}) => {
  const analyticsData = useMemo(() => ({ search_section: key }), [key]);
  return results.length ? (
    <AnalyticsProvider data={analyticsData}>
      <div>
        {!hideTitle && (
          <header className="bg-gray-97 font-mono text-gray-40 text-4xs py-2xs pl-[20px] uppercase">
            {title}
          </header>
        )}
        <div>
          {results.map(result => (
            <Result {...result} key={result.uuid} />
          ))}
        </div>
      </div>
    </AnalyticsProvider>
  ) : null;
};

export default ResultSet;
