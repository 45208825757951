import { EmojiName } from './emojiName';

export const EmojiAriaLabelMap = {
  [EmojiName.BALLOON]: 'balloon icon',
  [EmojiName.BUILDING]: 'building icon',
  [EmojiName.CALENDAR]: 'calendar icon',
  [EmojiName.CHECK_BOX_GREY]: 'check box icon',
  [EmojiName.COCKTAIL_GLASS]: 'cocktail glass icon',
  [EmojiName.COUCH_WITH_LAMP]: 'couch icon',
  [EmojiName.EYES]: 'eyes icon',
  [EmojiName.FACE_WITH_CROSSED_EYES]: 'crossed eyes face icon',
  [EmojiName.MAGNIFYING_GLASS]: 'magnifying glass icon',
  [EmojiName.MEDAL]: 'medal icon',
  [EmojiName.MEMO]: 'memo icon',
  [EmojiName.MONEY_BAG]: 'money bag',
  [EmojiName.PAGE_FACING_UP]: 'page icon',
  [EmojiName.PALETTE]: 'palette icon',
  [EmojiName.PARTY_POPPER]: 'party popper emoji',
  [EmojiName.PENSIVE_FACE]: 'sad face icon',
  [EmojiName.POINTING_UP]: 'pointing up icon',
  [EmojiName.SEE_NO_EVIL_MONKEY]: 'see no evil monkey icon',
  [EmojiName.SLIGHTLY_FROWNING_FACE]: 'slightly frowning face icon',
  [EmojiName.SMILING_FACE_WITH_OPEN_HANDS]: 'smiling face with hands icon',
  [EmojiName.SPARKLE]: 'sparkle icon',
  [EmojiName.SPEAK_NO_EVIL_MONKEY]: 'speak no evil monkey icon',
  [EmojiName.THINKING_FACE]: 'thinking face icon',
  [EmojiName.THREE_O_CLOCK]: 'clock icon',
  [EmojiName.THUMBS_UP]: 'thumbs up icon',
  [EmojiName.TRIANGULAR_FLAG]: 'red flag icon',
  [EmojiName.TRUCK]: 'truck icon',
  [EmojiName.TWO_PEOPLE]: 'two people icon',
  [EmojiName.WARNING]: 'warning icon',
  [EmojiName.WAVING_HAND]: 'waving hand icon',
  [EmojiName.MEGAPHONE]: 'megaphone icon',
  [EmojiName.NOTIFICATION_BELL]: 'notification bell icon',
};
