import { MimoReservable } from 'features/mimo/types';
import { MimoSubset } from 'features/mimo/redux';

export type ReservableFilterResult = {
  moveInRelevant: boolean;
  moveOutRelevant: boolean;
};
export type FilterResult = Hash<ReservableFilterResult>;

export type Filter<I> = (input: I, ...args: Array<any>) => FilterResult;

export type ReservablesFilter = Filter<Array<MimoReservable>>;

export type ReservablePredicator = (reservable: MimoReservable, ...args) => ReservableFilterResult;

export type FilterCombineProc = (
  h1: FilterResult | null,
  h2: FilterResult | null,
  key: string
) => ReservableFilterResult | null;

export type CreateFilterSelector<R> = (...args) => (state: any, props: any) => R;

export type FiltersGroup = Hash<(state: MimoSubset, props: any) => FilterResult | null>;

export type FilterOptionDefinition = {
  abbreviation: string;
  name: string;
};

export type FilterGroups = MoveTypeFilterGroup | MoveStatusFilterGroup;

export type FilterOption = FilterOptionDefinition & {
  key: string;
  count: number | undefined;
};

export interface FilterOptionHash {
  [filterType: string]: FilterOptionDefinition;
}

export interface FilterChipProps {
  title: string;
  options: Array<FilterOption>;
  popupTitle?: string;
  activeItems: string[];
  onChange: (aciveItems: string[]) => void;
}

export enum MoveTypeFilterGroup {
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  EXTERNAL_TRANSFER = 'EXTERNAL_TRANSFER',
  SIGNED_IN_LAST_48H = 'SIGNED_IN_LAST_48H',
  SELF_SERVE = 'SELF_SERVE',
  NEW_TO_WEWORK = 'NEW_TO_WEWORK',
  NEW_IN_BUILDING = 'NEW_IN_BUILDING',
}

export enum MoveStatusFilterGroup {
  SCHEDULED = 'SCHEDULED',
  MOVED_OUT = 'MOVED_OUT',
  READY_FOR_MOVE_IN = 'READY_FOR_MOVE_IN',
  MOVED_IN = 'MOVED_IN',
  DEFERRED = 'DEFERRED',
}

export enum OpenTasksFilterGroup {
  EMAIL_NOT_SENT = 'EMAIL_NOT_SENT',
  UNPAID_SR = 'UNPAID_SR',
  OPEN_REQUESTS = 'OPEN_REQUESTS',
}

export enum OfficeTypeFilterGroup {
  OFFICE = 'OFFICE',
  HOT_DESK = 'HOT_DESK',
  DEDICATED_DESK = 'DEDICATED_DESK',
}

export enum MimoFilterGroup {
  MOVE_TYPE = 'MOVE_TYPE',
  MOVE_STATUS = 'MOVE_STATUS',
  OPEN_TASKS = 'OPEN_TASKS',
  OFFICE_TYPE = 'OFFICE_TYPE',
  FLOORS = 'FLOORS',
  SEARCH = 'SEARCH',
}

export type MimoFilterItemProps = {
  name: string;
  abbreviation: string;
  key: string;
  count?: number;
  disabled: boolean;
  text: string;
};

export type MimoFilterGroupProps = {
  items: MimoFilterItemProps[];
  activeItems: string[];
  filterType: string;
  defaultTitle: string;
  onChange?: (event, group: MimoFilterGroupProps) => void;
};
