import { createRequestConstantsWithPrefix } from 'store/util/createReduxConstant';

const USER_GROUPS_PREFIX = 'userGroups';

export const EDIT_MEMBER_GROUP_FORM_NAME = 'editMemberGroupForm';

export const [
  FETCH_USER_GROUP,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAIL,
] = createRequestConstantsWithPrefix('fetchUserGroup', USER_GROUPS_PREFIX);

export const [
  UPDATE_USER_USER_GROUP,
  UPDATE_USER_USER_GROUP_SUCCESS,
  UPDATE_USER_USER_GROUP_FAIL,
] = createRequestConstantsWithPrefix('updateUserUserGroup', USER_GROUPS_PREFIX);

export const [
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP_FAIL,
] = createRequestConstantsWithPrefix('createUserGroup', USER_GROUPS_PREFIX);

export const [
  DELETE_USER_GROUP,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_FAIL,
] = createRequestConstantsWithPrefix('deleteUserGroup', USER_GROUPS_PREFIX);

export const [
  ADD_USERS_USER_GROUP,
  ADD_USERS_USER_GROUP_SUCCESS,
  ADD_USERS_USER_GROUP_FAIL,
] = createRequestConstantsWithPrefix('deleteUserGroup', USER_GROUPS_PREFIX);
