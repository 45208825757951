import { handleActions } from 'redux-actions';
import { keyBy, omit } from 'lodash';

import {
  FETCH_INVALIDATIONS,
  FETCH_INVALIDATIONS_SUCCESS,
  FETCH_INVALIDATIONS_FAIL,
  CREATE_INVALIDATION,
  CREATE_INVALIDATION_SUCCESS,
  CREATE_INVALIDATION_FAIL,
  DELETE_INVALIDATION,
  DELETE_INVALIDATION_SUCCESS,
  DELETE_INVALIDATION_FAIL,
} from 'features/companies/edit/redux/menaModularAdmin/constants';
import { State, Invalidation } from 'features/companies/edit/redux/menaModularAdmin/types';
import { ActionWithMeta } from 'store/types';
import { Action } from 'features/companies/redux/menaCompanies/types';

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  invalidationsFetched: false,
  formSubmitting: false,
  invalidations: {},
};

export const reducer = handleActions<State, any, any>(
  {
    [FETCH_INVALIDATIONS]: (state: State) => ({
      ...state,
      invalidationsFetched: false,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_INVALIDATIONS_SUCCESS]: (state: State, action: { payload: { result: {} } }) => {
      const invalidations = keyBy(action.payload.result, 'id');
      return {
        ...state,
        invalidationsFetched: true,
        loading: false,
        loaded: true,
        error: null,
        invalidations,
      };
    },
    [FETCH_INVALIDATIONS_FAIL]: (state: State, action: { payload: { message: string } }) => {
      return {
        ...state,
        invalidationsFetched: false,
        loading: false,
        loaded: false,
        error: action.payload.message,
      };
    },
    [CREATE_INVALIDATION]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [CREATE_INVALIDATION_SUCCESS]: (
      state: State,
      action: { payload: { result: Invalidation } }
    ) => {
      const invalidations = {
        ...state.invalidations,
        [action.payload.result.id]: action.payload.result,
      };
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        invalidations,
      };
    },
    [CREATE_INVALIDATION_FAIL]: (state: State, action: { payload: { message: string } }) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
    [DELETE_INVALIDATION]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [DELETE_INVALIDATION_SUCCESS]: (
      state: State,
      action: ActionWithMeta<{ id: string }> & Action
    ) => {
      const {
        meta: { id },
      } = action;
      let invalidations: Pick<Hash<Invalidation>, number>;
      if (state.invalidations[id]) {
        invalidations = omit(state.invalidations, id);
      } else {
        invalidations = { ...state.invalidations };
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        invalidations,
      };
    },
    [DELETE_INVALIDATION_FAIL]: (state: State, action: { payload: { message: string } }) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
  },
  initialState
);

export default reducer;
