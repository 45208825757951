import { createSelector } from 'reselect';

import { AuthSubset, State } from './reducer';

export const getAuth = (state: AuthSubset): State => state.auth;
export const getAuthData = createSelector(getAuth, auth => auth?.data);

export const getUserInfoFromAuth = createSelector(getAuthData, data => data?.userInfo ?? {});

export const getUserUuidFromAuth = createSelector(getUserInfoFromAuth, ({ userUuid }) => userUuid);

export const getUserEmailFromAuth = createSelector(getUserInfoFromAuth, ({ email }) => email);

export const getUserLocationUuidFromAuth = createSelector(
  getAuth,
  auth => auth?.data?.userInfo?.locationUuid ?? null
);

// retry if authorization fails or authentication token is expired
export const shouldRetryLogin = createSelector(getAuth, auth => !!auth.error);

// show failure message when wrong email chosen
export const showAuthTroubleshootCaption = createSelector(
  [getAuth],
  auth => auth.showTroubleshootCaption
);

export const getAuthError = (state: AuthSubset) => state.auth.error;

export const getAlgolia = createSelector(getAuthData, data => data.algolia);

export const getAlgoliaSecuredKeyFromState = createSelector(
  getAlgolia,
  algolia => algolia.securedKey
);
export const getAlgoliaValidUntilFromState = createSelector(
  getAlgolia,
  algolia => algolia.validUntil || 0
);
