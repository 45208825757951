import Discount from 'features/paperwork/discounts/commitmentBasedPromotions/models/discount';
import {
  startedOn,
  endedOn,
} from 'features/paperwork/discounts/commitmentBasedPromotions/utils/dateUtil';
import Suggestion from 'features/paperwork/discounts/commitmentBasedPromotions/models/suggestion';
import { PendingMoveIn } from 'features/paperwork/ducks/pendingMoveIns';
import { calculateTotalOfficePrice } from 'lib/discountModalUtil';

interface SerializedDiscount {
  amount: number;
  currency: string;
  ended_on: string;
  location_uuid: string;
  move_in_date: string;
  promotion_code: string | null | undefined;
  started_on: string;
}

const serializeDiscount = (
  discount: Discount,
  suggestion: Suggestion,
  pendingReservations: Array<PendingMoveIn>,
  index: number
): SerializedDiscount => {
  const { currency, building_uuid: locationUuid, moveIn } = pendingReservations[0];
  const startDate = startedOn(moveIn, index);
  const endDate = endedOn(startDate);
  const totalPrice = calculateTotalOfficePrice(pendingReservations);

  return {
    amount: discount.netPrice(totalPrice),
    currency,
    ended_on: endDate,
    location_uuid: locationUuid,
    move_in_date: moveIn,
    promotion_code: suggestion.getCode(),
    started_on: startDate,
  };
};

export const serializeDiscountsForContract = (
  discounts: Array<Discount>,
  suggestion: Suggestion | null | undefined,
  pendingReservations: Array<PendingMoveIn> | null | undefined
): Array<SerializedDiscount> => {
  // Be defensive in case a defective contract key was used.
  if (!suggestion || !pendingReservations || pendingReservations.length === 0) {
    return [];
  }

  return discounts.reduce(
    (
      serializedDiscounts: Array<SerializedDiscount>,
      discount,
      index
    ): Array<SerializedDiscount> => {
      if (discount.toNumber() === 0) {
        return serializedDiscounts;
      }

      return serializedDiscounts.concat(
        serializeDiscount(discount, suggestion, pendingReservations, index)
      );
    },
    []
  );
};

// We assume that the keys in discounts is a subset of the keys in suggestions and contracts
export const serialize = (
  discounts: Hash<Array<Discount>>,
  suggestions: Hash<Suggestion>,
  pendingReservations: Hash<Array<PendingMoveIn>>
): Array<SerializedDiscount> =>
  Object.keys(discounts).reduce(
    (serializedDiscounts: Array<SerializedDiscount>, key): Array<SerializedDiscount> => [
      ...serializedDiscounts,
      ...serializeDiscountsForContract(discounts[key], suggestions[key], pendingReservations[key]),
    ],
    []
  );
