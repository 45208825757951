import { handleActions } from 'redux-actions';

import {
  ProductReservationAttrs,
  ProductItem,
  PreviousAgreement,
  Suggestion,
  OutOfPolicyAcknowledgement,
  CommitmentTermInputAttrs,
  CommitmentTermInput,
  OOPRationale,
} from 'features/paperwork/contracts-flow/types';
import { ContractsError, PPW_ERROR } from 'features/paperwork/contracts-flow/types/errors';
import { OfficeInfoAttrs } from 'features/paperwork/contracts-flow/types/officeInfo';
import {
  firstStartOfMonth,
  formatToISODate,
} from 'features/paperwork/contracts-flow/sections/product/utils';
import { PromotionModel } from 'features/paperwork/types';
import { DiscountType } from 'features/paperwork/contracts-flow/layouts/types';

import {
  FETCH_PRODUCTS_RESERVATION,
  FETCH_PRODUCTS_RESERVATION_SUCCESS,
  FETCH_PRODUCTS_RESERVATION_FAIL,
  EDIT_PRODUCT_RESERVATION,
  CLEAR_PRODUCTS_RESERVATION,
  TOGGLE_OUT_OF_POLICY_ACKNOWLEDEMENT,
  CHANGE_OUT_OF_POLICY_DISCOUNT_REASON,
  CHANGE_OUT_OF_POLICY_APPROVER,
  CHANGE_OUT_OF_POLICY_RATIONALE,
  NEW_PROMOTION,
  EDIT_PROMOTION,
  EDIT_TERM_FOR_PROMOTION,
  NEW_TERM_FOR_PROMOTION,
  PERPETUATE_PROMOTION_DISCOUNT,
  TOGGLE_SINGLE_AGREEMENT,
  SET_DISCOUNT_TYPE,
  SET_COMMITMENT_START_DATE,
  REMOVE_PROMOTION,
  ADD_PRODUCT_RESERVATION,
  REMOVE_PRODUCT_RESERVATION,
  ADD_PRODUCT_RESERVATION_LOADING,
  ADD_PRODUCT_RESERVATION_FAILED,
  ADD_PRODUCT_RESERVATION_LOADED,
} from './consts';
import discounts from './discounts';
import suggestions from './suggestions';
import officeInfo from './officeInfo';
import commitmentTerm, {
  updateProratedByMoveInDate,
  getEndDate,
  getAffectedDiscountsByDateChange,
} from './commitmentTerm';
import { validateTerm, validateDiscounts } from './validators';
import { getMaxSuggestedDiscount } from './selectors';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: ContractsError | null;
  officeInfo: Hash<OfficeInfoAttrs> | null;
  discountSuggestions: {
    loading: boolean;
    suggestions: Hash<Suggestion>;
  };
  outOfPolicyAcknowledged: OutOfPolicyAcknowledgement;
  items: Array<ProductItem>;
  previousAgreements: Array<PreviousAgreement>;
  usingPromotion: boolean;
  promotion: {
    selectedPromotion: PromotionModel | undefined;
    term?: CommitmentTermInput;
    isPromotionDiscountPerpetuating: boolean;
    amount: number;
  };
  singleAgreement: boolean;
  discountType: DiscountType;
  commitmentStartDate: Date;
  upgrades: {
    loading: boolean;
  };
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  officeInfo: null,
  discountSuggestions: {
    loading: false,
    suggestions: {},
  },
  outOfPolicyAcknowledged: {
    checked: false,
    discountReason: undefined,
    approver: '',
    appliedPromoCode: '',
    rationale: OOPRationale.ABOVE_CBP,
  },
  items: [],
  previousAgreements: [],
  usingPromotion: false,
  promotion: {
    selectedPromotion: undefined,
    term: undefined,
    isPromotionDiscountPerpetuating: false,
    amount: 0,
  },
  singleAgreement: false,
  discountType: DiscountType.NO_DISCOUNT,
  commitmentStartDate: firstStartOfMonth(formatToISODate(new Date())),
  upgrades: {
    loading: false,
  },
};

const getEquivalentAmountFrom = (value, total, newTotal) => {
  const percentageToApply = (value * 100) / total;
  return (percentageToApply * newTotal) / 100;
};

const reducer = handleActions<State, any, { reservableUuid: string }>(
  {
    [FETCH_PRODUCTS_RESERVATION]: (state: State) => ({
      ...state,
      loading: true,
    }),
    [FETCH_PRODUCTS_RESERVATION_SUCCESS]: (
      state: State,
      action: {
        payload: {
          items: Array<ProductItem>;
          previousAgreement: Array<PreviousAgreement>;
        };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      previousAgreements: action.payload.previousAgreement,
      items: action.payload.items,
    }),
    [FETCH_PRODUCTS_RESERVATION_FAIL]: (
      state: State,
      action: { payload: { message: string } }
    ) => ({
      ...state,
      loading: false,
      loaded: false,
      error: {
        message: action?.payload?.message,
        code: PPW_ERROR.RESERVATIONS,
      },
    }),
    [ADD_PRODUCT_RESERVATION]: (
      state: State,
      action: {
        payload: {
          productItem: ProductItem;
        };
      }
    ) => {
      let productItem = action.payload.productItem;

      if (state.discountType !== DiscountType.NO_DISCOUNT) {
        const { terms, discounts, productReservation } = state.items[0];
        const newDiscounts = discounts.map(discount => {
          if (!state.usingPromotion) {
            return {
              ...discount,
              amount: 0,
              errors: {},
            };
          }
          return {
            ...discount,
            amount: getEquivalentAmountFrom(
              discount.amount,
              productReservation.marketPrice,
              productItem.productReservation.marketPrice
            ),
          };
        });
        productItem = {
          ...productItem,
          terms,
          discounts: newDiscounts,
        };
      }

      return {
        ...state,
        items: [...state.items, productItem],
      };
    },
    [REMOVE_PRODUCT_RESERVATION]: (
      state: State,
      action: {
        payload: {
          id: string;
        };
      }
    ) => {
      const idx = state.items.findIndex(
        ({ productReservation }) => productReservation.id === action.payload.id
      );
      return {
        ...state,
        items: [...state.items.slice(0, idx), ...state.items.slice(idx + 1)],
      };
    },
    [ADD_PRODUCT_RESERVATION_LOADING]: (state: State) => ({
      ...state,
      upgrades: {
        loading: true,
      },
    }),
    [ADD_PRODUCT_RESERVATION_LOADED]: (state: State) => ({
      ...state,
      upgrades: {
        loading: false,
      },
    }),
    [ADD_PRODUCT_RESERVATION_FAILED]: (state: State) => ({
      ...state,
      upgrades: {
        loading: false,
      },
    }),
    [ADD_PRODUCT_RESERVATION_LOADING]: (state: State) => ({
      ...state,
      upgrades: {
        loading: true,
      },
    }),
    [EDIT_PRODUCT_RESERVATION]: (
      state: State,
      action: {
        payload: { id: string; attrs: ProductReservationAttrs; proratedOfficePrice: number };
        meta: { reservableUuid };
      }
    ) => {
      const idx = state.items.findIndex(
        ({ productReservation }) => productReservation.id === action.payload.id
      );
      const toUpdate = state.items[idx];
      const attrs = Object.keys(action.payload.attrs).reduce((acc, key) => {
        acc[key] = {
          ...toUpdate.productReservation[key],
          value: action.payload.attrs[key],
        };
        return acc;
      }, {});

      const moveInDate =
        action.payload.attrs.startDate || toUpdate.productReservation.startDate.value;
      const productReservation = {
        ...toUpdate.productReservation,
        ...attrs,
      };

      let updatedDiscounts;
      let terms;
      if (toUpdate.terms.length) {
        const updatedTerm = {
          ...toUpdate.terms[0],
          startDate: {
            ...toUpdate.terms[0].startDate,
            value: firstStartOfMonth(moveInDate),
          },
          endDate: {
            ...toUpdate.terms[0].endDate,
            value: toUpdate.terms[0].length.value
              ? getEndDate(firstStartOfMonth(moveInDate), toUpdate.terms[0].length.value)
              : toUpdate.terms[0].endDate.value,
          },
        };

        terms = [updatedTerm, ...toUpdate.terms.slice(1)].map(term =>
          validateTerm(term, moveInDate)
        );

        const updatedByProratedDiscounts = updateProratedByMoveInDate(
          toUpdate.discounts,
          moveInDate,
          0,
          updatedTerm.id
        );
        const affectedDiscounts = getAffectedDiscountsByDateChange(
          updatedByProratedDiscounts,
          toUpdate.terms[0].startDate.value,
          updatedTerm.startDate.value
        );
        updatedDiscounts = validateDiscounts(
          affectedDiscounts,
          null,
          terms[0],
          toUpdate.productReservation.chargePrice,
          getMaxSuggestedDiscount(
            state,
            toUpdate.productReservation.uuid || toUpdate.productReservation.id,
            terms[0].length.value
          )
        );
      }

      const updated = {
        ...toUpdate,
        productReservation,
        terms: terms || toUpdate.terms,
        discounts: updatedDiscounts || toUpdate.discounts,
        proratedPrice: action.payload.proratedOfficePrice,
        setupFee: action.payload.attrs.setupFee,
      };

      return {
        ...state,
        items: [...state.items.slice(0, idx), updated, ...state.items.slice(idx + 1)],
      };
    },
    [TOGGLE_OUT_OF_POLICY_ACKNOWLEDEMENT]: (
      state: State,
      action: { payload: { checked: boolean } }
    ) => ({
      ...state,
      outOfPolicyAcknowledged: {
        ...state.outOfPolicyAcknowledged,
        checked: action.payload.checked,
      },
    }),
    [CHANGE_OUT_OF_POLICY_DISCOUNT_REASON]: (
      state: State,
      action: { payload: { value: string } }
    ) => {
      return {
        ...state,
        outOfPolicyAcknowledged: {
          ...state.outOfPolicyAcknowledged,
          discountReason: action.payload.value,
        },
      };
    },
    [CHANGE_OUT_OF_POLICY_RATIONALE]: (
      state: State,
      action: { payload: { value: OOPRationale } }
    ) => {
      return {
        ...state,
        outOfPolicyAcknowledged: {
          ...state.outOfPolicyAcknowledged,
          rationale: action.payload.value,
        },
      };
    },
    [CHANGE_OUT_OF_POLICY_APPROVER]: (state: State, action: { payload: { value: string } }) => {
      return {
        ...state,
        outOfPolicyAcknowledged: {
          ...state.outOfPolicyAcknowledged,
          approver: action.payload.value,
        },
      };
    },
    [NEW_PROMOTION]: (state: State) => ({
      ...state,
      usingPromotion: true,
      promotion: {
        ...state.promotion,
        amount: 0,
      },
    }),
    [EDIT_PROMOTION]: (
      state: State,
      action: { payload: { promotion: PromotionModel | undefined } }
    ) => ({
      ...state,
      promotion: {
        ...state.promotion,
        selectedPromotion: action.payload.promotion,
      },
    }),
    [REMOVE_PROMOTION]: (state: State) => ({
      ...state,
      usingPromotion: false,
      promotion: {
        ...state.promotion,
        isPromotionDiscountPerpetuating: false,
      },
    }),
    [PERPETUATE_PROMOTION_DISCOUNT]: (state: State) => ({
      ...state,
      promotion: {
        ...state.promotion,
        isPromotionDiscountPerpetuating: !state.promotion.isPromotionDiscountPerpetuating,
      },
    }),
    [NEW_TERM_FOR_PROMOTION]: (state: State, action: { payload: { id: string } }) => {
      const idx = state.items.findIndex(
        ({ productReservation }) => productReservation.id === action.payload.id
      );
      const productItem = state.items[idx];

      const term = productItem.terms[0];
      return {
        ...state,
        promotion: {
          ...state.promotion,
          term,
        },
      };
    },
    [EDIT_TERM_FOR_PROMOTION]: (
      state: State,
      action: { payload: { attrs: CommitmentTermInputAttrs } }
    ) => {
      const term = state.promotion.term;
      if (term) {
        const attrs = Object.keys(action.payload.attrs).reduce((acc, key) => {
          acc[key] = {
            ...term[key],
            value: action.payload.attrs[key],
          };
          return acc;
        }, {});

        const termFromAttrs = {
          ...term,
          ...attrs,
        };

        const updatedTerm = {
          ...termFromAttrs,
          endDate: {
            ...termFromAttrs.endDate,
            value: termFromAttrs.length?.value
              ? getEndDate(termFromAttrs.startDate.value, termFromAttrs.length.value)
              : null,
          },
        };

        return {
          ...state,
          promotion: {
            ...state.promotion,
            term: updatedTerm,
          },
        };
      }

      return {
        ...state,
        promotion: {
          ...state.promotion,
          term,
        },
      };
    },
    [TOGGLE_SINGLE_AGREEMENT]: (
      state: State,
      action: { payload: { singleAgreementStatus: boolean } }
    ) => ({
      ...state,
      singleAgreement: action.payload.singleAgreementStatus,
    }),
    [SET_DISCOUNT_TYPE]: (state: State, action: { payload: { discountType: DiscountType } }) => ({
      ...state,
      discountType: action.payload.discountType,
      items: state.items.map(item => ({
        ...item,
        discounts: [],
        terms: [],
      })),
    }),
    [SET_COMMITMENT_START_DATE]: (state: State, action: { payload: { date: Date } }) => ({
      ...state,
      commitmentStartDate: action.payload.date,
    }),
    ...commitmentTerm,
    ...discounts,
    ...suggestions,
    ...officeInfo,
    [CLEAR_PRODUCTS_RESERVATION]: () => initialState,
  },
  initialState
);

export default reducer;
