import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { EmployeePermissions } from '@wework/we-auth-react';

import { getItems } from 'features/app/appLayout/sideNav/items';
import { getSortedExperiments } from 'store/middlewares/experimentsHelper';

const permissions = Object.values(EmployeePermissions).reduce(
  (allPermissions, permission) => ({ ...allPermissions, [permission]: true }),
  {}
);
export const useGetCurrentWorkflow = (): string => {
  const location = useLocation();
  const experimentGroups = Object.fromEntries(useSelector(getSortedExperiments));
  const workflowItems = useMemo(
    () => getItems(experimentGroups, permissions, undefined).flatMap(route => route.items),
    [experimentGroups]
  );
  const pathname = location.pathname.split('/').pop();
  const workflow = workflowItems.find(item => `/${item?.url?.split('/').pop()}` === `/${pathname}`);

  if (workflow) return workflow?.label || '';

  return location.pathname.split('/').shift() || location.pathname.split('/')?.[1] || '';
};
