import config from 'config';
import { Location } from 'store/modules/locations';
import { tour as tourMapping } from 'features/visitors/redux/serverMapping';
import { formatDate } from 'features/visitors/util';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { AuthPrefix, httpFetcher } from 'lib/fetchAPI';

const fetcher = httpFetcher({
  domain: config.toursAPI.tours.uri,
  entry: 'tours',
  authPrefix: AuthPrefix.TOKEN,
  getAccessToken,
});

export async function fetchTours(location: Location, date: Date, signal?: AbortSignal) {
  const accessToken = await getAccessToken();

  const result = await fetcher(
    'v1/tours',
    {
      building_id: location.uuid,
      date: formatDate(date, location),
    },
    false,
    'GET',
    signal,
    {
      'X-MEMBER-TOKEN': `${accessToken}`,
    }
  );

  return result.map(tour => tourMapping(location?.time_zone, tour));
}
