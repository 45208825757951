import { Action } from 'redux-actions';

import config from 'config';
import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { createRequestAction } from 'store/util';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { camelCaseJson } from 'lib/util';
import cc from 'store/util/createReduxConstant';

import { Suggestions } from '../config/discountTypes';
import { CBPItems } from '../config/types';

// Action Constants
export const FETCH_DISCOUNT_SUGGESTIONS = cc('FETCH_DISCOUNT_SUGGESTIONS');
export const FETCH_DISCOUNT_SUGGESTIONS_SUCCESS = cc('FETCH_DISCOUNT_SUGGESTIONS_SUCCESS');
export const FETCH_DISCOUNT_SUGGESTIONS_FAIL = cc('FETCH_DISCOUNT_SUGGESTIONS_FAIL');

const types: RequestActionTypes = [
  {
    type: FETCH_DISCOUNT_SUGGESTIONS,
    meta: { keepCurrentResult: true },
  },
  FETCH_DISCOUNT_SUGGESTIONS_SUCCESS,
  FETCH_DISCOUNT_SUGGESTIONS_FAIL,
];

// Initial State
export const initialState = {
  loading: false,
  loaded: false,
  data: {},
  error: null,
};

const handlePayload = (
  response: Action<any>,
  previousResponse: Hash<Suggestions>
): Hash<Suggestions> => {
  const responsePayload: Hash<Suggestions> = camelCaseJson(
    response.payload?.result?.contracts ?? {}
  );

  const itemUuid = Object.keys(responsePayload)[0];

  const previousSuggestions = previousResponse[itemUuid]?.promotionSuggestions ?? [];
  const suggestions = responsePayload[itemUuid]?.promotionSuggestions ?? [];

  return {
    ...previousResponse,
    [itemUuid]: {
      ...responsePayload[itemUuid],
      promotionSuggestions: [...previousSuggestions, ...suggestions],
    },
  };
};

type InventoryItemDiscountsRequest = {
  contracts: Hash<CBPItems>;
  kind: string;
  commitment_length?: string;
};

export const fetchInventoryItemDiscounts = (
  uuid: string | null | undefined,
  cbpItems: CBPItems | null | undefined,
  commitmentLength?: string
) => (dispatch: Dispatch<BaseAction>) => {
  const itemUuid = uuid || '';
  const body: InventoryItemDiscountsRequest = {
    contracts: {
      [itemUuid]: cbpItems,
    },
    kind: 'Variable',
  };

  if (commitmentLength != null) {
    body.commitment_length = commitmentLength;
  }

  const requestAction = createRequestAction({
    body,
    endpoint: `${config.discounts.uri}/api/v1/suggestions/available`,
    method: 'POST',
    types,
  });

  return dispatch(requestAction);
};

const reducer = createRequestReducer<Hash<Suggestions>, any>(types, {}, handlePayload);

export default reducer;
