import { handleActions } from 'redux-actions';
import queryString from 'query-string';

import { ActionWithPayload, Dispatch, BaseAction } from 'store/types';
import SalesAPI from 'api/sales';
import cc from 'store/util/createReduxConstant';
import {
  SalesforceOpportunityPayload,
  SalesforceOpportunity,
  SalesforceParams,
  opportunityPayloadToOpportunity,
} from 'features/paperwork/types';

export const INITIATE_SALESFORCE_OPPORTUNITY = cc('SALESFORCE_OPPORTUNITY/INITIATE');
export const INITIATE_SALESFORCE_OPPORTUNITY_FAIL = cc('SALESFORCE_OPPORTUNITY/INITIATE_FAIL');
export const CLEAR_SALESFORCE_OPPORTUNITY = cc('CLEAR_SALESFORCE_OPPORTUNITY');

export const extractUrlSfparams = (urlParamsString: string): SalesforceParams | null => {
  const { sfOpportunityId, waitForSfSync, building, moveindate } = queryString.parse(
    urlParamsString
  );

  return sfOpportunityId
    ? {
        sfOpportunityId: sfOpportunityId as string,
        waitForSfSync: (waitForSfSync as string) === 'true',
        building: building as string,
        moveindate: moveindate as string,
      }
    : null;
};

export const getOpportunityFromParams = (params: SalesforceParams): SalesforceOpportunity => {
  const { sfOpportunityId, waitForSfSync, building, moveindate } = params;

  return {
    id: sfOpportunityId,
    meta: {
      waitForSfSync,
      building,
      moveindate,
    },
  };
};

export const clearSalesforceOpportunity = () => ({
  type: CLEAR_SALESFORCE_OPPORTUNITY,
});

type Payload = {
  opportunity?: SalesforceOpportunity;
  error?: Error;
};

export const initiateSalesforceOpportunity = (opportunity: SalesforceOpportunity) => async (
  dispatch: Dispatch<ActionWithPayload<Payload> | BaseAction>
) => {
  try {
    const salesAPIClient = new SalesAPI();
    const salesforceOpportunityResponse = await salesAPIClient.getOpportunityStage(
      window.atob(opportunity.id)
    );

    dispatch({
      type: INITIATE_SALESFORCE_OPPORTUNITY,
      payload: {
        opportunity: {
          ...opportunityPayloadToOpportunity(
            (salesforceOpportunityResponse as { data: SalesforceOpportunityPayload }).data
          ),
          meta: opportunity.meta,
        },
      },
    });
  } catch (error) {
    dispatch({
      type: INITIATE_SALESFORCE_OPPORTUNITY_FAIL,
      payload: {
        error,
      },
    });
  }
};

export type State = SalesforceOpportunity | null | undefined;

export const initialState: State = null;

export const reducer = handleActions<State, any>(
  {
    [INITIATE_SALESFORCE_OPPORTUNITY]: (_state: State, action: ActionWithPayload<Payload>) =>
      action.payload.opportunity,
    [CLEAR_SALESFORCE_OPPORTUNITY]: () => initialState,
  },
  initialState
);

export default reducer;
