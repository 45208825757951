import { getFromStorage } from './util';

export default class PersistentObject<T extends {}> {
  storage: Storage;
  key: string;

  constructor(storage: Storage, key: string) {
    this.storage = storage;
    this.key = key;
  }

  get(): T | null {
    return getFromStorage(this.storage, this.key);
  }

  set(value: T): void {
    this.storage.setItem(this.key, JSON.stringify(value));
  }

  clear(): void {
    this.storage.removeItem(this.key);
  }

  // TODO(grozki): Flow %checks somehow?
  exists(): boolean {
    return Boolean(this.get());
  }
}
