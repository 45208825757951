import { createAction, handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';
import { GlobalState } from 'store/modules';

export type State = {
  actionUrl: string | undefined;
  isModalOpen: boolean;
  targetLocationUuid: string | undefined;
};

export interface NotificationsDeepLinkSubset {
  notificationsDeepLink: State;
}

// actions
export const RESET_NOTIFICATION_DEEP_LINK_STATE = cc('RESET_NOTIFICATION_DEEP_LINK_STATE');
export const SET_LOCATION_CHANGE_MODAL_CLOSED = cc('SET_LOCATION_CHANGE_MODAL_CLOSED');
export const SET_LOCATION_CHANGE_MODAL_OPEN = cc('SET_LOCATION_CHANGE_MODAL_OPEN');
export const SET_NOTIFICATION_DEEP_LINK = cc('SET_NOTIFICATION_DEEP_LINK');

// action creators
export const resetNotificationDeepLinkState = createAction(RESET_NOTIFICATION_DEEP_LINK_STATE);

export const setLocationChangeModalOpen = createAction(SET_LOCATION_CHANGE_MODAL_OPEN);

export const setLocationChangeModalClosed = createAction(SET_LOCATION_CHANGE_MODAL_CLOSED);

export const setNotificationDeepLink = createAction(
  SET_NOTIFICATION_DEEP_LINK,
  (actionUrl: string, targetLocationUuid: string) => ({ actionUrl, targetLocationUuid })
);

// reducer initial state
export const notificationsDeepLinkInitialState = {
  actionUrl: undefined,
  isModalOpen: false,
  targetLocationUuid: undefined,
};

// reducer
const notificationsDeepLinkReducer = handleActions<State, any>(
  {
    [SET_LOCATION_CHANGE_MODAL_CLOSED]: state => ({
      ...state,
      isModalOpen: false,
    }),

    [SET_LOCATION_CHANGE_MODAL_OPEN]: state => ({
      ...state,
      isModalOpen: true,
    }),

    [SET_NOTIFICATION_DEEP_LINK]: (state, action) => ({
      ...state,
      actionUrl: action.payload?.actionUrl,
      targetLocationUuid: action.payload?.targetLocationUuid,
    }),

    [RESET_NOTIFICATION_DEEP_LINK_STATE]: () => ({
      ...notificationsDeepLinkInitialState,
    }),
  },
  notificationsDeepLinkInitialState
);

// selectors
export const getNotificationsDeepLink = (state: GlobalState) => ({
  actionUrl: state.notificationsDeepLink?.actionUrl,
  isModalOpen: state.notificationsDeepLink?.isModalOpen,
  targetLocationUuid: state.notificationsDeepLink?.targetLocationUuid,
});

export default notificationsDeepLinkReducer;
