import { LocationAmenities as MLSLocationAmenities } from '@wework/proton-grpcwebtext/space/marketable_location/models_pb';

import cc from 'store/util/createReduxConstant';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { getLocationAmenities } from 'features/building/serializers/locationAmenitiesSerializer';
import { LocationAmenities } from 'features/building/types';

export const FETCH_LOCATION_AMENITIES = cc('FETCH_LOCATION_AMENITIES');
export const FETCH_LOCATION_AMENITIES_SUCCESS = cc('FETCH_LOCATION_AMENITIES_SUCCESS');
export const FETCH_LOCATION_AMENITIES_FAIL = cc('FETCH_LOCATION_AMENITIES_FAIL');

const reducer = createRequestReducer<LocationAmenities | null, MLSLocationAmenities[] | undefined>(
  [FETCH_LOCATION_AMENITIES, FETCH_LOCATION_AMENITIES_SUCCESS, FETCH_LOCATION_AMENITIES_FAIL],
  null,
  res => getLocationAmenities(res.payload)
);

export default reducer;
