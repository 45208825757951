import React from 'react';

import Colors from 'styles/colors';

const style: { [key: string]: React.CSSProperties } = {
  flag: {
    backgroundColor: Colors.secondary,
    bottom: 0,
    fontWeight: 700,
    padding: '3px 5px',
    position: 'fixed',
    right: 0,
    textAlign: 'center',
    zIndex: 20,
  },
};

type Props = Readonly<{ text?: string }>;

const Flag = ({ text }: Props) => <div style={style.flag}>{text}</div>;

export default Flag;
