import React, { useContext, useMemo } from 'react';

import { ContextData } from './types';

import { AnalyticsContext } from './index';

type Props = {
  children: React.ReactNode;
  data: ContextData;
};

// AnalyticsProvider is a component that we use whenever we need to create a new context
const AnalyticsProvider: React.FC<Props> = ({ data, children }) => {
  const previousContexts = useContext(AnalyticsContext);
  const contexts = useMemo(() => [...previousContexts, data], [previousContexts, data]);

  return <AnalyticsContext.Provider value={contexts}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
