import { createRequestReducer } from 'store/util/createRequestReducer';
import {
  FETCH_ADDR_NAME,
  FETCH_ADDR_NAME_FAIL,
  FETCH_ADDR_NAME_SUCCESS,
} from 'features/building/ducks/addressNameConsts';

type ReducerType = { [key: string]: string };

const reducer = createRequestReducer<ReducerType, ReducerType>(
  [FETCH_ADDR_NAME, FETCH_ADDR_NAME_SUCCESS, FETCH_ADDR_NAME_FAIL],
  {},
  res => res.payload
);

export default reducer;
