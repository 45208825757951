// Selectors are a pattern allowing you to compute derived data efficiently. Selectors are not recomputed
import { createSelector } from 'reselect';
import {
  flatten,
  get,
  groupBy,
  includes,
  isEmpty,
  keyBy,
  map,
  mapValues,
  orderBy,
  sortBy,
  uniqBy,
} from 'lodash';
import { match } from 'react-router-dom';
import moment from 'moment-timezone';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';

import { PrimaryReservationsSubset } from 'features/companies/redux/primaryReservations/types';
import { ReservationsSubset } from 'features/companies/redux/reservations/reducer';
import { EmployeesSubset } from 'features/employees/redux/employees/reducer';
import { PaperworkSubset } from 'features/paperwork/ducks';
import config from 'config';
import { getUserLocationUuidFromAuth } from 'features/auth/selectors';
import { Reservation } from 'features/companies/redux/reservations/types';
import { AppSubset } from 'store/modules/app';
import { ListingsSubset, State as ListingsState } from 'features/alchemist/ducks/listings';
import { Location, LocationsSubset } from 'store/modules/locations';
import { MembersSubset } from 'store/modules/members';
import { OccupanciesSubset, Occupancy } from 'store/modules/occupancies/types';
import { OfficeHoldsSubset } from 'store/modules/officeHolds';
import { InventorySearchSubset } from 'features/inventorySearch/ducks';
import { isSSFilterKubeLocationsExperimentOn } from 'features/inventorySearch/ducks/experimentSelectors';
import { Price } from 'features/inventorySearch/config/types';
import { getLocalizedPrice } from 'lib/util';
import { getCurrentLocationState, getHomeLocationState } from 'store/modules/locations/selectors';
import { getCurrentLocationUuid as getCurrentLocationUuidFromLocationsState } from 'store/modules/siLocations/selectors';
import { BuildingsSubset } from 'store/modules/buildings';
import { getExperiments, ExperimentGroups } from 'store/middlewares/experimentsHelper';

export const getBuildings = (state: BuildingsSubset) => Object.values(state.buildings.byId);
export const getInventorySearch = (state: InventorySearchSubset) => state.inventorySearch;
export const getBuildingsById = (state: BuildingsSubset) => state.buildings.byId;
export const getEmployeesByRole = (state: EmployeesSubset) => state.employees.byRole;
export const getOfficeInventory = (state: InventorySearchSubset) =>
  state.inventorySearch.inventoryQueryService.result.rawPayload.inventory;
export const getListings = (state: ListingsSubset): ListingsState => state.listings;
export const getLocations = (state: LocationsSubset): Array<Location> => {
  // TODO(grozki): This is here because this is a very central and common selector and many tests fail
  //  over not initializing locations. It can be removed once the warning stops appearing in tests.
  if (__DEVELOPMENT__ || __TEST__) {
    if (!state.locations || !state.locations.data) {
      // eslint-disable-next-line no-console
      console.warn(
        'getLocations selector found no location data in the Redux Store, this is possibly because the selector ' +
          'was used before the application was fully initialized.'
      );

      return [];
    }
  }

  return state.locations.data;
};
export const getNonMigratedLocations = createSelector(
  [getLocations, isSSFilterKubeLocationsExperimentOn],
  (locations, isSSFilterKubeLocationsExperimentOn) =>
    isSSFilterKubeLocationsExperimentOn
      ? locations.filter(({ isMigrated }) => !isMigrated)
      : locations
);
export const getLocationsLoading = (state: LocationsSubset): boolean => state.locations.loading;
export const getLocationsLoaded = (state: LocationsSubset): boolean => state.locations.loaded;
export const getLocationsByUuid = (state: LocationsSubset): Hash<Location> =>
  keyBy(state.locations.data, location => location?.uuid);
export const getNonKubeMigratedLocationsByUuid = createSelector(
  [getNonMigratedLocations],
  locations => keyBy(locations, location => location?.uuid)
);

export const getAllLocationsForPrivateAccess = (state: LocationsSubset): Array<{}> =>
  state.locations.data;

export const getLocationsNamesByUuid = createSelector<
  LocationsSubset,
  any,
  Hash<Location>,
  Hash<{ name: string }>
>([getLocationsByUuid], locations => mapValues(locations, location => ({ name: location?.name })));

export const getMembers = (state: MembersSubset) => state.members.byUuid;

export const getOccupancies = (state: OccupanciesSubset) => state.occupancies.byLocationUuid;
export const getOfficeHolds = (state: OfficeHoldsSubset) => state.officeHolds.data;
export const getOfficeHoldsByLocationUuid = (state: OfficeHoldsSubset) =>
  state.officeHolds.dataByLocationUuid;
export const getContractDetails = (state: PaperworkSubset) => state.paperwork.contractDetails;

export const getParams = (
  _: unknown,
  props: { match?: match<{ code?: string }> }
): { code?: string } => props?.match?.params ?? {};

export const isInHardcopyLocation = (
  reservations: Array<Reservation>,
  locationsByUuid: Hash<Location>
): boolean =>
  reservations.some((reservation: Reservation): boolean =>
    config.hardcopyProcessBuildingCountries.includes(
      locationsByUuid[reservation.location_uuid]?.country
    )
  );

export const getPrimaryReservations = (
  state: ReservationsSubset,
  props: { match: match<{ uuid?: string }> }
): Array<Reservation> => {
  const uuid = props?.match?.params?.uuid ?? '';

  if (!uuid) {
    return [];
  }

  return (state.reservations.byAccountUuid[uuid] ?? []).filter(
    (res): boolean => res.type === 'PrimaryReservation'
  );
};

// Only HD and WeM.
export const getAnywhereMembershipReservations = (
  state: ReservationsSubset,
  props: { match: match<{ uuid?: string }> }
): Array<Reservation> => {
  const uuid = props?.match?.params?.uuid ?? '';

  if (!uuid) {
    return [];
  }

  return (state.reservations.byAccountUuid[uuid] ?? []).filter(
    (res): boolean => res.type === 'AnywhereReservation' && res.anywhere_membership
  );
};

export const isGlobalAccess = (state: PrimaryReservationsSubset, props: { uuid: string }) => {
  if (isEmpty(state.primaryReservations.byCompanyUuid[props.uuid])) {
    return false;
  }
  const allAccessPlusResourceUuid = config.allAccessPlusResourceUuid;
  return (
    get(
      state,
      `primaryReservations.byCompanyUuid[${props.uuid}][0].reservables[0].reservable_uuid`,
      ''
    ) === allAccessPlusResourceUuid
  );
};

// TODO: remove this along with locations store module
// https://jira.weworkers.io/browse/IMS-2111
export const getCurrentLocationUuidFromParams = createSelector(
  [getParams, getLocations],
  (params, locations) => {
    if (!params?.code) {
      return null;
    }
    const location = locations.find(loc => {
      return (
        loc.code === params.code ||
        (loc.uuid && loc.uuid.toLowerCase() === params.code?.toLowerCase())
      );
    });

    return location?.uuid ?? null;
  }
);

// The "current location" of the user is either:
//
//   1. the location based on the URL parameters, or
//   2. the location set in the application state, or
//   3. the user's home location
//
// determined in that order.
export const getCurrentLocationUuid = createSelector(
  [
    getCurrentLocationUuidFromParams,
    getCurrentLocationUuidFromLocationsState,
    getUserLocationUuidFromAuth,
  ],
  (_, uuidFromState, uuidFromAuth): string | null => {
    return uuidFromState ?? uuidFromAuth;
  }
);

export const getHomeLocation = createSelector(
  [getHomeLocationState],
  (location): Location | null => location ?? null
);

export const getCurrentLocation = createSelector(
  [getCurrentLocationState, getCurrentLocationUuid],
  (location, _): Location | null => location ?? null
);

export const getCurrentTimezone = createSelector([getCurrentLocation], currentLocation => {
  return get(currentLocation, 'time_zone', moment.tz.guess());
});

const emptyBuilding = {
  code: '',
  country: undefined,
  countrygeo: {
    id: undefined,
    iso: undefined,
    name: undefined,
  },
  default_locale: '',
  entrance_instructions: '',
  entrance_instructions_localized: null,
  geogroupings: [],
  id: '',
  latitude: '',
  longitude: '',
  marketGeo: undefined,
  marketGeoId: '',
  name: '',
  netverify_policy: '',
  netverify_activated_on: '',
  submarket: '',
};
export const getCurrentBuilding = createSelector(
  [getBuildings, getCurrentLocationUuid],
  (buildings, uuid) => buildings.find(building => building.id === uuid) || emptyBuilding
);

export const getCurrentBuildingCountryCode = createSelector([getCurrentBuilding], building =>
  (building?.country?.iso ?? 'US').toLowerCase()
);

export const getOccupanciesForCurrentLocation = createSelector(
  [getCurrentLocationUuid, getOccupancies],
  (currentLocationUuid, occupancies): Array<Occupancy> => {
    if (!currentLocationUuid) {
      return [];
    }

    return occupancies[currentLocationUuid] ?? [];
  }
);

export const getReservationsByAccountUuidFromOccupancies = createSelector(
  [getOccupanciesForCurrentLocation],
  (occupancies: Array<Occupancy>) => {
    const reservationData = occupancies.map((occupancy: Occupancy) => {
      // Occupancy is defined as Partial, so reservations might be missing.
      return (occupancy?.reservations ?? []).map(res => ({
        ...res,
        reservable: {
          type: occupancy.type,
          office_num: occupancy.office_num,
          capacity: occupancy.capacity,
          uuid: occupancy.uuid,
        },
      }));
    });

    const flattenedReservationData = flatten(reservationData);

    return groupBy(flattenedReservationData, data => data?.account?.uuid);
  }
);

export const getCompaniesFromOccupancies = createSelector(
  [getOccupanciesForCurrentLocation],
  occupancies => {
    return sortBy(
      uniqBy(
        flatten(map(occupancies, occupancy => map(occupancy.reservations, 'account'))),
        'uuid'
      ),
      company => company.name.toLowerCase()
    );
  }
);

export const getPrimaryAndAnywhereReservationsWithLocation = createSelector(
  [getPrimaryReservations, getAnywhereMembershipReservations, getLocationsByUuid],
  (primaryRs, anywhereRs, locations): Array<Reservation> =>
    primaryRs.concat(anywhereRs).map(res => ({
      ...res,
      location: locations[res.location_uuid],
    }))
);

export const getPrimaryAndAnywhereReservationLocations = createSelector(
  [getPrimaryAndAnywhereReservationsWithLocation, isSSFilterKubeLocationsExperimentOn],
  (reservations, isSSFilterKubeLocationsExperimentOn) => {
    const locations = uniqBy(map(reservations, 'location'), 'uuid');
    if (!isSSFilterKubeLocationsExperimentOn) return locations;
    return locations.filter(({ isMigrated }) => !isMigrated);
  }
);

const getDisplayPrice = (price: Price | null | undefined) => {
  if (typeof price?.amount !== 'number') return '';
  if (typeof price?.currency !== 'string') return `${price.amount}`;

  return getLocalizedPrice({
    currencyCode: price.currency,
    price: price.amount,
  });
};

export const getOfficeHoldsKeyedByLocationAndReservableUuid = createSelector(
  [getOfficeHoldsByLocationUuid],
  officeHoldsByLocationUuid =>
    mapValues(officeHoldsByLocationUuid, officeHolds => keyBy(officeHolds, 'reservable_uuid'))
);

export const getInventoryAsOffice = createSelector(
  [getOfficeInventory, getOfficeHoldsKeyedByLocationAndReservableUuid],
  (inventory, officeHolds) => {
    return inventory
      ? inventory
          .map(inventoryItem => {
            const locationUuid = inventoryItem?.location?.uuid;
            const onHold = inventoryItem.onHold;
            const endsAt = inventoryItem.hold?.ends_at;
            const holdInfo =
              onHold && locationUuid ? get(officeHolds[locationUuid], inventoryItem.uuid, {}) : {};

            return {
              ...inventoryItem,
              capacity: inventoryItem.workUnits,
              displayPrice: getDisplayPrice(inventoryItem.price),
              has_hold: onHold,
              hold_expires_in: endsAt ? formatDistanceToNowStrict(parseISO(endsAt)) : '',
              hold_info: holdInfo,
              label: inventoryItem.name,
              pending: inventoryItem.paperworkPending,
              price: inventoryItem.price?.amount,
              value: inventoryItem.uuid,
            };
          })
          .filter(
            // Filter out items whose locations aren't in SpaceMan
            item => !!item.location?.code
          )
          .filter(
            // Filter out hotdesks in countries where they aren't enabled
            item =>
              item.type !== 'HotDesk' ||
              (item.location && includes(config.hdEnabledCountries, item.location.country))
          )
      : [];
  }
);

export function makeGetSortedCollection<S, T>(
  getCollection: (state: S) => Array<T>,
  getSortKey: (state: S) => string,
  getSortOrder: (state: S) => 'asc' | 'desc'
) {
  return createSelector<S, Array<T>, string, 'asc' | 'desc', Array<T>>(
    [getCollection, getSortKey, getSortOrder],
    (collection, sortKey, sortOrder) => {
      if (!sortKey || !sortOrder) {
        return collection;
      }

      return orderBy<T>(collection, [sortKey], [sortOrder]);
    }
  );
}

export const getReloadRequired = (state: AppSubset) => state.app.reloadRequired;

export const getIsLocationKubeMigrated = createSelector(
  getCurrentLocation,
  getExperiments,
  (location: Location | null, experiments: ExperimentGroups): boolean =>
    !!location?.isMigrated && experiments.ssEnableKubeMigratedProperties === 'on'
);
