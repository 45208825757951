import React from 'react';
import { compose } from 'redux';
import { withRouter, match } from 'react-router-dom';
import { WithRequestedAuthzProps } from '@wework/we-auth-react';
import { IconName, IconSize, IconType } from '@wework/ray2';
import cn from 'classnames';

import RaySideNav, { SideNavGroup, SideNavHeader, SideNavItem } from 'components-ray/sideNav';
import { Location } from 'store/modules/siLocations/types';
import { ExperimentGroups } from 'store/middlewares/experimentsHelper';
import { trackAnalyticsFor } from 'lib/analytics/analytics';
import { AnalyticsEventName } from 'lib/analytics/constants';
import spacestationLogo from 'images/spacestation-logo.svg';

import styles from './index.scss';
import { getItems } from './items';

type RoutedProps = {
  currentLocation: Location | null | undefined;
  experimentGroups: ExperimentGroups;
  loading?: boolean;
};

type OwnProps = RoutedProps &
  WithRequestedAuthzProps & {
    match?: match;
  };

type Props = Readonly<OwnProps>;

const SideNav = ({
  experimentGroups,
  currentLocation,
  loading,
  requestedPermissions,
}: Props): JSX.Element => {
  const items = React.useMemo(
    () => getItems(experimentGroups, requestedPermissions, currentLocation),
    [experimentGroups, requestedPermissions, currentLocation]
  );

  return (
    <RaySideNav className={styles.sideNav}>
      <SideNavHeader className="flex items-center">
        <header className={cn('flex items-center py-xs pl-sm', styles.logo)}>
          <img alt="spacestationIcon" className="h-32px w-32px" src={spacestationLogo} />
          <h1 className="ml-3xs mt-0 font-normal font-mono text-2xs text-gray-40">
            <SideNav.SpacestationWordmark />
          </h1>
        </header>
      </SideNavHeader>
      <div className={cn('overflow-y-scroll pb-xl', styles.sideNavContent)}>
        {items.map(group => (
          <SideNavGroup
            key={group.id}
            text={group.label || ''}
            icon={
              group.icon
                ? {
                    icon: group.rayIcon as IconName,
                    size: IconSize.SMALL,
                    type: IconType.OUTLINE,
                    className: 'text-gray-40 max-h-sm overflow-hidden',
                  }
                : undefined
            }
            hidden={group.hidden}
          >
            {group.items?.map(item =>
              loading ? null : (
                <SideNavItem
                  key={item.id}
                  url={item.url}
                  text={item.label || ''}
                  hidden={item.hidden}
                  onClick={() =>
                    trackAnalyticsFor(AnalyticsEventName.navigation_clicked, {
                      workflow: item.label || '',
                    })
                  }
                />
              )
            )}
          </SideNavGroup>
        ))}
      </div>
    </RaySideNav>
  );
};

// Subcomponent for Spacestation's wordmark. Contains code for custom kerning.
SideNav.SpacestationWordmark = function SideNavSpacestationWordmark(): JSX.Element {
  return (
    <>
      S<span className="tracking-[2.5px]">P</span>A<span className="tracking-[4px]">C</span>E
      <span className="tracking-[2.5px]">S</span>
      <span className="tracking-[2px]">TA</span>
      <span className="tracking-[2.5px]">TI</span>ON
    </>
  );
};

export const TestableSideNav = SideNav;

export default compose<React.ComponentType<OwnProps>>(withRouter)(SideNav);
