import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { omit, flow } from 'lodash';
import cn from 'classnames';

import Trackable, { OptionalTrackableProps, TrackableType } from 'components/decorators/trackable';

import style from './index.scss';

// TODO: https://jira.we.co/browse/CMOS-2884
// Stop passing in custom styles (this.props.style) to TextLink.
// Then, convert to functional component
//
// TODO: https://jira.we.co/browse/CMOS-2885
// Remove `danger` and `secondary` props on TextLink, or replace with `type`
//
// TODO: https://jira.we.co/browse/CMOS-2886
// Change signature to accept `text` string instead of `children`

export type OwnProps = {
  children?: React.ReactNode;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  external?: boolean;
  href?: string;
  onClick?: (evt: React.MouseEvent) => void;
  secondary?: boolean;
  strong?: boolean;
  target?: string;
  to?: string;
  title?: string;
  rel?: string;
  role?: string;
  component?: string;
  undecorated?: string;
} & OptionalTrackableProps;

type Props = Readonly<TrackableType & RouteComponentProps & OwnProps>;

const omitRouterProps = <T extends RouteComponentProps>(props: T): Diff<T, RouteComponentProps> =>
  omit(props, ['match', 'location', 'history', 'staticContext']);

const omitTrackableProps = <T extends Record<string, any>>(
  props: T
): Diff<T, TrackableType & Pick<OptionalTrackableProps, 'trackEvent'>> =>
  omit(props, [
    'eventMetadata',
    'getEventMetadata',
    'trackInteraction',
    'trackEvent',
    'trackEventMetadata',
    'interactionStart',
    'interactionEnd',
  ]);

const omitProps: (
  props: Props
) => Diff<
  Props,
  TrackableType & Pick<OptionalTrackableProps, 'trackEvent'> & RouteComponentProps
> = flow(omitRouterProps, omitTrackableProps);

class TextLink extends React.Component<Props> {
  static defaultProps = {
    onClick(_: React.MouseEvent) {},
    target: '_self',
  };

  buildHref = (): string | undefined => {
    const { to, href } = this.props;

    if (to) return `#${to}`;
    if (href) return href;

    return undefined;
  };

  handleClick = (ev: React.MouseEvent): void => {
    const { disabled, onClick } = this.props;

    if (disabled || !onClick) {
      return;
    }

    onClick(ev);
  };

  render() {
    const {
      className,
      children,
      danger,
      disabled,
      external,
      secondary,
      strong,
      target,
      component,
      undecorated,
      ...rest
    } = omitProps(this.props);

    const classes = cn(style.base, className, {
      [style.danger]: danger,
      [style.disabled]: disabled,
      [style.external]: external,
      [style.secondary]: secondary,
      [style.strong]: strong,
      [style.component]: component,
      [style.undecorated]: undecorated,
    });

    return (
      <a
        {...rest}
        href={this.buildHref()}
        onClick={this.handleClick}
        className={classes}
        target={target}
        rel={target === '_blank' ? 'noopener' : undefined}
      >
        {children}
      </a>
    );
  }
}

export default Trackable()(withRouter(TextLink));
