import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  GET_COMPANY_CUSTOM_ACCESS_LEVELS,
  GET_COMPANY_CUSTOM_ACCESS_LEVELS_SUCCESS,
  GET_COMPANY_CUSTOM_ACCESS_LEVELS_FAIL,
] = createRequestConstantNames(cc('GET_COMPANY_CUSTOM_ACCESS_LEVELS'));

export const [
  REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL,
  REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL_SUCCESS,
  REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL_FAIL,
] = createRequestConstantNames(cc('REMOVE_COMPANY_CUSTOM_ACCESS_LEVEL'));

export const [
  GET_BUILDINGS_LIST,
  GET_BUILDINGS_LIST_SUCCESS,
  GET_BUILDINGS_LIST_FAIL,
] = createRequestConstantNames(cc('GET_BUILDINGS_LIST'));

export const [
  GET_BUILDING_CUSTOM_ACCESS_LEVELS,
  GET_BUILDING_CUSTOM_ACCESS_LEVELS_SUCCESS,
  GET_BUILDING_CUSTOM_ACCESS_LEVELS_FAIL,
] = createRequestConstantNames(cc('GET_BUILDING_CUSTOM_ACCESS_LEVELS'));

export const [
  ASSOCIATE_ACCESS_LEVEL_TO_COMPANY,
  ASSOCIATE_ACCESS_LEVEL_TO_COMPANY_SUCCESS,
  ASSOCIATE_ACCESS_LEVEL_TO_COMPANY_FAIL,
] = createRequestConstantNames(cc('ASSOCIATE_ACCESS_LEVEL_TO_COMPANY'));
