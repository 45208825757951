import { Building } from 'store/modules/buildings';
import { Location as SILocation } from 'store/modules/siLocations/types';
import { getSpacemanId, getSpacemanCode } from 'store/modules/siLocations/utils';

import { LocationsResponse } from './locationsFetcher';

import { Location as SMLocation } from './index';

export const fromLocationsResponseToSMLocation = (
  locationsResponse: LocationsResponse
): Array<SMLocation> => {
  return locationsResponse.data.map(locResponse => {
    const locationData = locResponse.attributes;

    return {
      uuid: locationData.id,
      name: locationData.default_name,
      opened_on: locationData.opened_on,
      code: locationData.code,
      time_zone: locationData.time_zone,
      default_currency: locationData.normalized_default_currency,
      locale: locationData.default_locale,
      ppwk_default_locale: locationData.ppwk_default_locale,
      support_email: locationData.support_email,
      country_code: locationData.default_country_code,
      primary_gateway: locationData.primary_gateway,
      country: locationData.default_country,
      isMigrated: locationData.is_migrated,
      address: {
        country: locationData.default_country,
        latitude: parseFloat(locationData.latitude),
        longitude: parseFloat(locationData.longitude),
        city: locationData.city || '',
        state: locationData.state || '',
      },
    };
  });
};

export type BuildingResponseItem = {
  id: string;
  type: string;
  attributes: Building;
};

export type BuildingResponse = {
  data: Array<BuildingResponseItem>;
};

export const fromLocationsResponseToBuildings = (
  locationsResponse: LocationsResponse
): BuildingResponse => {
  return {
    data: locationsResponse.data.map(locationData => ({
      id: locationData.id,
      type: locationData.type,
      attributes: {
        code: locationData.attributes.code,
        country: locationData.attributes.country,
        countrygeo: locationData.attributes.countrygeo,
        default_locale: locationData.attributes.default_locale,
        entrance_instructions: locationData.attributes.entrance_instructions,
        entrance_instructions_localized: locationData.attributes.entrance_instructions_localized,
        geogroupings: locationData.attributes.geogroupings,
        id: locationData.attributes.id,
        latitude: locationData.attributes.latitude,
        longitude: locationData.attributes.longitude,
        marketGeo: locationData.attributes.marketGeo,
        marketGeoId: locationData.attributes.marketGeoId,
        name: locationData.attributes.name,
        netverify_policy: locationData.attributes.netverify_policy,
        netverify_activated_on: locationData.attributes.netverify_activated_on,
        submarket: locationData.attributes.submarket,
        isMigrated: locationData.attributes.is_migrated,
      },
    })),
  };
};

export const fromSILocationToSMLocation = (location: SILocation | null): SMLocation | null => {
  if (!location) {
    return null;
  }
  return {
    uuid: getSpacemanId(location),
    code: getSpacemanCode(location),
    floorIds: location.buildings?.flatMap(building => building.floors?.map(floor => floor.id)),
    name: location.name,
    opened_on: location.openDate,
    time_zone: location.localTimeZone,
    locale: location.defaultLocale,
    ppwk_default_locale: location.paperworkLocale,
    default_currency: location.defaultCurrency,
    support_email: location.supportEmail,
    country_code: location.defaultAddress?.countryISO3 || '',
    country: location.defaultAddress?.country || '',
    isMigrated: location.isMigrated,
    // fallback to empty address
    address: {
      city: location.defaultAddress?.locality || '',
      state: location.defaultAddress?.administrativeArea || '',
      country: location.defaultAddress?.country || '',
      latitude: location.defaultAddress?.latitude || 0,
      longitude: location.defaultAddress?.longitude || 0,
    },
  };
};
