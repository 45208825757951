import React from 'react';
import { Page, Loader } from '@wework-dev/plasma';

type Props = Readonly<{ delayMs: number }>;

type State = Readonly<{ showLoader: boolean }>;

export default class Loading extends React.PureComponent<Props, State> {
  delayTimeout: number | null = null;

  static defaultProps = {
    delayMs: 0,
  };

  state = {
    showLoader: this.props.delayMs <= 0,
  };

  componentDidMount() {
    if (this.props.delayMs > 0) {
      this.delayTimeout = window.setTimeout(
        () => this.setState({ showLoader: true }),
        this.props.delayMs
      );
    }
  }

  componentWillUnmount() {
    if (this.delayTimeout) {
      window.clearTimeout(this.delayTimeout);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (__DEVELOPMENT__ && prevProps.delayMs !== this.props.delayMs) {
      // eslint-disable-next-line no-console
      console.error(
        'Warning: Cannot change `delayMs` prop on component `Loading` once it has already been mounted.'
      );
    }
  }

  render() {
    return <Page>{this.state.showLoader && <Loader />}</Page>;
  }
}
