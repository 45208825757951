export const PREFIX = 'spacestation/';

export const createConstant = (constant: string): string => PREFIX + constant;

export const createConstantWithPrefix = (constant: string, prefix: string): string =>
  createConstant(`${prefix}/${constant}`);

export const createRequestConstants = (name: string): [string, string, string] => [
  createConstant(name),
  createConstant(`${name}/success`),
  createConstant(`${name}/fail`),
];

export const createRequestConstantsWithPrefix = (
  name: string,
  prefix: string
): [string, string, string] => createRequestConstants(createConstantWithPrefix(name, prefix));

export default createConstant;
