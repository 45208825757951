import React, { ReactNode } from 'react';
import cn from 'classnames';
/** TODO: Table and Icon Components need to be updated to Ray, once available add via SPSN-11277 */
import { Icon } from '@wework/dieter-ui';

import { EmojiName } from '../emoji/emojiName';
import Emoji from '../emoji';

export interface EmptyStateProps {
  additionalCopy?: ReactNode;
  callToAction?: ReactNode;
  centered?: boolean;
  className?: string;
  colSpan?: number;
  disabled?: boolean;
  emoji?: EmojiName;
  headlineContent?: ReactNode;
  imageSrc?: HTMLImageElement | URL | string;
  medium?: boolean;
  small?: boolean;
  subHeadlineContent?: ReactNode;
}

const EmptyState = ({
  additionalCopy,
  callToAction,
  centered,
  className,
  disabled = false,
  emoji,
  headlineContent,
  imageSrc,
  medium,
  small,
  subHeadlineContent,
}: EmptyStateProps) => {
  if (disabled) return null;
  return (
    <div
      className={cn(
        className,
        "!hover:shadow-none hover:cursor-default !shadow-none text-gray-40 font-['Apercu'] max-w-[400px] my-28 mx-auto",
        {
          'text-center': centered,
          'max-w-[320px]': medium,
          'max-w-[215px] !my-8': small,
        }
      )}
    >
      {imageSrc && (
        <Icon className="!mx-0 !my-3xs !text-sm !w-[21px] !h-[21px]" as="img" src={imageSrc} />
      )}
      {emoji && (
        <div className="m-0 !my-3xs">
          <Emoji emoji={emoji} className="!h-6 w-auto !mt-[3.5px] !mb-[4.75px]" />
        </div>
      )}
      {headlineContent && (
        <h3 className="font-main font-bold text-xs m-0 !mt-2xs mb-2xs text-gray-40">
          {headlineContent}
        </h3>
      )}
      {subHeadlineContent && (
        <p className="text-body text-3xs my-0 text-gray-40">{subHeadlineContent}</p>
      )}
      {callToAction && <p className="text-body text-2xs underline !mt-sm mb-0">{callToAction}</p>}
      {additionalCopy && (
        <p
          className={cn('text-body text-3xs mt-2xs mb-0 text-gray-40', {
            '!mt-lg': !callToAction,
          })}
        >
          {additionalCopy}
        </p>
      )}
    </div>
  );
};

export default EmptyState;
