export const ERRORS = {
  DESK: 'Unable to retrieve the desk information',
  MEMBERSHIP: 'Unable to retrieve the membership information',
  NO_FUNDS: 'Card declined',
  NO_SPACES_AVAILABLE: 'No spaces are available',
  NOTES: 'Unable to update notes on reservation',
  NOT_SUPPORTED: 'On demand reservations are currently not supported',
  ON_DEMAND_RESERVATION: 'Unable to create the on demand reservation',
  ORDER: 'Unable to create order',
  PAYMENT_PROFILES: 'Unable to retrieve any payment profiles',
  PRICING: 'Unable to retrieve the pricing information',
  QUOTE_INVALID: 'Unable to create quote',
  QUOTE_STATUS: 'Quote status not ready',
  RESERVATION: 'Unable to create the reservation',
  ROOM_NOT_AVAILABLE: 'Room no longer available',
  USER_ALREADY_BOOKED: 'User already booked this reservation',
};

export const ERROR_CODES = {
  NO_FUNDS: 'no_funds',
  ROOM_NOT_AVAILABLE: 'room_not_available',
  USER_ALREADY_BOOKED: 'user_already_booked',
};

export const QUOTE_STATUSES = {
  INVALIDATED: 'INVALIDATED',
  READY: 'READY',
};

export const ORDER_STATUSES = {
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
};
