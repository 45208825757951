import {
  parseISO,
  addMonths,
  endOfMonth,
  isFirstDayOfMonth,
  differenceInCalendarDays,
  max,
  isBefore,
} from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import {
  CommitmentTermInputAttrs,
  CommitmentTermInput,
  DiscountInput,
} from 'features/paperwork/contracts-flow/types';
import {
  formatToISODate,
  byDateProp,
  isProrated,
  shiftDateRangesBy,
} from 'features/paperwork/contracts-flow/sections/product/utils';

import { NEW_TERM, EDIT_TERM } from './consts';
import { validateTerm, validateDiscounts } from './validators';
import { getMaxSuggestedDiscount } from './selectors';

import { State } from '.';

export const getAffectedDiscountsByDateChange = (
  discounts: Array<DiscountInput>,
  prevDate: string,
  currentDate: string
) => {
  const { prorated, regularDiscounts } = discounts.reduce<Hash<Array<DiscountInput>>>(
    (acc, discount) => {
      if (isProrated(discount.startDate, discount.endDate)) {
        acc.prorated.push(discount);
      } else {
        acc.regularDiscounts.push(discount);
      }
      return acc;
    },
    { prorated: [], regularDiscounts: [] }
  );

  const shiftedDiscounts = shiftDateRangesBy(
    regularDiscounts,
    differenceInCalendarDays(parseISO(currentDate), parseISO(prevDate))
  ) as Array<DiscountInput>;

  return [...prorated, ...shiftedDiscounts];
};

export const updateProratedByMoveInDate = (
  discounts: Array<DiscountInput>,
  moveInDate: string,
  amount: number,
  termId: string
): Array<DiscountInput> => {
  const proratedIndex = discounts
    .sort(byDateProp('startDate'))
    .findIndex(({ startDate, endDate }) => isProrated(startDate, endDate));

  if (isFirstDayOfMonth(parseISO(moveInDate))) {
    if (proratedIndex >= 0) {
      return [...discounts.slice(0, proratedIndex), ...discounts.slice(proratedIndex + 1)];
    }
  } else {
    const proratedUpdate = {
      startDate: moveInDate,
      endDate: moveInDate,
      amount,
    };
    if (proratedIndex >= 0) {
      return [
        ...discounts.slice(0, proratedIndex),
        {
          ...discounts[proratedIndex],
          ...proratedUpdate,
        },
        ...discounts.slice(proratedIndex + 1),
      ];
    }

    return [
      {
        key: uuidv4(),
        ...proratedUpdate,
        termId,
        errors: {},
      },
      ...discounts,
    ];
  }
  return discounts;
};

export const getEndDate = (date: string, termLength: number) =>
  formatToISODate(endOfMonth(addMonths(parseISO(date), termLength - 1)));

export const NEW_TERM_ID = 'new_term_id';
export const newTermFrom = (startDate): CommitmentTermInput => ({
  id: NEW_TERM_ID,
  startDate: {
    value: startDate,
    editable: true,
  },
  endDate: {
    value: '',
    editable: true,
  },
  length: {
    value: 0,
    editable: true,
  },
  errors: {},
});

const reducer = {
  [NEW_TERM]: (state: State) => {
    const newTerm = newTermFrom(state.commitmentStartDate);

    const updatedItems = state.items.map(item => {
      const startDate = max([parseISO(item.productReservation.startDate.value), new Date()]);
      const startDateISO = formatToISODate(startDate);

      const discounts: Array<DiscountInput> = [];

      if (!isBefore(parseISO(startDateISO), new Date()) && !isFirstDayOfMonth(startDate)) {
        discounts.push({
          startDate: startDateISO,
          endDate: startDateISO,
          amount: 0,
          key: uuidv4(),
          termId: NEW_TERM_ID,
          errors: {},
        });
      }

      return {
        ...item,
        terms: [...item.terms, newTerm],
        discounts,
      };
    });

    return {
      ...state,
      items: updatedItems,
    };
  },
  [EDIT_TERM]: (
    state: State,
    action: { payload: { id: string; termId: string; attrs: CommitmentTermInputAttrs } }
  ) => {
    const idx = state.items.findIndex(
      ({ productReservation }) => productReservation.id === action.payload.id
    );

    const productItem = state.items[idx];

    let termIdx = productItem.terms.findIndex(({ id }) => id === action.payload.termId);

    if (termIdx < 0) {
      termIdx = productItem.terms.length;
    }

    const attrs = Object.keys(action.payload.attrs).reduce((acc, key) => {
      acc[key] = {
        ...productItem.terms[termIdx][key],
        value: action.payload.attrs[key],
      };
      return acc;
    }, {});

    const termFromAttrs = {
      ...productItem.terms[termIdx],
      ...attrs,
    };

    const updatedTerm = {
      ...termFromAttrs,
      endDate: {
        ...termFromAttrs.endDate,
        value: termFromAttrs.length?.value
          ? getEndDate(termFromAttrs.startDate.value, termFromAttrs.length.value)
          : null,
      },
    };

    const updatedItems = state.items.map(item => {
      const commitmentbasedDiscounts = item.discounts.filter(({ termId }) => termId);
      const otherDiscounts = item.discounts.filter(({ termId }) => !termId);

      let updatedDiscounts;
      if (action.payload.attrs.startDate) {
        const affectedDiscounts = getAffectedDiscountsByDateChange(
          commitmentbasedDiscounts,
          item.terms[termIdx].startDate.value,
          updatedTerm.startDate.value
        );
        updatedDiscounts = validateDiscounts(
          [...affectedDiscounts, ...otherDiscounts],
          null,
          updatedTerm,
          item.productReservation.chargePrice,
          getMaxSuggestedDiscount(
            state,
            item.productReservation.uuid || item.productReservation.id,
            updatedTerm.length.value
          )
        );
      }

      return {
        ...item,
        terms: [
          ...item.terms.slice(0, termIdx),
          validateTerm(updatedTerm, productItem.productReservation.startDate.value),
          ...item.terms.slice(termIdx + 1),
        ],
        discounts: updatedDiscounts || item.discounts,
      };
    });

    return {
      ...state,
      items: updatedItems,
    };
  },
};

export default reducer;
