import { BaseAction, Dispatch } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import cc from 'store/util/createReduxConstant';
import { LocationInfo, LocationInfoPayload } from 'features/building/types';
import { getContentfulUri } from 'features/building/ducks/contentfulUriUtil';
import createRequestAction from 'store/util/createRequestAction';
import config from 'config';

import { getLocationInfoByID } from '../serializers/locationInfoSerializer';

// Action Constants
export const FETCH_LOCATION_INFO = cc('FETCH_LOCATION_INFO');
export const FETCH_LOCATION_INFO_SUCCESS = cc('FETCH_LOCATION_INFO_SUCCESS');
export const FETCH_LOCATION_INFO_FAIL = cc('FETCH_LOCATION_INFO_FAIL');

export const fetchLocationInfo = (mlsIds: string[]) => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'GET',
    endpoint: getContentfulUri(false, 'location', mlsIds),
    headers: {
      Authorization: `Bearer ${config.contentful.cdn_token}`,
    },
    types: [
      { type: FETCH_LOCATION_INFO },
      { type: FETCH_LOCATION_INFO_SUCCESS },
      { type: FETCH_LOCATION_INFO_FAIL },
    ],
  });

  return dispatch(requestAction);
};

const reducer = createRequestReducer<
  { [key: string]: LocationInfo } | null,
  LocationInfoPayload | undefined
>([FETCH_LOCATION_INFO, FETCH_LOCATION_INFO_SUCCESS, FETCH_LOCATION_INFO_FAIL], null, res =>
  getLocationInfoByID(res.payload)
);

export default reducer;
