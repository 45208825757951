import moment from 'moment-timezone';
import { addMonths, startOfMonth, format } from 'date-fns';

import { dateFormatsDeprecated, dateFormats } from 'lib/constants';

export const earliestAmendmentDate = (): string => {
  const currentDate = Date.now();
  const earliestDate = startOfMonth(addMonths(currentDate, 1));
  return format(earliestDate, dateFormats.iso_date);
};

export const startedOn = (moveInDate: string, index: number): string => {
  if (index === 0) {
    return moveInDate;
  }

  return moment
    .utc(moveInDate)
    .add(index, 'months')
    .startOf('month')
    .format(dateFormatsDeprecated.iso_date);
};

export const endedOn = (startDate: string): string =>
  moment.utc(startDate).endOf('month').format(dateFormatsDeprecated.iso_date);
