export type MembershipFee = {
  startDate: string;
  endDate: string;
  type: MEMBERSHIP_FEE_TYPE;
  price: number;
  discount: number;
  netFee: number;
  termId?: string;
};

export enum MEMBERSHIP_FEE_TYPE {
  PRORATED = 'prorated',
  REGULAR = 'regular',
  PRICE_INCREASE = 'price_increase',
  NO_COMMITMENT = 'no_commitment',
  MONTH_TO_MONTH = 'month_to_month',
  NO_DISCOUNT = 'no_discount',
  NO_COMMITMENT_MONTH_TO_MONTH = 'no_commitment_month_to_month',
}
