import { handleActions } from 'redux-actions';

import {
  FETCH_MEMBERSHIP_TYPES_BY_COMPANY,
  FETCH_MEMBERSHIP_TYPES_BY_COMPANY_SUCCESS,
  FETCH_MEMBERSHIP_TYPES_BY_COMPANY_FAIL,
} from 'features/companies/edit/redux/membershipTypes/constants';

interface State {
  loaded: boolean;
  loading: boolean;
  error: string | null;
  byUuid: {};
}

export interface MembershipTypesSubset {
  membershipTypes: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byUuid: {},
  error: null,
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_MEMBERSHIP_TYPES_BY_COMPANY]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_MEMBERSHIP_TYPES_BY_COMPANY_SUCCESS]: (state, action: { payload: { result: {} } }) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byUuid: {
        ...state.byUuid,
        ...action.payload.result,
      },
    }),
    [FETCH_MEMBERSHIP_TYPES_BY_COMPANY_FAIL]: (state, action: { payload: string }) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload,
    }),
  },
  initialState
);

export default reducer;
