import { castArray } from 'lodash';
import { handleActions } from 'redux-actions';

import {
  FETCH_ENDED_OCCUPANCIES,
  FETCH_ENDED_OCCUPANCIES_FAIL,
  FETCH_ENDED_OCCUPANCIES_SUCCESS,
  FETCH_OCCUPANCIES_BY_LOCATION,
  FETCH_OCCUPANCIES_BY_LOCATION_FAIL,
  FETCH_OCCUPANCIES_BY_LOCATION_SUCCESS,
  FETCH_OCCUPANCY,
  FETCH_OCCUPANCY_FAIL,
  FETCH_OCCUPANCY_SUCCESS,
} from 'store/modules/occupancies/constants';
import {
  State,
  CreateLocationAction,
  CreateOccupiableAction,
  CreateReservableAction,
} from 'store/modules/occupancies/types';

export type { OccupanciesSubset } from 'store/modules/occupancies/types';

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  byLocationUuid: {},
  byOccupiableUuid: {},
  byReservableUuid: {},
  error: null,
  status: {
    byOccupiableUuid: {},
    byLocationUuid: {},
  },
};

// type Payload = LocationPayload | OccupiablePayload | ReservablePayload;
// type Meta = LocationMeta | OccupiableMeta | ReservableMeta;

// TODO(grozki): How can we use Payload and Meta here?
// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [FETCH_OCCUPANCIES_BY_LOCATION]: (state: State, action: CreateLocationAction): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
      status: {
        ...state.status,
        byLocationUuid: {
          ...state.status.byLocationUuid,
          [action.meta.locationUuid]: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      },
    }),

    [FETCH_OCCUPANCIES_BY_LOCATION_SUCCESS]: (
      state: State,
      action: CreateLocationAction
    ): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byLocationUuid: {
        ...state.byLocationUuid,
        [action.meta.locationUuid]: castArray(action.payload.result),
      },
      status: {
        ...state.status,
        byLocationUuid: {
          ...state.status.byLocationUuid,
          [action.meta.locationUuid]: {
            loading: false,
            loaded: true,
            error: null,
          },
        },
      },
    }),

    [FETCH_OCCUPANCIES_BY_LOCATION_FAIL]: (state: State, action: CreateLocationAction): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
      status: {
        ...state.status,
        byLocationUuid: {
          ...state.status.byLocationUuid,
          [action.meta.locationUuid]: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      },
    }),

    [FETCH_OCCUPANCY]: (state: State, action: CreateOccupiableAction): State => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
      status: {
        ...state.status,
        byOccupiableUuid: {
          ...state.status.byOccupiableUuid,
          [action.meta.occupiableUuid]: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      },
    }),

    [FETCH_OCCUPANCY_SUCCESS]: (state: State, action: CreateOccupiableAction): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byOccupiableUuid: {
        ...state.byOccupiableUuid,
        [action.meta.occupiableUuid]: action.payload.result,
      },
      status: {
        ...state.status,
        byOccupiableUuid: {
          ...state.status.byOccupiableUuid,
          [action.meta.occupiableUuid]: {
            loading: false,
            loaded: true,
            error: null,
          },
        },
      },
    }),

    [FETCH_OCCUPANCY_FAIL]: (state: State, action: CreateOccupiableAction): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
      status: {
        ...state.status,
        byOccupiableUuid: {
          ...state.status.byOccupiableUuid,
          [action.meta.occupiableUuid]: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      },
    }),

    [FETCH_ENDED_OCCUPANCIES]: (state: State, action: CreateReservableAction): State => ({
      ...state,
      byReservableUuid: {
        ...state.byReservableUuid,
        [action.meta.reservableUuid]: {
          data: [],
          loading: true,
          loaded: false,
          error: null,
        },
      },
    }),

    [FETCH_ENDED_OCCUPANCIES_SUCCESS]: (state: State, action: CreateReservableAction): State => ({
      ...state,
      byReservableUuid: {
        ...state.byReservableUuid,
        [action.meta.reservableUuid]: {
          data: action.payload.result,
          loading: false,
          loaded: true,
          error: null,
        },
      },
    }),

    [FETCH_ENDED_OCCUPANCIES_FAIL]: (state: State, action: CreateReservableAction): State => ({
      ...state,
      byReservableUuid: {
        ...state.byReservableUuid,
        [action.meta.reservableUuid]: {
          data: [],
          loading: false,
          loaded: false,
          error: action.error,
        },
      },
    }),
  },
  initialState
);

export default reducer;
