import { handleActions, Action, ReducerMap } from 'redux-actions';

import {
  RequestsList,
  IDCompany,
  MMPWhitelist,
  ReservableRequest,
  FetchCompaniesFromIDResponse,
  MimoChecklistTask,
  UpdateTaskOptimistically,
  MetaType,
} from 'features/mimo/types';
import {
  FETCH_REQUESTS,
  FETCH_REQUESTS_SUCCESS,
  FETCH_REQUESTS_FAILURE,
  FETCH_PREVIOUS_REQUESTS,
  FETCH_PREVIOUS_REQUESTS_SUCCESS,
  FETCH_PREVIOUS_REQUESTS_FAILURE,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  UPDATE_REQUEST,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_FAILURE,
  FETCH_MMP_WHITELIST,
  FETCH_MMP_WHITELIST_SUCCESS,
  FETCH_INVENTORY_CAPACITY_SUCCESS,
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_OPTIMISTICALLY,
  UPDATE_TASK_RAY,
  UPDATE_TASK_SUCCESS_RAY,
  UPDATE_TASK_FAILURE_RAY,
} from 'features/mimo/redux/requests/constants';
import { SearchResultPayload, InventoryPayload } from 'features/inventorySearch/types';
import { ActionWithMeta } from 'store/types';

export interface State {
  requests: RequestsList;
  requestsLoaded: boolean;
  requestsError: boolean;
  previousRequests: RequestsList;
  previousRequestsLoaded: boolean;
  previousRequestsError: boolean;
  companiesLoaded: boolean;
  companiesByUuid: Hash<IDCompany>;
  requestSaving: boolean;
  whitelisted: MMPWhitelist;
  reservablesCapacity: Hash<number>;
  taskLists: MimoChecklistTask[];
  taskListsLoaded: boolean;
  taskListsHasChanges: boolean;
  taskListsError: boolean;
}

export const initialState: State = {
  requests: [],
  requestsLoaded: false,
  requestsError: false,
  previousRequests: [],
  previousRequestsLoaded: false,
  previousRequestsError: false,
  companiesLoaded: false,
  companiesByUuid: {},
  requestSaving: false,
  whitelisted: {},
  reservablesCapacity: {},
  taskLists: [],
  taskListsLoaded: false,
  taskListsHasChanges: false,
  taskListsError: false,
};

type AcceptablePayloads =
  | RequestsList
  | ReservableRequest
  | MMPWhitelist
  | SearchResultPayload
  | FetchCompaniesFromIDResponse
  | MimoChecklistTask[]
  | MimoChecklistTask
  | UpdateTaskOptimistically;

const inventoryToCapacityHash = (inventory: InventoryPayload | null | undefined): Hash<number> =>
  Array.isArray(inventory)
    ? inventory.reduce((obj, reservable) => {
        obj[reservable.uuid] = reservable.capacity;
        return obj;
      }, {})
    : {};

const reducerDefinition: ReducerMap<State, AcceptablePayloads> = {
  [FETCH_TASKS]: state => ({
    ...state,
    taskListsLoaded: false,
    taskListsError: false,
    taskLists: [],
  }),
  [FETCH_TASKS_SUCCESS]: (state, { payload: taskLists }: Action<MimoChecklistTask[]>) => ({
    ...state,
    taskListsLoaded: true,
    taskLists,
  }),
  [FETCH_TASKS_FAILURE]: state => ({
    ...state,
    taskListsLoaded: true,
    taskListsError: true,
  }),
  [UPDATE_TASK_OPTIMISTICALLY]: (state, { payload }: Action<UpdateTaskOptimistically>) => {
    const { task, completed } = payload;
    return {
      ...state,
      taskListsHasChanges: true,
      taskLists: state.taskLists.map((taskListsTask: MimoChecklistTask) => {
        if (taskListsTask.id === task.id) {
          return { ...taskListsTask, completed };
        }
        return taskListsTask;
      }),
    };
  },
  [UPDATE_TASK]: state => ({
    ...state,
  }),
  [UPDATE_TASK_SUCCESS]: (state, { payload: payloadTaskLists }: Action<MimoChecklistTask>) => {
    return {
      ...state,
      taskListsHasChanges: true,
      taskLists: state.taskLists.map((taskListsTask: MimoChecklistTask) =>
        taskListsTask.id === payloadTaskLists.id ? payloadTaskLists : taskListsTask
      ),
    };
  },
  [UPDATE_TASK_FAILURE]: state => ({
    ...state,
    taskListsHasChanges: true,
    taskListsError: true,
  }),
  [UPDATE_TASK_RAY]: state => ({
    ...state,
  }),
  [UPDATE_TASK_SUCCESS_RAY]: (state, { payload: payloadTaskLists }: Action<MimoChecklistTask>) => {
    return {
      ...state,
      taskListsHasChanges: true,
      taskLists: state.taskLists.map((taskListsTask: MimoChecklistTask) =>
        taskListsTask.id === payloadTaskLists.id
          ? { ...payloadTaskLists, completed: taskListsTask.completed }
          : taskListsTask
      ),
    };
  },
  [UPDATE_TASK_FAILURE_RAY]: (
    state,
    { meta }: ActionWithMeta<MetaType> & Action<AcceptablePayloads>
  ) => {
    const failedTaskId = meta.taskId;
    return {
      ...state,
      taskListsHasChanges: true,
      taskListsError: true,
      taskLists: state.taskLists.map((taskListsTask: MimoChecklistTask) => {
        if (taskListsTask.id === failedTaskId) {
          return { ...taskListsTask, completed: !taskListsTask.completed };
        }
        return taskListsTask;
      }),
    };
  },

  [FETCH_REQUESTS]: state => ({
    ...state,
    requestsLoaded: false,
    requestsError: false,
    requests: [],
  }),
  [FETCH_REQUESTS_SUCCESS]: (state, { payload: requests }: Action<RequestsList>) => ({
    ...state,
    requestsLoaded: true,
    requests,
  }),
  [FETCH_REQUESTS_FAILURE]: state => ({
    ...state,
    requestsLoaded: true,
    requestsError: true,
  }),
  [FETCH_PREVIOUS_REQUESTS]: state => ({
    ...state,
    previousRequests: [],
    previousRequestsLoaded: false,
    previousRequestsError: false,
  }),
  [FETCH_PREVIOUS_REQUESTS_SUCCESS]: (
    state,
    { payload: previousRequests }: Action<RequestsList>
  ) => ({
    ...state,
    previousRequests,
    previousRequestsLoaded: true,
  }),
  [FETCH_PREVIOUS_REQUESTS_FAILURE]: state => ({
    ...state,
    previousRequestsLoaded: true,
    previousRequestsError: true,
  }),
  [FETCH_COMPANIES]: state => ({
    ...state,
    companiesLoaded: false,
  }),
  [FETCH_COMPANIES_SUCCESS]: (
    state,
    { payload: { result } }: Action<FetchCompaniesFromIDResponse>
  ) => ({
    ...state,
    companiesLoaded: true,
    companiesByUuid: result.reduce(
      (acc: Hash<IDCompany>, company: IDCompany) => ({
        ...acc,
        [company.uuid]: company,
      }),
      {}
    ),
  }),
  [FETCH_COMPANIES_FAILURE]: state => ({
    ...state,
    companiesLoaded: true,
  }),
  [UPDATE_REQUEST]: state => ({
    ...state,
    requestSaving: true,
  }),
  [UPDATE_REQUEST_SUCCESS]: (state, { payload: task }: Action<ReservableRequest>) => {
    const requestIdx = state.requests.findIndex(request => request.id === task.id);
    const requests = [...state.requests];
    if (requestIdx !== -1) {
      requests.splice(requestIdx, 1, task);
    }
    return {
      ...state,
      requests,
      requestSaving: false,
    };
  },
  [UPDATE_REQUEST_FAILURE]: state => ({
    ...state,
    requestSaving: false,
  }),
  [FETCH_MMP_WHITELIST]: state => ({
    ...state,
    whitelisted: {},
  }),
  [FETCH_MMP_WHITELIST_SUCCESS]: (state, { payload: whitelisted }: Action<MMPWhitelist>) => ({
    ...state,
    whitelisted,
  }),
  [FETCH_INVENTORY_CAPACITY_SUCCESS]: (
    state,
    { payload: { inventory } }: Action<SearchResultPayload>
  ) => ({
    ...state,
    reservablesCapacity: {
      ...state.reservablesCapacity,
      ...inventoryToCapacityHash(inventory),
    },
  }),
};

export const reducer = handleActions(reducerDefinition, initialState);

export default reducer;
