import React, { ComponentType } from 'react';
import { omit } from 'lodash';

export type FieldHocProps = Readonly<{
  input: {
    onBlur: (...args: Array<unknown>) => unknown;
    value: unknown;
  };
  render?: (...args: Array<any>) => React.ReactNode | React.ReactElement<any>;
  meta: {
    touched: boolean;
    error: string;
  };
}>;

export type Props = Readonly<
  FieldHocProps & {
    render: (...args: Array<unknown>) => React.ReactNode;
    required?: boolean;
  }
>;

export class CreateField extends React.Component<Props> {
  handleOnBlur = (...args: Array<unknown>) => {
    const {
      input: { onBlur, value },
    } = this.props;
    return args.length ? onBlur(...args) : onBlur(value);
  };

  render() {
    const {
      input,
      meta: { touched, error },
      render,
      ...fieldProps
    } = this.props;

    // Omitted props are automatically invoked by the Field component and do not need to be passed into InputField
    const sanitizedFieldProps = omit(fieldProps, [
      'onChange',
      'onBlur',
      'onDragStart',
      'onDrop',
      'onFocus',
    ]);

    return render(
      {
        ...input,
        onBlur: this.handleOnBlur,
        ...sanitizedFieldProps,
        error: !!error && touched,
      },
      this.props
    );
  }
}

// TODO: Add type notation
export default (InputField: ComponentType<any>): ComponentType<any> => {
  // eslint-disable-next-line react/no-multi-comp
  return class WrappedComponent extends React.Component<FieldHocProps> {
    static displayName = `createField(${InputField.displayName || InputField.name || 'Component'})`;
    renderPropHelper = (props: {}) => <InputField {...props} render={this.props.render} />;

    render() {
      return <CreateField {...this.props} render={this.renderPropHelper} />;
    }
  };
};
