import ApiRequest from 'lib/api/apiRequest';
import config from 'config';
import {
  BulkGetResponse,
  DeleteResponse,
  PostResponse,
  SkywriterPayload,
} from 'lib/api/skywriter/types';

export default class SkywriterAPI extends ApiRequest {
  constructor() {
    super(config.skywriter.uri);
  }

  addCompanyNote = async (payload: SkywriterPayload): Promise<PostResponse> => {
    const request = {
      path: '/api/v1/notes/',
      data: JSON.stringify({
        note: {
          ...payload,
        },
      }),
      config: {
        useArrayFormatBrackets: false,
        isCamelCase: false,
      },
    };

    return this.post(request);
  };

  deleteCompanyNote = async (noteUuid: string): Promise<DeleteResponse> => {
    const request = {
      path: `/api/v1/notes/${noteUuid}`,
      config: {
        isCamelCase: false,
      },
    };

    return this.delete(request);
  };

  getSkywriterNotes = async (companyUuid: string): Promise<BulkGetResponse> => {
    const request = {
      path: `/api/v1/notes?notable_id=${companyUuid}&notable_type=company`,
      config: {
        isCamelCase: false,
      },
    };

    return this.get(request);
  };
}
