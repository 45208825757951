import { get, some, mapValues, snakeCase, toUpper, pickBy } from 'lodash';
import queryString from 'query-string';

import createRequestAction, { Params } from 'store/util/createRequestActionAdapter';
import { BaseAction, GetGlobalState, Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';
import { GlobalState } from 'store/modules';

import {
  ActionStatus,
  ActionType,
  EndpointOption,
  EntityActionMeta,
  EntityActionParams,
  EntityOptions,
  Method,
  CustomActions,
  StringOrStateFunction,
} from './types';

export const actionTypes: { [K in ActionType]: K } = {
  create: 'create',
  update: 'update',
  query: 'query',
  find: 'find',
  destroy: 'destroy',
};

const actionToMethod = {
  create: 'POST',
  update: 'PUT',
  query: 'GET',
  find: 'GET',
  destroy: 'DELETE',
};

const actionPrefix = cc('entity');

const getMethod = (action: ActionType): Method => actionToMethod[action] as Method;

const constantize = entityName => toUpper(snakeCase(entityName));

const createEntityConstants = (
  entityName: string,
  actionName: string
): {
  [K in ActionStatus]: string;
} => ({
  pending: `${actionPrefix}/${constantize(actionName)}_${constantize(entityName)}`,
  success: `${actionPrefix}/${constantize(actionName)}_${constantize(entityName)}_SUCCESS`,
  fail: `${actionPrefix}/${constantize(actionName)}_${constantize(entityName)}_FAIL`,
});

export const createEntityConstantsForAllActions = <T extends CustomActions>(
  entityName: string,
  options: EntityOptions<T>
): Hash<
  {
    [K in ActionStatus]: string;
  }
> => ({
  find: createEntityConstants(entityName, actionTypes.find),
  query: createEntityConstants(entityName, actionTypes.query),
  create: createEntityConstants(entityName, actionTypes.create),
  update: createEntityConstants(entityName, actionTypes.update),
  destroy: createEntityConstants(entityName, actionTypes.destroy),
  // Custom action constants
  ...mapValues(options.customActions || {}, (_, name) => createEntityConstants(entityName, name)),
});

const createEntityConstantsForRequestAction = (actionName: string, entityName: string) => {
  const constants = createEntityConstants(entityName, actionName);

  return [
    {
      type: constants.pending,
      meta: { pending: true },
    },
    {
      type: constants.success,
      meta: { success: true },
    },
    {
      type: constants.fail,
      meta: { error: true },
    },
  ];
};

const createEntityActionMeta = <T extends CustomActions>(
  actionName: string,
  actionType: ActionType,
  entityName: string,
  options: EntityOptions<T>,
  params: EntityActionParams
): EntityActionMeta => ({
  entityName,
  action: actionName,
  actionType,
  params,
  idAttribute: options.idAttribute || 'uuid',
  key: params.key,
});

const interpolateEndpoint = (
  endpoint: EndpointOption,
  params: EntityActionParams,
  baseUrl?: string
): string => {
  const interpolated = typeof endpoint === 'function' ? endpoint(params) : endpoint;

  return `${baseUrl || ''}${interpolated}`;
};

const getEndpoint = <T extends CustomActions>(
  options: EntityOptions<T>,
  actionName: string,
  actionType: ActionType,
  params: EntityActionParams = {}
): string => {
  const baseEndpoint = interpolateEndpoint(options.endpoint, params, options.baseUrl);

  const isOverrideOrCustom =
    get(options.overrides, `${actionName}.endpoint`) || actionName !== actionType;

  if (isOverrideOrCustom) {
    return baseEndpoint;
  }

  // If the endpoint has no specific action override, treat it
  // as a generic REST endpoint
  if (actionType === 'find' || actionType === 'update' || actionType === 'destroy') {
    if (!params.id) {
      throw new Error(`no ID provided for ${actionName} action`);
    }

    return `${baseEndpoint}/${params.id}`;
  }

  return baseEndpoint;
};

const getEndpointWithQueryParams = <T extends CustomActions>(
  options: EntityOptions<T>,
  actionName: string,
  actionType: ActionType,
  params: EntityActionParams
): string => {
  const baseEndpoint = getEndpoint(options, actionName, actionType, params);

  if (params.queryParams) {
    return `${baseEndpoint}?${queryString.stringify(params.queryParams, {
      arrayFormat: 'bracket',
    })}`;
  }

  return baseEndpoint;
};

const getToken = (
  authToken: StringOrStateFunction | undefined,
  state: Record<string, any>
): string | undefined => {
  if (typeof authToken === 'function') {
    return authToken(state);
  }

  return authToken;
};

const reconcileOptionsForAction = <T extends CustomActions>(
  options: EntityOptions<T>,
  actionName: string,
  actionType: ActionType
): EntityOptions<T> => {
  const overrides = get(options.overrides, actionType, {}) as Partial<EntityOptions<T>>;
  const customActionOptions = get(options.customActions, actionName, {}) as Partial<
    EntityOptions<T>
  >;

  return { ...options, ...overrides, ...customActionOptions };
};

const createRequestActionOptions = <T extends CustomActions>(
  actionName: string,
  actionType: ActionType,
  entityName: string,
  options: EntityOptions<T>,
  params: EntityActionParams,
  state: GlobalState
) => {
  const types = createEntityConstantsForRequestAction(actionName, entityName);
  const endpoint = getEndpointWithQueryParams(options, actionName, actionType, params);
  const method = options.method || getMethod(actionType);
  const meta = {
    entity: createEntityActionMeta(actionName, actionType, entityName, options, params),
  };

  const body = params.body;
  const headers = { ...params.headers };

  const token = getToken(options.authToken, state);
  const getErrorMessageFromResponse = options.getErrorMessageFromResponse;
  const getPayloadFromResponse = options.getPayloadFromResponse;
  const getMetaFromResponse = options.getMetaFromResponse;
  const getFailureMeta = options.getFailureMeta;

  return pickBy({
    types,
    body,
    method,
    endpoint,
    meta,
    headers,
    token,
    getErrorMessageFromResponse,
    getPayloadFromResponse,
    getMetaFromResponse,
    getFailureMeta,
  }) as Params<any>;
};

const createEntityActionCreator = <T extends CustomActions>(
  actionName: string,
  actionType: ActionType,
  entityName: string,
  options: EntityOptions<T>,
  params: EntityActionParams
) => (dispatch: Dispatch<BaseAction>, getState: GetGlobalState) =>
  dispatch(
    createRequestAction(
      createRequestActionOptions(actionName, actionType, entityName, options, params, getState())
    )
  );

export const createQueryAction = <T extends CustomActions>(
  actionName: string,
  entityName: string,
  options: EntityOptions<T>
) => {
  const actionType = actionTypes.query;
  const reconciledOptions = reconcileOptionsForAction(options, actionName, actionType);
  return (params: EntityActionParams) =>
    createEntityActionCreator(actionName, actionType, entityName, reconciledOptions, params);
};

export const createFindAction = <T extends CustomActions>(
  actionName: string,
  entityName: string,
  options: EntityOptions<T>
) => {
  const actionType = actionTypes.find;
  const reconciledOptions = reconcileOptionsForAction(options, actionName, actionType);
  return (id: string, params?: EntityActionParams) =>
    createEntityActionCreator(actionName, actionType, entityName, reconciledOptions, {
      id,
      ...params,
    });
};

export const createCreateAction = <T extends CustomActions>(
  actionName: string,
  entityName: string,
  options: EntityOptions<T>
) => {
  const actionType = actionTypes.create;
  const reconciledOptions = reconcileOptionsForAction(options, actionName, actionType);
  return (params: EntityActionParams) =>
    createEntityActionCreator(actionName, actionType, entityName, reconciledOptions, params);
};

export const createUpdateAction = <T extends CustomActions>(
  actionName: string,
  entityName: string,
  options: EntityOptions<T>
) => {
  const actionType = actionTypes.update;
  const reconciledOptions = reconcileOptionsForAction(options, actionName, actionType);
  return (id: string, params: EntityActionParams) =>
    createEntityActionCreator(actionName, actionType, entityName, reconciledOptions, {
      id,
      ...params,
    });
};

export const createDestroyAction = <T extends CustomActions>(
  actionName: string,
  entityName: string,
  options: EntityOptions<T>
) => {
  const actionType = actionTypes.destroy;
  const reconciledOptions = reconcileOptionsForAction(options, actionName, actionType);
  return (id: string, params?: EntityActionParams) =>
    createEntityActionCreator(actionName, actionType, entityName, reconciledOptions, {
      id,
      ...params,
    });
};

type ActionCreators<T extends CustomActions> = {
  [key in ActionType]: (
    actionName: string,
    entityName: string,
    options: EntityOptions<T>
  ) => Function;
};

const actionCreatorsByActionType: ActionCreators<CustomActions> = {
  query: createQueryAction,
  find: createFindAction,
  create: createCreateAction,
  update: createUpdateAction,
  destroy: createDestroyAction,
};

export const createCustomActions = <T extends CustomActions>(
  entityName: string,
  options: EntityOptions<T>
): { [P in keyof T]?: Function } => {
  if (!some(options.customActions)) {
    return {};
  }

  return mapValues(options.customActions, (customEntityOptions, actionName) => {
    const actionCreator = actionCreatorsByActionType[customEntityOptions.type];

    if (!actionCreator) {
      throw new Error(`custom actions must have a valid type: see ${entityName} ${actionName}`);
    }

    return actionCreator(actionName, entityName, options);
  });
};
