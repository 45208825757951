import React, { Component } from 'react';
import { find, replace } from 'lodash';

import ModalPortal from 'components/modals/portal';

type Props = Readonly<{
  children?: React.ReactNode;
  modals?: Array<React.ReactElement<any>>;
  currentModal: string | null | undefined;
}>;

export default class ModalManager extends Component<Props> {
  findModal(): React.ReactNode | null | undefined {
    const { currentModal, children, modals } = this.props;

    if (modals) {
      return find(modals, { key: currentModal });
    }

    if (children) {
      // NOTE: When converting React children to an array, or passing children
      // down via this.props.children, React will prefix the original keys with `.$`
      // See: https://reactjs.org/docs/react-api.html#reactchildrentoarray
      const childrenArray = React.Children.toArray(children);
      return childrenArray.find(
        child =>
          React.isValidElement(child) && replace(String(child.key), /\.\$/g, '') === currentModal
      );
    }

    return null;
  }

  render() {
    const modal = this.findModal();

    if (!modal) {
      return null;
    }

    const visibleProps = {
      isOpen: true,
      visible: true,
    };

    const modalWithProps = React.isValidElement(modal)
      ? React.cloneElement(modal, visibleProps)
      : modal;

    return <ModalPortal>{modalWithProps}</ModalPortal>;
  }
}
