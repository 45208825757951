import createEntity from 'store/util/createEntity';
import { createRequestReducer } from 'store/util/createRequestReducer';
import createRequestActionAdapter from 'store/util/createRequestActionAdapter';

export { createRequestActionAdapter as createRequestAction, createEntity, createRequestReducer };

export function compareMaps(map1, map2) {
  if (!map1 || !map2) {
    return false;
  }
  if (map1.size !== map2.size) {
    return false;
  }
  for (const [key, val] of map1) {
    if (map2.get(key) !== val || (map2.get(key) === undefined && !map2.has(key))) {
      return false;
    }
  }
  return true;
}
