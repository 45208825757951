import Rollbar from 'rollbar';
import { matchPath } from 'react-router-dom';

import config from 'config';
import routes from 'features/app/routes';
import { KnownRollbarParseErrors } from 'store/constants';

const TELEMETRY_FILTER_HOSTS = ['(sdk|events)\\.split\\.io', 'api\\.segment\\.io'];
const TELEMETRY_FILTER_REGEX = new RegExp(`^https://(${TELEMETRY_FILTER_HOSTS.join('|')})`, 'i');

interface RollbarBody {
  subtype: 'xhr' | 'fetch';
  url: string;

  [key: string]: any;
}

interface TruncatedRollbarPayload {
  body: {
    message: { body: string };
    trace: {
      exception: {
        description: string;
        message: string;
      };
      frames: Array<{
        filename: string;
      }>;
      extra: {
        status: string;
      };
    };
  };
}

type Frame = {
  colno: number;
  filename: string;
  lineno: number;
  method: string;
};

type RollbarData = {
  body:
    | {
        message: { body: string };
      }
    | {
        trace: {
          exception: {
            class: string;
            message: string;
            description?: string;
          };
          frames: Frame[];
        };
      };
};

const rollbar = Rollbar.init({
  enabled: config.appEnv === 'production' || config.appEnv === 'staging',
  accessToken: config.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: false,
  // TODO Suppress uncaught known ParseError errors for now. Might remove after
  // confirming a better solution to react-phone-number-input errors
  // {@link https://jira.weworkers.io/browse/SPSN-8949}
  checkIgnore: (_isUncaught, _args, payload: TruncatedRollbarPayload) => {
    const containsKnownRollbarError: boolean = KnownRollbarParseErrors.some((parseError: string) =>
      payload?.body?.trace?.exception?.description.includes(parseError)
    );
    const has403Regex = /(?<!\d)403(?!\d)/g;
    const containsIgnoredStatusCode: boolean =
      !!payload?.body?.message?.body.match(has403Regex) ||
      !!payload?.body?.trace?.exception?.description.match(has403Regex) ||
      !!payload?.body?.trace?.exception?.message.match(has403Regex) ||
      !!(payload?.body?.trace?.extra?.status === '403');
    return containsKnownRollbarError || containsIgnoredStatusCode;
  },
  hostSafeList: ['wework.com'],
  payload: {
    environment: config.appEnv,
    version: config.version,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: config.version,
        branch: config.branch,
      },
    },
  },
  filterTelemetry: (event): boolean => {
    const body = event.body as RollbarBody;

    if (event.type === 'network' && (body.subtype === 'xhr' || body.subtype === 'fetch')) {
      return TELEMETRY_FILTER_REGEX.test(body.url);
    }

    return false;
  },
  transform: (data: RollbarData): void => {
    const route = routes.find((route): boolean => {
      const path = window.location.hash.replace('#', '');

      return matchPath(path, { path: route.path, exact: route.exact }) !== null;
    });

    if (!route) {
      return;
    }

    const prefixWithPath = (path: string, message: string): string => `${path}: ${message}`;

    // Explicit calls to Rollbar with a string passed contain only a message field.
    if ('message' in data.body) {
      data.body.message.body = prefixWithPath(route.path, data.body.message.body);

      return;
    }

    const exception = data.body.trace?.exception;

    // Explicit calls to Rollbar with an exception argument and a description argument contain an exception with
    // a description, which is used for display in Rollbar's dashboard, so we modify only that.
    if (exception?.description) {
      exception.description = prefixWithPath(route.path, exception.description);

      return;
    }

    // Explicit calls to Rollbar with only an exception argument, or uncaught exceptions, contain an exception with only
    // a message which used for the display, so we modify that.
    if (exception?.message) {
      exception.message = prefixWithPath(route.path, exception.message);
    }
  },
});

export default rollbar;
