import { get } from 'lodash';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { Purpose } from 'store/modules/accounts/constants';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

import { AdapterResult, EntityType, SearchRequest } from '../types';
import { Adapter } from '../searchService';

export default class PrivateMemberAdapter implements Adapter {
  async search(request: SearchRequest): Promise<AdapterResult> {
    if (!request.query) {
      return {
        [EntityType.PRIVATE_MEMBER]: {
          items: [],
          totalCount: 0,
          facets: undefined,
        },
      };
    }

    const accessToken = await getAccessToken();
    const queryParams = queryString.stringify({
      search: request.query,
      purpose: Purpose.PHYSICAL_ACCESS,
      location: get(request, 'filters.locations[0]'),
      status: get(request, 'filters.active') ? 'active' : 'all',
      primary: get(request, 'filters.activeLicense'),
    });
    const response = await fetch(`${config.accountService.uri}/v1/users?${queryParams}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token token=${accessToken}`,
        'x-request-id': uuidv4(),
      },
    });
    if (!response.ok) {
      throw new Error('Unable to fetch users');
    }

    const json = await response.json();

    const items = json?.users || [];
    return {
      [EntityType.PRIVATE_MEMBER]: {
        items,
        totalCount: items.length,
        facets: undefined,
      },
    };
  }
}
