const deskTypes = ['SharedOfficeDesk', 'DedicatedDesk', 'HotDesk'];

const officeTypes = [
  'Office',
  'PrivateHotDeskArea',
  'WWLO',
  'HQbyWeWork',
  'FLOOR',
  'PrivateAccessArea',
  'BookablePrivateOffice',
  'DynamicInventory',
];

const reservableTypes = ['VirtualReservable'];

export { deskTypes, officeTypes, reservableTypes };
