import { GlobalState } from 'store/modules';

import { BuildingsSubset } from './index';

export const getBuildingsLoading = (state: BuildingsSubset): boolean => state.buildings.loading;
export const getBuildingsLoaded = (state: BuildingsSubset): boolean => state.buildings.loaded;
export const getLocaleByBuildingId = (state: GlobalState): string => {
  const ENGLISH_LOCALE = 'en-US';

  if (state.paperwork.pendingMoveIns.length < 1) {
    return ENGLISH_LOCALE;
  }

  const buildingId = state.paperwork.pendingMoveIns[0].building_uuid;

  return state.buildings.byId[buildingId]
    ? state.buildings.byId[buildingId].default_locale
    : ENGLISH_LOCALE;
};
