import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_ACCESS_PERSON_SEARCH,
  FETCH_ACCESS_PERSON_SEARCH_SUCCESS,
  FETCH_ACCESS_PERSON_SEARCH_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_PERSON_SEARCH'));

export const [
  FETCH_ACCESS_PERSON_CONTROLLERS,
  FETCH_ACCESS_PERSON_CONTROLLERS_SUCCESS,
  FETCH_ACCESS_PERSON_CONTROLLERS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_PERSON_CONTROLLERS'));

export const [GET_ACCESS_PERSON_SELECTED_DATA] = createRequestConstantNames(
  createReduxConstant('GET_ACCESS_PERSON_SELECTED_DATA')
);
