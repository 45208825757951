import config from 'config';
import { AlgoliaSecureKeyResponse } from 'features/auth/types';
import { createRequestAction } from 'store/util';
import { getEmployee } from 'features/employees/redux/employees/actions';
import tokenRegistry from 'lib/tokenRegistry';
import { camelCaseJson } from 'lib/util';
import { bootstrapAnalyticsAndRollbar, resetAnalytics } from 'lib/analyticsAndRollbarUtil';
import { BaseAction, Dispatch, GetGlobalState, RequestActionTypes } from 'store/types';
import { ToastType } from 'store/modules/toasts/constants';
import { notifyError } from 'store/modules/toasts/actions';
import {
  Auth0User,
  employeeProfileFromAuth0User,
  getAuthInstance,
  inIframe,
} from 'lib/tokenRegistry/auth0Provider';

import {
  GET_ALGOLIA_SECURED_KEY,
  GET_ALGOLIA_SECURED_KEY_FAIL,
  GET_ALGOLIA_SECURED_KEY_SUCCESS,
} from './constants';
import { authorizeBegin, authorizeSuccess, logoutAction } from './actions';
import { getAuth } from './selectors';

export const getAlgoliaSecuredKey = () => {
  const types: RequestActionTypes = [
    GET_ALGOLIA_SECURED_KEY,
    GET_ALGOLIA_SECURED_KEY_SUCCESS,
    {
      type: GET_ALGOLIA_SECURED_KEY_FAIL,
      meta: {
        notification: {
          message: 'Failed to initialize search component',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  return createRequestAction<AlgoliaSecureKeyResponse>({
    endpoint: config.algolia.securedKeyUri,
    types,
    getPayloadFromResponse: camelCaseJson,
    getErrorMessageFromResponse: (_res, json) => json.error,
  });
};

const initializeUser = async (dispatch: Dispatch<BaseAction>, getState: GetGlobalState) => {
  const auth0User: Auth0User | undefined = getAuthInstance()?.user;
  const employeeProfile = employeeProfileFromAuth0User(auth0User!);

  // fetch the user profile from spaceman
  await dispatch(getEmployee(employeeProfile.employeeUuid));

  // Get the auth state for the logged in user for analytics
  const auth = getAuth(getState());
  bootstrapAnalyticsAndRollbar(auth.data.userInfo);
};

export const loginSuccess = () => async (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  await initializeUser(dispatch, getState);

  dispatch(authorizeSuccess());
};

const authorize = () => async (dispatch: Dispatch<BaseAction>, getState: GetGlobalState) => {
  if (getAuthInstance().isAuthenticated) {
    await initializeUser(dispatch, getState);
    return dispatch(authorizeSuccess());
  }
};

export const appInitAuthorize = () => async (dispatch: Dispatch<BaseAction>) => {
  await dispatch(authorizeBegin());
  return dispatch(authorize());
};

export const login = () => async (dispatch: Dispatch<BaseAction>, getState: GetGlobalState) => {
  dispatch(authorizeBegin());

  // SpaceStation is embedded as an iframe in Salesforce
  // In this situation, we have to use the popup based login flow
  if (inIframe()) {
    getAuthInstance().loginWithPopup();
    await initializeUser(dispatch, getState);
    return dispatch(authorizeSuccess());
  } else {
    // Default case, SpaceStation running as standalone app
    return getAuthInstance().loginWithRedirect({
      appState: { returnTo: window.location.href },
    });
  }
};

export const logout = (logoutReason?: string) => (dispatch: Dispatch<BaseAction>): void => {
  tokenRegistry.clear();
  getAuthInstance().logout();

  resetAnalytics();

  if (logoutReason) {
    dispatch(notifyError(logoutReason));
  }

  dispatch(logoutAction());
};
