export type InitialTerms = {
  calendarUnit: string;
  count: number;
  location_uuid: string;
  started_on: string;
};

export type InitialTermsType = {
  calendarUnit: string;
  count: number;
  locationUUID: string;
  moveInDate: string;
};

export type Promotion = {
  amount: number;
  duration: string;
  initial_term: number;
  percent: boolean;
  perpetuating: boolean;
  variable: string;
};

export type PromotionModel = {
  duration: number;
  variable: boolean;
  percent: boolean;
  amount: number;
  initial_term: number;
  perpetuating: boolean;
  description_blurb: string;
  displayText: string;
  code: string;
};

export type Location = { uuid: string };

export type Reservable = { reservation_uuid: string; end_date: string };

export type Reservation = {
  name: string;
  buildingName: string;
  capacity: number;
  code: string;
  currency: string;
  location: Location;
  moveIn: string;
  officeNum: string;
  price: number;
  building_uuid: string;
  location_uuid: string;
  reservables: Array<Reservable>;
};

export type PendingReservation = {
  action: string;
  buildingName: string;
  building_uuid: string;
  capacity: number;
  country: string;
  countryCode: string;
  currency: string;
  index: number;
  locale: string;
  membership_agreement_uuid: string | null | undefined;
  moveIn: string;
  office: string;
  officeNum: string;
  officeType: string;
  pending: boolean;
  price: number;
  quantity: string;
  uuid: string;
};

export type SerializedReservation = {
  action: string;
  agreement_notes?: string;
  capacity: number;
  change_type: string;
  committed: boolean;
  discount_reason: string | null | undefined;
  initial_terms?: InitialTermsType;
  location_uuid: string;
  long_term_agreement?: string;
  market_price: number;
  membership_agreement_uuid?: string;
  modification_types: string;
  move_in_date: string;
  move_out_date: string | null | undefined;
  notice_requirement?: string;
  quantity: number | null | undefined;
  reservable_number: string;
  reservable_uuid: string;
  reservable_type: string;
  sf_opportunity_id?: string;
  type: string;
  price: number;
  currency: string;
  location: {
    name: string;
    uuid: string;
    currency: string;
    country_code: string;
    locale: string;
  };
  product_uuid?: string;
  officeNum: string;
};

export type SetupFee = {
  startedOn: string;
  locationUUID: string;
  amount: number;
};

export interface SalesforceParams {
  sfOpportunityId: string;
  waitForSfSync: boolean;
  building?: string;
  moveindate?: string;
}

export type SalesforceOpportunityData = {
  id: string;
  name?: string | null;
  accountName?: string | null;
  accountOwnerName?: string | null;
  buildingUuid?: string | null;
  closeDate?: string | null;
  companySfId?: string | null;
  contractStage?: string | null;
  contractUuid?: string | null;
  netTcvLocal?: number | null;
  netTcvUnweighted?: number | null;
  numberOfDesks?: number | null;
  opportunityOwnerName?: string | null;
  opportunityStage?: string | null;
  primaryMemberSfId?: string | null;
  segment?: string | null;
  startDate?: string | null;
  tcvDefaultCurrency?: string | null;
};

export type SalesforceOpportunityPayload = {
  account_name: string;
  account_owner_name: string;
  building_uuid: string;
  close_date: string;
  company_sf_id: string;
  contract_stage: string;
  contract_uuid: string;
  name: string;
  net_tcv_local: number;
  net_tcv_unweighted: number;
  number_of_desks: number;
  opportunity_owner_name: string;
  opportunity_stage: string;
  primary_member_sf_id: string;
  salesforce_id: string;
  segment: string;
  start_date: string;
  tcv_default_currency: string;
};

export type SalesforceOpportunity = SalesforceOpportunityData & {
  meta: {
    waitForSfSync?: boolean;
    building?: string;
    moveindate?: string;
  };
};

export const opportunityPayloadToOpportunity = (
  opportunityPayload: SalesforceOpportunityPayload
): SalesforceOpportunityData => ({
  accountName: opportunityPayload.account_name,
  accountOwnerName: opportunityPayload.account_owner_name,
  buildingUuid: opportunityPayload.building_uuid,
  closeDate: opportunityPayload.close_date,
  companySfId: opportunityPayload.company_sf_id,
  contractStage: opportunityPayload.contract_stage,
  contractUuid: opportunityPayload.contract_uuid,
  name: opportunityPayload.name,
  netTcvLocal: opportunityPayload.net_tcv_local,
  netTcvUnweighted: opportunityPayload.net_tcv_unweighted,
  numberOfDesks: opportunityPayload.number_of_desks,
  opportunityOwnerName: opportunityPayload.opportunity_owner_name,
  opportunityStage: opportunityPayload.opportunity_stage,
  primaryMemberSfId: opportunityPayload.primary_member_sf_id,
  id: opportunityPayload.salesforce_id,
  segment: opportunityPayload.segment,
  startDate: opportunityPayload.start_date,
  tcvDefaultCurrency: opportunityPayload.tcv_default_currency,
});

export type TOS = {
  country: string;
  link: string;
};
