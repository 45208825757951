import { handleActions, Reducer } from 'redux-actions';

import { SET_SELECTED_LOCATIONS } from './constants';

const initialState: Array<string> = [];

const reducer: Reducer<Array<string>, any> = handleActions(
  {
    [SET_SELECTED_LOCATIONS]: (_, action) => [...action.payload],
  },
  initialState
);

export default reducer;
