import React, { Dispatch, useMemo } from 'react';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { HotKeys } from 'react-hotkeys';
import cn from 'classnames';

import { EntityType, Filters, SearchRequest } from 'store/modules/search/searchService/types';
import { GlobalState } from 'store/modules';
import {
  enrichMemberSpaces,
  getSearchFilters,
  isSearching,
  performSearch,
} from 'store/modules/search';
import { searchReset, setSearchFilter } from 'store/modules/search/actions';
import { ReduxProps } from 'store/types';
import { getCurrentLocationUuid } from 'store/selectors';
import withToasts, { ToastsProps } from 'components/decorators/withToasts';
import KeyboardListNavigation from 'components/keyboardListNavigation';
import { SendEventProp, withSendEvent } from 'lib/contextualAnalytics/sendEvent';
import AnalyticsProvider from 'lib/contextualAnalytics/provider';
import { AnalyticsEventName, AnalyticsWorkflow } from 'lib/analytics/constants';
import { trackAnalyticsFor } from 'lib/analytics/analytics';

import TABS from './searchTabs';
import SearchBar from './components/searchBar';
import SearchFilters from './components/searchFilters';
import SearchDropdown from './components/searchDropdown';
import styles from './index.scss';
import * as constants from './constants';
import { fetchRecentlyViewed } from './recentlyViewed/redux';
import { getRecentlyViewed, getRecentlyViewedLoading } from './recentlyViewed/redux/selectors';
import { getFormattedSearchResults, getSearchResultCounts } from './selectors';
import Results from './components/results';
import { getSearchResultAtIndex } from './resultSets/utils';
import { SearchContext } from './context/search';
import { TabDefinition } from './types';

type SearchBarComponent = InstanceType<typeof SearchBar>;

const SEARCH_ANALYTICS_KEY = 'sst_search';
const SLOW_FETCHING_NOTIFICATION_TIMEOUT = 10000;
const SLOW_FETCHING_NOTIFICATION_MSG = 'Seems like something is taking longer than usual...';
const SEARCH_ITEMS_LIMIT = 30;
const SEARCH_TIME_EVENT = 'Search Time';
const SEARCH_TIME_EVENT_LABEL = 'sst_search_time';

const pageAnalyticsData = {
  object: 'Search',
  screen_name: 'Global Search',
  feature_group: 'Global Search',
};

const SearchAnalyticsProvider: React.FC<
  React.PropsWithChildren<{
    searchFilters: Hash<boolean>;
    query: string;
    currentTab: TabDefinition;
  }>
> = ({ searchFilters, query, currentTab, children }) => {
  const analyticsData = useMemo(
    () => ({
      ...pageAnalyticsData,
      search_filters: searchFilters,
      tab_type: currentTab.title,
    }),
    [searchFilters, query, currentTab]
  );
  return <AnalyticsProvider data={analyticsData}>{children}</AnalyticsProvider>;
};

type OwnProps = {
  isOpen?: boolean;
  initialQuery?: string;
  loading: boolean;
  setIsGlobalSearchOpen: Dispatch<React.SetStateAction<boolean>>;
};

const mapStateToProps = (state: GlobalState, props: RouteComponentProps) => ({
  currentLocationUuid: getCurrentLocationUuid(state, props)!,
  isSearching: isSearching(state),
  searchResultSets: getFormattedSearchResults(state),
  searchResultCounts: getSearchResultCounts(state),
  searchFilters: getSearchFilters(state),
  recentlyViewed: getRecentlyViewed(state, props),
  isRecentlyViewedLoading: getRecentlyViewedLoading(state),
});

const mapDispatchToProps = {
  performSearch,
  enrichMemberSpaces,
  searchReset,
  setSearchFilter,
  fetchRecentlyViewed,
};

type Props = Readonly<
  OwnProps &
    ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
    RouteComponentProps &
    SendEventProp &
    ToastsProps
>;

interface State {
  currentItemIndex: number | null;
  currentTabIndex: number;
  dropdownOpen: boolean;
  query: string;
  isFocused: boolean;
}

const HOTKEY_MAP = {
  next: 'alt+right',
  prev: 'alt+left',
};

const DEBOUNCE_SEARCH_MS = 250;

const performDelayed = (fn: () => void) => {
  if (typeof window.requestIdleCallback !== 'undefined') {
    window.requestIdleCallback(fn);
    return;
  }

  setTimeout(fn, 1000 / 60);
};

class Search extends React.PureComponent<Props, State> {
  static defaultProps = {
    isOpen: false,
    initialQuery: '',
  };

  state: State = {
    currentItemIndex: null,
    currentTabIndex: 0,
    dropdownOpen: false,
    query: this.props.initialQuery ?? '',
    isFocused: false,
  };

  slowSearchTimeout: null | NodeJS.Timeout = null;

  input: { current: null | HTMLInputElement } = React.createRef();

  unlisten: null | (() => void);

  searchStartTime = 0;

  componentDidMount() {
    const { history } = this.props;

    this.unlisten = history.listen(this.dropdownClose);
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }

    this.performSearch.cancel();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { searchFilters, isSearching, sendEvent, searchResultCounts } = this.props;
    const query = this.state.query;
    const prevTab = TABS[prevState.currentTabIndex].title;
    const currentTab = TABS[this.state.currentTabIndex].title;

    if (prevProps.searchFilters !== searchFilters && query) {
      this.performSearch(query);
    }

    if (prevTab !== currentTab && !prevProps.isSearching) {
      trackAnalyticsFor(AnalyticsEventName.search_tab, {
        subtab_name: currentTab,
        workflow: AnalyticsWorkflow.GLOBAL_SEARCH_WORKFLOW,
      });
    }

    if (prevProps.isSearching && !isSearching) {
      const endTime = Date.now();
      const passedTime = endTime - this.searchStartTime;
      let resultCount = 0;
      for (const type in searchResultCounts) {
        resultCount += searchResultCounts[type];
      }
      sendEvent(
        SEARCH_TIME_EVENT,
        {
          startTime: this.searchStartTime,
          endTime,
          passedTime,
          event: 'Search Time',
          eventKey: 'Search Time',
        },
        SEARCH_TIME_EVENT_LABEL
      );
      trackAnalyticsFor(AnalyticsEventName.search_results_returned, {
        duration: passedTime,
        filters: {
          current_location: searchFilters.currentLocation,
          active_members: searchFilters.activeMembers,
          inactive_members: searchFilters.inactiveMembers,
        },
        num_rows: resultCount,
        subtab_name: currentTab,
        text: query,
        workflow: AnalyticsWorkflow.GLOBAL_SEARCH_WORKFLOW,
      });
    }

    if (prevState.dropdownOpen !== this.state.dropdownOpen) {
      // NOTE(grozki): You can thank Plasma for me being forced to do this.
      const app = document.getElementById('app');

      if (app) {
        app.classList.toggle('disable-scroll', this.state.dropdownOpen);
      }
    }
  }

  clear = (): void => {
    const { searchReset } = this.props;

    // This operation can cause a noticeable delay in the time it takes the dropdown to disappear.
    // We'll do it in a delayed callback using requestIdleCallback/setTimeout.
    performDelayed(searchReset);

    this.clearSelectedItem();

    this.setQuery('');

    this.performSearch.cancel();

    this.dropdownClose();
  };

  clearSelectedItem = () => this.setState({ currentItemIndex: null });

  setQuery = (query: string) => this.setState({ query });

  dropdownOpen = () => {
    const { fetchRecentlyViewed, setIsGlobalSearchOpen } = this.props;
    const { query } = this.state;
    if (!query) {
      fetchRecentlyViewed();
    }

    this.setState({
      dropdownOpen: true,
    });
    setIsGlobalSearchOpen(true);
  };

  dropdownClose = () => {
    const { setIsGlobalSearchOpen } = this.props;
    this.input.current && this.input.current.blur && this.input.current.blur();

    this.setState({
      dropdownOpen: false,
    });
    setIsGlobalSearchOpen(false);
  };

  performSearch = debounce((query: string) => {
    const { currentLocationUuid, performSearch, searchFilters } = this.props;

    this.searchStartTime = Date.now();

    if (!this.slowSearchTimeout) {
      const { isSearching, notifyInfo } = this.props;

      this.slowSearchTimeout = setTimeout(() => {
        if (isSearching) {
          notifyInfo(SLOW_FETCHING_NOTIFICATION_MSG);
        }

        this.slowSearchTimeout = null;
      }, SLOW_FETCHING_NOTIFICATION_TIMEOUT);
    }

    const filters: Filters = {
      types: [EntityType.COMPANY, EntityType.MEMBER, EntityType.KEYCARD, EntityType.GUEST],
    };

    if (searchFilters.currentLocation) {
      filters.locations = [currentLocationUuid];
    }

    if (searchFilters.activeMembers) {
      filters.active = true;
    }

    const request: SearchRequest = {
      query,
      limit: SEARCH_ITEMS_LIMIT,
      filters,
    };

    performSearch(request);
  }, DEBOUNCE_SEARCH_MS);

  selectTabIndex = (index: number) => {
    this.clearSelectedItem();

    this.setState(state => {
      if (index === state.currentTabIndex) {
        return state;
      }

      return {
        ...state,
        currentTabIndex: index,
      };
    });
  };

  selectNextTab = () => {
    if (document.activeElement !== this.input.current) {
      return;
    }
    const tabIndex = (this.state.currentTabIndex + 1) % TABS.length;

    this.selectTabIndex(tabIndex);

    const event = {
      label: constants.KEYBOARD_NAVIGATION_TAB_EVENT_LABEL,
      event: constants.KEYBOARD_NAVIGATION_EVENT,
      eventKey: constants.KEYBOARD_NAVIGATION_EVENT,
      action: constants.KEYBOARD_NAVIGATION_EVENT,
      details: {
        page_type: this.props.history.location.pathname,
        tab_index: tabIndex,
        tab_type: TABS[tabIndex].title,
        target_text: HOTKEY_MAP.next,
      },
    };

    this.props.sendEvent(
      constants.KEYBOARD_NAVIGATION_EVENT,
      event,
      constants.KEYBOARD_NAVIGATION_TAB_EVENT_LABEL
    );
  };

  selectPrevTab = () => {
    if (document.activeElement !== this.input.current) {
      return;
    }

    const tabIndex = (TABS.length + this.state.currentTabIndex - 1) % TABS.length;

    this.selectTabIndex(tabIndex);

    const event = {
      label: constants.KEYBOARD_NAVIGATION_TAB_EVENT_LABEL,
      event: constants.KEYBOARD_NAVIGATION_EVENT,
      eventKey: constants.KEYBOARD_NAVIGATION_EVENT,
      action: constants.KEYBOARD_NAVIGATION_EVENT,
      details: {
        page_type: this.props.history.location.pathname,
        tab_index: tabIndex,
        tab_type: TABS[tabIndex].title,
        target_text: HOTKEY_MAP.prev,
      },
    };
    this.props.sendEvent(
      constants.KEYBOARD_NAVIGATION_EVENT,
      event,
      constants.KEYBOARD_NAVIGATION_TAB_EVENT_LABEL
    );
  };

  hotKeysHandlers = {
    next: this.selectNextTab,
    prev: this.selectPrevTab,
  };

  handleBlur = (evt: React.FocusEvent<HTMLElement>): void => {
    if (!this.state.dropdownOpen) {
      return;
    }

    // NOTE(grozki): Contrary to spec, React's onBlur and onFocus bubble the events up.
    // With this check we ensure we catch all the blur events in which the focus was shifted to an element still
    // inside the search container.
    // The dropdown will only close if the focus is no longer on the search.
    // See: https://github.com/facebook/react/issues/6410
    if (
      evt.relatedTarget instanceof Node &&
      evt.currentTarget.contains(evt.relatedTarget as Node)
    ) {
      if (this.input.current !== evt.relatedTarget && evt.currentTarget.focus) {
        evt.currentTarget.focus();
      }
      return;
    }

    this.dropdownClose();
  };

  handleInputFocus = () => {
    if (!this.state.dropdownOpen) {
      this.dropdownOpen();
    }
  };

  handleItemSelected = (selectedItemIndex: number | null) => {
    if (selectedItemIndex === null) {
      return;
    }

    const { history, searchResultSets, sendEvent, recentlyViewed } = this.props;
    const { query } = this.state;

    const currentTab = TABS[this.state.currentTabIndex];

    const resultSets = query
      ? currentTab.selectResultSet(searchResultSets)
      : currentTab.selectResults(recentlyViewed);

    const selectedResult = getSearchResultAtIndex(resultSets, selectedItemIndex);

    if (!selectedResult) {
      throw new Error(
        `Could not find item with index ${selectedItemIndex} in current search results.`
      );
    }

    history.push(selectedResult.link);

    const event = {
      label: constants.KEYBOARD_NAVIGATION_ITEM_EVENT_LABEL,
      event: constants.KEYBOARD_NAVIGATION_EVENT,
      eventKey: constants.KEYBOARD_NAVIGATION_EVENT,
      object: 'Search',
      action: 'click',
      details: {
        page_type: this.props.history.location.pathname,
        item_index: selectedItemIndex,
        target_text: 'enter',
      },
    };
    sendEvent(
      constants.KEYBOARD_NAVIGATION_EVENT,
      event,
      constants.KEYBOARD_NAVIGATION_ITEM_EVENT_LABEL
    );
  };

  handleTermChange = (_: React.SyntheticEvent<HTMLInputElement>, query: string): void => {
    const { dropdownOpen } = this.state;
    if (!dropdownOpen && query) {
      this.dropdownOpen();
    }
    this.clearSelectedItem();

    this.setQuery(query);

    if (query.length) {
      this.performSearch(query);
    } else {
      this.props.searchReset();
    }
  };

  handleSelectedItemChange = (index: number | null) => {
    this.setState(state => {
      if (index === state.currentItemIndex) {
        return state;
      }

      return {
        ...state,
        currentItemIndex: index,
      };
    });
  };

  handleSelectedPreviousItem = () => {
    const event = {
      label: constants.KEYBOARD_NAVIGATION_ITEM_EVENT_LABEL,
      event: constants.KEYBOARD_NAVIGATION_EVENT,
      eventKey: constants.KEYBOARD_NAVIGATION_EVENT,
      action: constants.KEYBOARD_NAVIGATION_EVENT,
      details: {
        page_type: this.props.history.location.pathname,
        target_text: 'up',
      },
    };
    this.props.sendEvent(
      constants.KEYBOARD_NAVIGATION_EVENT,
      event,
      constants.KEYBOARD_NAVIGATION_ITEM_EVENT_LABEL
    );
  };

  handleSelectedNextItem = () => {
    const event = {
      label: constants.KEYBOARD_NAVIGATION_ITEM_EVENT_LABEL,
      event: constants.KEYBOARD_NAVIGATION_EVENT,
      eventKey: constants.KEYBOARD_NAVIGATION_EVENT,
      action: constants.KEYBOARD_NAVIGATION_EVENT,
      details: {
        page_type: this.props.history.location.pathname,
        target_text: 'down',
      },
    };
    this.props.sendEvent(
      constants.KEYBOARD_NAVIGATION_EVENT,
      event,
      constants.KEYBOARD_NAVIGATION_ITEM_EVENT_LABEL
    );
  };

  setSearchFilter = (filterName: string, value: boolean) => {
    this.props.setSearchFilter(filterName, value, {
      details: {
        tab_type: TABS[this.state.currentTabIndex].title,
        filter_type: filterName,
        page_type: this.props.history.location?.pathname,
      },
    });
  };

  setInputRef = (inputComponent: SearchBarComponent | null | undefined) => {
    this.input = inputComponent?.input;
  };

  render() {
    const {
      isOpen,
      searchFilters,
      searchResultSets,
      isSearching,
      isRecentlyViewedLoading,
      recentlyViewed,
      history,
      searchResultCounts,
      loading,
    } = this.props;

    const { currentTabIndex, currentItemIndex, dropdownOpen, query } = this.state;

    const currentTab = TABS[currentTabIndex];

    const resultSets = query
      ? currentTab.selectResultSet(searchResultSets)
      : currentTab.selectResults(recentlyViewed);
    const resultCount = resultSets.reduce((amount, set) => amount + set.results.length, 0);
    const isLoading = query ? isSearching : isRecentlyViewedLoading;

    const isDropdownOpen = dropdownOpen || isOpen;

    return (
      <SearchAnalyticsProvider searchFilters={searchFilters} query={query} currentTab={currentTab}>
        <HotKeys
          className={cn(
            'flex-1 fixed top-0 left-[13.75rem] right-0 bg-white outline-0 p-sm mx-8 text-3xs z-20',
            { '!z-30': isDropdownOpen }
          )}
          keyMap={HOTKEY_MAP}
          handlers={this.hotKeysHandlers}
          onBlur={this.handleBlur as () => void}
        >
          <KeyboardListNavigation
            currentItemIndex={currentItemIndex}
            itemCount={resultCount}
            onClear={this.clear}
            onSelect={this.handleItemSelected}
            onPrev={this.handleSelectedPreviousItem}
            onNext={this.handleSelectedNextItem}
            onChange={this.handleSelectedItemChange}
          >
            {currentItemIndex => (
              <SearchContext.Provider
                value={{
                  query,
                  keyboardSelectedItemIndex: currentItemIndex,
                  resultSets,
                  currentTabTitle: currentTab.title,
                  pageUrl: history.location.pathname,
                }}
              >
                <SearchBar
                  className={styles.search}
                  isDropdownOpen={isDropdownOpen}
                  loading={loading}
                  onChange={this.handleTermChange}
                  onClear={this.clear}
                  onFocus={this.handleInputFocus}
                  ref={this.setInputRef}
                  value={query}
                />
                <SearchDropdown isOpen={isDropdownOpen}>
                  <SearchFilters
                    filters={searchFilters}
                    resultCounts={searchResultCounts}
                    onSelect={this.selectTabIndex}
                    onFilterChange={this.setSearchFilter}
                    selectedIndex={currentTabIndex}
                    tabs={TABS}
                  />
                  <Results isLoading={isLoading} resultSets={resultSets} />
                </SearchDropdown>
              </SearchContext.Provider>
            )}
          </KeyboardListNavigation>
        </HotKeys>
      </SearchAnalyticsProvider>
    );
  }
}

export const TestableSearch = Search;

export default compose<React.ComponentType<OwnProps>>(
  withRouter,
  withToasts,
  withSendEvent<OwnProps>({
    analyticsKey: SEARCH_ANALYTICS_KEY,
    analyticsData: pageAnalyticsData,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Search);
