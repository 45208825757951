const UPDATE_SEVERITY_LOW = 'low';
const UPDATE_SEVERITY_MEDIUM = 'medium';
const UPDATE_SEVERITY_HIGH = 'high';

// Temporary export style to support update_version.js.
module.exports = {
  UPDATE_SEVERITY_LOW,
  UPDATE_SEVERITY_MEDIUM,
  UPDATE_SEVERITY_HIGH,
  UPDATE_SEVERITIES: [UPDATE_SEVERITY_LOW, UPDATE_SEVERITY_MEDIUM, UPDATE_SEVERITY_HIGH],
};