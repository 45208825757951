import { handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

export const SET_PENDING_MOVE_OUTS = cc('SET_PENDING_MOVE_OUTS');
export const CLEAR_PENDING_MOVE_OUTS = cc('CLEAR_PENDING_MOVE_OUTS');

export interface PendingMoveOut {
  action: 'drop' | 'cancel';
  buildingName: string;
  location_uuid: string;
  building_uuid: string;
  capacity: number;
  country: string;
  countryCode: string;
  currency: string;
  index: number;
  membership_agreement_uuid?: string;
  moveOut: string;
  office: string;
  officeNum: string;
  officeType: string;
  pending: boolean;
  price: number;
  uuid: string;
}

export type State = Array<PendingMoveOut>;

export const initialState: State = [];
export const reducer = handleActions<State, any>(
  {
    [SET_PENDING_MOVE_OUTS]: (_state: State, action: { payload: Array<PendingMoveOut> }) =>
      action.payload,
    [CLEAR_PENDING_MOVE_OUTS]: () => [],
  },
  initialState
);

export const setPendingMoveOuts = (pendingMoveOuts: Array<PendingMoveOut>) => ({
  type: SET_PENDING_MOVE_OUTS,
  payload: pendingMoveOuts,
});

export const clearPendingMoveOuts = () => ({ type: CLEAR_PENDING_MOVE_OUTS });

export default reducer;
