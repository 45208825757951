import { AccountUser } from 'store/modules/accounts/types';
import { AccountsSubset } from 'store/modules/accounts/index';
import { FilterState, PageState } from 'store/modules/accounts/usersByAccount';

export const getAccountUsersLoading = (state: AccountsSubset): boolean =>
  state.accounts.usersByAccount.loading;

export const getAccountUsersLoaded = (state: AccountsSubset): boolean =>
  state.accounts.usersByAccount.loaded;

export const getAccountUsersFilter = (state: AccountsSubset): FilterState =>
  state.accounts.usersByAccount.filter;

export const getAccountUsersPage = (state: AccountsSubset): PageState =>
  state.accounts.usersByAccount.page;

export const getAccountUsersPageTotal = (state: AccountsSubset): number =>
  state.accounts.usersByAccount.page.total;

export const getAccountUsersByAccountUuid = (state: AccountsSubset) => (
  accountUuid: string
): Array<AccountUser> => state.accounts.usersByAccount.byAccountUuid[accountUuid] || [];

export const getAccountUserByUserUuidAndAccountUuid = (state: AccountsSubset) => (
  userUuid: string,
  accountUuid: string
): AccountUser | undefined => {
  const accountUsers = state.accounts.usersByAccount.byAccountUuid[accountUuid] || [];

  return accountUsers.find(user => user.uuid === userUuid);
};
