import { createAction, handleActions, Action } from 'redux-actions';

import { CommitmentBasedPromotion } from 'features/paperwork/discounts/commitmentBasedPromotions/types';
import cc from 'store/util/createReduxConstant';

// action types
export const CLEAR_COMMITMENT_BASED_PROMOTIONS = cc('COMMITMENT_BASED_PROMOTIONS/CLEAR');
export const SAVE_COMMITMENT_BASED_PROMOTIONS = cc('COMMITMENT_BASED_PROMOTIONS/SAVE');

export type State = Hash<CommitmentBasedPromotion>;

export const initialState: State = {};

// actions
export const clearCommitmentBasedPromotions = createAction(CLEAR_COMMITMENT_BASED_PROMOTIONS);
export const saveCommitmentBasedPromotions = createAction(
  SAVE_COMMITMENT_BASED_PROMOTIONS,
  (commitmentBasedPromotions: Hash<CommitmentBasedPromotion>) => commitmentBasedPromotions
);

// reducer
export default handleActions<State, any>(
  {
    [CLEAR_COMMITMENT_BASED_PROMOTIONS]: () => ({}),
    [SAVE_COMMITMENT_BASED_PROMOTIONS]: (state: State, { payload }: Action<State>) => ({
      ...state,
      ...payload,
    }),
  },
  {}
);
