import { dateFormatsDeprecated } from 'lib/constants';

export const MOVE_DIRECTION_TYPES = Object.freeze({
  moveIn: 'in',
  moveOut: 'out',
});

export const SIGNED_RECENTLY_HOURS = 48;

export const TENURE_TYPES = Object.freeze({
  newToWeWork: 'new_to_wework',
  newToBuilding: 'new_to_building',
});

export enum MimoLabel {
  NewToBuilding = 'newToBuilding',
  NewAccount = 'newAccount',
  InternalTransfer = 'internalTransfer',
  ExternalTransfer = 'externalTransfer',
  SignedRecently = 'signedRecently',
  SelfServe = 'selfServe',
}

export const MIMO_LABELS_TEXT = {
  newToBuilding: 'NEW TO BLDG',
  newAccount: 'NEW ACCOUNT',
  internalTransfer: 'INT. TRANSFER',
  externalTransfer: 'EXT. TRANSFER',
  signedRecently: `SIGNED LAST ${SIGNED_RECENTLY_HOURS}h`,
  selfServe: 'ECOMM',
};

export const EMAIL_DISPLAY_TEXT_FOR_TYPE = {
  Office: 'Office',
  DedicatedDesk: 'Desk',
  HotDesk: 'Desk',
  SharedOfficeDesk: 'Desk',
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const DATE_FORMATS = Object.freeze({
  CARD_DATE_PICKER: 'MMMM d',
  DRAWER_DATETIME: 'MMM D, h:mmA',
  ANALYTICS: 'YYYY-MM',
  API: dateFormatsDeprecated.iso_date,
  SECTION_DATETIME: 'MMMM D, h:mm A',
});

export const TIME_FORMATS = Object.freeze({
  MILITARY_TIME: 'HH:mm',
  CSV_12_HOUR: 'h:mmA',
});

export const ANALYTICS_PAGE_TYPES = Object.freeze({
  MIMO_TRACKER: 'mimo_tracker',
});

export const MOVE_IN_DEFAULT_TIME = '09:00';
export const MOVE_OUT_DEFAULT_TIME = '16:00';

export const PARAM_TYPE = Object.freeze({
  STRING: 'string',
  DATE: 'date',
  TIME: 'time',
  RESERVABLE_LIST: 'reservableList',
});

export enum TaskType {
  REQUEST_FURNITURE = 'mimo::moves_portal::furniture',
  REQUEST_OPTION = 'mimo::moves_portal::option',
  REQUEST_LOGO = 'mimo::moves_portal::logo',
  REQUEST_LOGISTICS = 'mimo::moves_portal::logistics',
  REQUEST_MOVE_IN = 'mimo::checklist::move_in',
  REQUEST_MOVE_OUT = 'mimo::checklist::move_out',
  REQUEST_EXTERNAL_TRANSFER_OUT = 'mimo::checklist::external_transfer_out',
  REQUEST_EXTERNAL_TRANSFER_IN = 'mimo::checklist::external_transfer_in',
  REQUEST_INTERNAL_TRANSFER = 'mimo::checklist::internal_transfer',
}

export const MIMO_TASKLIST_MOVE_TYPES = [
  TaskType.REQUEST_MOVE_IN,
  TaskType.REQUEST_MOVE_OUT,
  TaskType.REQUEST_EXTERNAL_TRANSFER_OUT,
  TaskType.REQUEST_EXTERNAL_TRANSFER_IN,
  TaskType.REQUEST_INTERNAL_TRANSFER,
];

export const MOVE_STATUSES = Object.freeze({
  SCHEDULED: 'scheduled',
  MOVED_OUT: 'moved_out',
  READY_FOR_MOVE_IN: 'ready_for_move_in',
  MOVED_IN: 'moved_in',
  DEFERRED: 'deferred',
});

export const EMAIL_ATTACHMENT_LIMIT = 25 * 1024 * 1024; // 25 MB

export const GMAIL_COMPOSER_URI = 'https://mail.google.com/mail/?view=cm&fs=1&to=';

export const REQUEST_TASK_STATUSES = Object.freeze({
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  UPDATED: 'updated_by_member',
  DONE: 'resolved',
});

// TODO [NBL-1246] Link to email modal
export enum MIMOAutomatedTask {
  SEND_MOVE_EMAIL = 'Send move email',
  SEND_MOVE_OUT_EMAIL = 'Send move-out email',
  SEND_MOVE_IN_EMAIL = 'Send move-in email',
  COMPLETE_MIMO_REQUESTS = 'Complete MIMO requests',
}

export const MIMO_CHECKLIST_AUTOMATED_TASK = isSSMimoChecklistRequestsTaskAutomationOn => ({
  ...(isSSMimoChecklistRequestsTaskAutomationOn
    ? {
        [MIMOAutomatedTask.COMPLETE_MIMO_REQUESTS]: {
          iconName: 'requests',
          href: '/#/mimo-requests',
        },
      }
    : {}),
  [MIMOAutomatedTask.SEND_MOVE_EMAIL]: {
    iconName: 'email',
    href: '/#/mimo-tracker',
  },
  [MIMOAutomatedTask.SEND_MOVE_IN_EMAIL]: {
    iconName: 'email',
    href: '/#/mimo-tracker',
  },
  [MIMOAutomatedTask.SEND_MOVE_OUT_EMAIL]: {
    iconName: 'email',
    href: '/#/mimo-tracker',
  },
});
