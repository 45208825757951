import cc from 'store/util/createReduxConstant';

export const SEARCH = cc('SEARCH');
export const SEARCH_SUCCESS = cc('SEARCH_SUCCESS');
export const SEARCH_FAIL = cc('SEARCH_FAIL');
export const SEARCH_RESET = cc('SEARCH_RESET');

export const SEARCH_ENRICHMENT_USER_SPACES = cc('SEARCH_ENRICHMENT_USER_SPACES');

export const SET_SEARCH_FILTER = cc('SET_SEARCH_FILTER');
export const TOGGLE_SEARCH_FILTER = cc('TOGGLE_SEARCH_FILTER');
export const CLEAR_ALL_SEARCH_FILTERS = cc('CLEAR_ALL_SEARCH_FILTERS');

export const CLEAR_CACHE = cc('CLEAR_CACHE');

export const RESET_USERS_WITH_HOTSTAMP = cc('RESET_USERS_WITH_HOTSTAMP');

export const SEARCH_CHECK_IN_GUEST_SUCCESS = cc('SEARCH_CHECK_IN_GUEST_SUCCESS');

// Constants
export const SEARCH_BAR_FILTER_KEY = 'search.searchFilters';

export const PUBLIC_MEMBER_ITEM_OVERRIDE_FIELDS = [
  'name',
  'email',
  'companies',
  'is_licensee',
  'is_active_licensee',
  'isPrimary',
];
