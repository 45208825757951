import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  CREATE_ADD_ON_AGREEMENT,
  CREATE_ADD_ON_AGREEMENT_SUCCESS,
  CREATE_ADD_ON_AGREEMENT_FAIL,
] = createRequestConstantNames(createReduxConstant('CREATE_ADD_ON_AGREEMENT'));

export const [
  DISCARD_ADD_ON_AGREEMENT,
  DISCARD_ADD_ON_AGREEMENT_SUCCESS,
  DISCARD_ADD_ON_AGREEMENT_FAIL,
] = createRequestConstantNames(createReduxConstant('DISCARD_ADD_ON_AGREEMENT'));

export const [
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT'));

export const [
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_V3,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS_V3,
  FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL_V3,
] = createRequestConstantNames(createReduxConstant('FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT_V3'));

export const [
  REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT,
  REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT_SUCCESS,
  REFRESH_ADD_ON_AGREEMENTS_BY_ACCOUNT_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ADD_ON_AGREEMENTS_BY_ACCOUNT'));
