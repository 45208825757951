import * as React from 'react';
import { Icon, IconName, IconSize } from '@wework/ray2';

type Props = Readonly<{
  isOpen: boolean;
  onToggle: (arg0: boolean) => void;
  unreadNotificationCount: number;
}>;

const getNotificationButtonClass = (isOpen: boolean): string => `
  !w-[40px] !h-[40px] rounded-full
  align-top border border-solid border-gray-80
  ${!isOpen ? 'bg-white' : 'bg-gray-90'}
  hover:bg-gray-95 active:bg-gray-90
  focus-visible:outline-offset-4 focus-visible:outline-primaryblue-70
`;
const notificationDotClass = `w-3 h-3 rounded-full bg-primary absolute`;

const NotificationIcon = ({ isOpen, unreadNotificationCount, onToggle }: Props) => {
  const handleIconClick = () => onToggle(isOpen);

  const icon = (
    <Icon
      className="!mt-[11px] !mb-[13px] !my-3 pointer-events-none"
      icon={IconName.BELL}
      size={IconSize.SMALL}
    />
  );

  const unread = unreadNotificationCount > 0;

  return (
    <button
      className={getNotificationButtonClass(isOpen)}
      onClick={handleIconClick}
      type="button"
      aria-label={`Notifications menu ${
        unread ? `(${unreadNotificationCount} unread notifications)` : '(no unread notifications)'
      }`}
    >
      {icon}
      {unread && <span role="presentation" className={notificationDotClass} />}
    </button>
  );
};

export default NotificationIcon;
