export const CANCELLATION_MODAL_SUBTITLES = {
  PAST:
    'Cancel a conference room booking that is about to start, and free up the room for other members to book',
  ONGOING:
    'Stop an ongoing conference room booking, and free up the room for other members to book',
  FUTURE: 'Cancel a future conference room booking, and free up the room for other members to book',
};

export const CANCELLATION_MODAL_DISCLAIMERS = {
  FUTURE_NO_REASON:
    'Please note that this booking will be cancelled and no credits will be charged',
  FUTURE_WITH_REASON:
    'Credits will be refunded for this booking. Kindly let the member know to cancel their future reservations at least 1 hour before their start time to avoid losing credits',
  ONGOING_WITH_REASON:
    'Credits will be refunded for this booking. Kindly let the member know to cancel their future reservations at least 1 hour before their start time to avoid losing credits',
  ONGOING_NO_REASON:
    'Please note that this booking will be cancelled and no credits will be charged',
};

export const MemberStatus = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISABLED: 'disable',
  POTENTIAL: 'potential',
});

export const ROOM_RESERVATION_REASON_FORM_FIELD = 'roomReservationReasonField';
export const ROOM_RESERVATION_NOTE_FORM_FIELD = 'roomReservationNoteField';
