import { handleActions } from 'redux-actions';

import { SRSBuildingPayload, SrsBuilding } from 'features/building/types';
import {
  FETCH_SRS_BUILDINGS,
  FETCH_SRS_BUILDINGS_FAILURE,
  FETCH_SRS_BUILDINGS_SUCCESS,
} from 'features/building/ducks/buildingConsts';

export type SRSBuildingState = {
  loading: boolean;
  buildings: { [key: string]: SrsBuilding };
};

export const srsBuildingsInitialState: SRSBuildingState = {
  loading: false,
  buildings: {},
};

const srsBuildingsReducer = handleActions<SRSBuildingState, any>(
  {
    [FETCH_SRS_BUILDINGS]: (state: SRSBuildingState): SRSBuildingState => ({
      ...state,
      loading: true,
    }),
    [FETCH_SRS_BUILDINGS_SUCCESS]: (
      state: SRSBuildingState,
      action: SRSBuildingPayload
    ): SRSBuildingState => ({
      ...state,
      buildings: action.payload,
      loading: false,
    }),
    [FETCH_SRS_BUILDINGS_FAILURE]: (state: SRSBuildingState): SRSBuildingState => ({
      ...state,
      buildings: {},
      loading: false,
    }),
  },
  srsBuildingsInitialState
);

export default srsBuildingsReducer;
