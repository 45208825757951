import * as JSONAPI from 'jsonapi-typescript';
import { Moment } from 'moment-timezone';

import { Granularity } from 'components/carouselDatePicker';
import { RoomReservationTypes } from 'features/rooms/constants';
import { RoomsCompany, CompanyServerPayload } from 'features/rooms/types';
import {
  RoomBookingReservation,
  RoomBookingReservationServer,
  Refund,
} from 'features/rooms/components/modals/types';

import {
  Location,
  Room,
  State,
  LocationServerPayload,
  RefundedCreditsServerPayload,
  UserServerPayload,
} from './types';

export const getCurrentReservationTimeFrame = (payload: string, state: State): Moment =>
  payload === Granularity.MONTHS ? state.currentReservationsTimeFrame : state.lastSelectedDay;

const isRelationshipWithData = (
  rel: JSONAPI.RelationshipObject
): rel is JSONAPI.RelationshipsWithData => {
  return 'data' in rel && !!rel.data;
};

const isDataResourceIdentifierObject = (
  data: JSONAPI.ResourceLinkage
): data is JSONAPI.ResourceIdentifierObject => {
  return !!data && 'id' in data;
};

export function getDataItemFromIncluded<
  T extends string = string,
  A extends JSONAPI.AttributesObject = JSONAPI.AttributesObject
>(
  relationship: JSONAPI.RelationshipObject | undefined,
  included: JSONAPI.Included | null | undefined
): JSONAPI.ResourceObject<T, A> | undefined {
  if (!relationship) {
    return undefined;
  }

  return (included ?? []).find(
    (item): boolean =>
      isRelationshipWithData(relationship) &&
      isDataResourceIdentifierObject(relationship.data) &&
      item.id === relationship.data.id &&
      item.type === relationship.data.type
  ) as JSONAPI.ResourceObject<T, A>;
}

export function parseLocation(
  relationship: JSONAPI.RelationshipObject | undefined,
  included: JSONAPI.Included | null | undefined
) {
  const item = getDataItemFromIncluded<string, LocationServerPayload>(relationship, included);

  const location: Location = {
    time_zone: item?.attributes?.time_zone ?? '',
    name: item?.attributes?.name ?? '',
    city: item?.attributes?.address?.city ?? '',
    address: item?.attributes?.address?.line1 ?? '',
  };

  return location;
}

export function parseCompany(
  relationship: JSONAPI.RelationshipObject | undefined,
  included: JSONAPI.Included | undefined
) {
  const item = getDataItemFromIncluded<string, CompanyServerPayload>(relationship, included);

  const company: RoomsCompany = {
    name: item?.attributes?.name ?? '',
    uuid: item?.attributes?.uuid ?? '',
    creditsAlloted: item?.attributes?.credits_alloted ?? '',
  };

  return company;
}

export function parseFloor(
  relationship: JSONAPI.RelationshipObject | undefined,
  included: JSONAPI.Included | undefined
): string | null | undefined {
  const item = getDataItemFromIncluded<string, { name: string }>(relationship, included);

  return item?.attributes?.name;
}

export function parseRoomName(
  relationship: JSONAPI.RelationshipObject | undefined,
  included: JSONAPI.Included | undefined
) {
  const item = getDataItemFromIncluded<string, Room>(relationship, included);
  const floorName = parseFloor(item?.relationships?.floor, included);

  const room: Room = {
    name: item?.attributes?.name || '',
    image_url: item?.attributes?.image_url || '',
    amenity_tags: item?.attributes?.amenity_tags || [''],
    capacity: item?.attributes?.capacity ?? null,
    floorName: floorName ?? null,
  };

  return room;
}

export function parseBookOnBehalf(
  item: JSONAPI.ResourceObject<string, RoomBookingReservationServer>
): boolean {
  const userUuid = item.attributes?.user_uuid ?? '';
  const bookedByUserUuid = item.attributes?.booked_by_user_uuid ?? '';

  return userUuid !== bookedByUserUuid;
}

export function parseRefunds(
  relationship: JSONAPI.RelationshipObject | undefined,
  included: JSONAPI.Included | undefined
): Array<Refund> {
  const refunds: Array<Refund> = [];

  if (Array.isArray(relationship) && relationship.length > 0) {
    relationship.forEach((dataItem): void => {
      const item = getDataItemFromIncluded<string, RefundedCreditsServerPayload>(
        dataItem,
        included
      );

      refunds.push({
        creditsRefunded: Number(item?.attributes?.credits_refunded) ?? 0,
        notes: item?.attributes?.notes ?? '',
      });
    });
  }
  return refunds;
}

export function parseUser(
  relationship: JSONAPI.RelationshipObject | undefined,
  included: JSONAPI.Included | undefined
): UserServerPayload {
  const item = getDataItemFromIncluded<string, UserServerPayload>(relationship, included);
  return {
    uuid: item?.attributes?.uuid || '',
    name: item?.attributes?.name || '',
  };
}

// TODO: (Yotam) Refactor this whole method and add jsonapi types
// the v7 json object is build different from v4, in order to continue use the Reservation Object as v4, need to convert the v7 response that use jsonApi and parse common field under the "include" by "id" and "type".
export function convertV7PayloadToV4(
  payload: JSONAPI.CollectionResourceDoc<string, RoomBookingReservationServer> | null | undefined
): Array<RoomBookingReservation> {
  const payloadData = payload?.data ?? [];

  const result = payloadData.map(
    (
      dataItem: JSONAPI.ResourceObject<string, RoomBookingReservationServer>
    ): RoomBookingReservation => {
      const location = parseLocation(dataItem.relationships?.location, payload?.included);

      const room = parseRoomName(dataItem.relationships?.reservable, payload?.included);

      const company = parseCompany(dataItem.relationships?.company, payload?.included);

      const bookOnBehalf = parseBookOnBehalf(dataItem);

      const user = parseUser(dataItem.relationships?.user, payload?.included);

      const bookedByUser = parseUser(dataItem.relationships?.booked_by_user, payload?.included);

      const reservationRefunds = parseRefunds(
        dataItem?.relationships?.reservation_refunds,
        payload?.included
      );

      return {
        uuid: dataItem.attributes?.uuid ?? '',
        start: dataItem.attributes?.start ?? '',
        finish: dataItem.attributes?.finish ?? '',
        notes: dataItem.attributes?.notes ?? '',
        credits: dataItem.attributes?.credits ?? '',
        user_uuid: dataItem.attributes?.user_uuid ?? '',
        company_uuid: dataItem.attributes?.company_uuid ?? '',
        reservableUuid: dataItem.attributes?.reservable_uuid ?? '',
        type: dataItem.attributes?.reservable_type ?? RoomReservationTypes.CONFERENCE,
        // @ts-ignore admin_edit_url is defined by the server that does a bad usage of json api (this should be under links.self);
        editUrl: dataItem.links?.admin_edit_url ?? '',
        date: dataItem.attributes?.start ?? '',
        cancellationDeadline: dataItem.attributes?.cancellation_deadline ?? '',
        isOnDemand: dataItem.attributes?.is_on_demand ?? false,
        company,
        zone: location?.time_zone,
        roomName: room.name,
        roomImageUrl: room.image_url,
        roomAmenityTags: room.amenity_tags,
        roomCapacity: room.capacity || 0,
        locationName: location?.name,
        roomFloorName: room.floorName || '',
        city: location?.city,
        address: location?.address,
        bookOnBehalf,
        user,
        bookedByUser,
        reservationRefunds,
        refunded: reservationRefunds.length > 0, // TODO when multiple refunds be implemented we need to sum all refunds and compare to reservation.credits
      };
    }
  );

  return result;
}
