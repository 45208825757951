import { handleActions } from 'redux-actions';
import { keyBy } from 'lodash';

import {
  FETCH_ALL_FEATURE_FLAGS,
  FETCH_ALL_FEATURE_FLAGS_SUCCESS,
  FETCH_ALL_FEATURE_FLAGS_FAIL,
  UPDATE_FEATURE_FLAG,
  UPDATE_FEATURE_FLAG_SUCCESS,
  UPDATE_FEATURE_FLAG_FAIL,
  FETCH_ACCOUNT_FEATURE_FLAGS,
  FETCH_ACCOUNT_FEATURE_FLAGS_SUCCESS,
  FETCH_ACCOUNT_FEATURE_FLAGS_FAIL,
} from 'features/companies/companyInfo/redux/featureFlags/constants';
import { State } from 'features/companies/companyInfo/redux/featureFlags/types';

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  featureFlagsFetched: false,
  formSubmitting: false,
  featureFlagAssignments: {},
  featureFlagDescriptions: {},
};

export const reducer = handleActions<State, any, any>(
  {
    [FETCH_ALL_FEATURE_FLAGS]: (state: State) => ({
      ...state,
      featureFlagsFetched: false,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_ALL_FEATURE_FLAGS_SUCCESS]: (
      state: State,
      action: { payload: { feature_flags: [] } }
    ) => {
      const featureFlagDescriptions = keyBy(action.payload.feature_flags, 'feature_key');
      return {
        ...state,
        featureFlagsFetched: true,
        loading: false,
        loaded: true,
        error: null,
        featureFlagDescriptions,
      };
    },
    [FETCH_ALL_FEATURE_FLAGS_FAIL]: (state: State, action: { payload: { message: string } }) => {
      return {
        ...state,
        featureFlagsFetched: false,
        loading: false,
        loaded: false,
        error: action.payload.message,
      };
    },
    [FETCH_ACCOUNT_FEATURE_FLAGS]: (state: State) => ({
      ...state,
      featureFlagsFetched: false,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_ACCOUNT_FEATURE_FLAGS_SUCCESS]: (
      state: State,
      action: { payload: { feature_flags: [] } }
    ) => {
      const featureFlagAssignments = keyBy(action.payload.feature_flags, 'key');
      return {
        ...state,
        featureFlagsFetched: true,
        loading: false,
        loaded: true,
        error: null,
        featureFlagAssignments,
      };
    },
    [FETCH_ACCOUNT_FEATURE_FLAGS_FAIL]: (
      state: State,
      action: { payload: { message: string } }
    ) => {
      return {
        ...state,
        featureFlagsFetched: false,
        loading: false,
        loaded: false,
        error: action.payload.message,
      };
    },
    [UPDATE_FEATURE_FLAG]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [UPDATE_FEATURE_FLAG_SUCCESS]: (state: State) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      };
    },
    [UPDATE_FEATURE_FLAG_FAIL]: (state: State, action: { payload: { message: string } }) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),
  },
  initialState
);

export default reducer;
