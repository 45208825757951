import axios, { Method } from 'axios';

import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { setHeaders } from 'lib/api/apiRules';

class WeWorkBase {
  host: string;

  constructor(host: string) {
    this.host = host;
  }

  get(path: string, params?: Object) {
    return this.baseRequest('get', path, params);
  }
  post(path: string, params?: Object) {
    return this.baseRequest('post', path, params);
  }
  put(path: string, params?: Object) {
    return this.baseRequest('put', path, params);
  }
  delete(path: string, params?: Object) {
    return this.baseRequest('delete', path, params);
  }

  getToken() {
    return getAccessToken();
  }

  async baseRequest(method: Method, path: string, params: {} = {}) {
    const url = this.host + path;
    const options: {
      url: string;
      method: Method;
      params?: {};
      data?: {};
      headers?: {};
    } = {
      url,
      method,
    };

    if (method === 'get') {
      options.params = params;
    } else if (Object.keys(params).length > 0) {
      options.data = params;
    }

    const accessToken = await this.getToken();
    const originalHeaders = {
      Authorization: `Token token="${accessToken}"`,
    };

    const headers = setHeaders(url, originalHeaders);
    options.headers = headers;
    return axios({
      ...options,
      headers,
    });
  }
}

export default WeWorkBase;
