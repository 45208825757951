import { handleActions } from 'redux-actions';

import {
  FETCH_S2_SEARCHED_KEYCARDS,
  FETCH_S2_SEARCHED_KEYCARDS_SUCCESS,
  FETCH_S2_SEARCHED_KEYCARDS_FAIL,
  REMOVE_KEYCARD,
  REMOVE_KEYCARD_SUCCESS,
  REMOVE_KEYCARD_FAIL,
} from 'features/accessControl/s2keycards/redux/constants';

export interface State {
  s2SearchedKeycards: any[];
  airlockSearchedKeycards: any[];
  loadingSearchedKeycards: boolean;
  loadingSearchedKeycardsSuccess: boolean;
  loadingSearchedKeycardsFail: boolean;
  removingKeycard: boolean;
  removingKeycardSuccess: boolean;
  removingKeycardFail: boolean;
}

export interface s2KeycardsSubset {
  s2Keycards: State;
}

const initialState: State = {
  s2SearchedKeycards: [],
  airlockSearchedKeycards: [],
  loadingSearchedKeycards: false,
  loadingSearchedKeycardsSuccess: false,
  loadingSearchedKeycardsFail: false,
  removingKeycard: false,
  removingKeycardSuccess: false,
  removingKeycardFail: false,
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_S2_SEARCHED_KEYCARDS]: (state: State) => ({
      ...state,
      loadingSearchedKeycardsFail: false,
      loadingSearchedKeycards: true,
    }),

    [FETCH_S2_SEARCHED_KEYCARDS_SUCCESS]: (state: State, action) => ({
      ...state,
      s2SearchedKeycards: action.payload?.result?.s2 || [],
      airlockSearchedKeycards: action.payload?.result?.airlock || [],
      loadingSearchedKeycards: false,
      loadingSearchedKeycardsSuccess: true,
    }),

    [FETCH_S2_SEARCHED_KEYCARDS_FAIL]: (state: State) => ({
      ...state,
      s2SearchedKeycards: [],
      airlockSearchedKeycards: [],
      loadingSearchedKeycards: false,
      loadingSearchedKeycardsSuccess: false,
      loadingSearchedKeycardsFail: true,
    }),

    [REMOVE_KEYCARD]: (state: State) => ({
      ...state,
      removingKeycard: true,
    }),

    [REMOVE_KEYCARD_SUCCESS]: (state: State) => ({
      ...state,
      removingKeycard: false,
      removingKeycardSuccess: true,
    }),

    [REMOVE_KEYCARD_FAIL]: (state: State) => ({
      ...state,
      removingKeycard: false,
      removingKeycardSuccess: false,
      removingKeycardFail: true,
    }),
  },
  initialState
);

export default reducer;
