import React, { useRef, useContext } from 'react';
import { Loader } from '@wework-dev/plasma';

import { ContainerRefContext } from 'components-dieter/actionsMenu/containerRef';

import ResultSetComponent from '../resultSets';
import { ResultSet } from '../resultSets/types';
import { SearchContext } from '../context/search';

import ResultsEmpty from './resultsEmpty';

interface Props {
  resultSets: Array<ResultSet>;
  isLoading: boolean;
}

const ResultsLoader = () => (
  <div className="p-sm mt-2xs border-t border-solid">
    <Loader />
  </div>
);

const Results: React.FC<Props> = ({ resultSets, isLoading }) => {
  const { query } = useContext(SearchContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  if (isLoading) {
    return <ResultsLoader />;
  }
  if (resultSets.length === 0) {
    return query ? <ResultsEmpty /> : null;
  }

  return (
    <div className="flex-1 overflow-auto pb-sm" ref={containerRef}>
      <ContainerRefContext.Provider value={containerRef}>
        {resultSets.map(resultSet => (
          <ResultSetComponent data={resultSet} key={resultSet.key} />
        ))}
      </ContainerRefContext.Provider>
    </div>
  );
};

export default Results;
