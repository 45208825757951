import Config from 'config';
import rollbar from 'lib/rollbar';
import { UserInfo } from 'features/auth/reducer';

export const resetAnalytics = () => {
  window.analytics.reset();
};

export const bootstrapAnalyticsAndRollbar = (userInfo: UserInfo): void => {
  [userInfo.email, userInfo.uuid]
    .filter(Boolean)
    .forEach((identifier: string): void => window.analytics.alias(identifier));

  const trackData = {
    user_uuid: userInfo.userUuid,
    email: userInfo.email,
    location_uuid: userInfo.locationUuid,
    app_version: Config.version,
    app_branch: Config.branch,
  };

  window.analytics.identify(userInfo.uuid, trackData);

  rollbar.configure({
    payload: {
      person: {
        id: userInfo.uuid ?? '',
        ...trackData,
      },
    },
  });
};
