export interface CommitmentTerm {
  id: string;
  startDate: {
    value: string;
  };
  endDate: {
    value: string;
  };
}

export type CommitmentTermInput = {
  id: string;
  startDate: {
    value: string;
    editable: boolean;
  };
  endDate: {
    value: string;
    editable: boolean;
  };
  length: {
    value: number;
    editable: boolean;
  };
  errors: Hash<string>;
};
export type CommitmentTermInputAttrs = {
  id: string;
  startDate?: string;
  endDate?: string;
  length?: number;
};

export type CommitmentTermAttrs = {
  id: string;
  startDate: string;
  endDate: string;
  length: number;
};

export type CommitmentTermsPayload = {
  id: string;
  started_on: string;
  ended_on: string;
  duration_in_months: number;
  editable: Array<string>;
};

export type TermPayload = {
  calendarUnit: string;
  count: number;
  location_uuid: string;
  started_on: string;
};

export type CommitmentTermPayloadSubmit = {
  id: string;
  started_on: string;
  ended_on: string;
  months: number;
};

export const toTermPayload = (term: CommitmentTermInput): CommitmentTermPayloadSubmit => ({
  id: term.id,
  started_on: term.startDate.value,
  ended_on: term.endDate.value,
  months: term.length.value,
});

export const toCommitmentTermsInput = (term: CommitmentTermsPayload): CommitmentTermInput => ({
  id: term.id,
  startDate: {
    value: term.started_on,
    editable: term.editable.includes('started_on'),
  },
  endDate: {
    value: term.ended_on,
    editable: term.editable.includes('ended_on'),
  },
  length: {
    value: term.duration_in_months,
    editable: term.editable.includes('duration_in_months'),
  },
  errors: {},
});

export const termInputToAttrs = (term: CommitmentTermInput): CommitmentTermAttrs => ({
  id: term.id,
  startDate: term.startDate.value,
  endDate: term.endDate.value,
  length: term.length.value,
});
