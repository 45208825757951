import { createAction } from 'redux-actions';

import { BaseAction, Dispatch } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import cc from 'store/util/createReduxConstant';
import { fetchInventory } from 'store/modules/siInventory/api';
import { Location as SILocation } from 'store/modules/siLocations';

import { serializeSearchResultSI } from '../serializers/inventorySerializer';
import { Filters } from '../config/types';
import { SIInventoryAndLocationPreprocess, SIInventorySearchResult } from '../types';
import SerializersMapWithItems from '../config/serializersMapWithItems';
import { inventorySearchQuery, SIInventoryResponse } from '../config/siQueries';
import applyFilterMappers from '../config/siFilterMap';

import { locationsForInventory } from './utils';

// Action Constants
export const CLEAR_ES_INVENTORY = cc('CLEAR_ES_INVENTORY');
export const SEARCH_ES_INVENTORY = cc('SEARCH_ES_INVENTORY');
export const SEARCH_ES_INVENTORY_SUCCESS = cc('SEARCH_ES_INVENTORY_SUCCESS');
export const SEARCH_ES_INVENTORY_FAIL = cc('SEARCH_ES_INVENTORY_FAIL');

const pageSize = 100;

export const initialResultState: SIInventorySearchResult = {
  inventory: [],
  metadata: {
    pagination: {
      offset: 0,
      limit: pageSize,
      totalCount: 0,
    },
    location: {
      availableCount: {},
    },
  },
  rawPayload: {
    data: [],
    pageInfo: {
      offset: 0,
      limit: pageSize,
      totalCount: 0,
    },
    meta: {
      availableCount: [],
    },
  },
  isSearchGroups: true,
};

export const clearInventoryEs = () => (dispatch: Dispatch<BaseAction>) =>
  dispatch({ type: CLEAR_ES_INVENTORY });

const searchEsInventoryAction = createAction(SEARCH_ES_INVENTORY);
const searchEsInventorySuccessAction = createAction(SEARCH_ES_INVENTORY_SUCCESS);
const searchEsInventoryFailAction = createAction(SEARCH_ES_INVENTORY_FAIL);

export const searchInventoryEs = (filters: Filters) => async (dispatch: Dispatch<BaseAction>) => {
  try {
    dispatch(searchEsInventoryAction());

    // gateway actually ignores filters & page when cursor is present, but safe to do so here as well
    const variables = {
      filters: applyFilterMappers(filters),
      cursor: null,
      limit: null,
    };

    const inventory: SIInventoryResponse = await fetchInventory(inventorySearchQuery, variables);
    if (inventory.error) {
      return dispatch(searchEsInventoryFailAction(inventory.error));
    }

    const { locationResponses, errors } = await locationsForInventory(
      inventory.data.searchInventory.data
    );
    // extract required location ids
    if (errors && errors.length > 0) {
      return dispatch(searchEsInventoryFailAction(errors));
    }

    const locations: Array<SILocation> = locationResponses.map(resp => resp.data.location);

    // combine results
    const result: SIInventoryAndLocationPreprocess = {
      locations,
      inventory: inventory.data.searchInventory,
      isSearchGroups: true,
    };

    return dispatch(searchEsInventorySuccessAction(result));
  } catch (err: any) {
    return dispatch(searchEsInventoryFailAction(err));
  }
};

const reducer = createRequestReducer<SIInventorySearchResult, SIInventoryAndLocationPreprocess>(
  [SEARCH_ES_INVENTORY, SEARCH_ES_INVENTORY_SUCCESS, SEARCH_ES_INVENTORY_FAIL, CLEAR_ES_INVENTORY],
  initialResultState,
  res => serializeSearchResultSI(res?.payload, SerializersMapWithItems)
);

export default reducer;
