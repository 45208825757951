import React from 'react';
import ReactSelect, { GroupBase } from 'react-select';
import type { Props as SelectProps } from 'react-select/dist/declarations/src';
import cn from 'classnames';

export const ClearIndicator = ({ innerProps }) => {
  return (
    <div {...innerProps} className="Select__clear">
      <span className="Select__clear-indicator">×</span>
    </div>
  );
};
export const DropdownIndicator = () => (
  <span className="Select-arrow-zone">
    <span className="Select-arrow" />
  </span>
);

export const MultiValueRemove = ({ innerProps }) => (
  <span {...innerProps} className="Select__multi-value__remove">
    ×
  </span>
);

const StyledReactSelect = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  className,
  ...props
}: SelectProps<Option, IsMulti, Group>): JSX.Element => {
  return (
    <ReactSelect
      classNamePrefix="Select"
      className={cn('Select', className)}
      {...props}
      components={{
        DropdownIndicator,
        ClearIndicator,
        IndicatorSeparator: null,
        MultiValueRemove,
        ...props.components,
      }}
    />
  );
};

export default StyledReactSelect;
