import React, { useState, useCallback } from 'react';
import cn from 'classnames';

import Tooltip, { TooltipProps } from 'components-ray/tooltip';
import { copyTextToClipboard } from 'lib/util';

type OwnProps = {
  className?: string;
  textToCopy: string;
  textClassName?: string;
  tooltipClassName?: string;
  tooltipHoverText?: string;
  tooltipClickText?: string;
  onClick?: (event?: React.SyntheticEvent<any>) => void;
} & Pick<TooltipProps, 'placement'>;

type Props = OwnProps;

const CopyableTooltip: React.FC<Props> = ({
  className,
  placement,
  textToCopy,
  textClassName,
  tooltipClassName,
  tooltipClickText = 'Copied!',
  tooltipHoverText = 'Copy to clipboard',
  onClick,
}) => {
  const [copied, setCopied] = useState(false);
  const handleClick = useCallback(
    (...args) => {
      copyTextToClipboard(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);

      if (onClick) {
        onClick(...args);
      }
    },
    [textToCopy, onClick]
  );

  return (
    <Tooltip
      className={tooltipClassName}
      placement={placement}
      tooltip={copied ? tooltipClickText : tooltipHoverText}
    >
      <div className={cn(className)}>
        <span
          className={cn(textClassName, 'cursor-pointer text-primary text-3xs')}
          onClick={handleClick}
        >
          {textToCopy}
        </span>
      </div>
    </Tooltip>
  );
};

export default CopyableTooltip;
