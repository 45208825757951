import { lazy as reactLazy, ComponentType } from 'react';

import rollbar from 'lib/rollbar';

function isJSChunkLoadError(error: Error) {
  return error.message.toLowerCase().startsWith('loading chunk');
}

function isCSSChunkLoadError(error: Error) {
  return error.message.toLowerCase().startsWith('loading css chunk');
}

function isChunkLoadError(error: Error) {
  return isJSChunkLoadError(error) || isCSSChunkLoadError(error);
}

export const lazy = (
  importCallback: () => Promise<{ default: ComponentType<any> }>
): ComponentType<any> => {
  return reactLazy<any>(async () => {
    try {
      return await importCallback();
    } catch (error) {
      if (error instanceof Error && isChunkLoadError(error)) {
        rollbar.warning(error.message);
        window.location.reload();
      } else {
        rollbar.error(error);
      }

      return import('components/loading');
    }
  });
};
