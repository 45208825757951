import { createRequestReducer } from 'store/util/createRequestReducer';

import {
  FETCH_ACCOUNT_DISCOUNTS,
  FETCH_ACCOUNT_DISCOUNTS_FAIL,
  FETCH_ACCOUNT_DISCOUNTS_SUCCESS,
} from './constants';
import { DiscountReservation } from './types';

const handlePayload = (response, previousState): Hash<Array<DiscountReservation>> => {
  return {
    ...previousState,
    [response.payload.params.accountId]: response.payload.result,
  };
};

export const reducer = createRequestReducer<
  Hash<Array<DiscountReservation>>,
  Array<DiscountReservation>
>(
  [FETCH_ACCOUNT_DISCOUNTS, FETCH_ACCOUNT_DISCOUNTS_SUCCESS, FETCH_ACCOUNT_DISCOUNTS_FAIL],
  {},
  handlePayload
);

export default reducer;
