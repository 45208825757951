import { handleActions, Action } from 'redux-actions';

import {
  FETCH_EMAIL_TEMPLATE_TYPES,
  FETCH_EMAIL_TEMPLATE_TYPES_SUCCESS,
  FETCH_EMAIL_TEMPLATE_TYPES_FAIL,
  SELECT_EMAIL_TEMPLATE_TYPE,
} from 'features/mimo/redux/emailTemplateTypes/constants';
import { State, EmailTemplateData } from 'features/mimo/redux/emailTemplateTypes/types';

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  data: [],
  error: false,
  selected: null,
};

export const reducer = handleActions<State, Array<EmailTemplateData> | string>(
  {
    [FETCH_EMAIL_TEMPLATE_TYPES]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_EMAIL_TEMPLATE_TYPES_SUCCESS]: (
      state,
      { payload: data }: Action<Array<EmailTemplateData>>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      data,
      selected: state.selected || data[0]?.slug,
    }),

    [FETCH_EMAIL_TEMPLATE_TYPES_FAIL]: state => ({
      ...state,
      loading: false,
      loaded: false,
      error: true,
    }),

    [SELECT_EMAIL_TEMPLATE_TYPE]: (state, { payload: selected }: Action<string>) => ({
      ...state,
      selected,
    }),
  },
  initialState
);

export default reducer;
