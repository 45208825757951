import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_INCIDENT_TYPES,
  FETCH_INCIDENT_TYPES_SUCCESS,
  FETCH_INCIDENT_TYPES_FAIL,
] = createRequestConstantNames(cc('FETCH_INCIDENT_TYPES'));

export const [
  FETCH_USER_STATUS,
  FETCH_USER_STATUS_SUCCESS,
  FETCH_USER_STATUS_FAIL,
] = createRequestConstantNames(cc('FETCH_USER_STATUS'));

export const [CREATE_BAN, CREATE_BAN_SUCCESS, CREATE_BAN_FAIL] = createRequestConstantNames(
  cc('CREATE_BAN')
);

export const [
  GET_IMPERSONATION_HISTORY,
  GET_IMPERSONATION_HISTORY_SUCCESS,
  GET_IMPERSONATION_HISTORY_FAIL,
] = createRequestConstantNames(cc('GET_IMPERSONATION_HISTORY'));

export const [
  IMPERSONATE_MEMBER,
  IMPERSONATE_MEMBER_SUCCESS,
  IMPERSONATE_MEMBER_FAIL,
] = createRequestConstantNames(cc('IMPERSONATE_MEMBER'));

export const [
  ANONYMIZE_USER,
  ANONYMIZE_USER_SUCCESS,
  ANONYMIZE_USER_FAIL,
] = createRequestConstantNames(cc('ANONYMIZE_USER'));

export const [
  GET_USER_AUDIT_HISTORY,
  GET_USER_AUDIT_HISTORY_SUCCESS,
  GET_USER_AUDIT_HISTORY_FAIL,
] = createRequestConstantNames(cc('GET_USER_AUDIT_HISTORY'));

export const [
  GET_LOCALE_CODES,
  GET_LOCALE_CODES_SUCCESS,
  GET_LOCALE_CODES_FAIL,
] = createRequestConstantNames(cc('GET_LOCALE_CODES'));

export const [
  GET_USER_KIND_TYPES,
  GET_USER_KIND_TYPES_SUCCESS,
  GET_USER_KIND_TYPES_FAIL,
] = createRequestConstantNames(cc('GET_USER_KIND_TYPES'));

export const [
  SYNC_USER_TO_MENA,
  SYNC_USER_TO_MENA_SUCCESS,
  SYNC_USER_TO_MENA_FAIL,
] = createRequestConstantNames(cc('SYNC_USER_TO_MENA'));

export const [
  DEACTIVATE_WEWORK_EMPLOYEE,
  DEACTIVATE_WEWORK_EMPLOYEE_SUCCESS,
  DEACTIVATE_WEWORK_EMPLOYEE_FAIL,
] = createRequestConstantNames(cc('DEACTIVATE_WEWORK_EMPLOYEE'));

export const [
  ACTIVATE_WEWORK_EMPLOYEE,
  ACTIVATE_WEWORK_EMPLOYEE_SUCCESS,
  ACTIVATE_WEWORK_EMPLOYEE_FAIL,
] = createRequestConstantNames(cc('ACTIVATE_WEWORK_EMPLOYEE'));

export const [
  RECONCILE_EMPLOYEE_KIND,
  RECONCILE_EMPLOYEE_KIND_SUCCESS,
  RECONCILE_EMPLOYEE_KIND_FAIL,
] = createRequestConstantNames(cc('RECONCILE_EMPLOYEE_KIND'));

export const [
  GET_ACCOUNT_AUDIT_LOG,
  GET_ACCOUNT_AUDIT_LOG_SUCCESS,
  GET_ACCOUNT_AUDIT_LOG_FAIL,
] = createRequestConstantNames(cc('GET_ACCOUNT_AUDIT_LOG'));
