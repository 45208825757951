import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  SEARCH_ALL_INVENTORY_METRICS,
  SEARCH_ALL_INVENTORY_METRICS_SUCCESS,
  SEARCH_ALL_INVENTORY_METRICS_FAIL,
] = createRequestConstantNames(cc('SEARCH_ALL_INVENTORY_METRICS'));

export const [
  SEARCH_INVENTORY_METRICS,
  SEARCH_INVENTORY_METRICS_SUCCESS,
  SEARCH_INVENTORY_METRICS_FAIL,
] = createRequestConstantNames(cc('SEARCH_INVENTORY_METRICS'));
