import queryString from 'query-string';

import { camelCaseJson } from 'lib/util';
import { ApiError, messageHandler } from 'store/errors';

import { setHeaders } from './api/apiRules';

export enum AuthPrefix {
  TOKEN,
  BEARER,
}

const parseQuery = (params: {}, useArrayFormatBrackets: boolean) =>
  useArrayFormatBrackets
    ? queryString.stringify(params, { arrayFormat: 'bracket' })
    : new URLSearchParams(params).toString();

// Todo - delete 'encryptedEmployeeUuid' after spacemanJWT includes it later.
export function httpFetcher({
  domain,
  authPrefix,
  getAccessToken,
  encryptedEmployeeUuid,
  entry = 'data',
  getEntry = true,
}: {
  domain: string;
  authPrefix: AuthPrefix;
  getAccessToken: () => Promise<string>;
  encryptedEmployeeUuid?: string;
  entry?: string;
  getEntry?: boolean;
  method?: string;
}) {
  return async <ResponseType = any>(
    path: string,
    params?: {},
    useArrayFormatBrackets = false,
    method = 'GET',
    signal?: AbortSignal,
    extraHeaders?: Record<string, string>
  ): Promise<ResponseType> => {
    const accessToken = await getAccessToken();

    const authorization = {
      [AuthPrefix.TOKEN]: `Token token=${accessToken}`,
      [AuthPrefix.BEARER]: `Bearer ${String(accessToken)}`,
    }[authPrefix];

    const intialHeaders = {
      'Content-Type': 'application/json',
      Authorization: authorization,
      ...(extraHeaders && { ...extraHeaders }),
    };

    const headers = setHeaders(domain, intialHeaders);

    // eslint-disable-next-line dot-notation
    if (encryptedEmployeeUuid) headers['encrypted_employee_uuid'] = encryptedEmployeeUuid;

    let response;
    if (method === 'GET') {
      response = await fetch(
        `${domain}/${path}${params ? `?${parseQuery(params, useArrayFormatBrackets)}` : ''}`,
        {
          mode: 'cors',
          method,
          headers,
          signal,
        }
      );
    } else if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      response = await fetch(`${domain}/${path}`, {
        mode: 'cors',
        method,
        headers,
        signal,
        body: JSON.stringify(params),
      });
    }

    if (!response.ok) {
      const errorMessage = response.message;

      throw new ApiError(
        response.status,
        response.statusText,
        response,
        errorMessage || messageHandler(response.status, response.statusText, domain)
      );
    }

    let json = await response.json();

    if (getEntry && entry !== undefined && json.hasOwnProperty(entry)) {
      json = json[entry];
    }

    return camelCaseJson(json);
  };
}
