import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

import ChevronIconSmall from '../icons/ChevronIconSmall';
import CloseIconSmall from '../icons/CloseIconSmall';

export enum ChipSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum ChipType {
  CLOSABLE = 'closable',
  DROPDOWN = 'dropdown',
}

const getIconColor = (selected?: boolean, disabled?: boolean): string => {
  if (selected && !disabled) return 'text-primary';
  if (!selected && !disabled) return 'text-gray-40';
  if (!selected && disabled) return 'text-gray-70';
  return 'text-primaryblue-80';
};

export interface ChipProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  chipType?: ChipType;
  size?: ChipSize;
  icon?: JSX.Element;
  onClose?: (e: React.SyntheticEvent<SVGElement>) => void;
}

const Chip = ({
  children,
  selected,
  disabled,
  className,
  type = 'button',
  chipType,
  icon,
  size = ChipSize.MEDIUM,
  onClose,
  ...rest
}: ChipProps): JSX.Element => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={cn(
        className,
        'border border-solid inline-flex items-center font-main rounded-[56px]',
        'focus-visible:outline-primaryblue-70 focus-visible:outline-offset-4',
        size === ChipSize.SMALL && 'py-3xs px-[10px] h-lg',
        size === ChipSize.MEDIUM && 'py-2xs px-xs h-10',
        selected &&
          !disabled &&
          'border-primary text-primary bg-primaryblue-95 hover:bg-primaryblue-90 focus-visible:outline-2 active:bg-primaryblue-80',
        selected && disabled && 'text-primaryblue-80 border-primaryblue-80',
        !selected &&
          !disabled &&
          'border-gray-40 text-black hover:bg-gray-95 focus-visible:outline-2 active:bg-gray-90',
        !selected && disabled && 'text-gray-70 border-gray-70'
      )}
      {...rest}
    >
      {/* TODO: Add icons from Ray {@link https://jira.weworkers.io/browse/DSYS-4} */}
      {icon &&
        React.cloneElement(icon, {
          className: cn('mr-3xs h-xs w-xs', getIconColor(selected, disabled), icon.props.className),
        })}
      {children}
      {/* TODO: Add icons from Ray {@link https://jira.weworkers.io/browse/DSYS-4} */}
      {chipType === ChipType.CLOSABLE && (
        <CloseIconSmall className="ml-2xs flex-shrink-0" onClick={onClose} />
      )}
      {chipType === ChipType.DROPDOWN && (
        <ChevronIconSmall
          className={cn('ml-2xs mt-[3px] flex-shrink-0', getIconColor(selected, disabled))}
        />
      )}
    </button>
  );
};

export default Chip;
