import { handleActions } from 'redux-actions';

import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';
import { BaseAction, BaseState } from 'store/types';
import { AccountUser, ListAccountUsersResponse } from 'store/modules/accounts/types';
import {
  Purpose,
  DEFAULT_FILTER_STATE,
  DEFAULT_PAGE_STATE,
} from 'store/modules/accounts/constants';
import { FetchUsersByAccountOptions } from 'store/modules/accounts/actions';

// Action Constants
export const [
  FETCH_USERS_BY_ACCOUNT,
  FETCH_USERS_BY_ACCOUNT_SUCCESS,
  FETCH_USERS_BY_ACCOUNT_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_USERS_BY_ACCOUNT'));

export interface FilterState {
  name: string | null | undefined;
  status: string | null | undefined;
  location: string | null | undefined;
  purpose: Purpose | null | undefined;
  user_group: string | null | undefined;
}

export interface PageState {
  number: number;
  size: number;
  total: number;
}

export interface State extends BaseState {
  filter: FilterState;
  page: PageState;
  byAccountUuid: Hash<Array<AccountUser>>;
}

export interface UsersByAccountSubset {
  usersByAccount: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  filter: DEFAULT_FILTER_STATE,
  page: DEFAULT_PAGE_STATE,
  byAccountUuid: {},
};

export interface ActionPayload extends BaseAction {
  payload: ListAccountUsersResponse;
  meta: {
    accountUuid: string;
    options: FetchUsersByAccountOptions;
  };
}

export interface ActionMeta {
  accountUuid: string;
  options: FetchUsersByAccountOptions;
}

// Reducer
export const reducer = handleActions<State, ListAccountUsersResponse, ActionMeta>(
  {
    [FETCH_USERS_BY_ACCOUNT]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: true,
      loaded: false,
      filter: {
        ...state.filter,
        name: action.meta.options.filter.name,
        status: action.meta.options.filter.status,
        location: action.meta.options.filter.location,
        purpose: action.meta.options.filter.purpose,
      },
    }),
    [FETCH_USERS_BY_ACCOUNT_SUCCESS]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: true,
      error: action.error,
      page: action.payload.page,
      byAccountUuid: {
        ...state.byAccountUuid,
        [action.meta.accountUuid]: action.payload.users,
      },
      data: action.payload,
    }),
    [FETCH_USERS_BY_ACCOUNT_FAIL]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error || true,
    }),
  },
  initialState
);

export default reducer;
