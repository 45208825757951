export const EventNameTypes = {
  form: 'Form Submission',
  interaction: 'Interaction',
  page: 'Page View',
  view: 'View',
};

export const ErrorName = {
  GeneralInvalidError: 'GeneralInvalidError',
  ApiError: 'ApiError',
  ParseError: 'ParseError',
  NetworkError: 'NetworkError',
  FallBackError: 'FallBackError',
  DispatchError: 'DispatchError',
};

export const KnownRollbarParseErrors = ['INVALID_COUNTRY', 'NOT_A_NUMBER', 'TOO_SHORT'];

export default { EventNameTypes };
