import { handleActions } from 'redux-actions';
import queryString from 'query-string';

import { BaseAction, Dispatch } from 'store/types';
import { createRequestAction } from 'store/util';
import config from 'config';
import cc from 'store/util/createReduxConstant';
import { AccessLevel } from 'features/keycards/ducks/types';

export interface State {
  loaded: boolean;
  data: AccessLevel[];
  loading: boolean;
  error: string | null | undefined;
}

export interface AccessLevelsSubset {
  accessLevels: State;
}

// Action Constants
export const FETCH_ACCESS_LEVELS = cc('FETCH_ACCESS_LEVELS');
export const FETCH_ACCESS_LEVELS_SUCCESS = cc('FETCH_ACCESS_LEVELS_SUCCESS');
export const FETCH_ACCESS_LEVELS_FAIL = cc('FETCH_ACCESS_LEVELS_FAIL');

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_ACCESS_LEVELS]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_ACCESS_LEVELS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.payload.result,
      error: null,
    }),

    [FETCH_ACCESS_LEVELS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      data: [],
      error: action.payload.message,
    }),
  },
  initialState
);

// Action Creators
export function fetchAccessLevels(
  partitionIds: Array<string>,
  userUuid: string | null | undefined,
  filterForMembers: boolean
) {
  return (dispatch: Dispatch<BaseAction>) => {
    const queryParams = {
      partition_ids: partitionIds,
      filter_for_members: filterForMembers,
      user_uuid: userUuid,
    };

    const query = queryString.stringify(queryParams, { arrayFormat: 'bracket' });

    const requestAction = createRequestAction({
      endpoint: `${config.airlock.uri}/api/v1/access_levels?${query}`,
      types: [FETCH_ACCESS_LEVELS, FETCH_ACCESS_LEVELS_SUCCESS, FETCH_ACCESS_LEVELS_FAIL],
    });

    return dispatch(requestAction);
  };
}

export function fetchAccountAccessLevels(accountUuid: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const queryParams = {
      account_uuid: accountUuid,
    };

    const query = queryString.stringify(queryParams);

    const requestAction = createRequestAction({
      endpoint: `${config.airlock.uri}/api/v1/access_levels?${query}`,
      types: [FETCH_ACCESS_LEVELS, FETCH_ACCESS_LEVELS_SUCCESS, FETCH_ACCESS_LEVELS_FAIL],
    });

    return dispatch(requestAction);
  };
}

export default reducer;
