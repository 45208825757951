import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';
import isInventoryGroup from '../actions/isInventoryGroup';

import { getActionsPayload } from './actionsSerializer';

export default (
  inventory: InventoryPayloadItem,
  key: string
): InventoryAttribute | null | undefined => {
  const payload = getActionsPayload(inventory);

  if (isInventoryGroup(payload)) {
    return null;
  }

  const value = inventory[key] || '';

  const meta = { payload };
  return {
    value,
    meta,
  };
};
