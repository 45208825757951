import { combineReducers } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { compact, uniqBy } from 'lodash';

import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { ActionWithMetaPayload, ActionWithPayload, BaseAction, Dispatch } from 'store/types';
import { createRequestAction, createRequestReducer } from 'store/util';
import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';
import { RequestState } from 'store/util/createRequestReducer';
import { PersistMetaFields } from 'store/middlewares/persistToLocalStorage';

import { notifyError } from '../toasts/actions';

import { Location } from './types';
import { fetchLocation } from './api';
import { getSpacemanCode } from './utils';

export * from './types';

// Action Constants
export const [
  FETCH_CURRENT_LOCATION,
  FETCH_CURRENT_LOCATION_SUCCESS,
  FETCH_CURRENT_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_CURRENT_LOCATION'));

export const [
  FETCH_HOME_LOCATION,
  FETCH_HOME_LOCATION_SUCCESS,
  FETCH_HOME_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_HOME_LOCATION'));

export const ADD_RECENT_LOCATION = cc('ADD_RECENT_SI_LOCATION');
export const SET_RECENT_LOCATIONS = cc('SET_RECENT_SI_LOCATIONS');

export const [
  SET_CURRENT_LOCATION,
  SET_CURRENT_LOCATION_SUCCESS,
  SET_CURRENT_LOCATION_FAIL,
] = createRequestConstantNames(cc('SET_CURRENT_LOCATION'));

const setLocationAction = createAction(SET_CURRENT_LOCATION);
const setLocationSuccessAction = createAction(SET_CURRENT_LOCATION_SUCCESS);
const setLocationFailureAction = createAction(SET_CURRENT_LOCATION_FAIL);

export const RECENT_LOCATIONS_PATH = 'siLocations.recentLocations';
const SEARCH_LIMIT = 1;

const locationByCodeQuery = `query SearchQuery($code: String!) {
  searchLocations(filters: {query: $code}, pagination: {offset: 0, limit: ${SEARCH_LIMIT}}) {
    data {
      id,
      name,
      localTimeZone,
      defaultLocale,
      paperworkLocale,
      defaultCurrency,
      supportEmail,
      identifiers {
        type,
        value,
      },
      defaultAddress {
        type,
        line1,
        line2,
        locality,
        dependentLocality,
        postalCode,
        countryISO3,
        country,
        latitude,
        longitude
      }
    }
  }
}`;

export const locationByIdQuery = `query GetLocation($id: ID!) {
  location(id: $id, type: SPACEMAN) {
    id,
    name,
    localTimeZone,
    defaultLocale,
    paperworkLocale,
    defaultCurrency,
    supportEmail,
    isMigrated,
    status,
    identifiers {
      type,
      value,
    },
    buildings {
      floors {
        id,
        name,
      }
    },
    defaultAddress {
      type,
      line1,
      line2,
      locality,
      dependentLocality,
      postalCode,
      countryISO3,
      country,
      latitude,
      longitude
    }
  }
}`;

export const addRecentLocation = (
  location: Location
): ActionWithMetaPayload<PersistMetaFields, Location> => ({
  type: ADD_RECENT_LOCATION,
  payload: location,
  meta: {
    persist: true,
    path: RECENT_LOCATIONS_PATH,
  },
});

const handleError = (payload, dispatch) =>
  payload.errors?.forEach(err => dispatch(notifyError(err.message)));

const handleHomeLocationResponse = (payload, dispatch) => {
  handleError(payload, dispatch);
  return payload?.data?.location;
};

export const fetchCurrentLocationByCode = (code: string) => async (
  dispatch: Dispatch<BaseAction>
) => {
  try {
    dispatch(setLocationAction());
    const resp = await fetchLocation(locationByCodeQuery, { code });
    const location = resp?.data?.searchLocations?.data?.find(loc => getSpacemanCode(loc) === code);
    if (location) {
      dispatch(setLocationSuccessAction(location));
    } else {
      handleError(resp, dispatch);
      dispatch(setLocationFailureAction());
    }
  } catch (err) {
    dispatch(notifyError(err.message));
    dispatch(setLocationFailureAction());
  }
};

export const fetchCurrentLocationById = (id: string) => async (dispatch: Dispatch<BaseAction>) => {
  try {
    dispatch(setLocationAction());
    const resp = await fetchLocation(locationByIdQuery, { id });
    const location = resp?.data?.location;
    if (location) {
      dispatch(setLocationSuccessAction(location));
    } else {
      handleError(resp, dispatch);
      dispatch(setLocationFailureAction());
    }
  } catch (err) {
    dispatch(notifyError(err.message));
    dispatch(setLocationFailureAction());
  }
};

export const fetchHomeLocation = (id: string) => async (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.siGateway.uri}`,
    types: [FETCH_HOME_LOCATION, FETCH_HOME_LOCATION_SUCCESS, FETCH_HOME_LOCATION_FAIL],
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'apollographql-client-name': 'spacestation-web',
    },
    body: {
      query: locationByIdQuery,
      variables: {
        id,
      },
    },
    getPayloadFromResponse: json => handleHomeLocationResponse(json, dispatch),
  });
  return dispatch(requestAction);
};

export interface LocationResponse {
  data: {
    location: Location;
  };
}

type CurrentLocationState = {
  loading: boolean;
  result: Location | null;
  error: boolean | null;
};

export const currentLocationInitialState = {
  loading: false,
  result: null,
  error: null,
};

const currentLocationReducer = handleActions<CurrentLocationState, Location>(
  {
    [SET_CURRENT_LOCATION]: (state: CurrentLocationState) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [SET_CURRENT_LOCATION_SUCCESS]: (
      state: CurrentLocationState,
      action: ActionWithPayload<Location | null>
    ) => ({
      ...state,
      loading: false,
      error: false,
      result: action.payload,
    }),
    [SET_CURRENT_LOCATION_FAIL]: (state: CurrentLocationState) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  currentLocationInitialState
);

const homeLocationReducer = createRequestReducer<Location | null, LocationResponse>(
  [FETCH_HOME_LOCATION, FETCH_HOME_LOCATION_SUCCESS, FETCH_HOME_LOCATION_FAIL],
  null
);

const recentLocationsReducer = handleActions<any, any>(
  {
    [ADD_RECENT_LOCATION]: (state, action: ActionWithPayload<Location>) => {
      return compact(uniqBy([...[action.payload, ...state]], 'id')).slice(0, 10);
    },
    [SET_RECENT_LOCATIONS]: (_, action: ActionWithPayload<Array<Location>>) => {
      return compact(uniqBy(action.payload, 'id')).slice(0, 10);
    },
  },
  []
);

/**
 * Will update localStorage `siLocations.recentLocations`.
 */
export const setRecentLocations = (
  locations: Array<Location>
): ActionWithMetaPayload<PersistMetaFields, Array<Location>> => ({
  type: SET_RECENT_LOCATIONS,
  payload: locations,
  meta: {
    persist: true,
    path: RECENT_LOCATIONS_PATH,
  },
});

/**
 * It will attempt to load `siLocations.recentLocations` from localStorage and dispatch setRecentLocations.
 */
export const loadRecentLocations = () => (
  dispatch: Dispatch<ActionWithPayload<Array<Location>>>
) => {
  const saved = localStorage.getItem(RECENT_LOCATIONS_PATH);
  if (!saved) {
    return;
  }
  const payload = JSON.parse(saved);
  dispatch(setRecentLocations(payload));
};

type State = {
  homeLocation: RequestState<Location | null>;
  currentLocation: CurrentLocationState;
  recentLocations: Location[];
};

export interface SiLocationsSubset {
  siLocations: State;
}

export default combineReducers({
  currentLocation: currentLocationReducer,
  homeLocation: homeLocationReducer,
  recentLocations: recentLocationsReducer,
});
