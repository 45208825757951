import { cloneDeep, get, map, reduce } from 'lodash';

import config from 'config';
import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { createRequestAction } from 'store/util';
import { createRequestReducer, RequestState } from 'store/util/createRequestReducer';
import { camelCaseJson, snakeCaseJson } from 'lib/util';
import { PendingReservation } from 'features/paperwork/types';
import cc from 'store/util/createReduxConstant';

import { DiscountsAPIReservation, SerializedOffer, Suggestions } from './types';

// Action Constants
export const FETCH_DISCOUNT_SUGGESTIONS = cc('FETCH_DISCOUNT_SUGGESTIONS');
export const FETCH_DISCOUNT_SUGGESTIONS_SUCCESS = cc('FETCH_DISCOUNT_SUGGESTIONS_SUCCESS');
export const FETCH_DISCOUNT_SUGGESTIONS_FAIL = cc('FETCH_DISCOUNT_SUGGESTIONS_FAIL');

const types: RequestActionTypes = [
  {
    type: FETCH_DISCOUNT_SUGGESTIONS,
    meta: { keepCurrentResult: true }, // we will handle the state in the getPayloadFromResponse function
  },
  FETCH_DISCOUNT_SUGGESTIONS_SUCCESS,
  FETCH_DISCOUNT_SUGGESTIONS_FAIL,
];

type Response = { readonly payload?: {} };

// Initial State
export const initialState = {
  loading: false,
  loaded: false,
  data: {},
  error: null,
  selectedPromotion: null,
};

export const getPayloadFromResponse = (response: Response | null | undefined): any =>
  reduce(
    get(response, 'result.contracts', {}),
    (
      currentState: Response | null | undefined,
      pendingReservations: Array<PendingReservation>,
      contractName: string
    ) => ({
      ...currentState,
      [contractName]: camelCaseJson(pendingReservations),
    }),
    {}
  );

export const getPayloadFromCustomResponse = (
  response: Response | null | undefined,
  previousResponse: Hash<Suggestions> | null | undefined
): Hash<Suggestions> =>
  reduce(
    get(response, 'payload', {}),
    (
      currentState: Hash<Suggestions>,
      contractData: Response | null | undefined,
      contractName: string
    ) => ({
      ...currentState,
      [contractName]: {
        ...currentState[contractName],
        customSuggestions: get(contractData, 'promotionSuggestions', []),
      },
    }),
    cloneDeep(previousResponse || {})
  );

const handlePayload = (
  response: Response | null | undefined,
  previousResponse: Hash<Suggestions> | null | undefined
): Hash<Suggestions> => {
  return get(response, ['meta', 'commitmentLength'])
    ? getPayloadFromCustomResponse(response, previousResponse)
    : get(response, 'payload', {});
};

type GroupedReservations =
  | Hash<Array<PendingReservation>>
  | Hash<Array<DiscountsAPIReservation>>
  | Hash<Array<SerializedOffer>>;

type Reservations =
  | Array<PendingReservation>
  | Array<DiscountsAPIReservation>
  | Array<SerializedOffer>;

export const getContracts = (groupedPendingReservations: GroupedReservations) =>
  reduce(
    groupedPendingReservations,
    (
      currentState: GroupedReservations,
      pendingReservations: Reservations,
      contractName: string
    ) => ({
      ...currentState,
      [contractName]: map(pendingReservations, (item: PendingReservation) => {
        if (item.officeType === 'DynamicInventory') {
          item.officeType = 'Office';
        }
        item = snakeCaseJson(item);
        return item;
      }),
    }),
    {}
  );

export const fetchDiscountSuggestions = (
  groupedPendingReservations: GroupedReservations,
  accountUuid: string,
  commitmentLength: number | null | undefined
) => (dispatch: Dispatch<BaseAction>) => {
  const body = {
    account_uuid: accountUuid,
    contracts: getContracts(groupedPendingReservations),
    kind: 'Variable',
    commitment_length: commitmentLength,
  };

  const requestAction = createRequestAction({
    body,
    getPayloadFromResponse,
    endpoint: `${config.discounts.uri}/api/v1/suggestions/available`,
    method: 'POST',
    types,
    meta: { commitmentLength },
  });

  return dispatch(requestAction);
};

export type State = RequestState<Hash<Suggestions>>;

// Reducer
const reducer = createRequestReducer<Hash<Suggestions>, any>(types, {}, handlePayload);

export default reducer;
