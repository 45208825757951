import { handleActions } from 'redux-actions';

import { State } from 'features/accessReports/s2FeenicsLogin/redux/types';
import {
  FETCH_S2FEENICS_LOGIN_SEARCH,
  FETCH_S2FEENICS_LOGIN_SEARCH_SUCCESS,
  FETCH_S2FEENICS_LOGIN_SEARCH_FAIL,
  FETCH_S2FEENICS_LOGIN_ROLES,
  FETCH_S2FEENICS_LOGIN_ROLES_SUCCESS,
  FETCH_S2FEENICS_LOGIN_ROLES_FAIL,
  FETCH_S2FEENICS_LOGIN_CONTROLLERS,
  FETCH_S2FEENICS_LOGIN_CONTROLLERS_SUCCESS,
  FETCH_S2FEENICS_LOGIN_CONTROLLERS_FAIL,
  GET_S2FEENICS_LOGIN_SELECTED_DATA,
} from 'features/accessReports/s2FeenicsLogin/redux/constants';

const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  controllerList: [],
  rolesList: [],
  searchList: [],
  selectedParams: {},
  totalPages: 1,
  totalElements: 0,
  numberPage: 1,
  pageSize: 20,
  isVisibleTable: false,
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_S2FEENICS_LOGIN_CONTROLLERS]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_S2FEENICS_LOGIN_CONTROLLERS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      controllerList: action.payload.result,
      error: null,
    }),

    [FETCH_S2FEENICS_LOGIN_CONTROLLERS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      controllerList: [],
      error: action.payload.message,
    }),
    [FETCH_S2FEENICS_LOGIN_ROLES]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_S2FEENICS_LOGIN_ROLES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      rolesList: action.payload.content,
      pageSize: action.payload.pageable.pageSize,
      error: null,
    }),

    [FETCH_S2FEENICS_LOGIN_ROLES_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      rolesList: [],
      pageSize: 20,
      error: action.payload.message,
    }),
    [FETCH_S2FEENICS_LOGIN_SEARCH]: state => ({
      ...state,
      loading: true,
      loaded: false,
      isVisibleTable: true,
      numberPage: 1,
    }),

    [FETCH_S2FEENICS_LOGIN_SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      searchList: action.payload.content,
      pageSize: action.payload.pageable.pageSize,
      totalPages: action.payload.totalPages,
      totalElements: action.payload.totalElements,
      numberPage: action.payload.number,
      error: null,
    }),

    [FETCH_S2FEENICS_LOGIN_SEARCH_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      isVisibleTable: false,
      searchList: [],
      pageSize: 20,
      totalPages: 1,
      numberPage: 1,
      totalElements: 0,
      error: action.payload.message,
    }),
    [GET_S2FEENICS_LOGIN_SELECTED_DATA]: (state, action) => ({
      ...state,
      selectedParams: action.payload,
    }),
  },
  initialState
);

export default reducer;
