import { createAction } from 'redux-actions';

import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import cc from 'store/util/createReduxConstant';
import { fetchInventory } from 'store/modules/siInventory/api';
import { InventoryItem as SIInventoryItem } from 'store/modules/siInventory/types';
import { locationIdsForInventory } from 'store/modules/siInventory/utils';
import { Location } from 'store/modules/siLocations/types';
import { fetchLocation } from 'store/modules/siLocations/api';

import { SIFetchInventoryResult, SIInventoryAndLocationPreprocess, InventoryItem } from '../types';
import { serializeSearchResultSI } from '../serializers/inventorySerializer';
import SerializersMapWithItems from '../config/serializersMapWithItems';
import { findLocationQuery, SILocationResponse } from '../config/siQueries';

const getInventoryQuery: string = `query getInventory($id: ID!) {
  inventory(id: $id) {
    id
    name
    productType
    displayProductType
    filterableGate
    startDate
    availability
    capacity
    seatCount
    workUnits
    spatialWorkUnits
    rentableSquareFootage
    squareFootage
    usableSquareFootage
    internalRoomCount
    hasWindow
    openDate
    buildingClass
    buildingTier
    notes
    alias
    archivedDate
    doNotSell
    floorName
    price {
      amount
      currency
      hasDiscountFlag
      discountDetails {
        type
        value
      }
    }
    locationId {
      value
    }
    floorId
    isAvailable
    isOnHold
    isPaperworkPending
    hold {
      id
      isActive
      beginsAt
      endsAt
    }
    currentMoveIn
    currentMoveOut
    nextMoveIn
  }
}`;

export const FETCH_INVENTORY_BY_UUIDS_SI = cc('FETCH_INVENTORY_BY_UUIDS_SI');
export const FETCH_INVENTORY_BY_UUIDS_SUCCESS_SI = cc('FETCH_INVENTORY_BY_UUIDS_SUCCESS_SI');
export const FETCH_INVENTORY_BY_UUIDS_FAIL_SI = cc('FETCH_INVENTORY_BY_UUIDS_FAIL_SI');

const fetchInventoryByUuidsAction = createAction(FETCH_INVENTORY_BY_UUIDS_SI);
const fetchInventoryByUuidsSuccessAction = createAction(FETCH_INVENTORY_BY_UUIDS_SUCCESS_SI);
const fetchInventoryByUuidsFailAction = createAction(FETCH_INVENTORY_BY_UUIDS_FAIL_SI);

const types: RequestActionTypes = [
  FETCH_INVENTORY_BY_UUIDS_SI,
  FETCH_INVENTORY_BY_UUIDS_SUCCESS_SI,
  FETCH_INVENTORY_BY_UUIDS_FAIL_SI,
];

export const initialFetchResult: Array<SIInventoryItem> = [];

type InventoryResponse = {
  data: {
    inventory: SIInventoryItem;
  };
  error;
};

export const fetchInventoryItems = (uuids: string) => async (
  dispatch: Dispatch<BaseAction>
): Promise<SIInventoryAndLocationPreprocess> => {
  dispatch(fetchInventoryByUuidsAction());

  try {
    const inventoryPromises: Array<Promise<InventoryResponse>> = uuids
      .split(',')
      .map(id => fetchInventory(getInventoryQuery, { id }));
    const inventory: Array<SIInventoryItem> = (await Promise.all(inventoryPromises)).map(
      resp => resp.data.inventory
    );
    const packagedInventory: SIFetchInventoryResult = {
      data: inventory,
      pageInfo: {
        offset: 0,
        limit: inventory.length,
        totalCount: inventory.length,
      },
      meta: {
        availableCount: [],
      },
    };

    const locationIds = locationIdsForInventory(inventory);

    const locationPromises: Promise<SILocationResponse>[] = [];
    locationIds
      .map(id => fetchLocation(findLocationQuery, { id }))
      .forEach(promise => locationPromises.push(promise));
    const locations: Array<Location> = (await Promise.all(locationPromises)).map(
      resp => resp.data.location
    );

    const result: SIInventoryAndLocationPreprocess = {
      locations,
      inventory: packagedInventory,
      isSearchGroups: false,
    };

    return dispatch(fetchInventoryByUuidsSuccessAction(result));
  } catch (err: any) {
    return dispatch(fetchInventoryByUuidsFailAction(err));
  }
};

export default createRequestReducer<Array<InventoryItem>, SIInventoryAndLocationPreprocess>(
  types,
  [],
  (res): Array<InventoryItem> =>
    serializeSearchResultSI(res.payload, SerializersMapWithItems).inventory
);
