import { Location, IdentifierType } from './types';

export const getLocationLatLong = (location: Location) => ({
  latitude: location.defaultAddress?.latitude || 0,
  longitude: location.defaultAddress?.longitude || 0,
});

export const getSpacemanId = (location: Location): string => {
  const identifier = location.identifiers?.find(
    identifier => identifier.type === IdentifierType.SPACEMAN
  );
  if (!identifier) {
    throw new Error(`No spaceman identifier found for location ${location.id}`);
  }
  return identifier.value;
};

export const getSpacemanCode = (location: Location): string => {
  const identifier = location.identifiers?.find(
    identifier => identifier.type === IdentifierType.SPACEMAN_CODE
  );
  if (!identifier) {
    throw new Error(`No spaceman code identifier found for location: ${location.id}`);
  }
  return identifier.value;
};
