import { pick } from 'lodash';

import {
  CompanySearchResultItem,
  Filters,
  CompanyMapperKeys,
  AlgoliaCompany,
} from 'store/modules/search/searchService/types';
import config from 'config';

import { Mapper } from './types';

enum Field {
  LOCATIONS = 'location_uuids',
  UUID = 'uuid',
  STATUS = 'status',
}

const COMPANY_FIELDS: Array<CompanyMapperKeys> = [
  'uuid',
  'name',
  'location_uuids',
  'spaces',
  'aliases',
  'status',
  'is_migrated',
  'primary_member',
  'enterprise',
  'short_code',
  'objectID',
  'potential',
  'desks',
  'mimo',
  'system_of_record',
];

interface FilterEntry {
  field: Field;
  values: Array<string>;
}

const filterEntryCreator = (field: Field, values: Array<string>): FilterEntry => ({
  field,
  values,
});

const mapper = (filters: Filters): Array<FilterEntry> => {
  const ret: Array<FilterEntry> = [];

  if (filters.locations) {
    ret.push(filterEntryCreator(Field.LOCATIONS, filters.locations));
  }

  if (filters.ids) {
    ret.push(filterEntryCreator(Field.UUID, filters.ids));
  }

  if (filters.active !== undefined) {
    ret.push(filterEntryCreator(Field.STATUS, [filters.active ? 'active' : 'inactive']));
  }

  return ret;
};

const itemMapper = (algoliaItem: AlgoliaCompany): CompanySearchResultItem =>
  pick(algoliaItem, COMPANY_FIELDS);

const companyMapper: Mapper = {
  index: config.algolia.indices.company,
  mapper,
  itemMapper,
};

export default companyMapper;
