import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_COMPANY_ACCESS_SEARCH,
  FETCH_COMPANY_ACCESS_SUCCESS,
  FETCH_COMPANY_ACCESS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_COMPANY_ACCESS_SEARCH'));

export const [GET_COMPANY_ACCESS_SWIPE_SELECTED_DATA] = createRequestConstantNames(
  createReduxConstant('GET_COMPANY_ACCESS_SWIPE_SELECTED_DATA')
);
