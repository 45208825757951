import { handleActions } from 'redux-actions';

import { ActionWithMeta, ActionWithPayload } from 'store/types';
import {
  OrganizationNameValidationState,
  SetIsOrganizationValidPayload,
  ValidateOrganizationNameMeta,
} from 'store/modules/organizationNameValidation/types';

import {
  VALIDATE_ORGANIZATION_NAME,
  VALIDATE_ORGANIZATION_NAME_SUCCESS,
  VALIDATE_ORGANIZATION_NAME_FAIL,
  SET_IS_ORGANIZATION_VALID,
} from './constants';

const initialState: OrganizationNameValidationState = {
  currentName: '',
  validatedName: '',
  isValid: true,
  isLoading: false,
};

export const reducer = handleActions<OrganizationNameValidationState, any, any>(
  {
    [VALIDATE_ORGANIZATION_NAME]: (
      state: OrganizationNameValidationState,
      action: ActionWithMeta<ValidateOrganizationNameMeta>
    ) => ({
      ...state,
      isLoading: true,
      currentName: action.meta.newName,
    }),
    [VALIDATE_ORGANIZATION_NAME_SUCCESS]: (
      state: OrganizationNameValidationState,
      action: ActionWithMeta<ValidateOrganizationNameMeta>
    ) => ({
      ...state,
      isLoading: false,
      ...(state.currentName === action.meta.newName && {
        isValid: action.meta.status === 404,
        validatedName: action.meta.newName,
      }),
    }),
    [VALIDATE_ORGANIZATION_NAME_FAIL]: (state: OrganizationNameValidationState) => ({
      ...state,
      isLoading: false,
      currentName: state.validatedName,
    }),
    [SET_IS_ORGANIZATION_VALID]: (
      state: OrganizationNameValidationState,
      action: ActionWithPayload<SetIsOrganizationValidPayload>
    ) => ({
      ...state,
      isLoading: false,
      isValid: action.payload.isValid,
      validatedName: action.payload.name,
      currentName: action.payload.name,
    }),
  },
  initialState
);

export default reducer;
