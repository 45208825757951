import React from 'react';
import { compose } from 'redux';

// eslint-disable-next-line import/no-named-default
import { default as RayCopyableTooltip } from 'components-ray/copyableTooltip';
import Cell from 'features/search/resultSets/cell';
import { TooltipPlacement } from 'components-ray/tooltip';

interface Props {
  email: string | null | undefined;
  phoneNumber?: string | null | undefined;
}

const ContactInfoCell: React.FC<Props> = ({ email, phoneNumber = '' }) => {
  return (
    <Cell inactive>
      <RayCopyableTooltip
        placement={TooltipPlacement.TOP_START}
        textToCopy={email ?? ''}
        className="truncate"
        textClassName="hover:underline"
        tooltipClassName="z-[30]"
      />
      {phoneNumber && <p className="text-black">{phoneNumber}</p>}
    </Cell>
  );
};

export default compose()(ContactInfoCell);
