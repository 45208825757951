import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { MemberSearchResultItem, SearchResultItem } from 'store/modules/search/searchService/types';
import { ReduxProps, BaseAction, Dispatch } from 'store/types';
import {
  MemberNotificationSource,
  MemberNotificationTemplate,
} from 'features/communication/memberNotifications/types';
import { openSendMemberNotificationModal } from 'features/communication/memberNotifications/redux';
import { SearchableEntity } from 'features/search/types';
import { addRecentlyViewed } from 'features/search/recentlyViewed/redux';
import { ResultContext, ResultContextType } from 'features/search/context/result';
import { trackAnalyticsFor } from 'lib/analytics/analytics';
import { AnalyticsEventName } from 'lib/analytics/constants';
import { GlobalState } from 'store/modules';

import MemberNotificationActionItem from './item';
import { getMemberNotificationActions } from './selectors';
import { MemberNotificationAction } from './types';
import styles from './index.scss';

const mapStateToProps = (state: GlobalState) => ({
  actions: getMemberNotificationActions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  openSendMemberNotificationModal: (
    member: MemberSearchResultItem,
    template: MemberNotificationTemplate,
    source: MemberNotificationSource
  ) => dispatch(openSendMemberNotificationModal(member, template, source)),
  addRecentlyViewed: (
    entityType: SearchableEntity,
    itemId: string,
    searchResult: SearchResultItem
  ) => dispatch(addRecentlyViewed(entityType, itemId, searchResult)),
});

type OwnProps = {
  className?: string;
  member: MemberSearchResultItem;
  hidden?: boolean;
};

type Props = Readonly<ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & OwnProps>;

export class MemberNotificationActions extends React.PureComponent<Props> {
  handleAction = (
    event: React.MouseEvent,
    action: MemberNotificationAction,
    entityType: SearchableEntity,
    uuid: string,
    data: SearchResultItem
  ) => {
    const { member, openSendMemberNotificationModal, addRecentlyViewed } = this.props;
    const { actionType, title } = action;

    trackAnalyticsFor(AnalyticsEventName.comms_send_notification_clicked, {
      source: 'Search',
      type: title,
    });

    event.preventDefault();

    action.type = actionType;

    openSendMemberNotificationModal(member, action, 'Search');

    addRecentlyViewed(entityType, uuid, data);
  };

  render() {
    const { className, actions, hidden } = this.props;
    return !hidden ? (
      <ResultContext.Consumer>
        {({ entityType, uuid, data }: ResultContextType) => (
          <div className={cn(className, styles.memberNotifications)}>
            {actions.map(action => (
              <MemberNotificationActionItem
                key={`member-notification-${action.id}`}
                action={action}
                onClick={event => this.handleAction(event, action, entityType, uuid, data)}
              />
            ))}
          </div>
        )}
      </ResultContext.Consumer>
    ) : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberNotificationActions);
