export const base64ToHex = (base64: string): string => {
  const raw = window.atob(base64);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : `0${hex}`;
  }
  return result;
};

const format = (str: string): string =>
  str.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5').toLowerCase();

export const base64ToUuid = (base64: string): string => {
  return format(base64ToHex(base64));
};

export const bytesToHex = (bytes: Uint8Array): string => {
  let result = '';
  for (let i = 0; i < bytes.length; i++) {
    const hex = bytes[i].toString(16);
    result += hex.length === 2 ? hex : `0${hex}`;
  }
  return result;
};

export const bytesToUuid = (bytes: Uint8Array): string => {
  return format(bytesToHex(bytes));
};

export const bytesToBase64 = (bytes: Uint8Array): string => {
  return window.btoa(String.fromCharCode(...bytes));
};

export const uuidToBytes = (hex: string): Uint8Array => {
  const noDash = hex.replace(/-/g, '');
  const result = new Uint8Array(16);
  for (let i = 0, count = 0; i < noDash.length; i += 2) {
    result[count++] = parseInt(noDash.substr(i, 2), 16);
  }
  return result;
};
