import { setHours, startOfHour } from 'date-fns';

import { Location } from 'store/modules/locations';
import { createAvatarUrlById } from 'components/avatar/constants';
import { Memberships } from 'features/reservations/types';
import { accountIsOnDemandOnly } from 'features/visitors/components/modals/bookWorkspaceModal/utils/utils';

import {
  GuestVisit,
  CheckedInGuestVisit,
  MemberVisit,
  TourVisit,
  GuestTagValues,
  MemberTagValues,
} from '../types';
import { MEMBER_TAGS, START_OF_DAY, GUEST_TAGS, VisitType, MemberBookingTypes } from '../constants';
import { parseDate, memberLabel, userName } from '../util';

import {
  MulesoftVisitorTour,
  RoomsReservation,
  CheckedInGuestTimeline,
  Registration,
} from './types';

export const calcGuestTags = (
  isIdVerified: boolean,
  hasRegistration: boolean
): Array<GuestTagValues> => {
  const tags: Array<GuestTagValues> = [];

  tags.push(hasRegistration ? GUEST_TAGS.PREREGISTERED_GUEST : GUEST_TAGS.WALK_IN);

  if (isIdVerified) {
    tags.push(GUEST_TAGS.ID_CHECKED);
  }

  return tags;
};

export const checkedInGuest = (
  timeZone: string,
  {
    id,
    registration,
    registrationId,
    phone,
    signedInAt,
    signedOutAt,
    idVerified,
    room,
    firstName,
    lastName,
    email,
    company,
    photo,
    user,
    comment,
    visitorId,
  }: Omit<CheckedInGuestTimeline[0], 'type'>
): CheckedInGuestVisit => ({
  id,
  registrationId,
  tags: calcGuestTags(idVerified ?? false, !!registration),
  type: VisitType.GUEST,
  time: registration?.expectedAt ? parseDate(registration.expectedAt, timeZone) : null,
  checkInTime: parseDate(signedInAt, timeZone),
  checkOutTime: signedOutAt ? parseDate(signedOutAt, timeZone) : null,
  note: registration?.comment ?? (comment || null),
  guestNote: registration?.guestComment ?? '',
  isIdVerified: idVerified ?? false,
  visitor: {
    id: visitorId,
    name: userName(firstName, lastName),
    email,
    imgSrc: photo?.urls?.thumbSm,
    largeImgSrc: photo?.urls?.thumbLg,
    phone: phone.national,
    company: company
      ? {
          name: company,
        }
      : null,
    firstName: firstName ?? '',
    lastName: lastName ?? '',
  },
  host: user && {
    id: user.id,
    name: userName(user.firstName, user.lastName),
    email: user.email,
    phone: user.phone.national,
    company: {
      name: user.company ?? '',
      offices: room ?? '',
    },
  },
});

export const nonCheckedInGuest = (
  timeZone: string,
  {
    id,
    expectedAt,
    firstName,
    lastName,
    email,
    room,
    comment,
    company,
    guestComment,
    ...registration
  }: Registration
): GuestVisit => {
  const visitor = {
    name: userName(firstName, lastName),
    email,
    company: company
      ? {
          name: company,
        }
      : null,
    firstName: firstName ?? '',
    lastName: lastName ?? '',
  };
  const host = registration.host
    ? {
        id: registration.host.id,
        name: registration.host.fullName ?? '',
        email: registration.host.email,
        phone: registration.host.phone.national,
        company: {
          name: registration.host.company ?? '',
          offices: room ?? '',
        },
      }
    : null;
  return {
    id,
    tags: [],
    type: VisitType.GUEST,
    time: parseDate(expectedAt, timeZone),
    note: comment,
    guestNote: guestComment ?? '',
    visitor,
    host,
  };
};

export const tour = (
  timeZone: string,
  {
    tourId,
    status,
    startDatetimeUtc,
    startTimeLocal,
    tourNotes,
    contactName,
    interestedNumberOfDesks,
    contactEmail,
    contactPhone,
    contactAccount,
    assignedHost,
    leadSource,
  }: MulesoftVisitorTour
): TourVisit => ({
  id: tourId,
  type: VisitType.TOUR,
  tags: [{ name: status }],
  time: parseDate(startDatetimeUtc, timeZone),
  startTime: startTimeLocal,
  note: tourNotes,
  number_of_desks: interestedNumberOfDesks,
  visitor: {
    email: contactEmail,
    phone: contactPhone,
    name: contactName,
    company: {
      name: contactAccount,
    },
  },
  host: {
    name: assignedHost,
    leadSource,
  },
});

export const member = (
  date: Date,
  timeZone: string,
  {
    uuid,
    userKind,
    reservableType,
    conferenceRoom,
    privateOffice,
    user,
    start,
    company,
    finish,
    firstTime,
    thirdPartyReservation,
  }: RoomsReservation,
  locations: Array<Location>,
  memberships: Memberships
): MemberVisit => {
  const tags: Array<MemberTagValues> = [];
  const isConferenceRoom = reservableType === MemberBookingTypes.CONFERENCE_ROOM;
  const isPrivateOffice = reservableType === MemberBookingTypes.PRIVATE_OFFICE;

  const location = locations.find(location => location.uuid === user.homeLocationUuid) || {
    name: 'Unknown',
  };
  const isOnDemand = company?.id ? accountIsOnDemandOnly(memberships, company.id) : false;

  let memberVisitType;
  if (!firstTime) {
    memberVisitType = isOnDemand ? VisitType.ON_DEMAND : VisitType.MEMBER;
  } else {
    tags.push(MEMBER_TAGS.FIRST_TIME);
    memberVisitType = isOnDemand ? VisitType.ON_DEMAND_FIRST_TIME : VisitType.MEMBER_FIRST_TIME;
  }

  if (thirdPartyReservation) {
    memberVisitType = VisitType.THIRD_PARTY_BOOKING;
  }

  return {
    id: uuid,
    type: memberVisitType,
    tags,
    time: isConferenceRoom ? parseDate(start, timeZone) : startOfHour(setHours(date, START_OF_DAY)),
    visitor: {
      id: user.id,
      name: user.name,
      email: user.email,
      imgSrc: createAvatarUrlById(user.id),
      phone: user.phone,
      address: thirdPartyReservation
        ? null
        : memberLabel(userKind, location, memberships, isOnDemand),
      company: company
        ? {
            id: company.id,
            name: company.name,
          }
        : null,
      isOnDemand,
      thirdPartyReservation,
    },
    booking: {
      type: reservableType,
      items:
        isConferenceRoom || isPrivateOffice
          ? [
              {
                from: parseDate(start, timeZone),
                to: parseDate(finish, timeZone),
                room: isConferenceRoom
                  ? conferenceRoom.attributes.name
                  : privateOffice.attributes.name,
              },
            ]
          : [],
    },
  };
};
