import { handleActions } from 'redux-actions';
import { concat } from 'lodash';

import { PromotionModel } from 'features/paperwork/types';
import { addDisplayTextToPromotions } from 'lib/discountModalUtil';
import {
  FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION,
  FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION_SUCCESS,
  FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION_FAIL,
  CUSTOM_PROMOTION,
} from 'features/companies/edit/redux/availablePromotions/constants';

export interface State {
  loaded: boolean;
  availablePromotions: Hash<Array<PromotionModel>>;
  error: boolean | null | undefined;
  loading: boolean;
}

export interface AvailablePromotionsSubset {
  availablePromotions: State;
}

// Initial state
export const initialState: State = {
  availablePromotions: {},
  error: null,
  loaded: false,
  loading: false,
};

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION_SUCCESS]: (state, action) => {
      const availablePromotions = concat(
        addDisplayTextToPromotions(action.payload.result, action.meta.currency),
        CUSTOM_PROMOTION
      );
      return {
        ...state,
        availablePromotions: {
          ...state.availablePromotions,
          [action.meta.locationUuid]: availablePromotions,
        },
        loading: false,
        loaded: true,
        error: null,
      };
    },
    [FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
