import config from '../config';

import WeWorkBase from './weworkBase';

type Params = { encryptedUserUuid?: string } | {};

export default class Spaceman extends WeWorkBase {
  constructor() {
    super(config.spaceman.uri);
  }

  addPromotion(params: Params) {
    return this.post('/api/v2/reservations/add_discounts.json', params);
  }

  discounts(locationUUID: string, deskAdds: string, deskDrops: string) {
    return this.get('/api/v2/discounts.json', {
      location_uuid: locationUUID,
      desk_adds: deskAdds,
      desk_drops: deskDrops,
    });
  }

  discardMembershipAgreement(agreementUUID: string) {
    return this.post(`/api/v2/membership_agreements/${agreementUUID}/discard`, {});
  }

  resendMembershipAgreement(agreementUUID: string) {
    return this.post(`/api/v2/membership_agreements/${agreementUUID}/resend`, {});
  }

  createMembershipAgreement(payload: {}) {
    return this.post('/api/v2/membership_agreements', payload);
  }

  createMembershipAgreementAndMoveIn(payload: {}) {
    return this.post('/api/v2/membership_agreements/create_and_move_in', payload);
  }

  createOutOfPolicyApprovalRequest(payload: Params) {
    return this.post('/api/v2/membership_agreements/send_for_approval', payload);
  }

  notifyOfOutOfPolicyApprovalStatus(payload: Params) {
    return this.post('/api/v2/membership_agreements/notify_of_approval_status', payload);
  }

  validateMembershipActions(params: Params) {
    return this.post('/api/v2/membership_agreements/verify', params);
  }

  validateSecurityDepositExemptionForReservation(reservationUuid: string, locationUuid: string) {
    return this.get(`/api/v4/reservations/${reservationUuid}/security_deposit`, {
      location_uuid: locationUuid,
    });
  }
}
