import data from './reducers/data';
import lookup from './reducers/lookup';
import status, { State as StatusState } from './reducers/status';
import { EntityAction } from './types';

interface EntityState {
  // TODO: Type this. Not sure if it's an array or an object or either.
  data: any;
  lookup: any;
  status: StatusState;
}

const EMPTY: { [K in keyof EntityState]: undefined } = {
  data: undefined,
  lookup: undefined,
  status: undefined,
};

interface State {
  [key: string]: EntityState;
}

const initialState: State = {};

export interface EntitySubset {
  entity: State;
}

export default (state: State = initialState, action: EntityAction) => {
  if (!action.meta || !action.meta.entity) {
    return state;
  }

  const {
    entity: { entityName },
  } = action.meta;

  const entityState = state[entityName] ?? EMPTY;

  return {
    ...state,
    [entityName]: {
      data: data(entityState.data, action),
      lookup: lookup(entityState.lookup, action),
      status: status(entityState.status, action),
    },
  };
};
