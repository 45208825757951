import { range } from 'lodash';
import { handleActions } from 'redux-actions';
import { format } from 'date-fns';

import { createRequestAction } from 'store/util';
import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import config from 'config';
import {
  FiltersObject,
  FetchAction,
  FetchError,
  SetCurrentPage,
  SetFiltersObject,
  PromotionState as Promotions,
  FormValues,
  SetSortOrder,
  ProductLimitation,
} from 'features/promotions/types';
import cc from 'store/util/createReduxConstant';
import { dateFormats } from 'lib/constants';

export const FETCH_PROMOTIONS = cc('FETCH_PROMOTIONS');
export const FETCH_PROMOTIONS_SUCCESS = cc('FETCH_PROMOTIONS_SUCCESS');
export const FETCH_PROMOTIONS_FAIL = cc('FETCH_PROMOTIONS_FAIL');
export const CREATE_PROMOTION = cc('CREATE_PROMOTION');
export const CREATE_PROMOTION_SUCCESS = cc('CREATE_PROMOTION_SUCCESS');
export const CREATE_PROMOTION_FAIL = cc('CREATE_PROMOTION_FAIL');

export const ARCHIVE_PROMOTION = cc('ARCHIVE_PROMOTION');
export const ARCHIVE_PROMOTION_SUCCESS = cc('ARCHIVE_PROMOTION_SUCCESS');
export const ARCHIVE_PROMOTION_FAIL = cc('ARCHIVE_PROMOTION_FAIL');

export const UPDATE_CURRENT_PAGE = cc('UPDATE_CURRENT_PAGE');
export const SET_FILTER = cc('SET_FILTER');
export const RESET_FILTER = cc('RESET_FILTER');
export const SET_SORT = cc('SET_SORT');

// Product types stored in Discounts-api
const RESOURCE = 'Resource';
const WE_MEMBERSHIP = 'WeMembership'; // resolve to RESOURCE
const HOT_DESK = 'HotDesk';
const ALL_ACCESS_BASIC = 'All Access Basic plan';

// On Demand product types
const ON_DEMAND_CONFERENCE_ROOM = 'Conference Rooms'; // resolve to ON_DEMAND_PRODUCT_TYPE
const ON_DEMAND_HOT_DESK = 'Workspaces'; // resolve to ON_DEMAND_PRODUCT_TYPE
const ON_DEMAND_PRODUCT_TYPE = 'ProductCatalog';
const ON_DEMAND_BPO = 'OnDemand BPO';

// GlobalAccess product type
export const ALL_ACCESS_PLUS = 'All Access Plus Plan'; // resolve to ON_DEMAND_PRODUCT_TYPE
export const GLOBAL_ACCESS_TRIAL = 'GlobalAccessTrial'; // resolve to ON_DEMAND_PRODUCT_TYPE
export const GLOBAL_ACCESS_SAKS_WORKS = 'GlobalAccess SaksWorks';

export const PRODUCT_TYPES = [
  HOT_DESK,
  config.weMembershipUuid,
  config.conferenceRoomUuid,
  config.workspaceUuid,
  config.onDemandBPOResourceUuid,
  config.allAccessPlusResourceUuid,
  config.globalAccessTrialResourceUuid,
  config.globalAccessSaksWorksResourceUuid,
  config.allAccessBasicResourceUuid,
];

const ALLOWED_PRODUCT_TYPES = [RESOURCE, HOT_DESK, ON_DEMAND_PRODUCT_TYPE];

// Currently supported products from Discounts-api which are Commons memberships
// For now, we bake these product types into every new promotion
export const COMMONS_MEMBERSHIP_PRODUCT_TYPES = [
  {
    product_type: HOT_DESK,
    product_uuid: null,
  },
  {
    product_type: WE_MEMBERSHIP,
    product_uuid: config.weMembershipUuid,
  },
];

export const ON_DEMAND_PRODUCT_TYPES = [
  {
    product_type: ON_DEMAND_CONFERENCE_ROOM,
    product_uuid: config.conferenceRoomUuid,
  },
  {
    product_type: ON_DEMAND_HOT_DESK,
    product_uuid: config.workspaceUuid,
  },
  {
    product_type: ON_DEMAND_BPO,
    product_uuid: config.onDemandBPOResourceUuid,
  },
];

export const GLOBAL_ACCESS_PRODUCT_TYPES = [
  {
    product_type: ALL_ACCESS_PLUS,
    product_uuid: config.allAccessPlusResourceUuid,
  },
  {
    product_type: GLOBAL_ACCESS_TRIAL,
    product_uuid: config.globalAccessTrialResourceUuid,
  },
  {
    product_type: GLOBAL_ACCESS_SAKS_WORKS,
    product_uuid: config.globalAccessSaksWorksResourceUuid,
  },
  {
    product_type: ALL_ACCESS_BASIC,
    product_uuid: config.allAccessBasicResourceUuid,
  },
];

const ON_DEMAND_PRODUCTS_MAP = {
  [config.conferenceRoomUuid]: ON_DEMAND_PRODUCT_TYPE,
  [config.workspaceUuid]: ON_DEMAND_PRODUCT_TYPE,
  [config.onDemandBPOResourceUuid]: ON_DEMAND_PRODUCT_TYPE,
  [config.allAccessPlusResourceUuid]: ON_DEMAND_PRODUCT_TYPE,
  [config.globalAccessTrialResourceUuid]: ON_DEMAND_PRODUCT_TYPE,
  [config.globalAccessSaksWorksResourceUuid]: ON_DEMAND_PRODUCT_TYPE,
  [config.allAccessBasicResourceUuid]: ON_DEMAND_PRODUCT_TYPE,
};

const COMMONS_MEMBERSHIP_PRODUCTS = {
  [HOT_DESK]: null,
  [WE_MEMBERSHIP]: config.weMembershipUuid,
};

export interface State {
  loading: boolean;
  loaded: boolean;
  error?: boolean | null | undefined;
  data: any[];
  currentPage: number;
  pageSize: number;
  filters: {
    location_limitations: any[];
    types: string[];
    query: string;
  };
  sort: {
    key: string;
    order: 'asc' | 'desc';
  };
}

export interface PromotionsSubset {
  promotions: State;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
  currentPage: 0,
  pageSize: 25,
  filters: {
    location_limitations: [],
    types: PRODUCT_TYPES,
    query: '',
  },
  sort: {
    key: '',
    order: 'asc',
  },
};

export const filterAllowedLimitations = (productLimitations: Array<ProductLimitation>): boolean => {
  return (
    productLimitations.filter(
      productLimitation => ALLOWED_PRODUCT_TYPES.indexOf(productLimitation.product_type) === -1
    ).length === 0
  );
};

// type State = typeof initialState;

export const reducer = handleActions<State, any>(
  {
    [FETCH_PROMOTIONS]: (state: Promotions): Promotions => ({
      ...state,
      loading: true,
      error: null,
    }),

    [FETCH_PROMOTIONS_SUCCESS]: (state: Promotions, action: FetchAction): Promotions => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      data: action.payload.result.filter(promotion =>
        filterAllowedLimitations(promotion.product_limitations)
      ),
    }),

    [FETCH_PROMOTIONS_FAIL]: (state: Promotions, action: FetchError): Promotions => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),

    [UPDATE_CURRENT_PAGE]: (state: Promotions, action: SetCurrentPage): Promotions => ({
      ...state,
      currentPage: action.payload,
    }),

    [RESET_FILTER]: (state: Promotions): Promotions => ({
      ...initialState,
      filters: {
        ...initialState.filters,
        query: state.filters.query,
      },
      data: state.data,
      loaded: true,
      loading: false,
    }),

    [SET_FILTER]: (state: Promotions, action: { payload: FiltersObject }): Promotions => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
      currentPage: 0,
    }),

    [SET_SORT]: (state: Promotions, action: { payload: Promotions }): Promotions => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);

export const fetchPromotions = () => (dispatch: Dispatch<BaseAction>) => {
  const types: RequestActionTypes = [
    FETCH_PROMOTIONS,
    FETCH_PROMOTIONS_SUCCESS,
    FETCH_PROMOTIONS_FAIL,
  ];

  const action = createRequestAction({
    endpoint: `${config.discounts.uri}/api/v1/promotions`,
    types,
  });

  return dispatch(action);
};

export const getSchedule = ({
  amount,
  duration,
  isPercent,
}: {
  amount: string;
  duration: string;
  isPercent: boolean;
}): Array<number> => {
  const numericAmount = parseFloat(amount);
  const convertedAmount = isPercent ? numericAmount / 100 : numericAmount;

  return range(Number(duration)).map(() => convertedAmount);
};

export const formatProductAttributes = (productAttributes): Array<ProductLimitation> => {
  return productAttributes.reduce((acc, productLimitation) => {
    if (ON_DEMAND_PRODUCTS_MAP[productLimitation.product_uuid]) {
      acc.push({
        product_type: ON_DEMAND_PRODUCTS_MAP[productLimitation.product_uuid],
        product_uuid: productLimitation.product_uuid,
      });
    }

    // Exception for HOT_DESK that doesn't have a product_uuid
    if (productLimitation.product_type in COMMONS_MEMBERSHIP_PRODUCTS) {
      if (productLimitation.product_type === WE_MEMBERSHIP) {
        acc.push({
          product_type: RESOURCE,
          product_uuid: productLimitation.product_uuid,
        });
      } else {
        acc.push(productLimitation);
      }
    }

    return acc;
  }, []);
};

const isOndemand = productAttributes =>
  productAttributes[0].product_uuid === config.conferenceRoomUuid ||
  productAttributes[0].product_uuid === config.workspaceUuid ||
  productAttributes[0].product_uuid === config.onDemandBPOResourceUuid;

const buildPromotionPayload = (formValues: FormValues) => {
  const productAttributes = formatProductAttributes(formValues.productLimitations);
  const buildingUuidsInput = formValues.buildingUuids?.split(',') ?? [];
  const promotionPayload = {
    uuid: formValues.uuid,
    promotion_attributes: {
      code: formValues.code,
      marketing_name: formValues.marketingName,
      description: formValues.description,
    },
    location_attributes: {
      location_uuids: [...new Set([...buildingUuidsInput])],
    },
    date_attributes: {
      available_from: formValues.availableFrom,
      expires_at: formValues.expiresAt,
    },
    suggestion_attributes: {
      schedule: getSchedule(formValues),
      commitment_length: formValues.commitmentLength,
      currency: formValues.currency,
    },
    product_attributes: productAttributes,
    usage_attributes: {
      max_usage: formValues.maxRedemptions,
    },
  };

  if (isOndemand(productAttributes)) {
    return {
      ...promotionPayload,
      suggestion_attributes: {
        ...promotionPayload.suggestion_attributes,
        perpetuating: true, // Attribute specific to OnDemand
      },
    };
  }

  return promotionPayload;
};

export const createPromotion = (formValues: FormValues) => (dispatch: Dispatch<BaseAction>) => {
  const payload = buildPromotionPayload(formValues);

  const action = createRequestAction({
    method: 'POST',
    endpoint: `${config.discounts.uri}/api/v1/promotions`,
    types: [CREATE_PROMOTION, CREATE_PROMOTION_SUCCESS, CREATE_PROMOTION_FAIL],
    body: payload,
  });

  return dispatch(action);
};

export const archivePromotion = (promotion: FormValues) => (dispatch: Dispatch<BaseAction>) => {
  const payload = {
    ...buildPromotionPayload(promotion),
    archived_at: format(new Date(), dateFormats.iso_date),
  };

  const action = createRequestAction({
    method: 'POST',
    endpoint: `${config.discounts.uri}/api/v1/promotions`,
    types: [ARCHIVE_PROMOTION, ARCHIVE_PROMOTION_SUCCESS, ARCHIVE_PROMOTION_FAIL],
    body: payload,
  });

  return dispatch(action);
};

export const updatePageNumber = (data: number) => ({
  type: UPDATE_CURRENT_PAGE,
  payload: data,
});

export const setFilters = (filters: SetFiltersObject) => {
  return {
    type: SET_FILTER,
    payload: filters,
  };
};

export const resetFilters = () => ({
  type: RESET_FILTER,
});

export const setSort = (sort: SetSortOrder) => ({
  type: SET_SORT,
  payload: sort,
});

export default reducer;
