import { handleActions } from 'redux-actions';

import {
  FETCH_MARKETABLE_AMENITY,
  FETCH_MARKETABLE_AMENITY_FAILURE,
  FETCH_MARKETABLE_AMENITY_SUCCESS,
} from 'features/building/ducks/mlsAmenityConsts';
import { MLSAmenity } from 'features/building/types';
import { ActionWithPayload } from 'store/types';

export type ActionType = ActionWithPayload<{ [key: string]: MLSAmenity }>;

export type MLSAmenityState = {
  loading: boolean;
  mlsAmenities: { [key: string]: MLSAmenity };
};

export const marketableAmenitiesInitialState: MLSAmenityState = {
  loading: false,
  mlsAmenities: {},
};

const marketableAmenityReducer = handleActions<MLSAmenityState, any>(
  {
    [FETCH_MARKETABLE_AMENITY]: (state: MLSAmenityState): MLSAmenityState => ({
      ...state,
      loading: true,
    }),
    [FETCH_MARKETABLE_AMENITY_SUCCESS]: (
      state: MLSAmenityState,
      action: ActionType
    ): MLSAmenityState => ({
      ...state,
      mlsAmenities: action.payload,
      loading: false,
    }),
    [FETCH_MARKETABLE_AMENITY_FAILURE]: (state: MLSAmenityState): MLSAmenityState => ({
      ...state,
      mlsAmenities: {},
      loading: false,
    }),
  },
  marketableAmenitiesInitialState
);

export default marketableAmenityReducer;
