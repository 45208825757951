import cc from 'store/util/createReduxConstant';
import { RequestActionTypes } from 'store/types';
import { createRequestAction } from 'store/util/createRequestAction';
import config from 'config';
import { createRequestReducer } from 'store/util/createRequestReducer';

import {
  PendingAmendmentsErrorResponse,
  PendingAmendments,
  PendingAmendmentsPayload,
} from './types';

export const FETCH_PENDING_AMEMDMENTS = cc('FETCH_PENDING_AMEMDMENTS');
export const FETCH_PENDING_AMEMDMENTS_SUCCESS = cc('FETCH_PENDING_AMEMDMENTS_SUCCESS');
export const FETCH_PENDING_AMEMDMENTS_FAIL = cc('FETCH_PENDING_AMEMDMENTS_FAIL');

const types: RequestActionTypes = [
  FETCH_PENDING_AMEMDMENTS,
  FETCH_PENDING_AMEMDMENTS_SUCCESS,
  FETCH_PENDING_AMEMDMENTS_FAIL,
];

export const initialResultState: PendingAmendments = [];

export const fetchPendingAmendments = (accountUuid: string) =>
  createRequestAction<PendingAmendmentsPayload | PendingAmendmentsErrorResponse>({
    endpoint: `${config.spaceman.uri}/api/v2/membership_agreements/pending_amendments?account_uuid=${accountUuid}`,
    method: 'GET',
    types,
  });

const serializePendingAmendmentsPayload = (payload: PendingAmendmentsPayload) => {
  return payload.result.map(pendingAmendmentPayload => ({
    reservations: pendingAmendmentPayload.reservations,
    amendmentId: pendingAmendmentPayload.ma_uuid,
    amendmentUrl: pendingAmendmentPayload.agreement_url,
  }));
};

const reducer = createRequestReducer<PendingAmendments, PendingAmendmentsPayload>(
  [FETCH_PENDING_AMEMDMENTS, FETCH_PENDING_AMEMDMENTS_SUCCESS, FETCH_PENDING_AMEMDMENTS_FAIL],
  initialResultState,
  res => serializePendingAmendmentsPayload(res?.payload || {})
);

export default reducer;
