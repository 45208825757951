import { useMemo } from 'react';

import config from 'config';

import { SearchableEntity } from '../types';

import { ResultSet, Result } from './types';

const baseAvatarUrl = `${config.mena.uri}/api/avatars`;
export const createAvatarUrlById = (id: string) => `${baseAvatarUrl}/avatar_by_uuid?uuid=${id}`;

type SearchResultPosition = {
  overallPosition: number;
  sectionPosition: number;
  currentResultSetKey: SearchableEntity;
};

export const getSearchResultPosition = (
  resultSets: Array<ResultSet>,
  uuid: string
): Partial<SearchResultPosition> => {
  let overallPosition = -1;
  let sectionPosition = -1;
  const currentResultSet = resultSets.find(({ results }) => {
    return !!results.find(({ uuid: resultUuid }, indexInSection) => {
      overallPosition += 1;
      sectionPosition = indexInSection;
      return resultUuid === uuid;
    });
  });
  if (!currentResultSet) {
    return {};
  }
  return { overallPosition, sectionPosition, currentResultSetKey: currentResultSet.key };
};

export const useSearchResultPosition = (
  resultSets: Array<ResultSet>,
  uuid: string
): Partial<SearchResultPosition> => {
  const resultPosition = useMemo<Partial<SearchResultPosition>>(
    () => getSearchResultPosition(resultSets, uuid),
    [resultSets, uuid]
  );

  return resultPosition;
};

export const getSearchResultAtIndex = (resultSets: Array<ResultSet>, index: number): Result => {
  const results = resultSets.flatMap(set => set.results);
  return results[index];
};
