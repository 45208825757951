import { format } from 'date-fns';
import { debounce } from 'lodash';

import { BaseAction, Dispatch, GetGlobalState } from 'store/types';
import { Location } from 'store/modules/locations';
import { notifyError } from 'store/modules/toasts/actions';
import { Guest, MemberVisit, Visitor } from 'features/visitors/types';
import {
  getGuests,
  getInProgressVisitIdUpdates,
  getSelectedDate,
  getSelectedDateZoned,
} from 'features/visitors/redux/selectors';
import { toSentence, PromiseWithAllSettled, PromiseSettledResults } from 'lib/util';
import { dateFormats } from 'lib/constants';
import rollbar from 'lib/rollbar';
import { getCurrentLocation, getLocations } from 'store/selectors';
import { fetchReservations } from 'features/visitors/lib/roomsAPI';
import { fetchTours } from 'features/visitors/lib/toursAPI';
import { fetchMemberships } from 'features/reservations/lib/membershipAPI';
import { Memberships, MemberOption } from 'features/reservations/types';

import { MemberUserKind, VisitType } from '../constants';
import {
  groupMembersByConferenceRoomBooking,
  groupMembersByDailyDeskBooking,
  groupMembersByPrivateOfficeBooking,
} from '../util';
import { fetchGuests } from '../lib/welkioAPI';
import * as welkioAPI from '../lib/welkioAPI';

import { downloadVisitorsList } from './util';
import * as actions from './actions/actions';
import * as welkioActions from './actions/welkio';
import * as mappings from './serverMapping';

const GLOBAL_ACCESS = 'Global Access';
const THIRD_PARTY_RESERVATION_PROVIDERS = ['UpFlex'];

const visitorReservations = (
  { companies, conferenceRooms, privateOffices, reservations, users },
  memberships
) =>
  Object.values(reservations)
    .map((reservation: any) => {
      const { relationships, attributes } = reservation;
      const { company, user, reservable } = relationships;

      const thirdPartyReservation = THIRD_PARTY_RESERVATION_PROVIDERS.map(tp =>
        tp.toLowerCase()
      ).includes(attributes.sourceSystem?.toLowerCase());

      const reservationUser = users?.[user?.data?.id];
      const privateOffice = privateOffices?.[reservable.data.id];
      const conferenceRoom = conferenceRooms?.[reservable.data.id];
      const isGlobalAccess =
        memberships.flat().find(({ userUuid }) => userUuid === reservationUser?.id)?.product ===
        GLOBAL_ACCESS;

      return {
        uuid: attributes.uuid,
        userKind: reservationUser?.attributes?.kind,
        reservableType: attributes.reservableType,
        conferenceRoom,
        privateOffice,
        user: {
          id: reservationUser?.id,
          name: reservationUser?.attributes?.name,
          email: reservationUser?.attributes?.email,
          phone: reservationUser?.attributes?.phone,
          locationUuid: attributes.locationUuid,
          homeLocationUuid: attributes.userHomeLocationUuid,
        },
        company:
          thirdPartyReservation || !company?.data
            ? null
            : {
                id: companies[company.data.id].id,
                name: companies[company.data.id].attributes.name,
              },
        start: reservation.attributes.start,
        finish: reservation.attributes.finish,
        cancelledAt: '',
        firstTime: reservation.attributes.firstTimeAtLocation,
        isGlobalAccess,
        thirdPartyReservation,
      };
    })
    .filter(
      ({ isGlobalAccess, user }) =>
        isGlobalAccess || (user.id && user.homeLocationUuid !== user.locationUuid)
    );

const fetchAllMemberships = async (memberships: Array<string>): Promise<Memberships[]> => {
  // Using 39 as the batch size to keep the URI under 2000 characters
  const BATCH_SIZE = 39;
  const allMemberships = [] as Array<Promise<Memberships>>;
  let offset = 0;

  while (offset < memberships.length) {
    allMemberships.push(fetchMemberships(memberships.slice(offset, offset + BATCH_SIZE)));
    offset += BATCH_SIZE;
  }
  return Promise.all(allMemberships);
};

const fetchMembers = async (location: Location, date: Date, locations: Array<Location>) => {
  const [, count] = await fetchReservations(location, date, false);
  const [allReservationData] = await fetchReservations(location, date, true, count);

  if (!allReservationData.users) return [];

  const userIds = Object.values(allReservationData.users).reduce(
    (userIds: Set<string>, { attributes, id }) => {
      if (
        attributes.kind === MemberUserKind.MEMBER ||
        attributes.kind === MemberUserKind.ANYWHERE
      ) {
        userIds.add(id);
      }
      return userIds;
    },
    new Set()
  ) as any;

  const memberships = userIds.size ? await fetchAllMemberships(Array.from(userIds.values())) : [];
  const remappedReservations = visitorReservations(allReservationData, memberships);
  const members = remappedReservations.map(reservation =>
    mappings.member(
      date,
      location?.time_zone,
      reservation,
      locations,
      memberships.flat().filter(item => item.userUuid === reservation.user.id)
    )
  );
  const conferenceRooms = groupMembersByConferenceRoomBooking(members);
  const dailyDesks = groupMembersByDailyDeskBooking(members);
  const privateOffices = groupMembersByPrivateOfficeBooking(members);
  const reservationsByUuid = new Map<string, MemberVisit>();
  conferenceRooms.forEach(visit => reservationsByUuid.set(visit.id, visit));
  dailyDesks.forEach(visit => reservationsByUuid.set(visit.id, visit));
  privateOffices.forEach(visit => reservationsByUuid.set(visit.id, visit));
  return Array.from(reservationsByUuid.values());
};

class DateMissMatchError extends Error {
  message: 'Dates do not match';
}

async function execWithDateGuard<T>(
  exec: () => Promise<PromiseSettledResults<T>>,
  getState
): Promise<PromiseSettledResults<T>> {
  const requestDate = getSelectedDate(getState());

  const result = await exec();

  const currentDate = getSelectedDate(getState());
  if (requestDate !== currentDate) {
    throw new DateMissMatchError();
  }
  return result;
}

const _fetchAllVisitors = async (dispatch, getState) => {
  const state = getState();
  const location = getCurrentLocation(state, {})!;
  const dateZoned = getSelectedDateZoned(state, {});
  const locations = getLocations(state);

  dispatch(actions.fetchStart());
  const results = await execWithDateGuard<Visitor>(
    () =>
      (Promise as PromiseWithAllSettled).allSettled<Visitor>([
        fetchGuests(location, dateZoned),
        fetchTours(location, dateZoned),
        fetchMembers(location, dateZoned, locations),
      ]),
    getState
  );

  const visits = results.flatMap(result => (result?.status === 'fulfilled' ? result.value : []));
  const failedVisitNames = [
    { name: 'guests', result: results[0] },
    { name: 'tours', result: results[1] },
    { name: 'members', result: results[2] },
  ]
    .filter(({ result }) => result.status === 'rejected')
    .map(({ name }) => name);

  dispatch(actions.fetchComplete(visits));

  if (failedVisitNames.length) {
    dispatch(
      notifyError(
        `Failed to load ${toSentence(failedVisitNames)} for ${format(
          dateZoned,
          dateFormats.month_with_ordinal_date
        )}`
      )
    );
  }
};

const fetchAllVisitorsDebounced = debounce(_fetchAllVisitors, 300, { leading: true });

export const fetchAllVisitors = () => async (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  try {
    await fetchAllVisitorsDebounced(dispatch, getState);
  } catch (error) {
    if (!(error instanceof DateMissMatchError)) {
      rollbar.error(error.message, error);
    }
  }
};

const shouldUpdate = (id: string, getState: GetGlobalState, shouldIncludeGuest: boolean) => {
  const hasGuest = getGuests(getState()).some(({ id: _id }) => _id === id);
  const isInProgress = getInProgressVisitIdUpdates(getState()).includes(id);
  return !isInProgress && (shouldIncludeGuest ? hasGuest : !hasGuest);
};

const fetchCheckedInGuest = async (location: Location, visitId: string, userId?: string) => {
  const [guest, user] = await Promise.all([
    welkioAPI.fetchCheckedInGuest(visitId),
    userId ? welkioAPI.fetchUser(userId) : Promise.resolve(null),
  ]);

  return guest
    ? mappings.checkedInGuest(location?.time_zone, {
        ...guest,
        user,
        registration: null,
        photo: null,
      })
    : null;
};

const fetchUnCheckedInGuest = async (location: Location, visitId: string) => {
  const guest = await welkioAPI.fetchNonCheckedInGuest(visitId);
  return mappings.nonCheckedInGuest(location.time_zone, guest);
};

export const checkedInVisitCreated = (location: Location, id: string, userId?: string) => async (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  if (shouldUpdate(id, getState, false)) {
    const guest = await fetchCheckedInGuest(location, id, userId);
    if (guest) {
      dispatch(actions.visitorCreated(guest));
    }
  }
};

export const unCheckedInVisitCreated = (location: Location, id: string) => async (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  if (shouldUpdate(id, getState, false)) {
    const guest = await fetchUnCheckedInGuest(location, id);
    dispatch(actions.visitorCreated(guest));
  }
};

export const unCheckedInVisitUpdated = (location: Location, id: string) => async (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  if (shouldUpdate(id, getState, true)) {
    const guest = await fetchUnCheckedInGuest(location, id);
    dispatch(actions.visitorUpdated(guest));
  }
};

export const unCheckedInVisitDeleted = (_: Location, id: string) => async (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  if (shouldUpdate(id, getState, true)) {
    dispatch(actions.visitorDeleted(id));
  }
};

export const filterChange = (key: VisitType | 'search', value: boolean | string) => (
  dispatch: Dispatch<BaseAction>
) => dispatch(actions.filterChange(key, value));

export const sendEmergencyList = (locationId: string, userId: string) => (
  dispatch: Dispatch<BaseAction>
): Promise<void> => dispatch(welkioActions.sendEmergencyList(locationId, userId));

export const exportCheckInVisitorList = (date: Date, locationId: string) => async (
  dispatch: Dispatch<BaseAction>
) => {
  const response = await dispatch(welkioActions.exportCheckInVisitorList(locationId, date));
  const url = response.payload.data.url;
  downloadVisitorsList(url);
};

export const exportSchedulesVisitorsList = (date: Date, locationId: string) => async (
  dispatch: Dispatch<BaseAction>
) => {
  const response = await dispatch(welkioActions.exportScheduledVisitorList(locationId, date));
  const url = response.payload.data.url;
  downloadVisitorsList(url);
};

export const checkInGuest = (location: Location, visitId: string, visitor: Guest) => (
  dispatch: Dispatch<BaseAction>
) => dispatch(welkioActions.checkInGuest(visitId, location, visitor));

export const checkOutGuest = (location: Location, visitId: string, visitor: Guest) => (
  dispatch: Dispatch<BaseAction>
) => dispatch(welkioActions.checkOutGuest(visitId, location, visitor));

export const setIdVerified = (location: Location, visitId: string, visitor: Guest) => (
  dispatch: Dispatch<BaseAction>
) => dispatch(welkioActions.setIdVerified(visitId, location, visitor));

export const printGuestBadge = (location: Location, visitId: string, visitor: Guest) => (
  dispatch: Dispatch<BaseAction>
) => dispatch(welkioActions.printGuestBadge(visitId, location, visitor));

export const resetGuestPhoto = (location: Location, visitId: string, visitor: Guest) => (
  dispatch: Dispatch<BaseAction>
) => dispatch(welkioActions.resetGuestPhoto(visitId, location, visitor));

export const deleteGuestPhoto = (location: Location, visitId: string, visitor: Guest) => (
  dispatch: Dispatch<BaseAction>
) => dispatch(welkioActions.deleteGuestPhoto(visitId, location, visitor));

export const deleteGuest = (visitId: string, visitor: Guest) => (dispatch: Dispatch<BaseAction>) =>
  dispatch(welkioActions.deleteGuest(visitId, visitor));

export const createGuestVisit = (
  firstName: string,
  lastName: string,
  host: MemberOption,
  note: string = '',
  location: Location
) => (dispatch: Dispatch<BaseAction>) =>
  dispatch(welkioActions.createGuestVisit(firstName, lastName, host, note, location));
