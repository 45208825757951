import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';
import isInventoryGroup from '../actions/isInventoryGroup';
import SerializersMap from '../config/serializersMap';

import { getSerializedInventoryItems } from './inventorySerializer';

export default (
  inventoryPayloadItem: InventoryPayloadItem,
  serializerKey: string
): InventoryAttribute | null | undefined => {
  if (!isInventoryGroup(inventoryPayloadItem)) return null;

  const inventoryItems = inventoryPayloadItem[serializerKey];

  if (inventoryItems == null || inventoryItems.length === 0) return null;

  const meta = { items: getSerializedInventoryItems(inventoryItems, SerializersMap) };

  return { value: '', meta };
};
