import React from 'react';

import { KeycardSearchResultItem } from 'store/modules/search/searchService/types';
import { SearchableEntity } from 'features/search/types';
import EmptyCell from 'features/search/resultSets/cell';

import { createAvatarUrlById } from '../utils';
import { Result } from '../types';
import MemberNameCell from '../member/cells/name';
import ContactInfoCell from '../common/cells/contactInfo';
import { memberActionURL } from '../member/utils';

import { KeycardCellProps } from './types';

const KeycardHolderCell = ({ keycard: { user: member, hotstamp } }: KeycardCellProps) => (
  <MemberNameCell member={member} secondRowContent={<div>{hotstamp}</div>} />
);
const HolderContactInfoCell = ({
  keycard: {
    user: { email },
  },
}: KeycardCellProps) => <ContactInfoCell email={email} />;

const createKeycardsCells = (keycard: KeycardSearchResultItem) =>
  [KeycardHolderCell, EmptyCell, HolderContactInfoCell, EmptyCell].map((Cell, index) => (
    <Cell keycard={keycard} key={index} />
  ));

export const mapKeycardResult = (keycard: KeycardSearchResultItem): Result => {
  const member = keycard.user;
  const analyticsData = {
    member_uuid: member.uuid,
    target_entity: member.uuid,
  };
  return {
    uuid: keycard.id,
    avatarUrl: createAvatarUrlById(member.uuid),
    cells: createKeycardsCells(keycard),
    entityType: SearchableEntity.KEYCARDS,
    link: memberActionURL(member),
    analyticsData,
    data: keycard,
  };
};
