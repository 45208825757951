import { createAction, handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

import { ReactSelectOption } from '../types';
import { Location } from '../config/types';

// Action Constants
export const UPDATE_SELECTED_LOCATION = cc('UPDATE_SELECTED_LOCATION');

export type LocationOption = {
  label: string;
  value: Location;
};

export const setSelectedLocation = createAction<
  LocationOption | ReactSelectOption | null | undefined
>(UPDATE_SELECTED_LOCATION);

export const reducer = handleActions<ReactSelectOption | null, ReactSelectOption>(
  { [UPDATE_SELECTED_LOCATION]: (_state, { payload: selectedLocation }) => selectedLocation },
  null
);

export default reducer;
