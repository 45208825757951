import React, { ComponentType } from 'react';

import { FormField } from 'components/formFields/formField';
import { CreateField, FieldHocProps } from 'components/formFields/createField';

export type HocProps = Readonly<
  FieldHocProps & {
    id: string;
    isRequired: boolean;
    label?: string;
    disabled: boolean;
    style: Hash<string>;
  }
>;

export type Props = Readonly<
  HocProps & {
    render: (arg0: {} | null | undefined) => React.ReactNode;
  }
>;

export const CreateFormField: React.FC<any> = (props: Props) => {
  const {
    meta: { touched, error },
    isRequired,
    label,
    disabled,
    style,
  } = props;
  const { render, id, ...fieldProps } = props;

  return (
    <div id={id} style={style}>
      <FormField
        isrequired={label ? isRequired : false}
        label={label}
        displayError={!!error && touched}
        errorMessage={error}
        isDisabled={disabled}
      >
        <CreateField
          render={render} // TODO(grozki): Temporary compatibility fix for Plasma components using required instead of isRequired.
          required={isRequired}
          {...fieldProps}
        />
      </FormField>
    </div>
  );
};

CreateFormField.displayName = 'CreateFormField';

// __TS_TECH_DEBT: Add type notation
export default (InputField: ComponentType<any>): ComponentType<any> => {
  // eslint-disable-next-line react/no-multi-comp
  return class WrappedComponent extends React.Component<any> {
    static displayName = `createFormField(${
      InputField.displayName || InputField.name || 'Component'
    })`;

    renderPropHelper = (props: Record<string, any>): React.ReactElement => (
      <InputField {...props} render={this.props.render} />
    );

    render() {
      return <CreateFormField {...(this.props as any)} render={this.renderPropHelper} />;
    }
  };
};
