import { createAction, handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';

// Action Constants
export const UPDATE_COMMITMENT_TERM = cc('UPDATE_COMMITMENT_TERM');

export const updateCommitmentTerm = createAction<string>(UPDATE_COMMITMENT_TERM);

export const reducer = handleActions<string>(
  {
    [UPDATE_COMMITMENT_TERM]: (_state: string, { payload: commitmentTerm }) => commitmentTerm,
  },
  ''
);

export default reducer;
