import queryString from 'query-string';

import { BaseAction, Dispatch, RequestActionTypes } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { createRequestAction } from 'store/util/createRequestAction';
import config from 'config';
import cc from 'store/util/createReduxConstant';

import { LocationAutocompleteResponse, InventoryErrorResponse } from '../types';
import { LatLng } from '../config/types';

// Action Constants
export const FETCH_PLACE_PREDICTIONS = cc('FETCH_PLACE_PREDICTIONS');
export const FETCH_PLACE_PREDICTIONS_SUCCESS = cc('FETCH_PLACE_PREDICTIONS_SUCCESS');
export const FETCH_PLACE_PREDICTIONS_FAIL = cc('FETCH_PLACE_PREDICTIONS_FAIL');

const types: RequestActionTypes = [
  {
    type: FETCH_PLACE_PREDICTIONS,
    meta: { keepCurrentResult: true },
  },
  FETCH_PLACE_PREDICTIONS_SUCCESS,
  FETCH_PLACE_PREDICTIONS_FAIL,
];

export const fetchPlacePredictions = (
  text: string,
  sessionToken: string,
  locationBias: LatLng | null | undefined
) => (dispatch: Dispatch<BaseAction>) => {
  const queryParams = queryString.stringify({
    text,
    lat: locationBias?.lat,
    lng: locationBias?.lng,
  });
  const requestAction = createRequestAction<
    Array<LocationAutocompleteResponse> | InventoryErrorResponse
  >({
    endpoint: `${config.inventoryQuery.uri}/v1/locationAutocomplete?${queryParams}`,
    errorMessageFromJSON: (json: InventoryErrorResponse) => json.message,
    headers: { 'Session-Token': sessionToken },
    method: 'GET',
    types,
  });

  return dispatch(requestAction);
};

const initialResult: Array<LocationAutocompleteResponse> = [];
// TODO - createRequestReducer assumes that the payload will be an object. The location autocomplete
//        endpoint returns an array of objects (there is no object, just the array).
export default createRequestReducer<Array<LocationAutocompleteResponse>, any>(types, initialResult);
