import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';

export default (inventory: InventoryPayloadItem): InventoryAttribute | null | undefined => {
  const uuid = inventory.uuid;
  const locationUuid = inventory.location?.uuid;

  if (!locationUuid) return null;

  return {
    value: '',
    meta: { uuid, locationUuid },
  };
};
