import isEstimatedData from '../actions/isEstimatedData';
import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';
import isInventoryGroup from '../actions/isInventoryGroup';

export default (
  inventory: InventoryPayloadItem,
  key: string
): InventoryAttribute | null | undefined => {
  const availableDate = inventory[key];
  const fate = inventory.filterableGate;
  const gate = fate && (fate.length === 1 ? `Gate ${fate}` : `${fate}`);
  const isAvailable = inventory.available;
  const isGroup = isInventoryGroup(inventory);
  const showAvailableTodayText = true;

  if (availableDate == null) {
    return null;
  }

  return {
    value: availableDate,
    meta: {
      availableDate,
      estimate: isEstimatedData(inventory),
      gate,
      isAvailable,
      isGroup,
      showAvailableTodayText,
    },
  };
};
