import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { createRequestAction, createRequestReducer } from 'store/util';
import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';
import { BaseAction, Dispatch } from 'store/types';
import { Location } from 'store/modules/siLocations';

export const LocationsQuery = `query GetLocations($ids: [ID], $pagination: PaginationInput) {
  locations(ids: $ids, pagination: $pagination) {
    data {
      id
      name
      status
      buildings {
        floors {
          id
          status
        }
      }
    }
    pageInfo {
      totalCount
      limit
    }
  }
}`;

const [
  FETCH_SI_LOCATIONS,
  FETCH_SI_LOCATIONS_SUCCESS,
  FETCH_SI_LOCATIONS_FAILURE,
] = createRequestConstantNames(cc('FETCH_SI_LOCATIONS'));

export const fetchSILocations = (ids: string[]) => async (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.siGateway.uri}`,
    types: [FETCH_SI_LOCATIONS, FETCH_SI_LOCATIONS_SUCCESS, FETCH_SI_LOCATIONS_FAILURE],
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'apollographql-client-name': 'spacestation-web',
    },
    body: {
      query: LocationsQuery,
      variables: { ids, pagination: { limit: 100, offset: 0 } },
    },
  });
  return dispatch(requestAction);
};

export interface Payload {
  data: {
    locations: {
      data: Location[];
    };
  };
}

const reducer = createRequestReducer<Location[], Payload>(
  [FETCH_SI_LOCATIONS, FETCH_SI_LOCATIONS_SUCCESS, FETCH_SI_LOCATIONS_FAILURE],
  [],
  res => {
    return res?.payload?.data?.locations?.data || [];
  }
);

export default reducer;
