import cc from 'store/util/createReduxConstant';

export const FETCH_MOVES_BY_LOCATION = cc('FETCH_MOVES_BY_LOCATION');
export const FETCH_MOVES_BY_LOCATION_SUCCESS = cc('FETCH_MOVES_BY_LOCATION_SUCCESS');
export const FETCH_MOVES_BY_LOCATION_FAIL = cc('FETCH_MOVES_BY_LOCATION_FAIL');

export const UPDATE_MOVE = cc('UPDATE_MOVE');
export const UPDATE_MOVE_SUCCESS = cc('UPDATE_MOVE_SUCCESS');
export const UPDATE_MOVE_FAIL = cc('UPDATE_MOVE_FAIL');

export const UPDATE_RESERVABLE_MIMO = cc('UPDATE_RESERVABLE_MIMO');
