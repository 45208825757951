import { handleActions } from 'redux-actions';

import { State } from 'features/accessReports/companyAccess/redux/types';
import {
  FETCH_COMPANY_ACCESS_SEARCH,
  FETCH_COMPANY_ACCESS_SUCCESS,
  FETCH_COMPANY_ACCESS_FAIL,
  GET_COMPANY_ACCESS_SWIPE_SELECTED_DATA,
} from 'features/accessReports/companyAccess/redux/constants';

const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  searchList: [],
  selectedParams: {},
  totalPages: 1,
  totalElements: 0,
  numberPage: 1,
  pageSize: 20,
  isVisibleTable: false,
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_COMPANY_ACCESS_SEARCH]: state => ({
      ...state,
      loading: true,
      loaded: false,
      isVisibleTable: true,
      numberPage: 1,
    }),

    [FETCH_COMPANY_ACCESS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      searchList: action.payload.content,
      pageSize: action.payload.pageable.pageSize,
      totalPages: action.payload.totalPages,
      totalElements: action.payload.totalElements,
      numberPage: action.payload.number,
      error: null,
    }),

    [FETCH_COMPANY_ACCESS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      isVisibleTable: false,
      searchList: [],
      data: [],
      totalPages: 1,
      numberPage: 1,
      totalElements: 0,
      pageSize: 20,
      error: action.payload.message,
    }),
    [GET_COMPANY_ACCESS_SWIPE_SELECTED_DATA]: (state, action) => ({
      ...state,
      selectedParams: action.payload,
    }),
  },
  initialState
);

export default reducer;
