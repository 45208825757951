import React from 'react';
import { SemanticCOLORS, Tag } from '@wework/dieter-ui';
// eslint-disable-next-line import/no-named-default
import { Tag as RayTag } from '@wework/ray2';

import Tooltip from 'components-ray/tooltip';

import style from './visitorsTags.scss';

export type TagItem = {
  name: string;
  color?: SemanticCOLORS;
  hoverContent?: string;
};

const VisitorsTags = ({ tags = [] }: { tags: Array<TagItem> }) => {
  const elements = tags.map(({ name, color, hoverContent }) => {
    const key = `${name}${color ? `-${color}` : ''}`;

    return !hoverContent ? (
      <Tag className={style.tag} color={color} key={key}>
        {name}
      </Tag>
    ) : (
      <Tooltip tooltip={hoverContent}>
        <div>
          <RayTag theme="gray" key={key}>
            {name}
          </RayTag>
        </div>
      </Tooltip>
    );
  });

  return <Tag.Group>{elements}</Tag.Group>;
};

export default VisitorsTags;
