import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';
import isInventoryGroup from '../actions/isInventoryGroup';

export const floorPlanSerializer = (
  inventoryPayloadItem: InventoryPayloadItem
): InventoryAttribute | null | undefined => {
  const floorUuid = inventoryPayloadItem?.stargateFloorUuid;
  const locationUuid = inventoryPayloadItem?.location?.uuid;
  const physicalSpaceUuid = inventoryPayloadItem?.physicalSpaceUuid;
  const reservableUuid = inventoryPayloadItem?.uuid;
  const type = inventoryPayloadItem?.type;
  const name =
    isInventoryGroup(inventoryPayloadItem) && !!inventoryPayloadItem?.items
      ? inventoryPayloadItem.items.map(item => item?.name).join(',') // this is a string to better find WWLO
      : inventoryPayloadItem?.name;

  if (!floorUuid || !locationUuid) {
    return null;
  }

  return {
    value: String(floorUuid),
    meta: { floorUuid, locationUuid, name, physicalSpaceUuid, reservableUuid, type },
  };
};

export default floorPlanSerializer;
