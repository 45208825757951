import {
  fetchNotificationsAction,
  fetchNotificationsSuccessAction,
  fetchNotificationsFailureAction,
  markNotificationAsReadAction,
} from 'features/notifications/ducks/notifications';
import { notifyAPI } from 'lib/api';
import { BaseAction, Dispatch } from 'store/types';

interface FetchNotificationsProp {
  page?: number;
}

export const fetchNotifications = ({ page = 1 }: FetchNotificationsProp) => async (
  dispatch: Dispatch<BaseAction>
): Promise<void> => {
  await dispatch(fetchNotificationsAction());
  try {
    const notifications = await notifyAPI.getNotifications({ page });
    dispatch(fetchNotificationsSuccessAction(notifications, page));
  } catch (error) {
    dispatch(fetchNotificationsFailureAction(error));
  }
};

export const markAllNotificationsAsRead = () => async (
  dispatch: Dispatch<BaseAction>
): Promise<void> => {
  try {
    await notifyAPI.markAllNotificationsAsRead();
    // always re-fetch notifications after marking them as read in order to update the read_at attributes
    await dispatch(fetchNotifications({ page: 1 }));
  } catch {
    // ignore if unable to mark notifications as read, client will have to retry
  }
};

export const markNotificationAsRead = (notificationUuid: string) => async (
  dispatch: Dispatch<BaseAction>
) => {
  try {
    const updatedNotification = await notifyAPI.markNotificationAsRead(notificationUuid);
    dispatch(markNotificationAsReadAction(updatedNotification));
  } catch {
    // ignore if unable to mark notification as read, client will have to retry
  }
};
