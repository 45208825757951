import { handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';
import { AccountUserGroups, UserGroupState } from 'store/modules/userGroups/types';
import {
  FETCH_USER_GROUPS,
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_FAIL,
  CREATE_USER_GROUP,
  CREATE_USER_GROUP_SUCCESS,
  CREATE_USER_GROUP_FAIL,
} from 'store/modules/userGroups/constants';

const initialUserGroupState: UserGroupState = {
  userGroups: undefined,
  userGroupsLoading: false,
};

const userGroupsReducer = handleActions<UserGroupState, AccountUserGroups>(
  {
    [FETCH_USER_GROUPS]: (state: UserGroupState) => ({
      ...state,
      userGroupsLoading: true,
    }),
    [FETCH_USER_GROUPS_SUCCESS]: (
      state: UserGroupState,
      action: ActionWithPayload<AccountUserGroups>
    ) => ({
      ...state,
      userGroups: action.payload || [],
      userGroupsLoading: false,
    }),
    [FETCH_USER_GROUPS_FAIL]: (state: UserGroupState) => ({
      ...state,
      userGroupsLoading: false,
    }),
    [CREATE_USER_GROUP]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: true,
    }),
    [CREATE_USER_GROUP_SUCCESS]: (
      state: UserGroupState,
      action: ActionWithPayload<AccountUserGroups>
    ) => ({
      ...state,
      organization: action.payload || [],
      organizationLoading: false,
    }),
    [CREATE_USER_GROUP_FAIL]: (state: UserGroupState) => ({
      ...state,
      organizationLoading: false,
    }),
  },
  initialUserGroupState
);

export default userGroupsReducer;
