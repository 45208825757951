import React, { useContext, useCallback } from 'react';

import { trackAnalyticsFor } from 'lib/analytics/analytics';
import { AnalyticsEventName } from 'lib/analytics/constants';

import { ContextData, AnalyticsEvent, AnalyticsConsumerProps, AnalyticsKey } from './types';

import { AnalyticsContext, buildEvent } from './index';

export type SendEventFunction = (
  action: string, // (click, hover, etc)
  eventData?: ContextData, // data that is specific to the event that has occurred;
  eventKey?: string // controls label event attribute
) => void;

export type SendEventProp = {
  sendEvent: SendEventFunction;
};

const getEventLabel = (analyticsKey: AnalyticsKey, action: string): string | undefined => {
  if (typeof analyticsKey === 'string') {
    return `${analyticsKey}_${action}`;
  }

  return analyticsKey[action];
};

export function useSendEvent(
  analyticsKey?: AnalyticsKey, // e.g. sst_check_in_button
  analyticsData: ContextData = {} // data that is passed at the element level;
): SendEventFunction {
  const contextHierarchy = useContext(AnalyticsContext);

  const sendEvent = useCallback<SendEventFunction>(
    (action, eventData = {}, eventKey) => {
      // append the context data of the element on which analytics is performed
      const analyticsLabel = analyticsKey && getEventLabel(analyticsKey, action);
      const eventLabel = eventKey ?? analyticsLabel;
      const elementContext: Partial<AnalyticsEvent> = {
        action,
        label: eventLabel,
      };

      // append the newly created context in order to get a full hierarchy, from the analytics root to the element on which interaction occurred
      const elementContexts = [...contextHierarchy, analyticsData, elementContext, eventData];

      const eventMetadata = buildEvent(elementContexts);

      trackAnalyticsFor(AnalyticsEventName.legacy_interaction, eventMetadata);
    },
    [analyticsKey, analyticsData, contextHierarchy]
  );

  return sendEvent;
}

export const withSendEvent = <OwnProps extends {}>({
  analyticsKey,
  analyticsData = {},
}: AnalyticsConsumerProps) => (Component: React.ComponentType<any>) => {
  const WrappedComponent = React.forwardRef(function WrappedComponent(props: OwnProps, ref) {
    const sendEvent = useSendEvent(analyticsKey, analyticsData);

    return <Component {...props} sendEvent={sendEvent} ref={ref} />;
  });
  WrappedComponent.displayName = `withSendEvent(${Component.displayName})`;

  return WrappedComponent as React.ComponentType<OwnProps>;
};
