import { handleActions, ReduxCompatibleReducer } from 'redux-actions';

import { TOAST } from './constants';
import { Toast, ToastAction } from './types';

export interface State {
  lastToast: Toast | null;
}

export interface ToastsSubset {
  toasts: State;
}

const initialState: State = {
  lastToast: null,
};

export const reducer: ReduxCompatibleReducer<State, ToastAction> = handleActions<State, any>(
  {
    [TOAST]: (state, { payload }) => ({
      ...state,
      lastToast: payload,
    }),
  },
  initialState
);

export default reducer;
