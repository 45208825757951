import Pusher, { Channel } from 'pusher-js';
import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { PusherEvent } from 'features/notifications/pusher/constants';

class NotifyPusherClient {
  pusher: Pusher;
  channel: Channel;
  channelName: string;

  constructor(channelName: string, accessToken: string) {
    this.channelName = channelName;
    this.pusher = new Pusher(config.notifyService.pusherKey, {
      cluster: 'mt1',
      authEndpoint: `${config.notifyService.uri}/api/v2/pusher/auth`,
      auth: {
        params: {},
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'x-request-id': uuidv4(),
        },
      },
    });
  }

  subscribe() {
    this.channel = this.pusher.subscribe(this.channelName);
  }

  unsubscribe() {
    this.pusher.unsubscribe(this.channelName);
  }

  bind(eventType: PusherEvent, handler) {
    this.channel.bind(eventType, event => handler(event));
  }

  unbind(eventType: PusherEvent) {
    this.channel && this.channel.unbind(eventType);
  }
}

export default NotifyPusherClient;
