import cc from 'store/util/createReduxConstant';

export const FETCH_EMAILS_BY_MOVE_IDS = cc('FETCH_EMAILS_BY_MOVE_IDS');
export const FETCH_EMAILS_BY_MOVE_IDS_SUCCESS = cc('FETCH_EMAILS_BY_MOVE_IDS_SUCCESS');
export const FETCH_EMAILS_BY_MOVE_IDS_FAIL = cc('FETCH_EMAILS_BY_MOVE_IDS_FAIL');

export const SEND_EMAIL = cc('SEND_EMAIL');
export const SEND_EMAIL_SUCCESS = cc('SEND_EMAIL_SUCCESS');
export const SEND_EMAIL_FAIL = cc('SEND_EMAIL_FAIL');

export const FLAG_MANUALLY_SENT_EMAIL = cc('FLAG_MANUALLY_SENT_EMAIL');
export const FLAG_MANUALLY_SENT_EMAIL_SUCCESS = cc('FLAG_MANUALLY_SENT_EMAIL_SUCCESS');
export const FLAG_MANUALLY_SENT_EMAIL_FAIL = cc('FLAG_MANUALLY_SENT_EMAIL_FAIL');

export const FLAG_MOVE_MANUAL_MESSAGE = cc('FLAG_MOVE_MANUAL_MESSAGE');
