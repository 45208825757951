import { Dispatch, AnyAction } from 'redux';

import { ActionWithMeta } from 'store/types';
import { notifyError, notifySuccess } from 'store/modules/toasts/actions';
import { ToastType } from 'store/modules/toasts/constants';

type Next = Dispatch<AnyAction>;
type Action = ActionWithMeta<{
  notification: {
    message: string;
    type: ToastType;
  };
}> & {
  success: boolean;
};

export default () => (next: Next) => (action: Action) => {
  const { error, meta } = action;
  const notification = meta && meta.notification;
  const returnValue = next(action);

  if (notification && notification.message) {
    const notificationType = notification.type;

    if (error || notificationType === ToastType.ERROR) {
      next(notifyError(notification.message));
    } else if (notificationType === ToastType.SUCCESS) {
      next(notifySuccess(notification.message));
    }
  }

  return returnValue;
};
