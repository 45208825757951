import { handleActions, Action } from 'redux-actions';

import { BaseState } from 'store/types';
import {
  FETCH_PXWE_BUILDINGS,
  FETCH_PXWE_BUILDINGS_SUCCESS,
  FETCH_PXWE_BUILDINGS_FAILURE,
} from 'features/paperwork/ducks/pxwe/constants';

// Types
export type PxweBuilding = {
  id: string;
  type: string;
  attributes: {
    name: string;
    id: string;
  };
};

export type PxweBuildings = Array<PxweBuilding>;

interface State extends BaseState {
  data: PxweBuildings;
  translated_keys: string | null;
}

export interface PxweBuildingsSubset {
  pxweBuildings: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  data: [],
  translated_keys: null,
  error: null,
};

interface Payload {
  data: PxweBuildings;
  translated_keys: string;
}

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_PXWE_BUILDINGS]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_PXWE_BUILDINGS_SUCCESS]: (state: State, action: Action<Payload>): State => ({
      ...state,
      data: action.payload.data,
      translated_keys: action.payload.translated_keys,
      loading: false,
      loaded: true,
      error: null,
    }),
    [FETCH_PXWE_BUILDINGS_FAILURE]: (state: State, action: Action<never>): State => ({
      ...state,
      loading: false,
      loaded: false,
      data: [],
      translated_keys: null,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
