import { handleActions } from 'redux-actions';

import {
  GET_DOWNSIZE_INFO_BY_ACCOUNT,
  GET_DOWNSIZE_INFO_BY_ACCOUNT_SUCCESS,
  GET_DOWNSIZE_INFO_BY_ACCOUNT_FAIL,
} from 'features/companies/redux/downsizeInfo/constants';
import { ErrorContainer } from 'store/errors';

import { MembershipDownsizeInfo } from './types';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: ErrorContainer | null | undefined;
  downsizeInfo: Array<MembershipDownsizeInfo>;
}

export interface DownsizeInfoSubset {
  downsizeInfo: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  downsizeInfo: [],
  error: null,
};

export const reducer = handleActions<State, any>(
  {
    [GET_DOWNSIZE_INFO_BY_ACCOUNT]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),

    [GET_DOWNSIZE_INFO_BY_ACCOUNT_SUCCESS]: (
      state: State,
      action: {
        payload: {
          result: Array<MembershipDownsizeInfo>;
          meta: { success: boolean; version: string; httpStatus: number };
        };
      }
    ): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      downsizeInfo: action.payload.result,
    }),

    [GET_DOWNSIZE_INFO_BY_ACCOUNT_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: {
        status: action.payload.status,
        message: action.payload.message,
        type: action.payload.type,
        url: action.payload.response?.url,
      },
    }),
  },
  initialState
);

export default reducer;
