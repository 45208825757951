import { handleActions } from 'redux-actions';

import config from 'config';
import cc from 'store/util/createReduxConstant';
import { BaseAction, ActionWithPayload } from 'store/types';
import { createRequestAction } from 'store/util';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  DISCARD_CONTRACT,
  DISCARD_CONTRACT_SUCCESS,
  DISCARD_CONTRACT_FAIL,
] = createRequestConstantNames(cc('DISCARD_CONTRACT'));

export const [
  RESEND_CONTRACT,
  RESEND_CONTRACT_SUCCESS,
  RESEND_CONTRACT_FAIL,
] = createRequestConstantNames(cc('RESEND_CONTRACT'));

export const [
  FETCH_QINIU_TOKEN,
  FETCH_QINIU_TOKEN_SUCCESS,
  FETCH_QINIU_TOKEN_FAIL,
] = createRequestConstantNames(cc('FETCH_QINIU_TOKEN'));

export const [
  DELETE_QINIU_TOKEN,
  DELETE_QINIU_TOKEN_SUCCESS,
  DELETE_QINIU_TOKEN_FAIL,
] = createRequestConstantNames(cc('DELETE_QINIU_TOKEN'));

export interface State {
  qiniuToken: string | null | undefined;
  uploadKey: string | null | undefined;
  publicUrl: string | null | undefined;
  error: boolean | null | undefined;
}

const initialState: State = {
  qiniuToken: null,
  uploadKey: null,
  publicUrl: null,
  error: false,
};

export const reducer = handleActions<State, any>(
  {
    [DISCARD_CONTRACT]: (state: State): State => ({
      ...state,
      error: false,
    }),

    [DISCARD_CONTRACT_SUCCESS]: (state: State): State => ({
      ...state,
      error: false,
    }),

    [DISCARD_CONTRACT_FAIL]: (state: State, action: BaseAction): State => ({
      ...state,
      error: action.error,
    }),

    [RESEND_CONTRACT_FAIL]: (state: State, action: BaseAction): State => ({
      ...state,
      error: action.error,
    }),

    [FETCH_QINIU_TOKEN_SUCCESS]: (
      state: State,
      action: ActionWithPayload<{
        qiniu: {
          token: string;
          key: string;
          public_url: string;
        };
      }>
    ): State => ({
      ...state,
      qiniuToken: action.payload.qiniu.token,
      uploadKey: action.payload.qiniu.key,
      publicUrl: action.payload.qiniu.public_url,
    }),

    [FETCH_QINIU_TOKEN_FAIL]: (state: State, action: BaseAction): State => ({
      ...state,
      error: action.error,
    }),

    [DELETE_QINIU_TOKEN_FAIL]: (state: State, action: BaseAction): State => ({
      ...state,
      error: action.error,
    }),
  },
  initialState
);

// Action Creators

export const fetchTokenForQiniu = (fileName: string) =>
  createRequestAction({
    method: 'GET',
    endpoint: `${config.spacemoney.uri}/api/v1/agreement_uploads/signed_upload_url_token.json?file_name=${fileName}`,
    types: [FETCH_QINIU_TOKEN, FETCH_QINIU_TOKEN_SUCCESS, FETCH_QINIU_TOKEN_FAIL],
  });

export const deleteQiniuFile = (fileName: string) =>
  createRequestAction({
    method: 'POST',
    endpoint: `${config.spacemoney.uri}/api/v1/agreement_uploads/delete_qiniu_file?key=${fileName}`,
    types: [DELETE_QINIU_TOKEN, DELETE_QINIU_TOKEN_SUCCESS, DELETE_QINIU_TOKEN_FAIL],
  });

export default reducer;
