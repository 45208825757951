import React, { useCallback } from 'react';
import { GridRow } from '@wework/dieter-ui';
import { compose } from 'redux';
import cn from 'classnames';

import { Menu } from 'components-dieter/base/menu';
// eslint-disable-next-line import/no-named-default
import { default as RayTab } from 'components-ray/tab';

import { TabDefinition } from '../types';

import FilterChip from './filterChip';
import styles from './searchFilters.scss';

type Props = {
  selectedIndex: number;
  filters: Hash<boolean>;
  resultCounts: Hash<number>;
  onSelect: (index: number) => void;
  onFilterChange: (filterName: string, value: boolean) => void;
  tabs: Array<TabDefinition>;
};

type TabProps = {
  tab: TabDefinition;
  resultCount: number | undefined;
  index: number;
} & Pick<Props, 'onSelect' | 'selectedIndex'>;

const Tab: React.FC<TabProps> = ({ tab, resultCount, index, selectedIndex, onSelect }) => {
  const onClick = useCallback(() => onSelect(index), [onSelect, index]);
  const resultNum = resultCount ? ` (${resultCount})` : '';
  return (
    <RayTab
      active={selectedIndex === index}
      className="max-w-fit px-2"
      label={`${tab.title}${resultNum}`}
      onClick={onClick}
      role="tab"
      aria-selected={selectedIndex === index ? 'true' : 'false'}
    />
  );
};

function chooseValue(...args: Array<boolean | null | undefined>): boolean {
  return args.find(arg => typeof arg !== 'undefined') ?? false;
}

const SearchFilters = ({
  onSelect,
  selectedIndex,
  filters,
  onFilterChange,
  tabs,
  resultCounts,
}: Props) => (
  <div className={cn(styles.searchFilters, '!py-0 !mt-sm')}>
    <Menu className={cn('!mx-0 !mb-0')} pointing={false} secondary>
      {tabs.map((tab, index) => (
        <Tab
          tab={tab}
          index={index}
          onSelect={onSelect}
          selectedIndex={selectedIndex}
          resultCount={resultCounts[tab.key]}
          key={`tab-${tab.key}`}
        />
      ))}
      <div className="border-b border-gray-70 w-full" />
    </Menu>
    <GridRow className="!my-xs">
      {tabs[selectedIndex].options.map(option => (
        <FilterChip
          option={option}
          onFilterChange={onFilterChange}
          checked={chooseValue(option.overrideValue, filters[option.id], option.defaultValue)}
          key={option.id}
          disabled={option.overrideValue !== undefined}
        />
      ))}
    </GridRow>
  </div>
);

export default compose()(React.memo<Props>(SearchFilters));
