import { handleActions } from 'redux-actions';

import {
  GET_COMPANY_FROM_MENA,
  GET_COMPANY_FROM_MENA_SUCCESS,
  GET_COMPANY_FROM_MENA_FAIL,
} from 'features/companies/redux/menaCompanies/constants';
import { ErrorContainer } from 'store/errors';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: ErrorContainer | null | undefined;
  byUuid: {};
}

export interface MenaCompaniesSubset {
  menaCompanies: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byUuid: {},
  error: null,
};

export const reducer = handleActions<State, any>(
  {
    [GET_COMPANY_FROM_MENA]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [GET_COMPANY_FROM_MENA_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byUuid: {
        ...state.byUuid,
        [action.payload.result.uuid]: action.payload.result,
      },
    }),
    [GET_COMPANY_FROM_MENA_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: {
        status: action.payload.status,
        message: action.payload.message,
        type: action.payload.type,
        url: action.payload.response?.url,
      },
    }),
  },
  initialState
);

export default reducer;
