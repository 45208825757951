import React from 'react';
import cn from 'classnames';
import { noop } from 'lodash';
import { Icon } from '@wework/dieter-ui';
import { IconName, IconSize } from '@wework/ray2';

import { Button } from 'components-dieter/base/button';
import { AnalyticsKey } from 'lib/contextualAnalytics/types';
import { SearchInput } from 'components-ray/form/fields/searchInput';

import { SEARCH_CLEAR_QUERY_EVENT_LABEL } from '../constants';

import SearchLegend from './searchLegend';
import styles from './searchBar.scss';

const SEARCH_PLACEHOLDER = 'Search for Companies, Members and Guests';

const clearButtonEventLabels: AnalyticsKey = {
  click: SEARCH_CLEAR_QUERY_EVENT_LABEL,
};

type OwnProps = {
  className?: string;
  // eslint-disable-next-line react-redux/no-unused-prop-types
  inputClassName?: string;
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (evt: React.SyntheticEvent<HTMLInputElement>, value: string) => void;
  onClear?: () => void;
  value?: string;
  isDropdownOpen?: boolean;
  // eslint-disable-next-line react-redux/no-unused-prop-types
  loading: boolean;
};

type Props = OwnProps & {
  onChange: (evt: React.SyntheticEvent<HTMLInputElement>, value: string) => void;
  onClear: () => void;
  value: string;
  forwardedRef?: InstanceType<typeof SearchBar>;
};

interface State {
  isFocused: boolean;
}
class SearchBar extends React.Component<Props, State> {
  state = { isFocused: false };
  input = this.props.forwardedRef || React.createRef();
  static defaultProps = {
    onChange: noop,
    onClear: noop,
    value: '',
  };

  clear = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onClear } = this.props;
    const isLeftClick = event.button === 0;
    if (isLeftClick) {
      onClear();
    }
  };

  handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    if (evt?.target) {
      const query = evt.target.value;
      onChange(evt, query);
    }
  };

  handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    if (onFocus) {
      onFocus(evt);
    }
  };

  handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    if (onBlur) {
      onBlur(evt);
    }
  };

  render() {
    const { className, isDropdownOpen, value } = this.props;
    const { isFocused } = this.state;
    const closeButton =
      value && isDropdownOpen ? (
        <Button
          analyticsKey={clearButtonEventLabels}
          as={Icon}
          fitted
          name="x"
          onClick={this.clear}
          className={styles.clear}
        />
      ) : null;
    const legend = isFocused && <SearchLegend className={styles.legend} />;
    const rayFullWidth = isDropdownOpen && '!w-full !z-[100]';
    const rayInitialClass = ' border border-gray-80 rounded-sm w-1/2';

    return (
      <div
        className={cn([
          rayInitialClass,
          rayFullWidth,
          styles.inputContainer,
          className,
          { focused: isFocused },
        ])}
      >
        <div className="w-full h-full">
          <SearchInput
            className="!h-full w-full !text-3xs truncate outline-none"
            data-test="search-bar"
            fillSpace
            icon={{ icon: IconName.SEARCH, size: IconSize.SMALL, className: 'text-black' }}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            placeholder={SEARCH_PLACEHOLDER}
            ref={this.input as React.RefObject<HTMLInputElement>}
            type="search"
            value={value}
          />
        </div>
        {legend}
        {closeButton}
      </div>
    );
  }
}
export default SearchBar;
