import { handleActions, createAction } from 'redux-actions';

import { PaperworkSubset } from 'features/paperwork/ducks';
import cc from 'store/util/createReduxConstant';

export const SET_AGREEMENT_NOTES = cc('SET_AGREEMENT_NOTES');

export const getAgreementNotes = (state: PaperworkSubset) => {
  return state.paperwork.agreementManagement.notes;
};

export const setAgreementNotes = createAction(SET_AGREEMENT_NOTES);

const reducer = handleActions<string>(
  {
    [SET_AGREEMENT_NOTES]: (_state: string, { payload: notes }) => notes,
  },
  ''
);

export default reducer;
