import { VisitorInfo } from 'features/visitors/types';

export const SEARCH_DEBOUNCE = 400;

export const START_OF_DAY = 9;

export const WW_GLOBAL_ACCESS_LOCATION_CODE = 'WW_GLOBAL_ACCESS' as const;

export enum TourTag {
  NO_SHOW = 'No Show',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  SCHEDULED = 'Scheduled',
  RESCHEDULED = 'Rescheduled',
}

export const GUEST_TAGS = {
  PREREGISTERED_GUEST: {
    name: 'PR',
    hoverContent: 'Pre-Registered guest',
  },
  WALK_IN: {
    name: 'WI',
    hoverContent: 'Walk in guest',
  },
  ID_CHECKED: {
    name: 'ID',
    hoverContent: 'ID has been checked',
  },
} as const;

export const MEMBER_TAGS = {
  FIRST_TIME: {
    name: 'FT',
    hoverContent: 'First time in your building',
  },
  ON_DEMAND: {
    name: 'ON DEMAND',
    hoverContent: 'On Demand Member',
  },
} as const;

export enum VisitType {
  GUEST = 'GUEST',
  MEMBER = 'MEMBER',
  MEMBER_FIRST_TIME = 'MEMBER_FIRST_TIME',
  ON_DEMAND = 'ON_DEMAND',
  ON_DEMAND_FIRST_TIME = 'ON_DEMAND_FIRST_TIME',
  TOUR = 'TOUR',
  THIRD_PARTY_BOOKING = 'THIRD_PARTY_BOOKING',
}

export const VISITOR_INFO: Readonly<VisitorInfo> = {
  [VisitType.GUEST]: {
    color: 'orange',
    title: 'Guest',
  },
  [VisitType.MEMBER]: {
    color: 'green',
    title: 'Visiting Member',
  },
  [VisitType.ON_DEMAND]: {
    color: 'blue',
    title: 'On Demand',
  },
  [VisitType.TOUR]: {
    color: 'purple',
    title: 'Tour',
  },
  [VisitType.THIRD_PARTY_BOOKING]: {
    color: 'violet',
    title: '3rd Party Booking',
  },
};

export enum MemberBookingTypes {
  DAILY_DESK = 'DailyDesk',
  CONFERENCE_ROOM = 'ConferenceRoom',
  PRIVATE_OFFICE = 'PrivateOffice',
}

export enum MemberUserKind {
  MEMBER = 'member',
  EMPLOYEE = 'wework_employee',
  ANYWHERE = 'anywhere',
  ALUMNI = 'alumni',
  POTENTIAL = 'potential',
  ANYWHERE_LEAD = 'anywhere_lead',
}

export const guestSearchFields = [
  {
    threshold: 0.25,
    keys: ['visitor.name', 'host.name'],
  },
  {
    threshold: 0.1,
    keys: ['host.company.name'],
  },
];
