import React from 'react';
import { Button, IconName } from '@wework/ray2';

import config from 'config';
import Avatar from 'components-ray/avatar';
import missionControlLogo from 'images/mission-control-logo.svg';

import { SS_MC_OPERATOR_ROUTE_MAP, MC_OPERATOR_PAGE_NAMES, MC_SLACK_URI } from './constants';

interface MCBannerProps {
  onDismiss: () => void;
  pathname: string;
}

const notificationLinkClassName = `
  text-black text-3xs font-main
  flex items-start
  min-h-[94px] p-xs w-full
`;

export const MissionControlBanner = ({ onDismiss, pathname }: MCBannerProps) => {
  const notificationAlertClassName = `
    fixed bottom-10 right-10 z-[1100]
    bg-white w-[400px]
    border-gray-90 rounded-sm shadow
  `;

  return (
    <aside className={notificationAlertClassName} data-testid="notification-alert" role="status">
      <div className="flex justify-between items-center p-xs !pb-0">
        <p className="font-bold text-2xs leading-6 m-0">Heads up, we're moving!</p>
        <Button
          className="!text-black"
          icon={IconName.CLOSE}
          onClick={onDismiss}
          theme="text"
          size="small"
        />
      </div>
      <div className={notificationLinkClassName}>
        <div className="relative min-w-fit">
          <Avatar
            imageUrl={missionControlLogo}
            rounded={false}
            size="medium"
            customBorder="border-white"
          />
        </div>
        <div className="ml-xs text-black">
          <p className="mb-2">
            You can now find this page in Mission Control's{' '}
            <a
              className="link-primary hover:text-primary"
              href={config.missionControl.uri + SS_MC_OPERATOR_ROUTE_MAP[pathname]}
              rel="noreferrer"
              target="_blank"
            >
              {MC_OPERATOR_PAGE_NAMES[pathname]}
            </a>
            .
          </p>
          <span className="text-gray-40 leading-4 text-4xs mb-2">
            Let us know if you have questions or feedback in{' '}
            <a
              className="link-primary hover:text-primary"
              href={MC_SLACK_URI}
              rel="noreferrer"
              target="_blank"
            >
              #mission-control-general
            </a>
          </span>
        </div>
      </div>
    </aside>
  );
};
