import { Dispatch } from 'redux';
import { handleActions, ActionMeta } from 'redux-actions';
import { EventTypes } from 'redux-segment';
import { get } from 'lodash';

import { GetGlobalState } from 'store/types';
import cc from 'store/util/createReduxConstant';

import { EventNameTypes } from '../constants';

export const PAGE_VIEW = cc('PAGE_VIEW');

interface State {
  path: string;
  metadata: { params: { uuid: string } };
  name: string;
  count: number;
  title: string | undefined;
}

export interface PageSubset {
  page: State;
}

const initialState: State = {
  name: 'Home',
  title: 'SpaceStation',
  metadata: {
    params: {
      uuid: '',
    },
  },
  path: '/',
  count: 0,
};

export interface Payload {
  name: string;
  title?: string;
  metadata: {
    params: {
      uuid: string;
    };
    currentLocation: {
      uuid?: string;
      name?: string;
    };
  };
  path: string;
}

// type Action = {payload: Payload;};

export const reducer = handleActions<State, any>(
  {
    [PAGE_VIEW]: (state: State, action: ActionMeta<Payload, any>) => ({
      name: action.payload.name,
      // TODO: Shouldn't this be reversed?
      title: state.title || action.payload.title,
      metadata: action.payload.metadata,
      path: action.payload.path,
      count: state.count + 1,
    }),
  },
  initialState
);

// TODO: use contextual analytics
export const pageView = (payload: Payload) => (
  dispatch: Dispatch<ActionMeta<Payload, any>>,
  getState: GetGlobalState
) => {
  const referrer = get(getState(), 'page.path', '');

  return dispatch({
    type: PAGE_VIEW,
    payload,
    meta: {
      analytics: [
        {
          eventType: EventTypes.track,
          eventPayload: {
            event: EventNameTypes.page,
            label: 'page',
            properties: {
              pageName: payload.name,
              path: payload.path,
              metadata: payload.metadata,
              referrer,
            },
          },
        },
        {
          eventType: EventTypes.page,
          eventPayload: {
            name: payload.name,
            properties: {
              path: payload.path,
              referrer,
              ...payload.metadata,
            },
          },
        },
      ],
    },
  });
};

export default reducer;
