import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { clearAllSearchFilters } from 'store/modules/search';
import { ReduxProps } from 'store/types';

const mapDispatchToProps = {
  clearAllSearchFilters,
};

type Props = Readonly<ReduxProps<any, typeof mapDispatchToProps>>;

const ResultsEmpty: React.FC<Props> = ({ clearAllSearchFilters }) => (
  <div className="border-t border-gray-90 p-sm my-2xs mx-sm">
    <p className="text-3xs">
      No results found. Try adjusting the query or{' '}
      <button type="button" className="text-primary" onClick={clearAllSearchFilters}>
        clearing the filters
      </button>
    </p>
  </div>
);

export default compose<React.ComponentType<Props>>(connect(null, mapDispatchToProps))(ResultsEmpty);
