import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { includes, get } from 'lodash';

import { BaseAction, Dispatch } from 'store/types';
import { createRequestAction } from 'store/util';
import config from 'config';
import { deskTypes } from 'lib/occupiableTypes';
import { deprecated_isPresent, getLocalizedPrice } from 'lib/util';
import { Location } from 'store/modules/locations';
import { getOfficeHoldsKeyedByLocationAndReservableUuid } from 'features/inventory/yourInventory/selectors';
import cc from 'store/util/createReduxConstant';
import { dateFormatsDeprecated } from 'lib/constants';

// Action Constants
export const FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION = cc(
  'FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION'
);
export const FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_SUCCESS = cc(
  'FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_SUCCESS'
);
export const FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_FAIL = cc(
  'FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_FAIL'
);

interface State {
  byLocationUuid: {};
  loaded: boolean;
  error: any | null;
  loading: boolean;
}

export interface AvailableOccupiablesSubset {
  availableOccupiables: State;
}

// Initial State
const initialState: State = {
  byLocationUuid: {},
  error: null,
  loaded: false,
  loading: false,
};

// Selectors
export const getAvailableOccupiables = (
  state: AvailableOccupiablesSubset,
  props: Readonly<{ locationUuid: string }>
) => state.availableOccupiables.byLocationUuid[props.locationUuid];

export const getLocation = (
  _: unknown,
  props: Readonly<{
    locations: Array<Location>;
    locationUuid: string;
  }>
) => props.locations.find(location => location.uuid === props.locationUuid);

export const getCountry = (_: unknown, props: { country?: string }): string => props.country ?? '';

export const getTransformedAvailableOccupiables = createSelector(
  [getAvailableOccupiables, getLocation, getOfficeHoldsKeyedByLocationAndReservableUuid],
  (availableOccupiables, location, officeHolds) => {
    // TODO: Get rid of isPresent and figure out type of availableOccupiables.
    if (!deprecated_isPresent(availableOccupiables)) {
      return [];
    }

    const currency = location && location.default_currency;
    const locale = location && (location.ppwk_default_locale || location.locale);

    const filteredOccupiables = availableOccupiables.filter(
      occupiable =>
        occupiable.type !== 'HotDesk' ||
        (location && includes(config.hdEnabledCountries, location.country))
    );

    return filteredOccupiables.map(occupiable => {
      const hasHold =
        occupiable.hold_expires && moment().isBefore(moment(occupiable.hold_expires * 1000));
      return {
        value: occupiable.uuid,
        label: occupiable.office_num,
        capacity: occupiable.capacity,
        price: occupiable.price,
        displayPrice: getLocalizedPrice({
          price: occupiable.price,
          currencyCode: currency || '',
          locale,
        }),
        pending:
          (occupiable.contract_status === 'Contract Sent' ||
            occupiable.contract_status === 'Contract Voided') &&
          moment().isBefore(moment(occupiable.contract_expiry * 1000)),
        contract_expires: moment(occupiable.contract_expiry * 1000),
        has_hold: hasHold,
        hold_expires_in: occupiable.hold_expires
          ? moment(occupiable.hold_expires * 1000).fromNow(true)
          : 0,
        hold_info: hasHold && location ? get(officeHolds[location.uuid], occupiable.uuid, {}) : {},
        type: occupiable.type,
      };
    });
  }
);

// Reducer
export const reducer = handleActions<State, any, { locationUuid: string }>(
  {
    [FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byLocationUuid: {
        ...state.byLocationUuid,
        [action.meta.locationUuid]: action.payload.result.data,
      },
    }),
    [FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload,
    }),
  },
  initialState
);

export const getAvailableDate = (date?: string | null | undefined): string | null | undefined => {
  if (date == null) {
    return null;
  }

  return moment(date || new Date()).format(dateFormatsDeprecated.iso_date);
};

// Action Creators
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchAvailableOccupiablesByLocation = (locationUuid?: string, fromDate?: string) => (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction({
    endpoint: `${config.salesAPI.uri}/v2/office_availabilities/search`,
    method: 'POST',
    types: [
      FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION,
      { type: FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_SUCCESS, meta: { locationUuid } },
      FETCH_AVAILABLE_OCCUPIABLES_BY_LOCATION_FAIL,
    ],
    body: {
      page: 0,
      per_page: 1000,
      summary: false,
      include_pending_contracts: true,
      types: [...deskTypes, 'Office', 'VirtualReservable'],
      show_unavailable: false,
      location_uuids: [locationUuid],
      available_date: getAvailableDate(fromDate),
    },
  });
  return dispatch(requestAction);
};

export default reducer;
