import removeMd from 'remove-markdown';

import config from 'config';
import ApiRequest from 'lib/api/apiRequest';
import { CheckListPayload, TaskPayload } from 'lib/api/taskAPI/types';
import { CHECKLIST_MAPPING } from 'features/check-ins/constants';
import { Task, TaskCheckList } from 'features/check-ins/types';
import { CHECKINS, STATUSES } from 'features/check-ins/checkInsTable/constants';

export default class TaskAPI extends ApiRequest {
  constructor() {
    super(config.tasksApi.uri);
  }

  getTasks = async ({
    locationUuid,
    referenceUuids,
  }: {
    locationUuid?: string;
    referenceUuids?: string[];
  }): Promise<Task[]> => {
    const config = {
      useArrayFormatBrackets: true,
    };
    const path = `api/v1/tasks`;
    const params: { types: string[]; location_uuid?: string; reference_uuids?: string[] } = {
      types: [CHECKINS.QUARTERLY.type, CHECKINS.TWO_WEEK.type],
      location_uuid: locationUuid,
      reference_uuids: referenceUuids,
    };

    return this.get({ config, params, path });
  };

  getNewTasks = ({
    locationUuid = '',
    taskId,
    types,
  }: {
    types: string | string[];
    locationUuid?: string;
    taskId?: string;
  }): Promise<TaskCheckList[]> => {
    const params = {
      // for some reason, task api does not like "actual" array format brackets but wants the encoded "types[]"
      'types[]': types,
      location_uuid: locationUuid,
    };
    const path = taskId ? `api/v1/tasks/${taskId}` : `api/v1/tasks`;

    return this.get({ params, path });
  };

  updateCheckListTask = (
    taskId: string,
    { checkList, updatedBy, version }: CheckListPayload
  ): Promise<any> => {
    const data = {
      payload: {
        ...TaskAPI.generateCheckListPayload(checkList),
        ...(updatedBy && { updated_by: updatedBy }),
      },
      version,
    };
    const path = `api/v1/tasks/${taskId}`;

    return this.put({ data, path });
  };

  updateTask = (taskId: string, taskPayload: TaskPayload): Promise<any> => {
    const config = {};
    const data = TaskAPI.transformTaskPayload(taskPayload);
    const path = `api/v1/tasks/${taskId}`;

    return this.put({ config, data, path });
  };

  static isStatusDoneOrDeclined = (status: string) => {
    return status === STATUSES.DONE.value || status === STATUSES.DECLINED.value;
  };

  private static generateCheckListPayload = (checkList: Map<string, boolean>) => {
    const values = Object.values(CHECKLIST_MAPPING).map(checkListItem => {
      return [
        checkListItem.apiKey,
        {
          name: checkListItem.value,
          completed: checkList.get(checkListItem.value) || false,
        },
      ] as const;
    });
    return Object.fromEntries(values);
  };

  private static transformTaskPayload = ({
    assigneeName,
    assigneeUuid,
    assignerName,
    assignerUuid,
    completed,
    completedAt,
    completedBy,
    completedByName,
    note,
    priority,
    status,
    updatedBy,
    version,
  }: TaskPayload) => ({
    payload: {
      ...((assigneeName || assigneeName === null) && { assignee_name: assigneeName }),
      ...((assigneeUuid || assigneeUuid === null) && { assignee_uuid: assigneeUuid }),
      ...((assignerName || assignerName === null) && { assigner_name: assignerName }),
      ...((assignerUuid || assignerUuid === null) && { assigner_uuid: assignerUuid }),
      ...(completed && { completed }),
      ...(completedAt && { completed_at: completedAt }),
      ...(completedBy && { completed_by: completedBy }),
      ...(completedByName && { completed_by_name: completedByName }),
      ...(note && {
        note: {
          text: note.noteText,
          raw_text: removeMd(note?.rawText ?? note.noteText),
          ...(note.reasonType && { reason_type: note.reasonType }),
          created_by_uuid: note.authorUuid,
          created_by_name: note.authorName,
          created_by_email: note.authorEmail,
          created_at: Date.now(),
        },
      }),
      ...(priority &&
        status &&
        TaskAPI.isStatusDoneOrDeclined(status) && {
          priority: {
            status: priority.status,
            reasons: priority.reasons,
          },
        }),
      ...(status && { status }),
      ...(updatedBy && { updated_by: updatedBy }),
    },
    version,
  });
}
