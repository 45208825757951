import cc from 'store/util/createReduxConstant';

export const FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME = cc('FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME');
export const FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_SUCCESS = cc(
  'FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_SUCCESS'
);
export const FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_FAIL = cc(
  'FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_FAIL'
);
export const FETCH_EMPLOYEES_BY_ROLE = cc('FETCH_EMPLOYEES_BY_ROLE');
export const FETCH_EMPLOYEES_BY_ROLE_SUCCESS = cc('FETCH_EMPLOYEES_BY_ROLE_SUCCESS');
export const FETCH_EMPLOYEES_BY_ROLE_FAIL = cc('FETCH_EMPLOYEES_BY_ROLE_FAIL');
export const FETCH_EMPLOYEES = cc('FETCH_EMPLOYEES');
export const FETCH_EMPLOYEES_SUCCESS = cc('FETCH_EMPLOYEES_SUCCESS');
export const FETCH_EMPLOYEES_FAIL = cc('FETCH_EMPLOYEES_FAIL');
export const CREATE_EMPLOYEE = cc('CREATE_EMPLOYEE');
export const CREATE_EMPLOYEE_SUCCESS = cc('CREATE_EMPLOYEE_SUCCESS');
export const CREATE_EMPLOYEE_FAIL = cc('CREATE_EMPLOYEE_FAIL');
export const UPDATE_EMPLOYEE = cc('UPDATE_EMPLOYEE');
export const UPDATE_EMPLOYEE_SUCCESS = cc('UPDATE_EMPLOYEE_SUCCESS');
export const UPDATE_EMPLOYEE_FAIL = cc('UPDATE_EMPLOYEE_FAIL');
export const REVOKE_EMPLOYEE = cc('REVOKE_EMPLOYEE');
export const REVOKE_EMPLOYEE_SUCCESS = cc('REVOKE_EMPLOYEE_SUCCESS');
export const REVOKE_EMPLOYEE_FAIL = cc('REVOKE_EMPLOYEE_FAIL');
export const REACTIVATE_EMPLOYEE = cc('REACTIVATE_EMPLOYEE');
export const REACTIVATE_EMPLOYEE_SUCCESS = cc('REACTIVATE_EMPLOYEE_SUCCESS');
export const REACTIVATE_EMPLOYEE_FAIL = cc('REACTIVATE_EMPLOYEE_FAIL');
export const GET_EMPLOYEE = cc('GET_EMPLOYEE');
export const GET_EMPLOYEE_SUCCESS = cc('GET_EMPLOYEE_SUCCESS');
export const GET_EMPLOYEE_FAIL = cc('GET_EMPLOYEE_FAIL');
export const SET_FILTERS = cc('SET_FILTERS');
export const FETCH_AVAILABLE_ROLES = cc('FETCH_AVAILABLE_ROLES');
export const FETCH_AVAILABLE_ROLES_SUCCESS = cc('FETCH_AVAILABLE_ROLES_SUCCESS');
export const FETCH_AVAILABLE_ROLES_FAIL = cc('FETCH_AVAILABLE_ROLES_FAIL');

export const FETCH_EMPLOYEES_BY_ROLE_PAGE_SIZE = 2000;
