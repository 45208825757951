import * as React from 'react';
import cn from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatDistance, parseISO, isValid } from 'date-fns';

import Avatar from 'components-ray/avatar';
import { getCurrentLocationUuid } from 'store/selectors';
import { GlobalState } from 'store/modules';
import { BaseAction, Dispatch, ReduxProps } from 'store/types';
import { trackAnalyticsFor } from 'lib/analytics/analytics';
import { AnalyticsEventName } from 'lib/analytics/constants';
import rollbar from 'lib/rollbar';
import { useGetCurrentWorkflow } from 'lib/analytics/types/useGetCurrentWorkflow';
import { Notification } from 'lib/api/notify/types';
import NotificationMapper from 'features/notifications/utils/notificationMapper';
import { markNotificationAsRead } from 'features/notifications/ducks/utils';
import { getUrlPath } from 'features/notifications/utils/urlUtils';
import {
  setLocationChangeModalOpen,
  setNotificationDeepLink,
} from 'features/notifications/ducks/notificationDeepLink';
import { notificationLinkClassName } from 'features/notifications/components/notificationItem/constants';

export type WithSpacestationContextProps = {
  notification: Notification;
  roundedAvatar?: boolean;
  style?: React.CSSProperties;
};

export type NotificationItemProps = WithSpacestationContextProps & {
  notificationContent?: JSX.Element | string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const NotificationItem = ({
  notification,
  notificationContent,
  onClick,
  roundedAvatar = false,
  style,
}: NotificationItemProps): JSX.Element => {
  const { read_at, websocket_image, websocket_url } = notification;

  const avatarUrl = websocket_image || '';
  const isRead = !!read_at;
  const notificationUrl = websocket_url || '';

  const lastUpdated =
    formatDistance(new Date(notification.created_at), new Date(), {
      addSuffix: true,
    }) || '';

  return (
    <>
      <a
        className={cn(notificationLinkClassName, { 'bg-white': !isRead })}
        data-testid="notification-item"
        href={notificationUrl}
        onClick={onClick}
        style={style}
      >
        <div className="relative min-w-fit">
          <Avatar
            imageUrl={avatarUrl}
            rounded={roundedAvatar}
            size="medium"
            customBorder="border-white"
          />
          {!isRead && (
            <span
              className="absolute -top-1 -right-1 w-3 h-3 rounded-full bg-primary"
              role="presentation"
            />
          )}
        </div>
        <div className="ml-xs">
          {notificationContent}
          <span className="text-gray-40 leading-4 text-4xs">{lastUpdated.toLowerCase()}</span>
        </div>
      </a>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  currentLocationUuid: getCurrentLocationUuid(state, {}),
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  markNotificationAsRead: (notificationUuid: string) =>
    dispatch(markNotificationAsRead(notificationUuid)),
  setNotificationDeepLink: (actionUrl: string, targetLocationUuid: string) =>
    dispatch(setNotificationDeepLink(actionUrl, targetLocationUuid)),
});

export type Props = Readonly<
  ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & WithSpacestationContextProps
>;

// used to separate out spacestation-specific logic from notification item for re-usability across platforms
function WithSpacestationContext<P>(
  Component: React.ComponentType<P & Props & NotificationItemProps>
) {
  return function SpacestationContext(props: P & Props) {
    const { currentLocationUuid, markNotificationAsRead, notification } = props;

    const {
      created_at,
      kind,
      meta_data,
      read_at,
      uuid,
      websocket_content,
      websocket_url,
    } = notification;

    const history = useHistory();
    const dispatch = useDispatch();
    const workflow = useGetCurrentWorkflow();

    const isRead = !!read_at;
    const location_uuid = meta_data?.websocket?.location_uuid || '';
    const notificationUrl = websocket_url || '';

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      markNotificationAsRead(uuid);

      if (!isValid(parseISO(created_at))) {
        rollbar.error(
          `Failed to convert notification: ${uuid} created time ${created_at} to ISO String`
        );
      }
      trackAnalyticsFor(AnalyticsEventName.notifications_card_clicked, {
        category: kind,
        date_populated: created_at,
        free_text: websocket_content,
        unread: isRead,
        workflow,
      });

      const urlPath = getUrlPath(notificationUrl);

      dispatch(setNotificationDeepLink(urlPath, location_uuid));

      if (currentLocationUuid === location_uuid) {
        return history.push(urlPath);
      }

      dispatch(setLocationChangeModalOpen());
    };

    const notificationContent = <NotificationMapper notification={notification} />;

    return <Component {...props} onClick={handleClick} notificationContent={notificationContent} />;
  };
}

export const TestableNotificationItem = NotificationItem;

export const SpacestationNotificationItem = WithSpacestationContext(NotificationItem);

export default connect(mapStateToProps, mapDispatchToProps)(SpacestationNotificationItem);
