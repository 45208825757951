import { handleActions } from 'redux-actions';

import { BaseAction, BaseState, RSAAType } from 'store/types';
import { createRequestAction } from 'store/util';
import config from 'config';
import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';
import { ListingGroup } from 'features/alchemist/types';

export const [
  FETCH_LISTINGS_BY_BUILDING,
  FETCH_LISTINGS_BY_BUILDING_SUCCESS,
  FETCH_LISTINGS_BY_BUILDING_FAIL,
] = createRequestConstantNames(cc('FETCH_LISTINGS_BY_BUILDING'));

export const ADDONS_GROUP_SLUG = 'addons';

export type ListingsByBuildingUuidMap = {
  [buildingUuid: string]: {
    [categorySlug: string]: ListingGroup;
  };
};

export interface State extends BaseState {
  byBuildingUuid: ListingsByBuildingUuidMap;
}

export interface ListingsByBuildingSubset {
  listingsByBuilding: State;
}

interface ActionMeta {
  buildingUuid: string;
  groupSlug: string;
}

interface ActionPayload extends BaseAction {
  payload: ListingGroup;
  meta: ActionMeta;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  byBuildingUuid: {},
  error: null,
};

const reducer = handleActions<State, ListingGroup, ActionMeta>(
  {
    [FETCH_LISTINGS_BY_BUILDING]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
    }),
    [FETCH_LISTINGS_BY_BUILDING_SUCCESS]: (state, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byBuildingUuid: {
        ...state.byBuildingUuid,
        [action.meta.buildingUuid]: {
          [action.payload.name]: action.payload,
        },
      },
    }),
    [FETCH_LISTINGS_BY_BUILDING_FAIL]: state => ({
      ...state,
      loading: false,
      loaded: false,
      error: true,
    }),
  },
  initialState
);

export const fetchListingsForBuilding = (buildingUuid: string, groupSlug: string): RSAAType =>
  createRequestAction({
    endpoint: `${config.productService.uri}/v2/listings/buildings/${buildingUuid}/groups/${groupSlug}`,
    types: [
      FETCH_LISTINGS_BY_BUILDING,
      {
        type: FETCH_LISTINGS_BY_BUILDING_SUCCESS,
        meta: { buildingUuid, groupSlug },
      },
      FETCH_LISTINGS_BY_BUILDING_FAIL,
    ],
  });

export default reducer;
