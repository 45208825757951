import { Amenity, AmenityPayload } from 'features/building/types';

export const getAmenitiesByID = (
  amenities: AmenityPayload | undefined
): { [key: string]: Amenity } => {
  if (!amenities) return {};

  return amenities.items.reduce((result, amenity) => {
    const entryId = amenity.fields.marketingAttributes?.sys.id;
    const entry = amenities.includes?.Entry.find(ent => ent.sys.id === entryId);
    const asset = entry
      ? amenities.includes?.Asset?.find(ass => ass.sys.id === entry?.fields?.icon?.sys.id)
      : null;
    result[amenity.fields.mlsUuid] = {
      name: amenity.fields.name,
      mlsUuid: amenity.fields.mlsUuid,
      description: amenities.includes.Entry.find(entry => entry.fields.name === amenity.fields.name)
        ?.fields?.description,
      iconUrl: asset ? `https://${asset.fields.file.url}` : null,
    };

    return result;
  }, {});
};
