import { handleActions, Action, ActionMeta, ReduxCompatibleReducer } from 'redux-actions';
import { RSAARequestType, RSAASuccessType, RSAAFailureType } from 'redux-api-middleware';

import { RequestActionTypes as FetchActionTypes, BaseAction } from 'store/types';

type RequestActionTypes =
  | FetchActionTypes
  | [RSAARequestType, RSAASuccessType, RSAAFailureType, string | BaseAction];

type FetchMeta = Readonly<{ keepCurrentResult?: boolean }>;

type MapActionToResult<Payload, Result> = (action: Action<Payload>, prevResult: Result) => Result;

export interface RequestState<R> {
  loading: boolean;
  loaded: boolean;
  error: Error | null;
  result: R;
}

function defaultMapActionToResult<P>(action: Action<P>): any {
  return action.payload;
}

// Creates a reducer to handle the default behavior pattern of createRequestAction.
// Result - the type of value to be stored in the state, accepted in the success step.
// Payload - the type of value returned from the fetch in the success action's payload.
export function createRequestReducer<Result, Payload = Result>(
  requestActionTypes: RequestActionTypes, // Must specify initial result, since some initial values are incompatible.
  initialResult: Result, // Allow user to transform the incoming fetch result to the structure required in the state.
  // Also pass the current result, in case a more specific change is required, such as an update to a value.
  mapActionToResult: MapActionToResult<Payload, Result> = defaultMapActionToResult
): ReduxCompatibleReducer<RequestState<Result>, Payload> {
  const [FETCH, SUCCESS, FAIL, RESET] = Array.from(requestActionTypes).map(requestActionType =>
    typeof requestActionType === 'string' || typeof requestActionType === 'symbol'
      ? requestActionType
      : requestActionType.type
  );

  type State = RequestState<Result>;

  const initialState: State = {
    loading: false,
    loaded: false,
    error: null,
    result: initialResult,
  };

  const reducer = handleActions<State, any, FetchMeta>(
    {
      [FETCH]: (state, action) => ({
        ...state,
        loading: true,
        loaded: false,
        error: null,
        // Allow user to specify of he wants to keep the current value or reset it.
        result: action.meta && action.meta.keepCurrentResult ? state.result : initialResult,
      }),

      [SUCCESS]: (state: State, action: Action<Payload>): State => ({
        ...state,
        loading: false,
        loaded: true,
        error: null,
        result: mapActionToResult(action, state.result),
      }),

      [FAIL]: (state: State, action: Action<Error>): State => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      }),

      [RESET]: (state: State): State => ({
        ...state,
        loading: false,
        loaded: true,
        error: null,
        result: initialResult,
      }),
    },
    initialState
  );

  return (state, action: ActionMeta<Payload, FetchMeta>) =>
    typeof state === 'undefined' && typeof action === 'undefined'
      ? initialState
      : reducer(state, action);
}
