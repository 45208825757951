import React from 'react';
import moment, { Moment } from 'moment-timezone';
import cn from 'classnames';
import DatePicker from 'react-datepicker';
import { Button } from '@wework/ray2';

import triangleImage from 'images/triangle.svg';
import SelectInput from 'components/formFields/selectInput';
import { dateFormatsDeprecated } from 'lib/constants';

import style from './index.scss';

export enum Granularity {
  DAYS = 'days',
  MONTHS = 'months',
}
interface Props {
  selected: Moment;
  onChange: (date: Moment | null | undefined) => void;
  granularity: Granularity;
  clickDateInCalendarCallback?: (date: Moment | null) => void;
  clickOnArrowCallback?: (arg0: string) => void;
  openMonthSelectCallback?: () => void;
  clickTodayButtonCallback?: () => void;
  datePickerContainerClassName?: string;
  monthsAhead: number;
  monthsBefore: number;
}

const LEFT_ARROW = 'left_arrow';
const RIGHT_ARROW = 'right_arrow';

class CarouselDatePicker extends React.Component<Props> {
  static defaultProps = {
    granularity: Granularity.DAYS,

    // if current date is in December 2018, options will be (inclusive)
    // in the range of January 2019 - January 2018
    monthsAhead: 1,
    monthsBefore: 11,
  };

  getCurrentSelected = (): Moment => {
    const selected = this.props.selected ? this.props.selected.clone() : moment();

    if (this.props.granularity === Granularity.MONTHS) {
      selected.startOf('month');
    }

    return selected;
  };

  onChange = (selected: Moment | null | undefined) => {
    this.props.onChange(selected);
  };

  handleLeftArrowClick = () => {
    this.onChange(this.getCurrentSelected().subtract(1, this.props.granularity));

    if (this.props.clickOnArrowCallback) {
      this.props.clickOnArrowCallback(LEFT_ARROW);
    }
  };

  handleRightArrowClick = () => {
    this.onChange(this.getCurrentSelected().add(1, this.props.granularity));

    if (this.props.clickOnArrowCallback) {
      this.props.clickOnArrowCallback(RIGHT_ARROW);
    }
  };

  handleDatePicked = (date: string) => {
    this.selectDate(moment(date));
  };

  selectDate = (date: Moment | null) => {
    this.onChange(date);

    if (this.props.clickDateInCalendarCallback) {
      this.props.clickDateInCalendarCallback(date);
    }
  };

  setTodayDate = () => {
    this.selectDate(moment());
    if (this.props.clickTodayButtonCallback) {
      this.props.clickTodayButtonCallback();
    }
  };

  formatMoment = (date: Moment): string => {
    return date.format(
      this.props.granularity === Granularity.MONTHS
        ? dateFormatsDeprecated.month_with_year
        : dateFormatsDeprecated.long_with_year
    );
  };

  getMonthOptions = () => {
    const { monthsAhead, monthsBefore } = this.props;
    const currentMonth = moment().startOf('month');

    const months: Array<Moment> = [];

    for (let i = monthsAhead; i >= -monthsBefore; i--) {
      months.push(currentMonth.clone().add(i, 'month'));
    }

    return months.map((month: Moment) => ({
      label: this.formatMoment(month),
      value: month.toISOString(true),
    }));
  };

  handleMonthSelectOpen = (): any => this.props.openMonthSelectCallback?.();

  renderMonthSelectComponent = () => {
    const selected = this.getCurrentSelected();

    return (
      <SelectInput
        options={this.getMonthOptions()}
        className={style.buttonDropDown}
        onChange={this.handleDatePicked}
        onOpen={this.handleMonthSelectOpen()}
        value={selected.toISOString(true)}
        clearable={false}
        searchable={false}
        placeholder={this.formatMoment(selected)}
      />
    );
  };

  isTodayDate = (): boolean => {
    const { granularity, selected } = this.props;
    return selected.isSame(new Date(), granularity === Granularity.MONTHS ? 'month' : 'day');
  };

  renderDateSelectComponent = () => {
    const { selected } = this.props;

    return (
      <>
        <span>{this.formatMoment(selected)}</span>
        <div>
          <DatePicker
            customInput={
              <img
                alt="dropdown"
                style={{ cursor: 'pointer', marginLeft: '5px', alignSelf: 'center' }}
                src={triangleImage}
              />
            }
            fixedHeight
            popperPlacement="bottom"
            popperModifiers={[
              {
                name: 'flip',
                enabled: false,
              },
            ]}
            selected={selected.toDate()}
            onChange={(date: Date | null) => {
              this.selectDate(moment(date));
            }}
          />
        </div>
      </>
    );
  };

  render() {
    const { granularity, datePickerContainerClassName } = this.props;
    const isTodayButtonDisabled = this.isTodayDate();

    return (
      <div className={style.wrapper}>
        <Button
          className="mr-5"
          disabled={isTodayButtonDisabled}
          onClick={this.setTodayDate}
          size="medium"
          theme="fill"
        >
          Today
        </Button>
        <div className={style.pageArrow} onClick={this.handleLeftArrowClick} />
        <div className={cn(style.pageArrow, style.right)} onClick={this.handleRightArrowClick} />
        <div className={cn(style.datePickerContainer, datePickerContainerClassName)}>
          {granularity === Granularity.MONTHS
            ? this.renderMonthSelectComponent()
            : this.renderDateSelectComponent()}
        </div>
      </div>
    );
  }
}

export default CarouselDatePicker;
