import { handleActions, Reducer, Action, ActionMeta } from 'redux-actions';

import { CompanyStats } from 'features/companies/membersSection/types';
import {
  FETCH_COMPANY_STATS,
  FETCH_COMPANY_STATS_SUCCESS,
  FETCH_COMPANY_STATS_FAIL,
} from 'features/companies/membersSection/redux/companyStats/constants';

export interface State {
  byUuid: Hash<CompanyStats>;
  status: {
    loaded: boolean;
    loading: boolean;
    byUuid: { loaded: boolean; loading: boolean; error: string | undefined };
  };
}

export interface CompanyStatsSubset {
  companyStats: State;
}

const initialState: State = {
  byUuid: {},
  status: {
    loading: false,
    loaded: true,
    byUuid: {
      loaded: false,
      loading: false,
      error: undefined,
    },
  },
};

const reducer: Reducer<State, any> = handleActions<State, any, any>(
  {
    [FETCH_COMPANY_STATS]: (state: State, action: ActionMeta<null, { companyUuid: string }>) => ({
      ...state,
      status: {
        ...state.status,
        loading: true,
        loaded: false,
        byUuid: {
          ...state.status.byUuid,
          [action.meta.companyUuid]: {
            loading: true,
            loaded: false,
            error: undefined,
          },
        },
      },
    }),

    [FETCH_COMPANY_STATS_SUCCESS]: (
      state: State,
      action: ActionMeta<CompanyStats, { companyUuid: string }>
    ) => ({
      ...state,
      byUuid: {
        ...state.byUuid,
        [action.meta.companyUuid]: action.payload,
      },
      status: {
        ...state.status,
        loading: false,
        loaded: true,
        byUuid: {
          ...state.status.byUuid,
          loading: false,
          loaded: true,
          error: undefined,
        },
      },
    }),

    [FETCH_COMPANY_STATS_FAIL]: (state: State, action: Action<{ error?: string }>) => ({
      ...state,
      status: {
        ...state.status,
        loading: false,
        loaded: false,
        byUuid: {
          ...state.status.byUuid,
          loading: false,
          loaded: false,
          error: action.payload.error,
        },
      },
    }),
  },
  initialState
);

export default reducer;
