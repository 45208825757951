import { RequestActionTypes } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';

import {
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION,
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION_SUCCESS,
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION_FAIL,
} from './constants';
import { PriceChangeReservation } from './types';

const types: RequestActionTypes = [
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION,
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION_SUCCESS,
  FETCH_ALL_PRICE_CHANGES_BY_LOCATION_FAIL,
];

const reducer = createRequestReducer<PriceChangeReservation[], PriceChangeReservation[]>(
  types,
  [],
  res => res.payload
);
export default reducer;
