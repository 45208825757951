import moment from 'moment-timezone';
import {
  concat,
  findLastIndex,
  head,
  isEmpty,
  isInteger,
  isNaN,
  map,
  range,
  reduce,
  slice,
  sumBy,
  round,
  merge,
} from 'lodash';

import { getCurrencySymbol, getOrdinal, isNumber, deprecated_isPresent } from 'lib/util';
import { dateFormatsDeprecated } from 'lib/constants';

const endOfMonth = dateString => {
  return moment
    .utc(dateString)
    .endOf('month')
    .format(dateFormatsDeprecated.iso_date);
};

export const MAX_DISCOUNT_DURATION = 36;
const CURRENCY_USD = 'USD';
const ENGLISH_US_LOCALE = 'en-US';

// Calculates the prorated price for pending reservations. Assumes that the price
// passed in is the total price for all offices the discount will apply to.
export const calculateProration = (moveIn, price) => {
  const startedOn = moment.utc(moveIn);
  const beginningOfMonth = moment.utc(moveIn).startOf('month');
  const numDaysInMonth = beginningOfMonth.daysInMonth();

  if (startedOn.date() === beginningOfMonth.date()) {
    return price;
  }

  const daysToEndOfMonth = numDaysInMonth - startedOn.date() + 1;
  return round((price / numDaysInMonth) * daysToEndOfMonth, 2);
};

export const calculateProrationWithInternationalization = (moveIn, price, currency = CURRENCY_USD, locale = ENGLISH_US_LOCALE) => {
  return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
          }).format(calculateProration(moveIn, price));
};

// Returns the price that will actually be charged
export const getChargePrice = (price, reservation_price) => {
  if (reservation_price > price) {
    return reservation_price;
  }
  return price;
};

// This is terribly named
export const getReservationPrice = (reservation) => {
  // reservation_price = current reservation price
  // price = current reservable market price
  return getChargePrice(reservation.price, reservation.reservation_price);
};

export const calculateTotalOfficePrice = (reservations) => {
  return reduce(
    reservations,
    (total, reservation) => {
      const price = getReservationPrice(reservation);

      if (isNumber(reservation.quantity) && reservation.quantity > 1) {
        return total + parseFloat(price) * reservation.quantity;
      }
      return total + parseFloat(price);
    },
    0
  );
};

const getRate = (baseRate, { isVariable, variableRates, index }) => {
  if (index > 0 && isVariable) {
    return variableRates[index - 1];
  }
  return baseRate;
};

export const getStartDate = (startDate, index) => {
  if (index === 0) {
    return moment.utc(startDate).format(dateFormatsDeprecated.iso_date); // convert the date to the format we are using
  }

  return moment
    .utc(startDate)
    .startOf('month')
    .add(index, 'month')
    .format(dateFormatsDeprecated.iso_date);
};

const generatePromotionDisplayText = (promotion, currency) => {
  let blurb = `${promotion.code} [${promotion.applies_to_product}]`;
  if (promotion.variable) {
    blurb += ' - variable rate discount, going from ';
    if (promotion.percent === true) {
      const varAmount = promotion.variable_amounts[promotion.variable_amounts.length - 1] * 100;
      blurb += `${promotion.amount * 100}% to ${varAmount}% `;
    } else {
      blurb += `${currency}${promotion.amount} to ${currency}`;
      blurb += `${promotion.variable_amounts[promotion.variable_amounts.length - 1]} `;
    }
    blurb += `over ${promotion.duration} months`;
  } else {
    const amount =
      promotion.percent === true ? `${promotion.amount * 100} %` : `${currency}${promotion.amount}`;
    blurb += `- up to ${amount} off; up to ${promotion.duration} months`;
  }
  return blurb;
};

export const addDisplayTextToPromotions = (promotions, currency) => {
  return map(promotions, promotion => {
    const displayText = generatePromotionDisplayText(promotion, currency);
    return { ...promotion, displayText };
  });
};

const serializeDiscount = (discount, type, locationUuid, changeDate) => {
  return {
    started_on: discount.startedOn,
    ended_on: discount.endedOn ? discount.endedOn : null,
    amount: discount.amount,
    promotion_code: discount.promotionCode,
    location_uuid: locationUuid,
    change_date: changeDate,
  };
};

export const createPayload = (opts = {}) => {
  const {
    accountUuid,
    accountAdminUuid,
    amounts,
    initialTerms,
    isPerpetuating,
    locationUuid,
    perpetuateFrom,
    promotionCode,
    reservations,
    startedOn,
    totalOfficePrice,
    type,
    uploadedContractLink,
    operationalDiscounts,
    operationalDiscountReason,
    operationalDiscountNote,
    salesforceOpportunityId,
    shouldSkipSignature,
    discountReason,
    modificationClause,
  } = opts;
  const serializedDiscounts = reduce(
    amounts,
    (discounts, amount, index) => {
      if (amount === '' || amount === 0) {
        return discounts;
      }

      if (isPerpetuating && index > perpetuateFrom) {
        return discounts;
      }

      const startDate = getStartDate(startedOn, index);
      const endedOn = isPerpetuating && index === perpetuateFrom ? '' : endOfMonth(startDate);

      const discount = {
        amount: type === 'percent' ? (amount / 100) * totalOfficePrice : amount,
        endedOn,
        promotionCode,
        startedOn: startDate,
      };
      return concat(discounts, serializeDiscount(discount, type, locationUuid, startedOn));
    },
    []
  );

  const serializedReservations = map(reservations, reservation => {
    // reservation_price is what the member is currently paying. Default to this when sending
    // amendments to Spaceman. If it is not present, use price as a backup.

    return {
      reservation_uuid: reservation.uuid,
      reservable_uuid: reservation.reservable_uuid,
      initial_terms: initialTerms,
      location_uuid: locationUuid,
      change_date: startedOn,
      market_price: getReservationPrice(reservation),
      ...(salesforceOpportunityId ? { sf_opportunity_id: salesforceOpportunityId } : {}),
      discount_reason: discountReason,
      agreement_notes: modificationClause,
    };
  });

  return {
    discounts: serializedDiscounts,
    reservations: serializedReservations,
    account_uuid: accountUuid,
    account_admin_uuid: accountAdminUuid,
    signed_pdf_url: uploadedContractLink,
    operational_discounts: operationalDiscounts, // this is serialized in resourceForm, only one that uses this,
    operational_discount_note: operationalDiscountNote,
    operational_discount_reason: operationalDiscountReason,
    self_executing: shouldSkipSignature,
  };
};

const buildInitialTerms = (count, reservation) => ({
  calendarUnit: 'months',
  count,
  moveInDate: moment.utc(reservation.moveIn).format(dateFormatsDeprecated.iso_date),
  locationUUID: reservation.building_uuid,
});

export const buildAllInitialTerms = (forms, pendingReservations) =>
  reduce(
    pendingReservations,
    (allInitialTerms, reservations, storeKey) => ({
      ...allInitialTerms,
      [storeKey]: buildInitialTerms(forms[storeKey]?.initialTerms || 1, head(reservations)),
    }),
    {}
  );

export const calculateOriginalSetupFeeAmount = (reservations, setupFeeResource) => {
  if (!setupFeeResource || reservations == null || reservations[0]?.currency == null || reservations[0]?.capacity == null) {
    return 0;
  }
  const totalSeats = sumBy(reservations, 'capacity');
  const currency = head(reservations).currency;
  const setupFeeAmount = setupFeeResource.international_prices[currency];
  return totalSeats * setupFeeAmount;
};

export const buildSetupFees = (
  setupFees,
  pendingReservations,
  setupFeeResource
) => {
  if (!setupFeeResource) {
    return {};
  }

  return reduce(
    pendingReservations,
    (allSetupFees, reservations, storeKey) => {
      const reservation = head(reservations);
      const originalAmount = calculateOriginalSetupFeeAmount(reservations, setupFeeResource);

      return {
        ...allSetupFees,
        [storeKey]: {
          amount: setupFees[storeKey] ?? null,
          locationUUID: reservation.building_uuid,
          originalAmount,
          startedOn: moment.utc(reservation.moveIn).format(dateFormatsDeprecated.iso_date),
        },
      };
    },
    {}
  );
};

export const getEditSetupFeeAmount = value => {
  if (isNumber(value)) {
    return parseFloat(value);
  }
  return value === '' ? null : value;
};

export const formatAmount = amount => (isInteger(amount) ? `${amount}` : Number(amount).toFixed(2));

export const getAmountsFromPromotion = promotion => {
  const duration = promotion.duration || 1;
  const isVariable = promotion.variable;
  const variableRates = isVariable ? promotion.variable_amounts : [];

  return map(range(duration), index => {
    const amount = getRate(promotion.amount, { isVariable, variableRates, index });
    return promotion.percent ? amount * 100 : amount;
  });
};

export const toggleDiscountAmounts = (amounts, newType, totalPrice) =>
  map(amounts, amount => {
    const newAmount =
      newType === 'percent' ? (amount / totalPrice) * 100 : (amount / 100) * totalPrice;
    return isNaN(newAmount) ? amount : newAmount;
  });

export const fillAmounts = (duration, rate, type, totalPrice) => {
  const amount = type === 'amount' ? totalPrice * (rate / 100) : rate;
  return Array(duration).fill(amount);
};

export const calculateAverageRate = (delta, rate, duration, type, totalPrice) => {
  if (type === 'amount') {
    return rate + (delta / (duration * totalPrice)) * 100;
  }
  return rate + delta / duration;
};

const buildDiscounts = (
  { amounts, promotionCode, type, perpetuating, lastIndex },
  { currency, locationUUID, totalPrice, startDate }
) =>
  reduce(
    amounts,
    (discounts, amount, index) => {
      const startedOn = getStartDate(startDate, index);
      return index <= lastIndex
        ? concat(discounts, {
            amount,
            currency,
            endedOn: endOfMonth(startedOn),
            index,
            locationUUID,
            month: moment.utc(startedOn).format(dateFormatsDeprecated.month),
            moveIn: startDate,
            percentageConversion:
              type === 'percent' ? (parseFloat(amount) / 100) * totalPrice : amount,
            perpetuating: perpetuating && index === lastIndex,
            promotionCode,
            promotionRate: type === 'percent' ? amount : (parseFloat(amount) / totalPrice) * 100,
            prorating: moment.utc(startedOn).date() > 1,
            startedOn,
            title: `${getOrdinal(index + 1)} Month`,
            type,
          })
        : discounts;
    },
    []
  );

const buildFormDiscount = (
  { amounts, promotionCode, type, perpetuating, formStyle },
  reservations
) => ({
  discounts: buildDiscounts(
    {
      amounts,
      promotionCode,
      type,
      perpetuating,
      lastIndex: findLastIndex(amounts, amount => amount > 0),
    },
    {
      currency: getCurrencySymbol(head(reservations).currency),
      locationUUID: head(reservations).building_uuid,
      totalPrice: calculateTotalOfficePrice(reservations),
      startDate: head(reservations).moveIn,
    }
  ),
  duration: amounts.length,
  showForm: true,
  formStyle,
  rate:
    type === 'percent'
      ? reduce(amounts, (total, amount) => total + parseFloat(amount), 0) / amounts.length / 100
      : reduce(amounts, (total, amount) => total + parseFloat(amount), 0) /
        amounts.length /
        calculateTotalOfficePrice(reservations),
  promotionCode,
  type,
});

const buildCommitmentBasedDiscount = (
  commitmentBasedDiscount,
  override,
  overrideAmount,
  overrideDuration,
  reservations
) => {
  const amount = override ? overrideAmount : commitmentBasedDiscount.percentage;
  // only flexible discounts can override duration (not commitment term)
  const duration =
    override && isNumber(overrideDuration) ? overrideDuration : commitmentBasedDiscount.months;
  const promotionCode = override ? 'discount_adjust' : commitmentBasedDiscount.tag;
  return {
    discounts: buildDiscounts(
      {
        amounts: Array(duration).fill(amount),
        promotionCode,
        type: 'percent',
        perpetuating: false,
        lastIndex: duration - 1,
      },
      {
        currency: getCurrencySymbol(head(reservations).currency),
        locationUUID: head(reservations).building_uuid,
        totalPrice: calculateTotalOfficePrice(reservations),
        startDate: head(reservations).moveIn,
      }
    ),
    duration,
    showForm: true,
    formStyle: 'basic',
    rate: amount / 100,
    promotionCode,
    type: 'percent',
  };
};

export const buildAllDiscounts = (
  forms,
  commitmentBasedDiscounts,
  products,
  pendingReservations,
  promotionCodes,
  isCommitmentBasedPromotionExperimentOn = false
) =>
  reduce(
    pendingReservations,
    (discounts, reservations, storeKey) => {
      if (products[storeKey] === 'custom') {
        if (deprecated_isPresent(forms[storeKey])) {
          return {
            ...discounts,
            [storeKey]: buildFormDiscount(
              merge({ promotionCode: promotionCodes[storeKey] }, forms[storeKey]),
              reservations
            ),
          };
        }
        return discounts;
      }

      if (isCommitmentBasedPromotionExperimentOn) {
        return discounts;
      }

      return {
        ...discounts,
        [storeKey]: buildCommitmentBasedDiscount(
          commitmentBasedDiscounts.selections[storeKey],
          commitmentBasedDiscounts.overrides[storeKey],
          commitmentBasedDiscounts.overrideAmounts[storeKey],
          commitmentBasedDiscounts.overrideDurations[storeKey],
          reservations
        ),
      };
    },
    {}
  );

const getPerpetuateFrom = amounts => {
  const lastIndex = findLastIndex(amounts, amount => amount > 0);
  return lastIndex > -1 ? lastIndex : 0;
};

export const getCommitments = (amounts, initialTerms, perpetuating, type) => {
  const isPercent = type === 'percent';
  const fullAmounts =
    amounts.length < initialTerms
      ? concat(amounts, Array(initialTerms - amounts.length).fill(0))
      : slice(amounts, 0, initialTerms);
  const perpetuateFrom = getPerpetuateFrom(amounts);
  return reduce(
    fullAmounts,
    (commitmentDiscounts, amount, index) => {
      if (perpetuating && perpetuateFrom < index) {
        return concat(commitmentDiscounts, { isPercent, amount: amounts[perpetuateFrom] || 0 });
      }
      return concat(commitmentDiscounts, { isPercent, amount: amount || 0 });
    },
    []
  );
};

export const isFormPopulated = form => {
  if (form == null || isEmpty(form)) {
    return false;
  }

  const { amounts, initialTerms } = form;
  return amounts.some(amount => amount > 0) || initialTerms > 1;
};
