import { get, isUndefined, isNull } from 'lodash';

import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';
import isInventoryGroup from '../actions/isInventoryGroup';

export const simpleSerializer = (
  inventoryPayloadItem: InventoryPayloadItem,
  serializerKey: string
): InventoryAttribute | null | undefined => {
  const attribute = get(inventoryPayloadItem, serializerKey);
  const isGroup = isInventoryGroup(inventoryPayloadItem);

  if (isUndefined(attribute) || isNull(attribute)) {
    return null;
  }

  const value = String(attribute);

  return { value, meta: { isGroup } };
};

export default simpleSerializer;
