import React from 'react';
import { kebabCase, mapKeys } from 'lodash';
import cn from 'classnames';

interface FormFieldProps {
  children: React.PropsWithChildren<React.ReactNode>;
  isDisabled?: boolean;
  isrequired?: boolean;
  label: string;
  displayError?: boolean;
  errorMessage?: string;
  data?: Object;
}

interface LabelProps {
  children: React.PropsWithChildren<string>;
  disabled?: boolean;
  displayError?: boolean;
}

/**
 * Taken from the Plasma package.
 * May need to switch to its own utility later.
 * @param {Object} data The data to parse through
 * @returns
 */
export const getDataAttrs = (data?: Object) => {
  if (!data) return {};
  return mapKeys(data, (_val, key) => `data-${kebabCase(key)}`);
};

export const FormField = ({
  isDisabled,
  isrequired,
  label,
  data,
  displayError,
  errorMessage,
  children,
}: FormFieldProps) => {
  let labelText = label;

  if (isrequired) {
    labelText += ' *';
  }

  return (
    <div className="relative mb-4" {...getDataAttrs(data)}>
      <FormField.Label disabled={isDisabled} displayError={displayError}>
        {labelText}
      </FormField.Label>
      <div>{children}</div>
      {displayError && errorMessage && (
        <p className="text-3xs text-negative ml-2.5 mt-1">{errorMessage}</p>
      )}
    </div>
  );
};

FormField.defaultProps = {
  children: '',
  isDisabled: false,
  label: '',
};

FormField.Label = function FormFieldLabel({
  children,
  disabled = false,
  displayError = false,
}: LabelProps): JSX.Element {
  return (
    <label
      className={cn(
        'absolute text-label text-4xs text-gray-40 -top-3 bg-white px-3xs left-2 z-10',
        {
          '!text-gray-70': disabled,
          '!text-negative': displayError,
        }
      )}
    >
      {children}
    </label>
  );
};
