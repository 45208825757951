import { handleActions } from 'redux-actions';

import {
  RESET_CREATE_MEMBERS_STATUS,
  INVITE_BULK_USERS,
  INVITE_BULK_USERS_SUCCESS,
  INVITE_BULK_USERS_FAIL,
  INVITE_SINGLE_USER,
  INVITE_SINGLE_USER_SUCCESS,
  INVITE_SINGLE_USER_FAIL,
  FETCH_EXTRA_MEMBER_FEE,
  FETCH_EXTRA_MEMBER_FEE_FAIL,
  FETCH_EXTRA_MEMBER_FEE_SUCCESS,
} from 'features/companies/membersSection/redux/createMembers/constants';

import {
  State,
  CreateMembersAction,
  CreateMembersActionWithPayload,
  CreateMembersMeta,
  InviteMemberAction,
  ExtraMemberFeeAction,
} from './types';

// Initial State
export const initialState: State = {
  byCompanyUuid: {},
  extraMemberFee: {
    fee: null,
    error: null,
  },
};

export const reducer = handleActions<State, any, CreateMembersMeta>(
  {
    [INVITE_BULK_USERS]: (state: State, action: CreateMembersAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: true,
          loaded: false,
          error: null,
        },
      },
    }),
    [INVITE_BULK_USERS_SUCCESS]: (state: State, action: CreateMembersAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: false,
          loaded: true,
          error: null,
        },
      },
    }),
    [INVITE_BULK_USERS_FAIL]: (state: State, action: CreateMembersActionWithPayload) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: false,
          loaded: false,
          error: action?.payload?.message || '',
        },
      },
    }),
    [RESET_CREATE_MEMBERS_STATUS]: (state: State, action: CreateMembersAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: null,
      },
    }),
    [INVITE_SINGLE_USER]: (state: State, action: CreateMembersAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: true,
          loaded: false,
          error: null,
        },
      },
    }),
    [INVITE_SINGLE_USER_SUCCESS]: (state: State, action: InviteMemberAction) => {
      return {
        ...state,
        byCompanyUuid: {
          ...state.byCompanyUuid,
          [action.meta.companyUuid]: {
            loading: false,
            loaded: true,
            error: null,
            createdMemberUuid: action.payload.createdMemberUuid,
          },
        },
      };
    },
    [INVITE_SINGLE_USER_FAIL]: (state: State, action: InviteMemberAction) => ({
      ...state,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: {
          loading: false,
          loaded: false,
          error: action?.payload?.message || '',
        },
      },
    }),
    [FETCH_EXTRA_MEMBER_FEE]: (state: State) => ({
      ...state,
      byCompanyUuid: {},
      extraMemberFee: {
        error: null,
        fee: null,
      },
    }),
    [FETCH_EXTRA_MEMBER_FEE_SUCCESS]: (state: State, action: ExtraMemberFeeAction) => {
      return {
        ...state,
        extraMemberFee: {
          error: null,
          fee: action.payload,
        },
      };
    },
    [FETCH_EXTRA_MEMBER_FEE_FAIL]: (state: State, action: ExtraMemberFeeAction) => ({
      ...state,
      extraMemberFee: {
        error: action?.payload?.message || '',
        fee: null,
      },
    }),
  },
  initialState
);

export default reducer;
