import { PromiseWithAllSettled } from 'lib/util';

import { SearchRequest, SearchResult, EntityType, AdapterResult } from './types';

export interface Adapter {
  search(request: SearchRequest): Promise<AdapterResult>;
}

const DEFAULT_ENTITY_FILTER = Object.values(EntityType);

function buildResponse(
  success: boolean,
  result:
    | AdapterResult
    | {
        [K in EntityType]: Error;
      }
) {
  return Object.entries(result).reduce((acc, [entity, value]) => {
    acc[entity] = { success, value };

    return acc;
  }, {});
}

const buildAdaptersMap = (types, adapters) => {
  const adaptersMap = new Map<Adapter, Array<EntityType>>();
  types.forEach(type => {
    const adapter = adapters[type];

    if (adapter) {
      const types = adaptersMap.get(adapter);

      const values = types ? [...types, type] : [type];

      adaptersMap.set(adapter, values);
    }
  });
  return adaptersMap;
};

const entityPromiseWrapper = (
  promise: Promise<AdapterResult>,
  entities: Array<EntityType>
): Promise<AdapterResult | Record<EntityType, any>> =>
  promise.catch(err =>
    Promise.reject<Record<EntityType, any>>(
      entities.reduce((acc, entity) => {
        acc[entity] = err;
        return acc;
      }, {})
    )
  );

const buildPromises = (adaptersMap: Map<Adapter, Array<EntityType>>, request: SearchRequest) =>
  [...adaptersMap.entries()].map(([adapter, entities]) => {
    const specificTypesRequest = {
      ...request,
      filters: {
        ...request.filters,
        types: entities,
      },
    };

    if (request.facets) {
      specificTypesRequest.facets = request.facets;
    }
    return entityPromiseWrapper(adapter.search(specificTypesRequest), entities);
  });

export default class SearchService {
  adapters: Partial<Record<EntityType, Adapter>>;

  constructor(adapters: Partial<Record<EntityType, Adapter>>) {
    this.adapters = adapters;
  }

  async search(request: SearchRequest): Promise<SearchResult> {
    const { types = DEFAULT_ENTITY_FILTER } = request.filters;
    const adaptersMap: Map<Adapter, Array<EntityType>> = buildAdaptersMap(types, this.adapters);
    const promises = buildPromises(adaptersMap, request);

    const results = await (Promise as PromiseWithAllSettled).allSettled<
      Promise<AdapterResult | Record<EntityType, any>>
    >(promises);

    return results.reduce(
      (acc, result) => ({
        ...buildResponse(
          result.status === 'fulfilled',
          result.status === 'fulfilled' ? result.value : result.reason
        ),
        ...acc,
      }),
      {}
    );
  }
}
