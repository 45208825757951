import { format } from 'date-fns';
import { Grid } from '@wework/dieter-ui';

import { dateFormats } from 'lib/constants';

import { VisibilityUpdateOnType } from './types';

export const dateForStories = new Date('2019-10-23T12:00:00');

export const formatDateTime = (time: Date) => format(time, dateFormats.time_long);

export const cardVisibilityProps = {
  as: Grid.Row,
  updateOn: VisibilityUpdateOnType.REPAINT,
};
