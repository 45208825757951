import React, { useContext } from 'react';

export const ContainerRefContext = React.createContext<React.MutableRefObject<HTMLDivElement | null> | null>(
  null
);

export interface ContainerRefProps {
  containerRef: React.MutableRefObject<HTMLDivElement> | null;
}

export function withContainerRef<T extends ContainerRefProps = ContainerRefProps>(
  Component: React.ComponentType<T>
) {
  const WrappedComponent: React.FC<Optionalize<T, ContainerRefProps>> = (props: T) => {
    const containerRef = useContext(ContainerRefContext);
    return <Component {...props} containerRef={containerRef} />;
  };
  WrappedComponent.displayName = `withContainerRef(${Component.displayName})`;
  return WrappedComponent;
}
