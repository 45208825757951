import { Auth0ContextInterface } from '@auth0/auth0-react';

import config from 'config';

let authInstance;

// Throughout our app, the return type for authInstance.user seems to be User or Auth0User so removing type for now
export const getAuthInstance = (): Auth0ContextInterface<Auth0User> => authInstance;

export const setAuthInstance = (instance?: Auth0ContextInterface<Auth0User>): void => {
  authInstance = instance;
};

export const inIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
};

export const getRedirectUrl = (): string | undefined => {
  const { redirectorUri } = config.auth0;
  const { href, pathname } = window.location;

  if (inIframe()) {
    return;
  }

  // redirector uri is configured and the path contains a branch name
  if (!!redirectorUri && pathname !== '/') {
    const branchName = pathname.slice(1, pathname.length - 1); // remove the leading and trailing slashes
    return `${redirectorUri}?branchName=${branchName}`;
  }

  return href.split('#')[0];
};

export const getAccessToken = () => {
  const authInstance = getAuthInstance();
  if (authInstance) {
    return authInstance.getAccessTokenSilently();
  }

  // can this ever be reasonably null?
  return Promise.reject(new Error('Missing auth0 instance'));
};

export interface Auth0User {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  'https://wework.com/employee_uuid': string;
  'https://wework.com/member_uuid': string;
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export interface IEmployeeProfile {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  employeeUuid: string;
  memberUuid: string;
}

export const employeeProfileFromAuth0User = (user: Auth0User): IEmployeeProfile => ({
  email: user.email,
  firstName: user.given_name,
  lastName: user.family_name,
  name: user.name,
  employeeUuid: user['https://wework.com/employee_uuid'],
  memberUuid: user['https://wework.com/member_uuid'],
});
