import { createSelector } from 'reselect';

import { CurrentLocationSearchSubset } from 'features/locationPicker/ducks';
import { Location } from 'store/modules/siLocations/types';

const getCurrentLocationSearch = (state: CurrentLocationSearchSubset) =>
  state.currentLocationSearch.search;

export const getSearchLoading = createSelector(getCurrentLocationSearch, search => search.loading);

export const getSearchLoaded = createSelector(getCurrentLocationSearch, search => search.loaded);

export const getSearchResults = (state: CurrentLocationSearchSubset): Array<Location> =>
  state.currentLocationSearch.search.result;

export const getNearbyLocations = (state: CurrentLocationSearchSubset): Array<Location> =>
  state.currentLocationSearch.nearby.result;
