import config from 'config';
import { RequestActionTypes } from 'store/types';
import { createRequestAction } from 'store/util/createRequestAction';
import cc from 'store/util/createReduxConstant';
import { createRequestReducer } from 'store/util/createRequestReducer';

import { Filters } from '../config/types';
import { serializeSearchResult } from '../serializers/inventorySerializer';
import { InventorySearchResult, SearchResultPayload, InventoryErrorResponse } from '../types';
import SerializersMapWithItems from '../config/serializersMapWithItems';

// Action Constants
export const SEARCH_INVENTORY = cc('SEARCH_INVENTORY');
export const SEARCH_INVENTORY_SUCCESS = cc('SEARCH_INVENTORY_SUCCESS');
export const SEARCH_INVENTORY_FAIL = cc('SEARCH_INVENTORY_FAIL');

const types: RequestActionTypes = [
  SEARCH_INVENTORY,
  SEARCH_INVENTORY_SUCCESS,
  SEARCH_INVENTORY_FAIL,
];

export const initialResultState: InventorySearchResult = {
  inventory: [],
  inventoryGroups: [],
  metadata: {
    location: {
      availableCount: {},
    },
    pagination: {
      count: 0,
      countPerPage: 0,
      currentPageNumber: 0,
      totalPages: 0,
    },
  },
  rawPayload: {
    inventory: [],
  },
};

export const searchInventory = (filters: Filters, page: number | null | undefined) =>
  createRequestAction<SearchResultPayload | InventoryErrorResponse>({
    body: {
      ...filters,
      page,
    },
    endpoint: `${config.inventoryQuery.uri}/v1/inventory`,
    errorMessageFromJSON: (json: InventoryErrorResponse) => json.message,
    method: 'POST',
    types,
  });

const reducer = createRequestReducer<InventorySearchResult, SearchResultPayload>(
  types,
  initialResultState,
  res => serializeSearchResult(res?.payload || {}, SerializersMapWithItems)
);

export default reducer;
