import React from 'react';
import { animated, useTransition } from '@react-spring/web';
import { Button, IconName } from '@wework/ray2';
import { connect } from 'react-redux';

import { ReduxProps } from 'store/types';
import {
  dismissCurrentNotification,
  getRecentNotification,
} from 'features/notifications/ducks/notifications';
import { GlobalState } from 'store/modules';
import {
  DELAY,
  notificationAlertClassName,
  TRANSITION_TIMEOUT,
} from 'features/notifications/components/notificationAlert/constants';

import NotificationItem from '../notificationItem';

const mapStateToProps = (state: GlobalState) => ({
  recentNotification: getRecentNotification(state),
});

const mapDispatchToProps = { dismissCurrentNotification };

type Props = Readonly<ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>>;

const NotificationAlert = ({
  recentNotification,
  dismissCurrentNotification,
}: Props): JSX.Element | null => {
  const config = { tension: 125, friction: 20, precision: 0.1 };
  const animationConfig = {
    // eslint-disable-next-line id-length
    from: { life: '100%', y: 110, opacity: 0 },
    // eslint-disable-next-line id-length
    enter: { y: 0, opacity: 1, life: '0%' },
  };
  const transitions = useTransition(recentNotification, {
    ...animationConfig,
    // eslint-disable-next-line id-length
    leave: { opacity: 0, y: 220, life: '0%' },
    config: (_item, _index, phase) => key =>
      phase === 'enter' && key === 'life' ? { duration: TRANSITION_TIMEOUT } : config,
    onRest: (result, _spring, item) => {
      // prevent a race condition where the item would be dismissed at the relative same time a new notification appears,
      // causing the new notification to be canceled unintentionally
      window.setTimeout(() => {
        if (
          item?.reference_id === recentNotification?.reference_id &&
          !result.cancelled &&
          result.value.y === 0
        )
          dismissCurrentNotification();
      }, DELAY);
    },
    delay: DELAY,
  });

  return transitions((style, item) => {
    if (!item) {
      return null;
    }

    return (
      <animated.aside
        className={notificationAlertClassName}
        style={style}
        data-testid="notification-alert"
        role="status"
      >
        <div className="flex justify-between items-center p-xs">
          <p className="text-body !font-bold text-2xs leading-6 m-0">New notification</p>
          <Button
            className="!text-black"
            icon={IconName.CLOSE}
            onClick={() => {
              if (item.reference_id === recentNotification?.reference_id)
                dismissCurrentNotification();
            }}
            theme="text"
            size="small"
            aria-label="Close the Notification Alert"
          />
        </div>
        <NotificationItem notification={item} />
      </animated.aside>
    );
  });
};

export const TestableNotificationAlert = NotificationAlert;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAlert);
