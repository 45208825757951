import React, { useState } from 'react';
import cn from 'classnames';

import { createAvatarUrl, defaultAvatarUrl, sizes } from './constants';

export type UserInfo = {
  email?: string;
  id?: string;
};

export type AvatarSize = typeof sizes[keyof typeof sizes];

interface AvatarUrlParams {
  showDefaultAvatar?: boolean;
  imageUrl?: string;
  userInfo?: UserInfo;
}

type Props = Readonly<
  AvatarUrlParams & {
    buttonAriaLabel?: string;
    centered?: boolean;
    className?: string;
    customBorder?: string;
    customButtonBorder?: string;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    rounded?: boolean;
    size: AvatarSize;
  }
>;

export const getAvatarUrl = ({ showDefaultAvatar, imageUrl, userInfo }: AvatarUrlParams) => {
  if (showDefaultAvatar) {
    return defaultAvatarUrl;
  } else if (imageUrl) {
    return imageUrl;
  }
  return createAvatarUrl(userInfo);
};

export const isDefaultAvatar = (props: AvatarUrlParams) => getAvatarUrl(props) === defaultAvatarUrl;

const buttonClasses = `
  active:border-gray-90
  border-4 border-solid border-transparent
  focus-visible:outline-offset-1 focus-visible:outline-primaryblue-70
  hover:border-gray-95
`;

const Avatar = ({
  buttonAriaLabel,
  className,
  imageUrl,
  isActive,
  customBorder,
  customButtonBorder,
  onClick,
  rounded = true,
  showDefaultAvatar,
  size,
  userInfo,
}: Props) => {
  const [loadImageError, setLoadImageError] = useState(false);

  const getAvatarUrl = () => {
    const shouldShowDefault: boolean = showDefaultAvatar || loadImageError;
    if (shouldShowDefault) {
      return defaultAvatarUrl;
    } else if (imageUrl) {
      return imageUrl;
    }
    return createAvatarUrl(userInfo);
  };

  const avatar = (
    <img
      className={cn(
        'block w-full h-auto border border-solid',
        !customBorder && 'border-gray-80',
        customBorder,
        {
          'w-[32px] h-[32px] rounded': size === sizes.SMALL,
          'w-[40px] h-[40px] rounded': size === sizes.MEDIUM,
          'w-[64px] h-[64px] rounded': size === sizes.LARGE,
          'w-40 h-40 rounded': size === sizes.X_LARGE,
          '!rounded-full': rounded,
        },
        className
      )}
      src={getAvatarUrl()}
      onError={() => setLoadImageError(true)}
      alt={`${rounded ? 'member' : 'company'} avatar`}
      loading="lazy"
    />
  );

  const isButton = typeof onClick === 'function';

  return isButton ? (
    <button
      type="button"
      className={cn(buttonClasses, customButtonBorder, {
        '!border-gray-90': isActive,
        'rounded-full': rounded,
      })}
      onClick={onClick}
      aria-label={buttonAriaLabel ?? ''}
    >
      {avatar}
    </button>
  ) : (
    avatar
  );
};

export default Avatar;
