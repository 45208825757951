import { handleActions } from 'redux-actions';

import {
  CREATE_HOLDS,
  CREATE_HOLDS_SUCCESS,
  CREATE_HOLDS_FAIL,
  FETCH_HOLDS,
  FETCH_HOLDS_SUCCESS,
  FETCH_HOLDS_FAIL,
  UPDATE_CURRENT_PAGE,
} from 'features/accounts/holds/redux/constants';

interface State {
  byLocationUuid: {
    [uuid: string]: {
      meta: { page: number };
      pages: {
        [page: number]: any;
      };
    };
  };
  created: boolean;
  creating: boolean;
  page: number;
  error: boolean | null | undefined;
}

export interface HoldsSubset {
  holds: State;
}

// Initial State
const initialState: State = {
  created: false,
  creating: false,
  error: null,
  page: 0,
  byLocationUuid: {},
};

export const reducer = handleActions<State, any, { page: number }>(
  {
    [UPDATE_CURRENT_PAGE]: (state, action) => ({
      ...state,
      page: action.payload,
    }),

    [CREATE_HOLDS]: state => ({ ...state, creating: true, created: false }),
    [CREATE_HOLDS_SUCCESS]: state => ({ ...state, created: true, creating: false }),
    [CREATE_HOLDS_FAIL]: (state, action) => ({
      ...state,
      creating: false,
      created: false,
      error: action.payload,
    }),

    [FETCH_HOLDS]: (state, action) => ({
      ...state,
      loading: true,
      loaded: false,
      page: action.meta.page,
    }),

    [FETCH_HOLDS_SUCCESS]: (state, action) => {
      const locationUuid = action.payload.params.location_id;
      const holdsForLocation = state.byLocationUuid[locationUuid] || { pages: {} };

      return {
        ...state,
        loading: false,
        loaded: true,
        byLocationUuid: {
          ...state.byLocationUuid,
          [locationUuid]: {
            meta: action.meta,
            pages: {
              ...holdsForLocation.pages,
              [action.meta.page]: action.payload.result,
            },
          },
        },
      };
    },

    [FETCH_HOLDS_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
  },
  initialState
);

export default reducer;
