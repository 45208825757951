// Selectors are a pattern allowing you to compute derived data efficiently. Selectors are not recomputed
// unless any of its dependencies are updated.
//
// This replaces the antipattern of computing data inside the components and setting them as component state.
//
// Read more about selectors here: https://github.com/reactjs/reselect
//
import { createSelector } from 'reselect';
import { formValueSelector, getFormValues } from 'redux-form';
import {
  filter,
  find,
  findLastIndex,
  first,
  flatMap,
  get,
  groupBy,
  includes,
  keys,
  map,
  property,
  reduce,
  reject,
  sortBy,
  startsWith,
  union,
  uniq,
} from 'lodash';
import moment, { Moment } from 'moment-timezone';

import { PaperworkSubset } from 'features/paperwork/ducks/index';
import { ResourcesSubset } from 'features/companies/redux/resources/types';
import { GlobalState } from 'store/modules';
import {
  getAllLocationsForPrivateAccess,
  getNonMigratedLocations as getLocationsState,
} from 'store/selectors';
import config from 'config';
import { getCurrencySymbol, isNumber, deprecated_isPresent } from 'lib/util';
import {
  buildAllDiscounts,
  buildAllInitialTerms,
  buildSetupFees,
  calculateOriginalSetupFeeAmount,
  calculateTotalOfficePrice,
  getCommitments,
} from 'lib/discountModalUtil';
import { CommitmentBasedPromotion } from 'features/paperwork/discounts/commitmentBasedPromotions/types';
import Suggestion from 'features/paperwork/discounts/commitmentBasedPromotions/models/suggestion';
import { PendingReservation, PromotionModel, Reservation } from 'features/paperwork/types';
import { TerminationOption } from 'features/paperwork/earlyTermination/types';
import { Reservable as ActiveReservation } from 'features/companies/redux/primaryReservations/types';
import { DISCOUNT_TYPES, PRODUCTS } from 'features/paperwork/ducks/amendments/constants';
import { getExperiments } from 'store/middlewares/experimentsHelper';
import { Signatory } from 'features/paperwork/ducks/docusignInfo';
import {
  PaperworkLocale,
  PaperworkLocalesByLocale,
} from 'features/paperwork/ducks/paperworkLocales';
import { PendingMoveIn } from 'features/paperwork/ducks/pendingMoveIns';
import {
  GlobalAccessLocations,
  GlobalAccessLocation,
} from 'features/paperwork/ducks/globalAccessLocations/reducer';
// Simple selectors are pure functions that take two arguments:
//    state: the complete redux store
//    props: the components own props
//

export const getAllAvailableOfferings = (state: GlobalState) =>
  state.paperwork.commitmentBasedDiscounts.availableOfferings;
export const getAllAvailablePromotions = (state: GlobalState): Hash<Array<PromotionModel>> =>
  state.availablePromotions.availablePromotions;
export const getAllAvailableDiscountSuggestions = (state: GlobalState) =>
  state.paperwork.discountsApi.result;
export const getAllFetchingCommitmentBasedDiscounts = (state: GlobalState) =>
  state.paperwork.commitmentBasedDiscounts.loading;
export const getAllMonthToMonthOfferings = (state: GlobalState) =>
  state.paperwork.commitmentBasedDiscounts.monthToMonth;
export const getCommitmentBasedDiscountFetchErrors = (state: GlobalState) =>
  state.paperwork.commitmentBasedDiscounts.errors;
export const getCommitmentBasedPromotions = (state: GlobalState): Hash<CommitmentBasedPromotion> =>
  state.paperwork.commitmentBasedPromotions;
export const getDiscountAmounts = (state: GlobalState): Array<string> => {
  const formId = state.paperwork.discounts.storeKey;
  return formId ? formValueSelector(formId)(state, 'amounts') : [];
};
export const getDiscountInitialTerms = (state: GlobalState) => {
  const formId = state.paperwork.discounts.storeKey;
  return deprecated_isPresent(formId) ? formValueSelector(formId)(state, 'initialTerms') || 1 : 1;
};
export const getDiscountOverrideAmounts = (state: GlobalState): Hash<string | number> =>
  state.paperwork.commitmentBasedDiscounts.overrideAmounts;
export const getDiscountOverrideDurations = (state: GlobalState) =>
  state.paperwork.commitmentBasedDiscounts.overrideDurations;
export const getDiscountOverrides = (state: GlobalState) =>
  state.paperwork.commitmentBasedDiscounts.overrides;
export const getDiscountStoreKey = (state: GlobalState): string =>
  state.paperwork.discounts.storeKey;
export const getLocations = createSelector(
  [getLocationsState],
  locations => locations as GlobalAccessLocations
);
export const getPrivateAccessLocations = createSelector(
  [getAllLocationsForPrivateAccess],
  locations => locations as GlobalAccessLocations
);
export const getCurrentLocation = (
  state: GlobalState,
  locationUuid: string
): GlobalAccessLocation | undefined =>
  getLocations(state).find(({ uuid }) => uuid === locationUuid);
export const getSetupFees = (state: GlobalState) => state.paperwork.discounts.setupFees;
export const getPendingReservations = (
  _state: GlobalState,
  { pendingReservations }: { pendingReservations?: Hash<Array<PendingReservation>> }
): Hash<Array<PendingReservation>> => pendingReservations ?? {};
export const getPerpetuating = (state: GlobalState) => {
  const formId = state.paperwork.discounts.storeKey;
  return deprecated_isPresent(formId) ? formValueSelector(formId)(state, 'perpetuating') : false;
};
export const getProducts = (state: GlobalState) => state.paperwork.discounts.products;
export const getPromotionCodes = (state: GlobalState) => state.paperwork.discounts.promotionCodes;
export const getActiveReservations: (
  state: GlobalState,
  props: { activeReservations?: Array<ActiveReservation> }
) => Array<ActiveReservation> = (_state, { activeReservations }) => activeReservations ?? [];
export const getSelectedOfferings = (state: PaperworkSubset) =>
  state.paperwork.commitmentBasedDiscounts.selectedOfferings;
export const getSetupFeeResource = (state: ResourcesSubset) =>
  state.resources.byUuid[config.setupFeeResourceUuid];
export const getType = (state: PaperworkSubset) => {
  const formId = state.paperwork.discounts.storeKey;
  return deprecated_isPresent(formId) ? formValueSelector(formId)(state, 'type') : '';
};
export const getFinalAmounts = (props: { finalAmounts: Array<number> }) => props.finalAmounts;
export const getStartDate = (props: { startDate: string }) => props.startDate;
export const getOverlaps = (state: PaperworkSubset) =>
  state.paperwork.discounts.commitmentTermAndDiscountOverlaps;

export const getDiscountOverlaps = (state: PaperworkSubset) =>
  get(first(getOverlaps(state)), 'discounts', []);

export const getShouldSkipSignature = (state: PaperworkSubset) => {
  return !!state.paperwork.discounts.shouldSkipSignature;
};

export const getPrimaryReservations = (
  _state: GlobalState,
  props: { primaryReservations?: Array<Reservation> }
) => props.primaryReservations || [];
export const getPxweBuildings = (state: GlobalState) => state.pxweBuildings.data;
export const getCurrentAndPendingLocationIdsFromProps = (
  _state: GlobalState,
  props: { currentAndPendingLocationIds: Array<string> }
) => props.currentAndPendingLocationIds;

export const getPxWeResource = (state: GlobalState) => {
  return state.resources.byUuid[config.pxWeResourceUuid];
};

// Selectors can be composed. This selector is derived from the params and the locations
// selectors defined above. This will only be recomputed if either of the dependent selectors
// change.
export const getReservations = createSelector(
  getDiscountStoreKey,
  getPendingReservations,
  getActiveReservations,
  (
    storeKey: string,
    pendingReservations: Hash<Array<PendingReservation>>,
    activeReservations: Array<ActiveReservation>
  ): Array<ActiveReservation> | Array<PendingReservation> => {
    if (activeReservations.length > 0) {
      return activeReservations;
    }

    if (keys(pendingReservations).length > 0) {
      if (pendingReservations[storeKey] != null) {
        return pendingReservations[storeKey];
      }

      return pendingReservations[keys(pendingReservations)[0]];
    }

    return [] as Array<PendingReservation>;
  }
);

export const getSetupFeeByStoreKey = createSelector(
  [getDiscountStoreKey, getSetupFees],
  (storeKey, setupFees): number | null => setupFees?.[storeKey] ?? null
);

export const areSetupFeesValid = createSelector([getSetupFees], setupFees =>
  reduce(
    setupFees,
    (valid: boolean, setupFee: number | null | undefined) => valid && isNumber(setupFee),
    true
  )
);

export const getOriginalSetupFeeAmount = createSelector(
  [getReservations, getSetupFeeResource],
  (reservations, setupFeeResource) =>
    calculateOriginalSetupFeeAmount(reservations, setupFeeResource)
);

export const getPromotionCode = createSelector<GlobalState, string, Hash<string>, string>(
  [getDiscountStoreKey, getPromotionCodes],
  (storeKey, promotionCodes) => promotionCodes[storeKey]
);

export const getAvailablePromotions = createSelector(
  [getReservations, getAllAvailablePromotions],
  (reservations, availablePromotions): Array<PromotionModel> => {
    if (reservations.length === 0) {
      return [];
    }

    const uuid =
      (reservations[0] as PendingReservation).building_uuid ||
      (reservations[0] as ActiveReservation).location_uuid;

    if (uuid !== null) {
      return availablePromotions[uuid] || [];
    }

    return [];
  }
);

export const getLocale = createSelector(getReservations, reservations => {
  // Default to en-US if there are no reservations
  if (reservations.length === 0) {
    return 'en-US';
  }

  return (
    (reservations[0] as PendingReservation).locale ||
    (reservations[0] as ActiveReservation).location?.locale ||
    'en-US'
  );
});

export const getAvailablePromotionsByLocationUuid = (
  state: GlobalState,
  props: { locationUuid: string }
): Array<PromotionModel> => state.availablePromotions.availablePromotions[props.locationUuid] ?? [];

export const getPromotion = createSelector(
  [getAvailablePromotions, getPromotionCode],
  (availablePromotions: Array<PromotionModel>, code: string): PromotionModel | undefined =>
    availablePromotions.find(promo => promo.code === code)
);

export const getPromotionForNewRenewals = createSelector(
  [getAvailablePromotionsByLocationUuid, getPromotionCode],
  (availablePromotions: Array<PromotionModel>, code: string): PromotionModel | undefined =>
    availablePromotions.find(promo => promo.code === code)
);

export const getMoveIn = createSelector([getReservations], reservations => {
  if (reservations.length === 0) {
    return '';
  }

  if ((reservations[0] as PendingReservation).moveIn != null) {
    return (reservations[0] as PendingReservation).moveIn;
  }

  if ((reservations[0] as ActiveReservation).start_date != null) {
    return (reservations[0] as ActiveReservation).start_date;
  }

  return '';
});

export const getProrated = createSelector([getMoveIn], moveIn => moment(moveIn).date() > 1);

export const getNumericDiscountAmounts = createSelector(
  getDiscountAmounts,
  (amounts): Array<number> => map(amounts, amount => Number(amount) || 0)
);

export const getLastDiscount = createSelector(getNumericDiscountAmounts, (amounts): {
  amount: number;
  index: number;
} => {
  if (amounts.length === 0) {
    return { amount: 0, index: 0 };
  }

  const index: number = findLastIndex(amounts, (amount): boolean => amount > 0);

  return index >= 0
    ? { amount: amounts[index], index }
    : { amount: amounts[amounts.length - 1], index: amounts.length - 1 };
});

export const getProduct = createSelector<
  GlobalState,
  string,
  Hash<'custom' | 'standard'>,
  'custom' | 'standard'
>([getDiscountStoreKey, getProducts], (storeKey, products) => products[storeKey] || 'standard');

export const getCommitmentBasedDiscountFetchError = createSelector(
  [getDiscountStoreKey, getCommitmentBasedDiscountFetchErrors],
  (storeKey, commitmentBasedDiscountFetchErrors) =>
    commitmentBasedDiscountFetchErrors[storeKey] || false
);

export const getFetchingCommitmentBasedDiscounts = createSelector(
  [getDiscountStoreKey, getAllFetchingCommitmentBasedDiscounts],
  (storeKey, fetchingCommitmentBasedDiscounts) =>
    fetchingCommitmentBasedDiscounts[storeKey] || false
);

export const getAvailableOfferings = createSelector(
  [getDiscountStoreKey, getAllAvailableOfferings],
  (storeKey, availableOfferings) => availableOfferings[storeKey]
);

export const getSuggestions = createSelector(
  getAllAvailableDiscountSuggestions,
  promotionSuggestions => {
    return Object.keys(promotionSuggestions).reduce((suggestions: Hash<Array<Suggestion>>, key) => {
      const values = promotionSuggestions[key].promotionSuggestions ?? [];

      suggestions[key] = values.map(promotionSuggestion => new Suggestion(promotionSuggestion));

      return suggestions;
    }, {});
  }
);

export const getAllOffers = createSelector(
  getAllAvailableDiscountSuggestions,
  promotionSuggestions =>
    Object.keys(promotionSuggestions).reduce(
      (suggestions, key) => ({
        ...suggestions,
        [key]: promotionSuggestions[key].requests,
      }),
      {}
    )
);

export const getAvailableDiscountSuggestions = createSelector(
  [getDiscountStoreKey, getSuggestions],
  (storeKey, suggestions) => suggestions[storeKey] ?? []
);

export const getSortedAvailableDiscountSuggestions = createSelector(
  [getAvailableDiscountSuggestions],
  suggestions => sortBy(suggestions, 'commitmentLength')
);

export const getAvailableCustomDiscountSuggestions = createSelector(
  [getDiscountStoreKey, getAllAvailableDiscountSuggestions],
  (storeKey, discountSuggestions) => get(discountSuggestions[storeKey], 'customSuggestions', [])
);

export const getSuggestionsByGroupId = createSelector(
  getSortedAvailableDiscountSuggestions,
  suggestions => groupBy(suggestions, suggestion => suggestion.group().id)
);

export const getOffersByGroupId = createSelector(
  [getDiscountStoreKey, getAllAvailableDiscountSuggestions],
  (storeKey, discountSuggestions) => get(discountSuggestions[storeKey], 'requests', [])
);

export const getPercentDiscountSuggestions = createSelector(
  [getSortedAvailableDiscountSuggestions],
  discountSuggestions => filter(discountSuggestions, suggestion => suggestion.isDefault())
);

export const getMonthToMonthDiscountSuggestions = createSelector(
  [getAvailableDiscountSuggestions],
  discountSuggestions =>
    filter(discountSuggestions, obj => {
      return startsWith(obj.variant, 'default') && obj.code === 'MONTH_TO_MONTH';
    })
);

export const getMonthToMonthDiscountSuggestionsByStoreKey = createSelector(
  [getDiscountStoreKey, getAvailableDiscountSuggestions],
  (storeKey, discountSuggestions) => {
    const monthToMonth = filter(discountSuggestions, obj => {
      return startsWith(obj.variant, 'default') && obj.code === 'MONTH_TO_MONTH';
    });

    return { [storeKey]: monthToMonth };
  }
);

export const getGroupedMonthToMonthDiscountSuggestions = createSelector(
  [getMonthToMonthDiscountSuggestions],
  discountSuggestions => groupBy(discountSuggestions, 'commitmentLength')
);

export const getGroupedPercentDiscountSuggestions = createSelector(
  [getPercentDiscountSuggestions],
  discountSuggestions => groupBy(discountSuggestions, 'commitmentLength')
);

export const getFreeMonthDiscountSuggestions = createSelector(
  [getAvailableDiscountSuggestions],
  discountSuggestions =>
    filter(discountSuggestions, obj => {
      return startsWith(obj.variant, 'free_months');
    })
);

export const getGroupedFreeMonthDiscountSuggestions = createSelector(
  [getFreeMonthDiscountSuggestions],
  discountSuggestions => groupBy(discountSuggestions, 'commitmentLength')
);

// Custom Terms & Other Terms are the same thing
export const getOtherTermSuggestions = createSelector(
  getAllAvailableDiscountSuggestions,
  customSuggestions =>
    Object.keys(customSuggestions).reduce((suggestions, key) => {
      if (customSuggestions[key].customSuggestions) {
        return {
          ...suggestions,
          [key]: customSuggestions[key].customSuggestions.map(
            customSuggestion => new Suggestion(customSuggestion)
          ),
        };
      } else {
        return suggestions;
      }
    }, {})
);

export const getOtherTermAndMonthToMonthSuggestions = createSelector(
  [getDiscountStoreKey, getMonthToMonthDiscountSuggestionsByStoreKey, getOtherTermSuggestions],
  (storeKey, monthToMonth, otherTerms) => {
    const otherTermsForContract = otherTerms[storeKey] || [];
    const monthToMonthForContract = monthToMonth[storeKey] || [];
    return { [storeKey]: [...otherTermsForContract, ...monthToMonthForContract] };
  }
);

export const getAvailableOtherTermDiscountSuggestions = createSelector(
  [getDiscountStoreKey, getOtherTermAndMonthToMonthSuggestions],
  (storeKey, suggestions) => suggestions[storeKey] || []
);

export const getSortedAvailableOtherTermDiscountSuggestions = createSelector(
  [getAvailableOtherTermDiscountSuggestions],
  suggestions => sortBy(suggestions, 'commitmentLength')
);

export const getOtherTermSuggestionsByGroupId = createSelector(
  getSortedAvailableOtherTermDiscountSuggestions,
  suggestions => groupBy(suggestions, suggestion => suggestion.group().id)
);

export const getMonthToMonth = createSelector(
  [getDiscountStoreKey, getAllMonthToMonthOfferings],
  (storeKey, monthToMonth) => {
    return {
      errorMsg: get(monthToMonth, [storeKey, 'error_msg'], ''),
      maxDiscount: get(monthToMonth, [storeKey, 'max_discount'], 100),
      maxDiscountErrorMsg: get(monthToMonth, [storeKey, 'max_discount_error_msg'], ''),
      months: get(monthToMonth, [storeKey, 'duration'], 1),
      name: 'month to month',
      percentage: get(monthToMonth, [storeKey, 'percentage'], 0),
      tag: 'month_to_month',
    };
  }
);

export const getSelectedOffering = createSelector(
  [getDiscountStoreKey, getSelectedOfferings],
  (storeKey, selectedOfferings) =>
    selectedOfferings[storeKey]
      ? selectedOfferings[storeKey]
      : {
          months: 0,
          name: '',
          percentage: 0,
          tag: '',
        }
);

export const getInitialTerms = createSelector(
  [getProduct, getDiscountInitialTerms, getSelectedOffering],
  (product, initialTerms, selectedOffering) => {
    if (product === 'custom') {
      return initialTerms;
    } else if (
      product === 'standard' &&
      deprecated_isPresent(selectedOffering) &&
      selectedOffering.tag !== 'month_to_month'
    ) {
      return selectedOffering.months;
    }
    return 1;
  }
);

export const getDiscountOverride = createSelector(
  [getDiscountStoreKey, getDiscountOverrides],
  (storeKey, discountOverrides): boolean => discountOverrides[storeKey] || false
);

export const getDiscountOverrideAmount = createSelector(
  [getDiscountStoreKey, getDiscountOverrideAmounts],
  (storeKey, discountOverrideAmounts): number | null =>
    discountOverrideAmounts[storeKey] ? Number(discountOverrideAmounts[storeKey]) : 0
);

export const getDiscountOverrideDuration = createSelector(
  [getDiscountStoreKey, getDiscountOverrideDurations],
  (storeKey, discountOverrideDurations): string | null =>
    discountOverrideDurations[storeKey] ?? null
);

export const getCommitmentBasedDiscountAmount = createSelector(
  [getDiscountOverride, getDiscountOverrideAmount, getSelectedOffering],
  (override, overrideAmount, selectedOffering): number => {
    if (override) {
      return overrideAmount || 0;
    }
    return selectedOffering.percentage || 0;
  }
);

export const getCommitmentBasedDiscountDuration = createSelector(
  [getDiscountOverride, getDiscountOverrideDuration, getSelectedOffering],
  (override, overrideDuration, selectedOffering): number => {
    if (override && isNumber(overrideDuration)) {
      return Number(overrideDuration);
    }

    return Number(selectedOffering.months);
  }
);

export const getDiscount = createSelector(
  [getProduct, getLastDiscount, getType, getCommitmentBasedDiscountAmount],
  (
    product,
    lastDiscount,
    type,
    commitmentBasedDiscountAmount
  ): { isPercent: boolean; amount: number } => {
    if (product === 'custom') {
      const isPercent = type === 'percent';
      return { isPercent, amount: lastDiscount.amount };
    }
    if (product === 'standard') {
      return { isPercent: true, amount: commitmentBasedDiscountAmount };
    }
    return { isPercent: true, amount: 0 };
  }
);

export const getDuration = createSelector(
  [getProduct, getDiscountAmounts, getSelectedOffering],
  (product, discountAmounts, selectedOffering): number => {
    if (product === 'custom') {
      return discountAmounts ? discountAmounts.length : 0;
    }

    if (product === 'standard') {
      return selectedOffering?.months || 0;
    }

    return 0;
  }
);

export const getTotalOfficePrice = createSelector([getReservations], reservations =>
  calculateTotalOfficePrice(reservations)
);

export const getCurrency = createSelector(
  [getReservations], // getCurrencySymbol throws an error if it is passed an empty string
  reservations => {
    if (reservations == null || reservations.length === 0) {
      return '';
    }

    return reservations[0].currency ? getCurrencySymbol(reservations[0].currency) : '';
  }
);

export const getCommitmentDiscounts = createSelector(
  [
    getProduct,
    getNumericDiscountAmounts,
    getType,
    getPerpetuating,
    getSelectedOffering,
    getInitialTerms,
  ],
  (product, discountAmounts, type, perpetuating, selectedOffering, initialTerms) => {
    if (product === 'custom') {
      if (deprecated_isPresent(discountAmounts)) {
        return getCommitments(discountAmounts, initialTerms, perpetuating, type);
      }
      return Array(initialTerms).fill({ isPercent: true, amount: 0 });
    }
    return Array(initialTerms).fill({
      isPercent: true,
      amount: selectedOffering.percentage,
    });
  }
);

export const getIsPerpetuating = createSelector(
  [getProduct, getPerpetuating],
  (product, perpetuating) => (product === PRODUCTS.STANDARD ? false : perpetuating)
);

export const getAmounts = createSelector(
  [
    getProduct,
    getNumericDiscountAmounts,
    getCommitmentBasedDiscountAmount,
    getCommitmentBasedDiscountDuration,
  ],
  (product, amounts, commitmentBasedDiscountAmount, commitmentBasedDiscountDuration) => {
    if (product === 'standard') {
      return Array(commitmentBasedDiscountDuration).fill(commitmentBasedDiscountAmount);
    }
    if (deprecated_isPresent(amounts)) {
      return amounts;
    }
    return [];
  }
);

// Group promotions by amount so we have one entry for each contiguous duration
// Eg. [[70],[70],[70],[80]] becomes [[7,3],[8,1]]
// ($70 discount for 3 months followed by $80 discount for 1 month)
export const getGroupedCustomPromotionAmounts = createSelector(
  [getFinalAmounts],
  (amounts): Array<[number, number]> => {
    let counter = 1;
    const amountDurations: Array<[number, number]> = [];

    amounts.forEach((amount, index) => {
      if (index < amounts.length && amounts[index + 1] === amount) {
        counter += 1;
      } else {
        amountDurations.push([amount, counter]);
        counter = 1;
      }
    });

    return amountDurations;
  }
);

// Turn a grouped promotion array into a list of start & end dates
// Flow's mixed array type appears to be incompatible with Moment's moment$Moment type :-(
export const computeCustomPromotionDateRanges = createSelector(
  [getGroupedCustomPromotionAmounts, getStartDate],
  (groupedAmounts, startDate): Array<[Moment, Moment, number]> => {
    const results: Array<[Moment, Moment, number]> = [];

    let currentStartDate: Moment = moment(startDate);

    groupedAmounts.forEach(amountDuration => {
      const [amount, duration] = amountDuration;

      const nextStartDate = currentStartDate.clone().add(duration, 'months');
      const endDate = nextStartDate.clone().subtract(1, 'days');

      results.push([currentStartDate, endDate, amount]);

      currentStartDate = nextStartDate;
    });

    return results;
  }
);

// Return a list of date ranges & discount amounts
// eg. [[01-01-2018, 02-28-2018, -1000], [03-01-2018, 08-31-2018, -500]]
export const getDiscountDateRanges = createSelector([getDiscountOverlaps], discounts => {
  if (discounts.length < 1) {
    return [];
  }

  const results: Array<[Moment, Moment | null, number]> = [];

  discounts.sort((one, two): number => {
    if (moment(one.started_on).isAfter(moment(two.started_on))) {
      return 1;
    }
    return moment(one.started_on).isBefore(moment(two.started_on)) ? -1 : 0;
  });

  let startDate = discounts[0].started_on;
  let endDate;
  let price = discounts[0].price;

  discounts.forEach((discount, index) => {
    if (
      index <= discounts.length - 1 &&
      discount.price === price &&
      (!endDate || moment(discount.started_on).isSame(moment(endDate).add(1, 'days')))
    ) {
      endDate = discount.ended_on;
    } else {
      results.push([startDate, endDate ?? null, price]);

      startDate = discount.started_on;
      endDate = discount.ended_on;
      price = discount.price;
    }

    if (index >= discounts.length - 1) {
      results.push([startDate, discount.ended_on, price]);
    }
  });

  return results;
});

export const getCurrentAndPendingLocationIds = createSelector(
  [getPrimaryReservations],
  primaryReservations => {
    const currentReservations: Array<Reservation> = primaryReservations.filter(
      res => res.name === 'Current'
    );
    const pendingReservations: Array<Reservation> = primaryReservations.filter(
      res => res.name === 'Membership Agreement'
    );
    const reservations = reject(
      currentReservations,
      res =>
        !!res.reservables.find(reservable => reservable.reservation_uuid === res.code)?.end_date
    ); // make sure not to include already ended current reservations

    const currentLocationUuids = uniq(reservations.map(reservation => reservation.location.uuid));
    const pendingLocationUuids = uniq(
      pendingReservations.map(reservation => reservation.location.uuid)
    );
    const locationUuids = union(currentLocationUuids, pendingLocationUuids);
    return locationUuids;
  }
);

export const filterCurrentAndPendingLocationsFromBuildingOptions = createSelector(
  [getCurrentAndPendingLocationIdsFromProps, getPxweBuildings],
  (currentAndPendingLocationIds, pxweBuildings) => {
    if (!pxweBuildings) {
      return [];
    }

    return reject(pxweBuildings, building => includes(currentAndPendingLocationIds, building.id));
  }
);

export const getPromotionCodeForPayload = createSelector(
  [getProduct, getSelectedOffering, getPromotionCode],
  (product, selectedOffering, promotionCode) =>
    product === PRODUCTS.STANDARD ? selectedOffering.tag : promotionCode
);

export const getAmendmentType = createSelector([getProduct, getType], (product, type) =>
  product === PRODUCTS.STANDARD ? DISCOUNT_TYPES.PERCENT : type
);

export const getPrimaryMemberUuid = (
  _state: GlobalState,
  props: { company: { primary_member: { uuid: string } } }
) => props.company?.primary_member?.uuid;

const getPendingMoveIns = state => state.paperwork.pendingMoveIns;

export const getCountryInfo = state => ({
  countryCode: state.paperwork.pendingMoveIns[0]?.countryCode,
  country: state.paperwork.pendingMoveIns[0]?.country,
});

const getDiscounts = state => state.paperwork.discounts;

export const getOutOfPolicyDiscountApprover = state =>
  state.paperwork.discounts.outOfPolicyInfo?.approver?.email;
export const getOutOfPolicyInfo = state => state.paperwork.discounts.outOfPolicyInfo;
export const getOutOfPolicyDiscountRationale = state =>
  state.paperwork.discounts.outOfPolicyInfo?.rationale;
export const getUsingOutOfPolicyDiscountApprovalFlow = state =>
  state.paperwork.discounts.outOfPolicyInfo?.usingApprovalFlow;
const getCommitmentBasedDiscounts = state => state.paperwork.commitmentBasedDiscounts;

export const getPendingReservationsByContract = createSelector([getPendingMoveIns], moveIns =>
  groupBy(moveIns, move => `${move.buildingName.replace('.', '')} - ${move.moveIn}`)
);

/*
 *  This does not play nicely with locations with "." in their names
 *  because of how redux-form internal handles form names
 *  Which means we can't use reselect for this selector
 *
 *  const getForms = state => state.form;
 *  export const getDiscountForms = createSelector(
 *    [getPendingReservationsByContract, getForms],
 *    (pendingReservationsByContract, formsByKey) =>
 *      reduce(
 *        pendingReservationsByContract,
 *        (discountForms, _, storeKey) => ({
 *          ...discountForms,
 *          [storeKey]: formsByKey[storeKey]?.values,
 *        }),
 *        {}
 *      )
 *  );
 */
export const getDiscountForms = (state: GlobalState) => {
  const pendingReservationsByContract = getPendingReservationsByContract(state);

  return reduce(
    pendingReservationsByContract,
    (discountForms, _, storeKey) => ({
      ...discountForms,
      [storeKey]: getFormValues(storeKey)(state),
    }),
    {}
  );
};

export const getDiscountsV2Values = createSelector(
  [getDiscounts, getCommitmentBasedDiscounts, getDiscountForms, getPendingReservationsByContract],
  (discounts, commitmentBasedDiscounts, forms, pendingReservations) => {
    const { products, promotionCodes } = discounts;
    const {
      selectedOfferings,
      overrideAmounts,
      overrideDurations,
      overrides,
    } = commitmentBasedDiscounts;

    return buildAllDiscounts(
      forms,
      { selections: selectedOfferings, overrideAmounts, overrideDurations, overrides },
      products,
      pendingReservations,
      promotionCodes,
      true
    );
  }
);

// Used to put a default setup fee value in store
export const getDefaultSetupFees = createSelector(
  [getPendingReservationsByContract, getSetupFeeResource],
  (pendingReservations, setupFeeResource) => {
    return reduce(
      pendingReservations,
      (allSetupFees, reservations, storeKey) => {
        const originalAmount = calculateOriginalSetupFeeAmount(reservations, setupFeeResource);

        return {
          ...allSetupFees,
          [storeKey]: originalAmount,
        };
      },
      {}
    );
  }
);

export const getTransformedSetupFees = createSelector(
  [getSetupFees, getPendingReservationsByContract, getSetupFeeResource],
  (setupFees, pendingReservations, setupFeeResource) =>
    buildSetupFees(setupFees, pendingReservations, setupFeeResource)
);

export const getAllInitialTerms = createSelector(
  [getDiscountForms, getPendingReservationsByContract],
  (forms, pendingReservations) => buildAllInitialTerms(forms, pendingReservations)
);

export const getSfOpportunityExperiment = (state: GlobalState): string | null | undefined =>
  getExperiments(state).ssSfOpportunity;

export const isSfOpportunityExperimentOn = createSelector(
  getSfOpportunityExperiment,
  ssSfOpportunity => ssSfOpportunity === 'on'
);

export const getSfOpportunityAddOnsExperiment = (state: GlobalState): string | null | undefined =>
  getExperiments(state).ssSfOpportunityAddOns;

export const isSfOpportunityAddOnsExperimentOn = createSelector(
  getSfOpportunityAddOnsExperiment,
  ssSfOpportunityAddOns => ssSfOpportunityAddOns === 'on'
);

export const getEarlyTerminationOptions = (state: GlobalState): Hash<Array<TerminationOption>> =>
  // @ts-ignore
  state.paperwork.earlyTermination.saved;

// TODOEA: change to getDefaultDocusignSignatory
export const getDefaultSignatory = (state: GlobalState, companyUuid: string): Signatory | null => {
  const companies = state.companies;
  const primaryMember = companies.byUuid[companyUuid]?.primary_member;
  if (!primaryMember) {
    return null;
  }
  return {
    name: primaryMember.name,
    email: primaryMember.email,
  };
};

export const getDocusignSignatory = (state: GlobalState): Signatory | null => {
  return state.paperwork.docusignInfo.signatory;
};

export const getAuthorizedSignatoryInfo = (
  state: GlobalState,
  companyUuid: string
): Signatory | null => {
  const companies = state.companies;
  const companyInfo = companies.byUuid[companyUuid];
  if (companyInfo?.authorized_signatory_name && companyInfo?.authorized_signatory_email) {
    return {
      name: companyInfo.authorized_signatory_name,
      email: companyInfo.authorized_signatory_email,
    };
  }
  return null;
};

export const getDocusignPaperworkLocale = (state: GlobalState): PaperworkLocale | null => {
  return state.paperwork.docusignInfo.paperworkLocale;
};

export const getPendingLocales = createSelector(
  [getPendingMoveIns],
  (reservations: Array<PendingMoveIn>): Array<string> =>
    reservations.filter(res => !!res.locale).map(res => res.locale) as Array<string>
);

export const getPaperworkLocalesByLocale = (state: GlobalState): PaperworkLocalesByLocale | null =>
  state.paperwork.paperworkLocales?.result ?? null;

export const getPendingPaperworkLocales = createSelector(
  [getPaperworkLocalesByLocale, getPendingLocales],
  (byLocale: PaperworkLocalesByLocale, pendingLocales: Array<string>): Array<PaperworkLocale> => {
    return flatMap(pendingLocales, locale => byLocale[locale] || [{ locale, language: 'Default' }]);
  }
);

const custom = {
  path: 'form',
  propPath: 'values.initialTerms',
};

const standard = {
  path: 'paperwork.commitmentBasedPromotions',
  propPath: 'suggestion.commitmentLength',
};

type StandardPromotion = {
  suggestion: {
    commitmentLength: number;
  };
};

type CustomPromotion = {
  values: {
    duration: number;
  };
};

const extractCommitmentDetails = (state, promotionPath, propPath) => {
  const promotions: Array<StandardPromotion> | Array<CustomPromotion> = property<
    GlobalState,
    Array<StandardPromotion> | Array<CustomPromotion>
  >(promotionPath)(state);
  return reduce(
    promotions,
    (acc, promotion, storeKey: string) => {
      const commitmentLength = property(propPath)(promotion);

      if (!commitmentLength) {
        return acc;
      }

      return {
        ...acc,
        [storeKey]: {
          commitmentLength,
        },
      };
    },
    {}
  );
};

export const getCommitmentsBasedPromotions = (state: GlobalState) => ({
  ...extractCommitmentDetails(state, standard.path, standard.propPath),
  ...extractCommitmentDetails(state, custom.path, custom.propPath),
});

const getEarlyTerminationStoreKey = (_state: GlobalState, { storeKey }) => storeKey;

export const getEarlyTerminationCurrencySymbol = createSelector(
  [getEarlyTerminationStoreKey, getPendingMoveIns],
  (storeKey: string, pendingReservations: Array<Reservation>) => {
    const currentReservation = find<Reservation>(
      pendingReservations,
      (reservation: Reservation) =>
        `${reservation.buildingName} - ${reservation.moveIn}` === storeKey
    );
    return currentReservation ? getCurrencySymbol(currentReservation.currency) : '';
  }
);

export const getEarlyTerminationCurrencyCode = createSelector(
  [getEarlyTerminationStoreKey, getPendingMoveIns],
  (storeKey: string, pendingReservations: Array<Reservation>) => {
    const currentReservation = find<Reservation>(
      pendingReservations,
      (reservation: Reservation) =>
        `${reservation.buildingName} - ${reservation.moveIn}` === storeKey
    );
    return currentReservation ? currentReservation.currency : null;
  }
);

export const getCurrencyByReservations = (state: GlobalState) =>
  keys(state.paperwork.earlyTermination.saved).reduce<Hash<string | null>>(
    (acc, storeKey) => ({
      ...acc,
      [storeKey]: getEarlyTerminationCurrencyCode(state, { storeKey }),
    }),
    {}
  );

export const getEarlyTerminationMoveInDate = createSelector(
  [getEarlyTerminationStoreKey, getPendingMoveIns],
  (storeKey: string, pendingReservations: Array<Reservation>) => {
    const currentReservation = find<Reservation>(
      pendingReservations,
      (reservation: Reservation) =>
        `${reservation.buildingName} - ${reservation.moveIn}` === storeKey
    );

    return currentReservation ? currentReservation.moveIn : '';
  }
);

export const getCurrentOpportunity = (state: PaperworkSubset) => state.paperwork.sfOpportunity;

export const getCurrentServiceCloudNumber = (state: PaperworkSubset) =>
  state.paperwork.agreementManagement.serviceCloudNumber;
