import { handleActions } from 'redux-actions';

import config from 'config';
import { BaseAction, ActionWithPayload } from 'store/types';
import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';

export const FETCH_OFFICE_HOLD_CONTACT = cc('FETCH_OFFICE_HOLD_CONTACT');
export const FETCH_OFFICE_HOLD_CONTACT_SUCCESS = cc('FETCH_OFFICE_HOLD_CONTACT_SUCCESS');
export const FETCH_OFFICE_HOLD_CONTACT_FAIL = cc('FETCH_OFFICE_HOLD_CONTACT_FAIL');

export interface State {
  contactName: string | null;
  contactEmail: string | null;
  error: boolean | null | undefined;
}

export interface OfficeHoldsV2Subset {
  officeHoldsV2: State;
}

type OfficeHoldActionWithPayload = {
  office_hold: {
    contact_name: string;
    contact_email: string;
  };
};

const initialState: State = {
  contactName: null,
  contactEmail: null,
  error: null,
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_OFFICE_HOLD_CONTACT]: (state: State): State => ({
      ...state,
    }),

    [FETCH_OFFICE_HOLD_CONTACT_SUCCESS]: (
      state: State,
      action: ActionWithPayload<OfficeHoldActionWithPayload>
    ): State => ({
      ...state,
      contactName: action.payload.office_hold.contact_name,
      contactEmail: action.payload.office_hold.contact_email,
    }),

    [FETCH_OFFICE_HOLD_CONTACT_FAIL]: (state: State, action: BaseAction): State => ({
      ...state,
      error: action.error,
    }),
  },
  initialState
);

// Action Creators
export const fetchOfficeHoldData = (officeHoldID: string, includeContactInfo: boolean) =>
  createRequestAction({
    method: 'GET',
    endpoint: `${
      config.salesAPI.uri
    }/v2/office_holds/${officeHoldID}?include_contact_information=${includeContactInfo.toString()}`,
    types: [
      FETCH_OFFICE_HOLD_CONTACT,
      FETCH_OFFICE_HOLD_CONTACT_SUCCESS,
      FETCH_OFFICE_HOLD_CONTACT_FAIL,
    ],
  });

export default reducer;
