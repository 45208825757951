import React from 'react';
import cn from 'classnames';
import { Icon, IconProps } from '@wework/ray2';
import { NavLink } from 'react-router-dom';
import { noop } from 'lodash';

export type SideNavHeaderProps = {
  hidden?: boolean;
  className?: string;
};

export type SideNavGroupProps = {
  text: string;
  icon?: IconProps;
  hidden?: boolean;
  className?: string;
};

export type SideNavItemProps = {
  text: string;
  url?: string;
  hidden?: boolean;
  onClick?: () => void;
  className?: string;
};

export type SideNavProps = {
  hidden?: boolean;
  className?: string;
};

export const SideNavHeader: React.FC<React.PropsWithChildren<SideNavHeaderProps>> = ({
  hidden = false,
  className,
  children,
}) => <div className={cn('bg-gray-90', className, { hidden })}>{children}</div>;

export const SideNavGroup: React.FC<React.PropsWithChildren<SideNavGroupProps>> = ({
  text,
  icon,
  hidden = false,
  className,
  children,
}) => {
  return (
    <div className={cn('pt-sm mt-2xs flex flex-col', className, { hidden })}>
      <div className="px-md pb-2xs pt-3xs mb-4xs flex items-center">
        {icon && <Icon {...icon} />}
        <div className="text-3xs text-gray-40 font-main font-bold ml-xs">{text}</div>
      </div>
      {children}
    </div>
  );
};

export const SideNavItem: React.FC<SideNavItemProps> = ({
  text,
  url = '',
  hidden = false,
  onClick = noop,
  className,
}) => {
  return (
    <NavLink
      to={url}
      className={cn(
        'font-main flex-1 text-3xs text-gray-40 hover:text-gray-40 hover:bg-gray-90 pl-xl pr-md py-3xs mt-3xs',
        className,
        { hidden }
      )}
      activeClassName="!bg-secondary !text-primary"
      onClick={onClick}
    >
      <div className="pl-3xs">{text}</div>
    </NavLink>
  );
};

const SideNav: React.FC<React.PropsWithChildren<SideNavProps>> = ({
  hidden = false,
  className,
  children,
}) => {
  return (
    <nav className={cn('flex flex-col bg-gray-97 h-full', className, { hidden })}>{children}</nav>
  );
};

export default SideNav;
