import { map, get, compact, values } from 'lodash';

import { EntitySubset } from 'store/util/createEntity/reducer';
import { Status } from 'store/util/createEntity/reducers/status';

export const getAllEntity = (entityName: string) => () => (state: EntitySubset) => {
  return values(get(state.entity, `${entityName}.data`, {}));
};

export const getEntityById = (entityName: string) => (id: string | null | undefined) => (
  state: EntitySubset
) => (id ? state.entity?.[entityName]?.data?.[id] ?? '' : '');

export const getEntityByKey = (entityName: string) => (key: string | null | undefined) => (
  state: EntitySubset
) => {
  const entity = get(state.entity, entityName);
  // $FlowFixMe TODO (kangax) issues in compact and generics
  return compact(map(get(entity, `lookup.${key}`), id => get(entity, `data.${id}`)));
};

export const getEntityStatusByAction = (entityName: string) => (actionName: string) => (
  state: EntitySubset
) => {
  return state.entity?.[entityName]?.status?.[actionName] ?? {};
};

export const getEntityStatusByKey = (entityName: string) => (key: string) => (
  state: EntitySubset
): Status => {
  return (
    state.entity?.[entityName]?.status?.query?.[key] ?? {
      loaded: false,
      loading: false,
      error: null,
    }
  );
};

export const getEntityStatusByActionAndId = (entityName: string) => (
  actionName: string,
  id: string | null | undefined
) => (state: EntitySubset): Status =>
  id ? state.entity?.[entityName]?.status?.[actionName]?.[id] ?? {} : {};

export const getEntityErrorByActionAndId = (entityName: string) => (
  actionName: string,
  id: string | null | undefined
) => (state: EntitySubset) => {
  return id ? state.entity?.[entityName]?.status?.[actionName]?.[id]?.error ?? null : null;
};
