import { handleActions, createAction } from 'redux-actions';
import { get } from 'lodash';

import config from 'config';
import { RSAAType, GetGlobalState, Dispatch, ActionWithPayload } from 'store/types';
import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';
import { getDefaultSetupFees } from 'features/paperwork/ducks/selectors';

// Action names
export const CLEAR_CURRENT = cc('CLEAR_CURRENT');
export const RESET_DISCOUNTS = cc('RESET_DISCOUNTS');
export const SET_INITIAL_TERMS = cc('SET_INITIAL_TERMS');
export const SET_PRISTINE_STATE = cc('SET_PRISTINE_STATE');
export const SET_PRODUCT = cc('SET_PRODUCT');
export const SET_PROMOTION_CODE = cc('SET_PROMOTION_CODE');
export const SET_START_DATE = cc('SET_START_DATE');
export const SET_STORE_KEY = cc('SET_STORE_KEY');
export const SET_DISCOUNT_REASONS = cc('SET_DISCOUNT_REASONS');
export const SUBMIT_DISCOUNTS = cc('SUBMIT_DISCOUNTS');
export const SUBMIT_DISCOUNTS_SUCCESS = cc('SUBMIT_DISCOUNTS_SUCCESS');
export const SUBMIT_DISCOUNTS_FAIL = cc('SUBMIT_DISCOUNTS_FAIL');
export const SUBMIT_CHECK_OVERLAPS = cc('SUBMIT_CHECK_OVERLAPS');
export const SUBMIT_CHECK_OVERLAPS_SUCCESS = cc('SUBMIT_CHECK_OVERLAPS_SUCCESS');
export const SUBMIT_CHECK_OVERLAPS_FAIL = cc('SUBMIT_CHECK_OVERLAPS_FAIL');
export const TOGGLE_SHOULD_SKIP_SIGNATURE = cc('TOGGLE_SHOULD_SKIP_SIGNATURE');
export const SET_DEFAULT_SETUP_FEES = cc('SET_DEFAULT_SETUP_FEES');
export const EDIT_SETUP_FEE = cc('EDIT_SETUP_FEE');
export const SET_OUT_OF_POLICY_APPROVER = cc('SET_OUT_OF_POLICY_APPROVER');
export const SET_OUT_OF_POLICY_RATIONALE = cc('SET_OUT_OF_POLICY_RATIONALE');
export const SET_OUT_OF_POLICY_DISCOUNT_INFORMATION = cc('SET_OUT_OF_POLICY_INFORMATION');

export interface State {
  commitmentTermAndDiscountOverlaps: Array<any>;
  errors: Hash<boolean | null | undefined>;
  initialTerms: object;
  setupFees: Hash<number>;
  pristineState: State | null;
  products: Hash<'standard' | 'custom'>;
  promotionCodes: Hash<string>;
  startDates: object;
  storeKey: string;
  submitting: object;
  discountReasons: object;
  shouldSkipSignature: boolean;
  outOfPolicyInfo: object;
}

// Initial state - startDate is a string (instead of a moment object) since
// moment objects can be mutated outside of a reducer action.
export const initialState: State = {
  commitmentTermAndDiscountOverlaps: [],
  errors: {},
  initialTerms: {},
  setupFees: {},
  pristineState: null,
  products: {},
  promotionCodes: {},
  startDates: {},
  storeKey: '',
  submitting: {},
  discountReasons: {},
  shouldSkipSignature: false,
  outOfPolicyInfo: {},
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [CLEAR_CURRENT]: state => {
      if (state.pristineState === null) {
        return {
          ...initialState,
        };
      }
      return {
        ...state.pristineState,
      };
    },
    [SET_OUT_OF_POLICY_APPROVER]: (state, action) => ({
      ...state,
      outOfPolicyInfo: {
        ...state.outOfPolicyInfo,
        approver: {
          email: action.payload.outOfPolicyApprover,
        },
        usingApprovalFlow: true,
      },
    }),
    [SET_OUT_OF_POLICY_RATIONALE]: (state, action) => ({
      ...state,
      outOfPolicyInfo: {
        ...state.outOfPolicyInfo,
        rationale: action.payload.outOfPolicyRationale,
      },
    }),
    [SET_OUT_OF_POLICY_DISCOUNT_INFORMATION]: (state, action) => ({
      ...state,
      outOfPolicyInfo: {
        ...state.outOfPolicyInfo,
        reservable_discounts: action.payload.outOfPolicyDiscountInformation,
      },
    }),
    [SET_DEFAULT_SETUP_FEES]: (state, action) => ({
      ...state,
      setupFees: action.payload,
    }),
    [EDIT_SETUP_FEE]: (state, action) => ({
      ...state,
      setupFees: {
        ...state.setupFees,
        [state.storeKey]: action.payload.amount,
      },
    }),
    [RESET_DISCOUNTS]: () => ({ ...initialState }),
    [SET_INITIAL_TERMS]: (state, action) => ({
      ...state,
      initialTerms: {
        ...state.initialTerms,
        [state.storeKey]: action.payload.initialTerms,
      },
    }),
    [SET_PRISTINE_STATE]: state => ({
      ...state,
      pristineState: { ...state },
    }),
    [SET_PRODUCT]: (state, action) => ({
      ...state,
      products: {
        ...state.products,
        [state.storeKey]: action.payload?.product,
      },
    }),
    [SET_PROMOTION_CODE]: (state, action) => ({
      ...state,
      promotionCodes: {
        ...state.promotionCodes,
        [state.storeKey]: action.payload.promotionCode,
      },
    }),
    [SET_START_DATE]: (state, action) => ({
      ...state,
      startDates: {
        ...state.startDates,
        [state.storeKey]: action.payload.startDate,
      },
    }),
    [SET_STORE_KEY]: (state, action) => ({
      ...state,
      storeKey: action.payload.storeKey,
    }),
    [SET_DISCOUNT_REASONS]: (state, action) => ({
      ...state,
      discountReasons: action.payload.discountReasons,
    }),
    [SUBMIT_DISCOUNTS]: state => ({
      ...state,
      errors: {
        ...state.errors,
        [state.storeKey]: null,
      },
      submitting: {
        ...state.submitting,
        [state.storeKey]: true,
      },
    }),
    [SUBMIT_DISCOUNTS_FAIL]: (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        [state.storeKey]: action.error,
      },
      submitting: {
        ...state.submitting,
        [state.storeKey]: false,
      },
    }),
    [SUBMIT_DISCOUNTS_SUCCESS]: state => ({
      ...state,
      submitting: {
        ...state.submitting,
        [state.storeKey]: false,
      },
    }),
    [SUBMIT_CHECK_OVERLAPS]: state => ({
      ...state,
      errors: {
        ...state.errors,
        [state.storeKey]: null,
      },
      submitting: {
        ...state.submitting,
        [state.storeKey]: true,
      },
    }),
    [SUBMIT_CHECK_OVERLAPS_FAIL]: (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        [state.storeKey]: action.error,
      },
      submitting: {
        ...state.submitting,
        [state.storeKey]: false,
      },
    }),
    [SUBMIT_CHECK_OVERLAPS_SUCCESS]: (state, action) => ({
      ...state,
      submitting: {
        ...state.submitting,
        [state.storeKey]: false,
      },
      commitmentTermAndDiscountOverlaps: get(action.payload, 'result', []),
    }),
    [TOGGLE_SHOULD_SKIP_SIGNATURE]: state => ({
      ...state,
      shouldSkipSignature: !state.shouldSkipSignature,
    }),
  },
  initialState
);

// Action creators
export const clearCurrent = createAction(CLEAR_CURRENT);

export const editSetupFee = createAction(EDIT_SETUP_FEE, amount => ({ amount }));

export const resetDiscounts = createAction(RESET_DISCOUNTS);

export const setInitialTerms = createAction(SET_INITIAL_TERMS, initialTerms => ({
  initialTerms,
}));

export const setPristineState = createAction(SET_PRISTINE_STATE);

export const setProduct = createAction(SET_PRODUCT, (product: 'standard' | 'custom') => ({
  product,
}));

export const setPromotionCode = createAction(SET_PROMOTION_CODE, promotionCode => ({
  promotionCode,
}));

export const setOutOfPolicyRationale = createAction(
  SET_OUT_OF_POLICY_RATIONALE,
  outOfPolicyRationale => ({
    outOfPolicyRationale,
  })
);

export const setOutOfPolicyApprover = createAction(
  SET_OUT_OF_POLICY_APPROVER,
  outOfPolicyApprover => ({
    outOfPolicyApprover,
  })
);

export const setOutOfPolicyDiscountInformation = createAction(
  SET_OUT_OF_POLICY_DISCOUNT_INFORMATION,
  outOfPolicyDiscountInformation => ({
    outOfPolicyDiscountInformation,
  })
);

export const setStartDate = createAction(SET_START_DATE, startDate => ({ startDate }));

export const setStoreKey = createAction(SET_STORE_KEY, storeKey => ({ storeKey }));

export const setDiscountReasons = createAction(SET_DISCOUNT_REASONS, discountReasons => ({
  discountReasons,
}));

export const toggleShouldSkipSignature = createAction(TOGGLE_SHOULD_SKIP_SIGNATURE);

export const setDefaultSetupFees = () => (
  dispatch: Dispatch<ActionWithPayload<any>>,
  getState: GetGlobalState
) => dispatch({ type: SET_DEFAULT_SETUP_FEES, payload: getDefaultSetupFees(getState()) });

export const submitDiscounts = (payload: any): RSAAType => {
  const endpointBasic = `${config.spaceman.uri}/api/v2/membership_agreements`;
  const endpointForPDF = `${config.spaceman.uri}/api/v2/membership_agreements/create_and_move_in`;
  const endpoint = payload?.signed_pdf_url ? endpointForPDF : endpointBasic;

  return createRequestAction({
    endpoint,
    method: 'POST',
    body: payload,
    types: [SUBMIT_DISCOUNTS, SUBMIT_DISCOUNTS_SUCCESS, SUBMIT_DISCOUNTS_FAIL],
    getErrorMessageFromResponse: (_res, jsonRes) => jsonRes.result.error,
  });
};

export const submitCheckOverlaps = payload => {
  const endpoint = `${config.spaceman.uri}/api/v2/membership_agreements/overlapping_terms_and_discounts`;

  return createRequestAction({
    endpoint,
    method: 'POST',
    body: payload,
    types: [SUBMIT_CHECK_OVERLAPS, SUBMIT_CHECK_OVERLAPS_SUCCESS, SUBMIT_CHECK_OVERLAPS_FAIL],
    getErrorMessageFromResponse: (_res, jsonRes) => jsonRes.result.error,
  });
};

export default reducer;
