import React from 'react';
import cn from 'classnames';

import styles from './label.scss';

type Props = Readonly<{
  children: React.ReactNode;
  size?: 'tiny' | 'small' | 'medium';
  htmlFor?: string;
  inline?: boolean;
  required?: boolean;
  fontSize?: number;
  fontWeight?: string | number;
}>;

const Label = ({ children, size, htmlFor, inline, required, fontSize, fontWeight }: Props) => (
  <label
    htmlFor={htmlFor}
    className={cn(styles.base, size && styles[`size-${size}`], {
      [styles.inline]: inline,
    })}
    style={{ fontSize, fontWeight: fontWeight ? +fontWeight : undefined }}
  >
    {children} {required && '*'}
  </label>
);

export default Label;
