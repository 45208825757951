import React from 'react';
import { Icon as RayIcon, IconSize } from '@wework/ray2';
import { compose } from 'redux';

import Tooltip, { TooltipPlacement } from 'components-ray/tooltip';

import { MemberNotificationAction } from './types';

type Props = Readonly<{
  action: MemberNotificationAction;
  onClick: (event: React.MouseEvent) => void;
  hidden?: boolean;
}>;

const MemberNotificationActionItem: React.FC<Props> = ({ action, onClick }) => (
  <div>
    <Tooltip className="z-[30]" placement={TooltipPlacement.TOP} tooltip={action.title}>
      <RayIcon
        className="text-primary cursor-pointer mr-md last:mr-sm"
        icon={action.rayIconSource}
        onClick={onClick}
        size={IconSize.SMALL}
      />
    </Tooltip>
  </div>
);

export default compose()(MemberNotificationActionItem);
