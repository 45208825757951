import cn from 'classnames';
import React from 'react';

import styles from './searchDropdown.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
}

export default function SearchDropdown({ children, className, isOpen }: Props) {
  const classes = cn(className, styles.dropdown, '!shadow', {
    [styles.dropdownOpen]: isOpen,
  });

  return (
    <>
      {isOpen && (
        <div className="fixed flex flex-col justify-start max-h-[calc(100vh-8px)] top-0 left-[15.8rem] right-8 px-0">
          <div className={classes}>{isOpen ? children : null}</div>
        </div>
      )}
    </>
  );
}

SearchDropdown.defaultProps = {
  isOpen: false,
};
