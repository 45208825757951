import {
  isBefore,
  isAfter,
  max,
  min,
  parseISO,
  addDays,
  formatISO,
  eachMonthOfInterval,
  isFirstDayOfMonth,
} from 'date-fns';

import { parseDateInUTC } from 'lib/util';
import { DiscountInput, CommitmentTermInput } from 'features/paperwork/contracts-flow/types';
import { byDateProp } from 'features/paperwork/contracts-flow/sections/product/utils';

export enum DATE_ERROR_MSG {
  START_DATE_AFTER_END_DATE = 'Start Date cannot be after End Date',
  OVERLAPPING_DATES = 'Discounts cannot have overlapping dates',
  START_DATE_REQUIRED = 'Start Date is required',
  END_DATE_REQUIRED = 'End Date is required',
}

export enum AMOUNT_ERROR_MESSAGES {
  NEGATIVE_AMOUNT = 'Amount must be greater than or equal to 0',
  EXCEDES_GROSS_MEMBERSHIP_FEE = 'Amount cannot exceed the gross membership fee',
  EXCEDES_MAX_RECOMENDATION = 'You have exceeded the max recommended discount',
}

const validateStartDate = (
  { startDate, endDate }: { startDate: string; endDate: string },
  prev: DiscountInput,
  next: DiscountInput
) => {
  if (isAfter(parseISO(startDate), parseISO(prev.endDate))) {
    if (endDate) {
      if (!isBefore(parseISO(startDate), min([parseISO(endDate), parseISO(next.startDate)]))) {
        return DATE_ERROR_MSG.OVERLAPPING_DATES;
      }
      return;
    }
    return;
  }
  return DATE_ERROR_MSG.OVERLAPPING_DATES;
};

const validateEndDate = (
  { startDate, endDate }: { startDate: string; endDate: string },
  prev: DiscountInput,
  next: DiscountInput
) => {
  if (isAfter(parseISO(endDate), max([parseISO(startDate), parseISO(prev.endDate)]))) {
    if (!isBefore(parseISO(endDate), parseISO(next.startDate))) {
      return DATE_ERROR_MSG.OVERLAPPING_DATES;
    }
    return;
  }
  return DATE_ERROR_MSG.OVERLAPPING_DATES;
};

const addDaysToISO = (date, days) =>
  formatISO(addDays(parseISO(date), days), { representation: 'date' });

export const getDiscountDateErrors = (
  option: DiscountInput,
  idx: number,
  discountOptions: Array<DiscountInput>,
  term: CommitmentTermInput
) => {
  const prev = discountOptions[idx - 1] || {
    endDate: addDaysToISO(term.startDate.value, -1),
  };
  const next = discountOptions[idx + 1] || {
    startDate: addDaysToISO(term.endDate.value, 1),
  };
  let startDate;
  let endDate;

  if (option.startDate) {
    startDate = validateStartDate(option, prev, next);
  } else {
    startDate = DATE_ERROR_MSG.START_DATE_REQUIRED;
  }

  if (option.endDate) {
    endDate = validateEndDate(option, prev, next);
  } else {
    endDate = DATE_ERROR_MSG.END_DATE_REQUIRED;
  }

  return {
    startDate,
    endDate,
  };
};

const isNegativeAmount = amount => amount < 0;

const isAboveOfficePrice = (amount, officePrice) => amount > officePrice;

const isAboveMaxRecomendation = (amount, accumulatedAmount, optionLength, maxRecomendation) =>
  Math.round(accumulatedAmount + amount * optionLength) > Math.round(maxRecomendation);

export const getDiscountsAmountErrors = (
  discounts: Array<DiscountInput>,
  maxRecomendation,
  officePrice
) => {
  let amount = 0;
  let outOfPolicy;

  return discounts.reduce<Array<DiscountInput>>((acc, option) => {
    const optionLength =
      option.startDate &&
      option.endDate &&
      isBefore(parseDateInUTC(option.startDate), parseDateInUTC(option.endDate))
        ? eachMonthOfInterval({
            start: parseISO(option.startDate),
            end: parseISO(option.endDate),
          }).length
        : 0;
    let amountError;
    if (isNegativeAmount(option.amount)) {
      amountError = AMOUNT_ERROR_MESSAGES.NEGATIVE_AMOUNT;
    }
    if (isAboveMaxRecomendation(option.amount, amount, optionLength, maxRecomendation)) {
      outOfPolicy = AMOUNT_ERROR_MESSAGES.EXCEDES_MAX_RECOMENDATION;
    }
    if (isAboveOfficePrice(option.amount, officePrice)) {
      amountError = AMOUNT_ERROR_MESSAGES.EXCEDES_GROSS_MEMBERSHIP_FEE;
    }

    const validatedOption = {
      ...option,
      errors: {
        ...option.errors,
        amount: amountError,
        outOfPolicy,
      },
    };
    acc.push(validatedOption);
    amount += option.amount * optionLength;
    return acc;
  }, []);
};

export const validateDiscounts = (
  discountOptions: Array<DiscountInput>,
  key,
  term: CommitmentTermInput,
  officePrice: number,
  maxDiscount: number
) => {
  const maxRecomendation = maxDiscount * officePrice * term.length.value;
  const { discountsToValidate, untouchedDiscounts } = discountOptions.reduce<{
    discountsToValidate: Array<DiscountInput>;
    untouchedDiscounts: Array<DiscountInput>;
  }>(
    (acc, discount) => {
      if ((term.id && discount.termId === term.id) || discount.key === key) {
        acc.discountsToValidate.push(discount);
      } else {
        acc.untouchedDiscounts.push(discount);
      }
      return acc;
    },
    {
      discountsToValidate: [],
      untouchedDiscounts: [],
    }
  );

  const dateValidatedDiscounts = discountsToValidate
    .sort(byDateProp('startDate'))
    .map((option, idx, sortedOptions) => {
      if (option.startDate && !isFirstDayOfMonth(parseISO(option.startDate))) {
        return option;
      }
      return {
        ...option,
        errors: getDiscountDateErrors(option, idx, sortedOptions, term),
      };
    });

  return untouchedDiscounts.concat(
    getDiscountsAmountErrors(dateValidatedDiscounts, maxRecomendation, officePrice)
  );
};

export const validateTerm = (term, moveInDate) => {
  let startDate;
  if (term.startDate.value) {
    if (!isFirstDayOfMonth(parseISO(term.startDate.value))) {
      startDate = 'CT must start on the 1st day of the month';
    }
    if (isBefore(parseISO(term.startDate.value), parseISO(moveInDate))) {
      startDate = 'CT should not start before Move-in Date';
    }
  } else {
    startDate = 'Start Date is required';
  }
  return {
    ...term,
    errors: {
      startDate,
    },
  };
};
