import { Moment } from 'moment-timezone';

import config from 'config';
import ApiRequest from 'lib/api/apiRequest';

export default class WelkioAuthProxy extends ApiRequest {
  constructor() {
    super(config.welkioAuthProxy.uri);
  }

  getMemberNotificationsHistory = async (
    currentLocationUuid: string,
    forDate: Moment,
    signal?: AbortSignal
  ) => {
    const config = {
      isCamelCase: true,
      requireXRequestId: false,
      signal,
    };
    const params = {
      location_id: currentLocationUuid,
      start_at: forDate.clone().startOf('day').format(),
      end_at: forDate.clone().endOf('day').format(),
      page: 1,
      expand: 'user',
      per_page: 1000,
    };
    const path = 'access/v2/timeline';

    return this.get({ config, params, path });
  };
}
