import { AnalyticsEventName, AnalyticsWorkflow } from 'lib/analytics/constants';
import { deepFreeze } from 'lib/analytics/types/util';

import {
  AboutWorkflowStatic,
  CheckinsWorkflowStatic,
  CommunicationsWorkflowStatic,
  CompaniesWorkflowStatic,
  EmptyStaticType,
  MimoWorkflowStatic,
  RoomsWorkflowStatic,
  GlobalSearchWorkflowStatic,
  StaticProperty,
  VisitorsWorkflowStatic,
} from './index';

/**
 * Static Values
 */
const EMPTY_STATIC_TYPE: EmptyStaticType = Object.freeze({});

const ABOUT_WORKFLOW_STATIC: AboutWorkflowStatic = {
  workflow: AnalyticsWorkflow.ABOUT_WORKFLOW,
};

const VISITORS_WORKFLOW_STATIC: VisitorsWorkflowStatic = {
  workflow: AnalyticsWorkflow.VISITORS_WORKFLOW,
};

const CHECKINS_WORKFLOW_STATIC: CheckinsWorkflowStatic = {
  workflow: AnalyticsWorkflow.CHECKINS_WORKFLOW,
};

const COMMUNICATIONS_WORKFLOW_STATIC: CommunicationsWorkflowStatic = {
  workflow: AnalyticsWorkflow.COMMUNICATIONS_WORKFLOW,
};

const COMPANIES_WORKFLOW_STATIC: CompaniesWorkflowStatic = {
  workflow: AnalyticsWorkflow.COMPANIES_WORKFLOW,
};

const ROOMS_WORKFLOW_STATIC: RoomsWorkflowStatic = {
  workflow: AnalyticsWorkflow.ROOMS_WORKFLOW,
};

const MIMO_WORKFLOW_STATIC: MimoWorkflowStatic = {
  workflow: AnalyticsWorkflow.MIMO_WORKFLOW,
};

const GLOBAL_SEARCH_WORKFLOW_STATIC: GlobalSearchWorkflowStatic = {
  workflow: AnalyticsWorkflow.GLOBAL_SEARCH_WORKFLOW,
};

const staticValuesMap: Readonly<
  { [key in AnalyticsEventName]: StaticProperty<AnalyticsEventName> }
> = deepFreeze({
  // About
  [AnalyticsEventName.about_document_clicked]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.about_document_upload_submit]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.about_view_allamenities_clicked]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.about_avcontrols_clicked]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.wayf_bestpractices_clicked]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.wayf_entrance_field_edit_submitted]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.wayf_field_edit_clicked]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.wayf_field_edit_submitted]: ABOUT_WORKFLOW_STATIC,
  [AnalyticsEventName.wayf_section_clicked]: ABOUT_WORKFLOW_STATIC,

  // Visitors
  [AnalyticsEventName.visitors_actions_item_clicked]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_actions_menu_clicked]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_book_desk_clicked]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_book_desk_submitted]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_book_desk_success]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_calnav_clicked]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_filter]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_visitorcard_action]: VISITORS_WORKFLOW_STATIC,
  [AnalyticsEventName.visitors_visitorcard_clicked]: VISITORS_WORKFLOW_STATIC,

  // Checkins
  [AnalyticsEventName.checkins_add_note_submitted]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_change_status_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_checklist_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_company_profile_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_email_copy_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_medallia_details_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_medallia_view_in_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_medallia_view_more_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_past_checkin_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_section_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_task_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_vital_stats_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_assignment_selected]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_my_checkins_selected]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.checkins_table_sort]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.invite_member_clicked]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.invite_member_submitted]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.invite_member_success]: CHECKINS_WORKFLOW_STATIC,
  [AnalyticsEventName.upload_type_toggle]: CHECKINS_WORKFLOW_STATIC,

  // Communications
  [AnalyticsEventName.comms_search_submitted]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_filter_selected]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_edit_templates]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_create_announcement]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_annon_template_view]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_send_announcement]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_notif_template_variable]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_notif_template_test]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_notif_template_save]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_send_notification_clicked]: COMMUNICATIONS_WORKFLOW_STATIC,
  [AnalyticsEventName.comms_send_notification_success]: COMMUNICATIONS_WORKFLOW_STATIC,

  // Companies
  [AnalyticsEventName.company_menu_item_clicked]: COMPANIES_WORKFLOW_STATIC,
  [AnalyticsEventName.company_notes_filter_selected]: COMPANIES_WORKFLOW_STATIC,
  [AnalyticsEventName.company_note_clicked]: COMPANIES_WORKFLOW_STATIC,
  [AnalyticsEventName.company_note_added]: COMPANIES_WORKFLOW_STATIC,
  [AnalyticsEventName.company_note_deleted]: COMPANIES_WORKFLOW_STATIC,
  [AnalyticsEventName.member_filter_selected]: COMPANIES_WORKFLOW_STATIC,
  [AnalyticsEventName.member_view_toggle]: COMPANIES_WORKFLOW_STATIC,
  [AnalyticsEventName.view_member]: COMPANIES_WORKFLOW_STATIC,

  // Rooms
  [AnalyticsEventName.rooms_cancel_booking_clicked]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_cancel_booking_submitted]: EMPTY_STATIC_TYPE,
  [AnalyticsEventName.rooms_cancel_booking_success]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_refund_booking_clicked]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_refund_booking_submitted]: EMPTY_STATIC_TYPE,
  [AnalyticsEventName.rooms_refund_booking_success]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_book_room_clicked]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_book_room_submitted]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_book_room_success]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_edit_booking_clicked]: EMPTY_STATIC_TYPE,
  [AnalyticsEventName.rooms_edit_booking_submitted]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_edit_booking_success]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_filter_selected]: ROOMS_WORKFLOW_STATIC,
  [AnalyticsEventName.rooms_today_selected]: ROOMS_WORKFLOW_STATIC,

  // MIMO
  [AnalyticsEventName.mimo_move_card_edited]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.mimo_move_details_clicked]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.mimo_exported]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.mimo_email_modal_clicked]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.mimo_email_sent]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.mimo_filter_selected]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.mimo_move_note_saved]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.onboarding_task_marked]: MIMO_WORKFLOW_STATIC,
  [AnalyticsEventName.onboarding_task_unmarked]: MIMO_WORKFLOW_STATIC,

  // global
  [AnalyticsEventName.navigation_clicked]: EMPTY_STATIC_TYPE,
  [AnalyticsEventName.subtab_navigation_clicked]: EMPTY_STATIC_TYPE,

  // legacy
  [AnalyticsEventName.legacy_interaction]: EMPTY_STATIC_TYPE,

  // timing
  [AnalyticsEventName.timing]: EMPTY_STATIC_TYPE,

  // global search
  [AnalyticsEventName.search_results_returned]: GLOBAL_SEARCH_WORKFLOW_STATIC,
  [AnalyticsEventName.search_tab]: GLOBAL_SEARCH_WORKFLOW_STATIC,

  // notifications
  [AnalyticsEventName.notifications_bell_toggled]: EMPTY_STATIC_TYPE,
  [AnalyticsEventName.notifications_card_clicked]: EMPTY_STATIC_TYPE,
  [AnalyticsEventName.locationchange_modal_clicked]: EMPTY_STATIC_TYPE,
});

export default function getStaticValues<E extends AnalyticsEventName>(
  eventName: E
): StaticProperty<E> {
  return staticValuesMap[eventName] as StaticProperty<E>;
}
