import { handleActions } from 'redux-actions';
import { castArray } from 'lodash';

import {
  DEFAULT_PER_PAGE,
  DEFAULT_FETCH_SEATS_OPTIONS,
  FETCH_SEATS,
  FETCH_SEATS_SUCCESS,
  FETCH_SEATS_FAIL,
  CREATE_SEAT,
  CREATE_SEAT_SUCCESS,
  CREATE_SEAT_FAIL,
  REACTIVATE_SEAT,
  REACTIVATE_SEAT_SUCCESS,
  REACTIVATE_SEAT_FAIL,
  DEACTIVATE_SEAT,
  DEACTIVATE_SEAT_SUCCESS,
  DEACTIVATE_SEAT_FAIL,
  DELETE_SEAT,
  DELETE_SEAT_SUCCESS,
  DELETE_SEAT_FAIL,
  CHANGE_LICENSEE,
  CHANGE_LICENSEE_SUCCESS,
  CHANGE_LICENSEE_FAIL,
  TOGGLE_BILLING,
  TOGGLE_BILLING_SUCCESS,
  TOGGLE_BILLING_FAIL,
  SELECT_SEAT,
} from 'features/companies/redux/seats/constants';

import { Seat } from './types';

interface State {
  toggledBilling: boolean;
  reactivating: boolean;
  created: boolean;
  query: string | null | undefined;
  changingLicensee: boolean;
  loading: boolean;
  // TODO: Type this.
  error: any | null;
  deactivated: boolean;
  loaded: boolean;
  filter: {
    locationUuid?: string | null | undefined;
    status?: string;
    userUuids?: string | null | undefined;
  };
  deleting: boolean;
  deleted: boolean;
  perPage: number;
  creating: boolean;
  deactivating: boolean;
  totalPages: number;
  changedLicensee: boolean;
  togglingBilling: boolean;
  page: number;
  selected: Seat | null;
  byUuid: Hash<Array<Seat>>;
  reactivated: boolean;
  status: {
    byUuid: Hash<{
      loading: boolean;
      loaded: boolean;
      error: boolean | null | undefined;
    }>;
  };
}

export interface SeatsSubset {
  seats: State;
}

// Initial State
export const initialState: State = {
  selected: null,
  loading: false,
  loaded: false,
  byUuid: {},
  creating: false,
  created: false,
  reactivating: false,
  reactivated: false,
  deactivating: false,
  deactivated: false,
  deleting: false,
  deleted: false,
  changingLicensee: false,
  changedLicensee: false,
  togglingBilling: false,
  toggledBilling: false,
  error: null,
  status: {
    byUuid: {},
  },
  totalPages: 1,
  perPage: DEFAULT_PER_PAGE,
  ...DEFAULT_FETCH_SEATS_OPTIONS,
};

export const reducer = handleActions<
  State,
  any,
  { companyUuid: string; opts: { page: number; query: string; filter: any } }
>(
  {
    [SELECT_SEAT]: (state, action) => ({
      ...state,
      selected: action.payload.seat,
    }),
    [FETCH_SEATS]: (state, { meta }) => ({
      ...state,
      loading: true,
      loaded: false,
      error: null,
      page: meta.opts.page || 1,
      query: meta.opts.query || '',
      filter: {
        ...(meta.opts.filter || state.filter),
      },
      status: {
        ...state.status,
        byUuid: {
          ...state.status.byUuid,
          [meta.companyUuid]: {
            ...(state.status.byUuid[meta.companyUuid] || {}),
            loaded: false,
            loading: true,
            error: null,
          },
        },
      },
    }),
    [FETCH_SEATS_SUCCESS]: (state, action) => ({
      ...state,
      totalPages: action.payload.meta.total_pages,
      selected: null,
      loading: false,
      loaded: true,
      error: null,
      byUuid: {
        ...state.byUuid,
        [action.meta.companyUuid]: castArray(action.payload.result),
      },
      status: {
        ...state.status,
        byUuid: {
          ...state.status.byUuid,
          [action.meta.companyUuid]: {
            loading: false,
            loaded: true,
            error: null,
          },
        },
      },
    }),
    [FETCH_SEATS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload,
      status: {
        ...state.status,
        byUuid: {
          ...state.status.byUuid,
          [action.meta.companyUuid]: {
            loading: false,
            loaded: false,
            error: action.payload,
          },
        },
      },
    }),
    [CREATE_SEAT]: state => ({
      ...state,
      creating: true,
      created: false,
      error: null,
    }),
    [CREATE_SEAT_SUCCESS]: state => ({
      ...state,
      creating: false,
      created: true,
      error: null,
    }),
    [CREATE_SEAT_FAIL]: (state, action) => ({
      ...state,
      creating: false,
      created: false,
      error: action.payload,
    }),
    [REACTIVATE_SEAT]: state => ({
      ...state,
      reactivating: true,
      reactivated: false,
      error: null,
    }),
    [REACTIVATE_SEAT_SUCCESS]: state => ({
      ...state,
      reactivating: false,
      reactivated: true,
      error: null,
    }),
    [REACTIVATE_SEAT_FAIL]: (state, action) => ({
      ...state,
      reactivating: false,
      reactivated: false,
      error: action.payload,
    }),
    [DEACTIVATE_SEAT]: state => ({
      ...state,
      deactivating: true,
      deactivated: false,
      error: null,
    }),
    [DEACTIVATE_SEAT_SUCCESS]: state => ({
      ...state,
      deactivating: false,
      deactivated: true,
      error: null,
    }),
    [DEACTIVATE_SEAT_FAIL]: (state, action) => ({
      ...state,
      deactivating: false,
      deactivated: false,
      error: action.payload,
    }),
    [DELETE_SEAT]: state => ({
      ...state,
      deleting: true,
      deleted: false,
      error: null,
    }),
    [DELETE_SEAT_SUCCESS]: state => ({
      ...state,
      deleting: false,
      deleted: true,
      error: null,
    }),
    [DELETE_SEAT_FAIL]: (state, action) => ({
      ...state,
      deleting: false,
      deleted: false,
      error: action.payload,
    }),
    [CHANGE_LICENSEE]: state => ({
      ...state,
      changingLicensee: true,
      changedLicensee: false,
      error: null,
    }),
    [CHANGE_LICENSEE_SUCCESS]: state => ({
      ...state,
      changingLicensee: false,
      changedLicensee: true,
      error: null,
    }),
    [CHANGE_LICENSEE_FAIL]: (state, action) => ({
      ...state,
      changingLicensee: false,
      changedLicensee: false,
      error: action.payload,
    }),
    [TOGGLE_BILLING]: state => ({
      ...state,
      togglingBilling: true,
      toggledBilling: false,
      error: null,
    }),
    [TOGGLE_BILLING_SUCCESS]: state => ({
      ...state,
      togglingBilling: false,
      toggledBilling: true,
      error: null,
    }),
    [TOGGLE_BILLING_FAIL]: (state, action) => ({
      ...state,
      togglingBilling: false,
      toggledBilling: false,
      error: action.payload,
    }),
  },
  initialState
);

export default reducer;
