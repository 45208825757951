export const SourceAppName = Object.freeze({
  SPACESTATION: 'Spacestation',
  SALESFORCE: 'Salesforce',
});

export const Workflows = Object.freeze({
  DEFAULT: 'default',
  MANUAL: 'manual_upload',
  CHINA: 'china',
});

export const PpwkTypes = Object.freeze({
  MOVE_IN: 'move_in',
  TRANSFER: 'transfer',
  MOVE_OUT: 'move_out',
  ADDON: 'addon',
});

export default { SourceAppName, Workflows, PpwkTypes };

export const SERVICE_CLOUD_NUMBER = 'Service Cloud Request Number';

export const COMMUNITY_MANAGER_WARNING_TEXT =
  'Error: CM signatory missing at location. Submit a request to add CM name (Okta > Global Salesforce - Sales & Service > Apps and Digital Products > Spacestation > Members (Paperwork, Add-ons, etc.))';
