export const COMPANY_NAME_KEY = 'data.company.name';
export const MEMBER_NAME_KEY = 'data.company.primary_member_name';
export const TEXT_KEY = 'data.note.text';
export const COMPONENT_NAME = 'Proactive Check-ins';
export const COPY_SUCCESS_MESSAGE = 'Email copied to clipboard';
export const COPY_TOOLTIP = 'Copy to clipboard';
const NOT_AVAILABLE = 'NA';
export const DEFAULT_FALLBACK_DESK = NOT_AVAILABLE;
export const DEFAULT_FALLBACK_OFFICE = NOT_AVAILABLE;
export const DEFAULT_FALLBACK_IS_MIGRATED = false;
export const DEFAULT_FALLBACK_TCV = NOT_AVAILABLE;
export const DEFAULT_TASK_OWNERSHIP_FALLBACK = NOT_AVAILABLE;
export const DEBOUNCE_TIME = 1000;

export const CHECKLIST_MAPPING = {
  EMAIL: {
    apiKey: 'email',
    value: 'Send outreach email',
    displayName: 'Send outreach email',
  },
  CONFIRM: {
    apiKey: 'confirm',
    value: 'Confirm meeting date',
    displayName: 'Confirm meeting date',
  },
  COORDINATE: {
    apiKey: 'coordinate',
    value: 'Send calendar invite & book meeting space',
    displayName: 'Send calendar invite & book meeting space',
  },
  REVIEW: {
    apiKey: 'review',
    value: 'Review & research',
    displayName: 'Review & research',
  },
  MEETING: {
    apiKey: 'meeting',
    value: 'Confirm meeting 24h in advance',
    displayName: 'Confirm meeting 24h in advance',
  },
  QUESTIONS: {
    apiKey: 'questions',
    value: 'Prepare 5 questions to ask',
    displayName: 'Prepare 5 questions to ask',
  },
};

export const CHECKLIST_ITEMS = [
  CHECKLIST_MAPPING.EMAIL,
  CHECKLIST_MAPPING.CONFIRM,
  CHECKLIST_MAPPING.COORDINATE,
  CHECKLIST_MAPPING.REVIEW,
  CHECKLIST_MAPPING.MEETING,
  CHECKLIST_MAPPING.QUESTIONS,
];

export const CHECK_INS_SEARCH_PARAMS = {
  ID: 'check-ins-id',
  LOCATION: 'location',
};
