import { set } from 'dot-prop-immutable';
import { get } from 'lodash';

import { ErrorContainer } from 'store/errors';

import { EntityAction } from '../types';

export interface Status {
  loading: boolean;
  loaded: boolean;
  error: ErrorContainer | null | undefined;
}

export interface State {
  create?: Status;
  query?: Status | Hash<Status>;
  find?: Hash<Status>;
  destroy?: Hash<Status>;
  update?: Hash<Status>;
}

const initialState: State = {};

const statusReducer = (state: State = initialState, action: EntityAction): State => {
  if (!action.meta || !action.meta.entity) {
    return state;
  }

  const { pending, success, error, entity } = action.meta;

  const { action: actionName, actionType } = entity;

  const err = error
    ? ({
        status: action.payload?.status,
        url: action.payload?.response?.url,
        type: action.payload?.type,
        message: action.payload?.message,
      } as ErrorContainer) ?? null
    : null;

  const status = {
    loading: !!pending,
    loaded: !!success,
    error: err,
  };

  if (actionType === 'query' && entity.key) {
    return set(state, `query.${entity.key}`, status);
  }

  if (actionType === 'query' || actionType === 'create') {
    return set(state, actionName, status);
  }

  if (actionType === 'find' || actionType === 'destroy' || actionType === 'update') {
    const maybeId = get(entity, 'params.id', 'unknown');
    return set(state, `${actionName}.${maybeId}`, status);
  }

  return state;
};

export default statusReducer;
