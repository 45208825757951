import mirrorCreator from 'mirror-creator';

import { PREFIX } from './createReduxConstant';

// A helper function for creating action constants with less repetition.
export function createConstants(items: Array<string>): Hash<string> {
  return mirrorCreator(items, { prefix: PREFIX });
}

export function createRequestConstantNames(name: string): [string, string, string, string] {
  return [name, `${name}_SUCCESS`, `${name}_FAIL`, `${name}_RESET`];
}
