import { handleActions } from 'redux-actions';

import config from 'config';
import { BaseAction, BaseState } from 'store/types';
import cc from 'store/util/createReduxConstant';

// Action Constants
export const FETCH_KEYCARD_FORMATS = cc('FETCH_KEYCARD_FORMATS');
export const UPDATE_CURRENT_KEYCARD_FORMAT = cc('UPDATE_CURRENT_KEYCARD_FORMAT');

export type AccessLevelOption = Readonly<{
  label: string;
  value: number;
}>;

interface State extends BaseState {
  currentKeycardFormat: string | null | undefined;
  data: Array<AccessLevelOption>;
}

export interface KeycardFormatsSubset {
  keycardFormats: State;
}

type Action = Readonly<
  BaseAction & {
    payload: {
      cardFormat?: string;
      data?: Array<AccessLevelOption>;
    };
  }
>;

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  currentKeycardFormat: null,
  data: [],
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_KEYCARD_FORMATS]: (state: State, action: Action): State => ({
      ...state,
      data: action.payload.data ?? [],
    }),
    [UPDATE_CURRENT_KEYCARD_FORMAT]: (state: State, action: Action): State => ({
      ...state,
      currentKeycardFormat: action.payload.cardFormat,
    }),
  },
  initialState
);

// Same keycard type but with different name comes from either prod and stage env
export const mobileKeycardFormatName =
  config.appEnv === 'production' ? 'WeWork 39-bit FC 203' : 'HID BLE Demo';

// Action Creators
export const fetchKeycardFormats = () => ({
  type: FETCH_KEYCARD_FORMATS,
  payload: {
    data: [
      {
        name: 'WeWork 26 Bit Wiegand FC 100',
        hasSecondary: false,
      },
      {
        name: 'WeWork 26 Bit Wiegand FC 200',
        hasSecondary: false,
      },
      {
        name: 'WeWork 37 Bit Wiegand FC 3',
        hasSecondary: false,
      },
      {
        name: 'WeWork MiFare Classic 1K',
        hasSecondary: true,
      },
      {
        name: mobileKeycardFormatName,
        hasSecondary: false,
      },
    ],
  },
});

export const updateCurrentKeycardCardFormat = (cardFormat: string) => ({
  type: UPDATE_CURRENT_KEYCARD_FORMAT,
  payload: { cardFormat },
});

export default reducer;
