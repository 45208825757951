import React from 'react';
import cn from 'classnames';
import { noop } from 'lodash';

export type DimmerProps = {
  active?: boolean;
  disabled?: boolean;
  page?: boolean;
  onClick?: () => void;
  className?: string;
  children?: React.ReactElement;
};

const Dimmer = React.forwardRef<HTMLDivElement, DimmerProps>(
  ({ active = true, children, className, disabled = false, page = false, onClick = noop }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          '!flex p-8 absolute top-0 left-0 w-full h-full align-middle bg-opacity-0 will-change-[opacity] justify-center items-center bg-gray-20 z-[1000]',
          {
            '!fixed': page,
            '!w-0 !h-0': disabled,
            '!bg-opacity-[.85] !flex': active,
          },
          className
        )}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);

Dimmer.displayName = 'Dimmer';

export default Dimmer;
