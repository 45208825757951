import { createSelector } from 'reselect';

import { ExperimentSubset } from 'store/middlewares/experimentsHelper';

const ON = 'on';

const getSSInventoryComparisonExperiment = (state: ExperimentSubset): string | null | undefined =>
  state.experiments.experimentGroups.ssInventoryComparison;

export const isSSInventoryComparisonExperimentOn = createSelector(
  getSSInventoryComparisonExperiment,
  ssInventorySearchComparison => ssInventorySearchComparison === ON
);

const getSSInventoryRoomGroupingExperiment = (state: ExperimentSubset): string | null | undefined =>
  state.experiments.experimentGroups.ssInventoryRoomGrouping;

export const isSSInventoryRoomGroupingExperimentOn = createSelector(
  getSSInventoryRoomGroupingExperiment,
  ssInventoryssRoomGrouping => ssInventoryssRoomGrouping === ON
);

const getSSInventoryBuildingFilterExperiment = (
  state: ExperimentSubset
): string | null | undefined => state.experiments.experimentGroups.ssInventoryBuildingFilter;

export const isSSInventoryBuildingFilterExperimentOn = createSelector(
  getSSInventoryBuildingFilterExperiment,
  ssInventoryssBuildingFilter => ssInventoryssBuildingFilter === ON
);

const getSSInventoryNewAttributesExperiment = (
  state: ExperimentSubset
): string | null | undefined => state.experiments.experimentGroups.ssInventoryNewAttributes;

export const isSSInventoryNewAttributesExperimentOn = createSelector(
  getSSInventoryNewAttributesExperiment,
  ssInventoryssNewAttributes => ssInventoryssNewAttributes === ON
);

const getSSInventoryAvailabilityFilterExperiment = (
  state: ExperimentSubset
): string | null | undefined => state.experiments.experimentGroups.ssInventoryAvailabilityFilter;

export const isSSInventoryAvailabilityFilterExperimentOn = createSelector(
  getSSInventoryAvailabilityFilterExperiment,
  ssInventoryssAvailabilityFilter => ssInventoryssAvailabilityFilter === ON
);

const getSSInventoryRSFExperiment = (state: ExperimentSubset): string | null | undefined =>
  state.experiments.experimentGroups.ssInventoryRSF;

export const isSSInventoryRSFExperimentOn = createSelector(
  getSSInventoryRSFExperiment,
  ssInventoryssRSF => ssInventoryssRSF === ON
);

const getSSMimoChecklistRequestsTaskAutomationExperiment = (
  state: ExperimentSubset
): string | null | undefined =>
  state.experiments.experimentGroups.ssMimoChecklistRequestsTaskAutomation;

export const isSSMimoChecklistRequestsTaskAutomationOn = createSelector(
  getSSMimoChecklistRequestsTaskAutomationExperiment,
  ssMimoChecklistRequestsTaskAutomation => ssMimoChecklistRequestsTaskAutomation === ON
);

const getSSInventoryNewWorkUnitsExperiment = (state: ExperimentSubset): string | null | undefined =>
  state.experiments.experimentGroups.ssInventoryNewWorkUnits;

export const isSSInventoryNewWorkUnitsExperimentOn = createSelector(
  getSSInventoryNewWorkUnitsExperiment,
  ssInventoryNewWorkUnits => ssInventoryNewWorkUnits === ON
);

const getSSFilterKubeLocationsExperiment = (state: ExperimentSubset): string | null | undefined =>
  state.experiments?.experimentGroups?.ssFilterKubeLocations;

export const isSSFilterKubeLocationsExperimentOn = createSelector(
  getSSFilterKubeLocationsExperiment,
  ssFilterKubeLocations => ssFilterKubeLocations === ON
);
