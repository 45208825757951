import { handleActions } from 'redux-actions';

import { State } from 'features/accessReports/personalAccess/redux/types';
import {
  FETCH_ACCESS_PERSON_SEARCH_SUCCESS,
  FETCH_ACCESS_PERSON_SEARCH_FAIL,
  FETCH_ACCESS_PERSON_SEARCH,
  GET_ACCESS_PERSON_SELECTED_DATA,
  FETCH_ACCESS_PERSON_CONTROLLERS,
  FETCH_ACCESS_PERSON_CONTROLLERS_SUCCESS,
  FETCH_ACCESS_PERSON_CONTROLLERS_FAIL,
} from 'features/accessReports/personalAccess/redux/constants';

const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
  searchList: [],
  selectedParams: {},
  totalPages: 1,
  totalElements: 0,
  numberPage: 1,
  pageSize: 20,
  isVisibleTable: false,
  controllerList: [],
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_ACCESS_PERSON_SEARCH]: state => ({
      ...state,
      loading: true,
      loaded: false,
      isVisibleTable: true,
      numberPage: 1,
    }),

    [FETCH_ACCESS_PERSON_SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      searchList: action.payload.content,
      data: action.payload,
      pageSize: action.payload.pageable.pageSize,
      totalPages: action.payload.totalPages,
      totalElements: action.payload.totalElements,
      numberPage: action.payload.number,
      error: null,
    }),

    [FETCH_ACCESS_PERSON_SEARCH_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      isVisibleTable: false,
      searchList: [],
      data: [],
      pageSize: 20,
      totalPages: 1,
      numberPage: 1,
      totalElements: 0,
      error: action.payload.message,
    }),
    [GET_ACCESS_PERSON_SELECTED_DATA]: (state, action) => ({
      ...state,
      selectedParams: action.payload,
    }),
    [FETCH_ACCESS_PERSON_CONTROLLERS]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_ACCESS_PERSON_CONTROLLERS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      controllerList: action.payload.result,
      error: null,
    }),

    [FETCH_ACCESS_PERSON_CONTROLLERS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      controllerList: [],
      error: action.payload.message,
    }),
  },

  initialState
);

export default reducer;
