import { MultipleQueriesQuery } from '@algolia/client-search';

import { SearchRequest } from '../types';

import { Mappers, Mapper, FiledValueFilter } from './types';

const filterTermBuilder = (filters: Array<FiledValueFilter>): string =>
  filters
    .map(fieldFilter => {
      const terms = fieldFilter.values.map(value => {
        if (fieldFilter.isOptional) {
          return `${fieldFilter.field}:${value}<score=2> OR NOT ${fieldFilter.field}:${value}<score=1>`;
        }
        return `${fieldFilter.field}:${value}`;
      });

      return `(${terms.join(' OR ')})`;
    })
    .join(' AND ');

const getGeoLocation = geoLocation =>
  geoLocation
    ? {
        aroundLatLng: `${geoLocation.lat},${geoLocation.lng}`,
        aroundRadius: geoLocation.radius,
      }
    : {};

const stringifyFilters = (mapper: Mapper, request: SearchRequest): string =>
  filterTermBuilder(mapper.mapper(request.filters));

const facetValuesToQuery = (
  facets: Array<string>,
  dataFilterTerm: string | undefined
): Array<string> => {
  let facetRequestValues: Array<string> = [];

  if (dataFilterTerm) {
    facetRequestValues = facets.filter(facetName => dataFilterTerm.includes(facetName));
  }

  return facetRequestValues;
};

const createFacetedCountRequest = (
  request: SearchRequest,
  facets: Array<string>,
  mapper: Mapper
): Array<MultipleQueriesQuery> => {
  const facetedRequets: Array<MultipleQueriesQuery> = [];
  const geoLocation = getGeoLocation(request.filters.geoLocation);

  facets.forEach(facet => {
    const filtersWithoutFacetValue = mapper
      .mapper(request.filters)
      .filter(queryFilter => queryFilter.field !== facet);

    facetedRequets.push({
      indexName: mapper.index,
      query: request.query || '',
      params: {
        hitsPerPage: 0,
        facets: [facet],
        filters: filterTermBuilder(filtersWithoutFacetValue),
        ...geoLocation,
      },
    });
  });

  return facetedRequets;
};

export default (mappers: Mappers, request: SearchRequest): Array<MultipleQueriesQuery> => {
  const types = request.filters.types;

  if (!types) {
    return [];
  }

  let algoliaRequests: Array<MultipleQueriesQuery> = [];
  const geoLocation = getGeoLocation(request.filters.geoLocation);

  types.forEach(type => {
    const mapper = mappers[type];

    if (!mapper) {
      throw Error(`No mapper for type ${type} in Algolia`);
    }

    const dataRequest: MultipleQueriesQuery = {
      indexName: mapper.index,
      query: request.query || '',
      params: {
        filters: stringifyFilters(mapper, request),
        facets: request.facets,
        hitsPerPage: request.limit,
        ...geoLocation,
      },
    };
    algoliaRequests.push(dataRequest);

    if (request.facets && request.facets.length > 0) {
      const facetValues = facetValuesToQuery(request.facets, dataRequest.params?.filters);

      const facetedCountRequests = createFacetedCountRequest(request, facetValues, mapper);
      algoliaRequests = [...algoliaRequests, ...facetedCountRequests];
    }
  });

  return algoliaRequests;
};
