import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  FETCH_EMAIL_TEMPLATES_FAIL,
] = createRequestConstantNames(cc('FETCH_EMAIL_TEMPLATES'));

export const [
  CREATE_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_FAIL,
] = createRequestConstantNames(cc('CREATE_EMAIL_TEMPLATE'));

export const [
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
] = createRequestConstantNames(cc('UPDATE_EMAIL_TEMPLATE'));

export const [
  RENDER_EMAIL_TEMPLATE,
  RENDER_EMAIL_TEMPLATE_SUCCESS,
  RENDER_EMAIL_TEMPLATE_FAIL,
] = createRequestConstantNames(cc('RENDER_EMAIL_TEMPLATE'));
