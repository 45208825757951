import React from 'react';

import Trackable, { TrackableType } from 'components/decorators/trackable';

import styles from './networkStateObserver.scss';

type Props = Readonly<TrackableType>;

type State = Readonly<{ isOnline: boolean }>;

class NetworkStateObserver extends React.Component<Props, State> {
  state = {
    isOnline: window.navigator.onLine,
  };

  componentDidMount() {
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = (evt: Event) => {
    this.props.trackInteraction('Connection State', {
      // $FlowFixMe TODO inexact issue with Event and MouseEvent
      label: evt.type,
    });

    this.setState({
      // $FlowFixMe TODO inexact issue with Event and MouseEvent
      isOnline: evt.type === 'online',
    });
  };

  render() {
    return this.state.isOnline ? null : (
      <div className={styles.message}>No internet connection, please reconnect</div>
    );
  }
}

export default Trackable({
  workflow: 'app',
  featureContext: 'requestAction',
  feature: 'app',
})(NetworkStateObserver);
