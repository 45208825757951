import React, { Suspense, ComponentType } from 'react';
import { memoize, omit } from 'lodash';
import { RouteComponentProps, withRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getExperiments } from 'store/middlewares/experimentsHelper';
import { GlobalState } from 'store/modules';
// Pages
import NotFound from 'features/app/notFoundPage';
import { getCurrentLocation, getHomeLocation, getLocations } from 'store/selectors';
import { getUserInfoFromAuth } from 'features/auth/selectors';
// Components
import Loading from 'components/loading';
import { lazy } from 'lib/lazyCatch';

import RouteErrorBoundary from './routeErrorBoundary';
import routes from './routes';

const LOADER_DELAY_MS = 300;

export type RouteConfig = {
  exact: boolean;
  path: string;
  component?: () => Promise<{ default: ComponentType<any> }>;
};

// eslint-disable-next-line no-use-before-define
const mapStateToProps = (state: GlobalState, props: RouteComponentProps) => ({
  auth: state.auth,
  // $FlowFixMe TODO ReadOnly doesn't seem to work with empty objects
  currentLocation: getCurrentLocation(state, props),
  experimentGroups: getExperiments(state),
  homeLocation: getHomeLocation(state),
  locations: getLocations(state),
  userInfo: getUserInfoFromAuth(state),
});

type Props = Readonly<RouteComponentProps & ReturnType<typeof mapStateToProps>>;

// Clean a path to a generic key we can use for memoization.
const sanitizePath = (path: string): string =>
  path
    .toLowerCase() // Trim slash prefix
    .replace(/^\//, '') // Remove dashes
    .replace(/-/g, '') // Replace URL parameters with array-like notation (".../:uuid" -> "...[uuid]")
    .replace(/\/?:([a-z0-9]+)/gi, '[$1]') // Replace all non-alphanumerics with underscores
    .replace(/[^a-z0-9[\]]+/gi, '_');

const lazyComponent = memoize((componentPromise: () => Promise<{ default: ComponentType<any> }>) =>
  lazy(componentPromise)
);

function renderRoute(
  { component }: RouteConfig,
  props: Props,
  router: RouteComponentProps
): React.ReactNode {
  if (!component) return null;
  const Component = lazyComponent(component);

  const childProps = omit(props, ['currentLocation', 'homeLocation', 'locations']);

  return (
    <RouteErrorBoundary>
      <Component {...childProps} {...router} />
    </RouteErrorBoundary>
  );
}

const Router = (props: Props) => (
  <Suspense fallback={<Loading delayMs={LOADER_DELAY_MS} />}>
    <Switch>
      {routes.map(route => (
        <Route
          key={sanitizePath(route.path)}
          exact={route.exact}
          path={route.path}
          /* @ts-ignore TODO(react-redux update): The types returned by 'component()' are incompatible between these types. */
          render={router => renderRoute(route, props, router)}
        />
      ))}
      <Redirect from="/announcements" to="/communication/announcements" />
      <Redirect from="/memberNotifications" to="/communication/notifications" />
      <Redirect from="/milestones" to="/milestones/week" />
      <Redirect from="/todaysVisitors" to="/visitors" />
      <Redirect from="*" to="/visitors" />
      <Route path="404" component={NotFound} />
    </Switch>
  </Suspense>
);

export const TestableRouter = Router;

export default compose<React.ComponentType<{}>>(withRouter, connect(mapStateToProps))(Router);
