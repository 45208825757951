import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';

export const [
  FETCH_ACCESS_LEVELS_GROUPS,
  FETCH_ACCESS_LEVELS_SUCCESS_GROUPS,
  FETCH_ACCESS_LEVELS_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_LEVELS_GROUPS'));

export const [
  CREATE_ACCESS_LEVEL_GROUPS,
  CREATE_ACCESS_LEVEL_SUCCESS_GROUPS,
  CREATE_ACCESS_LEVEL_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('CREATE_ACCESS_LEVEL_GROUPS'));

export const [
  UPDATE_ACCESS_LEVEL_GROUPS,
  UPDATE_ACCESS_LEVEL_SUCCESS_GROUPS,
  UPDATE_ACCESS_LEVEL_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('UPDATE_ACCESS_LEVEL_GROUPS'));

export const [
  DELETE_ACCESS_LEVEL_GROUPS,
  DELETE_ACCESS_LEVEL_SUCCESS_GROUPS,
  DELETE_ACCESS_LEVEL_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('DELETE_ACCESS_LEVEL_GROUPS'));

export const [
  SEARCH_ACCESS_LEVEL_GROUPS,
  SEARCH_ACCESS_LEVEL_SUCCESS_GROUPS,
  SEARCH_ACCESS_LEVEL_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('SEARCH_ACCESS_LEVEL_GROUPS'));

export const [
  FETCH_SELECTED_ACCESS_LEVELS_GROUPS,
  FETCH_SELECTED_ACCESS_LEVELS_SUCCESS_GROUPS,
  FETCH_SELECTED_ACCESS_LEVELS_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('FETCH_SELECTED_ACCESS_LEVELS_GROUPS'));

export const [
  FETCH_ACCESS_PARTITION_GROUPS,
  FETCH_ACCESS_PARTITION_SUCCESS_GROUPS,
  FETCH_ACCESS_PARTITION_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_PARTITION_GROUPS'));

export const [
  FETCH_ACCESS_SERVERS_AND_PARTITIONS_GROUPS,
  FETCH_ACCESS_SERVERS_AND_PARTITIONS_SUCCESS_GROUPS,
  FETCH_ACCESS_SERVERS_AND_PARTITIONS_FAIL_GROUPS,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCESS_SERVERS_AND_PARTITIONS_GROUPS'));

export const FORM_NAME = 'accessLevelGroupsForm';
