import { handleActions, Action } from 'redux-actions';
import { combineReducers } from 'redux';
import { isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { parseDate } from 'features/visitors/util';

import { VisitType } from '../constants';
import { CheckedInGuestVisit, GuestVisit, Host, Visit } from '../types';

import { calcGuestTags, checkedInGuest, nonCheckedInGuest } from './serverMapping';
import {
  FiltersState,
  FilterVisitorsActiveState,
  EditAction,
  GuestResponse,
  WelkioVisit,
  RegistrationExpansion,
} from './types';
import {
  FETCH_VISITORS,
  FETCH_VISITORS_SUCCESS,
  VISITORS_FILTER_CHANGE,
  VISITORS_DATE_CHANGE,
  // Visit creation
  CREATE_NEW_PRE_REGISTERED_GUEST_SUCCESS,
  CREATE_NEW_GUEST_VISIT_SUCCESS,
  // Visit update post
  EDIT_GUEST_VISIT,
  EDIT_GUEST_VISIT_SUCCESS,
  EDIT_GUEST_VISIT_FAIL,
  EDIT_PRE_REGISTERED_GUEST,
  EDIT_PRE_REGISTERED_GUEST_SUCCESS,
  EDIT_PRE_REGISTERED_GUEST_FAIL,
  // Visit update patch
  UPDATE_VISITORS_CHECKED_IN_GUEST,
  UPDATE_VISITORS_CHECKED_IN_GUEST_SUCCESS,
  UPDATE_VISITORS_CHECKED_IN_GUEST_FAIL,
  UPDATE_VISITORS_GUEST,
  UPDATE_VISITORS_GUEST_SUCCESS,
  UPDATE_VISITORS_GUEST_FAIL,
  DELETE_GUEST_PHOTO_SUCCESS,
  // Visit delete
  DELETE_VISITORS_GUEST_SUCCESS,
  // Pusher
  VISITOR_CREATED,
  VISITOR_UPDATED,
  VISITOR_DELETED,
} from './constants';

interface VisitorsState {
  loading: boolean;
  data: Array<Visit>;
  inProgressVisitIdUpdates: Array<string>;
  filters: FiltersState;
  selectedDate: Date;
}

type FilterChangeAction =
  | {
      key: VisitType;
      value: boolean;
    }
  | {
      key: 'search';
      value: string;
    };

const initialFilters = Object.keys(VisitType).reduce((initialFilters, key: VisitType) => {
  initialFilters[key] = false;

  return initialFilters;
}, {}) as FilterVisitorsActiveState;

// Initial State
const initialState: VisitorsState = {
  loading: false,
  data: [],
  inProgressVisitIdUpdates: [],
  filters: {
    search: '',
    ...initialFilters,
  },
  selectedDate: new Date(),
};

const cloneVisitWithoutImage = (visit: Visit): Visit => {
  const { imgSrc, ...visitor } = visit.visitor;

  // @ts-ignore
  return {
    ...visit,
    visitor,
  };
};

const filterOutGuest = (data, id) => data.filter(({ id: _id }) => _id !== id);

const stateWithNewVisit = (state, newVisit) => ({
  ...state,
  data: (state.data.some(visit => visit.id === newVisit.id)
    ? state.data
    : [newVisit, ...state.data]
  )
    // in case a user is checkedIn (mainly from pusher) we need to filter out it's original registration visit
    .filter(({ id }) => id !== newVisit.registrationId),
});

const removeFromInProgressVisitIdUpdates = (state: VisitorsState, action) => ({
  ...state,
  inProgressVisitIdUpdates: state.inProgressVisitIdUpdates.filter(id => id !== action.meta.id),
});

const addToInProgressVisitIdUpdates = (state: VisitorsState, action) => ({
  ...state,
  inProgressVisitIdUpdates: [...state.inProgressVisitIdUpdates, action.meta.id],
});

// we don't use the whole payload because a lot is missing. instead we use the original data which is the same.
const updateCheckInVisit = (
  data: Array<Visit>,
  oldId: string,
  newId: string,
  { idVerified, signedInAt, registration }: WelkioVisit & RegistrationExpansion,
  timeZone: string
) => {
  const visitToUpdate = data.find(({ id }) => id === oldId);
  return visitToUpdate
    ? [
        {
          ...(visitToUpdate as GuestVisit),
          id: newId,
          checkInTime: parseDate(signedInAt, timeZone),
          tags: calcGuestTags(idVerified ?? false, !!registration),
        },
        ...filterOutGuest(data, oldId),
      ]
    : data;
};

// we don't use the whole payload because a lot is missing. instead we use the original data which is the same.
const updateGuestVisit = (
  data: Array<Visit>,
  id: string,
  { idVerified, signedOutAt, registration }: WelkioVisit & RegistrationExpansion,
  timeZone: string
): Array<Visit> =>
  data.map(visit =>
    visit.id !== id
      ? visit
      : ({
          ...visit,
          isIdVerified: idVerified,
          checkOutTime: signedOutAt ? parseDate(signedOutAt, timeZone) : null,
          tags: calcGuestTags(idVerified ?? false, !!registration),
        } as CheckedInGuestVisit)
  );

const updatedHost = (oldHost: Host, newHost: Host | null): Host | null => {
  if (newHost && newHost.company) {
    // welkio edge case bug where offices/room is missing in payload
    newHost.company.offices = newHost.company.offices || oldHost.company.offices;
  }
  return newHost;
};

const isSameDayAsSelectedDay = (visit: GuestVisit, state: VisitorsState, timeZone: string) =>
  isSameDay(visit.time, utcToZonedTime(state.selectedDate, timeZone));

// Reducer
export const visits = handleActions<VisitorsState, any, any>(
  {
    [FETCH_VISITORS]: (state: VisitorsState): VisitorsState => ({
      ...state,
      loading: true,
      data: [],
    }),
    [FETCH_VISITORS_SUCCESS]: (state: VisitorsState, action): VisitorsState => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    [VISITORS_DATE_CHANGE]: (state: VisitorsState, action: Action<Date>): VisitorsState => ({
      ...state,
      selectedDate: action.payload,
    }),
    [VISITORS_FILTER_CHANGE]: (
      state: VisitorsState,
      action: { payload: FilterChangeAction }
    ): VisitorsState => ({
      ...state,
      filters: {
        ...state.filters,
        [action.payload.key]: action.payload.value,
      },
    }),
    // Visit creation
    [CREATE_NEW_GUEST_VISIT_SUCCESS]: (
      state: VisitorsState,
      { payload: { data }, meta: { visit, timeZone } }
    ): VisitorsState =>
      stateWithNewVisit(state, {
        ...checkedInGuest(timeZone, data),
        ...visit,
      }),
    [CREATE_NEW_PRE_REGISTERED_GUEST_SUCCESS]: (
      state: VisitorsState,
      { payload: { data }, meta: { visit: metaVisit, timeZone } }
    ): VisitorsState => {
      const visit = {
        ...nonCheckedInGuest(timeZone, data),
        ...metaVisit,
      };

      return isSameDayAsSelectedDay(visit.time, state, timeZone)
        ? stateWithNewVisit(state, visit)
        : state;
    },
    // visit post update
    [EDIT_GUEST_VISIT]: addToInProgressVisitIdUpdates,
    [EDIT_GUEST_VISIT_FAIL]: removeFromInProgressVisitIdUpdates,
    [EDIT_GUEST_VISIT_SUCCESS]: (
      state: VisitorsState,
      { payload: { data }, meta: { visit: metaVisit, timeZone } }
    ): VisitorsState => ({
      ...state,
      inProgressVisitIdUpdates: state.inProgressVisitIdUpdates.filter(_id => _id !== data.id),
      data: state.data.map(visit =>
        visit.id !== data.id
          ? visit
          : {
              ...checkedInGuest(timeZone, data),
              ...metaVisit,
            }
      ),
    }),
    [EDIT_PRE_REGISTERED_GUEST]: addToInProgressVisitIdUpdates,
    [EDIT_PRE_REGISTERED_GUEST_FAIL]: removeFromInProgressVisitIdUpdates,
    [EDIT_PRE_REGISTERED_GUEST_SUCCESS]: (
      state: VisitorsState,
      { payload: { data }, meta: { visit: metaVisit, timeZone } }
    ): VisitorsState => {
      const updatedGuestVisit = {
        ...nonCheckedInGuest(timeZone, data),
        ...metaVisit,
      };
      // date might have changed so remove if needed
      const hasDateChanged = !isSameDayAsSelectedDay(updatedGuestVisit, state, timeZone);
      return {
        ...state,
        inProgressVisitIdUpdates: state.inProgressVisitIdUpdates.filter(_id => _id !== data.id),
        data: hasDateChanged
          ? state.data.filter(visit => visit.id !== data.id)
          : state.data.map(visit => (visit.id !== data.id ? visit : updatedGuestVisit)),
      };
    },
    // visit patch update
    [UPDATE_VISITORS_CHECKED_IN_GUEST]: addToInProgressVisitIdUpdates,
    [UPDATE_VISITORS_CHECKED_IN_GUEST_FAIL]: removeFromInProgressVisitIdUpdates,
    [UPDATE_VISITORS_CHECKED_IN_GUEST_SUCCESS]: (
      state: VisitorsState,
      action: EditAction<GuestResponse, WelkioVisit & RegistrationExpansion>
    ): VisitorsState => {
      const { payload, meta } = action;
      const { id: oldId, timeZone } = meta;
      const { id: newId } = payload.data;
      return {
        ...state,
        inProgressVisitIdUpdates: state.inProgressVisitIdUpdates.filter(id => id !== meta.id),
        data: updateCheckInVisit(state.data, oldId, newId, payload.data, timeZone),
      };
    },
    [UPDATE_VISITORS_GUEST]: addToInProgressVisitIdUpdates,
    [UPDATE_VISITORS_GUEST_FAIL]: removeFromInProgressVisitIdUpdates,
    [UPDATE_VISITORS_GUEST_SUCCESS]: (
      state: VisitorsState,
      { payload, meta }: EditAction<GuestResponse, WelkioVisit & RegistrationExpansion>
    ): VisitorsState => ({
      ...state,
      inProgressVisitIdUpdates: state.inProgressVisitIdUpdates.filter(
        _id => _id !== payload.data.id
      ),
      data: updateGuestVisit(state.data, payload.data.id, payload.data, meta.timeZone),
    }),
    [DELETE_GUEST_PHOTO_SUCCESS]: (
      state: VisitorsState,
      { meta }: EditAction<GuestResponse, WelkioVisit & RegistrationExpansion>
    ): VisitorsState => ({
      ...state,
      data: state.data.map(visit => (visit.id !== meta.id ? visit : cloneVisitWithoutImage(visit))),
    }),
    // visit delete
    [DELETE_VISITORS_GUEST_SUCCESS]: (state: VisitorsState, action): VisitorsState => ({
      ...state,
      data: filterOutGuest(state.data, action.meta.id),
    }),
    // welkio push events
    [VISITOR_CREATED]: (state: VisitorsState, action): VisitorsState =>
      stateWithNewVisit(state, action.payload),
    [VISITOR_UPDATED]: (state: VisitorsState, action): VisitorsState => ({
      ...state,
      data: state.data.map(visit =>
        action.payload.id !== visit.id
          ? visit
          : {
              ...action.payload,
              host: updatedHost(action.payload.host, (visit as GuestVisit).host),
            }
      ),
    }),
    [VISITOR_DELETED]: (state: VisitorsState, action): VisitorsState => ({
      ...state,
      data: state.data.filter(({ id }) => action.payload !== id),
    }),
  },
  initialState
);

export interface State {
  visits: VisitorsState;
}

export interface VisitorsSubset {
  visitors: State;
}

const visitorsReducers = {
  visits,
};
export default combineReducers<State>(visitorsReducers);
