import { handleActions } from 'redux-actions';

import { ActionWithPayload } from 'store/types';
import { Organization, OrganizationState } from 'store/modules/organization/types';

import {
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
} from './constants';

const initialState: OrganizationState = {
  organization: undefined,
  organizationLoading: false,
};

const reducer = handleActions<OrganizationState, any, any>(
  {
    [FETCH_ORGANIZATION]: (state: OrganizationState) => ({
      ...state,
      organizationLoading: true,
    }),
    [FETCH_ORGANIZATION_SUCCESS]: (
      state: OrganizationState,
      action: ActionWithPayload<Organization>
    ) => ({
      ...state,
      organization: action.payload,
      organizationLoading: false,
    }),
    [FETCH_ORGANIZATION_FAIL]: (state: OrganizationState) => ({
      ...state,
      organizationLoading: false,
    }),
    [UPDATE_ORGANIZATION]: (state: OrganizationState) => ({
      ...state,
      organizationLoading: true,
    }),
    [UPDATE_ORGANIZATION_SUCCESS]: (
      state: OrganizationState,
      action: ActionWithPayload<Organization>
    ) => ({
      ...state,
      organization: action.payload,
      organizationLoading: false,
    }),
    [UPDATE_ORGANIZATION_FAIL]: (state: OrganizationState) => ({
      ...state,
      organizationLoading: false,
    }),
  },
  initialState
);

export default reducer;
