import { handleActions, Action } from 'redux-actions';

import { CustomAccessLevels } from 'features/companies/redux/customAccessLevels/types';
import {
  BuildingAccessControlMap,
  PartitionAccessControlData,
  AccessLevelsTypesResponse,
  AirlockApiServerResponse,
  AcccessLevelsResponse,
} from 'features/accessControl/accessLevels/redux/types';
import {
  FETCH_ACCESS_LEVELS,
  FETCH_ACCESS_LEVELS_SUCCESS,
  FETCH_ACCESS_LEVELS_FAIL,
  FETCH_ACCESS_TYPES,
  FETCH_ACCESS_TYPES_SUCCESS,
  FETCH_ACCESS_TYPES_FAIL,
  FETCH_READER_GROUPS,
  FETCH_READER_GROUPS_SUCCESS,
  FETCH_READER_GROUPS_FAIL,
  FETCH_TIME_SPEC_GROUPS,
  FETCH_TIME_SPEC_GROUPS_SUCCESS,
  FETCH_TIME_SPEC_GROUPS_FAIL,
  CREATE_ACCESS_LEVEL,
  CREATE_ACCESS_LEVEL_SUCCESS,
  CREATE_ACCESS_LEVEL_FAIL,
  UPDATE_ACCESS_LEVEL,
  UPDATE_ACCESS_LEVEL_SUCCESS,
  UPDATE_ACCESS_LEVEL_FAIL,
  DELETE_ACCESS_LEVEL,
  DELETE_ACCESS_LEVEL_SUCCESS,
  DELETE_ACCESS_LEVEL_FAIL,
  FETCH_ACCESS_ASSIGNMENTS,
  FETCH_ACCESS_ASSIGNMENTS_SUCCESS,
  FETCH_ACCESS_ASSIGNMENTS_FAIL,
  FETCH_S2_SERVERS,
  FETCH_S2_SERVERS_SUCCESS,
  FETCH_S2_SERVERS_FAIL,
  DESTROY_ACCESS_ASSIGNMENTS,
  DESTROY_ACCESS_CONTROL_DATA,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAIL,
  EDIT_SCHEDULE,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAIL,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAIL,
  GET_SCHEDULES_LIST,
  SET_SCHEDULES_LIST_SUCCESS,
  SET_SCHEDULES_LIST_FAIL,
  GET_ACCESS_LEVEL,
  SET_ACCESS_LEVEL_SUCCESS,
  SET_ACCESS_LEVEL_FAIL,
  UPDATE_FEENICS_ACCESS_LEVEL,
  UPDATE_FEENICS_ACCESS_LEVEL_SUCCESS,
  UPDATE_FEENICS_ACCESS_LEVEL_FAIL,
  GET_COMPANY_INFO,
  GET_COMPANY_INFO_SUCCESS,
  GET_COMPANY_INFO_FAIL,
  GET_COMPANY_RESERVATIONS,
  GET_COMPANY_RESERVATIONS_SUCCESS,
  GET_COMPANY_RESERVATIONS_FAIL,
  CREATE_CUSTOM_ACCESS_LEVEL,
  CREATE_CUSTOM_ACCESS_LEVEL_SUCCESS,
  CREATE_CUSTOM_ACCESS_LEVEL_FAIL,
  DESTROY_CREATE_ACCESS_CONTROL_LEVEL_FORM,
  FETCH_ACCESS_LEVEL_PARTITION,
  FETCH_ACCESS_LEVEL_PARTITION_SUCCESS,
  FETCH_ACCESS_LEVEL_PARTITION_FAIL,
  GET_LOCATION_CUSTOM_ACCESS_LEVELS,
  GET_LOCATION_CUSTOM_ACCESS_LEVELS_SUCCESS,
  GET_LOCATION_CUSTOM_ACCESS_LEVELS_FAIL,
  REMOVE_CUSTOM_ACCESS_LEVEL,
  REMOVE_CUSTOM_ACCESS_LEVEL_SUCCESS,
  REMOVE_CUSTOM_ACCESS_LEVEL_FAIL,
  GET_ELEVATORS_LIST,
  GET_ELEVATORS_LIST_SUCCESS,
  GET_ELEVATORS_LIST_FAIL,
} from 'features/accessControl/accessLevels/redux/constants';
import {
  mapAccessLevelEntriesToFormReadableData,
  mapAccessLevelElevatorConfigurationToFormReadableData,
} from 'features/accessControl/accessLevels/accessLevelsUtils';
import {
  ElevatorsList,
  ScheduleAndReaderConfiguration,
} from 'features/accessControl/accessLevels/modals/modalTypes';

export interface State {
  timeSpecGroups: any[];
  misnamedAccessLevelsLoaded: boolean;
  accessAssignmentsLoading: boolean;
  s2ServersLoaded: boolean;
  buildingsAccessControl: BuildingAccessControlMap;
  error: null;
  timeSpecGroupsLoading: boolean;
  readerGroups: any[];
  updating: boolean;
  accessTypesLoaded: boolean;
  accessTypesLoading: boolean;
  accessAssignmentsLoaded: boolean;
  partitionId: string;
  controllerType: string;
  created: boolean;
  readerGroupsLoaded: boolean;
  accessTypes: any[];
  readerGroupsLoading: boolean;
  s2ServersLoading: boolean;
  s2ServersError: null;
  deleting: boolean;
  deleted: boolean;
  timeSpecGroupsLoaded: boolean;
  misnamedAccessLevels: AcccessLevelsResponse;
  creating: boolean;
  misnamedAccessLevelsLoading: boolean;
  accessAssignments: any[];
  updated: boolean;
  creatingSchedule: boolean;
  creatingScheduleSuccess: boolean;
  creatingScheduleFail: boolean;
  editingSchedule: boolean;
  editingScheduleSuccess: boolean;
  editingScheduleFail: boolean;
  scheduleSettings: any;
  scheduleSettingsLoading: boolean;
  scheduleSettingsFail: boolean;
  schedulesList: any[];
  schedulesListLoading: boolean;
  schedulesListFail: boolean;
  feenicsAccessLevel: {};
  feenicsAccessLevelLoading: boolean;
  feenicsAccessLevelFail: boolean;
  accessLevel: {};
  accessLevelEntries: ScheduleAndReaderConfiguration[] | any[];
  accessLevelElevatorConfigurations: any[];
  accessLevelLoading: boolean;
  accessLevelLoadingFail: boolean;
  updatingFeenicsAccessLevel: boolean;
  updatingFeenicsAccessLevelSuccess: boolean;
  updatingFeenicsAccessLevelFail: boolean;
  companyInformation: any[];
  companyInformationLoading: boolean;
  companyInformationLoadingSuccess: boolean;
  companyInformationLoadingFail: boolean;
  companyReservations: any[];
  companyReservationsLoading: boolean;
  companyReservationsLoadingSuccess: boolean;
  companyReservationsLoadingFail: boolean;
  creatingCustomAccessLevel: boolean;
  creatingCustomAccessLevelSuccess: boolean;
  creatingCustomAccessLevelFail: boolean;
  accessLevelPartition: any;
  fetchingAccessLevelPartition: boolean;
  fetchingAccessLevelPartitionFail: boolean;
  locationCustomAccessLevels: CustomAccessLevels[];
  locationCustomAccessLevelsLoading: boolean;
  locationCustomAccessLevelsLoadingSuccess: boolean;
  locationCustomAccessLevelsLoadingFail: boolean;
  removingCustomAccessLevel: boolean;
  removingCustomAccessLevelSuccess: boolean;
  removingCustomAccessLevelFail: boolean;
  elevatorsList: ElevatorsList[];
  loadingElevatorsList: boolean;
  loadingElevatorsListSuccess: boolean;
  loadingElevatorsListFail: boolean;
}

export interface AccessControlSubset {
  accessControl: State;
}

// Initial State
const initialState: State = {
  misnamedAccessLevelsLoading: false,
  misnamedAccessLevelsLoaded: false,
  misnamedAccessLevels: {},
  accessTypes: [],
  accessTypesLoading: false,
  accessTypesLoaded: false,
  readerGroupsLoading: false,
  readerGroupsLoaded: false,
  readerGroups: [],
  timeSpecGroupsLoading: false,
  timeSpecGroupsLoaded: false,
  timeSpecGroups: [],
  accessAssignments: [],
  accessAssignmentsLoading: false,
  accessAssignmentsLoaded: false,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  error: null,
  partitionId: '',
  controllerType: '',
  buildingsAccessControl: {} as BuildingAccessControlMap,
  s2ServersLoading: false,
  s2ServersLoaded: false,
  s2ServersError: null,
  creatingSchedule: false,
  creatingScheduleSuccess: false,
  creatingScheduleFail: false,
  editingSchedule: false,
  editingScheduleSuccess: false,
  editingScheduleFail: false,
  scheduleSettings: [],
  scheduleSettingsLoading: true,
  scheduleSettingsFail: false,
  schedulesList: [],
  schedulesListLoading: true,
  schedulesListFail: false,
  feenicsAccessLevel: {},
  feenicsAccessLevelLoading: true,
  feenicsAccessLevelFail: false,
  accessLevel: {},
  accessLevelEntries: [],
  accessLevelElevatorConfigurations: [],
  accessLevelLoading: true,
  accessLevelLoadingFail: false,
  updatingFeenicsAccessLevel: false,
  updatingFeenicsAccessLevelSuccess: false,
  updatingFeenicsAccessLevelFail: false,
  companyInformation: [],
  companyInformationLoading: false,
  companyInformationLoadingSuccess: false,
  companyInformationLoadingFail: false,
  companyReservations: [],
  companyReservationsLoading: false,
  companyReservationsLoadingSuccess: false,
  companyReservationsLoadingFail: false,
  creatingCustomAccessLevel: false,
  creatingCustomAccessLevelSuccess: false,
  creatingCustomAccessLevelFail: false,
  accessLevelPartition: {},
  fetchingAccessLevelPartition: false,
  fetchingAccessLevelPartitionFail: false,
  locationCustomAccessLevels: [],
  locationCustomAccessLevelsLoading: false,
  locationCustomAccessLevelsLoadingSuccess: false,
  locationCustomAccessLevelsLoadingFail: false,
  removingCustomAccessLevel: false,
  removingCustomAccessLevelSuccess: false,
  removingCustomAccessLevelFail: false,
  elevatorsList: [],
  loadingElevatorsList: false,
  loadingElevatorsListSuccess: false,
  loadingElevatorsListFail: false,
};

const NAKEDHUB_PARTITION_TYPE = 'nakedhub';

const hasPartitionAccessControl = (partition): boolean => {
  // There is no access control for nakedhub partitions
  return partition.building_uuid && partition.partition_type !== NAKEDHUB_PARTITION_TYPE;
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_ACCESS_LEVELS]: state => ({
      ...state,
      misnamedAccessLevelsLoading: true,
      misnamedAccessLevelsLoaded: false,
    }),

    [FETCH_ACCESS_LEVELS_SUCCESS]: (state, action) => ({
      ...state,
      misnamedAccessLevelsLoading: false,
      misnamedAccessLevelsLoaded: true,
      misnamedAccessLevels: action.payload.result.access_levels,
      partitionId: action.payload.result.partition_id,
      controllerType: action.payload.result.controller_type,
      error: null,
    }),

    [FETCH_ACCESS_LEVELS_FAIL]: (state, action) => ({
      ...state,
      misnamedAccessLevelsLoading: false,
      misnamedAccessLevelsLoaded: false,
      misnamedAccessLevels: {},
      error: action.payload.message,
    }),

    [FETCH_READER_GROUPS]: state => ({
      ...state,
      readerGroupsLoading: true,
      readerGroupsLoaded: false,
    }),

    [FETCH_READER_GROUPS_SUCCESS]: (state, action) => ({
      ...state,
      readerGroupsLoading: false,
      readerGroupsLoaded: true,
      readerGroups: action.payload.result.reader_groups,
      error: null,
    }),

    [FETCH_READER_GROUPS_FAIL]: (state, action) => ({
      ...state,
      readerGroupsLoading: false,
      readerGroupsLoaded: false,
      readerGroups: [],
      error: action.payload.message,
    }),

    [FETCH_TIME_SPEC_GROUPS]: state => ({
      ...state,
      timeSpecGroupsLoading: true,
      timeSpecGroupsLoaded: false,
    }),

    [FETCH_TIME_SPEC_GROUPS_SUCCESS]: (state, action) => ({
      ...state,
      timeSpecGroupsLoading: false,
      timeSpecGroupsLoaded: true,
      timeSpecGroups: action.payload.result.time_spec_groups,
      error: null,
    }),

    [FETCH_TIME_SPEC_GROUPS_FAIL]: (state, action) => ({
      ...state,
      timeSpecGroupsLoading: false,
      timeSpecGroupsLoaded: false,
      timeSpecGroups: [],
      error: action.payload.message,
    }),

    [CREATE_ACCESS_LEVEL]: state => ({
      ...state,
      creating: true,
      created: false,
    }),

    [CREATE_ACCESS_LEVEL_SUCCESS]: state => ({
      ...state,
      creating: false,
      created: true,
      error: null,
    }),

    [CREATE_ACCESS_LEVEL_FAIL]: (state, action) => ({
      ...state,
      creating: false,
      created: false,
      error: action.payload.message,
    }),

    [UPDATE_ACCESS_LEVEL]: state => ({
      ...state,
      updating: true,
      updated: false,
    }),

    [UPDATE_ACCESS_LEVEL_SUCCESS]: state => ({
      ...state,
      updating: false,
      updated: true,
      error: null,
    }),

    [UPDATE_ACCESS_LEVEL_FAIL]: (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.payload.message,
    }),

    [DELETE_ACCESS_LEVEL]: state => ({
      ...state,
      deleting: true,
      deleted: false,
    }),

    [DELETE_ACCESS_LEVEL_SUCCESS]: state => ({
      ...state,
      deleting: false,
      deleted: true,
      error: null,
    }),

    [DELETE_ACCESS_LEVEL_FAIL]: (state, action) => ({
      ...state,
      deleting: false,
      deleted: false,
      error: action.payload.message,
    }),

    [FETCH_ACCESS_TYPES]: state => ({
      ...state,
      accessTypesLoading: true,
      accessTypesLoaded: false,
    }),

    [FETCH_ACCESS_TYPES_SUCCESS]: (
      state: State,
      action: Action<AccessLevelsTypesResponse>
    ): State => ({
      ...state,
      accessTypesLoading: false,
      accessTypesLoaded: true,
      accessTypes: action.payload.result.types,
    }),

    [FETCH_ACCESS_TYPES_FAIL]: (state, action) => ({
      ...state,
      accessTypesLoading: false,
      accessTypesLoaded: false,
      accessTypes: [],
      error: action.payload.message,
    }),

    [FETCH_ACCESS_ASSIGNMENTS]: state => ({
      ...state,
      accessAssignmentsLoading: true,
      accessAssignmentsLoaded: false,
    }),

    [FETCH_ACCESS_ASSIGNMENTS_SUCCESS]: (state, action) => ({
      ...state,
      accessAssignmentsLoading: false,
      accessAssignmentsLoaded: true,
      accessAssignments: action.payload.result.users,
    }),

    [FETCH_ACCESS_ASSIGNMENTS_FAIL]: (state, action) => ({
      ...state,
      accessAssignmentsLoading: false,
      accessAssignmentsLoaded: false,
      accessAssignments: [],
      error: action.payload.message,
    }),

    [DESTROY_ACCESS_ASSIGNMENTS]: state => ({
      ...state,
      accessAssignments: [],
      accessAssignmentsLoading: false,
      accessAssignmentsLoaded: false,
    }),

    [FETCH_S2_SERVERS]: state => ({
      ...state,
      s2ServersLoading: true,
      s2ServersLoaded: false,
      s2ServersError: initialState.s2ServersError,
    }),

    [FETCH_S2_SERVERS_SUCCESS]: (state: State, action: Action<AirlockApiServerResponse>): State => {
      const buildingsAccessControl = action.payload.result
        .flatMap(
          s2Server =>
            s2Server.partitions
              .map(partition => {
                if (hasPartitionAccessControl(partition)) {
                  return {
                    buildingUuid: partition.building_uuid,
                    partitionId: partition.id,
                    partitionName: partition.name,
                    partitionType: partition.partition_type,
                    serverId: s2Server.id,
                    serverName: s2Server.name,
                  };
                }
                return undefined;
              })
              .filter(Boolean) as Array<PartitionAccessControlData>
        )
        .reduce((buildingsAccessControl: Hash<PartitionAccessControlData>, accessData) => {
          buildingsAccessControl[accessData.buildingUuid] = accessData;

          return buildingsAccessControl;
        }, {});

      return {
        ...state,
        s2ServersLoading: false,
        s2ServersLoaded: true,
        buildingsAccessControl,
      };
    },

    [FETCH_S2_SERVERS_FAIL]: (state, action) => ({
      ...state,
      s2ServersLoading: false,
      s2ServersLoaded: true,
      s2ServersError: action.payload.message,
    }),

    [DESTROY_ACCESS_CONTROL_DATA]: state => ({
      ...state,
      misnamedAccessLevelsLoaded: false,
      readerGroupsLoaded: false,
      timeSpecGroupsLoaded: false,
    }),

    [CREATE_SCHEDULE]: state => ({
      ...state,
      creatingSchedule: true,
    }),

    [CREATE_SCHEDULE_SUCCESS]: state => ({
      ...state,
      creatingSchedule: false,
      creatingScheduleSuccess: true,
    }),

    [CREATE_SCHEDULE_FAIL]: state => ({
      ...state,
      creatingSchedule: false,
      creatingScheduleSuccess: false,
      creatingScheduleFail: true,
    }),

    [EDIT_SCHEDULE]: state => ({
      ...state,
      editingSchedule: true,
    }),

    [EDIT_SCHEDULE_SUCCESS]: state => ({
      ...state,
      editingSchedule: false,
      editingScheduleSuccess: true,
    }),

    [EDIT_SCHEDULE_FAIL]: state => ({
      ...state,
      editingSchedule: false,
      editingScheduleSuccess: false,
      editingScheduleFail: true,
    }),

    [GET_SCHEDULE]: state => ({
      ...state,
      scheduleSettings: [],
      scheduleSettingsLoading: true,
    }),

    [GET_SCHEDULE_SUCCESS]: (state, action) => {
      const { name, key, configurations, access_levels } = action.payload.result.schedule;
      const config = configurations.find(config => config);

      const scheduleSettings = {
        name,
        key,
        accessLevels: access_levels,
        configuration: {
          starting_at: config?.starting_at,
          duration: config?.duration,
          days: config?.days,
        },
      };

      return {
        ...state,
        scheduleSettings,
        scheduleSettingsLoading: false,
      };
    },

    [GET_SCHEDULE_FAIL]: state => ({
      ...state,
      scheduleSettings: [],
      scheduleSettingsLoading: false,
      scheduleSettingsFail: true,
    }),

    [GET_SCHEDULES_LIST]: state => ({
      ...state,
      schedulesListLoading: true,
    }),

    [SET_SCHEDULES_LIST_SUCCESS]: (state, action) => ({
      ...state,
      schedulesList: action.payload.result.schedules,
      schedulesListLoading: false,
    }),

    [SET_SCHEDULES_LIST_FAIL]: state => ({
      ...state,
      schedulesList: [],
      schedulesListLoading: false,
      schedulesListFail: true,
    }),

    [GET_ACCESS_LEVEL]: state => ({
      ...state,
      accessLevelLoading: true,
      accessLevelLoadingFail: false,
    }),

    [SET_ACCESS_LEVEL_SUCCESS]: (state, action) => ({
      ...state,
      accessLevel: action.payload.result,
      accessLevelEntries: mapAccessLevelEntriesToFormReadableData(
        action.payload.result.access_level.access_level_entries
      ),
      accessLevelElevatorConfigurations: mapAccessLevelElevatorConfigurationToFormReadableData(
        action.payload.result.access_level.elevator_access_level_entries
      ),
      accessLevelLoading: false,
    }),

    [SET_ACCESS_LEVEL_FAIL]: state => ({
      ...state,
      accessLevel: {},
      accessLevelEntries: [],
      accessLevelElevatorConfigurations: [],
      accessLevelLoading: false,
      accessLevelLoadingFail: true,
    }),

    [UPDATE_FEENICS_ACCESS_LEVEL]: state => ({
      ...state,
      updatingFeenicsAccessLevel: true,
      updatingFeenicsAccessLevelSuccess: false,
    }),

    [UPDATE_FEENICS_ACCESS_LEVEL_SUCCESS]: state => ({
      ...state,
      updatingFeenicsAccessLevel: false,
      updatingFeenicsAccessLevelSuccess: true,
    }),

    [UPDATE_FEENICS_ACCESS_LEVEL_FAIL]: state => ({
      ...state,
      updatingFeenicsAccessLevel: false,
      updatingFeenicsAccessLevelSuccess: false,
      updatingFeenicsAccessLevelFail: true,
    }),

    [GET_COMPANY_INFO]: state => ({
      ...state,
      companyInformation: [],
      companyInformationLoading: true,
    }),

    [GET_COMPANY_INFO_SUCCESS]: (state, action) => ({
      ...state,
      companyInformation: action.payload.result,
      companyInformationLoading: false,
      companyInformationLoadingSuccess: true,
    }),

    [GET_COMPANY_INFO_FAIL]: state => ({
      ...state,
      companyInformation: [],
      companyInformationLoading: false,
      companyInformationLoadingSuccess: false,
      companyInformationLoadingFail: true,
    }),

    [GET_COMPANY_RESERVATIONS]: state => ({
      ...state,
      companyReservations: [],
      companyReservationsLoading: true,
    }),

    [GET_COMPANY_RESERVATIONS_SUCCESS]: (state, action) => ({
      ...state,
      companyReservations: action.payload.result,
      companyReservationsLoading: false,
      companyReservationsLoadingSuccess: true,
    }),

    [GET_COMPANY_RESERVATIONS_FAIL]: state => ({
      ...state,
      companyReservations: [],
      companyReservationsLoading: false,
      companyReservationsLoadingSuccess: false,
      companyReservationsLoadingFail: true,
    }),

    [CREATE_CUSTOM_ACCESS_LEVEL]: state => ({
      ...state,
      creatingCustomAccessLevel: true,
    }),

    [CREATE_CUSTOM_ACCESS_LEVEL_SUCCESS]: state => ({
      ...state,
      creatingCustomAccessLevel: false,
      creatingCustomAccessLevelSuccess: true,
    }),

    [CREATE_CUSTOM_ACCESS_LEVEL_FAIL]: state => ({
      ...state,
      creatingCustomAccessLevel: false,
      creatingCustomAccessLevelSuccess: false,
      creatingCustomAccessLevelFail: true,
    }),

    [DESTROY_CREATE_ACCESS_CONTROL_LEVEL_FORM]: state => ({
      ...state,
      creatingCustomAccessLevel: false,
      creatingCustomAccessLevelSuccess: false,
      creatingCustomAccessLevelFail: false,
      companyReservations: [],
      companyReservationsLoading: false,
      companyReservationsLoadingSuccess: false,
      companyReservationsLoadingFail: false,
      companyInformation: [],
      companyInformationLoading: false,
      companyInformationLoadingSuccess: false,
      companyInformationLoadingFail: false,
    }),

    [FETCH_ACCESS_LEVEL_PARTITION]: state => ({
      ...state,
      fetchingAccessLevelPartition: true,
    }),

    [FETCH_ACCESS_LEVEL_PARTITION_SUCCESS]: (state, action) => ({
      ...state,
      accessLevelPartition: action.payload.result,
      fetchingAccessLevelPartition: false,
    }),

    [FETCH_ACCESS_LEVEL_PARTITION_FAIL]: state => ({
      ...state,
      accessLevelPartition: {},
      fetchingAccessLevelPartition: false,
      fetchingAccessLevelPartitionFail: true,
    }),

    [GET_LOCATION_CUSTOM_ACCESS_LEVELS]: (state: State): State => ({
      ...state,
      locationCustomAccessLevelsLoading: true,
      locationCustomAccessLevelsLoadingSuccess: false,
      locationCustomAccessLevelsLoadingFail: false,
    }),

    [GET_LOCATION_CUSTOM_ACCESS_LEVELS_SUCCESS]: (state: State, action): State => ({
      ...state,
      locationCustomAccessLevels: action.payload.result,
      locationCustomAccessLevelsLoadingSuccess: true,
      locationCustomAccessLevelsLoading: false,
    }),

    [GET_LOCATION_CUSTOM_ACCESS_LEVELS_FAIL]: (state: State): State => ({
      ...state,
      locationCustomAccessLevels: [],
      locationCustomAccessLevelsLoading: false,
      locationCustomAccessLevelsLoadingSuccess: false,
      locationCustomAccessLevelsLoadingFail: true,
    }),

    [REMOVE_CUSTOM_ACCESS_LEVEL]: (state: State): State => ({
      ...state,
      removingCustomAccessLevel: true,
    }),

    [REMOVE_CUSTOM_ACCESS_LEVEL_SUCCESS]: (state: State): State => ({
      ...state,
      removingCustomAccessLevel: false,
      removingCustomAccessLevelSuccess: true,
    }),

    [REMOVE_CUSTOM_ACCESS_LEVEL_FAIL]: (state: State): State => ({
      ...state,
      removingCustomAccessLevel: false,
      removingCustomAccessLevelSuccess: false,
      removingCustomAccessLevelFail: true,
    }),

    [GET_ELEVATORS_LIST]: state => ({
      ...state,
      loadingElevatorsList: true,
    }),

    [GET_ELEVATORS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      elevatorsList: action.payload.result.elevator_access_levels,
      loadingElevatorsList: false,
      loadingElevatorsListSuccess: true,
    }),

    [GET_ELEVATORS_LIST_FAIL]: state => ({
      ...state,
      elevatorsList: [],
      loadingElevatorsList: false,
      loadingElevatorsListSuccess: false,
      loadingElevatorsListFail: true,
    }),
  },
  initialState
);

export default reducer;
