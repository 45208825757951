import { handleActions } from 'redux-actions';
import { keyBy } from 'lodash';

import { Resource } from 'features/invoiceReview/types';
import { State, PayloadAction } from 'features/companies/redux/resources/types';
import {
  FETCH_RESOURCES,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAIL,
} from 'features/companies/redux/resources/constants';

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byUuid: {},
  error: null,
};

const keyByUuid = (data: Array<Resource>) => keyBy(data, (res): string => res.uuid);

export const reducer = handleActions<State, any>(
  {
    [FETCH_RESOURCES]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_RESOURCES_SUCCESS]: (state: State, action: PayloadAction): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byUuid: keyByUuid(action.payload.result),
    }),

    [FETCH_RESOURCES_FAIL]: (state: State, action: PayloadAction): State => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
