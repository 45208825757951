import { SerializerConfig } from '../types';
import itemsSerializer from '../serializers/itemsSerializer';

import SerializersMap from './serializersMap';

export const SerializersMapWithItems: Hash<SerializerConfig> = {
  ...SerializersMap,
  items: { serializer: itemsSerializer, serializerKey: 'items', summable: false },
};

export default SerializersMapWithItems;
