import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { ApiError } from 'store/errors';

// TODO: should this merge with ../siLocations/api.ts ? copy/pasted method.
// Leaving this temporarily intact to avoid merge conflicts
export const fetchInventory = async (query: string, variables: Hash<any>) => {
  const accessToken = await getAccessToken();
  const body = JSON.stringify({
    query,
    variables,
  });

  const response = await fetch(`${config.siGateway.uri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'apollographql-client-name': 'spacestation-web',
      'x-request-id': uuidv4(),
    },
    body,
  });

  if (!response.ok) {
    throw new Error('non-200 response 💔');
  }
  if (response.status !== 200) {
    throw new ApiError(
      response.status,
      response.statusText,
      response,
      `Failed to fetch SSO status from Account Service (${response.status})`,
      response.url
    );
  }

  return response.json();
};
