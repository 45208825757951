// We are using these custom methods in stead of a library (numeral, parse-decimal-separator, cldr)
// for parsing localized numbers for a couple of reasons.
// 1. The libraries require the locale to be known. So let's say you are sending paperwork for a
//    German member, but you use a . (dot) instead of a , (comma) or you are sending paperwork for
//    a US member and you use a , (comma) instead of a . (dot). The libraries will return a value,
//    but it may be NaN or off by a factor of 100.
//
// 2. The input we are expecting is restricted to:
//      - one or more digits, a separator, zero or more digits
//      - a separator followed by one or more digits
//    for which the regular expression is fairly straightforward.
//
// 3. The underlying regex is well tested, has flow types, and should only be used in the context
//    of Discounts.
//
// Note: This parser should be used in conjunction with the DiscountInput component because of the
// following assumptions:
//   - The input is expected to be between 0 and 100 (inclusive)
//   - The input is expected to represent a percentage
//   - The empty string is valid input
export const isParseable = (discount: string): boolean =>
  /^[0-9]+(,|\.|')?[0-9]*$/.test(discount) || /^(,|\.|')[0-9]+$/.test(discount);

export const isSeparator = (discount: string): boolean => /^(\.|,|')$/.test(discount);

export const normalize = (discount: string): string => discount.replace(/(,|')/, '.');

export const parseDiscount = (discount: string): number => {
  if (isParseable(discount)) {
    return parseFloat(normalize(discount)) / 100;
  } else if (isSeparator(discount) || discount.length === 0) {
    return 0;
  }
  return NaN;
};
