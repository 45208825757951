import config from 'config';
import { createRequestAction, createRequestReducer } from 'store/util';
import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_PAPERWORK_LOCALES,
  FETCH_PAPERWORK_LOCALES_SUCCESS,
  FETCH_PAPERWORK_LOCALES_FAIL,
] = createRequestConstantNames(cc('FETCH_PAPERWORK_LOCALES'));

export type State = Readonly<{
  loading: boolean;
  loaded: boolean;
  result: PaperworkLocalesByLocale;
  error: null | Error;
}>;

export const initialState: State = {
  loading: false,
  loaded: false,
  result: {},
  error: null,
};

export type PaperworkLocale = { locale: string; language: string };
// e.g. key is "en-JP".
export type PaperworkLocalesByLocale = Hash<Array<PaperworkLocale>>;

export const reducer = createRequestReducer<PaperworkLocalesByLocale>(
  [FETCH_PAPERWORK_LOCALES, FETCH_PAPERWORK_LOCALES_SUCCESS, FETCH_PAPERWORK_LOCALES_FAIL],
  {}
);

export const fetchPaperworkLocales = () =>
  createRequestAction({
    method: 'GET',
    endpoint: `${config.spacemoney.uri}/api/v2/paperwork_locales`,
    types: [FETCH_PAPERWORK_LOCALES, FETCH_PAPERWORK_LOCALES_SUCCESS, FETCH_PAPERWORK_LOCALES_FAIL],
  });

export default reducer;
