import { SpacemanResponse } from 'types/api';
import { ToastType } from 'store/modules/toasts/constants';

import {
  MOVE_DIRECTION_TYPES,
  MimoLabel,
  TENURE_TYPES,
  TaskType,
  MOVE_STATUSES,
  REQUEST_TASK_STATUSES,
  PARAM_TYPE,
} from './constants';

export type MoveDirection = typeof MOVE_DIRECTION_TYPES[keyof typeof MOVE_DIRECTION_TYPES];

export type TenureType = typeof TENURE_TYPES[keyof typeof TENURE_TYPES];

export type MoveStatus = typeof MOVE_STATUSES[keyof typeof MOVE_STATUSES];

export type AccountMetadata = Readonly<{
  movingInLocationUuids: Array<string>;
  movingOutLocationUuids: Array<string>;
  hasInternalTransfer: boolean;
}>;

export type EmailTemplatesResponse<
  Result extends {},
  Params extends {} = {},
  Query extends {} = {}
> = {
  meta: { params: Params; query: Query; count?: number };
  result: Result;
  error?: string | null;
};

export type EmailInfo = {
  fromEmail: string;
  fromName: string;
  toEmail: string;
  toName: string;
};

export type Money = {
  amount: number;
  currency: string;
};

export type ServiceRetainer = Readonly<{
  outstandingSr: Money;
  currentSrBalance: Money;
  finalized: boolean;
  numberOfOffices: number;
}>;

export type EmailCommunications = EmailInfo & {
  templateName: string;
  localizedTemplateSlug: string;
  transferLocationName: string;
};

export type Account = Readonly<{
  name: string;
  uuid: string;
  emailTemplate: string;
  primaryMemberEmail: string;
  primaryMemberLocale: string;
  primaryMemberName: string;
  primaryMemberUuid: string;
  transferLocationUuid?: string;
  accountMetadata: AccountMetadata;
  emailCommunications: EmailCommunications;
  serviceRetainer?: ServiceRetainer;
}>;

export type SentEmailData = {
  sentAt: string | null | undefined;
  sentBy: string | null | undefined;
  templateName: string | null | undefined;
  templateType: string | null | undefined;
  isManuallySent?: boolean;
  sentState?: string;
};

type BaseMove = {
  id: string;
  moveDirection: MoveDirection;
  moveType?: string;
  moveDate: string;
  moveTime: string;
  sentEmail?: SentEmailData;
};

export type MoveWithReservableInfo = BaseMove & {
  reservableName: string;
  reservableType: string;
};

export type Move = BaseMove & {
  account: Account;
  reservable?: Reservable;
  note: string | null | undefined;
  moveStatus: MoveStatus;
  tenure: TenureType | null | undefined;
  isSelfServe: boolean;
  labels?: ReadonlyArray<MimoLabel>;
  outOfFilterScope?: boolean;
  createdAt: string;
};

export type AccountWithMoves = Account & {
  moves: Array<MoveWithReservableInfo>;
  hasEmailSent: boolean;
  sentEmailStatus: string;
  isTemplateMissing: boolean;
  isLocaleSupported: boolean;
};

export type Reservable = {
  uuid: string;
  name: string;
  type: string;
  capacity: number;
  floor: number;
  description: string;
};

export type MimoReservable = Reservable & {
  moveIns: Move[];
  moveOuts: Move[];
};

export type MimoEmailUser = {
  id: string;
};

type MimoMessage = {
  id: string;
  sentAt: string | null | undefined;
  openedAt: string | null | undefined;
  createdAt: string;
  updatedAt: string;
  type: string;
  user: MimoEmailUser | null | undefined;
};

export type MimoManualMessage = {
  createdAt: number;
  user: MimoEmailUser | null | undefined;
};

export type MimoEmail = {
  message?: MimoMessage;
  manualMessage?: MimoManualMessage;
};

export type EmailReservableVariable = {
  reservableTypeCategory: string;
  reservableName: string;
};

export type EmailVariable = Readonly<{
  name: string;
  value: string | Array<EmailReservableVariable> | null | undefined;
  type: Values<typeof PARAM_TYPE>;
}>;

type BaseSendEmailParams = Readonly<{
  templateSlug: string;
  code?: string;
  metadata: Hash<string>;
  subject?: string;
  toEmail: string;
  toName: string;
  type: string;
  vars: Array<EmailVariable>;
  moveIds: Array<string>;
  isBulk: boolean;
}>;

export type SendEmailParams = BaseSendEmailParams &
  Readonly<{
    ccEmail: string;
    fromEmail: string;
    fromName: string;
    locationCode: string; // locationCode is used to create a MIMO email template in the Email templates API
    group: string;
  }>;

export type BulkSendEmailParams = Readonly<{
  fromEmail: string;
  fromName: string;
  ccEmail: string;
  locationCode: string;
  group: string;
  bulkEmailInfo: Array<Omit<BaseSendEmailParams, 'isBulk'>>;
}>;

type Task<T extends TaskType, D> = {
  id: string;
  version: number;
  title: string;
  taskType: T;
  completed: boolean;
  locationUuid: string;
  createdBy: string | null | undefined;
  updatedBy: string | null | undefined;
  dueDate: string;
  referenceUuid: string;
  referenceType: string;
  createdAt: string;
  updatedAt: string;
  startDate: string;
  data: D;
};

export type CardSelection = {
  move: Move;
  reservable: MimoReservable;
  index?: number;
};

// Moves Portal
export type MMPTaskStatus = typeof REQUEST_TASK_STATUSES[keyof typeof REQUEST_TASK_STATUSES];

type TicketStatusTaskData = {
  canceled?: boolean;
  ticketStatus: MMPTaskStatus;
  internalNote?: string;
};

export type MimoChecklistTaskData = TicketStatusTaskData & {
  period: string;
  orderIndex: number;
  automated: boolean;
  ticketStatus: string;
};

export type FurnitureTaskData = TicketStatusTaskData & {
  chairs: number;
  desks: number;
  cabinets: number;
  reservableUuid: string;
  reservableName: string;
  ticketStatus: string;
  moveUuids: string[];
};

export type OptionTaskTypes =
  | 'movers'
  | 'change_datetime'
  | 'it'
  | 'additional_customization'
  | 'freight_elevator'
  | 'trolley';

export type OptionTaskData = TicketStatusTaskData & {
  serviceType: OptionTaskTypes;
  moveUuids: string[];
};

export type FileData = {
  url: string;
  originalFilename: string;
};

export type LogoTaskData = TicketStatusTaskData &
  FileData & {
    vendorId: string;
    vendorName: string;
    vendorOrderStatus: string;
    moveUuids: string[];
  };

export type LogisticsTaskData = TicketStatusTaskData & {
  movers: {
    company: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string | null;
  } | null;
  freightElevator: boolean;
  coi: FileData | null;
  moveUuids: string[];
};

export type IDCompany = {
  uuid: string;
  name: string;
  status: string;
  isPotential: boolean;
};

export type FurnitureTask = Task<TaskType.REQUEST_FURNITURE, FurnitureTaskData>;
export type OptionTask = Task<TaskType.REQUEST_OPTION, OptionTaskData>;
export type LogoTask = Task<TaskType.REQUEST_LOGO, LogoTaskData>;
export type LogisticsTask = Task<TaskType.REQUEST_LOGISTICS, LogisticsTaskData>;
export type MoveInTask = Task<TaskType.REQUEST_MOVE_IN, MimoChecklistTaskData>;
export type MoveOutTask = Task<TaskType.REQUEST_MOVE_OUT, MimoChecklistTaskData>;
export type ExternalTransferOutTask = Task<
  TaskType.REQUEST_EXTERNAL_TRANSFER_OUT,
  MimoChecklistTaskData
>;
export type ExternalTransferInTask = Task<
  TaskType.REQUEST_EXTERNAL_TRANSFER_IN,
  MimoChecklistTaskData
>;
export type InternalTransferTask = Task<TaskType.REQUEST_INTERNAL_TRANSFER, MimoChecklistTaskData>;

export type MimoChecklistTask =
  | MoveInTask
  | MoveOutTask
  | ExternalTransferOutTask
  | ExternalTransferInTask
  | InternalTransferTask;

export type ReservableRequest = FurnitureTask | OptionTask | LogoTask | LogisticsTask;

export type ReservableRequestWithCompany = ReservableRequest & {
  company: IDCompany;
};

export type ReservableRequestWithCapacity = FurnitureTask & {
  company: IDCompany;
  capacity?: number;
};

export type RequestsList = Array<ReservableRequest>;
export type MMPWhitelist = Hash<boolean>;
export type ReminderInfoType = Readonly<{
  firstReminderEstimatedAt: string | null;
  firstReminderSentAt: string | null;
  secondReminderSentAt: string | null;
}>;
export type FetchCompaniesFromIDResponse = SpacemanResponse<Array<IDCompany>>;

export type MoveTimes = {
  move_in_time: string;
  move_out_time: string;
};

export enum MMPBuildingSetting {
  Enabled = 'mmp_enabled',
  Freight = 'show_freight',
  COITemplate = 'coi_template',
  COIRequirement = 'coi_requirement',
  Furniture = 'furniture',
}

export type COITemplate = {
  key: string;
  url?: string;
};
export type MMPCOIRequirementOption = 'freight' | 'movers';
export type MMPFurnitureAvailability = 'chair' | 'desk' | 'cabinet';
export type MMPToggleOption = MMPCOIRequirementOption | MMPFurnitureAvailability;

export type MMPBuildingSettings = {
  [MMPBuildingSetting.Enabled]: boolean;
  [MMPBuildingSetting.Freight]: boolean;
  [MMPBuildingSetting.COITemplate]: COITemplate | null;
  [MMPBuildingSetting.COIRequirement]: MMPCOIRequirementOption[];
  [MMPBuildingSetting.Furniture]: MMPFurnitureAvailability[];
};

export type BuildingSettings = {
  moveTimes: MoveTimes;
  mmp: MMPBuildingSettings;
};

export interface UpdateTaskOptimistically {
  task: MimoChecklistTask;
  completed: boolean;
}

export interface MetaType {
  taskId: string;
  notification: {
    message: string;
    type: typeof ToastType;
  };
}

export interface SelectedMimoCard {
  reservableUuid: string;
  moveId: string;
  moveDirection: string;
  accountUuid: string;
}

export interface TaskPeriodMetaData {
  name: string;
  startDate: string;
  dueDate: string;
}

export interface PeriodMetaDataByPeriod {
  [key: string]: TaskPeriodMetaData;
}

export interface TasksByPeriod {
  [key: string]: MimoChecklistTask[];
}
