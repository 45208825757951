import React, { Component } from 'react';
import cn from 'classnames';
import Async from 'react-select/async';
import { get, includes, isEqual, isFunction, isObject, keys, map, pick } from 'lodash';
import { SelectComponentsConfig, OptionsOrGroups } from 'react-select';

import { deprecated_isPresent } from 'lib/util';
import formStyles from 'styles/form.scss';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
} from 'components-dieter/base/styled-react-select';

import styles from './baseSearchAutoComplete.scss';

type Value = {} | string;

export type Option<T = any> = {
  data: T;
  label: string;
  value: string;
};

export type Props = Readonly<{
  allowNew?: boolean;
  className?: string;
  disabled?: boolean;
  doSearchAction: (arg0: string, arg1: () => void) => void;
  filterOptions: () => boolean;
  getResults: () => Array<Value>;
  multiple: boolean;
  name: string;
  onChange: (arg0: string | null | undefined) => void;
  onClear?: () => void;
  onSelect: (arg0: string, arg1: {}, arg2: Array<{}>) => void;
  optionLabel: ((option: Option<any>) => string) | string;
  placeholder?: string;
  prefillMemberValue?: string;
  renderOption?: (option: Option<any>) => React.ReactElement;
  size?: string;
  value?: Value;
}>;

type NewItemType = {
  name: string | null | undefined;
  uuid: string | null | undefined;
  label: string | null | undefined;
} | null;

type State = Readonly<{ newItem: NewItemType }>;

const defaultState: State = {
  newItem: null,
};

class BaseSearchAutocomplete extends Component<Props, State> {
  static defaultProps = {
    allowNew: false,
    multiple: false,
    filterOptions: (): boolean => true,
  };

  state = defaultState;

  select: React.LegacyRef<Async> = null;

  resolveValue = (object: Value, resolver: ((option: Value) => string) | string) => {
    if (isFunction(resolver)) {
      return resolver.call(this, object);
    }
    return get(object, resolver);
  };

  asyncOptions = (input: string, callback: (result: OptionsOrGroups<Option, any>) => void) => {
    const { allowNew, doSearchAction, prefillMemberValue, value } = this.props;

    if (!input) {
      callback([]);
      return;
    }

    // we don't do anything if we are reacting to a onChange. We only want to react to
    // a user input which will not have the "uuid:" prepended to the input.
    if (allowNew && !includes(input, 'uuid:')) {
      const newItem = {
        name: input,
        uuid: null,
        label: input,
      };
      this.updateState({ newItem });
    }

    const prefillMemberQuery =
      prefillMemberValue && isObject(value) && get(value, 'prefillMemberValue');
    const searchQuery = prefillMemberQuery || input;

    doSearchAction(searchQuery, () => {
      callback(this.getOptions());
    });
  };

  updateState = (nextState: State) => {
    // only update the state if it changed to save on updates
    // and prevent a render loop
    const shouldUpdate = !isEqual(pick(this.state, keys(nextState)), nextState);

    if (shouldUpdate) {
      this.setState(nextState);
    }
  };

  updateValue = (newValue: string | null | undefined) => {
    // only update the state if it changed to save on updates
    // and prevent a render loop
    if (!isEqual(this.props.value, newValue)) {
      this.props.onChange(newValue);
    }
  };

  // $FlowFixMe TODO I have no idea what this flow type is
  changeValue = (selectedOptions): void => {
    let value = '';

    if (selectedOptions) {
      value = this.props.multiple
        ? map(selectedOptions, option => option.data)
        : selectedOptions.data;
    }

    const newItem: NewItemType = {
      // $FlowFixMe TODO value might be an array?
      name: value,
      // eslint-disable-next-line
      uuid: selectedOptions ? (selectedOptions.data ? selectedOptions.data.uuid : null) : '',
      label: selectedOptions ? selectedOptions.label : '',
    };

    this.setState({ newItem });

    // $FlowFixMe TODO value might be an array?
    this.updateValue(value);

    if (isFunction(this.props.onSelect)) {
      // $FlowFixMe TODO value might be an array?
      this.props.onSelect(value, selectedOptions ? selectedOptions.data : {}, this.getOptions());
    }

    if (!deprecated_isPresent(selectedOptions)) {
      this.clearValue();
    }
  };

  clearValue = () => {
    this.updateState(defaultState);

    this.updateValue(null);

    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  getOptions = (newItem: NewItemType | null = null) => {
    const results = this.props.getResults();

    // @ts-ignore
    const filteredOptions = results.filter(item => !!item.objectID);

    // @ts-ignore
    filteredOptions.unshift(this.props.value);

    // @ts-ignore
    filteredOptions.unshift(newItem ?? this.state.newItem);

    return filteredOptions
      .filter(Boolean)
      .flat()
      .filter(this.props.filterOptions)
      .map<{
        label: string;
        value: string;
        data: Value;
      }>((option: Value) => {
        // We want to map our list of resolved items to selectable
        // options. To distinguish between a resolved item value
        // and a user inputted query, we prefix resolved values with
        // "uuid:". To distinguish between new items and presisted
        // items, we use "new_item" as the uuid.
        const value = typeof option === 'string' ? option : get(option, 'uuid', 'new_item');
        return {
          label: this.resolveValue(option, this.props.optionLabel),
          value: `uuid:${value}`,
          data: option,
        };
      });
  };

  setSelectRef = select => {
    this.select = select;
  };

  render() {
    const {
      className,
      disabled,
      size,
      multiple,
      name,
      placeholder,
      renderOption,
      value,
    } = this.props;

    const selected = value && {
      // if an input is filled based on another input, try to use name as the label
      label: get(this.state, 'newItem.label') || (isObject(value) && get(value, 'name', '')),
      value,
    };

    const components: SelectComponentsConfig<Option, typeof multiple, any> = {
      DropdownIndicator,
      ClearIndicator,
      IndicatorSeparator: null,
      MultiValueRemove,
    };

    if (renderOption) components.Option = ({ data }) => renderOption(data);

    return (
      <div
        className={cn(styles.base, size && styles[`size-${size}`], {
          [formStyles.inputModeDisabled]: disabled,
        })}
        data-test="search-autocomplete"
      >
        {/* Async component uses the react-select component, and filters by default by label field of each option */}
        <Async
          classNamePrefix="Select"
          className={cn(size && styles[`size-${size}`], className, 'Select')}
          isDisabled={disabled}
          loadOptions={this.asyncOptions}
          isMulti={multiple}
          isClearable
          name={name}
          onChange={this.changeValue}
          placeholder={placeholder}
          ref={this.setSelectRef}
          value={selected}
          noOptionsMessage={() => 'Type to search'}
          components={components}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      </div>
    );
  }
}

export default BaseSearchAutocomplete;
