import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { getDate, getMonth, getYear, setDate, setMonth, setYear } from 'date-fns';

import { Location } from 'store/modules/locations';
import config from 'config';
import rollbar from 'lib/rollbar';
import { ProductTypes } from 'features/memberships/constants';
import { Memberships, MemberOption } from 'features/reservations/types';
import { dateFormats } from 'lib/constants';
import { parseDateInUTC } from 'lib/util';
import { companiesToName } from 'features/visitors/components/memberSelect/util';

import { Host, MemberVisit, GuestVisit, CheckedInGuestVisit } from './types';
import { MemberBookingTypes, MemberUserKind, WW_GLOBAL_ACCESS_LOCATION_CODE } from './constants';

const regularMemberLabel = (
  location: Partial<Location>,
  memberships: Memberships,
  isOnDemand: boolean
) => {
  if (!memberships.length) {
    return `Private Office at ${location?.name}`;
  }

  const productNames = [
    ...memberships.reduce((set, { productName }) => {
      set.add(productName === ProductTypes.PHYSICAL ? 'Private Office' : productName);
      return set;
    }, new Set()),
  ].join(', ');

  return location?.code !== WW_GLOBAL_ACCESS_LOCATION_CODE && !isOnDemand
    ? `${productNames} at ${location?.name}`
    : productNames;
};

export const formatDateWithTimeZone = (date: Date, location: Location): string =>
  format(date, dateFormats.iso_date_and_time_with_tz, { timeZone: location?.time_zone });

export const formatDate = (date: Date, location: Location) =>
  format(date, dateFormats.iso_date, { timeZone: location?.time_zone });

export const parseDate = (date: string | number | Date, timeZone: string): Date =>
  utcToZonedTime(new Date(date), timeZone);

export const combineDateTime = (date: Date, time: Date) => {
  return setYear(setMonth(setDate(Number(time), getDate(date)), getMonth(date)), getYear(date));
};

export const zonedDateInUTC = (date: Date, timeZone: string) => {
  return zonedTimeToUtc(parseDateInUTC(date), timeZone);
};

export const groupMembersByConferenceRoomBooking = (
  data: Array<MemberVisit>
): Array<MemberVisit> => {
  const useToReservationMap = data
    .filter(visit => visit.booking.type === MemberBookingTypes.CONFERENCE_ROOM)
    .reduce((reservationsByUser, reservation) => {
      const id = reservation.visitor.id;
      const _reservation = reservationsByUser.get(id);
      if (_reservation) {
        _reservation.booking.items.push(...reservation.booking.items);
      } else {
        reservationsByUser.set(id, reservation);
      }
      return reservationsByUser;
    }, new Map<string, MemberVisit>());

  return Array.from(useToReservationMap.values());
};

export const groupMembersByDailyDeskBooking = (data: Array<MemberVisit>): Array<MemberVisit> => {
  const useToReservationMap = data
    .filter(visit => visit.booking.type === MemberBookingTypes.DAILY_DESK)
    .reduce((reservationsByUser, reservation) => {
      const id = reservation.visitor.id;
      const _reservation = reservationsByUser.get(id);
      if (_reservation) {
        _reservation.booking.items.push(...reservation.booking.items);
      } else {
        reservationsByUser.set(id, reservation);
      }
      return reservationsByUser;
    }, new Map<string, MemberVisit>());

  return Array.from(useToReservationMap.values());
};

export const groupMembersByPrivateOfficeBooking = (
  data: Array<MemberVisit>
): Array<MemberVisit> => {
  const useToReservationMap = data
    .filter(visit => visit.booking.type === MemberBookingTypes.PRIVATE_OFFICE)
    .reduce((reservationsByUser, reservation) => {
      const id = reservation.visitor.id;
      const _reservation = reservationsByUser.get(id);
      if (_reservation) {
        _reservation.booking.items.push(...reservation.booking.items);
      } else {
        reservationsByUser.set(id, reservation);
      }
      return reservationsByUser;
    }, new Map<string, MemberVisit>());

  return Array.from(useToReservationMap.values());
};

export const todayByLocation = (location: Location) =>
  utcToZonedTime(new Date(), location?.time_zone);

export const memberLabel = (
  userKind: MemberUserKind,
  location: Partial<Location>,
  memberships: Memberships,
  isOnDemand: boolean
): string => {
  switch (userKind) {
    case MemberUserKind.EMPLOYEE:
    case MemberUserKind.ALUMNI:
      return 'Employee';
    case MemberUserKind.ANYWHERE:
      if (isOnDemand) return regularMemberLabel(location, memberships, isOnDemand);
      else if (location?.uuid === config.weworkAnywhereLocationUuid) return 'We Member';
      return `Hot Desk at ${location?.name}`;
    case MemberUserKind.ANYWHERE_LEAD:
      return 'Anywhere';
    case MemberUserKind.POTENTIAL:
      return 'Potential';
    case MemberUserKind.MEMBER:
      return regularMemberLabel(location, memberships, isOnDemand);
    default:
      rollbar.warning(`Unknown user kind: ${userKind}`);
      return 'Visitor';
  }
};

export function formReducer(state, { name, value }: { name: string; value: any }) {
  return {
    ...state,
    [name]: value,
  };
}

export function getFormProps(name: string, submitted: boolean, formValidations, hints) {
  if (!submitted) {
    return {
      error: false,
      hint: undefined,
    };
  }

  return {
    error: !formValidations[name],
    hint: !formValidations[name] ? hints[name] : undefined,
  };
}

export function isFormValid(formValidations) {
  return Object.values(formValidations).every(valid => valid);
}

export const userName = (firstName, lastName) => `${firstName}${lastName ? ` ${lastName}` : ''}`;

export const memberOptionToHost = (memberOption: MemberOption | null): Host | null =>
  memberOption && {
    id: memberOption.uuid,
    name: memberOption.name,
    email: memberOption.email,
    phone: memberOption.phone,
    company: {
      name: companiesToName(memberOption.companies),
      offices: memberOption.companies
        .flatMap(company => company.spaces?.flatMap(space => space.office_number))
        .filter(number => !!number)
        .join(', '),
    },
  };

export const hostToMemberOption = (host: Host | null): MemberOption | null =>
  host &&
  ({
    uuid: host.id,
    name: host.name,
    email: host.email,
    phone: host.phone,
    companies: [
      {
        name: host.company.name,
        spaces: host.company.offices?.split(',').map(office => ({
          office_number: office,
        })),
      },
    ],
  } as MemberOption);

export const convertGuestVisitToCheckedIn = (
  visit: GuestVisit,
  checkInTime: Date
): CheckedInGuestVisit => ({
  ...visit,
  checkInTime,
  registrationId: null,
  checkOutTime: null,
  isIdVerified: false,
});

export const formatVisitForPreregistered = (guestVisit: GuestVisit) => ({
  visitId: guestVisit.id,
  firstName: guestVisit.visitor.firstName,
  lastName: guestVisit.visitor.lastName,
  email: guestVisit.visitor.email || '',
  date: guestVisit.time,
  time: guestVisit.time,
  noteDesk: guestVisit.note || '',
  noteGuest: guestVisit.guestNote,
  memberOption: hostToMemberOption(guestVisit.host!),
});

export const formatVisitForEditVisit = (guestVisit: GuestVisit) => ({
  visitId: guestVisit.id,
  firstName: guestVisit.visitor.firstName,
  lastName: guestVisit.visitor.lastName,
  note: guestVisit.note || '',
  memberOption: hostToMemberOption(guestVisit.host!),
});
