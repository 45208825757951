import { handleActions } from 'redux-actions';

import { BaseAction, ActionWithPayload } from 'store/types';
import cc from 'store/util/createReduxConstant';

export type Step = {
  name: string;
  title: string;
};

type Steps = {
  closed: Step;
  add: Step;
  addPrivateAccess: Step;
  addProduct: Step;
  addGlobalAccess: Step;
  addNewGlobalAccess: Step;
  addDiscounts: Step;
  docusignInfo: Step;
  addPxwe: Step;
  drop: Step;
  transfer: Step;
  globalAccessTransfer: Step;
  customTermsOfService: Step;
  discounts: Step;
  discountReasons: Step;
  terms: Step;
  agreementModifications: Step;
  confirmation: Step;
  upload: Step;
  warning: Step;
  waitingForSync: Step;
  completed: Step;
  addons: Step;
  selectEntity: Step;
  selectSfOpportunityModal: Step;
  promotion: Step;
  promotionSummary: Step;
  earlyTermination: Step;
};

export type Payload = {
  stepName: string;
  opts: {};
};

export type State = Readonly<
  Step & {
    initial?: string;
    prevs: Array<string>;
  }
>;

export type StepAction = () => BaseAction;
export type StepActionWithParams = (stepName: string, opts?: {}) => ActionWithPayload<Payload>;

export const INITIATE_PAPERWORK = cc('PAPERWORK/INITIATE');
export const GO_FORWARD = cc('PAPERWORK/GO_FORWARD');
export const GO_BACK = cc('PAPERWORK/GO_BACK');
export const SET_STEP = cc('PAPERWORK/SET_STEP');
export const COMPLETE_PAPERWORK = cc('PAPERWORK/COMPLETE');
export const CANCEL_PAPERWORK = cc('PAPERWORK/CANCEL');

// Step names
export const CLOSED = 'closed';
export const ADD = 'add';
export const ADD_PRIVATE_ACCESS = 'addPrivateAccess';
export const ADD_PRODUCT = 'addProduct';
export const ADD_GLOBAL_ACCESS = 'addGlobalAccess';
export const ADD_PXWE = 'addPxwe';
export const ADD_DISCOUNTS = 'addDiscounts';
export const DOCUSIGN_INFO = 'docusignInfo';
export const DROP = 'drop';
export const TRANSFER = 'transfer';
export const GLOBAL_ACCESS_AMENDMENT = 'globalAccessTransfer';
export const DISCOUNTS = 'discounts';
export const DISCOUNT_REASONS = 'discountReasons';
export const CUSTOM_TERMS_OF_SERVICE = 'customTermsOfService';
export const TERMS = 'terms';
export const AGREEMENT_MODIFICATIONS = 'agreementModifications';
export const CONFIRMATION = 'confirmation';
export const UPLOAD = 'upload';
export const WARNING = 'warning';
export const WAITING_FOR_SYNC = 'waitingForSync';
export const COMPLETED = 'completed';
export const EDIT = 'edit';
export const ADDONS = 'addons';
export const SELECT_ENTITY = 'selectEntity';
export const SELECT_SF_OPPORTUNITY = 'selectSfOpportunityModal';
export const PROMOTION = 'promotion';
export const PROMOTION_SUMMARY = 'promotionSummary';
export const EARLY_TERMINATION = 'earlyTermination';
export const ADD_NEW_GLOBAL_ACCESS = 'addNewGlobalAccess';

export const steps: Steps = {
  [CLOSED]: {
    name: CLOSED,
    title: 'Closed',
  },
  [ADD]: {
    name: ADD,
    title: 'Add Offices',
  },
  [ADD_PRIVATE_ACCESS]: {
    name: ADD_PRIVATE_ACCESS,
    title: 'Add Private Access',
  },
  [ADD_PRODUCT]: {
    name: ADD_PRODUCT,
    title: 'Add Product',
  },
  [ADD_GLOBAL_ACCESS]: {
    name: ADD_GLOBAL_ACCESS,
    title: 'Add All Access',
  },
  [ADD_NEW_GLOBAL_ACCESS]: {
    name: ADD_NEW_GLOBAL_ACCESS,
    title: 'Add All Access',
  },
  [ADD_PXWE]: {
    name: ADD_PXWE,
    title: 'Add PxWe',
  },
  [ADD_DISCOUNTS]: {
    name: ADD_DISCOUNTS,
    title: 'Add Discounts',
  },
  [DOCUSIGN_INFO]: {
    name: DOCUSIGN_INFO,
    title: 'Docusign Info',
  },
  [DROP]: {
    name: DROP,
    title: 'Drop Offices',
  },
  [TRANSFER]: {
    name: TRANSFER,
    title: 'Transfer Offices',
  },
  [GLOBAL_ACCESS_AMENDMENT]: {
    name: GLOBAL_ACCESS_AMENDMENT,
    title: 'Global Access Transfer',
  },
  [CUSTOM_TERMS_OF_SERVICE]: {
    name: CUSTOM_TERMS_OF_SERVICE,
    title: 'Custom Terms of Service',
  },
  [DISCOUNTS]: {
    name: DISCOUNTS,
    title: 'Discounts',
  },
  [DISCOUNT_REASONS]: {
    name: DISCOUNT_REASONS,
    title: 'Discount Reasons',
  },
  [TERMS]: {
    name: TERMS,
    title: 'Contract Terms',
  },
  [AGREEMENT_MODIFICATIONS]: {
    name: AGREEMENT_MODIFICATIONS,
    title: 'Agreement Modifications Clause',
  },
  [CONFIRMATION]: {
    name: CONFIRMATION,
    title: 'Confirmation',
  },
  [UPLOAD]: {
    name: UPLOAD,
    title: 'Upload Contract',
  },
  [WARNING]: {
    name: WARNING,
    title: 'Upload Contract',
  },
  [WAITING_FOR_SYNC]: {
    name: WAITING_FOR_SYNC,
    title: 'Waiting for Sync',
  },
  [COMPLETED]: {
    name: COMPLETED,
    title: 'Completed',
  },
  [ADDONS]: {
    name: ADDONS,
    title: 'Add Add-ons',
  },
  [SELECT_ENTITY]: {
    name: SELECT_ENTITY,
    title: 'Select Entity',
  },
  [SELECT_SF_OPPORTUNITY]: {
    name: SELECT_SF_OPPORTUNITY,
    title: 'Salesforce Opportunities',
  },
  [PROMOTION]: {
    name: PROMOTION,
    title: 'New Promotion',
  },
  [PROMOTION_SUMMARY]: {
    name: PROMOTION_SUMMARY,
    title: 'Promotion Summary',
  },
  [EARLY_TERMINATION]: {
    name: EARLY_TERMINATION,
    title: 'Early Termination',
  },
};

export const initialState: State = {
  ...steps[CLOSED],
  initial: '',
  prevs: [],
};

export const reducer = handleActions<State, any>(
  {
    // $FlowFixMe TODO there's some nested destructuring going on here
    [INITIATE_PAPERWORK]: (_state: State, action: ActionWithPayload<Payload>) => ({
      ...steps[action.payload.stepName],
      ...action.payload.opts,
      initial: action.payload.stepName,
      prevs: [],
    }),
    // $FlowFixMe TODO there's some nested destructuring going on here
    [GO_FORWARD]: (state: State, action: ActionWithPayload<Payload>) => ({
      ...state,
      ...steps[action.payload.stepName],
      ...action.payload.opts,
      prevs: [state.name, ...state.prevs],
    }),
    // $FlowFixMe TODO there's some nested destructuring going on here
    [GO_BACK]: (state: State) => ({
      ...state,
      ...steps[state.prevs[0]],
      prevs: state.prevs.slice(1, state.prevs.length),
    }),
    // $FlowFixMe TODO there's some nested destructuring going on here
    [SET_STEP]: (state: State, action: ActionWithPayload<Payload>) => ({
      ...state,
      ...steps[action.payload.stepName],
      ...action.payload.opts,
    }),
    [COMPLETE_PAPERWORK]: () => steps[COMPLETED],

    [CANCEL_PAPERWORK]: () => steps[CLOSED],
  },
  initialState
);

export const initiatePaperwork = (stepName: string, opts: {} = {}) => ({
  type: INITIATE_PAPERWORK,
  payload: { stepName, opts },
});

export const goForward: StepActionWithParams = (stepName, opts = {}) => ({
  type: GO_FORWARD,
  payload: { stepName, opts },
});

export const goBack: StepAction = () => ({
  type: GO_BACK,
});

export const setStep: StepActionWithParams = (stepName, opts = {}) => ({
  type: SET_STEP,
  payload: { stepName, opts },
});

export const completePaperwork: StepAction = () => ({
  type: COMPLETE_PAPERWORK,
});

export const cancelPaperwork: StepAction = () => ({
  type: CANCEL_PAPERWORK,
});

export default reducer;
