import queryString from 'query-string';
import { camelCase, mapKeys } from 'lodash';

export function getQueryParams() {
  const hashSearchQueryPosition = document.location.hash.indexOf('?');
  const hashSearchQuery =
    hashSearchQueryPosition > 0
      ? document.location.hash.substr(document.location.hash.indexOf('?'))
      : '';

  return mapKeys(queryString.parse(document.location.search || hashSearchQuery), (_value, key) =>
    camelCase(key)
  );
}

export default getQueryParams;
