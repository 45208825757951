import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { mapValues, keyBy } from 'lodash';

import {
  SearchRequest,
  CompanySearchResultItem,
  MemberSearchResultItem,
  KeycardSearchResultItem,
  ContactSearchResultItem,
  GuestSearchResultItem,
} from 'store/modules/search/searchService/types';
import { createAlgoliaClearCacheAction } from 'store/algolia';
import {
  BaseAction,
  Dispatch,
  ActionWithPayload,
  ActionWithMetaPayload,
  GetGlobalState,
} from 'store/types';
import { filters } from 'features/search/resultSets/common';
import { GuestVisit } from 'features/visitors/types';
import { convertGuestVisitToCheckedIn } from 'features/visitors/util';

import {
  CLEAR_ALL_SEARCH_FILTERS,
  CLEAR_CACHE,
  SEARCH,
  SEARCH_BAR_FILTER_KEY,
  SEARCH_FAIL,
  SEARCH_RESET,
  SEARCH_SUCCESS,
  TOGGLE_SEARCH_FILTER,
  SET_SEARCH_FILTER,
  SEARCH_ENRICHMENT_USER_SPACES,
  SEARCH_CHECK_IN_GUEST_SUCCESS,
} from './constants';
import { search, searchFail, searchSuccess, searchEnrichmentUserSpaces } from './actions';
import searchService from './searchServiceWrapper';

export type SearchFilters = Hash<boolean>;

type SearchActionMeta = { query: string };

export type AlgoliaResult<T> = {
  numHits: number;
  hits: Array<T>;
};

type SearchServiceResults<T> = {
  value: {
    items: Array<T>;
    totalCount: number;
  };
};

export type SearchResults = {
  algolia: Array<AlgoliaResult<any>>;
  hotstampUsers: Array<{}>;
  company: SearchServiceResults<CompanySearchResultItem> | null;
  member: SearchServiceResults<MemberSearchResultItem> | null;
  keycard: SearchServiceResults<KeycardSearchResultItem> | null;
  guest: SearchServiceResults<GuestSearchResultItem> | null;
  contact: SearchServiceResults<ContactSearchResultItem> | null;
};

export interface State {
  query: string;
  searching: boolean;
  searched: boolean;
  searchResults: SearchResults;
  searchFilters: SearchFilters;
  error: Error | string | null;
}

export interface SearchSubset {
  search: State;
}

const defaultSearchFilters = (): SearchFilters => {
  const defaultFilters: SearchFilters = {};
  if (filters) {
    filters.forEach(filter => {
      defaultFilters[filter.name] = filter.default;
    });
  }
  return defaultFilters;
};

const getSearchFiltersFromLocalStorage = (): SearchFilters | null | undefined => {
  const filtersStr = localStorage.getItem(SEARCH_BAR_FILTER_KEY);
  return filtersStr ? JSON.parse(filtersStr) : null;
};

const initialSearchFilters = (): SearchFilters => ({
  ...defaultSearchFilters(),
  ...getSearchFiltersFromLocalStorage(),
});

// Initial State
export const initialState: State = {
  query: '',
  searching: false,
  searched: false,
  searchResults: {
    algolia: [],
    hotstampUsers: [],
    company: null,
    member: null,
    keycard: null,
    guest: null,
    contact: null,
  },
  searchFilters: initialSearchFilters(),
  error: null,
};

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [SEARCH_RESET]: (state: State): State => ({
      ...initialState,
      // Keep the previous filters.
      searchFilters: state.searchFilters,
    }),
    [SEARCH]: (state: State, action: ActionWithMetaPayload<SearchActionMeta, null>): State => ({
      ...state,
      query: action.meta.query,
      searching: true,
      searched: false,
      error: null,
    }),
    [SEARCH_SUCCESS]: (
      state: State,
      action: ActionWithMetaPayload<SearchActionMeta, SearchResults>
    ): State => {
      // Prevent searches which ended out-of-order from overwriting the results of the currently expected search.
      if (state.query !== action.meta.query) {
        return state;
      }

      return {
        ...state,
        searching: false,
        searched: true,
        searchResults: action.payload,
      };
    },
    [SEARCH_FAIL]: (
      state: State,
      action: ActionWithMetaPayload<SearchActionMeta, Error | string>
    ): State => {
      // Prevent searches which ended out-of-order from overwriting the results of the currently expected search.
      if (state.query !== action.meta.query) {
        return state;
      }

      return {
        ...state,
        searching: false,
        searched: false,
        error: action.payload,
      };
    },
    [SEARCH_ENRICHMENT_USER_SPACES]: (
      state: State,
      action: ActionWithMetaPayload<any, SearchResults>
    ): State => {
      const searchResults = state.searchResults;
      const enrichResults = action.payload;
      const companies = (enrichResults.company?.value?.items || []).reduce((obj, company) => {
        obj[company.uuid] = company;
        return obj;
      }, {});
      const newResults = { ...searchResults };
      (newResults?.member?.value?.items || []).forEach(member => {
        member.spaces = member.companies.reduce((spaces, company) => {
          return [...spaces, ...(companies[company.uuid]?.spaces || [])];
        }, []);
      });

      return {
        ...state,
        searchResults: newResults,
      };
    },
    [TOGGLE_SEARCH_FILTER]: (
      state: State,
      action: ActionWithPayload<{ filterName: string }>
    ): State => ({
      ...state,
      searchFilters: {
        ...state.searchFilters,
        [action.payload.filterName]: !state.searchFilters[action.payload.filterName],
      },
    }),
    [SET_SEARCH_FILTER]: (
      state: State,
      action: ActionWithPayload<{
        filterName: string;
        value: boolean;
      }>
    ): State => ({
      ...state,
      searchFilters: {
        ...state.searchFilters,
        [action.payload.filterName]: action.payload.value,
      },
    }),
    [TOGGLE_SEARCH_FILTER]: (
      state: State,
      action: ActionWithPayload<{ filterName: string }>
    ): State => ({
      ...state,
      searchFilters: {
        ...state.searchFilters,
        [action.payload.filterName]: !state.searchFilters[action.payload.filterName],
      },
    }),
    [CLEAR_ALL_SEARCH_FILTERS]: (state: State): State => ({
      ...state,
      searchFilters: mapValues(keyBy(filters, 'name'), () => false),
    }),
    [SEARCH_CHECK_IN_GUEST_SUCCESS]: (
      state: State,
      action: ActionWithPayload<{ visitId: string; checkInTime: Date }>
    ): State => ({
      ...state,
      searchResults: {
        ...state.searchResults,
        guest: state.searchResults.guest
          ? {
              ...state.searchResults.guest,
              value: {
                ...state.searchResults.guest.value,
                items: state.searchResults.guest.value.items.map(item =>
                  item.id === action.payload.visitId
                    ? convertGuestVisitToCheckedIn(item as GuestVisit, action.payload.checkInTime)
                    : item
                ),
              },
            }
          : null,
      },
    }),
  },
  initialState
);

// Action Creators

export const performSearch = (request: SearchRequest) => (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  dispatch(search(request.query));

  return searchService
    .get(getState)
    .search(request)
    .then(
      results => dispatch(searchSuccess(results, request.query)),
      err => dispatch(searchFail(err, request.query))
    );
};

export const enrichMemberSpaces = (request: SearchRequest) => (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  return searchService
    .get(getState)
    .search(request)
    .then(results => dispatch(searchEnrichmentUserSpaces(results)));
};

export const clearCache = () =>
  createAlgoliaClearCacheAction({
    type: CLEAR_CACHE,
  });

export const toggleSearchFilter = (filterName: string) => ({
  type: TOGGLE_SEARCH_FILTER,
  payload: {
    filterName,
  },
  meta: { persist: true, path: SEARCH_BAR_FILTER_KEY },
});

export const clearAllSearchFilters = () => ({
  type: CLEAR_ALL_SEARCH_FILTERS,
  meta: { persist: true, path: SEARCH_BAR_FILTER_KEY },
});

// Selectors

const getSearchState = (state: SearchSubset): State => state.search;

export const getSearchResults = createSelector(getSearchState, search => search.searchResults);

export const getSearchFilters = createSelector(getSearchState, search => search.searchFilters);

export const isSearching = createSelector(getSearchState, search => search.searching);
export const hasSearched = createSelector(getSearchState, search => search.searched);

export const getSearchFilter = createSelector(
  [getSearchFilters, (_state, props) => props.name],
  (filters, name) => filters[name]
);
export const getFormattedSearchFilters = createSelector(
  [getSearchFilters],
  (filters: Hash<Boolean>) =>
    Object.entries(filters).reduce(
      (aggregator, [key, value]) => (value ? `${aggregator},${key}` : aggregator),
      ''
    )
);

export const getMembers = createSelector<
  SearchSubset,
  SearchResults,
  Array<MemberSearchResultItem>
>([getSearchResults], searchResults => searchResults?.member?.value?.items || []);

export const getCompanies = createSelector<
  SearchSubset,
  SearchResults,
  Array<CompanySearchResultItem>
>([getSearchResults], searchResults => searchResults?.company?.value?.items || []);

export const getContactInfos = createSelector<
  SearchSubset,
  SearchResults,
  Array<ContactSearchResultItem>
>([getSearchResults], searchResults => searchResults?.contact?.value?.items || []);

export default reducer;
