import { SearchResultItem } from 'store/modules/search/searchService/types';

import { SearchableEntity } from '../types';

import { mapCompanyResult } from './company';
import { mapMemberResult } from './member';
import { mapGuestResult } from './guest';
import { mapKeycardResult } from './keycard';
import { Result } from './types';

type Descriptor = {
  title: string;
  mapToResult: (item: SearchResultItem) => Result;
};

type DescriptorMap = {
  [key in SearchableEntity]?: Descriptor;
};

const DESCRIPTORS: DescriptorMap = {
  companies: {
    title: 'Companies',
    mapToResult: mapCompanyResult,
  },
  members: {
    title: 'Members',
    mapToResult: mapMemberResult,
  },
  guests: {
    title: 'Guests',
    mapToResult: mapGuestResult,
  },
  keycards: {
    title: 'Keycards',
    mapToResult: mapKeycardResult,
  },
};

export default DESCRIPTORS;
