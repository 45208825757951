import { IconName } from '@wework/ray2';

import {
  MemberNotificationTemplate,
  MemberNotificationDeliveryType,
} from 'features/communication/memberNotifications/types';

export enum MemberNotificationEventLabel {
  FOOD_DELIVERY = 'sst_click_food_delivery_icon',
  PACKAGE_DELIVERY = 'sst_click_package_delivery_icon',
}

export type MemberNotificationAction = MemberNotificationTemplate & {
  actionType: MemberNotificationDeliveryType;
  eventLabel: MemberNotificationEventLabel;
  iconSource: string;
  rayIconSource: IconName;
};
