import { createContext } from 'react';

import { EventNameTypes } from 'store/constants';

import { ContextData, AnalyticsEvent } from './types';

// the top level context
export const AnalyticsContext = createContext<Array<ContextData>>([]);

const topLevelPropertyKeys = new Set([
  'tool',
  'platform',
  'object',
  'object_uuid',
  'object_type',
  'screen_name',
  'feature_group',
  'page_type',
  'label',
  'action',
  'event',
  'eventKey',
]);

export const buildEvent = (elementContexts: Array<ContextData>): AnalyticsEvent => {
  const baseEvent: AnalyticsEvent = {
    details: {},
    event: EventNameTypes.interaction,
    label: '',
    tool: 'spacestation',
    platform: 'web',
    screen_name: location.pathname,
  };

  const eventMetadata = elementContexts.reduce<AnalyticsEvent>((aggregator, contextData) => {
    const resolvedContextData = typeof contextData === 'function' ? contextData() : contextData;
    Object.entries(resolvedContextData).forEach(([key, value]) => {
      if (key === 'details') {
        Object.assign(aggregator.details, value);
      } else if (topLevelPropertyKeys.has(key)) {
        aggregator[key] = value;
      } else {
        aggregator.details[key] = value;
      }
    });

    return aggregator;
  }, baseEvent);

  return eventMetadata;
};
