import { createRequestReducer } from 'store/util/createRequestReducer';

import { FETCH_ADDR_NAME, FETCH_ADDR_NAME_FAIL, FETCH_ADDR_NAME_SUCCESS } from './constants';

const addressNameReducer = createRequestReducer<Hash<string>, Hash<string>>(
  [FETCH_ADDR_NAME, FETCH_ADDR_NAME_SUCCESS, FETCH_ADDR_NAME_FAIL],
  {},
  res => res.payload
);

export default addressNameReducer;
