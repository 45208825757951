import React from 'react';

import { ResultSet } from '../resultSets/types';
import TABS from '../searchTabs';

export interface SearchContextType {
  query: string;
  keyboardSelectedItemIndex: number | null;
  resultSets: Array<ResultSet>;
  currentTabTitle: string;
  pageUrl: string;
}

export const defaultSearchContext: SearchContextType = {
  query: '',
  keyboardSelectedItemIndex: null,
  resultSets: [],
  currentTabTitle: TABS[0].title,
  pageUrl: '/',
};

export const SearchContext = React.createContext<SearchContextType>(defaultSearchContext);
