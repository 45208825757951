import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as experiments } from '@wework/we-experiment-react';

import { AnnouncementsSubset } from 'features/communication/announcements/types';
import { MenaModularAdminSubset } from 'features/companies/edit/redux/menaModularAdmin/types';
import { CreateMembersSubset } from 'features/companies/membersSection/redux/createMembers/types';
import { PrimaryReservationsSubset } from 'features/companies/redux/primaryReservations/types';
import { ResourcesSubset } from 'features/companies/redux/resources/types';
import { FloorsSubset } from 'features/floors/redux/types';
import { InvoiceReviewSubset } from 'features/invoiceReview/types';
import accounts, { AccountsSubset } from 'store/modules/accounts';
import availablePromotions, {
  AvailablePromotionsSubset,
} from 'features/companies/edit/redux/availablePromotions/reducer';
import memberNotifications, {
  MemberNotificationsSubset,
} from 'features/communication/memberNotifications/redux/index';
import rooms, { RoomsSubset } from 'features/rooms/redux/reducers';
import recentlyViewed, { RecentlyViewedSubset } from 'features/search/recentlyViewed/redux';
import promotions, { PromotionsSubset } from 'features/promotions/ducks/promotions';
import products, { ProductsSubset } from 'features/alchemist/ducks/products';
import reduxFormUndo, { ReduxFormUndoSubset } from 'features/reduxFormUndo/ducks';
import verification, { VerificationSubset } from 'features/keycards/ducks/verification';
import keycards, { KeycardsSubset } from 'features/keycards/ducks/keycards';
import keycardFormats, { KeycardFormatsSubset } from 'features/keycards/ducks/keycardFormats';
import memberNotes, { MemberNotesSubset } from 'features/memberNotes/ducks';
import officeHoldsV2, {
  OfficeHoldsV2Subset,
} from 'features/inventory/yourInventory/modals/officeHolds/components/modals/redux/fetchOfficeHoldData';
import paperwork, { PaperworkSubset } from 'features/paperwork/ducks';
import eventMedia, { EventMediaSubset } from 'features/events/ducks/media';
import eventWeeklyPoster, { EventWeeklyPosterSubset } from 'features/events/ducks/weeklyPoster';
import currentLocationSearch, { CurrentLocationSearchSubset } from 'features/locationPicker/ducks';
import companyStats, {
  CompanyStatsSubset,
} from 'features/companies/membersSection/redux/companyStats/reducer';
import mimo, { MimoSubset } from 'features/mimo/redux';
import announcements from 'features/communication/announcements/ducks';
import accessServersAndPartitions, {
  AccessServersAndPartitionsSubset,
} from 'features/keycards/ducks/accessServersAndPartitions';
import accessLevels, { AccessLevelsSubset } from 'features/keycards/ducks/accessLevels';
import { ExperimentSubset } from 'store/middlewares/experimentsHelper';
import { RoomReservationsSubset } from 'store/modules/roomReservations/types';
import { ToursSubset } from 'store/modules/tours/types';
import { UserMembershipsSubset } from 'store/modules/userMemberships/types';
import { ReduxFormSubset } from 'store/reduxFormState';
import entity, { EntitySubset } from 'store/util/createEntity/reducer';
import auth, { AuthSubset } from 'features/auth/reducer';
import inventorySearch, { InventorySearchSubset } from 'features/inventorySearch/ducks/index';
import uploadedAgreements, {
  UploadedAddOnAgreementsSubset,
} from 'features/companies/addOnsSection/redux/index';
import visitors, { VisitorsSubset } from 'features/visitors/redux/reducers';
import invoiceReview from 'features/invoiceReview/redux/reducer';
import agreementHistory, { AgreementHistorySubset } from 'features/agreementHistory/ducks';
import accessControl, {
  AccessControlSubset,
} from 'features/accessControl/accessLevels/redux/reducer';
import addOnAgreements, {
  AddOnAgreementsSubset,
} from 'features/companies/addOnsSection/redux/addOnAgreements/reducer';
import anniversaries, { AnniversariesSubset } from 'features/memberMilestones/redux/reducer';
import spaceman, { SpacemanSubset } from 'features/accounts/redux/spaceman';
import bankAccountInfoCollector, {
  BankAccountInfoCollectorSubset,
} from 'features/companies/edit/redux/bankAccountInfoCollector/reducer';
import userProfileSettings, { UserProfileSubset } from 'features/profile/redux/reducer';
import commitmentTerms, {
  CommitmentTermsSubset,
} from 'features/companies/edit/redux/commitmentTerms/reducer';
import contracts, { ContractsSubset } from 'features/inventory/redux/contracts/reducer';
import createMembers from 'features/companies/membersSection/redux/createMembers/reducer';
import employees, { EmployeesSubset } from 'features/employees/redux/employees/reducer';
import expiredContracts, {
  ExpiredContractsSubset,
} from 'features/companies/edit/redux/expiredContracts/reducer';
import floors from 'features/floors/redux/reducer';
import holds, { HoldsSubset } from 'features/accounts/holds/redux/reducer';
import membershipTypes, {
  MembershipTypesSubset,
} from 'features/companies/edit/redux/membershipTypes/reducer';
import downsizeInfo, { DownsizeInfoSubset } from 'features/companies/redux/downsizeInfo/reducer';
import menaCompanies, { MenaCompaniesSubset } from 'features/companies/redux/menaCompanies/reducer';
import companyAuditHistory, {
  CompanyAuditHistorySubset,
} from 'features/companies/redux/auditLog/reducer';
import menaModularAdmin from 'features/companies/edit/redux/menaModularAdmin/reducer';
import featureFlags from 'features/companies/companyInfo/redux/featureFlags/reducer';
import noticePeriods, {
  NoticePeriodsSubset,
} from 'features/companies/edit/redux/noticePeriods/reducer';
import openDates, { OpenDatesSubset } from 'features/inventory/redux/openDates/reducer';
import primaryReservations from 'features/companies/redux/primaryReservations/reducer';
import pxweBuildings, { PxweBuildingsSubset } from 'features/paperwork/ducks/pxwe/reducer';
import renewals, { RenewalsSubset } from 'features/renewals/redux';
import reservationHistory, {
  ReservationHistorySubset,
} from 'features/companies/redux/reservationHistory/reducer';
import reservations, { ReservationsSubset } from 'features/companies/redux/reservations/reducer';
import resourceCategories, {
  ResourceCategoriesSubset,
} from 'features/companies/edit/redux/resourceCategories/reducer';
import resources from 'features/companies/redux/resources/reducer';
import seats, { SeatsSubset } from 'features/companies/redux/seats/reducer';
import yourInventory, { YourInventorySubset } from 'features/inventory/redux/yourInventory/reducer';
import inventoryToIgnore from 'features/inventory/redux/ignoreInventory/reducers';
import waiveRequests, {
  WaiveRequestsSubset,
} from 'features/companies/addOnsSection/redux/waiveRequests/reducer';
import listings, { ListingsSubset } from 'features/alchemist/ducks/listings';
import { InventoryToIgnoreSubset } from 'features/inventory/redux/ignoreInventory/types';
import building, { BuildingSubset } from 'features/building/ducks';
import s2Keycards, { s2KeycardsSubset } from 'features/accessControl/s2keycards/redux/reducer';
import memberInvites, { MemberInvitesSubset } from 'features/companies/redux/invites/reducer';
import companyCustomAccessLevels, {
  CompanyCustomAccessLevelsSubset,
} from 'features/companies/redux/customAccessLevels/reducer';
import aboutLocation, { AboutLocationSubset } from 'features/about/ducks';
import accessLevelGroups from 'features/accessLevelGroups/redux/reducer';
import { AccessLevelGroupsSubset } from 'features/accessLevelGroups/redux/types';
import notifications, { NotificationsSubset } from 'features/notifications/ducks/notifications';
import { PersonalAccessSubset } from 'features/accessReports/personalAccess/redux/types';
import personalAccess from 'features/accessReports/personalAccess/redux/reducer';
import { CompanyAccessSubset } from 'features/accessReports/companyAccess/redux/types';
import companyAccessReports from 'features/accessReports/companyAccess/redux/reducers';
import { DoorBuildingSwipeSubset } from 'features/accessReports/doorBuildingSwipe/redux/types';
import doorBuildingSwipe from 'features/accessReports/doorBuildingSwipe/redux/reducers';
import s2FeenicsLogin from 'features/accessReports/s2FeenicsLogin/redux/reducers';
import { S2FeenicsLoginSubset } from 'features/accessReports/s2FeenicsLogin/redux/types';
import subcategoryReports from 'features/accessReports/subcategoryReports/redux/reducers';
import { SubcategoryReportsSubset } from 'features/accessReports/subcategoryReports/redux/types';
import notificationsDeepLink, {
  NotificationsDeepLinkSubset,
} from 'features/notifications/ducks/notificationDeepLink';
import confirmModal from 'store/modules/confirmModal/reducer';
import organization from 'store/modules/organization/reducer';
import organizationAccounts from 'store/modules/organizationAccounts/reducer';
import organizationNameValidation from 'store/modules/organizationNameValidation/reducer';
import { ConfirmModalStateSubset } from 'store/modules/confirmModal/types';
import { OrganizationStateSubset } from 'store/modules/organization/types';
import { OrganizationAccountsStateSubset } from 'store/modules/organizationAccounts/types';
import { FeatureFlagSubset } from 'features/companies/companyInfo/redux/featureFlags/types';
import {
  UserGroupsStateSubset,
  BookingCreditOverageLimitStateSubset,
} from 'store/modules/userGroups/types';
import userGroups from 'store/modules/userGroups/reducer';
import bookingCreditOverageLimit from 'store/modules/userGroups/bookingCreditLimitOverageReducer';
import { UserGroupStateSubset } from 'store/modules/userGroup/types';
import userGroup from 'store/modules/userGroup/reducer';

import app, { AppSubset } from './app';
import version, { VersionSubset } from './appVersion';
import availableOccupiables, { AvailableOccupiablesSubset } from './availableOccupiables';
import buildings, { BuildingsSubset } from './buildings';
import companies, { CompaniesSubset } from './companies/reducer';
import locations, { LocationsSubset } from './locations';
import siLocations, { SiLocationsSubset } from './siLocations';
import members, { MembersSubset } from './members';
import toasts, { ToastsSubset } from './toasts';
import occupancies, { OccupanciesSubset } from './occupancies';
import occupiables, { OccupiablesSubset } from './occupiables/reducer';
import officeHolds, { OfficeHoldsSubset } from './officeHolds';
import page, { PageSubset } from './page';
import reasons, { ReasonsSubset } from './reasons';
import roomReservations from './roomReservations/reducer';
import search, { SearchSubset } from './search';
import tours from './tours';
import userMemberships from './userMemberships';

// TODO (Shem): it's better this code won't execute statically so the getReducers() won't call to early.
const rootReducer = {
  building,
  accessControl,
  accessLevelGroups,
  accessLevels,
  accessServersAndPartitions,
  accounts,
  addOnAgreements,
  agreementHistory,
  anniversaries,
  announcements,
  app,
  auth,
  availableOccupiables,
  availablePromotions,
  bankAccountInfoCollector,
  userProfileSettings,
  buildings,
  commitmentTerms,
  companies,
  companyStats,
  contracts,
  createMembers,
  currentLocationSearch,
  doorBuildingSwipe,
  downsizeInfo,
  employees,
  entity,
  eventMedia,
  eventWeeklyPoster,
  experiments,
  expiredContracts,
  floors,
  form,
  holds,
  inventorySearch,
  invoiceReview,
  keycardFormats,
  keycards,
  listings,
  aboutLocation,
  locations,
  siLocations,
  memberNotes,
  memberNotifications,
  members,
  membershipTypes,
  memberInvites,
  menaCompanies,
  companyAuditHistory,
  menaModularAdmin,
  featureFlags,
  mimo,
  noticePeriods,
  notifications,
  notificationsDeepLink,
  occupancies,
  occupiables,
  officeHolds,
  officeHoldsV2,
  openDates,
  page,
  paperwork,
  personalAccess,
  primaryReservations,
  products,
  promotions,
  pxweBuildings,
  reasons,
  recentlyViewed,
  reduxFormUndo,
  renewals,
  reservationHistory,
  reservations,
  resourceCategories,
  resources,
  roomReservations,
  rooms,
  search,
  seats,
  spaceman,
  s2FeenicsLogin,
  subcategoryReports,
  visitors,
  toasts,
  tours,
  uploadedAgreements,
  userMemberships,
  verification,
  version,
  waiveRequests,
  yourInventory,
  inventoryToIgnore,
  s2Keycards,
  companyCustomAccessLevels,
  companyAccessReports,
  confirmModal,
  organization,
  organizationAccounts,
  organizationNameValidation,
  userGroups,
  bookingCreditOverageLimit,
  userGroup,
};

const combinedReducer = combineReducers(rootReducer);

export type GlobalState = BuildingSubset &
  AccessControlSubset &
  AccessLevelsSubset &
  AccessServersAndPartitionsSubset &
  AccountsSubset &
  AddOnAgreementsSubset &
  AgreementHistorySubset &
  AnniversariesSubset &
  AnnouncementsSubset &
  AppSubset &
  AuthSubset &
  AvailableOccupiablesSubset &
  AvailablePromotionsSubset &
  BankAccountInfoCollectorSubset &
  UserProfileSubset &
  BuildingsSubset &
  CommitmentTermsSubset &
  CompaniesSubset &
  CompanyAccessSubset &
  CompanyStatsSubset &
  ContractsSubset &
  CreateMembersSubset &
  CurrentLocationSearchSubset &
  DoorBuildingSwipeSubset &
  DownsizeInfoSubset &
  EmployeesSubset &
  EntitySubset &
  EventMediaSubset &
  EventWeeklyPosterSubset &
  ExperimentSubset &
  ExpiredContractsSubset &
  FloorsSubset &
  ReduxFormSubset &
  HoldsSubset &
  InventoryToIgnoreSubset &
  InventorySearchSubset &
  InvoiceReviewSubset &
  KeycardFormatsSubset &
  KeycardsSubset &
  ListingsSubset &
  AboutLocationSubset &
  LocationsSubset &
  SiLocationsSubset &
  MemberNotesSubset &
  MemberNotificationsSubset &
  MembersSubset &
  MembershipTypesSubset &
  MemberInvitesSubset &
  MenaCompaniesSubset &
  CompanyAuditHistorySubset &
  MenaModularAdminSubset &
  MimoSubset &
  NoticePeriodsSubset &
  ToastsSubset &
  NotificationsSubset &
  NotificationsDeepLinkSubset &
  OccupanciesSubset &
  OccupiablesSubset &
  OfficeHoldsSubset &
  OfficeHoldsV2Subset &
  OpenDatesSubset &
  PageSubset &
  PaperworkSubset &
  PrimaryReservationsSubset &
  ProductsSubset &
  PromotionsSubset &
  PxweBuildingsSubset &
  ReasonsSubset &
  RecentlyViewedSubset &
  ReduxFormUndoSubset &
  RenewalsSubset &
  ReservationHistorySubset &
  ReservationsSubset &
  ResourceCategoriesSubset &
  ResourcesSubset &
  RoomReservationsSubset &
  RoomsSubset &
  SearchSubset &
  SeatsSubset &
  SpacemanSubset &
  S2FeenicsLoginSubset &
  SubcategoryReportsSubset &
  VisitorsSubset &
  ToursSubset &
  UploadedAddOnAgreementsSubset &
  UserMembershipsSubset &
  VerificationSubset &
  VersionSubset &
  WaiveRequestsSubset &
  YourInventorySubset &
  s2KeycardsSubset &
  CompanyCustomAccessLevelsSubset &
  AccessLevelGroupsSubset &
  PersonalAccessSubset &
  ConfirmModalStateSubset &
  OrganizationStateSubset &
  OrganizationAccountsStateSubset &
  FeatureFlagSubset &
  UserGroupStateSubset &
  BookingCreditOverageLimitStateSubset &
  UserGroupsStateSubset;

export default combinedReducer;
