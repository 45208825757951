import cc from 'store/util/createReduxConstant';

export const SET_FILTER_ANALYTICS_IS_SENT = cc('SET_FILTER_ANALYTICS_IS_SENT');

export const CLEAR_FILTER_SESSION_ID = cc('CLEAR_FILTER_SESSION_ID');

export const REFRESH_FILTER_SESSION_ID = cc('REFRESH_FILTER_SESSION_ID');

export const SET_MIMO_FILTER = cc('SET_MIMO_FILTER');

export const CLEAR_ALL_MIMO_FILTERS = cc('CLEAR_ALL_MIMO_FILTERS');

export const CLEAR_MIMO_CHIPS_FILTERS = cc('CLEAR_MIMO_CHIPS_FILTERS');
