import { handleActions } from 'redux-actions';

import { State } from 'features/accessReports/subcategoryReports/redux/types';
import {
  FETCH_SUBCATEGORY_REPORTS_PARTITIONS,
  FETCH_SUBCATEGORY_REPORTS_PARTITIONS_SUCCESS,
  FETCH_SUBCATEGORY_REPORTS_PARTITIONS_FAIL,
  FETCH_SUBCATEGORY_REPORTS_CONTROLLER,
  FETCH_SUBCATEGORY_REPORTS_CONTROLLER_SUCCESS,
  FETCH_SUBCATEGORY_REPORTS_CONTROLLER_FAIL,
  GET_SUBCATEGORY_REPORTS_SELECTED_DATA,
  FETCH_SUBCATEGORY_REPORTS_SEARCH,
  FETCH_SUBCATEGORY_REPORTS_SEARCH_SUCCESS,
  FETCH_SUBCATEGORY_REPORTS_SEARCH_FAIL,
  GET_SUBCATEGORY_REPORTS_SELECTED_TYPE,
} from 'features/accessReports/subcategoryReports/redux/constants';

const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  controllerList: [],
  partitionList: [],
  searchList: [],
  selectedParams: {},
  totalPages: 1,
  totalElements: 0,
  numberPage: 1,
  pageSize: 20,
  isVisibleTable: false,
  selectedType: '',
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_SUBCATEGORY_REPORTS_SEARCH]: state => ({
      ...state,
      loading: true,
      loaded: false,
      isVisibleTable: true,
      numberPage: 1,
    }),

    [FETCH_SUBCATEGORY_REPORTS_SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      searchList: action.payload.content,
      pageSize: action.payload.pageable.pageSize,
      totalPages: action.payload.totalPages,
      totalElements: action.payload.totalElements,
      numberPage: action.payload.number,
      error: null,
    }),

    [FETCH_SUBCATEGORY_REPORTS_SEARCH_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      searchList: [],
      pageSize: 20,
      isVisibleTable: false,
      totalPages: 1,
      numberPage: 1,
      totalElements: 0,
      error: action.payload.message,
    }),
    [FETCH_SUBCATEGORY_REPORTS_CONTROLLER]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_SUBCATEGORY_REPORTS_CONTROLLER_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      controllerList: action.payload.result,
      error: null,
    }),

    [FETCH_SUBCATEGORY_REPORTS_CONTROLLER_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      controllerList: [],
      error: action.payload.message,
    }),
    [FETCH_SUBCATEGORY_REPORTS_PARTITIONS]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_SUBCATEGORY_REPORTS_PARTITIONS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      partitionList: action.payload.content,
      error: null,
    }),

    [FETCH_SUBCATEGORY_REPORTS_PARTITIONS_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      partitionList: [],
      error: action.payload.message,
    }),

    [GET_SUBCATEGORY_REPORTS_SELECTED_DATA]: (state, action) => ({
      ...state,
      selectedParams: action.payload,
    }),
    [GET_SUBCATEGORY_REPORTS_SELECTED_TYPE]: (state, action) => ({
      ...state,
      searchList: [],
      isVisibleTable: false,
      selectedType: action.payload,
    }),
  },
  initialState
);

export default reducer;
