import { handleActions } from 'redux-actions';

import cc from 'store/util/createReduxConstant';
import { PaperworkLocale } from 'features/paperwork/ducks/paperworkLocales';

export const SET_DOCUSIGN_SIGNATORY = cc('SET_DOCUSIGN_SIGNATORY');
export const SET_DOCUSIGN_PAPERWORK_LOCALE = cc('SET_DOCUSIGN_PAPERWORK_LOCALE');
export const CLEAR_DOCUSIGN_PAPERWORK_LOCALE = cc('CLEAR_DOCUSIGN_PAPERWORK_LOCALE');

export interface Signatory {
  name?: string;
  email?: string;
}

export interface State {
  signatory: Signatory;
  paperworkLocale: PaperworkLocale | null;
}

export const initialState: State = {
  signatory: {},
  paperworkLocale: null,
};

export const reducer = handleActions<State, any>(
  {
    [SET_DOCUSIGN_SIGNATORY]: (state, action: { payload: { signatory: Signatory } }) => ({
      ...state,
      signatory: action.payload.signatory,
    }),
    [SET_DOCUSIGN_PAPERWORK_LOCALE]: (
      state,
      action: { payload: { paperworkLocale: PaperworkLocale } }
    ) => ({
      ...state,
      paperworkLocale: action.payload.paperworkLocale,
    }),
    [CLEAR_DOCUSIGN_PAPERWORK_LOCALE]: state => ({
      ...state,
      paperworkLocale: null,
    }),
  },
  initialState
);

export const setDocusignSignatory = (signatory: Signatory) => ({
  type: SET_DOCUSIGN_SIGNATORY,
  payload: {
    signatory,
  },
});

export const setDocusignPaperworkLocale = (paperworkLocale: PaperworkLocale) => ({
  type: SET_DOCUSIGN_PAPERWORK_LOCALE,
  payload: {
    paperworkLocale,
  },
});

export const clearDocusignPaperworkLocale = () => ({
  type: CLEAR_DOCUSIGN_PAPERWORK_LOCALE,
});

export default reducer;
