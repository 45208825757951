import React from 'react';
import cn from 'classnames';

import Emoji from 'components-ray/emoji';
import { EmojiName } from 'components-ray/emoji/emojiName';

import styles from './searchLegend.scss';

interface Props {
  className?: string;
}

const INSTRUCTIONS = ['↑↓ Select', '⎋ Exit'];

const SearchLegend = ({ className }: Props) => (
  <div className={cn(styles.wrapper, className)}>
    {INSTRUCTIONS.map(instruction => (
      <div className={cn(styles.instruction, 'text-gray-40')} key={instruction}>
        {instruction}
      </div>
    ))}
    <div className={cn(styles.instruction, 'text-gray-40')} key="⌘+☝️  Open in new tab">
      <span>
        {'⌘+'}
        <Emoji emoji={EmojiName.POINTING_UP} className="!h-3 w-auto !mt-[3px]" />
        {' Open in new tab'}
      </span>
    </div>
  </div>
);

export default SearchLegend;
