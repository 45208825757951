import React, { useState, useEffect, useCallback } from 'react';
import loadJS from 'loadjs';
import { currentEmployeeHasRoles } from '@wework/we-auth-react';

import rollbar from 'lib/rollbar';
import { UserInfo } from 'features/auth/reducer';
import config from 'config';
import { EmployeeRoles } from 'lib/permissions';

export const MEDALLIA_URL = 'https://nebula-cdn.kampyle.com/wu/627358/onsite/embed.js';
export const USER_DATA_KEY = '_userData';
export const MEDALLIA_READY_EVENT = 'neb_OnsiteLoaded';
const KAMPYLE_ONSITE_SDK_KEY = 'KAMPYLE_ONSITE_SDK';

const medalliaReady = new Promise(resolve =>
  window.addEventListener(MEDALLIA_READY_EVENT, resolve)
);

const useRole = role => {
  const [hasRole, setHasRole] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!role) {
      setHasRole(false);
      setLoading(false);
      return;
    }

    const asyncFn = async () => {
      try {
        const data = await currentEmployeeHasRoles([role]);
        setHasRole(data[role]);
        setLoading(false);
      } catch (error) {
        setHasRole(false);
        setLoading(false);
      }
    };

    asyncFn();
  }, [role]);

  return [hasRole, loading];
};

export const useMedalliaReady = (promise = medalliaReady): boolean => {
  const [medalliaIsReady, setMedalliaIsReady] = useState(false);

  useEffect(() => {
    promise.then(() => {
      setMedalliaIsReady(true);
    });
  }, [promise]);

  return medalliaIsReady;
};

export const loadMedallia = (userData: UserInfo | null): void => {
  if (!userData) {
    rollbar.error('userData required for Medallia');
  }

  global[USER_DATA_KEY] = userData;

  window.requestIdleCallback(() => loadJS(MEDALLIA_URL, 'load_medallia'), { timeout: 5000 });
};

export type MedalliaAppWrapperProps = {
  isMedalliaReady: boolean;
  showMedallia: (UserInfo) => void;
};

export const withMedallia = (WrappedComponent: React.ComponentType): React.ComponentType => {
  const ComponentWithExtraInfo = props => {
    const isMedalliaReady = useMedalliaReady();

    const [hasCommunityRole, communityRoleLoading] = useRole(EmployeeRoles.community_management);
    const [hasCityLeadRole, cityLeadRoleLoading] = useRole(EmployeeRoles.city_lead);
    const [hasSalesRole, salesRoleLoading] = useRole(EmployeeRoles.enterprise_sales);

    const showMedallia = useCallback(
      (currentUser: UserInfo) => {
        if (!currentUser) {
          return;
        }

        window[USER_DATA_KEY] = {
          ...window[USER_DATA_KEY],
          name: currentUser.fullName,
          uuid: currentUser.uuid,
          email: currentUser.email,
          show_spacestation_survey: true,
          environment: config.appEnv,
          is_community: !communityRoleLoading && hasCommunityRole,
          is_city_lead: !cityLeadRoleLoading && hasCityLeadRole,
          is_sales: !salesRoleLoading && hasSalesRole,
        };

        window[KAMPYLE_ONSITE_SDK_KEY].updatePageView();
      },
      [
        communityRoleLoading,
        hasCommunityRole,
        cityLeadRoleLoading,
        hasCityLeadRole,
        salesRoleLoading,
        hasSalesRole,
      ]
    );

    return (
      <WrappedComponent
        {...props}
        isMedalliaReady={
          isMedalliaReady && !communityRoleLoading && !cityLeadRoleLoading && !salesRoleLoading
        }
        showMedallia={showMedallia}
      />
    );
  };

  return ComponentWithExtraInfo;
};
