import { handleActions, Reducer } from 'redux-actions';

import { createRequestAction } from 'store/util';
import { Dispatch, BaseAction, ActionWithPayload } from 'store/types';
import config from 'config';
import cc from 'store/util/createReduxConstant';

// Action Constants
export const START_VERIFICATION = cc('START_VERIFICATION');
export const START_VERIFICATION_SUCCESS = cc('START_VERIFICATION_SUCCESS');
export const START_VERIFICATION_FAIL = cc('START_VERIFICATION_FAIL');
export const MANUAL_VERIFICATION = cc('MANUAL_VERIFICATION');
export const MANUAL_VERIFICATION_SUCCESS = cc('MANUAL_VERIFICATION_SUCCESS');
export const MANUAL_VERIFICATION_FAIL = cc('MANUAL_VERIFICATION_FAIL');
export const CANCEL_VERIFICATION = cc('CANCEL_VERIFICATION');
export const CANCEL_VERIFICATION_SUCCESS = cc('CANCEL_VERIFICATION_SUCCESS');
export const CANCEL_VERIFICATION_FAIL = cc('CANCEL_VERIFICATION_FAIL');
export const RESET_VERIFICATION = cc('RESET_VERIFICATION');
export const RESET_VERIFICATION_SUCCESS = cc('RESET_VERIFICATION_SUCCESS');
export const RESET_VERIFICATION_FAIL = cc('RESET_VERIFICATION_FAIL');

export const ON_DEMAND_MANUAL_VERIFICATION = cc('ON_DEMAND_MANUAL_VERIFICATION');
export const ON_DEMAND_MANUAL_VERIFICATION_SUCCESS = cc('ON_DEMAND_MANUAL_VERIFICATION_SUCCESS');
export const ON_DEMAND_MANUAL_VERIFICATION_FAIL = cc('ON_DEMAND_MANUAL_VERIFICATION_FAIL');

interface State {
  loaded: boolean;
  submitting: boolean;
  submitted: boolean;
  data: {};
  loading: boolean;
  error: string | null | undefined;
}

export interface VerificationSubset {
  verification: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  submitting: false,
  submitted: false,
  error: null,
  data: {},
};

export const reducer: Reducer<State, any> = handleActions<State, any>(
  {
    [START_VERIFICATION]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [START_VERIFICATION_SUCCESS]: (state: State, action: ActionWithPayload<{ result: {} }>) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.payload.result,
      error: null,
    }),

    [START_VERIFICATION_FAIL]: (state: State, action: ActionWithPayload<{ message: string }>) => ({
      ...state,
      loading: false,
      loaded: false,
      data: {},
      error: action.payload.message,
    }),

    [MANUAL_VERIFICATION]: (state: State) => ({
      ...state,
      submitting: true,
      submitted: false,
    }),

    [MANUAL_VERIFICATION_SUCCESS]: (state: State) => ({
      ...state,
      submitting: false,
      submitted: true,
      error: null,
    }),

    [MANUAL_VERIFICATION_FAIL]: (state: State, action: ActionWithPayload<{ message: string }>) => ({
      ...state,
      submitting: false,
      submitted: false,
      error: action.payload.message,
    }),

    [CANCEL_VERIFICATION]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [CANCEL_VERIFICATION_SUCCESS]: (state: State) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),

    [CANCEL_VERIFICATION_FAIL]: (state: State, action: ActionWithPayload<{ message: string }>) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),

    [RESET_VERIFICATION]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [RESET_VERIFICATION_SUCCESS]: (state: State) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),

    [RESET_VERIFICATION_FAIL]: (state: State, action: ActionWithPayload<{ message: string }>) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.payload.message,
    }),

    [ON_DEMAND_MANUAL_VERIFICATION]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [ON_DEMAND_MANUAL_VERIFICATION_SUCCESS]: (
      state: State,
      action: ActionWithPayload<{ result: {} }>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.payload.result,
      error: null,
    }),

    [ON_DEMAND_MANUAL_VERIFICATION_FAIL]: (
      state: State,
      action: ActionWithPayload<{ message: string }>
    ) => ({
      ...state,
      loading: false,
      loaded: false,
      data: {},
      error: action.payload.message,
    }),
  },
  initialState
);

// Action Creators
export function startVerification(userUuid: string, verifiedBy: string = '', keycardParams: {}) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.id.uri}/api/v4/verification/start_verification_from_spacestation`,
      method: 'POST',
      types: [START_VERIFICATION, START_VERIFICATION_SUCCESS, START_VERIFICATION_FAIL],
      body: {
        netverify: {
          successUrl: `${config.uri}/static/success.html`,
          errorUrl: `${config.uri}/static/failure.html`,
          customerInternalReference: userUuid,
          userReference: userUuid,
          callbackUrl: `${config.id.webUri}/api/v2/users/${userUuid}/verify`,
        },
        user_uuid: userUuid,
        verified_by: verifiedBy,
        keycard_info: keycardParams,
      },
    });

    return dispatch(requestAction);
  };
}

type ManualVerificationInfo = {
  name: string;
  dob: string;
  address: string;
  country: string;
  state: string;
  keycardImage: {
    picture: string;
    pictureExt: string;
  };
  verifiedBy: string;
};
export function manualVerification(
  userUuid: string,
  manualVerificationInfo: ManualVerificationInfo,
  keycardParams: {}
) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.id.uri}/api/v2/users/${userUuid}/manual_verification`,
      method: 'POST',
      types: [MANUAL_VERIFICATION, MANUAL_VERIFICATION_SUCCESS, MANUAL_VERIFICATION_FAIL],
      body: {
        personal_info: {
          name: manualVerificationInfo.name,
          dob: manualVerificationInfo.dob,
          address: manualVerificationInfo.address,
          country: manualVerificationInfo.country,
          state: manualVerificationInfo.state,
          picture: manualVerificationInfo.keycardImage.picture,
          picture_ext: manualVerificationInfo.keycardImage.pictureExt,
        },
        keycard_info: keycardParams,
        verified_by: manualVerificationInfo.verifiedBy,
      },
    });

    return dispatch(requestAction);
  };
}

export function cancelVerification(userUuid: string, cancelReason: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.id.uri}/api/v2/users/${userUuid}/cancel_verification`,
      method: 'POST',
      types: [CANCEL_VERIFICATION, CANCEL_VERIFICATION_SUCCESS, CANCEL_VERIFICATION_FAIL],
      body: {
        cancel_reason: cancelReason,
      },
    });

    return dispatch(requestAction);
  };
}

export function resetVerification(userUuid: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.id.uri}/api/v2/users/${userUuid}/increase_verification_attempt_max`,
      method: 'POST',
      types: [RESET_VERIFICATION, RESET_VERIFICATION_SUCCESS, RESET_VERIFICATION_FAIL],
    });

    return dispatch(requestAction);
  };
}

export function onDemandManualVerification(
  userUuid: string,
  locationUuid: string,
  verifiedBy: string = ''
) {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.id.uri}/api/v4/verification/start_verification`,
      method: 'POST',
      types: [
        ON_DEMAND_MANUAL_VERIFICATION,
        ON_DEMAND_MANUAL_VERIFICATION_SUCCESS,
        ON_DEMAND_MANUAL_VERIFICATION_FAIL,
      ],
      body: {
        user_uuid: userUuid,
        location_uuid: locationUuid,
        verified_by: verifiedBy,
        verification_type: 'manual',
        personal_info: {
          picture: '',
          picture_ext: '',
        },
      },
    });

    return dispatch(requestAction);
  };
}

export default reducer;
