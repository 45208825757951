import React from 'react';

import rollbar from 'lib/rollbar';
import { ErrorName } from 'store/constants';
import ErrorPage from 'store/errorPage';
import { FallbackError } from 'store/errors';

type Props = Readonly<{ children: React.ReactNode }>;

type State = Readonly<{
  hasError: boolean;
  fallBack: boolean;
  status: number | undefined | null;
  urlName: string | undefined | null;
  endPoint: string | undefined | null;
  xRequestId: string | undefined | null;
}>;

type ErrorInfo = {
  componentStack: string;
};

interface ErrorContainer {
  message: string;
  status: number;
  url: string;
  xRequestId: string;
}

export const fallbackPageHandler = ({ message, status, url, xRequestId }: ErrorContainer) => {
  throw new FallbackError(status, url, message, xRequestId);
};

class RouteErrorBoundary extends React.PureComponent<Props, State> {
  state = {
    hasError: false,
    fallBack: false,
    urlName: null,
    status: null,
    endPoint: null,
    xRequestId: null,
  };

  setFallBack = (error: FallbackError) => {
    this.setState({
      status: error.status,
      urlName: error.urlName,
      endPoint: error.endPointUrl,
      fallBack: true,
      xRequestId: error.xRequestId,
    });
  };

  componentDidCatch(error, errorInfo: ErrorInfo) {
    // TODO (Shem): Need to decide how to display it for users
    // NotificationActions.error(`An error while loading route`);
    this.setState({ hasError: true });

    switch (error.name) {
      case ErrorName.FallBackError:
        this.setFallBack(error);
        rollbar.warning(error.message, error, { errorInfo });
        break;
      case ErrorName.GeneralInvalidError:
        rollbar.error(`[${error.componentName}]: ${error.message}`, error, { errorInfo });
        break;
      default:
        rollbar.error(error.message, error, { errorInfo });
        break;
    }
  }

  render() {
    if (this.state.hasError && this.state.fallBack) {
      return (
        <ErrorPage
          serviceName={this.state.urlName}
          errorDetail={{
            status: this.state.status,
            endPoint: this.state.endPoint,
            xRequestId: this.state.xRequestId,
          }}
        />
      );
    } else if (this.state.hasError) {
      return null;
    }

    const { children } = this.props;
    return children;
  }
}

export default RouteErrorBoundary;
