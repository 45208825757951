import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_COMMITMENT_TERM,
  FETCH_COMMITMENT_TERM_SUCCESS,
  FETCH_COMMITMENT_TERM_FAIL,
] = createRequestConstantNames(cc('FETCH_COMMITMENT_TERM'));

export const [
  MODIFY_COMMITMENT_TERM,
  MODIFY_COMMITMENT_TERM_SUCCESS,
  MODIFY_COMMITMENT_TERM_FAIL,
] = createRequestConstantNames(cc('MODIFY_COMMITMENT_TERM'));
