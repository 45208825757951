import React from 'react';

import { GuestSearchResultItem } from 'store/modules/search/searchService/types';
import { isGuestCheckedIn } from 'store/modules/search/searchService/welkioGuestAdapter/utils';
import { SearchableEntity } from 'features/search/types';
import { CheckedInGuestVisit } from 'features/visitors/types';

import { Result } from '../types';
import OfficeNumbersCell from '../common/cells/officeNumbers';

import GuestNameCell from './cells/name';
import HostCell from './cells/host';
import CheckInStatusCell from './cells/checkInStatus';
import CheckInButton from './checkInButton';
import { GUEST_RESERVATIONS_ACTION_URL } from './constants';
import { GuestCellProps } from './types';

const CheckedInCell = ({ guestVisit }: GuestCellProps) => (
  <CheckInStatusCell checkInTime={(guestVisit as CheckedInGuestVisit).checkInTime} />
);
const HostOfficeNumberCell: React.FC<GuestCellProps> = ({ guestVisit: { host } }) => {
  const offices = host?.company.offices?.split(', ');
  return offices ? <OfficeNumbersCell offices={offices} /> : null;
};

const createGuestCells = (guestVisit: GuestSearchResultItem, guestCheckedIn: boolean) =>
  [GuestNameCell, HostCell, CheckedInCell, HostOfficeNumberCell].map((Cell, index) => (
    <Cell guestVisit={guestVisit} guestCheckedIn={guestCheckedIn} key={index} />
  ));

export const mapGuestResult = (guestVisit: GuestSearchResultItem): Result => {
  const { visitor, id } = guestVisit;
  const avatarUrl = visitor.imgSrc;

  const analyticsData = {
    reservation_uuid: id,
    guest_id: visitor.id,
    target_name: visitor.name,
  };

  const guestCheckedIn = isGuestCheckedIn(guestVisit);

  function RowEndComponent() {
    return !guestCheckedIn ? <CheckInButton guestVisit={guestVisit} /> : null;
  }

  return {
    uuid: id,
    avatarUrl,
    cells: createGuestCells(guestVisit, guestCheckedIn),
    entityType: SearchableEntity.GUESTS,
    link: GUEST_RESERVATIONS_ACTION_URL,
    RowEndComponent,
    analyticsData,
    data: guestVisit,
  };
};
