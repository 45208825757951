import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';
import { CSSProperties } from 'react';

import { MemberCompany } from 'store/modules/search/searchService/types';

export interface SubNavLocation {
  name: string;
  title: string;
  location: string;
}

// members & companies
export type TableColumnSorting = {
  byColumn: string | null;
  direction: 'ascending' | 'descending' | undefined;
};

export type CompanyPrimaryMember = {
  email: string;
  uuid: string;
  name: string;
  phone: string;
};

// TODO: these types should be removed/moved to different location once people/companies page will be removed.
export type Member = {
  uuid: string;
  name: string;
  locationUuids?: Array<string>;
  isPrimary: boolean;
  isAdmin: boolean;
  isBilling: boolean;
  roles: Array<string>;
  officeSpaces: string;
  email: string;
  companies: Array<MemberCompany>;
  phone: string;
  status?: string;
  kind?: string;
  normalizedPhone?: string;
  isLicensee?: boolean;
  isNewMember: boolean;
  isFutureMember: boolean;
  startedOn: string;
  search?: {
    name: string;
    officeSpaces: Array<string>;
    companies: Array<string>;
    companyAliases: Array<string>;
  };
};
// used in old people also
export enum FilterType {
  ALL = 'ALL',
  PRIMARY = 'PRIMARY MEMBER',
  ADMIN = 'ADMIN ACCESS',
  BILLING = 'BILLING ACCESS',
  NON_PRIMARY = 'NON PRIMARY',
  NEW_MEMBER = 'NEW MEMBER',
  FUTURE_MEMBER = 'MOVING IN SOON',
}
// used in old people also and store
export type FilterTypes = typeof FilterType[keyof typeof FilterType];

export type VirtualizedTableRowAttr = {
  style: CSSProperties;
  parent: MeasuredCellParent;
  key: string;
  index: number;
};

export type SearchResultInteractionDetails = {
  filter_active_membership: boolean;
  filter_member_type?: string;
  target_text: string | null;
  target_entity: string;
  entity_type: string;
  entity_placement: number | null;
};

export enum AnalyticsEntityType {
  COMPANY = 'company',
  MEMBER = 'member',
}
