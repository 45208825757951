import { Menu as DieterMenu, MenuItem as DieterMenuItem, MenuItemProps } from '@wework/dieter-ui';

import { withAnalytics } from 'lib/contextualAnalytics/hoc';
import { ClickActionProp, HoverActionProp } from 'lib/contextualAnalytics/hoc/actions';
import replaceComponentStatics from 'lib/replaceComponentStatics';

type ActionProps = ClickActionProp & HoverActionProp;

export const MenuItem = withAnalytics<MenuItemProps, ActionProps>({
  actions: ['click', { name: 'hover', trackByDefault: false }],
})(DieterMenuItem);

export const Menu = replaceComponentStatics(DieterMenu, {
  Item: MenuItem,
});

export type {
  MenuProps,
  StrictMenuProps,
  MenuItemProps,
  StrictMenuItemProps,
} from '@wework/dieter-ui';
