import lazySingleton, { LazySingleton } from 'lib/lazySingleton';
import tokenRegistry from 'lib/tokenRegistry';
import { GetGlobalState } from 'store/types';

import AirlockAdapter from './searchService/airlockAdapter/airlockAdapter';
import AlgoliaAdapter from './searchService/algoliaAdapter/algoliaAdapter';
import AlgoliaClient from './searchService/algoliaAdapter/algoliaClient';
import WelkioGuestAdapter from './searchService/welkioGuestAdapter/welkioGuestAdapter';
import PrivateMemberAdapter from './searchService/accountService/privateMemberAdapter';
import SearchService from './searchService/searchService';
import { EntityType } from './searchService/types';

const searchService: LazySingleton<SearchService> = lazySingleton((getState: GetGlobalState) => {
  const algoliaAdapter = new AlgoliaAdapter(new AlgoliaClient(tokenRegistry.getAlgoliaClient()));
  const airlock = new AirlockAdapter();

  const storeGuest = new WelkioGuestAdapter(getState);

  const privateMemberAdapter = new PrivateMemberAdapter();

  return new SearchService({
    [EntityType.COMPANY]: algoliaAdapter,
    [EntityType.MEMBER]: algoliaAdapter,
    [EntityType.CONTACT]: algoliaAdapter,
    [EntityType.KEYCARD]: airlock,
    [EntityType.GUEST]: storeGuest,
    [EntityType.BUILDING]: algoliaAdapter,
    [EntityType.PRIVATE_MEMBER]: privateMemberAdapter,
  });
});

export default searchService;
