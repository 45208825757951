import { handleActions, ActionMeta, Action } from 'redux-actions';

// Action Constants
import {
  FETCH_EMPLOYEES_BY_ROLE,
  FETCH_EMPLOYEES_BY_ROLE_SUCCESS,
  FETCH_EMPLOYEES_BY_ROLE_FAIL,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  FETCH_AVAILABLE_ROLES_SUCCESS,
  FETCH_AVAILABLE_ROLES_FAIL,
} from './constants';
import { Employee, EmployeesByRole, Filters, ByRoleMeta } from './types';

export interface State {
  loaded: boolean;
  data: Array<Employee>;
  byRole: Hash<Array<EmployeesByRole>>;
  filters: Filters;
  error: boolean | undefined;
  loading: boolean;
  posting: boolean;
  posted: boolean;
  availableRoles: {
    data: string[];
    loading: boolean;
    loaded: boolean;
    error: boolean | undefined;
  };
}

export interface EmployeesSubset {
  employees: State;
}

// Initial State
export const initialState: State = {
  byRole: {},
  data: [],
  error: false,
  loaded: false,
  loading: false,
  posted: false,
  posting: false,
  filters: {
    roles: [],
    query: null,
    status: 'active',
  },
  availableRoles: {
    data: [],
    error: false,
    loaded: false,
    loading: false,
  },
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_EMPLOYEES_BY_ROLE]: state => ({
      ...state,
      loading: true,
      loaded: false,
      error: false,
    }),
    [FETCH_EMPLOYEES_BY_ROLE_SUCCESS]: (
      state,
      action: ActionMeta<Array<EmployeesByRole>, ByRoleMeta>
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: false,
      byRole: {
        ...state.byRole,
        [action.meta.role]: action.payload,
      },
    }),
    [FETCH_EMPLOYEES_BY_ROLE_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
    [UPDATE_EMPLOYEE]: state => ({
      ...state,
      posting: true,
      posted: false,
      error: false,
    }),
    [UPDATE_EMPLOYEE_SUCCESS]: state => ({
      ...state,
      posting: false,
      posted: true,
      error: false,
    }),
    [UPDATE_EMPLOYEE_FAIL]: (state, action) => ({
      ...state,
      posting: false,
      posted: false,
      error: action.error,
    }),
    [FETCH_AVAILABLE_ROLES_SUCCESS]: (state, action: Action<{ content: string[] }>) => ({
      ...state,
      availableRoles: {
        ...state.availableRoles,
        data: action.payload.content,
        loading: false,
        loaded: true,
        error: false,
      },
    }),
    [FETCH_AVAILABLE_ROLES_FAIL]: (state, action) => ({
      ...state,
      availableRoles: {
        ...state.availableRoles,
        loading: false,
        loaded: true,
        error: action.error,
      },
    }),
  },
  initialState
);

export default reducer;
