import { handleActions, Action } from 'redux-actions';

import { Dispatch } from 'store/types';
import cc from 'store/util/createReduxConstant';

// Action Constants
export const UPDATE_COLLAPSIBLE_SECTION = cc('UPDATE_COLLAPSIBLE_SECTION');

// Initial State
const initialState: Record<string, boolean> = {};

export const updateCollapsibleSection = (collapsibleSection: string, isOpen: boolean) => (
  dispatch: Dispatch<Action<{ collapsibleSection: string; isOpen: boolean }>>
) =>
  dispatch({
    type: UPDATE_COLLAPSIBLE_SECTION,
    payload: {
      collapsibleSection,
      isOpen,
    },
  });

// Reducer
export const reducer = handleActions<Record<string, boolean>, any>(
  {
    [UPDATE_COLLAPSIBLE_SECTION]: (state, action) => ({
      ...state,
      [action.payload.collapsibleSection]: action.payload.isOpen,
    }),
  },
  initialState
);

export default reducer;
