import { createAction } from 'redux-actions';

import { VisitType } from 'features/visitors/constants';

import {
  FETCH_VISITORS,
  FETCH_VISITORS_SUCCESS,
  VISITOR_CREATED,
  VISITOR_UPDATED,
  VISITOR_DELETED,
  VISITORS_FILTER_CHANGE,
  VISITORS_DATE_CHANGE,
} from '../constants';

export const filterChange = (key: VisitType | 'search', value: boolean | string) => {
  return {
    type: VISITORS_FILTER_CHANGE,
    payload: {
      key,
      value,
    },
  };
};

export const fetchStart = createAction(FETCH_VISITORS);
export const fetchComplete = createAction(FETCH_VISITORS_SUCCESS);
export const visitorCreated = createAction(VISITOR_CREATED);
export const visitorUpdated = createAction(VISITOR_UPDATED);
export const visitorDeleted = createAction(VISITOR_DELETED);
export const visitorsDateChange = createAction(VISITORS_DATE_CHANGE);
