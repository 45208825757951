import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import { StrictButtonProps } from '@wework/dieter-ui';

// eslint-disable-next-line import/no-named-default
import { default as RayChip, ChipSize } from 'components-ray/chip';
import { FilterOption } from 'features/search/types';
import { useSendEvent } from 'lib/contextualAnalytics/sendEvent';

import { SEARCH_SET_FILTER_EVENT_LABEL } from '../constants';

type OwnProps = StrictButtonProps & {
  option: FilterOption;
  onFilterChange: (filterName: string, value: boolean) => void;
  checked?: boolean;
};

type Props = OwnProps;

const FilterChip = ({ checked = false, disabled, option, onFilterChange }: Props) => {
  const sendEvent = useSendEvent();
  const { id, title } = option;
  const capitalizedTitle = capitalize(title);
  const toggleChip = useCallback(() => {
    const toggledValue = !checked;
    onFilterChange(id, toggledValue);
    sendEvent(
      'change',
      {
        filter_name: option.title,
        filter_value: toggledValue,
      },
      SEARCH_SET_FILTER_EVENT_LABEL
    );
  }, [onFilterChange, id, checked, option, sendEvent]);

  return (
    <RayChip
      className="p-2xs mr-2xs"
      disabled={disabled}
      onClick={toggleChip}
      selected={checked}
      size={ChipSize.SMALL}
    >
      {capitalizedTitle}
    </RayChip>
  );
};
export default React.memo(FilterChip);
