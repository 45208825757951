import { uniq } from 'lodash';

import { InventoryItem } from './types';

export const locationIdsForInventory = (inventory: Array<InventoryItem>) => {
  const allLocIds: Array<string> = inventory
    .map(inv => inv.locationId?.value)
    .filter(Boolean) as Array<string>; // typescript doesn't recognize guard
  return uniq(allLocIds);
};
