export const KEYBOARD_NAVIGATION_EVENT = 'perform a keyboard navigation in search';
export const KEYBOARD_NAVIGATION_ITEM_EVENT_LABEL = 'sst_search_items_keyboard_navigate';
export const KEYBOARD_NAVIGATION_TAB_EVENT_LABEL = 'sst_search_tabs_keyboard_navigate';

export const TAB_NAVIGATION_EVENT = 'perform a tab navigation in search';
export const TAB_NAVIGATION_EVENT_LABEL = 'sst_search_tabs_navigate';

export const SEARCH_TERM_EVENT = 'type a search term';
export const SEARCH_TERM_EVENT_LABEL = 'sst_search_type_term';
export const SEARCH_TERM_EVENT_OBJECTIVE = 'search term';

export const SEARCH_SET_FILTER_EVENT = 'change a filter in search';
export const SEARCH_SET_FILTER_EVENT_LABEL = 'sst_search_set_filter';

export const SEARCH_CLEAR_QUERY_EVENT = 'clear query';
export const SEARCH_CLEAR_QUERY_EVENT_LABEL = 'sst_search_clear_term';
