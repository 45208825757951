import { Action, createAction, handleActions } from 'redux-actions';
import { formValueSelector } from 'redux-form';

import config from 'config';
import { createRequestAction } from 'store/util';
import { createConstants, createRequestConstantNames } from 'store/util/createConstants';
import { getUserUuidFromAuth } from 'features/auth/selectors';
import { fetchMemberByUuid } from 'store/modules/members';
import { replaceVariables } from 'features/communication/memberNotifications/templates/memberNotificationVariables';
import { SEND_MEMBER_NOTIFICATION_FORM_NAME } from 'features/communication/memberNotifications/constants';
import { getUserName } from 'features/communication/memberNotifications/redux/selectors';
import {
  BaseAction,
  Dispatch,
  GetGlobalState,
  GetState,
  RequestActionTypes,
  ActionWithPayload,
} from 'store/types';
import {
  MemberNotificationDeliveryType,
  MemberNotificationTemplate,
  TestNotificationStatus,
  Notification,
  MemberNotificationTemplateResponse,
  MemberNotificationSource,
} from 'features/communication/memberNotifications/types';
import { Member } from 'features/locations/buildingDirectory/types';
import { getCurrentLocationUuid } from 'store/selectors';
import { ToastType } from 'store/modules/toasts/constants';
import { MemberSearchResultItem } from 'store/modules/search/searchService/types';

const fetchMemberNotificationTemplatesConsts = createRequestConstantNames(
  'FETCH_MEMBER_NOTIFICATION_TEMPLATES'
);
const saveMemberNotificationTemplateConsts = createRequestConstantNames(
  'SAVE_MEMBER_NOTIFICATIONS_TEMPLATES'
);
const sendMemberNotificationConsts = createRequestConstantNames('SEND_MEMBER_NOTIFICATIONS');
const sendTestMemberNotificationConsts = createRequestConstantNames(
  'SEND_TEST_MEMBER_NOTIFICATION'
);
const fetchUserConsts = createRequestConstantNames('MEMBER_NOTIFICATIONS_FETCH_USER');

interface State {
  history: {
    error: Error | null | undefined;
    loading: boolean;
    loaded: boolean;
    items: Array<Notification>;
    users: {};
  };
  templates: {
    error: null;
    loading: boolean;
    loaded: boolean;
    items: Array<MemberNotificationTemplate>;
    selectedId: number | null;
  };
  currentModal: {
    name: string;
    member?: Member;
    template?: MemberNotificationTemplate;
    showMemberSearchInput?: boolean;
    status: TestNotificationStatus | null | undefined;
    source?: MemberNotificationSource;
  };
}

export interface MemberNotificationsSubset {
  memberNotifications: State;
}

export const {
  FETCH_MEMBER_NOTIFICATION_TEMPLATES,
  FETCH_MEMBER_NOTIFICATION_TEMPLATES_SUCCESS,
  FETCH_MEMBER_NOTIFICATION_TEMPLATES_FAIL,
  SAVE_MEMBER_NOTIFICATIONS_TEMPLATES,
  SAVE_MEMBER_NOTIFICATIONS_TEMPLATES_SUCCESS,
  SAVE_MEMBER_NOTIFICATIONS_TEMPLATES_FAIL,
  SEND_MEMBER_NOTIFICATIONS,
  SEND_MEMBER_NOTIFICATIONS_SUCCESS,
  SEND_MEMBER_NOTIFICATIONS_FAIL,
  SEND_TEST_MEMBER_NOTIFICATION,
  SEND_TEST_MEMBER_NOTIFICATION_SUCCESS,
  SEND_TEST_MEMBER_NOTIFICATION_FAIL,
  MEMBER_NOTIFICATIONS_FETCH_USER,
  MEMBER_NOTIFICATIONS_FETCH_USER_SUCCESS,
} = createConstants([
  ...fetchMemberNotificationTemplatesConsts,
  ...saveMemberNotificationTemplateConsts,
  ...sendMemberNotificationConsts,
  ...sendTestMemberNotificationConsts,
  ...fetchUserConsts,
]);

export const SEND_MEMBER_NOTIFICATION_MODAL_OPEN = 'MEMBER_NOTIFICATION_MODAL_OPEN';
export const UPDATE_MEMBER_NOTIFICATION_MODAL = 'UPDATE_MEMBER_NOTIFICATION_MODAL';
export const SEND_MEMBER_NOTIFICATION_MODAL_CLOSE = 'MEMBER_NOTIFICATION_MODAL_CLOSE';
export const TEST_MEMBER_NOTIFICATION_MODAL_CLOSE = 'TEST_MEMBER_NOTIFICATION_MODAL_CLOSE';
const SET_SELECTED_TEMPLATE_ID = 'SET_SELECTED_TEMPLATE_ID';
// Actions

export const setSelectedTemplateId = createAction(SET_SELECTED_TEMPLATE_ID);

export const fetchMemberNotificationTemplates = () => (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  const locationUuid = getCurrentLocationUuid(getState(), {});
  const action = createRequestAction({
    endpoint: `${config.memberNotifications.uri}/api/v1/locations/${locationUuid}/templates`,
    types: [
      FETCH_MEMBER_NOTIFICATION_TEMPLATES,
      FETCH_MEMBER_NOTIFICATION_TEMPLATES_SUCCESS,
      FETCH_MEMBER_NOTIFICATION_TEMPLATES_FAIL,
    ],
    meta: {
      locationUuid,
    },
  });

  return dispatch(action);
};

export const fetchUserName = (userId: string) => async (
  dispatch: Dispatch<ActionWithPayload<string> | BaseAction>,
  getState: GetState<MemberNotificationsSubset>
) => {
  const user = getUserName(getState(), userId);

  if (user && user.loading) {
    return;
  }
  dispatch({ type: MEMBER_NOTIFICATIONS_FETCH_USER, payload: userId });
  const response = await dispatch(fetchMemberByUuid(userId));
  dispatch({ type: MEMBER_NOTIFICATIONS_FETCH_USER_SUCCESS, payload: response.payload });
};

export const saveMemberNotificationTemplate = (template: { id: number }) => (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  const locationUuid = getCurrentLocationUuid(getState(), {});
  const templateId = template.id.toString();
  const action = createRequestAction<MemberNotificationTemplateResponse>({
    endpoint: `${config.memberNotifications.uri}/api/v1/locations/${locationUuid}/templates/${templateId}`,
    method: 'PUT',
    types: [
      SAVE_MEMBER_NOTIFICATIONS_TEMPLATES,
      {
        type: SAVE_MEMBER_NOTIFICATIONS_TEMPLATES_SUCCESS,
        meta: {
          notification: {
            message: 'Successfully update template.',
            type: ToastType.SUCCESS,
          },
        },
      },
      {
        type: SAVE_MEMBER_NOTIFICATIONS_TEMPLATES_FAIL,
        meta: {
          notification: {
            message: 'Failed to update template.',
            type: ToastType.ERROR,
          },
        },
      },
    ],
    body: template,
    meta: {
      locationUuid,
    },
  });

  return dispatch(action);
};

export const createMemberNotificationAction = (
  member: Member,
  _title: string,
  body: string,
  type: MemberNotificationDeliveryType | null | undefined,
  types: RequestActionTypes
) => (dispatch: Dispatch<BaseAction>, getState: GetGlobalState) => {
  const state = getState();

  const locationUuid = getCurrentLocationUuid(state, {});
  const uuid = getUserUuidFromAuth(state);
  const message = body;

  const action = createRequestAction({
    endpoint: `${config.welkioAuthProxy.uri}/access/v2/deliveries`,
    method: 'POST',
    types,
    body: {
      location_id: locationUuid,
      user_id: member.uuid,
      comment: message,
      commenter_id: uuid,
      notify: true,
      type,
      delivered_at: new Date().toISOString(),
      display_wrapper: false,
    },
  });

  return dispatch(action);
};

export const sendTestMemberNotification = (
  member: Member,
  memberNotificationTemplate: MemberNotificationTemplate
) => (dispatch: Dispatch<BaseAction>) => {
  const { body, title, type } = memberNotificationTemplate;

  const types: RequestActionTypes = [
    SEND_TEST_MEMBER_NOTIFICATION,
    SEND_TEST_MEMBER_NOTIFICATION_SUCCESS,
    SEND_TEST_MEMBER_NOTIFICATION_FAIL,
  ];

  return dispatch(
    createMemberNotificationAction(
      member,
      replaceVariables(title, member),
      replaceVariables(body, member),
      type,
      types
    )
  );
};

export const sendMemberNotification = (member: Member) => (
  dispatch: Dispatch<BaseAction>,
  getState: GetGlobalState
) => {
  const state = getState();
  const sendMemberNotificationFormValueSelector = formValueSelector(
    SEND_MEMBER_NOTIFICATION_FORM_NAME
  );

  const { body, title, type } = sendMemberNotificationFormValueSelector(
    state,
    'body',
    'title',
    'type'
  );

  const types: RequestActionTypes = [
    SEND_MEMBER_NOTIFICATIONS,
    {
      type: SEND_MEMBER_NOTIFICATIONS_SUCCESS,
      meta: {
        notification: {
          message: 'Your message has been sent!',
          type: ToastType.SUCCESS,
        },
      },
    },
    {
      type: SEND_MEMBER_NOTIFICATIONS_FAIL,
      meta: {
        error: true,
        notification: {
          message: 'Your message was not sent due to an error.',
          type: ToastType.ERROR,
        },
      },
    },
  ];

  return dispatch(createMemberNotificationAction(member, title, body, type, types));
};

// Initial State
const initialState: State = {
  history: {
    error: null,
    loading: false,
    loaded: false,
    items: [],
    users: {},
  },
  templates: {
    error: null,
    loading: false,
    loaded: false,
    items: [],
    selectedId: null,
  },
  currentModal: {
    name: '',
    status: null,
  },
};

// Reducer
export const reducer = handleActions<State, any>(
  {
    [FETCH_MEMBER_NOTIFICATION_TEMPLATES]: state => ({
      ...state,
      templates: {
        ...state.templates,
        loading: true,
        loaded: false,
        items: [],
        error: null,
        selectedId: null,
      },
    }),

    [FETCH_MEMBER_NOTIFICATION_TEMPLATES_SUCCESS]: (
      state: State,
      { payload }: Action<Array<MemberNotificationTemplateResponse>>
    ): State => ({
      ...state,
      templates: {
        ...state.templates,
        loading: false,
        loaded: true,
        items: payload,
        error: null,
        selectedId: payload?.[0].id ?? null,
      },
    }),

    [FETCH_MEMBER_NOTIFICATION_TEMPLATES_FAIL]: (state, { payload }) => ({
      ...state,
      templates: {
        ...state.templates,
        loading: false,
        loaded: false,
        items: [],
        error: payload,
      },
    }),

    [MEMBER_NOTIFICATIONS_FETCH_USER]: (state, { payload }) => ({
      ...state,
      history: {
        ...state.history,
        users: {
          ...state.history.users,
          [payload]: {
            loading: true,
          },
        },
      },
    }),
    [MEMBER_NOTIFICATIONS_FETCH_USER_SUCCESS]: (state, { payload }) => ({
      ...state,
      history: {
        ...state.history,
        users: {
          ...state.history.users,
          [payload.uuid]: payload,
        },
      },
    }),
    [SEND_MEMBER_NOTIFICATIONS]: state => ({
      ...state,
      currentModal: {
        name: '',
        status: null,
      },
    }),

    [SEND_MEMBER_NOTIFICATIONS_FAIL]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [SEND_MEMBER_NOTIFICATION_MODAL_OPEN]: (state, action) => ({
      ...state,
      currentModal: {
        name: 'memberNotificationModal',
        member: action.payload.member,
        template: action.payload.template,
        showMemberSearchInput: action.payload.showMemberSearchInput,
        status: null,
        source: action.payload.source,
      },
    }),

    [SEND_MEMBER_NOTIFICATION_MODAL_CLOSE]: state => ({
      ...state,
      currentModal: {
        name: '',
        status: null,
      },
    }),

    [UPDATE_MEMBER_NOTIFICATION_MODAL]: (state, action) => ({
      ...state,
      currentModal: {
        ...state.currentModal,
        member: action.payload.member,
      },
    }),

    [SEND_TEST_MEMBER_NOTIFICATION]: state => ({
      ...state,
      currentModal: {
        name: 'testNotificationModal',
        status: 'sending',
      },
    }),

    [SEND_TEST_MEMBER_NOTIFICATION_FAIL]: state => ({
      ...state,
      currentModal: {
        name: 'testNotificationModal',
        status: 'failed',
      },
    }),

    [SEND_TEST_MEMBER_NOTIFICATION_SUCCESS]: state => ({
      ...state,
      currentModal: {
        name: 'testNotificationModal',
        status: 'success',
      },
    }),

    [TEST_MEMBER_NOTIFICATION_MODAL_CLOSE]: state => ({
      ...state,
      currentModal: {
        name: '',
        status: null,
      },
    }),

    [SET_SELECTED_TEMPLATE_ID]: (state, { payload: selectedId }) => ({
      ...state,
      templates: {
        ...state.templates,
        selectedId,
      },
    }),
  },
  initialState
);

// Action creators

export const openSendMemberNotificationModal = (
  member: Member | Partial<MemberSearchResultItem> | null | undefined,
  template: MemberNotificationTemplate,
  source: MemberNotificationSource,
  showMemberSearchInput?: boolean
) => ({
  type: SEND_MEMBER_NOTIFICATION_MODAL_OPEN,
  payload: { member, template, showMemberSearchInput, source },
});

export const updateMemberNotificationModal = (member: Member) => ({
  type: UPDATE_MEMBER_NOTIFICATION_MODAL,
  payload: { member },
});

export const closeSendMemberNotificationModal = createAction(SEND_MEMBER_NOTIFICATION_MODAL_CLOSE);

export const closeTestNotificationModal = createAction(TEST_MEMBER_NOTIFICATION_MODAL_CLOSE);

export default reducer;
