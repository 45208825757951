import { LocationInfo, LocationInfoPayload } from 'features/building/types';

export const getLocationInfoByID = (
  locationInfos: LocationInfoPayload | undefined
): { [key: string]: LocationInfo } => {
  if (!locationInfos) return {};

  return locationInfos.items.reduce((result, acc) => {
    if (!locationInfos.includes || !locationInfos.includes.Entry) {
      return result; // no data, someone forgot to publish the marketing attributes
    }

    const entry = locationInfos.includes.Entry.find(
      entry => entry.sys.id === acc.fields.marketingAttributes?.sys.id
    );

    const transportation = {
      transportationBike: entry?.fields.transportationBike,
      transportationBus: entry?.fields.transportationBus,
      transportationFerry: entry?.fields.transportationFerry,
      transportationFreeway: entry?.fields.transportationFreeway,
      transportationMetro: entry?.fields.transportationMetro,
      transportationParking: entry?.fields.transportationParking,
    };

    const thumbnails = entry?.fields?.portfolioPageThumbnail ?? [];
    const thumbnail = thumbnails[0]?.thumbnails?.webimage ?? '';

    result[acc.fields.mlsUuid] = {
      name: entry?.fields.name || acc.fields.name,
      entranceInstructions: entry?.fields.entranceInstructions,
      imageUrl: thumbnail,
      nearbyTransit: transportation,
    };

    return result;
  }, {});
};
