import React from 'react';
import { HotKeys } from 'react-hotkeys';
import { noop, clamp } from 'lodash';

type Props = Readonly<{
  children: (currentItemIndex: number | null) => React.ReactNode;
  className?: string;
  clearable: boolean;
  initialItemIndex: number | null;
  currentItemIndex?: number | null;
  itemCount: number;
  onClear: () => void;
  onSelect: (selectedItem: number) => void;
  onChange: (selectedItem: number | null) => void;
  onPrev: () => void;
  onNext: () => void;
  wrapSelectionAround?: boolean;
}>;

type State = { currentItemIndex: number | null };

const hotKeysMap = {
  up: 'up',
  down: 'down',
  clear: 'esc',
  select: 'enter',
};

const calculateNextIndex = (
  currentItemIndex: number | null,
  offset: 1 | -1,
  itemCount: number,
  wrapSelectionAround = true
) => {
  let nextIndex = currentItemIndex;

  if (nextIndex === null) {
    // If nextIndex is null and we're stepping up, the next value should be the 0th index.
    // This prevents it from being incorrectly set to 1.
    nextIndex = offset > 0 ? -1 : 0;
  }

  nextIndex += offset;

  return wrapSelectionAround
    ? (itemCount + nextIndex) % itemCount
    : clamp(nextIndex, 0, itemCount - 1);
};

class KeyboardListNavigation extends React.Component<Props, State> {
  static defaultProps = {
    clearable: true,
    initialItemIndex: null,
    onClear: noop,
    onChange: noop,
    onPrev: noop,
    onNext: noop,
    wrapSelectionAround: true,
  };

  static getDerivedStateFromProps = (props: Props, state: State): State => {
    // If non-clearable, the current item index must be a number. Set it to the initial index (or 0 if it is undefined or null).
    if (!props.clearable && typeof state.currentItemIndex !== 'number') {
      return {
        ...state,
        currentItemIndex: props.initialItemIndex || 0,
      };
    }

    return state;
  };

  state = {
    currentItemIndex: this.props.initialItemIndex,
  };

  isControlled = (): boolean => typeof this.props.currentItemIndex !== 'undefined';

  currentItemIndex = (): number | null => {
    if (this.isControlled()) {
      // Flow is insisting that isControlled doesn't fix that check.
      return typeof this.props.currentItemIndex === 'undefined'
        ? null
        : this.props.currentItemIndex;
    }

    return this.state.currentItemIndex;
  };

  clear = () => {
    const { clearable, onChange, onClear } = this.props;

    if (clearable) {
      onClear();
      onChange(null);

      if (this.isControlled()) {
        // No need to update internal state if component is controlled from outside.
        return;
      }

      this.setState({ currentItemIndex: null });
    }
  };

  changeItemIndex = (offset: 1 | -1) => {
    const { itemCount, wrapSelectionAround } = this.props;

    if (this.isControlled()) {
      const nextIndex = calculateNextIndex(
        this.currentItemIndex(),
        offset,
        itemCount,
        wrapSelectionAround
      );

      this.props.onChange(nextIndex);

      // No need to update internal state if component is controlled from outside.
      return;
    }

    this.setState(state => {
      const nextIndex = calculateNextIndex(
        state.currentItemIndex,
        offset,
        itemCount,
        wrapSelectionAround
      );

      return {
        ...state,
        currentItemIndex: nextIndex,
      };
    });
  };

  selectNextItem = () => {
    this.changeItemIndex(1);

    this.props.onNext();
  };

  selectPrevItem = () => {
    this.changeItemIndex(-1);

    this.props.onPrev();
  };

  selectCurrentItem = () => {
    const currentIndex = this.currentItemIndex();

    if (typeof currentIndex === 'number') {
      this.props.onSelect(currentIndex);
    }
  };

  hotKeysHandlers = {
    up: this.selectPrevItem,
    down: this.selectNextItem,
    clear: this.clear,
    select: this.selectCurrentItem,
  };

  render() {
    const { children, className } = this.props;

    return (
      <HotKeys keyMap={hotKeysMap} handlers={this.hotKeysHandlers} className={className}>
        {children(this.currentItemIndex())}
      </HotKeys>
    );
  }
}

export default KeyboardListNavigation;
