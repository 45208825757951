import { handleActions } from 'redux-actions';

import { Opportunity } from 'features/paperwork/modals/selectSfOpportunityModal/types';

import {
  FETCH_OPEN_OPPORTUNITIES,
  FETCH_OPEN_OPPORTUNITIES_FAIL,
  FETCH_OPEN_OPPORTUNITIES_SUCCESS,
} from './constants';

export interface State {
  loaded: boolean;
  openOpportunities: {
    opportunities?: Array<Opportunity>;
  };
  loading: boolean;
  error: boolean | null | undefined;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  openOpportunities: {},
};

const reducer = handleActions<State, any>(
  {
    [FETCH_OPEN_OPPORTUNITIES]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_OPEN_OPPORTUNITIES_SUCCESS]: (state: State, action) => ({
      ...state,
      openOpportunities: action.payload,
      loading: false,
      loaded: true,
      error: null,
    }),

    [FETCH_OPEN_OPPORTUNITIES_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
