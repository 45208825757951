import { ROUTES as SINGLE_SPA_ROUTES } from 'features/app/appLayout/singleSpa/routes';

const singleSpaRoutes = SINGLE_SPA_ROUTES.map(({ exact, path }) => ({
  exact,
  path,
}));

export default [
  {
    exact: true,
    path: '/invoiceReview',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "invoiceReview" */
        'features/invoiceReview/invoiceReview'
      ),
  },
  {
    exact: false,
    path: '/access-reports',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "accessReports" */
        'features/accessReports'
      ),
  },
  {
    exact: false,
    path: '/access-level-groups',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "accessLevelGroups" */
        'features/accessLevelGroups'
      ),
  },
  {
    exact: false,
    path: '/access-level-group/:page',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "accessLevelGroups" */
        'features/accessLevelGroups/accessLevelGroupPage'
      ),
  },
  {
    exact: true,
    path: '/holds/new', // Used by Spaceman's "Place Account Hold" button
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "newHold" */
        'features/accounts/holds/newHold'
      ),
  },
  {
    exact: false,
    path: '/locations/:code',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "location" */
        'features/locations/location'
      ),
  },
  {
    exact: true,
    path: '/milestones/:view',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "milestones" */
        'features/home/milestones'
      ),
  },
  {
    exact: false,
    path: '/renewals',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "renewals" */
        'features/renewals'
      ),
  },
  {
    exact: true,
    path: '/accessControl',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "accessControl" */
        'features/accessControl/accessLevels/accessLevels'
      ),
  },
  {
    exact: false,
    path: '/keycards',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "keycards" */
        'features/accessControl/keycards'
      ),
  },
  {
    exact: false,
    path: '/s2keycards',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "s2keycards" */
        'features/accessControl/s2keycards'
      ),
  },
  {
    exact: true,
    path: '/offices/:uuid',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "office" */
        'features/office'
      ),
  },
  {
    exact: true,
    path: '/unauthorized',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "unauthorizedPage" */
        'components/unauthorizedPage'
      ),
  },
  {
    exact: true,
    path: '/visitors',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "visitors" */
        'features/visitors/visitors'
      ),
  },
  {
    exact: false,
    path: '/events',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "events" */
        'features/events/routes'
      ),
  },
  {
    exact: true,
    path: '/promotions',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "promotions" */
        'features/promotions'
      ),
  },
  {
    exact: true,
    path: '/mimo-tracker',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "mimoTracker" */
        'features/mimo/mimoTracker-ray'
      ),
  },
  {
    exact: true,
    path: '/mimo-requests',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "mimoRequests" */
        'features/mimo/requests'
      ),
  },
  {
    exact: false,
    path: '/inventory-manager/:code?',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "inventoryManager" */
        'features/home/inventoryManager'
      ),
  },
  {
    exact: true,
    path: '/communications/notificationTemplates',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "communication" */
        'features/communication/memberNotifications/templates/notificationTemplatesPage'
      ),
  },
  {
    exact: true,
    path: '/communications/announcements/new',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "communication" */
        'features/communication/announcements/newAnnouncement'
      ),
  },
  {
    exact: false,
    path: '/communications',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "communication" */
        'features/communication/communication'
      ),
  },
  {
    exact: true,
    path: '/rooms',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "rooms" */
        'features/rooms/overview'
      ),
  },
  {
    exact: false,
    path: '/directory',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "buildingDirectory" */
        'features/locations/buildingDirectory/buildingDirectory'
      ),
  },
  {
    exact: true,
    path: '/mimo-settings',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "mimoSettings" */
        'features/mimo/settings'
      ),
  },
  {
    exact: true,
    path: '/check-ins',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "checkIns" */
        'features/check-ins'
      ),
  },
  {
    exact: true,
    path: '/about',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "about" */
        'features/about'
      ),
  },
  {
    exact: true,
    path: '/members/:uuid/audit-log',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "memberAuditLog" */
        'features/profile/memberAuditLog'
      ),
  },
  {
    exact: false,
    path: '/members/:uuid',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "profile" */
        'features/profile'
      ),
  },
  {
    exact: true,
    path: '/companies/:uuid/audit-log',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "companyAuditLog" */
        'features/companies/companyAuditLog'
      ),
  },
  {
    exact: true,
    path: '/companies/:uuid/access-levels',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "companyAuditLog" */
        'features/companies/companyAccessLevelsList'
      ),
  },
  {
    exact: false,
    path: '/companies/:uuid',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "company" */
        'features/companies/company'
      ),
  },
  {
    exact: false,
    path: '/organizations/:organizationUuid/edit',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "organization" */
        'features/organization/editOrganizationPage'
      ),
  },
  {
    exact: false,
    path: '/organizations/:organizationUuid',
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "organization" */
        'features/organization/organizationPage'
      ),
  },
  ...singleSpaRoutes,
];
