import { handleActions } from 'redux-actions';

import { ReservationHistory } from 'features/companies/redux/reservationHistory/types';
import {
  FETCH_RESERVATION_HISTORY_BY_ACCOUNT,
  FETCH_RESERVATION_HISTORY_BY_ACCOUNT_SUCCESS,
  FETCH_RESERVATION_HISTORY_BY_ACCOUNT_FAIL,
} from 'features/companies/redux/reservationHistory/constants';

export interface State {
  loaded: boolean;
  byAccountUuid: Hash<Array<ReservationHistory>>;
  loading: boolean;
  error: boolean | null | undefined;
}

export interface ReservationHistorySubset {
  reservationHistory: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byAccountUuid: {},
  error: null,
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_RESERVATION_HISTORY_BY_ACCOUNT]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_RESERVATION_HISTORY_BY_ACCOUNT_SUCCESS]: (
      state: State,
      action: {
        payload: {
          result: Array<ReservationHistory>;
          params: { account_id: string };
        };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byAccountUuid: {
        ...state.byAccountUuid,
        [action.payload.params.account_id]: action.payload.result,
      },
    }),

    [FETCH_RESERVATION_HISTORY_BY_ACCOUNT_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
