import { handleActions } from 'redux-actions';

import {
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
} from 'features/accounts/redux/constants';
import { AccountData, NormalizedAccountData } from 'features/accounts/redux/types';

export interface State {
  loaded: boolean;
  loading: boolean;
  error: boolean | null | undefined;
  byUuid: Hash<NormalizedAccountData | null>;
}

export interface AccountsSubset {
  accounts: State;
}

const initialState: State = {
  loading: false,
  loaded: false,
  byUuid: {},
  error: null,
};

const normalizeAccountData = (accountData: AccountData): NormalizedAccountData | null => {
  if (!accountData) {
    return null;
  }

  const {
    short_code: shortCode,
    uuid,
    name,
    legacy_conference_room_plan: hasLegacyConferenceRoomPlan,
    bundles,
    notes,
  } = accountData;

  return {
    uuid,
    name,
    shortCode,
    hasLegacyConferenceRoomPlan,
    bundles,
    notes,
  };
};

export const reducer = handleActions<State, any>(
  {
    [GET_ACCOUNT]: state => ({ ...state, loading: true, loaded: false }),

    [GET_ACCOUNT_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byUuid: {
        ...state.byUuid,
        [action.payload.params.id]: normalizeAccountData(action.payload.result),
      },
    }),

    [GET_ACCOUNT_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
