import React from 'react';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import PersistentObject from 'lib/persistentObject';
import TextLink from 'components/textLink';
import Trackable, { TrackableType } from 'components/decorators/trackable';
import { getReloadRequired } from 'store/selectors';

import styles from './reloadRequiredMessage.scss';

const mapStateToProps = state => ({
  reloadRequired: getReloadRequired(state),
});

type Props = Readonly<ReturnType<typeof mapStateToProps> & TrackableType>;

type PersistentRefreshMarker = { message: string };

const RELOAD_MESSAGE_REFRESH = 'ss_reload_message_refresh';

const persistentRefreshMessage: PersistentObject<PersistentRefreshMarker> = new PersistentObject(
  sessionStorage,
  RELOAD_MESSAGE_REFRESH
);

function ReloadLink({ onClick }: { onClick: React.MouseEventHandler }) {
  return (
    <TextLink href={window.location.href} onClick={onClick}>
      Reload
    </TextLink>
  );
}

export class ReloadRequiredMessage extends React.Component<Props> {
  componentDidMount() {
    const marker = persistentRefreshMessage.get();

    if (marker) {
      this.props.trackInteraction('Reload app message interaction', {
        label: 'sst_reload_required_message_reloaded',
        text: marker.message,
      });

      persistentRefreshMessage.clear();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { trackInteraction, reloadRequired } = this.props;

    if (reloadRequired.message && reloadRequired.message !== prevProps.reloadRequired.message) {
      trackInteraction('Reload app message shown', {
        label: 'sst_reload_required_message_shown',
        text: reloadRequired.message || '',
        isOnline: reloadRequired.isOnline,
      });
    }
  }
  reload = (evt: React.MouseEvent) => {
    const { reloadRequired } = this.props;

    evt.preventDefault();

    persistentRefreshMessage.set({
      message: this.props.reloadRequired.message || '',
    });

    if (reloadRequired.callback) {
      reloadRequired.callback(evt);
    }

    window.location.reload();
  };

  createMessage() {
    const { reloadRequired } = this.props;

    if (reloadRequired.message) {
      return (
        <div>
          {/* $FlowFixMe TODO inexact issue with Event and MouseEvent */}
          {reloadRequired.message} <ReloadLink onClick={this.reload} />.
        </div>
      );
    }

    return null;
  }

  render() {
    const { reloadRequired } = this.props;

    const classes = cn(styles.wrapper, {
      [styles.wrapperShow]: !!reloadRequired.message,
    });

    return (
      <div className={classes}>
        <div className={styles.notification}>{this.createMessage()}</div>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  Trackable({ workflow: 'auth', feature: 'login', featureContext: 'page.login' })
)(ReloadRequiredMessage);
