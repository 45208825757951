import { handleActions, ActionMeta } from 'redux-actions';

import { LOADING_STATUS, Floor } from 'features/floors/types';
import {
  FETCH_FLOORS,
  FETCH_FLOORS_SUCCESS,
  FETCH_FLOORS_FAIL,
} from 'features/floors/redux/constants';
import {
  FloorsState as State,
  SpacemanApiFetchFloorMeta,
  SpacemanApiFetchFloorResponse,
} from 'features/floors/redux/types';

export const initialState: State = {
  floorsByLocationUuid: {},
  loadingStatuses: {},
};

const getFloorsDataFromLocationResponse = (res: SpacemanApiFetchFloorResponse): Array<Floor> => {
  return res?.floors;
};

export const reducer = handleActions<State, any, { locationUuid: string }>(
  {
    [FETCH_FLOORS]: (
      state: State,
      action: ActionMeta<unknown, SpacemanApiFetchFloorMeta>
    ): State => {
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          [action.meta.locationUuid]: LOADING_STATUS.LOADING,
        },
      };
    },
    [FETCH_FLOORS_FAIL]: (
      state: State,
      action: ActionMeta<unknown, SpacemanApiFetchFloorMeta>
    ): State => {
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          [action.meta.locationUuid]: LOADING_STATUS.ERROR,
        },
      };
    },
    [FETCH_FLOORS_SUCCESS]: (
      state: State,
      action: ActionMeta<SpacemanApiFetchFloorResponse, SpacemanApiFetchFloorMeta>
    ): State => {
      return {
        ...state,
        loadingStatuses: {
          ...state.loadingStatuses,
          [action.meta.locationUuid]: LOADING_STATUS.LOADED,
        },
        floorsByLocationUuid: {
          ...state.floorsByLocationUuid,
          [action.meta.locationUuid]: getFloorsDataFromLocationResponse(action.payload),
        },
      };
    },
  },
  initialState
);

export default reducer;
