import createReduxConstant from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';
import { dateFormatsDeprecated } from 'lib/constants';

export const DEFAULT_CONFERENCE_ROOM_PAGE_SIZE = 1000;

export const [
  FETCH_CONFERENCE_ROOMS_BY_LOCATION,
  FETCH_CONFERENCE_ROOMS_BY_LOCATION_SUCCESS,
  FETCH_CONFERENCE_ROOMS_BY_LOCATION_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_CONFERENCE_ROOMS_BY_LOCATION'));

export const [
  FETCH_RESERVATION_BY_LOCATION,
  FETCH_RESERVATION_BY_LOCATION_SUCCESS,
  FETCH_RESERVATION_BY_LOCATION_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_RESERVATION_BY_LOCATION'));

export const [
  FETCH_RESERVATION_BY_RESERVABLE,
  FETCH_RESERVATION_BY_RESERVABLE_SUCCESS,
  FETCH_RESERVATION_BY_RESERVABLE_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_RESERVATION_BY_RESERVABLE'));

export const [
  FETCH_ROOM_RESERVATION_REASONS,
  FETCH_ROOM_RESERVATION_REASONS_SUCCESS,
  FETCH_ROOM_RESERVATION_REASONS_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ROOM_RESERVATION_REASONS'));

export const REFUND_REASON_TYPE = 'refund';
export const CANCEL_REASON_TYPE = 'cancel';

export const DATE_FORMAT = dateFormatsDeprecated.iso_date;

export const UPDATE_CURRENT_TIME_FRAME = createReduxConstant('UPDATE_CURRENT_TIME_FRAME');

export const UPDATE_ROOMS_FILTERS = createReduxConstant('UPDATE_ROOMS_FILTERS');

export const ROOMS_SET_CURRENT_LOCATION = createReduxConstant('ROOMS_SET_CURRENT_LOCATION');
export const TOGGLE_ROOMS_FILTERS = createReduxConstant('TOGGLE_ROOMS_FILTERS');
export const TOGGLE_INTERNAL_FILTER = createReduxConstant('TOGGLE_INTERNAL_FILTER');
export const SET_CAPACITY_FILTER = createReduxConstant('SET_CAPACITY_FILTER');
export const SET_TIME_FILTER = createReduxConstant('SET_TIME_FILTER');
export const TOGGLE_AMENITY_FILTER = createReduxConstant('TOGGLE_AMENITY_FILTER');
export const CLEAR_AMENITIES_FILTER = createReduxConstant('CLEAR_AMENITIES_FILTER');
export const CLEAR_ALL_ROOMS_FILTERS = createReduxConstant('CLEAR_ALL_ROOMS_FILTERS');

export const ROOMS_SET_ACTIVE_RESERVATION = createReduxConstant(
  'ROOMS_RESERVATION_SET_ACTIVE_RESERVATION'
);
export const ROOMS_CLEAR_ACTIVE_RESERVATION = createReduxConstant('ROOMS_CLEAR_ACTIVE_RESERVATION');
