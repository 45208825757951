import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { INIT_EXPERIMENTS } from '@wework/we-experiment-react';

import { ActionWithPayload } from 'store/types';

const LOCAL_EXPERIMENTS = 'LOCAL_EXPERIMENTS';

// For use with ssOperatorService split
// {@link https://jira.weworkers.io/browse/SPSN-10730}
export const SPLIT_HEADER: Record<string, string> = { Split: 'operator-service' };

export type ExperimentGroups = Record<string, string>;

export interface ExperimentsState {
  experimentGroups: ExperimentGroups;
}

export interface ExperimentSubset {
  experiments: ExperimentsState;
}

export const getExperiments = (state: ExperimentSubset): ExperimentGroups =>
  state.experiments.experimentGroups;

export const getSortedExperiments = createSelector(
  getExperiments,
  (experiments): Array<[string, string]> => {
    const entries = Object.entries(experiments);

    return sortBy(entries, ([name]): string => name);
  }
);

const setLocalExperiment = (experimentName, statusToSet) => {
  const localExps = sessionStorage.getItem(LOCAL_EXPERIMENTS);
  const localStorageItem = localExps ? JSON.parse(localExps) : {};
  const localExperiments = localStorageItem || {};
  const newLocalExperimentsToSet = { ...localExperiments, [experimentName]: statusToSet };
  sessionStorage.setItem(LOCAL_EXPERIMENTS, JSON.stringify(newLocalExperimentsToSet));
};

export const getLocalExperiments = () => {
  const localExps = sessionStorage.getItem(LOCAL_EXPERIMENTS);
  return localExps ? JSON.parse(localExps) : {};
};

export function resetLocalExperiments() {
  sessionStorage.setItem(LOCAL_EXPERIMENTS, JSON.stringify({}));
  window.location.reload();
}

export function getLocalExperimentsCount() {
  return Object.keys(getLocalExperiments()).length;
}

const handleLocalExperimentsMiddleWare = (experiments: {}) => {
  // if one has -local set it save it remove it and return it
  Object.keys(experiments).forEach(key => {
    // key: the name of the object key
    const experimentStatus = experiments[key];
    if (experimentStatus.includes('-local')) {
      const statusToSet = experimentStatus.split('-')[0];
      setLocalExperiment(key, statusToSet);
    }
  });

  const localExperiments = getLocalExperiments();
  return { experiments: { ...experiments, ...localExperiments }, localExperiments };
};

type Next = (arg0: {}) => unknown;

export default () => (next: Next) => (action: ActionWithPayload<{ experiments: Hash<string> }>) => {
  if (action.type === INIT_EXPERIMENTS && action.payload.hasOwnProperty('experiments')) {
    // if set flag add to local storage
    const experimentsPayload = handleLocalExperimentsMiddleWare(action.payload.experiments || {});
    return next({ ...action, payload: experimentsPayload });
  }

  return next(action);
};

export function getOperatorServiceSplitHeader(
  state: ExperimentSubset
): Record<string, string> | undefined {
  return getExperiments(state).ssOperatorService === 'on' ? SPLIT_HEADER : undefined;
}
