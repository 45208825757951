import { createRequestConstantNames } from 'store/util/createConstants';
import cc from 'store/util/createReduxConstant';

export const [
  FETCH_PRODUCTS_RESERVATION,
  FETCH_PRODUCTS_RESERVATION_SUCCESS,
  FETCH_PRODUCTS_RESERVATION_FAIL,
] = createRequestConstantNames(cc('FETCH_PRODUCTS_RESERVATION'));

export const [
  FETCH_DISCOUNT_SUGGESTIONS,
  FETCH_DISCOUNT_SUGGESTIONS_SUCCESS,
  FETCH_DISCOUNT_SUGGESTIONS_FAIL,
] = createRequestConstantNames(cc('FETCH_AMENDMENT_DISCOUNT_SUGGESTIONS'));

export const EDIT_PRODUCT_RESERVATION = cc('EDIT_PRODUCT_RESERVATION');

export const ADD_PRODUCT_DISCOUNT = cc('ADD_PRODUCT_DISCOUNT');
export const EDIT_PRODUCT_DISCOUNT = cc('EDIT_PRODUCT_DISCOUNT');
export const REMOVE_PRODUCT_DISCOUNT = cc('REMOVE_PRODUCT_DISCOUNT');

export const CLEAR_PRODUCTS_RESERVATION = cc('CLEAR_PRODUCTS_RESERVATION');

export const EDIT_TERM = cc('EDIT_TERM');
export const EDIT_TERM_FOR_PROMOTION = cc('EDIT_TERM_FOR_PROMOTION');

export const NEW_TERM = cc('NEW_TERM');
export const NEW_TERM_FOR_PROMOTION = cc('NEW_TERM_FOR_PROMOTION');

export const NEW_PROMOTION = cc('NEW_PROMOTION');
export const EDIT_PROMOTION = cc('EDIT_PROMOTION');
export const REMOVE_PROMOTION = cc('REMOVE_PROMOTION');

export const PERPETUATE_PROMOTION_DISCOUNT = cc('PERPETUATE_PROMOTION_DISCOUNT');

export const CHANGE_EXPIRATION_TIME = cc('CHANGE_EXPIRATION_TIME');

export const TOGGLE_OUT_OF_POLICY_ACKNOWLEDEMENT = cc('TOGGLE_OUT_OF_POLICY_ACKNOWLEDEMENT');
export const CHANGE_OUT_OF_POLICY_DISCOUNT_REASON = cc('CHANGE_OUT_OF_POLICY_DISCOUNT_REASON');
export const CHANGE_OUT_OF_POLICY_APPROVER = cc('CHANGE_OUT_OF_POLICY_APPROVER');
export const CHANGE_OUT_OF_POLICY_RATIONALE = cc('CHANGE_OUT_OF_POLICY_RATIONALE');

export const TOGGLE_SINGLE_AGREEMENT = cc('TOGGLE_SINGLE_AGREEMENT');

export const SET_DISCOUNT_TYPE = cc('SET_DISCOUNT_TYPE');
export const SET_COMMITMENT_START_DATE = cc('SET_COMMITMENT_START_DATE');

export const ADD_PRODUCT_RESERVATION = cc('ADD_PRODUCT_RESERVATION');
export const ADD_PRODUCT_RESERVATION_LOADING = cc('ADD_PRODUCT_RESERVATION_LOADING');
export const ADD_PRODUCT_RESERVATION_FAILED = cc('ADD_PRODUCT_RESERVATION_FAILED');
export const ADD_PRODUCT_RESERVATION_LOADED = cc('ADD_PRODUCT_RESERVATION_LOADED');
export const REMOVE_PRODUCT_RESERVATION = cc('REMOVE_PRODUCT_RESERVATION');
