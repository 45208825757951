import { setReloadRequiredMessage } from 'store/modules/app';
import { BaseAction, Dispatch } from 'store/types';

export function notifyLogoutAndFail(
  dispatch: Dispatch<BaseAction>,
  isOnline: boolean
): Promise<void> {
  dispatch(
    setReloadRequiredMessage("You've been logged-out, please reload to log-in again.", null, {
      isOnline,
    })
  );

  return Promise.reject(
    new Error('Could not ensure an active session, session may have expired or cleared.')
  );
}
