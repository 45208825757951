export enum SectionKey {
  PRODUCTS = 'products',
  BILLING_INFORMATION = 'billing-information',
  PAYMENT_INFORMATION = 'payment-information',
  MONTHLY_FEE_SCHEDULE = 'monthly-fee-schedule',
  ADDITIONAL_ATTACHMENTS = 'additional-attachments',
  SIGNATURE = 'signature',
  UPLOAD_PAPERWORK = 'upload-paperwork',
  SALESFORCE_OPPORTUNITY = 'salesforce-opportunity',
  DISCOUNT_ACKNOWLEDGMENT = 'discount-acknowledgment',
  COMMITMENT = 'commitment-start-date',
  DISCOUNT_TYPE = 'discount-type',
  ADD_PRODUCT = 'add-product',
  NOTES = 'notes',
  SERVICE_CLOUD_NUMBER = 'service-cloud-number',
}

export enum ActionKey {
  SAVE_DRAFT = 'save-draft',
  PREVIEW = 'preview',
  SHARE = 'share',
  SEND_TO_DOCUSIGN = 'send-to-docusign',
  SEND = 'send',
  SEND_FOR_APPROVAL = 'send-for-approval',
}

export enum PaperworkFlow {
  AMENDMENT = 'amendment',
  RENEWAL = 'renewal',
}

export type SectionIdentifier = {
  key: SectionKey;
  title: string;
  props?: Hash<any>;
};

export type Action = {
  key: ActionKey;
  label?: string;
  handler?: () => void;
};

export type HeaderInfo = string[];

export type HeaderInput = {
  text: string;
  companyUuid: string;
  info: HeaderInfo;
  flow: PaperworkFlow;
};

export type NavigationInput = {
  sections: Array<SectionIdentifier>;
  onClick: (key: SectionKey) => void;
};

export type NavItemInput = {
  text: string;
  onClick: () => void;
};

export enum DiscountType {
  NO_DISCOUNT = 'no-discount',
  CT_DISCOUNT = 'commitment-term-discount',
  PROMOTION = 'promotion',
}
