import { handleActions } from 'redux-actions';
import { EventTypes } from 'redux-segment';
import { compact, reduce, get } from 'lodash';

import cc from 'store/util/createReduxConstant';

import { EventNameTypes } from '../constants';

export const INTERACTION = cc('analytics/TRACK_INTERACTION');
export const INTERACTION_FAILED = cc('analytics/TRACK_INTERACTION_FAILED');
export const VIEW = cc('analytics/TRACK_VIEW');
export const VIEW_FAILED = cc('analytics/TRACK_VIEW_FAILED');
export const FORM_SUBMISSION = cc('analytics/TRACK_FORM_SUBMISSION');

// Currently, not keeping any internal state about analytics,
// so we have an empty reducer
export const initialState = {};

export const reducer = handleActions<{}, any>({}, initialState);

/* You can attach extra props to track event */
export interface Options extends Hash<any> {
  page?: string;
  feature?: string;
  featureContext?: string;
  workflow?: string;
  event?: string;
  value?: unknown;
  filter_value?: string | null | undefined;
  label?: string;
  title?: string;
  type?: string;
  page_type?: string;
  target_text?: string;
  target_value?: string;
  uuid?: string;
  account_uuid?: string;
  event_uuid?: string;
  event_details?: Hash<any>;
  member_uuid?: string;
  timeToConvert?: number;
  query?: string;
  time_to_convert?: number;
  trackEvent?: string;
  trackEventMetadata?: Hash<any>;
  text?: string;
  name?: string;
  member_name?: string;
  stepSlug?: string;
  reservation_uuid?: string;
  guest_name?: string;
  location_uuid?: string | null | undefined;
  move_id?: string;
  tool?: string;
  isOnline?: boolean;
  description?: string;

  // Added 2019-06
  feature_group?: string;
  screen_name?: string;
  object?: string;
  object_type?: string;
  object_uuid?: string | Array<string> | null | undefined;
  action?: string;
  details?: Hash<any>;
  guest_id?: string;
  target_name?: string;
  time_change_source?: string;
  sf_id?: string;
  spaceman_reservable_uuid?: string;
}

// All workflows, features, and featureContexts that are tracked must be listed below.
export const trackables = {
  // Workflows
  workflow: {
    changeProductLocation: 'Change Product/Location',
    changeSettings: 'Change Settings',
    auth: 'Authentication',
    announcements: 'Announcements',
    search: 'Global Search',
    membersIndex: 'Members Index',
    companiesIndex: 'Companies Index',
    homepage: 'Home Page',
    inventory: 'Inventory ManagementexperimentGroups',
    offices: 'Office Management',
    companyMgmt: 'Company Management',
    paperwork: 'Paperwork',
    memberMgmt: 'Member Management',
    memberBulkUpload: 'Member Bulk Upload',
    guestBulkUpload: 'Guest Bulk Upload',
    mimo: 'MIMO Management',
    reservations: 'Reservations Management',
    accountHolds: 'Account Holds',
    keycards: 'Keycard Management',
    hospitalityNotes: 'Hospitality Notes',
    promotions: 'Promotion Management',
    ticketData: 'Ticket Data',
    quickAdd: 'Quick Add',
    memberNotifications: 'Member Notifications',
    events: 'Events',
    resyncWelkio: 'Resync Welkio',
    reservationsBooking: 'Reservations Booking',
    app: 'App',
    mimoTracker: 'MiMo Tracker',
    memberNotes: 'Hospitality Notes',
    occupancyMap: 'Occupancy Map',
    companyProfile: 'Company Profile',
    invoiceReview: 'Invoice Review',
    inventorySearch: 'Inventory Search',
  },

  // Feature Contexts
  featureContext: {
    search: 'Search',
    card: {
      userNotes: 'Person Notes Card',
    },
    form: {
      userNotes: 'Person Notes Form',
    },
    navbar: 'Navbar',
    filters: {
      filters: 'Filters',
      customFilters: 'Custom Filters',
      inventory: 'Inventory Table Filters',
      sideDrawer: 'Side Drawer Filter',
    },
    avatar: {
      magnify: 'Magnify',
    },
    page: {
      announcements: 'Announcements',
      login: 'Login Screen',
      membersIndex: 'Members Index',
      member: 'Member Page',
      inventory: 'Inventory',
      yourInventory: 'Your Inventory',
      companies: 'Company Index',
      company: 'Company Page',
      people: 'People Page',
      potentialCompany: 'Potential Company Page',
      reservations: 'Reservations Page',
      home: 'Home Page',
      accountHoldForm: 'Account Hold Form',
      accountHoldsList: 'Account Holds List',
      offices: 'Office Page',
      contextualProductMenu: 'Contextual Product Menu',
      memberNotifications: 'Member Notifications Page',
      inventorySearch: 'Inventory Search Page',
      promotions: 'Promotions Page',
    },
    modal: {
      newVisit: 'New Visit Modal',
      newAnnouncement: 'New Announcement Modal',
      deleteNoteConfirmation: 'Delete Note Confirmation Modal',
      settings: 'Settings Modal',
      removeOfficeHold: 'Remove Office Hold Modal',
      officeNotes: 'Office Notes Modal',
      productsLocations: 'Product/Locations Modal',
      entranceInstructions: 'Entrance Instructions Modal',
      newWaitlistEntry: 'New Waitlist Entry Modal',
      editWaitlistEntry: 'Edit Waitlist Entry Modal',
      removeWaitlistEntry: 'Waitlist Remove Entry Modal',
      dropOffice: 'Drop Office Modal',
      transferOffice: 'Transfer Office Modal',
      addOffice: 'Add Office Modal',
      paperworkSummary: 'PPW Summary Modal',
      addOnsPaperworkSummary: 'AddOns PPW Summary Modal',
      agreementModifications: 'Agreement Modifications Screen',
      serviceBundles: 'Service Bundles Screen',
      promotions: 'Promotions Screen',
      confirmCancelMoveIn: 'Cancel Move In Confirmation',
      authorizedSignatory: 'Authorized Signatory Modal',
      addMember: 'Add Member Modal',
      editMember: 'Edit Member Modal',
      editProduct: 'Edit Product Modal',
      addFee: 'Add Extra Member Fee',
      mimoExport: 'MIMO Export Modal',
      editMoveOut: 'Edit Move Out Modal',
      editInterest: 'Edit Interest Modal',
      archiveLead: 'Archive Lead Modal',
      reopenLead: 'Reopen Lead Modal',
      confirmation: 'Confirmation',
      changePrimaryMember: 'Change Primary Member',
      toggleBillingMember: 'Toggle Billing Role',
      accountHoldFailure: 'Account Hold Failure Modal',
      accountHoldSuccess: 'Account Hold Sucess Modal',
      replaceKeycard: 'Replace Keycard Modal',
      editKeycard: 'Edit Keycard Modal',
      manualVerification: 'Manually Verify and Activate Keycard Modal',
      takePhoto: 'Take Photo Modal',
      reservation: 'Reservation Modal',
      memberNotifications: 'Member Notification Modal',
      guestRegistration: 'Guest Registration',
      memberBulkUpload: 'Member Bulk Upload',
      guestBulkUpload: 'Guest Bulk Upload',
      cancelBooking: 'Cancel Booking Reservation Modal',
      selectSfOpportunity: 'Select Salesforce Opportunity Modal',
      newOfficeHold: 'New Office Hold Modal',
      removeOfficeHolds: 'Remove Office Holds Modal',
      terms: 'Contract Terms Modal',
      customTermsOfServiceUpload: 'Custom Terms of Service Modal',
      addProduct: 'Add Product Modal',
      discount: 'Discount Modal',
    },
    table: {
      inventory: 'Inventory Table',
      companyMembers: 'Company Page Members Table',
      companyCharges: 'Company Page Charges Table',
    },
    dropdown: {
      actions: 'Actions Dropdown',
      inventory: 'Inventory Page Actions Dropdown',
      companyOffices: 'Company Offices Actions Dropdown',
    },
    memberMilestones: 'Member Milestones',
    roomsOverview: 'Rooms Overview',
    currentLocation: 'Current Location Search',
    templates: {
      emailTemplate: 'Email Templates',
      newMember: 'New member template',
      internalTransfer: 'Internal transfer template',
      externalTransferIn: 'External transfer in template',
      externalTransferOut: 'External transfer out template',
      drop: 'Drop template',
      moveOut: 'Full move out template',
      add: 'Add template',
      newMemberHd: 'New Hot Desk Member template',
      fullMoveOut: 'Full move out template',
      fullMoveOutHd: 'Full Hot Desk move out template',
      addOffice: 'Add Office template',
      dropOffice: 'Drop Office template',
    },
    topNavigation: 'Top Navigation',
    shareStoryCard: 'Share Story Card',
    events: 'Events',
    requestAction: 'Request Action',
    fileUpload: 'Upload file with input',
    analytics: 'Analytics',
    mimoTracker: 'MiMo Tracker',
    invoiceReview: 'Invoice Review',
    memberNotes: 'Person Notes Form',
    occupancyMap: {
      spaceDetail: 'Space Detail',
      appBar: 'App Bar',
      onHoldModal: 'On Hold Modal',
      officeNoteModal: 'Office Note Modal',
      filters: 'Filters',
      viewOptions: 'View Options',
      helpAndSurvey: 'Help & Survey',
      onboarding: 'Onboarding',
      mapControl: 'Map control',
      floorplan: 'Floor Plan',
    },
    eventCheckIn: 'Event check in',
    eventCalendar: {
      publishedEventMenu: 'Published event menu',
    },
    memberlist: 'Member List',
    officeNotes: {
      noteWithHold: 'Hold keyword in Office Notes',
    },
  },

  // Features
  feature: {
    announcements: {
      list: 'List Announcements',
      create: 'Create Announcement',
    },
    app: 'Application',
    appVersionUpdater: 'App Version Updater',
    performance: 'Performance',
    memberBulkUpload: 'Member Bulk Upload',
    guestBulkUpload: 'Guest Bulk Upload',
    toggle: 'Toggle View',
    pagination: 'Pagination',
    search: 'Search',
    changeProductLocation: 'Change Product/Location',
    changeHomeLocation: 'Change Home Location',
    siteNavigation: 'Site Navigation',
    breadcrumbNavigation: 'Breadcrumb Navigation',
    changeSettings: 'Change Settings',
    login: 'Login',
    logout: 'Logout',
    quickAdd: 'Quick Add',
    memberIndex: 'Member Index',
    memberMilestones: 'Member Milestones',
    tabNavigation: 'Tab Navigation',
    viewDetails: 'View Details',
    editInfo: 'Edit Info',
    inventory: 'Inventory',
    officeNotes: 'Office Notes',
    officeHolds: 'Office Holds',
    submitReferral: 'Submit a Referral',
    promotionsAndDiscounts: 'Promotions & Discounts',
    sortableHeaders: 'Sortable headers',
    filters: {
      byTag: 'Filter by tag',
      byVerificationStatus: 'Filter by Verification Status',
      byUnderage: 'Filter by Underage',
      byBuildingsAndMarkets: 'Filter by Buildings and Markets',
      byName: 'Filter by Name Search',
      byCapacity: 'Filter by capacity',
      byMembershipType: 'Filter by membership type',
      bySpecialRequest: 'Filter by special request',
      active: 'Active filter',
      location: 'Location filter',
      date: 'Date Filters',
      filters: 'Filters',
      inventoryPreset: 'Inventory Preset Filters',
      preset: 'Preset Filters',
      custom: 'Custom Filters',
    },
    companies: {
      spacemanAccount: 'Spaceman Account',
      menaProfile: 'MENA Profile',
      officesAndDesks: 'Offices and Desks',
      reservations: 'Reservations Table',
      edit: 'Edit Company',
      externalLinks: 'External Links',
      officesDesks: 'Offices & Desks',
      bookings: 'Bookings',
      editCompany: 'Edit Company',
      searchConferenceRoomReservations: 'Search conference rooms reservations',
    },
    holds: {
      accountHoldReason: 'Account Hold Reason',
    },
    paperwork: {
      send: 'Send Paperwork',
      resendContract: 'Resend Contract',
      discardContract: 'Discard Contract',
      add: 'Add Office',
      drop: 'Drop Office',
      transfer: 'Transfer Office',
      agreementModifications: 'Agreement Modifications',
      changeContract: 'Change Contract',
      serviceBundles: 'Service Bundles',
      promosAndDiscounts: 'Promos & Discounts',
      commitmentBasedOfferings: 'Commitment Based Offerings',
      discountReasons: 'Discount Reasons',
      pending: 'Pending Paperwork',
      voided: 'Voided Paperwork',
      signed: 'Signed Paperwork',
      sfOpportunities: 'Edit Salesforce Opportunity',
      selectSfOpportunity: 'Select Salesforce Opportunity',
      addons: 'Edit Add-ons',
      discountSuggestionOfferings: 'Other Term Discount Suggestion Offering',
      addProduct: 'Add Product',
      notes: 'Agreement Modification Clause',
      uploadContracts: 'Upload Paperwork',
      contractTerms: 'Contract Terms Button',
      terms: 'Contract Terms',
      customTermsOfService: 'Custom Terms of Service',
    },
    mimo: {
      modify: 'Edit MiMo',
      export: 'MIMO Export',
      sendTest: 'Send test email',
      editTemplates: 'Edit MiMo email templates',
      tabNavigation: 'Tab Navigation',
    },
    mimoTracker: {
      card: 'Card',
      list: 'List',
      drawer: 'Drawer',
      header: 'Header',
      emails: 'Email modal',
      bulkEmails: 'Bulk Emails',
    },
    invoiceReview: {
      page: 'invoice_review',
    },
    membersIndex: {
      search: 'Search Members Index',
    },

    companiesIndex: {
      search: 'Search Companies Index',
    },
    companyMgmt: {
      clickEmail: 'Click email',
    },
    memberMgmt: {
      mgmt: 'Member Management',
      add: 'Add Member',
      edit: 'Edit member',
      remove: 'Remove Member',
      confirmRemove: 'Confirm Remove Member',
      authorizedSignatory: 'Authorized Signatory',
      changePrimary: 'Change Primary Member',
      toggleBilling: 'Toggle Billing Member',
      confirmPrimary: 'Confirm Primary Member Change',
      resetPassword: 'Reset Password',
      reactivate: 'Re-activate Member',
      deactivate: 'Deactivate Member',
      fee: 'Apply Extra Member Fee',
      banMember: 'Ban member',
      clickEmail: 'Click email',
      resyncWelkio: 'Resync Welkio',
    },
    reservations: {
      add: 'Add New Reservation',
      edit: 'Edit Reservation',
      waive: 'Waive Reservation',
    },
    keycards: {
      delete: 'Delete Keycard',
      replace: 'Replace Keycard',
      edit: 'Edit Keycard',
      create: 'Create Keycard',
      manualVerification: 'Manual Verification',
      takePhoto: 'Take Photo',
    },
    cancelVerification: 'Cancel Verification',
    resetVerification: 'Reset Verification',
    notes: {
      addNote: 'Add Note',
      pinNote: 'Pin Note',
      viewAllNotes: 'View All Notes',
      pinNotes: 'Pin Notes',
      deleteNote: 'Delete Note',
      editNote: 'Edit Note',
      noteCreated: 'Note Created',
    },
    memberNotifications: {
      edit: 'Edit Member Notifications Template',
      send: 'Send Member Notification',
      history: 'View Member Notifications History',
    },
    officeOccupancy: 'Office occupancy',
    entranceInstructions: 'Entrance Instructions',
    events: {
      date: 'Event creation date',
      time: 'Event creation time',
      location: 'Event creation location',
      details: 'Event creation details',
      media: 'Event creation media',
      review: 'Event creation review',
      backToCalendar: 'View event calendar',
      seeComingFrom: 'See event that we are replanning',
      summary: 'Event summary page',
      navigation: 'Event creation navigation item',
    },
    eventCalendar: {
      changesMonth: 'Changes calendar month',
      createNewEvent: 'Create new event',
      seeEventIdeas: 'See event ideas',
      publishedEventMenu: 'Published event menu',
      replanEvent: 'Plan event again',
      draftEventMenu: 'Draft event menu',
      toggleShowWeekend: 'Toggle show weekend',
    },
    fileUpload: 'Upload file',
    memberNotes: 'Note Tags',
    memberNoteText: 'Note Text',
    occupancyMap: {
      spaceDetail: 'Space Detail',
      buildingFloorSelection: 'Building & Floor Selection',
      onHold: 'On Hold',
      officeNote: 'Office Note',
      filters: 'Filters',
      multipleSelection: 'Multiple Selection',
      viewOptions: 'View Options',
      helpAndSurvey: 'Help & Survey',
      onboarding: 'Onboarding',
      buildingInfo: 'Building Info',
      mapControl: 'Map control',
      floorplan: 'Floor Plan',
    },
    officeHoldTooltip: 'Office Hold Tooltip',
    eventCheckIn: 'Event check in',
    roomsOverview: 'Rooms Overview',
  },
};

// Generate an "event key" from the analytics properties to uniquely identify the event.
//
// Example: the Booked a Tour event might have a key like:
//
// Tour Management / Tour Scheduling / Tours Page / Booked a Tour
const getEventKeyFromProperties = (eventName: string, properties: Options) => {
  const { workflow, feature, featureContext, event } = properties;

  return compact([workflow, feature, featureContext, event || eventName]).join(' / ');
};

const isFeatureGroupKeys = properties => properties.hasOwnProperty('feature_group');

// Translate the property names
//
// Example: { workflow: 'tours', featureContext: 'page.tours' }
//
// translates to
//
// { workflow: 'Tour Management', featureContext: 'Tours Page' }
//
// based on the 'trackables' object defined below. If a key does not have a corresponding translation,
// a warning will be logged.
const getTranslatedPropertyNames = (properties: Options): Options => {
  return reduce(
    properties,
    (result: {}, lookupKey: string, category: string) => {
      // Ignore property names that aren't a key in the 'trackables' object below
      if (!trackables[category]) {
        return { ...result, [category]: lookupKey };
      }

      const translatedValue = get(trackables, `${category}.${lookupKey}`);

      if (!translatedValue) {
        // eslint-disable-next-line no-console
        console.warn(
          `ANALYTICS ERROR: No trackable constant defined for ${category}: ${lookupKey}`
        );
      }

      return {
        ...result,
        [category]: translatedValue || lookupKey,
      };
    },
    {}
  );
};

export const createAnalyticsMetadata = (event: string, properties: Options) => ({
  eventType: EventTypes.track,
  eventPayload: {
    event: EventNameTypes.interaction,
    properties: {
      ...properties,
      event,
      eventKey: getEventKeyFromProperties(event, properties),
    },
  },
});

// Action creator: track an interaction.
//
// This function will translate the properties passed into it based on the 'trackables' constant defined
// below
export const trackInteraction = (event: string, properties: Options) => {
  const translatedProperties = isFeatureGroupKeys(properties)
    ? { tool: 'spacestation', platform: 'web', ...properties }
    : getTranslatedPropertyNames(properties);

  return {
    type: INTERACTION,
    meta: {
      analytics: createAnalyticsMetadata(event, translatedProperties),
    },
  };
};

export default reducer;
