import React from 'react';
import ReactDOM from 'react-dom';

type Props = { children: React.ReactNode };

const PORTAL_ID = 'modal-portal';

const ModalPortal: React.FC<Props> = ({ children }: Props) => {
  const el = document.getElementById(PORTAL_ID);

  if (!el) {
    return <>{children}</>;
  }

  return ReactDOM.createPortal(children, el);
};

export default ModalPortal;
