export const deepFreeze = <T>(mutableObject: T): Readonly<T> => {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(mutableObject);

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = mutableObject[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(mutableObject);
};
