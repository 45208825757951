import { handleActions } from 'redux-actions';

import { createRequestConstantNames } from 'store/util/createConstants';
import createReduxConstant from 'store/util/createReduxConstant';
import { BaseAction, BaseState } from 'store/types';
import { Account, ShowAccountResponse } from 'store/modules/accounts/types';

// Action Constants
export const [
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAIL,
] = createRequestConstantNames(createReduxConstant('FETCH_ACCOUNT'));

export interface State extends BaseState {
  byUuid: Hash<Account>;
}

export interface AccountsSubset {
  accounts: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  byUuid: {},
};

export interface ActionPayload extends BaseAction {
  payload: ShowAccountResponse;
  meta: {
    uuid: string;
  };
}

export interface ActionMeta {
  uuid: string;
}

// Reducer
export const reducer = handleActions<State, ShowAccountResponse, ActionMeta>(
  {
    [FETCH_ACCOUNT]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_ACCOUNT_SUCCESS]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: true,
      error: action.error,
      byUuid: {
        ...state.byUuid,
        [action.meta.uuid]: action.payload,
      },
      data: action.payload,
    }),
    [FETCH_ACCOUNT_FAIL]: (state: State, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error || true,
    }),
  },
  initialState
);

export default reducer;
