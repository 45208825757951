import { handleActions } from 'redux-actions';

import {
  FETCH_PRIMARY_RESERVATIONS,
  FETCH_PRIMARY_RESERVATIONS_SUCCESS,
  FETCH_PRIMARY_RESERVATIONS_FAIL,
  CANCEL_MODIFICATION_REQUEST_SUCCESS,
  CANCEL_MODIFICATION_REQUEST_FAIL,
  FETCH_KUBE_RESERVATIONS,
  FETCH_KUBE_RESERVATIONS_SUCCESS,
  FETCH_KUBE_RESERVATIONS_FAIL,
} from 'features/companies/redux/primaryReservations/constants';
import {
  State,
  PayloadAction,
  PrimaryReservation,
} from 'features/companies/redux/primaryReservations/types';

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byCompanyUuid: {},
  kubeReservationsByCompanyUuid: {},
  error: null,
  errorData: null,
};

export const reducer = handleActions<State, any, { companyUuid: string }>(
  {
    [FETCH_PRIMARY_RESERVATIONS]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_PRIMARY_RESERVATIONS_SUCCESS]: (state: State, action: PayloadAction): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byCompanyUuid: {
        ...state.byCompanyUuid,
        [action.meta.companyUuid]: action.payload.reservations,
      },
    }),

    [FETCH_PRIMARY_RESERVATIONS_FAIL]: (state: State, action): State => ({
      ...state,
      loading: false,
      loaded: false,
      errorData: action.payload,
      error: action.error,
    }),

    [FETCH_KUBE_RESERVATIONS]: (state: State): State => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_KUBE_RESERVATIONS_SUCCESS]: (state: State, action: PayloadAction): State => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      kubeReservationsByCompanyUuid: {
        ...state.kubeReservationsByCompanyUuid,
        [action.meta.companyUuid]:
          action?.payload?.data?.primaryReservations.filter(
            ({ systemOfRecord }) => systemOfRecord === 'kube'
          ) || [],
      },
    }),

    [FETCH_KUBE_RESERVATIONS_FAIL]: (state: State, action): State => ({
      ...state,
      loading: false,
      loaded: false,
      errorData: action.payload,
      error: action.error,
    }),

    [CANCEL_MODIFICATION_REQUEST_FAIL]: (state: State, action): State => ({
      ...state,
      errorData: action.payload,
    }),

    [CANCEL_MODIFICATION_REQUEST_SUCCESS]: (state: State, action): State => {
      const { uuid, reservation_uuid, account_uuid } = action.payload.result;
      const reservations = state.byCompanyUuid[account_uuid].reduce(
        (reservations: Array<PrimaryReservation>, current: PrimaryReservation) => {
          if (current.code === reservation_uuid) {
            const newRequests =
              current.modification_requests?.filter(req => req.uuid !== uuid) ?? [];

            current.modification_requests = newRequests;
          }

          reservations.push(current);

          return reservations;
        },
        []
      );

      return {
        ...state,
        byCompanyUuid: {
          ...state.byCompanyUuid,
          [account_uuid]: reservations,
        },
      };
    },
  },
  initialState
);

export default reducer;
