import config from 'config';

import { Filters, ContactSearchResultItem } from '../types';

import { FiledValueFilter, Mapper } from './types';

const CONTACT_TYPE = 'potential';

const mapper = (_filters: Filters): Array<FiledValueFilter> => []; // No filters

interface AlgoliaItem {
  uuid: string;
  objectID: string;
  company_name: string;
  contact_name: string;
  contact_uuid: string;
  email: string;
  salesforce_id?: string;
}

const itemMapper = (algoliaItem: AlgoliaItem): ContactSearchResultItem => ({
  type: CONTACT_TYPE,
  uuid: algoliaItem.contact_uuid,
  objectID: algoliaItem.objectID,
  company_name: algoliaItem.company_name,
  contact_name: algoliaItem.contact_name,
  contact_uuid: algoliaItem.contact_uuid,
  email: algoliaItem.email,
  salesforce_id: algoliaItem.salesforce_id,
});

export default {
  index: config.algolia.indices.contactInfo,
  mapper,
  itemMapper,
} as Mapper;
