import React from 'react';
import cn from 'classnames';

import style from './index.scss';

type Props = Readonly<{
  children: React.ReactNode;
  scroll?: boolean;
}>;

const ContentArea = ({ scroll = false, children }: Props) => {
  const tagStyles = cn({
    [style.base]: true,
    [style.scroll]: scroll,
  });

  return <section className={tagStyles}>{children}</section>;
};

export default ContentArea;
