import React from 'react';
import cn from 'classnames';
import { escapeRegExp } from 'lodash';

import styles from './index.scss';

type Props = Readonly<{
  text: string;
  query: string | null | undefined;
  highlightedClassName?: string;
}>;

const HighlightedText = ({ text, query, highlightedClassName }: Props) => {
  if (!query) {
    return <span>{text}</span>;
  }

  const match = text.match(new RegExp(escapeRegExp(query), 'i'));

  if (!match) {
    return <span>{text}</span>;
  }

  const startIdx = match.index;
  const finishIdx = (match.index ?? 0) + match[0].length;

  const first = text.slice(0, startIdx);
  const matchSegment = text.slice(startIdx, finishIdx);
  const last = text.slice(finishIdx, text.length);

  return (
    <span>
      {first}
      <span className={cn(styles.highlight, highlightedClassName)}>{matchSegment}</span>
      {last}
    </span>
  );
};

export default HighlightedText;
