import * as queryString from 'querystring';

import { handleActions } from 'redux-actions';

import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';
import config from 'config';
import { BaseAction, BaseState, RSAAType } from 'store/types';
import { ListingProduct } from 'features/alchemist/types';

// Action Constants
export const FETCH_LISTING_PRODUCTS_BY_PRODUCT = cc('FETCH_LISTING_PRODUCTS_BY_PRODUCT');
export const FETCH_LISTING_PRODUCTS_BY_PRODUCT_SUCCESS = cc(
  'FETCH_LISTING_PRODUCTS_BY_PRODUCT_SUCCESS'
);
export const FETCH_LISTING_PRODUCTS_BY_PRODUCT_FAIL = cc('FETCH_LISTING_PRODUCTS_BY_PRODUCT_FAIL');

export interface State extends BaseState {
  byProductUuid: Hash<Array<ListingProduct>>;
}

export interface ListingProductsByProductSubset {
  listingProductsByProduct: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  loaded: false,
  byProductUuid: {},
  error: null,
};

interface ActionPayload extends BaseAction {
  payload?: Array<ListingProduct>;
  meta: { productUuid };
}

interface ActionMeta {
  productUuid: string;
}

export const parseListingProductPrice = (listingProduct): ListingProduct => ({
  ...listingProduct,
  price: {
    ...listingProduct.price,
    price: parseFloat(listingProduct.price.price),
  },
});

// Reducer
export const reducer = handleActions<State, Array<ListingProduct>, ActionMeta>(
  {
    [FETCH_LISTING_PRODUCTS_BY_PRODUCT]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [FETCH_LISTING_PRODUCTS_BY_PRODUCT_SUCCESS]: (state, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: true,
      byProductUuid: {
        ...state.byProductUuid,
        [action.meta.productUuid]: action.payload
          ? action.payload.map(parseListingProductPrice)
          : [],
      },
      data: action.payload,
      error: action.error,
    }),
    [FETCH_LISTING_PRODUCTS_BY_PRODUCT_FAIL]: (state, action: ActionPayload) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error || true,
    }),
  },
  initialState
);

export const fetchListingProductsByProduct = (productUuid: string, addFees = false): RSAAType =>
  createRequestAction({
    endpoint: `${
      config.productService.uri
    }/v2/listings/products/${productUuid}/buildings?${queryString.stringify({
      addFees,
    })}`,
    types: [
      FETCH_LISTING_PRODUCTS_BY_PRODUCT,
      {
        type: FETCH_LISTING_PRODUCTS_BY_PRODUCT_SUCCESS,
        meta: { productUuid },
      },
      FETCH_LISTING_PRODUCTS_BY_PRODUCT_FAIL,
    ],
  });

export default reducer;
