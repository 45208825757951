import { Status } from 'store/modules/search/searchService/types';

export const filters = [
  {
    name: 'currentLocation',
    label: 'Only current location',
    default: true,
    data: { test: 'only-current-location' },
  },
  {
    name: 'activeMembers',
    label: 'Active',
    dotEnabledStatus: Status.ACTIVE,
    default: true,
  },
  {
    name: 'inactiveMembers',
    label: 'Past',
    dotEnabledStatus: Status.INACTIVE, // past member is inactive
    default: false,
  },
];
