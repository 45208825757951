import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_COMMITMENT_ITEMS_BY_LOCATION,
  FETCH_COMMITMENT_ITEMS_BY_LOCATION_SUCCESS,
  FETCH_COMMITMENT_ITEMS_BY_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_COMMITMENT_ITEMS_BY_LOCATION'));

export const [
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION,
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION_SUCCESS,
  FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_ALL_COMMITMENT_ITEMS_BY_LOCATION'));
