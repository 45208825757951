import { SearchResponse } from '@algolia/client-search';

import { assertIsDefined } from 'lib/assert';

import {
  EntityType,
  SearchRequest,
  AdapterResult,
  SearchResultGroup,
  SearchResultItem,
} from '../types';
import { Adapter } from '../searchService';

import { AlgoliaClient } from './algoliaClient';
import { Mappers } from './types';
import algoliaQueryBuilder from './algoliaQueryBuilder';
import memberMapper from './algoliaMemberMapper';
import companyMapper from './algoliaCompanyMapper';
import contactMapper from './algoliaContactMapper';
import buildingMapper from './algoliaBuildingMapper';

export const getIndicesMap = (mappers: Mappers): Hash<EntityType> => {
  const entries = Object.entries(mappers).map(([key, mapper]): [string, EntityType] => [
    mapper!.index,
    key as EntityType,
  ]);

  return Object.fromEntries(entries);
};

const isFacetResponse = (response: SearchResponse): boolean =>
  response && response.hitsPerPage === 0;

export default class AlgoliaAdapter implements Adapter {
  client: AlgoliaClient;
  mappers: Mappers;
  indexToEntityTypeMap: Hash<EntityType>;

  constructor(client: AlgoliaClient) {
    this.client = client;
    this.mappers = {
      [EntityType.MEMBER]: memberMapper,
      [EntityType.COMPANY]: companyMapper,
      [EntityType.CONTACT]: contactMapper,
      [EntityType.BUILDING]: buildingMapper,
    };

    this.indexToEntityTypeMap = getIndicesMap(this.mappers);
  }

  async search(request: SearchRequest): Promise<AdapterResult> {
    const response = await this.client.search<SearchResultItem>(
      algoliaQueryBuilder(this.mappers, request)
    );

    return response.results.reduce(
      (
        acc: Partial<Record<EntityType, SearchResultGroup<SearchResultItem>>>,
        result: SearchResponse
      ) => {
        if (result.index) {
          const entity: EntityType = this.indexToEntityTypeMap[result.index];

          const mapper = this.mappers[entity];

          assertIsDefined(mapper);

          const entityResult = acc[entity];
          if (request.facets && entityResult && isFacetResponse(result)) {
            entityResult.facets = { ...entityResult.facets, ...result.facets };
          } else {
            acc[entity] = {
              items: result.hits.map(mapper.itemMapper),
              facets: result.facets,
              totalCount: result.nbHits,
            };
          }
        }

        return acc;
      },
      {}
    );
  }
}
