import { createAction, handleActions, ReduxCompatibleReducer } from 'redux-actions';

import cc from 'store/util/createReduxConstant';
import { GlobalState } from 'store/modules';
import { Notification } from 'lib/api/notify/types';
import { NotificationAction } from 'features/notifications/ducks/types';

const MAX_NOTIFICATION_SIZE = 20;

export interface State {
  currentPage: number;
  error: boolean;
  hasMore: boolean;
  loading: boolean;
  messages: Notification[];
  recentNotification?: Notification;
}

export interface NotificationsSubset {
  notifications: State;
}

const combineNotifications = (
  // existing notifications "most recent" in the redux state
  currentNotifications: Notification[],

  // old in the sense that the notification's created_at is dated further back
  olderNotifications: Notification[]
) => [
  ...new Map(
    currentNotifications
      .concat(olderNotifications)
      .map(notification => [notification.uuid, notification])
  ).values(),
];

// action
export const FETCH_NOTIFICATIONS = cc('FETCH_NOTIFICATIONS');
export const FETCH_NOTIFICATIONS_SUCCESS = cc('FETCH_NOTIFICATIONS_SUCCESS');
export const FETCH_NOTIFICATIONS_FAILURE = cc('FETCH_NOTIFICATIONS_FAILURE');
export const CREATE_NEW_NOTIFICATION_ALERT = cc('CREATE_NEW_NOTIFICATION_ALERT');
export const DISMISS_CURRENT_NOTIFICATION = cc('DISMISS_CURRENT_NOTIFICATION');
export const MARK_NOTIFICATION_AS_READ = cc('MARK_NOTIFICATION_AS_READ');

// initial state
export const initialState = {
  currentPage: 0,
  error: false,
  hasMore: true,
  loading: false,
  messages: [],
  recentNotification: undefined,
};

// reducer
export const reducer: ReduxCompatibleReducer<State, NotificationAction> = handleActions<State, any>(
  {
    [CREATE_NEW_NOTIFICATION_ALERT]: (state, action) => ({
      ...state,
      recentNotification: action.payload,
      // recent notification goes first
      messages: [action.payload, ...state.messages],
    }),

    [DISMISS_CURRENT_NOTIFICATION]: state => ({
      ...state,
      recentNotification: undefined,
    }),

    [FETCH_NOTIFICATIONS]: state => ({
      ...state,
      error: false,
      loading: true,
    }),

    [FETCH_NOTIFICATIONS_SUCCESS]: (state, action) => ({
      ...state,
      currentPage: action.payload.page,
      error: false,
      hasMore: action.payload.messages.length === MAX_NOTIFICATION_SIZE,
      loading: false,
      // get unique set by uuid
      messages: combineNotifications(state.messages, action.payload.messages),
    }),

    [FETCH_NOTIFICATIONS_FAILURE]: state => ({
      ...state,
      error: true,
      loading: false,
    }),

    [MARK_NOTIFICATION_AS_READ]: (state, action) => ({
      ...state,
      messages: combineNotifications(state.messages, action.payload.messages),
    }),
  },
  initialState
);

// action creator
export const dismissCurrentNotification = createAction(DISMISS_CURRENT_NOTIFICATION);

export const fetchNotificationsAction = createAction(FETCH_NOTIFICATIONS);

export const fetchNotificationsSuccessAction = createAction(
  FETCH_NOTIFICATIONS_SUCCESS,
  (notifications: Notification[], page: number) => ({ messages: notifications, page })
);

export const fetchNotificationsFailureAction = createAction(
  FETCH_NOTIFICATIONS_FAILURE,
  () => {},
  (err: string) => ({
    notification: {
      message: err,
      type: 'error',
    },
  })
);

export const markNotificationAsReadAction = createAction(
  MARK_NOTIFICATION_AS_READ,
  (notification: Notification) => ({ messages: [notification] })
);

// selectors
export const getNotificationCurrentPage = (state: GlobalState) => state.notifications.currentPage;
export const getNotificationMessages = (state: GlobalState) => state.notifications.messages;
export const getHasMoreNotifications = (state: GlobalState) => state.notifications.hasMore;
export const getNotificationsError = (state: GlobalState) => state.notifications.error;
export const getIsNotificationsLoading = (state: GlobalState) => state.notifications.loading;
export const getRecentNotification = (state: GlobalState) => state.notifications.recentNotification;

export default reducer;
