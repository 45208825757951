export enum SuggestionCode {
  MONTH_TO_MONTH = 'MONTH_TO_MONTH',
  SIX_MONTH_SUGGESTED = '6_MONTH_SUGGESTED',
  TWELVE_MONTH_SUGGESTED = '12_MONTH_SUGGESTED',
  TWENTY_FOURTH_MONTH_SUGGESTED = '24_MONTH_SUGGESTED',
  CUSTOM_MONTH_SUGGESTED = 'CUSTOM_MONTH_SUGGESTED',
}

export enum ScheduleUnit {
  MONTH = 'MONTH',
}

export enum SuggestionType {
  PERCENT = 'percent',
}

export enum SuggestionVariant {
  DEFAULT = 'default',
  DEFAULT_OVERRIDE = 'default',
  FREE_MONTHS = 'default_override',
  FREE_MONTHS_OVERRIDE = 'free_months_override',
  DESCENDING = 'descending',
  DESCENDING_OVERRIDE = 'descending_override',
}

export type SuggestionPayload = {
  average_discount: string;
  code: SuggestionCode;
  commitment_length: number;
  currency: string | null;
  marketing_name: string;
  max_discount: string;
  max_discount_error: string;
  perpetuating: boolean;
  schedule: Array<string>;
  schedule_unit: ScheduleUnit;
  type: SuggestionType;
  uuid: string;
  variant: SuggestionVariant;
};

export type Suggestion = {
  averageDiscount: string;
  code: SuggestionCode;
  commitmentLength: number;
  currency: string | null;
  marketingName: string;
  maxDiscount: string;
  maxDiscountError: string;
  perpetuating: boolean;
  schedule: Array<string>;
  scheduleUnit: ScheduleUnit;
  type: SuggestionType;
  uuid: string;
  variant: SuggestionVariant;
};

export const toSuggestion = (suggestionPayload: SuggestionPayload): Suggestion => ({
  averageDiscount: suggestionPayload.average_discount,
  code: suggestionPayload.code,
  commitmentLength: suggestionPayload.commitment_length,
  currency: suggestionPayload.currency,
  marketingName: suggestionPayload.marketing_name,
  maxDiscount: suggestionPayload.max_discount,
  maxDiscountError: suggestionPayload.max_discount_error,
  perpetuating: suggestionPayload.perpetuating,
  schedule: suggestionPayload.schedule,
  scheduleUnit: suggestionPayload.schedule_unit,
  type: suggestionPayload.type,
  uuid: suggestionPayload.uuid,
  variant: suggestionPayload.variant,
});
