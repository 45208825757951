import { BaseAction, Dispatch } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import cc from 'store/util/createReduxConstant';
import { Amenity, AmenityPayload } from 'features/building/types';
import { getContentfulUri } from 'features/building/ducks/contentfulUriUtil';
import createRequestAction from 'store/util/createRequestAction';
import config from 'config';

import { getAmenitiesByID } from '../serializers/amenitiesSerializer';

// Action Constants
export const FETCH_AMENITIES = cc('FETCH_AMENITIES');
export const FETCH_AMENITIES_SUCCESS = cc('FETCH_AMENITIES_SUCCESS');
export const FETCH_AMENITIES_FAIL = cc('FETCH_AMENITIES_FAIL');

export const fetchAmenities = () => (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'GET',
    endpoint: getContentfulUri(false, 'amenity', null),
    headers: {
      Authorization: `Bearer ${config.contentful.cdn_token}`,
    },
    types: [
      { type: FETCH_AMENITIES },
      { type: FETCH_AMENITIES_SUCCESS },
      { type: FETCH_AMENITIES_FAIL },
    ],
  });

  return dispatch(requestAction);
};

const reducer = createRequestReducer<{ [key: string]: Amenity } | null, AmenityPayload | undefined>(
  [FETCH_AMENITIES, FETCH_AMENITIES_SUCCESS, FETCH_AMENITIES_FAIL],
  null,
  res => getAmenitiesByID(res.payload)
);

export default reducer;
