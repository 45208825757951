import queryString from 'query-string';
import { pick } from 'lodash';
import { createAction } from 'redux-actions';
import { OperatorServiceErrorResponse } from '@wework/we-auth-react';

import { BaseAction, Dispatch } from 'store/types';
import { createRequestAction } from 'store/util';
import { snakeCaseJson, camelCaseJson } from 'lib/util';
import config from 'config';
import { ToastType } from 'store/modules/toasts/constants';

// Action Constants
import {
  FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME,
  FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_SUCCESS,
  FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_FAIL,
  FETCH_EMPLOYEES_BY_ROLE,
  FETCH_EMPLOYEES_BY_ROLE_SUCCESS,
  FETCH_EMPLOYEES_BY_ROLE_FAIL,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  FETCH_EMPLOYEES_BY_ROLE_PAGE_SIZE,
} from './constants';
import { EmployeeCreation, EmployeeUpdate, OpSrvType } from './types';

export const getOperatorServiceErrorMessage = (_, json: OperatorServiceErrorResponse) => {
  if (json.fieldErrors) {
    return json.fieldErrors.reduce(
      (acc, error) => ` ${acc}, ${error.field} ${error.message}`,
      json.message
    );
  }
  return json.message;
};

export const serializeParams = (employee: EmployeeCreation | EmployeeUpdate) => {
  let firstName = employee.firstName;
  let lastName = employee.lastName;

  if (employee.name) {
    // TODO (MichaelKatz): This regex temporary fixes issues with members who are having more than 1 whitespace between their names
    // This does not address people with middle names who'll have it as their last name if created as employees.
    [firstName, lastName] = employee.name.trim().split(/\s+/);
  }

  const paramFields = [
    'uuid',
    'email',
    'userUuid',
    'locationUuid',
    'roleNames',
    'schema',
    'requestLink',
    'sodConfirmation',
  ];

  return snakeCaseJson({
    ...pick(employee, paramFields),
    firstName,
    lastName,
  });
};

const fetchEmployeesByRoleAction = createAction(FETCH_EMPLOYEES_BY_ROLE);

const fetchEmployeesByRoleSuccessAction = createAction(
  FETCH_EMPLOYEES_BY_ROLE_SUCCESS,
  allEmployeesByRole => allEmployeesByRole,
  (_, role: string) => ({
    role,
  })
);

const fetchEmployeesByRoleFailureAction = createAction(
  FETCH_EMPLOYEES_BY_ROLE_FAIL,
  () => {},
  (err: string) => ({
    notification: {
      message: err,
      type: ToastType.ERROR,
    },
  })
);

const fetchEmployeesByRolePageSize = (page: number, role: string, size: number) => {
  const query = queryString.stringify({
    page,
    size,
  });

  return createRequestAction<OpSrvType>({
    method: 'GET',
    endpoint: `${config.operatorService.uri}/v1/roles/${role}/employees?${query}`,
    meta: { role },
    types: [
      { type: FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME },
      { type: FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_SUCCESS },
      { type: FETCH_EMPLOYEES_BY_ROLE_INTERNAL_NAME_FAIL },
    ],
  });
};

/**
 * Combines all the pages of a paginated response into a single value
 */
const getAllPages = async (fetch, dispatch, role: string, size: number) => {
  const firstPage = await dispatch(fetch(0, role, size));
  const results = firstPage.payload.content;

  const promises: Promise<{ payload: OpSrvType }>[] = [];
  const totalPages = firstPage.payload.totalPages;
  for (let i = 1; i < totalPages; i++) {
    promises.push(dispatch(fetch(i, role, size)));
  }

  const payloads = await Promise.all(promises);
  return payloads.reduce((acc, payload) => acc.concat(payload.payload.content), results);
};

export const fetchEmployeesByRole = (
  role: string,
  size: number = FETCH_EMPLOYEES_BY_ROLE_PAGE_SIZE
) => async (dispatch: Dispatch<BaseAction>) => {
  dispatch(fetchEmployeesByRoleAction);

  try {
    const allEmployeesByRole = await getAllPages(
      fetchEmployeesByRolePageSize,
      dispatch,
      role,
      size
    );
    return dispatch(fetchEmployeesByRoleSuccessAction(allEmployeesByRole, role));
  } catch (err) {
    dispatch(fetchEmployeesByRoleFailureAction(err));
  }
};

export const updateEmployeeLocation = (employeeUuid: string, locationUuid: string) =>
  createRequestAction({
    endpoint: `${config.operatorService.uri}/v1/employees/${employeeUuid}/location`,
    method: 'PUT',
    types: [UPDATE_EMPLOYEE, UPDATE_EMPLOYEE_SUCCESS, UPDATE_EMPLOYEE_FAIL],
    body: {
      location_uuid: locationUuid,
    },
    getErrorMessageFromResponse: getOperatorServiceErrorMessage,
  });

export const getEmployee = (employeeUuid: string) =>
  createRequestAction({
    endpoint: `${config.operatorService.uri}/v1/employees/${employeeUuid}`,
    method: 'GET',
    types: [GET_EMPLOYEE, GET_EMPLOYEE_SUCCESS, GET_EMPLOYEE_FAIL],
    getPayloadFromResponse: camelCaseJson,
    getErrorMessageFromResponse: getOperatorServiceErrorMessage,
  });
