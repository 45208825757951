import { combineReducers } from 'redux';
import { handleActions, createAction } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { createRequestAction } from 'store/util';
import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';
import products, {
  State as ProductsReservationState,
} from 'features/paperwork/contracts-flow/sections/product/ducks';
import {
  CLEAR_PRODUCTS_RESERVATION,
  CHANGE_EXPIRATION_TIME,
} from 'features/paperwork/contracts-flow/sections/product/ducks/consts';
import opportunityReducer, {
  OpportunityState,
} from 'features/paperwork/contracts-flow/sections/opportunity/duck';
import notesReducer from 'features/paperwork/contracts-flow/sections/notes/duck';
import serviceCloudNumberReducer, {
  ServiceCloudNumberState,
} from 'features/paperwork/contracts-flow/sections/serviceCloudNumber/duck';
import {
  OutOfPolicyAcknowledgement,
  ProductReservationUpdate,
  toProductReservationPayload,
} from 'features/paperwork/contracts-flow/types';
import { SourceAppName } from 'features/paperwork/constants';
import { SalesforceOpportunity } from 'features/paperwork/types';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';

import { NEW_TERM_ID } from '../sections/product/ducks/commitmentTerm';

import {
  getRenewalReservations,
  getRenewalDiscounts,
  getRenewalTerms,
  getOutOfPolicyDiscounts,
} from './utils';

export interface ActionsState {
  updated: boolean;
  loading: boolean;
  error: string | null;
}

export interface ConfigurationsState {
  hoursUntilExpiration: number;
}

export interface State {
  products: ProductsReservationState;
  actions: ActionsState;
  configurations: ConfigurationsState;
  opportunity: OpportunityState;
  notes: string;
  serviceCloudNumber: ServiceCloudNumberState;
}

export const [
  UPDATE_PRODUCTS_RESERVATION,
  UPDATE_PRODUCTS_RESERVATION_SUCCESS,
  UPDATE_PRODUCTS_RESERVATION_FAIL,
] = createRequestConstantNames(cc('UPDATE_PRODUCTS_RESERVATION'));

export const initialState: ActionsState = {
  loading: false,
  updated: false,
  error: null,
};

const actionsReducer = handleActions<ActionsState, any>(
  {
    [UPDATE_PRODUCTS_RESERVATION]: (state: ActionsState) => ({
      ...state,
      loading: true,
      updated: false,
    }),
    [UPDATE_PRODUCTS_RESERVATION_SUCCESS]: (state: ActionsState) => ({
      ...state,
      loading: false,
      updated: true,
      error: null,
    }),
    [UPDATE_PRODUCTS_RESERVATION_FAIL]: (
      state: ActionsState,
      action: { payload: { message: string } }
    ) => ({
      ...state,
      loading: false,
      updated: false,
      error: action.payload.message,
    }),
    [CLEAR_PRODUCTS_RESERVATION]: () => initialState,
  },
  initialState
);

export const configurationsInitialState: ConfigurationsState = {
  hoursUntilExpiration: 24,
};

const configurationsReducer = handleActions<ConfigurationsState, any>(
  {
    [CHANGE_EXPIRATION_TIME]: (
      state: ConfigurationsState,
      action: { payload: { hoursUntilExpiration: number } }
    ) => ({
      ...state,
      hoursUntilExpiration: action.payload.hoursUntilExpiration,
    }),
  },
  configurationsInitialState
);

export const changeExpirationTime = createAction(
  CHANGE_EXPIRATION_TIME,
  (hoursUntilExpiration: number): { hoursUntilExpiration: number } => ({ hoursUntilExpiration })
);

export const buildProductsReservationUpdatesPayload = (
  productReservations: Array<ProductReservationUpdate>
) =>
  productReservations.map(productReservation => ({
    location_uuid: productReservation.locationUuid,
    product_reservation: {
      type: productReservation.type,
      reservation_uuid: productReservation.uuid,
      ...(productReservation.changes && toProductReservationPayload(productReservation.changes)),
    },
    discounts: productReservation.discounts?.map(discount =>
      discount.term_id !== NEW_TERM_ID
        ? {
            ...discount,
            term_id: null,
          }
        : discount
    ),
    terms: productReservation.terms?.map(term =>
      term.id === NEW_TERM_ID
        ? {
            ...term,
            id: null,
          }
        : term
    ),
  }));

export interface updateProductReservationsType {
  productsReservationChanges: Array<ProductReservationUpdate>;
  sfOpportunity: {
    id: string;
    waitForSfSync: boolean;
  };
  manualAgreementUpload: {
    url?: string;
    signedAt?: string;
  };
  serviceCloudNumber: string;
}

export const updateProductReservations = ({
  allProductReservationChanges,
  companyUuid,
}: {
  allProductReservationChanges: updateProductReservationsType;
  companyUuid: string;
}) =>
  createRequestAction({
    endpoint: `${config.spaceman.uri}/api/product_line_items/amendment`,
    method: 'POST',
    body: {
      account_uuid: companyUuid,
      source_app_name: SourceAppName.SPACESTATION,
      products: buildProductsReservationUpdatesPayload(
        allProductReservationChanges.productsReservationChanges
      ),
      signed_pdf_url: allProductReservationChanges.manualAgreementUpload.url,
      signed_at: allProductReservationChanges.manualAgreementUpload.signedAt,
      service_cloud_number: allProductReservationChanges.serviceCloudNumber,
    },
    types: [
      UPDATE_PRODUCTS_RESERVATION,
      UPDATE_PRODUCTS_RESERVATION_SUCCESS,
      UPDATE_PRODUCTS_RESERVATION_FAIL,
    ],
  });

type AgreementProps = {
  allProductReservationChanges: updateProductReservationsType;
  companyUuid: string;
  accountAdminUuid: string;
  sfOpportunity: SalesforceOpportunity;
  hoursUntilExpiration: number;
  outOfPolicyAcknowledgements: OutOfPolicyAcknowledgement;
  isPromotionDiscountPerpetuating: boolean;
  locationUuid: string;
  startDate: string;
  totalSetupFeeCost: number;
  agreementNotes?: string;
};

const buildPayload = (
  allProductReservationChanges: updateProductReservationsType,
  companyUuid: string,
  accountAdminUuid: string,
  sfOpportunity: SalesforceOpportunity,
  hoursUntilExpiration: number,
  outOfPolicyAcknowledgements: OutOfPolicyAcknowledgement,
  isPromotionDiscountPerpetuating: boolean,
  locationUuid: string,
  startDate: string,
  totalSetupFeeCost: number,
  agreementNotes?: string
) => {
  const discounts = getRenewalDiscounts(
    allProductReservationChanges.productsReservationChanges,
    isPromotionDiscountPerpetuating
  );
  return {
    source_app_name: SourceAppName.SPACESTATION,
    account_uuid: companyUuid,
    account_admin_uuid: accountAdminUuid,
    discounts,
    expiration_period: hoursUntilExpiration,
    reservations: getRenewalReservations(
      allProductReservationChanges.productsReservationChanges,
      outOfPolicyAcknowledgements,
      sfOpportunity?.id,
      agreementNotes
    ),
    signed_pdf_url: allProductReservationChanges.manualAgreementUpload.url,
    sign_date: allProductReservationChanges.manualAgreementUpload.signedAt,
    service_cloud_number: allProductReservationChanges.serviceCloudNumber,
    self_executing: false,
    membership_agreement: {
      account_uuid: companyUuid,
      account_admin_uuid: accountAdminUuid,
    },
    terms: getRenewalTerms(allProductReservationChanges.productsReservationChanges),
    setup_fees: [
      {
        amount: totalSetupFeeCost,
        location_uuid: locationUuid,
        started_on: startDate,
      },
    ],
    out_of_policy_discount: {
      approver: {
        email: outOfPolicyAcknowledgements.approver,
      },
      rationale: outOfPolicyAcknowledgements.rationale,
      reservable_discounts: getOutOfPolicyDiscounts(
        allProductReservationChanges.productsReservationChanges,
        discounts
      ),
    },
    is_draft: !!outOfPolicyAcknowledgements.approver,
  };
};

export const sendRenewal = ({
  allProductReservationChanges,
  companyUuid,
  accountAdminUuid,
  sfOpportunity,
  hoursUntilExpiration,
  outOfPolicyAcknowledgements,
  isPromotionDiscountPerpetuating,
  locationUuid,
  startDate,
  totalSetupFeeCost,
  agreementNotes,
}: AgreementProps) => async dispatch => {
  const accessToken = await getAccessToken();
  const payload = buildPayload(
    allProductReservationChanges,
    companyUuid,
    accountAdminUuid,
    sfOpportunity,
    hoursUntilExpiration,
    outOfPolicyAcknowledgements,
    isPromotionDiscountPerpetuating,
    locationUuid,
    startDate,
    totalSetupFeeCost,
    agreementNotes
  );

  try {
    if (outOfPolicyAcknowledgements.approver) {
      await fetch(`${config.spaceman.uri}/api/v2/membership_agreements/send_for_approval`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `Token token=${accessToken}`,
          'x-request-id': uuidv4(),
        },
        body: JSON.stringify(payload),
      });
    }

    dispatch(
      createRequestAction({
        endpoint: `${config.spaceman.uri}/api/v2/membership_agreements${
          allProductReservationChanges.manualAgreementUpload.url ? '/create_and_move_in' : ''
        }`,
        method: 'POST',
        body: payload,
        types: [
          UPDATE_PRODUCTS_RESERVATION,
          UPDATE_PRODUCTS_RESERVATION_SUCCESS,
          UPDATE_PRODUCTS_RESERVATION_FAIL,
        ],
        getErrorMessageFromResponse: (_res, jsonRes) => jsonRes.result.error,
      })
    );
  } catch (message) {
    dispatch({ type: UPDATE_PRODUCTS_RESERVATION_FAIL, payload: { message } });
  }
};

export default combineReducers<State>({
  products,
  actions: actionsReducer,
  configurations: configurationsReducer,
  opportunity: opportunityReducer,
  notes: notesReducer,
  serviceCloudNumber: serviceCloudNumberReducer,
});
