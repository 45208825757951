import { handleActions } from 'redux-actions';
import { filter } from 'lodash';
import { SearchResponse } from '@algolia/client-search';

import { createRequestAction } from 'store/util';
import config from 'config';
import Members from 'entities/members';
import { BaseAction, Dispatch, ActionWithPayload } from 'store/types';
import cc from 'store/util/createReduxConstant';
import { FilterType } from 'features/locations/buildingDirectory/types';
import { MenaCompanyData } from 'features/companies/redux/menaCompanies/types';
import { AccountUser } from 'store/modules/accounts/types';

import { AlgoliaMember } from './search/searchService/types';

// Action Constants
export const FETCH_MEMBER_BASIC_INFO = cc('FETCH_MEMBER_BASIC_INFO');
export const FETCH_MEMBER_BASIC_INFO_SUCCESS = cc('FETCH_MEMBER_BASIC_INFO_SUCCESS');
export const FETCH_MEMBER_BASIC_INFO_FAIL = cc('FETCH_MEMBER_BASIC_INFO_FAIL');

export const CLEAR_SELECTED_MEMBER = cc('CLEAR_SELECTED_MEMBER');
export const SET_NEW_MEMBER = cc('SET_NEW_MEMBER');
export const SET_SELECTED_MEMBER = cc('SET_SELECTED_MEMBER');

export const RESYNC_WELKIO = cc('RESYNC_WELKIO');
export const RESYNC_WELKIO_SUCCESS = cc('RESYNC_WELKIO_SUCCESS');
export const RESYNC_WELKIO_FAIL = cc('RESYNC_WELKIO_FAIL');

export const FETCH_MEMBERS_BY_LOCATION = cc('FETCH_MEMBERS_BY_LOCATION');
export const FETCH_MEMBERS_BY_LOCATION_SUCCESS = cc('FETCH_MEMBERS_BY_LOCATION_SUCCESS');
export const FETCH_MEMBERS_BY_LOCATION_FAIL = cc('FETCH_MEMBERS_BY_LOCATION_FAIL');

export const SET_MEMBERS_SEARCH_QUERY_FILTER = cc('SET_MEMBERS_SEARCH_QUERY_FILTER');
export const TOGGLE_IS_ACTIVE_MEMBERS = cc('TOGGLE_IS_ACTIVE_MEMBERS');
export const SET_FILTER_TYPE = cc('SET_FILTER_TYPE');
export const RESET_FILTERS = cc('RESET_FILTERS');

interface MemberData {
  allMembersCount: number;
  nonPrimaryMembersCount: number;
  primaryMembersCount: number;
  data: Array<AlgoliaMember>;
  page: number;
  status: {
    loading: boolean;
    loaded: boolean;
    error: boolean | null | undefined;
  };
}

interface State {
  loading: boolean;
  byLocationUuid: Hash<MemberData>;
  editingDisabled: boolean;
  newMember: {} | null;
  exists: {};
  selectedMemberUuid: string | null;
  filters: { searchQuery: string; isActive: boolean; filterType: FilterType };
  // TODO: Type this.
  error: any | null;
  byUuid: {};
}

export interface MembersSubset {
  members: State;
}

// Initial State
export const initialState: State = {
  loading: false,
  byUuid: {},
  error: null,
  exists: {},
  editingDisabled: true,
  newMember: null,
  selectedMemberUuid: null,
  byLocationUuid: {},
  filters: {
    searchQuery: '',
    isActive: true,
    filterType: FilterType.ALL,
  },
};

const getPrimaryMembersCount = (nextLocationValue, primary: boolean): number => {
  return filter(nextLocationValue.data, ['is_active_licensee', primary]).length;
};

// Reducer
export const reducer = handleActions<State, any, any>(
  {
    [FETCH_MEMBER_BASIC_INFO_SUCCESS]: (state, action) => ({
      ...state,
      exists: {
        ...state.exists,
        [action.meta.email]: true,
      },
      error: null,
    }),

    [FETCH_MEMBER_BASIC_INFO_FAIL]: (state, action) => {
      const exists = {
        ...state.exists,
      };

      // If the user doesn't exist, mark it as such. Any other error type is inconclusive.
      if (action.payload.status === 404) {
        exists[action.meta.email] = false;
      }

      return {
        ...state,
        exists,
        error: action.payload.status === 404 ? null : action.payload,
      };
    },

    [CLEAR_SELECTED_MEMBER]: state => ({
      ...state,
      editingDisabled: true,
      newMember: null,
      selectedMemberUuid: null,
    }),

    [SET_NEW_MEMBER]: (state, action) => ({
      ...state,
      editingDisabled: false,
      newMember: action.payload.newMember,
      selectedMemberUuid: null,
    }),

    [RESYNC_WELKIO]: state => ({ ...state }),

    [SET_SELECTED_MEMBER]: (state, action) => ({
      ...state,
      editingDisabled: true,
      newMember: null,
      selectedMemberUuid: action.payload.member.uuid,
      byUuid: {
        ...state.byUuid,
        [action.payload.member.uuid]: action.payload.member,
      },
    }),
    [FETCH_MEMBERS_BY_LOCATION]: (state, action) => {
      const location = state.byLocationUuid[action.meta.locationUuid];

      const nextLocation = {
        ...location,
        status: {
          ...location?.status,
          loading: true,
          loaded: false,
          error: null,
        },
      };

      // To prevent appending the same collection twice, we will mark an initial fetch as such and clear
      // the collection.
      if (action.meta.firstPage) {
        nextLocation.data = [];
      }

      return {
        ...state,
        byLocationUuid: {
          ...state.byLocationUuid,
          [action.meta.locationUuid]: nextLocation,
        },
      };
    },
    [FETCH_MEMBERS_BY_LOCATION_SUCCESS]: (state, action) => {
      const locationUuid = action.meta.locationUuid;

      const location = state.byLocationUuid[action.meta.locationUuid];
      const { nbPages, page, hits } = action.payload as SearchResponse;

      const isFinalPage = nbPages === page + 1; // Pages are zero-based in algolia

      const oldStatus = {
        ...location?.status,
      };

      const newStatus = {
        loading: false,
        loaded: true,
        error: null,
      };

      let nextLocationValue = {
        ...location,
        data: hits,
        page,
        status: isFinalPage ? newStatus : oldStatus,
      };

      // If we have a different page value, it means we're getting a new page of data.
      if (state?.byLocationUuid?.[locationUuid]?.page < nextLocationValue.page) {
        // Get the previous list of members.
        const currentMembers = state?.byLocationUuid?.[locationUuid]?.data ?? [];

        // And append the new page.
        nextLocationValue.data = currentMembers.concat(action.payload.hits);
      }

      if (isFinalPage) {
        nextLocationValue = {
          ...nextLocationValue,
          allMembersCount: nextLocationValue.data.length,
          nonPrimaryMembersCount: getPrimaryMembersCount(nextLocationValue, false),
          primaryMembersCount: getPrimaryMembersCount(nextLocationValue, true),
          data:
            action.meta.filter === FilterType.ALL
              ? nextLocationValue.data
              : filter(nextLocationValue.data, [
                  'is_active_licensee',
                  action.meta.filter === FilterType.PRIMARY,
                ]),
        };
      }

      return {
        ...state,
        byLocationUuid: {
          ...state.byLocationUuid,
          [locationUuid]: nextLocationValue,
        },
      };
    },
    [FETCH_MEMBERS_BY_LOCATION_FAIL]: (state, action) => {
      const locationUuid = action.meta.locationUuid;

      const location = state.byLocationUuid[action.meta.locationUuid];

      const nextLocationValue = {
        ...location,
        status: {
          ...location?.status,
          loading: false,
          loaded: false,
          error: action.payload,
        },
      };

      return {
        ...state,
        byLocationUuid: {
          ...state.byLocationUuid,
          [locationUuid]: nextLocationValue,
        },
      };
    },
    [SET_MEMBERS_SEARCH_QUERY_FILTER]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        searchQuery: action.payload,
      },
    }),
    [TOGGLE_IS_ACTIVE_MEMBERS]: state => ({
      ...state,
      filters: {
        ...state.filters,
        isActive: !state.filters.isActive,
      },
    }),
    [SET_FILTER_TYPE]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        filterType: action.payload,
      },
    }),
    [RESET_FILTERS]: state => ({
      ...state,
      filters: {
        ...initialState.filters,
      },
    }),
  },
  initialState
);

export function fetchMemberBasicInfoByEmail(email: string) {
  return (dispatch: Dispatch<BaseAction>) => {
    // We do not use query-string for the email, because ID does not expect a URI-encoded string
    const requestAction = createRequestAction({
      endpoint: `${config.id.uri}/api/v2/users/basic_info.json?email=${email}`,
      types: [
        FETCH_MEMBER_BASIC_INFO,
        {
          type: FETCH_MEMBER_BASIC_INFO_SUCCESS,
          meta: { email },
        },
        {
          type: FETCH_MEMBER_BASIC_INFO_FAIL,
          meta: { email },
        },
      ],
    });

    return dispatch(requestAction);
  };
}

export const fetchMemberByUuid = (uuid: string) => Members.find(uuid);

export const updateMemberByUuid = (uuid: string, params: AccountUser) =>
  Members.update(uuid, {
    body: {
      ...params,
    },
  });

export const resetPasswordByUuid = (uuid: string) => Members.resetPassword(uuid);
export const activateMember = (uuid: string) => Members.activate(uuid);
export const deactivateMember = (uuid: string) => Members.deactivate(uuid);

export type MemberInfoType = {
  name: string;
  phone: string;
  email: string;
  seat_kind: string;
  status: string;
  location_uuid: string | null | undefined;
};

export const createMember = (user: MemberInfoType, companyUuid: string) =>
  Members.create({
    id: companyUuid,
    body: {
      user,
    },
  });

export const clearSelectedMember = () => ({
  type: CLEAR_SELECTED_MEMBER,
});

export const setNewMember = (newMember: {}) => ({
  type: SET_NEW_MEMBER,
  payload: { newMember },
});

export const setExistingMember = member => (
  dispatch: Dispatch<ActionWithPayload<{ member: MenaCompanyData }>>
) => {
  dispatch({
    type: SET_SELECTED_MEMBER,
    payload: {
      member: {
        ...member,
        phone: member?.normalized_phone,
      },
    },
  });
};

export const resyncWelkio = (uuid: string) => {
  return (dispatch: Dispatch<BaseAction>) => {
    const requestAction = createRequestAction({
      endpoint: `${config.welkioAuthProxy.uri}/id/v1/users/${uuid}/sync`,
      method: 'POST',
      types: [RESYNC_WELKIO, RESYNC_WELKIO_SUCCESS, RESYNC_WELKIO_FAIL],
    });

    return dispatch(requestAction);
  };
};

export default reducer;
