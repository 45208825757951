import React, { ComponentProps } from 'react';
import { omit } from 'lodash';
import { Button } from '@wework-dev/plasma';

import Trackable, { TrackableType } from 'components/decorators/trackable';

type ButtonProps = ComponentProps<typeof Button>;

type Props = Readonly<ButtonProps & TrackableType>;

const TrackableButton = Trackable()((props: Props) => {
  const filteredProps: ButtonProps = omit(props, [
    'eventMetadata',
    'getEventMetadata',
    'trackInteraction',
    'trackEvent',
    'trackEventMetadata',
    'interactionStart',
    'interactionEnd',
  ]);

  return <Button {...filteredProps} />;
});

export default TrackableButton;
