import axios, { Method } from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { ApiError, messageHandler } from 'store/errors';
import { camelCaseJson } from 'lib/util';
import { ApiMethod, AuthPrefix, Config, HeaderConfig, RequestMethodsParams } from 'lib/api/type';

/**
 * Class ApiRequest - create the apiRequest with inputted domain
 * @param domain: string
 * */
class ApiRequest {
  domain: string;

  constructor(domain: string) {
    this.domain = domain;
  }

  get({ path, params, config }: RequestMethodsParams) {
    return this.request(ApiMethod.GET, path, params, null, config);
  }
  patch({ path, data, config }: RequestMethodsParams) {
    return this.request(ApiMethod.PATCH, path, null, data, config);
  }
  post({ path, data, config }: RequestMethodsParams) {
    return this.request(ApiMethod.POST, path, null, data, config);
  }
  put({ path, data, config }: RequestMethodsParams) {
    return this.request(ApiMethod.PUT, path, null, data, config);
  }
  delete({ path, data, config }: RequestMethodsParams) {
    return this.request(ApiMethod.DELETE, path, null, data, config);
  }

  private async request(
    method: Method,
    path: string,
    params: any = {},
    data: any = {},
    config: Config = {}
  ) {
    const {
      entry = 'data',
      getEntry = false,
      header = {},
      isCamelCase = true,
      signal = undefined,
      useArrayFormatBrackets = false,
      withRetry = false,
    } = config;
    const url = `${this.domain}/${path}`;

    const headers = await ApiRequest.createHeader(header);

    const options: {
      url: string;
      method: Method;
      headers?: {};
      params?: {};
      data?: Object;
      signal?: AbortSignal;
    } = {
      url,
      method,
      headers,
      params: params && method === ApiMethod.GET ? params : {},
      data: data && method !== ApiMethod.GET && Object.keys(data).length > 0 ? data : null,
      signal,
    };

    try {
      if (withRetry) axiosRetry(axios, { retryDelay: exponentialDelay });

      const response = await axios({
        ...options,
        paramsSerializer: params => {
          return ApiRequest.parseQuery(params, useArrayFormatBrackets);
        },
      });

      let { data } = response;

      if (getEntry && entry !== undefined && data.hasOwnProperty(entry)) {
        data = data[entry];
      }

      return isCamelCase ? camelCaseJson(data) : data;
    } catch (err) {
      const errorMessage = err.message;
      if (!axios.isAxiosError(err)) throw new Error(err);
      const status = err.response?.status ?? 0;
      const statusText = err.response?.statusText ?? '';
      throw new ApiError(
        status,
        statusText,
        err,
        errorMessage || messageHandler(status, statusText, this.domain)
      );
    }
  }

  /**
   * PRIVATE METHODS
   * */

  /**
   * createHeader - create the header for the request
   * @param authPrefix - Determine the Auth type. Default is 'Bearer'
   * @param extraHeaders - HeaderConfig which contains multiple config information for the header
   *
   * @returns Object - headers
   * */
  private static async createHeader(config: HeaderConfig) {
    const { authPrefix = AuthPrefix.BEARER, requireXRequestId, ...customHeaders } = config;
    const accessToken = await ApiRequest.getCredential();

    const authorization = {
      [AuthPrefix.TOKEN]: `Token token=${accessToken}`,
      [AuthPrefix.BEARER]: `Bearer ${String(accessToken)}`,
    }[authPrefix];

    return {
      'Content-Type': 'application/json',
      Authorization: authorization,
      ...(requireXRequestId && { 'x-request-id': uuidv4() }),
      ...customHeaders,
    };
  }

  /**
   * parseQuery - parse the query into Array Format Brackets
   * @param params: {} - query params
   * @param useArrayFormatBrackets: boolean
   * @returns string
   * */
  private static parseQuery(params: {}, useArrayFormatBrackets: boolean) {
    return useArrayFormatBrackets
      ? queryString.stringify(params, { arrayFormat: 'bracket' })
      : new URLSearchParams(params).toString();
  }

  /**
   * getCredential - to get the access credential for the api
   * */
  private static async getCredential() {
    return getAccessToken();
  }
}

export default ApiRequest;
