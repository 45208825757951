import { InventorySearchMetrics } from 'features/building/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { RequestActionTypes } from 'store/types';
import {
  SEARCH_ALL_INVENTORY_METRICS,
  SEARCH_ALL_INVENTORY_METRICS_SUCCESS,
  SEARCH_ALL_INVENTORY_METRICS_FAIL,
} from 'features/building/ducks/inventorySearchMetricsConsts';

const types: RequestActionTypes = [
  SEARCH_ALL_INVENTORY_METRICS,
  SEARCH_ALL_INVENTORY_METRICS_SUCCESS,
  SEARCH_ALL_INVENTORY_METRICS_FAIL,
];

const reducer = createRequestReducer<InventorySearchMetrics, InventorySearchMetrics>(
  types,
  {},
  res => res.payload
);

export default reducer;
