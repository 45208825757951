import React from 'react';

import EmptyState, { EmptyStateProps } from './index';

type TableRowProps = {
  colSpan?: number;
};

type Props = EmptyStateProps & TableRowProps;

export const EmptyStateTable = ({ colSpan, ...emptyStateProps }: Props) => {
  return (
    <tr className="!hover:shadow-none hover:cursor-default">
      <td className="!border-t-0 !shadow-none rounded-sm" colSpan={colSpan}>
        <EmptyState {...emptyStateProps} />
      </td>
    </tr>
  );
};
