import { handleActions } from 'redux-actions';
import { flatten, uniqBy } from 'lodash';

import { Reservation } from 'features/companies/redux/reservations/types';
import {
  FETCH_RESERVATIONS_BY_ACCOUNT,
  FETCH_RESERVATIONS_BY_ACCOUNT_SUCCESS,
  FETCH_RESERVATIONS_BY_ACCOUNT_FAIL,
  REFRESH_RESERVATIONS_BY_ACCOUNT,
  REFRESH_RESERVATIONS_BY_ACCOUNT_SUCCESS,
  REFRESH_RESERVATIONS_BY_ACCOUNT_FAIL,
  CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAIL,
  UPDATE_RESERVATION,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAIL,
} from 'features/companies/redux/reservations/constants';

export interface State {
  loaded: boolean;
  refreshing: boolean;
  byAccountUuid: Hash<Array<Reservation>>;
  loading: boolean;
  error: boolean | null | undefined;
}
export interface ReservationsSubset {
  reservations: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  byAccountUuid: {},
  error: null,
  refreshing: false,
};

export const reducer = handleActions<State, any>(
  {
    [FETCH_RESERVATIONS_BY_ACCOUNT]: (state: State) => ({
      ...state,
      loading: true,
      loaded: false,
    }),

    [FETCH_RESERVATIONS_BY_ACCOUNT_SUCCESS]: (
      state: State,
      action: {
        payload: {
          result: Array<Reservation>;
          params: { account_id: string };
        };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byAccountUuid: {
        ...state.byAccountUuid,
        [action.payload.params.account_id]: action.payload.result,
      },
    }),

    [FETCH_RESERVATIONS_BY_ACCOUNT_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),

    [REFRESH_RESERVATIONS_BY_ACCOUNT]: (state: State) => ({
      ...state,
      refreshing: true,
    }),

    [REFRESH_RESERVATIONS_BY_ACCOUNT_SUCCESS]: (
      state: State,
      action: {
        payload: {
          result: Array<Reservation>;
          params: { account_id: string };
        };
      }
    ) => ({
      ...state,
      refreshing: false,
      byAccountUuid: {
        ...state.byAccountUuid,
        [action.payload.params.account_id]: action.payload.result,
      },
    }),

    [REFRESH_RESERVATIONS_BY_ACCOUNT_FAIL]: (state: State, action) => ({
      ...state,
      refreshing: false,
      error: action.error,
    }),

    [CREATE_RESERVATION]: (state: State) => ({ ...state, refreshing: true }),

    [CREATE_RESERVATION_SUCCESS]: (
      state: State,
      action: {
        payload: {
          result: Reservation;
          params: { account_uuid: string };
        };
      }
    ) => {
      const accountUuid = action.payload.params.account_uuid;
      const existingReservationsForAccount = state.byAccountUuid[accountUuid] || [];
      const allReservationsForAccount = flatten([
        ...existingReservationsForAccount,
        action.payload.result,
      ]);

      return {
        ...state,
        refreshing: false,
        byAccountUuid: {
          ...state.byAccountUuid,
          [accountUuid]: allReservationsForAccount,
        },
      };
    },

    [CREATE_RESERVATION_FAIL]: (state: State, action) => ({
      ...state,
      refreshing: false,
      error: action.error,
    }),

    [UPDATE_RESERVATION]: (state: State) => ({ ...state, refreshing: true }),

    [UPDATE_RESERVATION_SUCCESS]: (state: State, action) => {
      const accountUuid = action.payload.params.account_uuid;
      const existingReservationsForAccount = state.byAccountUuid[accountUuid] || [];
      const allReservationsForAccount = uniqBy(
        [...existingReservationsForAccount, action.payload.result],
        'uuid'
      );

      return {
        ...state,
        refreshing: false,
        byAccountUuid: {
          ...state.byAccountUuid,
          [accountUuid]: allReservationsForAccount,
        },
      };
    },

    [UPDATE_RESERVATION_FAIL]: (state: State, action) => ({
      ...state,
      refreshing: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
