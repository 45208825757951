import { CHECK_INS_SEARCH_PARAMS } from 'features/check-ins/constants';

export const getUrlPath = (url: string) => {
  const withoutProtocol = url.replace(/^https?:\/\//i, '');
  const withoutHost = withoutProtocol.replace(window.location.host, '');
  // replace hash route
  return withoutHost.replace('/#', '');
};

export const generateUrl = (taskId: string, queryParamLocationUuid: string) =>
  `?${CHECK_INS_SEARCH_PARAMS.ID}=${taskId}&${CHECK_INS_SEARCH_PARAMS.LOCATION}=${queryParamLocationUuid}`;
