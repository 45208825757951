import { combineReducers } from 'redux';
import queryString from 'query-string';

import { createRequestAction, createRequestReducer } from 'store/util';
import { createRequestConstantNames } from 'store/util/createConstants';
import config from 'config';
import cc from 'store/util/createReduxConstant';
import { getQueryParams } from 'features/inventorySearch/actions/getQueryParams';
import { RSAAType } from 'store/types';
import { RequestState } from 'store/util/createRequestReducer';

export const FETCH_PRODUCTS = cc('FETCH_PRODUCTS');
export const FETCH_PRODUCTS_SUCCESS = cc('FETCH_PRODUCTS_SUCCESS');
export const FETCH_PRODUCTS_FAIL = cc('FETCH_PRODUCTS_FAIL');

export const [
  FETCH_RECOMMENDED_PRODUCTS,
  FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
  FETCH_RECOMMENDED_PRODUCTS_FAIL,
] = createRequestConstantNames(cc('FETCH_RECOMMENDED_PRODUCTS'));

// TODO: Type these.
export type Lists = Array<any>;
export type RecommendedProducts = Array<any>;

export interface State {
  list: RequestState<Lists>;
  recommendedProducts: RequestState<RecommendedProducts>;
}

export interface ProductsSubset {
  products: State;
}

export const reducer = combineReducers<State>({
  // TODO: Type this.
  list: createRequestReducer<Lists>(
    [FETCH_PRODUCTS, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAIL],
    [],
    (res): Lists => res.payload || []
  ),
  // TODO: Type this.
  recommendedProducts: createRequestReducer<RecommendedProducts>(
    [
      FETCH_RECOMMENDED_PRODUCTS,
      FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
      FETCH_RECOMMENDED_PRODUCTS_FAIL,
    ],
    [],
    (res): RecommendedProducts => res.payload || []
  ),
});

export const fetchProducts = () =>
  createRequestAction({
    method: 'GET',
    endpoint: `${config.productService.uri}/v2/products`,
    types: [FETCH_PRODUCTS, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAIL],
  });

export const fetchRecommendedProducts = (buildingUuid: string, capacity: number): RSAAType => {
  const { sfOpportunityId } = getQueryParams();

  const params = queryString.stringify({
    capacity,
    buildingUuid,
    sfOpportunityId,
  });

  return createRequestAction({
    endpoint: `${config.productService.uri}/v2/recommend_dedicated_bandwidth?${params}`,
    types: [
      FETCH_RECOMMENDED_PRODUCTS,
      FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
      FETCH_RECOMMENDED_PRODUCTS_FAIL,
    ],
  });
};

export default reducer;
