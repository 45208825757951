const PRODUCT_TYPE_PHYSICAL = 'Physical';
const PRODUCT_TYPE_DIGITAL = 'Digital';
const PRODUCT_TYPE_GLOBAL_ACCESS = 'Global Access';
const PRODUCT_TYPE_EXTRA_MEMBER = 'ExtraMember';
const PRODUCT_TYPE_HOTDESK = 'Hotdesk';
const PRODUCT_TYPE_EMPLOYEE = 'Employee';
const PRODUCT_TYPE_PXWE = 'PxWe';

export const ProductTypes = Object.freeze({
  PHYSICAL: PRODUCT_TYPE_PHYSICAL,
  DIGITAL: PRODUCT_TYPE_DIGITAL,
  GLOBAL_ACCESS: PRODUCT_TYPE_GLOBAL_ACCESS,
  EXTRA_MEMBER: PRODUCT_TYPE_EXTRA_MEMBER,
  HOTDESK: PRODUCT_TYPE_HOTDESK,
  EMPLOYEE: PRODUCT_TYPE_EMPLOYEE,
  PXWE: PRODUCT_TYPE_PXWE,
});

export const ErrorMessages = Object.freeze({
  ASSIGN_MEMBERSHIP_ERROR: {
    errorName: 'ASSIGN_MEMBERSHIP_ERROR',
    errorMessage: 'Failed to assign user to membership, please try again.',
  },
  REMOVE_MEMBERSHIP_ERROR: {
    errorName: 'REMOVE_MEMBERSHIP_ERROR',
    errorMessage: 'Failed to remove user from membership, please try again.',
  },
});

export const EDIT_MEMBER_PRODUCT_FORM_NAME = 'editMemberProductForm';

export const EDIT_MODAL_TITLE = 'Edit Memberships';
export const ACTIVATE_MEMBER_TITLE = 'Activate Member';
