import { handleActions, Reducer } from 'redux-actions';

import {
  FETCH_EXPIRED_CONTRACTS,
  FETCH_EXPIRED_CONTRACTS_SUCCESS,
  FETCH_EXPIRED_CONTRACTS_FAIL,
} from 'features/companies/edit/redux/expiredContracts/constants';

interface State {
  loaded: boolean;
  loading: boolean;
  error: boolean | null | undefined;
  byAccountUuid: Hash<Array<object>>;
}

export interface ExpiredContractsSubset {
  expiredContracts: State;
}

// Initial State
const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  byAccountUuid: {},
};

export const reducer: Reducer<State, any> = handleActions<State, any, any>(
  {
    [FETCH_EXPIRED_CONTRACTS]: (state: State) => ({ ...state, loading: true, loaded: false }),

    [FETCH_EXPIRED_CONTRACTS_SUCCESS]: (
      state: State,
      action: {
        payload: Array<object>;
        meta: { accountUuid: string };
      }
    ) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      byAccountUuid: {
        ...state.byAccountUuid,
        [action.meta.accountUuid]: action.payload,
      },
    }),

    [FETCH_EXPIRED_CONTRACTS_FAIL]: (state: State, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    }),
  },
  initialState
);

export default reducer;
