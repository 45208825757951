import config from 'config';
import ApiRequest from 'lib/api/apiRequest';
import {
  UserRoles,
  IAccountBulkMembershipAssignRequest,
  IAccountBulkKeycardAssignRequest,
  IAccountBulkUserGroupAssignRequest,
  IAccountBulkAccessAssignRequest,
} from 'lib/api/accountService/types';

export default class AccountService extends ApiRequest {
  constructor() {
    super(config.accountService.uri);
  }

  getAccountUserRoles = async (accountUuid: string, signal: AbortSignal): Promise<UserRoles> => {
    const request = {
      config: {
        isCamelCase: true,
        signal,
      },
      path: `v1/users/${accountUuid}/roles`,
    };

    return this.get(request);
  };

  // returns a 202 if successfully accepted
  postBulkMembershipAssign = async (
    accountUuid: string,
    membershipAssignmentRequest: IAccountBulkMembershipAssignRequest
  ): Promise<void> => {
    const request = {
      data: membershipAssignmentRequest,
      path: `v1/accounts/${accountUuid}/memberships/bulk_assignments`,
    };

    return this.post(request);
  };

  // returns a 202 if successfully accepted
  postBulkKeycardAssign = async (
    accountUuid: string,
    keycardAssignmentRequest: IAccountBulkKeycardAssignRequest
  ): Promise<void> => {
    const request = {
      data: keycardAssignmentRequest,
      path: `v1/accounts/${accountUuid}/keycards/bulk_assignments`,
    };

    return this.post(request);
  };

  // returns a 202 if successfully accepted
  postBulkUserGroupAssign = async (
    accountUuid: string,
    userGroupAssignmentRequest: IAccountBulkUserGroupAssignRequest
  ): Promise<void> => {
    const request = {
      data: userGroupAssignmentRequest,
      path: `v1/accounts/${accountUuid}/user_groups/bulk_assignments`,
    };

    return this.post(request);
  };

  // returns a 202 if successfully accepted
  postBulkAccessAssign = async (
    accountUuid: string,
    accessAssignmentRequest: IAccountBulkAccessAssignRequest
  ): Promise<void> => {
    const request = {
      data: accessAssignmentRequest,
      path: `v1/accounts/${accountUuid}/access/bulk_assignments`,
    };

    return this.post(request);
  };
}
