import cc from 'store/util/createReduxConstant';
import { createRequestConstantNames } from 'store/util/createConstants';

export const [
  FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION,
  FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION_SUCCESS,
  FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION_FAIL,
] = createRequestConstantNames(cc('FETCH_AVAILABLE_PROMOTIONS_BY_LOCATION'));

export const CUSTOM_PROMOTION = {
  amount: 0,
  code: '',
  description_blurb: '',
  displayText: '',
  duration: 1,
  initial_term: 1,
  percent: true,
  variable: false,
};
