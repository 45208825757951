import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { Header } from '@wework-dev/plasma';
import queryString from 'query-string';
import { useAuth0 } from '@auth0/auth0-react';

import { GlobalState } from 'store/modules';
import { ReduxProps } from 'store/types';
import { login } from 'features/auth/requestActions';
import { showAuthTroubleshootCaption } from 'features/auth/selectors';
import TrackableButton from 'components/trackableButton';

import style from './style.scss';

const mapStateToProps = (state: GlobalState) => ({
  showAuthTroubleshootCaption: showAuthTroubleshootCaption(state),
});

const mapDispatchToProps = {
  login,
};

type Props = Readonly<
  RouteComponentProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>
>;

const Login = ({ history, login, showAuthTroubleshootCaption }: Props) => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const location = useLocation();
  const auth0 = useAuth0();

  // check for error query params to be shown on the login page
  useEffect(() => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('error')) {
      setLoginError(queryParams.get('error'));
    }
  }, []);

  if (auth0.isAuthenticated) {
    const { nextPath, nextQuery } = queryString.parse(history.location.search);
    const pathName = Array.isArray(nextPath) ? nextPath[0] : nextPath;
    const search = Array.isArray(nextQuery)
      ? queryString.parse(nextQuery[0])
      : queryString.parse(nextQuery || '');

    return (
      <Redirect
        to={{
          pathname: pathName || '/',
          search: queryString.stringify(search),
        }}
      />
    );
  }

  return (
    <div className={style.base}>
      <div className={style.logo} />
      <div className={style.content}>
        <Header h1 invert>
          Welcome to Spacestation
        </Header>
        <div className={style.actions}>
          <TrackableButton
            loading={false}
            onClick={login}
            trackEvent="Login"
            data={{ test: 'spacestation-login' }}
            trackEventMetadata={{
              workflow: 'auth',
              featureContext: 'page.login',
              feature: 'login',
            }}
          >
            Log in with Auth0
          </TrackableButton>
        </div>
        {loginError && (
          <strong className="text-white">
            There was an error trying to log you in: {loginError}
          </strong>
        )}
        {showAuthTroubleshootCaption ? (
          <p className={style.message}>
            Having trouble logging in? Please create an{' '}
            <a href="https://jira.weworkers.io/plugins/servlet/desk/portal/118/create/1819">
              Application Support ticket
            </a>{' '}
            for Spacestation / Spaceman.
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export const TestableLogin = Login;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
