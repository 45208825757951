import {
  ROOM_RESERVATION_REASON_FORM_FIELD,
  ROOM_RESERVATION_NOTE_FORM_FIELD,
} from 'features/rooms/components/modals/constants';

export const MODAL_TITLE = 'Cancel Room Booking';
export const CANCELLATION_REASON_TITLE = 'Cancellation reason*';
export const CANCELLATION_REASON_PLACEHOLDER = 'Select a cancellation reason';

export const MODAL_TITLE_DIETER = 'Cancel booking';
export const MODAL_SUBTITLE_DIETER =
  'Are you sure you want to cancel this booking? Cancelling a booking will remove the booking and no cost will be charged to the company.';

export const CANCEL_REASON_PLACEHOLDER_DIETER = 'Select reason for cancellation';

export const CANCEL_BOOKING_MODAL_FORM = {
  NAME: 'CANCEL_BOOKING_FORM',
  FIELDS: {
    REASON: ROOM_RESERVATION_REASON_FORM_FIELD,
    NOTE: ROOM_RESERVATION_NOTE_FORM_FIELD,
  },
};

export const NOTE_OTHER_PLACEHOLDER = 'Please provide additional information';
export const NOTE_OTHER_PLACEHOLDER_OD_SHORT = 'Please specify the reason';
export const NOTE_ISSUE_WITH_A_ROOM_PLACEHOLDER = 'Please describe the issue';
export const OTHER = 'Other';
