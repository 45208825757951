import React from 'react';
import cn from 'classnames';

import Loading from 'components/loading';

import styles from './index.scss';

type Props = Readonly<{
  children?: React.ReactNode;
  invert?: boolean;
  delayMs?: number;
}>;

export default function LoadingPage({ children, invert, delayMs }: Props) {
  const classes = cn(styles.root, {
    [styles.invert]: invert,
  });

  return (
    <div className={classes}>
      <Loading delayMs={delayMs} />
      {children}
    </div>
  );
}
