import { eachMonthOfInterval, parseISO, endOfMonth } from 'date-fns';

import { formatToISODate } from 'features/paperwork/contracts-flow/sections/product/utils';
import {
  CommitmentTermPayloadSubmit,
  OutOfPolicyAcknowledgement,
  ProductReservationUpdate,
  TermPayload,
} from 'features/paperwork/contracts-flow/types';

const MONTHS = 'months';

const toMonthDiscount = (
  locationUuid: string,
  selectedPromotionCode: string,
  terms: Array<CommitmentTermPayloadSubmit>,
  amount: number,
  reservationUuid: string,
  currency: string,
  reservableUuid: string
) => month => {
  const discountPayload = {
    started_on: formatToISODate(month),
    ended_on: formatToISODate(endOfMonth(month)),
    amount: Number(amount),
    promotion_code: selectedPromotionCode,
    location_uuid: locationUuid,
    reservable_uuid: reservableUuid,
  };
  let actionSpecificpayload = {};
  if (!reservationUuid) {
    actionSpecificpayload = {
      move_in_date: terms[0].started_on,
      currency,
    };
  } else {
    actionSpecificpayload = {
      change_date: terms[0].started_on,
      reservation_uuid: reservationUuid,
    };
  }
  return {
    ...discountPayload,
    ...actionSpecificpayload,
  };
};

const rangeToDiscounts = (
  locationUuid: string,
  selectedPromotionCode: string,
  terms: Array<CommitmentTermPayloadSubmit>,
  reservationUuid: string,
  currency: string,
  reservableUuid: string
) => (fromOptionRange, { started_on, ended_on, amount }) => {
  if (amount > 0) {
    const months = eachMonthOfInterval({
      start: parseISO(started_on),
      end: parseISO(ended_on),
    });

    const monthlyDiscounts = months.map(
      toMonthDiscount(
        locationUuid,
        selectedPromotionCode,
        terms,
        amount,
        reservationUuid,
        currency,
        reservableUuid
      )
    );

    fromOptionRange.push(...monthlyDiscounts);
  }
  return fromOptionRange;
};

const extractDiscounts = (
  fromReservation,
  {
    discounts,
    locationUuid,
    selectedPromotionCode,
    terms,
    uuid: reservationUuid,
    currency,
    reservableUuid,
  }: ProductReservationUpdate
) => {
  const fromRange = discounts?.reduce(
    rangeToDiscounts(
      locationUuid,
      selectedPromotionCode,
      terms,
      reservationUuid,
      currency,
      reservableUuid
    ),
    []
  );

  fromReservation.push(...fromRange);
  return fromReservation;
};

export const getRenewalDiscounts = (
  productsReservationChanges: Array<ProductReservationUpdate>,
  isPromotionDiscountPerpetuating: boolean
) => {
  const discounts = productsReservationChanges.reduce(extractDiscounts, []);

  if (isPromotionDiscountPerpetuating && discounts.length > 0) {
    discounts[discounts.length - 1].ended_on = null;
  }

  return discounts;
};

export const getRenewalTerms = (productsReservationChanges: Array<ProductReservationUpdate>) =>
  productsReservationChanges.reduce<Array<TermPayload>>(
    (termsPayload, { uuid, terms, locationUuid, reservableUuid }) => {
      if (!uuid) {
        termsPayload.push(
          ...terms.map(term => ({
            calendarUnit: MONTHS,
            count: term.months,
            location_uuid: locationUuid,
            started_on: term.started_on,
            reservable_uuid: reservableUuid,
          }))
        );
      }

      return termsPayload;
    },
    []
  );

const toRenewalReservationPayload = (
  {
    uuid,
    terms,
    reservableUuid,
    locationUuid,
    price,
    officeNumber,
    reservableType,
  }: ProductReservationUpdate,
  sfOpportunityId?: string,
  outOfPolicyDiscountReason?: string,
  agreementNotes?: string
) => {
  const reservationPayload = {
    reservation_uuid: uuid,
    reservable_uuid: reservableUuid,
    initial_terms: terms[0]?.months,
    location_uuid: locationUuid,
    market_price: price,
    discount_reason: outOfPolicyDiscountReason,
    agreement_notes: agreementNotes,
    ...(sfOpportunityId ? { sf_opportunity_id: window.btoa(sfOpportunityId) } : {}),
  };

  let actionSpecificAttrs = {};
  if (!uuid) {
    actionSpecificAttrs = {
      action: 'add',
      type: 'move_in',
      change_type: 'Add',
      committed: false,
      move_in_date: terms[0].started_on,
      move_out_date: null,
      reservable_number: officeNumber,
      reservable_type: reservableType,
      initial_terms: {
        calendarUnit: 'months',
        count: 1,
        locationUUID: locationUuid,
        moveInDate: terms[0].started_on,
      },
    };
  } else {
    actionSpecificAttrs = {
      change_date: terms[0].started_on,
    };
  }

  return {
    ...reservationPayload,
    ...actionSpecificAttrs,
  };
};

export const getRenewalReservations = (
  productsReservationChanges: Array<ProductReservationUpdate>,
  outOfPolicyAcknowledgements: OutOfPolicyAcknowledgement,
  sfOpportunityId?: string,
  agreementNotes?: string
) =>
  productsReservationChanges.map(productReservationChange => {
    const outOfPolicyDiscountReason = outOfPolicyAcknowledgements.discountReason;

    return toRenewalReservationPayload(
      productReservationChange,
      sfOpportunityId,
      outOfPolicyDiscountReason,
      agreementNotes
    );
  });

const getAvgDiscountForReservable = (discounts, reservableUuid, termLength) =>
  discounts.reduce((sum, { amount, reservable_uuid }) => {
    if (reservable_uuid === reservableUuid) return sum + amount;
    return sum;
  }, 0) / termLength;

export const getOutOfPolicyDiscounts = (
  productsReservationChanges: Array<ProductReservationUpdate>,
  discounts
) =>
  productsReservationChanges.reduce(
    (discountsHash, { reservableUuid, maxDiscount, terms, price }) => {
      discountsHash[reservableUuid] = {
        max_allowable_discounts: Number(maxDiscount) * price,
        sales_requested_discounts: getAvgDiscountForReservable(
          discounts,
          reservableUuid,
          terms[0].months
        ),
      };
      return discountsHash;
    },
    {}
  );
