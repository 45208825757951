import { InventoryPayloadItem } from '../config/types';
import { InventoryAttribute } from '../types';
import { isHQxWeSpaceFromIMS } from '../actions/actionAvailability';
import isInventoryGroup from '../actions/isInventoryGroup';

import { getActionsPayload } from './actionsSerializer';

export default (inventory: InventoryPayloadItem): InventoryAttribute | null | undefined => {
  const payload = getActionsPayload(inventory);

  if (isHQxWeSpaceFromIMS(payload) || isInventoryGroup(payload)) {
    return null;
  }

  const ppwkValue = payload.paperworkPending && 'Paperwork Pending';
  const holdValue = payload.onHold && 'On Hold';
  const statusValue = ppwkValue || holdValue || '';
  const partialTimeOfficeValue = (payload.partialTimeOffice && 'Part Time Office') || '';
  const value =
    statusValue + (statusValue && partialTimeOfficeValue && ',') + partialTimeOfficeValue;

  return {
    value,
    meta: {
      payload,
    },
  };
};
