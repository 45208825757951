import { castArray, compact, first, isInteger } from 'lodash';
import moment from 'moment-timezone';

import { deprecated_isPresent } from 'lib/util';

export const when = <T extends (...args: Array<any>) => any>(
  predicate: (...args: Array<any>) => boolean,
  validators: Array<T>
) => (...args: Array<unknown>): T | null | undefined =>
  predicate(...args)
    ? first(compact(castArray(validators).map(validator => validator(...args))))
    : undefined;

const whiteSpaceRegex = /\s/;

const isNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined);
const greaterThanZero = (value: number) => (value <= 0 ? 'Must be greater than zero' : undefined);
const notNegative = (value: number) => (value < 0 ? 'Must be positive' : undefined);

export const Validators = {
  isNumber,

  greaterThanZero,

  notNegative,

  validateUUIDList(value: string | null | undefined): string | null | undefined {
    const uuidList = value && value?.split(',');
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    return uuidList && uuidList.some(uuid => !regex.test(uuid))
      ? 'Must be a comma-separated UUID list'
      : undefined;
  },

  notBlank(value: string | null | undefined): string | null | undefined {
    return !!value && !/^\s*$/.test(value) ? undefined : 'Required';
  },

  notInteger(value: number): string | null | undefined {
    return !isInteger(value) ? 'Must be a whole number' : undefined;
  },

  maxLength(max: number) {
    return (value: string): string | null | undefined => {
      return value.length > max ? `Must be no longer than ${max} characters` : undefined;
    };
  },
  fileNotBlank(value: unknown): string | null | undefined {
    return value ? undefined : 'Required';
  },

  isName(value: string): string | null | undefined {
    // adapted from FormsyValidator in lib/validators
    return value != null && value.match(/(\w+\s{1}\w+)/) != null
      ? undefined
      : 'Name must have both a first and a last name';
  },

  isEmail(value: string): string | null | undefined {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined;
  },

  isOnOrAfterToday: (value: string) =>
    value && moment(value).isBefore(moment().startOf('day')) ? 'Invalid' : undefined,

  commitmentTermDurationNotBlank(value: any): string | null | undefined {
    // This function assumes string input - will not work with integer input
    if (!deprecated_isPresent(value)) return 'Required';
    if (isNaN(value)) return 'Must be numerical';
    if (!isInteger(parseFloat(value))) return 'Must be an integer';
    if (parseInt(value, 10) <= 1) return 'Int must be > 1';
    return undefined;
  },

  noWhiteSpace(value: string): string | null | undefined {
    return value && whiteSpaceRegex.test(value) ? 'Cannot contain empty space' : undefined;
  },
};
