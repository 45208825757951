import squareFootageSerializer from 'features/inventorySearch/serializers/squareFootageSerializer';
import rsfSerializer from 'features/inventorySearch/serializers/rsfSerializer';

import { SerializerConfig } from '../types';
import actionsSerializer from '../serializers/actionsSerializer';
import availabilitySerializer from '../serializers/availabilitySerializer';
import booleanSerializer from '../serializers/booleanSerializer';
import floorPlanLinkSerializer from '../serializers/floorPlanLinkSerializer';
import floorPlanSerializer from '../serializers/floorPlanSerializer';
import holdStatusSerializer from '../serializers/holdStatusSerializer';
import locationSerializer from '../serializers/locationSerializer';
import notesSerializer from '../serializers/notesSerializer';
import occupancySerializer from '../serializers/occupancySerializer';
import priceAndCBPSerializer from '../serializers/priceAndCBPSerializer';
import simpleSerializer from '../serializers/simpleSerializer';
import numericSimpleSerializer from '../serializers/numericSimpleSerializer';
import seatCountSerializer from '../serializers/seatCountSerializer';
import inventoryTagsSerializer from '../serializers/inventoryTagsSerializer';

export const SerializersMap: Hash<SerializerConfig> = {
  actions: { serializer: actionsSerializer, serializerKey: '', summable: false }, // fetches from multiple keys in the payload.
  building: { serializer: locationSerializer, serializerKey: '', summable: false },
  buildingClass: { serializer: simpleSerializer, serializerKey: 'buildingClass', summable: false },
  buildingTier: { serializer: simpleSerializer, serializerKey: 'buildingTier', summable: false },
  capacity: { serializer: numericSimpleSerializer, serializerKey: 'capacity', summable: true },
  checked: { serializer: simpleSerializer, serializerKey: 'uuid', summable: false },
  communityManager: {
    serializer: simpleSerializer,
    serializerKey: 'location.uuid',
    summable: false,
  },
  currentMember: { serializer: occupancySerializer, serializerKey: 'uuid', summable: false },
  filterableGate: {
    serializer: simpleSerializer,
    serializerKey: 'filterableGate',
    summable: false,
  },
  floorPlan: { serializer: floorPlanSerializer, serializerKey: '', summable: false }, // fetches from multiple keys in the payload.
  floorPlanLink: { serializer: floorPlanLinkSerializer, serializerKey: '', summable: false },
  hasWindow: { serializer: booleanSerializer, serializerKey: 'hasWindow', summable: false },
  holdStatus: { serializer: holdStatusSerializer, serializerKey: '', summable: false },
  internalRoomCount: {
    serializer: numericSimpleSerializer,
    serializerKey: 'internalRoomCount',
    summable: true,
  },
  lat: { serializer: simpleSerializer, serializerKey: 'geoLocation.latitude', summable: false },
  lng: { serializer: simpleSerializer, serializerKey: 'geoLocation.longitude', summable: false },
  location: { serializer: locationSerializer, serializerKey: '', summable: false },
  locationName: { serializer: simpleSerializer, serializerKey: 'location.name', summable: false },
  locationUuid: { serializer: simpleSerializer, serializerKey: 'location.uuid', summable: false },
  name: { serializer: simpleSerializer, serializerKey: 'name', summable: false },
  neighboringMembers: {
    serializer: simpleSerializer,
    serializerKey: 'location.uuid',
    summable: false,
  },
  nextAvailableDate: {
    serializer: availabilitySerializer,
    serializerKey: 'startDate',
    summable: false,
  },
  notes: { serializer: notesSerializer, serializerKey: 'notes', summable: false },
  openDate: { serializer: simpleSerializer, serializerKey: 'openDate', summable: false },
  pastOccupancies: { serializer: occupancySerializer, serializerKey: 'uuid', summable: false },
  price: { serializer: priceAndCBPSerializer, serializerKey: 'price', summable: true },
  product: { serializer: locationSerializer, serializerKey: 'product', summable: true },
  seatCount: { serializer: seatCountSerializer, serializerKey: 'seatCount', summable: true },
  squareFootage: {
    serializer: squareFootageSerializer,
    serializerKey: 'squareFootage',
    summable: true,
  },
  rentableSquareFootage: {
    serializer: rsfSerializer,
    serializerKey: 'rentableSquareFootage',
    summable: true,
  },
  totalContractValueOptions: {
    serializer: priceAndCBPSerializer,
    serializerKey: 'price',
    summable: true,
  },
  usableSquareFootage: {
    serializer: numericSimpleSerializer,
    serializerKey: 'usableSquareFootage',
    summable: true,
  },
  viewOfficeDetails: { serializer: simpleSerializer, serializerKey: 'uuid', summable: false },
  workUnits: { serializer: numericSimpleSerializer, serializerKey: 'workUnits', summable: true },
  spatialWorkUnits: {
    serializer: numericSimpleSerializer,
    serializerKey: 'spatialWorkUnits',
    summable: true,
  },
  availability: { serializer: simpleSerializer, serializerKey: 'availability', summable: false },
  currentMoveOut: {
    serializer: simpleSerializer,
    serializerKey: 'currentMoveOut',
    summable: false,
  },
  nextMoveIn: { serializer: simpleSerializer, serializerKey: 'nextMoveIn', summable: false },
  inventoryTags: {
    serializer: inventoryTagsSerializer,
    serializerKey: 'inventoryTags',
    summable: false,
  },
};

export default SerializersMap;
