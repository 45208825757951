import { pick } from 'lodash';

import config from 'config';

import { Mapper } from './types';

// eslint-disable-next-line no-unused-vars
const mapper = _filters => [];

const itemMapper = algoliaItem => pick(algoliaItem, 'id', 'code', 'default_name', 'is_open');

const companyMapper: Mapper = {
  index: config.algolia.indices.building,
  mapper,
  itemMapper,
};

export default companyMapper;
