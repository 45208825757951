import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { v4 as uuidv4 } from 'uuid';

import config from 'config';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import rollbar from 'lib/rollbar';

// add this back once we run codegen for queries
// import generatedIntrospection from './__generated__';

export interface CreateClientOptions {
  host: string;
  target: string;
}

export const createClientFactory = (createClientOptions: CreateClientOptions) => {
  const cache = new InMemoryCache({
    dataIdFromObject: (object: any, _) => {
      const { __typename, id, _id, uuid } = object;
      if (typeof __typename === 'string') {
        if (typeof id !== 'undefined') return `${__typename}:${id}`;
        if (typeof _id !== 'undefined') return `${__typename}:${_id}`;
        if (typeof uuid !== 'undefined') return `${__typename}:${uuid}`;
      }
      return undefined;
    },
    // add this back once we run codegen for queries
    // possibleTypes: generatedIntrospection.possibleTypes,
  });

  const generateRequestIdHeader = () => ({ 'X-Request-Id': uuidv4() });

  const requestWithAuth = async operation => {
    const token = await getAccessToken();
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        ...(generateRequestIdHeader() || {}),
      },
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(oper => requestWithAuth(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const httpLinkOptions = { uri: createClientOptions.host, credentials: 'same-origin' };
  const batchHttpLink = new BatchHttpLink(httpLinkOptions);
  const httpLink = new HttpLink(httpLinkOptions);

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (operation) {
      let response;
      if (graphQLErrors?.[0]) {
        response = {
          statusCode: graphQLErrors[0].extensions?.status,
          body: graphQLErrors[0].message,
        };
      } else if (networkError) {
        response = {
          statusCode: (networkError as any).statusCode,
          body: networkError.message,
        };
      }

      const { headers } = operation.getContext();
      const requestId = (headers && headers['X-Request-Id']) || headers['x-request-id'] || '';
      const statusCode =
        response?.statusCode ||
        (response?.body && response.body.length < 16 && response.body) ||
        '';

      const errorContext = {
        backend: new URL(createClientOptions.host).host,
        request: {
          endpointOrQuery: operation.operationName,
          method: 'POST', // GraphQL always uses POST
          requestIdHeader: requestId,
        },
        response,
      };

      rollbar.error(
        `${createClientOptions.host} error with status code: ${statusCode}: POST ${operation.operationName}`,
        errorContext
      );

      return;
    }

    // if the operation isn't available for some reason, just log the error object to Rollbar
    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        rollbar.error(graphQLError, `Apollo [GraphQL] (${createClientOptions.target})`);
      });
    }
    if (networkError) {
      rollbar.error(networkError, `Apollo [Network] (${createClientOptions.target})`);
    }
  });

  const retryLink = new RetryLink();
  const link = ApolloLink.from([errorLink, requestLink, retryLink]).split(
    operation => operation.getContext().batch === true,
    batchHttpLink,
    httpLink
  );

  return new ApolloClient({
    name: `spacestation-web-${createClientOptions.target}-client`,
    link,
    cache,
  });
};

export const mxGqlClient = createClientFactory({
  host: config.mxGraphql.uri,
  target: 'mx-gql',
});
